import React, {useEffect, useState} from 'react'
import 'react-toastify/dist/ReactToastify.css'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {useNavigate, useParams} from 'react-router-dom'
import {
  IAdditionalTaxModel,
  addtaxInitValues as initialValues,
  addtaxInitValues,
  IAddTaxModel,
  addTaxInitValues,
} from '../../../_models/additionaltax-model'
import {saveAdditionalTax, getSingleAdditonalTax, getTaxById, addTax, updateTax} from '../../../services/AdditionalTaxService'
import {toastNotify} from '../../../services/NotifyService'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {KTSVG} from '../../../_metronic/helpers'
import {ToggleSwitchTax} from '../components/Toggle'

const profileDetailsSchema = Yup.object().shape({
  taxName : Yup.string().required("Tax name is required"),
  taxValue : Yup.number().required("Enter tax percent value")
})

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Application Status',
    path: '/application-status',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

type Props = {
  id : number,
  afterFupModal :any,
}
const AddAdditionalTax = (props: Props) => {
  const {id} = props;
  const intl = useIntl()
  const navigate = useNavigate()

  const [data, setData] = useState<IAddTaxModel>(addTaxInitValues)
  const [title, setTitle] = useState<any>(props.id < 1 ? 'Add' : 'Edit')

  const [loading, setLoading] = useState(false)
  const formik = useFormik<IAddTaxModel>({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        const updatedData = { ...data, ...values };
        setData(updatedData);
        let res;
        if (id) {

          values.id = id;
          res = await updateTax(values);
        } else {
          res = await addTax(values);
        }
        if (res.data.success) {
          toastNotify(id, res);
          props.afterFupModal();
          redirect();
        } else {
          toastNotify(id, res);
        }
      } catch (err) {
        const res = { data: { message: 'Something went wrong', success: false } };
        toastNotify(id, res);
        console.log(err);
      } finally {
        setLoading(false);
        resetForm();
      }
    },
    
  });
  

  const redirect = () => {
    setTimeout(() => {
      navigate('/taxes')
    }, 500)
  }

  const bindDetails = (id: any) => {
    setLoading(true)
    setTimeout(() => {
      getTaxById(id)
        .then((res: any) => {
          let _data = res.data.data
          toggleChange(res.data.data.isAdditional)
          setData(_data)
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false)
        })
    }, 500)
  }

  const stateEventHandler = (data: any, fieldName: any, setFieldValue: any) => {
    setFieldValue(fieldName, data.stateId)
  }

  const onCloseClick = () => {
    props.afterFupModal()
  }

  useEffect(() => {
    if (props.id > 0) bindDetails(props.id)
  }, [])
  const toggleChange = (value: boolean) => {
    formik.setFieldValue("isAdditional",value)
  }

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='modal-dialog'>
            {/* 1 */}
            <div className='modal-content'>
              <div className='modal-header'>
                {/* begin::Modal title */}
                <h2 className='fw-bolder'>{title} Tax Name</h2>
                {/* end::Modal title */}

                {/* begin::Close */}
                <div
                  className='btn btn-icon btn-sm btn-active-icon-primary'
                  data-kt-users-modal-action='close'
                  onClick={() => onCloseClick()}
                  style={{cursor: 'pointer'}}
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                </div>
                {/* end::Close */}
              </div>
              <div className='modal-body'>
                <div className='fv-row my-3'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Tax Name</label>
                  <input
                    type='text'
                    className='form-control  mb-3 mb-lg-0'
                    placeholder='Tax Name'
                    {...formik.getFieldProps('taxName')}
                  />
                  {formik.touched.taxName && formik.errors.taxName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.taxName}</div>
                    </div>
                  )}
                </div>
                <div className='fv-row my-3'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Tax Percentage
                  </label>
                  <input
                    type='text'
                    className='form-control  mb-3 mb-lg-0'
                    placeholder='Tax Percent'
                    {...formik.getFieldProps('taxValue')}
                  />
                  {formik.touched.taxValue && formik.errors.taxValue && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.taxValue}</div>
                    </div>
                  )}
                </div>
                <div className='fv-row my-3 d-flex justify-content-end'>
                  <div className='col-auto'>
                    <ToggleSwitchTax
                      id='isAdditional'
                      checked={formik.values.isAdditional}
                      label='Additional Tax'
                      onChange ={(e:any)=>{toggleChange(e.target.checked)}}
                    />
                    {formik.touched.isAdditional && formik.errors.isAdditional && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.isAdditional}</div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Test Scores End */}
                <div className='modal-footer'>
                  <button type='submit' className='btn btn-primary' disabled={loading}>
                    {!loading && 'Save Changes'}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}
export {AddAdditionalTax}
