const today = new Date();

export  const initialValues = {
    customerId: '',
    branchId: '',
    billbranchId: '',
    shipBranchId: '',
    quotationDate: today.toLocaleDateString(),
    quotationNumber: '',
    revisionNo: '',
    notes: '',
    excriseRange: '',
    isProforma: false,
    addTax: '',
    isDraft: false,
    terms: '',
    pan: '',
    convertedtoSale: false,
  }