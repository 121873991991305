import React from "react";
import { Link } from "react-router-dom";

export function Footer() {
    return (
        <>
            <footer className="bg-white dark:bg-gray-800">
                <div className="max-w-screen-xl p-4 py-6 mx-auto lg:py-16 md:p-8 lg:p-10">
                    <div className="grid grid-cols-2 gap-8 md:grid-cols-3 lg:grid-cols-5">
                        <div>
                            <h3 className="mb-6  font-semibold text-gray-900 uppercase dark:text-white">Company</h3>
                            <ul className="text-gray-500 dark:text-gray-400">
                                <li className="mb-4">
                                    <a href="https://www.amberinfotech.com/about-us" target={'_blank'} className=" hover:underline">About</a>
                                </li>
                                <li className="mb-4">
                                    <a href="https://www.amberinfotech.com/contact-us" target={'_blank'} className="hover:underline">Contact Us</a>
                                </li>
                                <li className="mb-4">
                                    <a href="https://www.amberinfotech.com/contact-us" target={'_blank'} className="hover:underline">Careers</a>
                                </li>
                                {/*<li className="mb-4">
                                    <a href="#" className="hover:underline">Brand Center</a>
                                </li>
                                <li className="mb-4">
                                    <a href="#" className="hover:underline">Blog</a>
                                </li> */}
                            </ul>
                        </div>
                        <div>
                            <h3 className="mb-6  font-semibold text-gray-900 uppercase dark:text-white">Social media</h3>
                            <ul className="text-gray-500 dark:text-gray-400">
                                {/* <li className="mb-4">
                                    <a href="#" className="hover:underline">Discord Server</a>
                                </li> */}
                                <li className="mb-4">
                                    <a href="https://www.kooapp.com/profile/amberinfotech" target={'_blank'} className="hover:underline">Koo</a>
                                </li>
                                <li className="mb-4">
                                    <a href="https://www.facebook.com/amberinfotech" target={'_blank'} className="hover:underline">Facebook</a>
                                </li>
                                <li className="mb-4">
                                    <a href="https://www.amberinfotech.com/contact-us" target={'_blank'} className="hover:underline">Contact Us</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="mb-6  font-semibold text-gray-900 uppercase dark:text-white">Legal</h3>
                            <ul className="text-gray-500 dark:text-gray-400">
                                <li className="mb-4">
                                    <a href="https://www.amberinfotech.com/page-terms" target={'_blank'} className="hover:underline">Privacy Policy</a>
                                </li>
                                {/* <li className="mb-4">
                                    <a href="https://www.amberinfotech.com/page-terms" target={'_blank'} className="hover:underline">Licensing</a>
                                </li> */}
                                <li className="mb-4">
                                    <a href="https://www.amberinfotech.com/page-terms" target={'_blank'} className="hover:underline">Terms</a>
                                </li>
                            </ul>
                        </div>

                        <div>
                            <h3 className="mb-6  font-semibold text-gray-900 uppercase dark:text-white">Account</h3>
                            <ul className="text-gray-500 dark:text-gray-400">
                                <li className="mb-4">
                                    <Link to={'/auth/login'} className="hover:underline">Login</Link>
                                </li>
                                <li className="mb-4">
                                    <Link to={'/auth/registration'} className="hover:underline">Sign Up</Link>
                                </li>
                                {/* <li className="mb-4">
                                    <a href="#" className="hover:underline">Windows</a>
                                </li>
                                <li className="mb-4">
                                    <a href="#" className="hover:underline">MacOS</a>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                    <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
                    <div className="text-center">
                        <a href="#" className="flex items-center justify-center mb-5 text-2xl font-semibold text-gray-900 dark:text-white">
                            <img src="/media/logos/logo1.png" className="h-6 mr-3 sm:h-9" alt="Amber Bills Logo" />
                        </a>
                        <span className="block  text-center text-gray-500 dark:text-gray-400">© 2022-2023 Amber Bills. All Rights Reserved. Built by <a href="https://amberinfotech.com" className="text-purple-600 hover:underline dark:text-purple-500">amberinfotech.com</a>.
                        </span>
                        <ul className="flex justify-center mt-5 space-x-5">
                            <li>
                                <a href="https://www.facebook.com/amberinfotech" target={'_blank'} className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" /></svg>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/amberinfotech" target={'_blank'} className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" /></svg>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.kooapp.com/profile/amberinfotech" target={'_blank'} className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" /></svg>
                                </a>
                            </li>
                            {/* <li>
                                <a href="#" className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clip-rule="evenodd" /></svg>
                                </a>
                            </li> */}
                            {/* <li>
                                <a href="#" className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z" clip-rule="evenodd" /></svg>
                                </a>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </footer>

            {/*begin::Footer Section*/}
            <div className="mb-0 d-none">
                {/*begin::Curve top*/}
                <div className="landing-curve landing-dark-color">
                    <svg viewBox="15 -1 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z" fill="currentColor"></path>
                    </svg>
                </div>
                {/*end::Curve top*/}
                {/*begin::Wrapper*/}
                <div className="landing-dark-bg pt-20">
                    {/*begin::Container*/}
                    <div className="container">
                        {/*begin::Row*/}
                        <div className="row py-10 py-lg-20">
                            {/*begin::Col*/}
                            <div className="col-lg-6 pe-lg-16 mb-10 mb-lg-0">
                                {/*begin::Block*/}
                                <div className="rounded landing-dark-border p-9 mb-10">
                                    {/*begin::Title*/}
                                    <h2 className="text-white">Would you need a Custom Software?</h2>
                                    {/*end::Title*/}
                                    {/*begin::Text*/}
                                    <span className="fw-normal fs-4 text-gray-700">Email us to
                                        <a href="https://amberinfoteceh.com/support" className="text-white opacity-50 text-hover-primary">info@amberinfoteceh.com</a></span>
                                    {/*end::Text*/}
                                </div>
                                {/*end::Block*/}
                                {/*begin::Block*/}
                                <div className="rounded landing-dark-border p-9">
                                    {/*begin::Title*/}
                                    <h2 className="text-white">How About a Custom Project?</h2>
                                    {/*end::Title*/}
                                    {/*begin::Text*/}
                                    <span className="fw-normal fs-4 text-gray-700">Use Our Custom Development Service.
                                        <a href="https://amberinfoteceh.com/support" className="text-white opacity-50 text-hover-primary">Click to Get a Quote</a></span>
                                    {/*end::Text*/}
                                </div>
                                {/*end::Block*/}
                            </div>
                            {/*end::Col*/}
                            {/*begin::Col*/}
                            <div className="col-lg-6 ps-lg-16">
                                {/*begin::Navs*/}
                                <div className="d-flex justify-content-center">
                                    {/*begin::Links*/}
                                    <div className="d-flex fw-semibold flex-column me-20">
                                        {/*begin::Subtitle*/}
                                        <h4 className="fw-bold text-gray-400 mb-6">More for Amber Bills</h4>
                                        {/*end::Subtitle*/}
                                        {/*begin::Link*/}
                                        <a href="https://www.amberinfotech.com/faqs" className="text-white opacity-50 text-hover-primary fs-5 mb-6">FAQ</a>
                                        {/*end::Link*/}
                                        {/*begin::Link*/}

                                        {/*end::Link*/}
                                        {/*begin::Link*/}
                                        <a href="https://www.amberinfotech.com/contact-us" className="text-white opacity-50 text-hover-primary fs-5 mb-6">Support Forum</a>
                                        {/*end::Link*/}
                                    </div>
                                    {/*end::Links*/}
                                    {/*begin::Links*/}
                                    <div className="d-flex fw-semibold flex-column ms-lg-20">
                                        {/*begin::Subtitle*/}
                                        <h4 className="fw-bold text-gray-400 mb-6">Stay Connected</h4>
                                        {/*end::Subtitle*/}
                                        {/*begin::Link*/}
                                        <a href="https://www.facebook.com/amerinfotech" className="mb-6">
                                            <img src="assets/media/svg/brand-logos/facebook-4.svg" className="h-20px me-2" alt="" />
                                            <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">Facebook</span>
                                        </a>
                                        {/*end::Link*/}

                                        {/*begin::Link*/}
                                        <a href="https://twitter.com/amerinfotech" className="mb-6">
                                            <img src="assets/media/svg/brand-logos/twitter.svg" className="h-20px me-2" alt="" />
                                            <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">Twitter</span>
                                        </a>
                                        {/*end::Link*/}
                                        {/*begin::Link*/}
                                        {/* <a href="https://dribbble.com/amerinfotech" className="mb-6">
                                            <img src="assets/media/svg/brand-logos/dribbble-icon-1.svg" className="h-20px me-2" alt="" />
                                            <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">Dribbble</span>
                                        </a> */}
                                        {/*end::Link*/}
                                        {/*begin::Link*/}
                                        <a href="https://www.instagram.com/amerinfotech" className="mb-6">
                                            <img src="assets/media/svg/brand-logos/instagram-2-1.svg" className="h-20px me-2" alt="" />
                                            <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">Instagram</span>
                                        </a>
                                        {/*end::Link*/}
                                    </div>
                                    {/*end::Links*/}
                                </div>
                                {/*end::Navs*/}
                            </div>
                            {/*end::Col*/}
                        </div>
                        {/*end::Row*/}
                    </div>
                    {/*end::Container*/}
                    {/*begin::Separator*/}
                    <div className="landing-dark-separator"></div>
                    {/*end::Separator*/}
                    {/*begin::Container*/}
                    <div className="container">
                        {/*begin::Wrapper*/}
                        <div className="d-flex flex-column flex-md-row flex-stack py-7 py-lg-10">
                            {/*begin::Copyright*/}
                            <div className="d-flex align-items-center order-2 order-md-1">
                                {/*begin::Logo*/}
                                <a href="../../demo1/dist/landing.html">
                                    <img alt="Logo" src="/media/logos/logo11.png" className="h-15px h-md-20px" />
                                </a>
                                {/*end::Logo image*/}
                                {/*begin::Logo image*/}
                                <a className="mx-5 fs-6 fw-semibold text-gray-600 pt-1" href="https://amberinfotech.com">© 2022 Amber Infotech</a>
                                {/*end::Logo image*/}
                            </div>
                            {/*end::Copyright*/}
                            {/*begin::Menu*/}
                            <ul className="menu menu-gray-600 menu-hover-primary fw-semibold fs-6 fs-md-5 order-1 mb-5 mb-md-0">
                                <li className="menu-item">
                                    <a href="https://amberinfotech.com/about-us" target="_blank" className="menu-link px-2">About</a>
                                </li>
                                <li className="menu-item mx-5">
                                    <a href="https://amberinfotech.com/contact-us" target="_blank" className="menu-link px-2">Support</a>
                                </li>
                            </ul>
                            {/*end::Menu*/}
                        </div>
                        {/*end::Wrapper*/}
                    </div>
                    {/*end::Container*/}
                </div>
                {/*end::Wrapper*/}
            </div>
            {/*end::Footer Section*/}
        </>
    )
}