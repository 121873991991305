import {useState} from 'react'
import {AUTH_LOCAL_STORAGE_KEY} from '../../modules/auth'
import axios from 'axios'
import {baseURL} from '../../../services/BaseUrl'
import {Button, Col, Form, Modal, ProgressBar, Row} from 'react-bootstrap'

interface IFileUploadModalProps {
  show: boolean
  setShow: (show: boolean) => void
  id: number
  // use only listed types as mentioned in the FileInput  Form
  type:
    | 'image'
    | 'video'
    | 'audio'
    | 'document'
    | 'pdf'
    | 'excel'
    | 'csv'
    | 'zip'
    | 'rar'
    | 'other'
    | 'doc'
    | 'docx'
  onUpload: () => void
}
export function AddCatThumb(props: IFileUploadModalProps) {
  const {show, setShow, type, id} = props
  const handleClose = () => setShow(false)
  const [progress, setProgress] = useState(0)
  const stages = ['Upload', 'Processing', 'Completed', 'Error']
  const [uploading, setUploading] = useState(stages[0])
  const [validationError, setValidationError] = useState<string>('')
  const handleSubmit = (event: any) => {
    // validate file size and type
    setUploading(stages[1])
    event.preventDefault()
    if (!event.target.file.files[0]) {
      setValidationError('Please select a file')
      return
    }
    if (event.target.file.files[0].size > 1000000) {
      setValidationError('File size is not valid')
      return
    }
    setValidationError('')
    const formData = new FormData(event.target)
    let token = ''
    const aware_user = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || '{}')
    if (aware_user) {
      token = aware_user?.token
    }
    const config = {
      onUploadProgress: (progressEvent: {loaded: number; total: number}) => {
        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        setProgress(progress)
      },
    }
    const axiosInstance = axios.create(config)
    const s = `cat-images/${id}`
    if (id === 0) {
      throw new Error('id is 0')
    }
    axiosInstance
      .post(`/FileUploads/${s}`, formData, {
        baseURL: baseURL,
        headers: {
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
          Authorization: 'Bearer ' + token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          setUploading(stages[2])
          props.onUpload()
        } else {
          setUploading(stages[3])
        }
      })
      .catch((err) => {
        setUploading(stages[3])
      })
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <div className='d-flex justify-content-between w-100'>
            <Modal.Title>Upload File</Modal.Title>
            <i
              className='fas fa-times'
              style={{cursor: 'pointer', fontSize: '1.5rem'}}
              onClick={handleClose}
            ></i>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Form.Label>File</Form.Label>
              <Col md={10}>
                <Form.Group>
                  <Form.Control
                    name='file'
                    accept={
                      type === 'image'
                        ? 'image/jpeg, image/png'
                        : type === 'video'
                        ? 'video/mp4,video/x-m4v,video/*'
                        : type === 'audio'
                        ? 'audio/*'
                        : type === 'pdf'
                        ? 'application/pdf'
                        : type === 'doc'
                        ? 'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                        : type === 'excel'
                        ? 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        : type === 'zip'
                        ? 'application/zip, application/x-compressed-zip'
                        : type === 'csv'
                        ? 'text/csv'
                        : 'image/jpeg, image/png'
                    }
                    type='file'
                  />
                </Form.Group>
              </Col>
              <Col md={2} className='d-flex align-items-center justify-content-center p-2'>
                <Button
                  type='submit'
                  className='d-flex align-items-center justify-content-center p-2'
                >
                  Upload
                </Button>
              </Col>
            </Row>
          </Form>
          {uploading === stages[1] && (
            <Row className='mt-2'>
              <ProgressBar
                striped
                variant={progress === 100 ? 'success' : 'primary'}
                animated
                now={progress}
                label={`${progress}%`}
              />
            </Row>
          )}
          {validationError && <div className='text-danger'>{validationError}</div>}
          {uploading === 'Error' && <div className='text-danger'>Error in uploading file</div>}
          {uploading === 'Completed' && (
            <Row className='d-flex align-items-center justify-content-center'>
              <Col md={2} className='d-flex align-items-center justify-content-center p-2'>
                <i className='fas fa-check-circle text-success'></i>
              </Col>
              <Col md={10} className='d-flex align-items-center justify-content-center p-2'>
                <div className='text-success'>File uploaded successfully</div>
              </Col>
            </Row>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}
