import React, { useEffect, useState, Fragment } from 'react';
import { Link } from "react-router-dom";
import { Table } from 'react-bootstrap';

import { searchInvoices } from "../../../services/InvoiceService";
import { DisableSidebar, PageLink, PageTitle } from '../../../_metronic/layout/core';
import { KTSVG } from '../../../_metronic/helpers';
import moment from 'moment';
import Pagination from '../../../lib/Pagination/Pagination';
import { PAGE_SIZE } from "../../../_shared";
import { InvoiceAddModal } from './partials/InvoiceAddModal';
import { NoDataFound, ServerMessage } from '../../../lib/NoData';
import { IConfirmModel } from '../../../_models/confirm-model';
import { ConfirmBox } from '../../../lib/ConfirmBox';
import { getCustomers } from '../../../services/CustomerService';
import { getInvoiceCount } from '../../../services/CounterService';
import { Loader } from '../components/components';
import { CounterBox } from '../../../lib/CounterBox';

interface BigObject<T> {
    [index: string]: T
}

export function ListInvoices() {
    const [toggle, setToggle] = useState("");
    const [counters, setCounters] = React.useState<any>({});
    const [data, setData] = React.useState([]);
    const [currentTableData, setCurrentTableData] = React.useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = React.useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [showInvoiceModal, setShowInvoiceModal] = useState<boolean>(false);
    const [confirmModel, setConfirmModel] = useState<IConfirmModel>();
    const [filterValues, setFilterValues] = useState<any>({
        pageNumber: 1,
        pageSize: 100000,
        status: [],
        isProforma: false,
        isTax: false,
        isBoth: false,
        customerId: 0,
        filter: "",
        fromDate: "",
        toDate: "",
    });

    const initialValues = {
        pageNumber: 1,
        pageSize: 100000,
        customerId: 0,
        fromDate: "",
        toDate: "",
        filter: "",
        status: [],
        isProforma: true,
        isTax: true,
        isBoth: true,
    }

    const LOADINGSTAGES = {
        IDLE: 0,
        LOADING: 1,
        POSITIVE: 3,
        NEGATIVE: 4,
        ERROR: 5,
        EMPTY: 6
    }
    const [loadingStage, setLoadingStage] = useState<any>(LOADINGSTAGES.IDLE);

    const [counterData, setCounterData] = React.useState([]);

    const bindCounters = () => {
        getInvoiceCount().then((res: any) => {
            const data = res.data.data;
            setCounters(data);

            const localArray: any = [];
            let bigObject: BigObject<object> = data;
            Object.keys(bigObject).forEach(key => {
                
                localArray.push({
                    text: key === "isCancel" ? "Cancelled" :
                        key === "taxInvoice" ? "Tax invoices" : "Proforma Invoices",
                    value: bigObject[key]
                })
            })
            setCounterData(localArray);

        }).catch((err: any) => {
            console.log(err);
        })
    }

    const paginate = (pageNumber: any) => {
        setCurrentPage(pageNumber);
        const firstPageIndex = (pageNumber - 1) * PAGE_SIZE;
        const lastPageIndex = firstPageIndex + PAGE_SIZE;
        let _currentTableData = data.slice(firstPageIndex, lastPageIndex);
        setCurrentTableData(_currentTableData);
    };

    const bindGrid = (values: any) => {
        setIsLoading(true);
        setLoadingStage(LOADINGSTAGES.LOADING);
        setTimeout(() => {
            searchInvoices(values)
                .then((res: any) => {
                    if (res.data.success) {
                        let _data = res.data.data;
                        const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
                        const lastPageIndex = firstPageIndex + PAGE_SIZE;
                        let _currentTableData = _data.slice(firstPageIndex, lastPageIndex);
                        setData(_data);
                        setTotalRows(_data.length);
                        if (_data.length > 0) {
                            setLoadingStage(LOADINGSTAGES.POSITIVE)
                            setCurrentTableData(_currentTableData);
                        }
                        else {
                            setLoadingStage(LOADINGSTAGES.NEGATIVE)
                            setCurrentTableData([]);
                        }
                    }
                    else {
                        setLoadingStage(LOADINGSTAGES.NEGATIVE)
                        setCurrentTableData([]);
                    }
                })
                .catch((err) => {
                    setLoadingStage(LOADINGSTAGES.ERROR)
                    setCurrentTableData([]);
                }).finally(() => {
                    setIsLoading(false);
                });
        }, 1000);
    };

    const filterData = () => {
        // const arrayToint = filterValues.status.map((item: any) => parseInt(item));
        const values = {
            pageNumber: 1,
            pageSize: 100000,
            customerId: filterValues.customerId,
            fromDate: filterValues.fromDate,
            toDate: filterValues.toDate,
            filter: filterValues.filter,
            status: [],
            isProforma: filterValues.isProforma,
            isTax: filterValues.isTax,
            isBoth: filterValues.isBoth,
        }
        bindGrid(values);
    }

    const resetData = () => {
        IsProformaHandler("0");
        setFilterValues(
            { filter: "", customerId: 0 }
        );
        setTimeout(() => {
            bindGrid(initialValues);
        }, 100);
    }

    const openDeleteModal = (id: any) => {
        if (id > 0) {
            const confirmModel: IConfirmModel = {
                title: 'Cancel Invoice',
                btnText: 'Cancel this invoice',
                deleteUrl: `invoices/cancel/${id}`,
                message: 'cancel-invoice',
            }

            setConfirmModel(confirmModel);
            setTimeout(() => {
                setShowConfirm(true)
            }, 500);
        }
    }

    const afterConfirm = (res: any) => {
        if (res) bindGrid(initialValues);

        setShowConfirm(false);
    }

    const afterInvoiceUpdate = (res: any) => {
        if (res) bindGrid(initialValues);

        setShowInvoiceModal(false);
    }

    const [customers, setCustomers] = useState<any>([])
    const bindCustomers = () => {
        getCustomers().then((res: any) => {
            const data = res.data.data;
            if (data.length > 0) {
                data.unshift({ id: 0, fullName: "Select" });
                setCustomers(data);
            }
        }).catch((err: any) => {
            console.log(err);
        })
    }

    const IsProformaHandler = (value: any) => {
        setFilterValues({
            ...filterValues,
            isProforma: value === '1' ? true : false,
            isTax: value === '2' ? true : false,
        })
    }

    useEffect(() => {
        bindGrid(initialValues);
        bindCounters();
        bindCustomers();
    }, []);

    const profileBreadCrumbs: Array<PageLink> = [
        {
            title: 'Invoices',
            path: '/invoices',
            isSeparator: false,
            isActive: false,
        },
    ]
    return (<>
        <PageTitle breadcrumbs={profileBreadCrumbs}>Invoices</PageTitle>
        <DisableSidebar>
            <div className='card mb-xl-5'>
                <div className='card-body pt-9 pb-0'>
                    <div className='d-flex flex-wrap flex-sm-nowrap'>
                        <div className='flex-grow-1'>
                            <div className='d-flex justify-content-between flex-wrap mb-2'>
                                <div className='d-flex justify-items-center flex-grow-1'>
                                    <CounterBox data={counterData} />
                                </div>
                                <div className='d-flex justify-items-center'>
                                    <div>
                                        {/* <div className=''> */}
                                        {/* begin::Filter Button */}
                                        <button
                                            disabled={isLoading}
                                            type='button'
                                            className='btn btn-sm btn-secondary me-3'
                                            data-kt-menu-trigger='click'
                                            data-kt-menu-placement='bottom-end'
                                        >
                                            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='' />
                                            Filter
                                        </button>
                                        {/* end::Filter Button */}
                                        {/* begin::SubMenu */}
                                        <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
                                            {/* begin::Header */}
                                            <div className='px-7 py-5'>
                                                <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
                                            </div>
                                            {/* end::Header */}

                                            {/* begin::Separator */}
                                            <div className='separator border-gray-200'></div>
                                            {/* end::Separator */}

                                            {/* begin::Content */}
                                            <div className='px-7 py-5' data-kt-user-table-filter='form'>
                                                {/* begin::Input group */}

                                                <div className='mb-10'>
                                                    <label className='form-label fs-6 fw-bold'>Customer :</label>
                                                    <select
                                                        className='form-select form-select-solid fw-bolder'
                                                        data-kt-select2='true'
                                                        data-placeholder='Select Customer'
                                                        data-allow-clear='true'
                                                        data-kt-user-table-filter='two-step'
                                                        data-hide-search='true'
                                                        id='customerId'
                                                        name='customerId'
                                                        value={filterValues.customerId}
                                                        onChange={(e) => {
                                                            setFilterValues({ ...filterValues, customerId: e.target.value });
                                                        }}
                                                    >
                                                        {customers.map((item: any) => (
                                                            <option key={item.id} value={item.id}>{item.fullName}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                {/* end::Input group */}

                                                {/* begin::Input group */}
                                                {/* begin::Input group */}
                                                <div className='mb-10'>
                                                    <label className='form-label fs-6 fw-bold'>Type:</label>
                                                    <select
                                                        className='form-select form-select-solid fw-bolder'
                                                        data-kt-select2='true'
                                                        data-placeholder='Select option'
                                                        data-allow-clear='true'
                                                        data-kt-user-table-filter='two-step'
                                                        data-hide-search='true'
                                                        name='isProforma'
                                                        onChange={(e) => (IsProformaHandler(e.target.value))}
                                                        value={filterValues.isProforma ? '1' : filterValues.isTax ? '2' : '0'}
                                                    >
                                                        <option value='0'>Select</option>
                                                        <option value='1'>Proforma</option>
                                                        <option value='2'>Tax</option>
                                                        {/* <option value='3'>Both</option> */}
                                                    </select>
                                                </div>
                                                {/* end::Input group */}

                                                {/* begin::Input group */}
                                                <div className='mb-10'>
                                                    <label className='form-label fs-6 fw-bold'>Keyword:</label>
                                                    <input
                                                        className='form-control fw-bolder'
                                                        placeholder='Keyword'
                                                        name='filter'
                                                        id='filter'
                                                        value={filterValues.filter}
                                                        onChange={(e) => setFilterValues({ ...filterValues, filter: e.target.value })}
                                                    />
                                                </div>
                                                {/* end::Input group */}

                                                {/* begin::Actions */}

                                                <div className='d-flex justify-content-end'>
                                                    <button
                                                        type='button'
                                                        disabled={isLoading}
                                                        onClick={resetData}
                                                        className='btn btn-sm btn-light btn-active-light-primary fw-bold me-2 px-6'
                                                        data-kt-menu-dismiss='true'
                                                        data-kt-user-table-filter='reset'
                                                    >
                                                        Reset
                                                    </button>
                                                    <button
                                                        disabled={isLoading}
                                                        type='button'
                                                        onClick={filterData}
                                                        className='btn btn-sm btn-primary fw-bold px-6'
                                                        data-kt-menu-dismiss='true'
                                                        data-kt-user-table-filter='filter'
                                                    >
                                                        Apply
                                                    </button>
                                                </div>
                                                {/* end::Actions */}
                                            </div>
                                            {/* end::Content */}
                                        </div>
                                        {/* end::SubMenu */}
                                        <Link to="add">
                                            <button
                                                className='btn btn-sm btn-primary me-3'
                                                data-bs-toggle='modal'
                                                data-bs-target='#kt_modal_offer_a_deal'
                                            >
                                                Add New
                                            </button></Link>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loadingStage === LOADINGSTAGES.LOADING && <Loader />}
            {loadingStage === LOADINGSTAGES.NEGATIVE && <NoDataFound />}
            {loadingStage === LOADINGSTAGES.ERROR && <ServerMessage message="Oops! server issue occured" />}
            {loadingStage === LOADINGSTAGES.POSITIVE &&
                <div className='row'>
                    <div className="col-xs-12 col-md-12">
                        <div className="notice d-flex bg-light-primary rounded border-primary-light border p-6 mb-3">
                            <i className="bi bi-info-circle-fill fs-1 text-primary me-4"></i>
                            <div className="d-flex flex-stack flex-grow-1" title='Info'>
                                <div className="">
                                    <div className="fs-7 text-gray-700">Click on the Invoice Number to view invoice details, edit invoice, view pdf and send email.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <div className="display mb-4 dataTablesCard customer-list-table">
                                        <Table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                                            <thead>
                                                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                                    <th>invoice Number</th>
                                                    <th>Bill To</th>
                                                    <th>invoice Date</th>
                                                    <th>invoice Due Date</th>
                                                    <th className='text-end'>total Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentTableData.map((item: any, index: any) =>
                                                    <tr key={index}>
                                                        <td className='text-gray-900 fw-bold '>
                                                            <div className="d-flex align-items-center">
                                                                <div className="d-flex flex-column">
                                                                    <Link to={`/invoice/details/${item.id}`} className="text-dark text-hover-primary mb-1"> {item.invoiceNumber}</Link>
                                                                    {item.isCancel ? <span className='badge badge-light-danger'>Cancelled</span> : item.isDraft ? <span className='badge badge-light'>Draft</span> : ""}
                                                                </div>
                                                            </div>

                                                        </td>
                                                        <td>{item.billName}</td>
                                                        <td>{moment(item.invoiceDate).format("DD-MM-yyyy")}</td>
                                                        <td>{moment(item.invoiceDueDate).format("DD-MM-yyyy")}</td>
                                                        <td className='text-dark fw-bold text-end'>
                                                            <div>
                                                                {item.totalAmount?.toFixed(2)}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                        <Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage}
                                            totalCount={totalRows}
                                            pageSize={PAGE_SIZE}
                                            onPageChange={(page: any) => paginate(page)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </DisableSidebar>

        {showInvoiceModal && <InvoiceAddModal setShowInvoiceModal={setShowInvoiceModal} afterInvoiceUpdate={afterInvoiceUpdate} />}
        {showConfirm && <ConfirmBox confirmModel={confirmModel} afterConfirm={afterConfirm} />}
    </>)
}