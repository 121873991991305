import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
type Props = {
    message: string;
    onConfirm: () => void;
    onCancel?: () => void;
}
export function ChangeAlert(props: Props) {
    const { message, onConfirm,onCancel } = props;
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
  };

  const handleConfirm = () => {
    onConfirm();
    setShow(false);
    onCancel && onCancel();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
