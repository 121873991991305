import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Table } from 'react-bootstrap';
import { IConfirmModel } from '../../../_models/confirm-model';
import { PAGE_SIZE } from '../../../_shared';
import { searchPayments } from '../../../services/PaymentService';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';
import { NoDataFound, ServerMessage } from '../../../lib/NoData';
import Pagination from '../../../lib/Pagination/Pagination';
import moment from 'moment';
import { getPaymentCounts } from "../../../services/CounterService"
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { LoadData } from '../../../lib/NoData';
import { getCustomers } from '../../../services/CustomerService';
import { Counter, FilterDate, FilterInput, FilterSelect, Loader, LOADINGSTAGES } from '../components/components';

const profileBreadCrumbs: Array<PageLink> = [
    {
        title: 'Payments',
        path: '/payments',
        isSeparator: false,
        isActive: false,
    },
    //    {
    //        title: '',
    //        path: '',
    //        isSeparator: true,
    //        isActive: false,
    //    },
]

export function ListPayments() {
    const [counters, setCounters] = React.useState<any>({});
    const [data, setData] = React.useState([]);
    const [currentTableData, setCurrentTableData] = React.useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = React.useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [customers, setCustomers] = React.useState<any>([]);
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [confirmModel, setConfirmModel] = useState<IConfirmModel>();
    const [keyword, setKeyword] = React.useState<any>('');
    const [loadingStages, setLoadingStages] = useState<number>(LOADINGSTAGES.IDLE);

    const initialValues = {
        pageNumber: 1,
        pageSize: 100000,
        fromDate: "",
        toDate: "",
        filter: "",
        status: 0,
        customer: 0,
    }

    const paginate = (pageNumber: any) => {
        setCurrentPage(pageNumber);
        const firstPageIndex = (pageNumber - 1) * PAGE_SIZE;
        const lastPageIndex = firstPageIndex + PAGE_SIZE;
        let _currentTableData = data.slice(firstPageIndex, lastPageIndex);
        setCurrentTableData(_currentTableData);
    };

    const bindCounters = () => {
        getPaymentCounts().then((res: any) => {
            const data = res.data.data;
            setCounters(data);
        }).catch((err: any) => {
            console.log(err);
        })
    }

    const bindGrid = (values: any) => {
        setLoadingStages(LOADINGSTAGES.LOADING);
        setIsLoading(true);
        setTimeout(() => {
            searchPayments(values)
                .then((res) => {
                    if (res.data.success) {
                        let _data = res.data.data.data;
                        const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
                        const lastPageIndex = firstPageIndex + PAGE_SIZE;
                        let _currentTableData = _data.slice(firstPageIndex, lastPageIndex);

                        setData(_data);
                        setTotalRows(_data.length);
                        if (_data.length > 0) {
                            setLoadingStages(LOADINGSTAGES.POSITIVE);
                            setCurrentTableData(_currentTableData);
                        }
                        else {
                            setLoadingStages(LOADINGSTAGES.NEGATIVE);
                        }
                    }
                    else {
                        setLoadingStages(LOADINGSTAGES.NEGATIVE);
                        setCurrentTableData([]);
                    }

                })
                .catch(() => {
                    setCurrentTableData([]);
                    setLoadingStages(LOADINGSTAGES.ERROR);
                }).finally(() => {
                    setIsLoading(false);
                });
        }, 1000);
    };

    const filterData = () => {
        setIsLoading(true)
        setCurrentTableData([])
        const filterValues = {
            ...initialValues,
            filter: keyword,
            customerId: filterSetups.customerId,
            fromDate: filterSetups.fromDate,
            toDate: filterSetups.toDate,
        }
        bindGrid(filterValues)
    }

    const resetData = () => {
        setKeyword('')
        setFilterSetups({
            customerId: 0,
            fromDate: '',
            toDate: '',
        })
        bindGrid(initialValues)
    }

    const [filterSetups, setFilterSetups] = React.useState<any>({
        customerId: 0,
        fromDate: '',
        toDate: '',
    });

    const openDeleteModal = (id: any) => {
        if (id > 0) {
            const confirmModel: IConfirmModel = {
                title: 'Delete payment',
                btnText: 'Delete this payment',
                deleteUrl: `payments/${id}`,
                message: 'delete-payment',
            }

            setConfirmModel(confirmModel);
            setTimeout(() => {
                setShowConfirm(true)
            }, 500);
        }
    }

    const afterConfirm = (res: any) => {
        if (res) bindGrid(initialValues);

        setShowConfirm(false);
    }

    const bindCustomers = () => {
        getCustomers().then((res: any) => {
            const data = res.data.data;
            if (data.length > 0) {
                data.map((item: any) => {
                    item.id = item.id;
                    item.name = item.fullName;
                })
                data.unshift({ id: 0, name: 'All' })
                setCustomers(data);
            }
        }).catch((err: any) => {
            console.log(err);
        })
    }

    useEffect(() => {
        bindGrid(initialValues);
        bindCounters();
        bindCustomers();
    }, []);

    return (<>
        <PageTitle breadcrumbs={profileBreadCrumbs}>Payments</PageTitle>
        {/* <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-0'>
                <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                    <div className='flex-grow-1'>
                        <div className='d-flex justify-content-center flex-wrap mb-20 mt-20' style={{ height: '400px' }}>
                            <div className="d-flex align-content-center flex-wrap">
                                <div className="d-flex flex-column bd-highlight mb-3">
                                    <div className="p-2 bd-highlight"><img alt='Under construction' src={toAbsoluteUrl('/media/svg/under_construction.svg')} className='h-250px my-50 mb-20' /></div>
                                    <div className="p-2 bd-highlight text-center"> <h3 className='fs-2 text-gray-600'>Under construction</h3></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}

        <div className=''>
            <div className='card mb-5 mb-xl-10'>
                <div className='card-body pt-9 pb-0'>
                    <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                        <div className='flex-grow-1'>
                            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                                <div className='d-flex flex-row'>
                                    <div className='d-flex align-items-center mb-2'>
                                        <div className='d-flex justify-items-center'>
                                            <div className='d-flex my-4 bg-light-success px-6 py-4 rounded-2 '>
                                                <Counter
                                                    text="Last Year Payments"
                                                    count={counters?.paymentLastYear}
                                                    color="text-success"
                                                    boxColor='bg-light-success'
                                                    link='#'
                                                    key={1}
                                                />
                                            </div>
                                            <div className='d-flex my-4 bg-light-primary px-6 py-4 rounded-2 ms-4'>
                                                <Counter
                                                    text="This Month Payments"
                                                    boxColor='bg-light-primary'
                                                    color='text-primary'
                                                    count={counters?.paymentThisMonth}
                                                    link='#'
                                                    key={2}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex my-4'>
                                    {/* begin::Filter Button */}
                                    <button
                                        disabled={isLoading}
                                        type='button'
                                        className='btn btn-sm btn-primary me-3'
                                        data-kt-menu-trigger='click'
                                        data-kt-menu-placement='bottom-end'
                                    >
                                        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                                        Filter
                                    </button>
                                    {/* end::Filter Button */}
                                    {/* begin::SubMenu */}
                                    {/* scrollable dropdown */}
                                    <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
                                        {/* begin::Header */}
                                        <div className='px-7 py-5'>
                                            <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
                                        </div>
                                        {/* end::Header */}

                                        {/* begin::Separator */}
                                        <div className='separator border-gray-200'></div>
                                        {/* end::Separator */}

                                        {/* begin::Content */}
                                        <div className='px-7 py-5' data-kt-user-table-filter='form'>
                                            {/* begin::Input group */}
                                            <div className='mb-10'>
                                                <FilterSelect
                                                    className='col-12'
                                                    placeholder='Select customer'
                                                    name='customerId'
                                                    label='Customer'
                                                    value={filterSetups.customerId}
                                                    onChange={(e: any) => {
                                                        setFilterSetups({ ...filterSetups, customerId: e.target.value })
                                                    }}
                                                    options={customers}
                                                />
                                            </div>
                                            {/* end::Input group */}

                                            {/* begin::Input group */}
                                            <div className='mb-10'>
                                                <FilterDate
                                                    className='col-12'
                                                    placeholder='From date'
                                                    name='fromDate'
                                                    label='From date'
                                                    value={filterSetups.fromDate}
                                                    onChange={(value: any) => {
                                                        setFilterSetups({ ...filterSetups, fromDate: value })
                                                    }}
                                                />
                                            </div>
                                            <div className='mb-10'>
                                                <FilterDate
                                                    className='col-12'
                                                    placeholder='To date'
                                                    name='toDate'
                                                    label='To date'
                                                    value={filterSetups.toDate}
                                                    onChange={(value: any) => {
                                                        setFilterSetups({ ...filterSetups, toDate: value })
                                                    }}
                                                />
                                            </div>
                                            {/* end::Input group */}
                                            {/* begin::Input group */}
                                            <div className='mb-10'>
                                                <FilterInput className='col-12'
                                                    placeholder='Search' name='keyword'
                                                    label='Keyword' value={keyword}
                                                    onChange={(e: any) => setKeyword(e.target.value)} />
                                            </div>
                                            {/* end::Input group */}
                                            {/* begin::Actions */}
                                            <div className='d-flex justify-content-end'>
                                                <button
                                                    type='button'
                                                    disabled={isLoading}
                                                    onClick={resetData}
                                                    className='btn btn-sm btn-light btn-active-light-primary fw-bold me-2 px-6'
                                                    data-kt-menu-dismiss='true'
                                                    data-kt-user-table-filter='reset'
                                                >
                                                    Reset
                                                </button>
                                                <button
                                                    disabled={isLoading}
                                                    type='button'
                                                    onClick={filterData}
                                                    className='btn btn-sm btn-primary fw-bold px-6'
                                                    data-kt-menu-dismiss='true'
                                                    data-kt-user-table-filter='filter'
                                                >
                                                    Apply
                                                </button>
                                            </div>
                                            {/* end::Actions */}
                                        </div>
                                        {/* end::Content */}
                                    </div>
                                    {/* end::SubMenu */}
                                    <Link
                                        to='/payment/add'
                                        className='btn btn-sm btn-primary me-3'
                                    >
                                        Add New
                                    </Link>
                                    <a href='#' className='btn btn-sm btn-secondary me-2' id='kt_import_toggle'>
                                        <KTSVG
                                            path='/media/icons/duotune/arrows/arr012.svg'
                                            className='svg-icon-3 d-none'
                                        />
                                        <span className='indicator-label'>Import</span>
                                    </a>
                                </div>
                            </div>

                            {/* <div className='d-flex flex-wrap flex-stack'>
                                <div className='d-flex flex-column flex-grow-1 pe-8'>
                                    <div className='d-flex flex-wrap'>
                                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                            <div className='d-flex align-items-center'>
                                                <div className='fs-2 fw-bolder'>{counters?.paymentCount}</div>
                                            </div>

                                            <div className='fw-bold fs-6 text-gray-400'>Active payments</div>
                                        </div>
                                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                            <div className='d-flex align-items-center'>
                                                <div className='fs-2 fw-bolder'>{counters?.paymentCount}</div>
                                            </div>

                                            <div className='fw-bold fs-6 text-gray-400'>Inactive payments</div>
                                        </div>
                                    </div>
                                </div>

                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            {loadingStages === LOADINGSTAGES.LOADING && <Loader />}
            {loadingStages === LOADINGSTAGES.ERROR && <ServerMessage message="Oops! server issue occured" />}
            {loadingStages === LOADINGSTAGES.NEGATIVE && <ServerMessage message="No Data Found" />}
            <div className='row'>
                <div className="col-xl-12 col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <div className="display mb-4 dataTablesCard payment-list-table">
                                    {loadingStages === LOADINGSTAGES.POSITIVE && <>
                                        <Table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                                            <thead>
                                                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                                    <th>Name</th>
                                                    <th>Amount</th>
                                                    <th>Payment mode</th>
                                                    <th>Payment date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    currentTableData.map((item: any, index: any) =>
                                                        <tr key={index}>
                                                            <td className='text-gray-600 fw-bold'>
                                                                {/* <p className='mb-0'>
                                                                <div className='symbol symbol-50px symbol-circle'>
                                                                    <span className={`symbol-label bg-light-primary text-primary fs-5 fw-bolder`}>
                                                                        {item.fullName.charAt(0)}
                                                                    </span>
                                                                </div>
                                                                <a className='text-gray-800 text-hover-primary mb-1' href={"/payments/edit" + item.id}>
                                                                    {item.fullName}
                                                                </a>
                                                            </p> */}
                                                                <div className="d-flex align-items-center">
                                                                    <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                                        <span>
                                                                            <div className={`symbol-label bg-light-${index % 2 === 0 ? 'primary' : 'info'} 
                                                                        text-${index % 2 === 0 ? 'primary' : 'info'} fs-5 fw-bolder`}>
                                                                                {item.fullName?.charAt(0)}</div>
                                                                        </span>
                                                                    </div>
                                                                    <div className="d-flex flex-column">
                                                                        <Link to={"/payment/edit/" + item.id} className="text-primary text-hover-secondary mb-1"> {item.fullName}</Link>
                                                                        <span className='fs-7'>{item.city}</span>
                                                                    </div>
                                                                </div>

                                                            </td>
                                                            <td className='text-gray-600 fw-bold '>{item.amount.toFixed(2)}</td>
                                                            <td className='text-gray-600 fw-bold '>{item.modeText}</td>
                                                            <td className='text-gray-600 fw-bold '>{moment(item.paymentDate).format("DD-MM-yyyy")}</td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                        <Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage}
                                            totalCount={totalRows}
                                            pageSize={PAGE_SIZE}
                                            onPageChange={(page: any) => paginate(page)}
                                        /></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>)
}