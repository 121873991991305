export interface IQuotation {
  id: number,
  custEmail: string,
  custContactNo: string
  custFullName: string
  customerCode: string
  custContactName: string
  isCancel: boolean
  billName: string
  email: string
  fullName: string
  billAddress: string
  billCity: string
  billState: string
  billPostalCode: string
  shipAddress: string
  shipCity: string
  shipName: string
  shipState: string
  shipPostalCode: string
  quotationNumber: string
  quotationDate?: Date
  revisionNo: string
  customerId: number
  GSTIN: string
  CST: string
  ECC: string
  TIN: string
  PAN: string
  ExciseRange: string
  status: number
  terms: string
  notes: string
  amountInWords: string
  subAmount?: number
  totalAmount: number
  taxAmount?: number
  totalFreightCharges: number
  quotationItems: IQuotationItem[]
  company: ICompanyModel
  branchId: number
  assignedTo: number
  companyName: string
  companyAddress: string
  companyCity: string
  companyState: string
  companyPostalCode: string
  companyGstin: string
  discount:number
  addDiscount:number
}

export const qCompanyInit: ICompanyModel = {
  id: 0,
  companyName: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  postalCode: '',
  alternateNo: '',
  email: '',
  website: '',
  gstin: '',
  // businessType: '',
  // organizationType: 0,
  // industryType: 0,
  // financialYear: 0,
  stateId: 0,
  // mobile: '',
  // portalUrl: '',
  // isBomEnabled: false,
  // appId: 0,
  // pictureId: 0,
  // parentId: 0,
  // code: '',
  // pan: '',  
  // pass: '',
  // currencyId: 0,
  // dateDisplayFormat: '',
  // taxId: 0,
  // isActive: 0,
}

export const qInitialValues: IQuotation = {
    id: 0,
    billName: '',
    email: '',
    isCancel: false,
    fullName: '',
    billAddress: '',
    billCity: '',
    billState: '',
    billPostalCode: '',
    shipAddress: '',
    shipCity: '',
    shipName: '',
    shipState: '',
    shipPostalCode: '',
    quotationNumber: '',
    quotationDate: new Date(),
    revisionNo: '',
    customerId: 0,
    GSTIN: '',
    CST: '',
    ECC: '',
    TIN: '',
    PAN: '',
    ExciseRange: '',
    status: 0,
    terms: '',
    notes: '',
    amountInWords: '',
    subAmount: 0,
    totalAmount: 0,
    taxAmount: 0,
    totalFreightCharges: 0,
    branchId: 0,
    assignedTo: 0,
    quotationItems: [],
    company: qCompanyInit,
    custContactName: '',
    custContactNo: '',
    custEmail: '',
    custFullName: '',
    customerCode: '',
    companyName: '',
    companyAddress: '',
    companyCity: '',
    companyState: '',
    companyPostalCode: '',
    companyGstin: '',
    discount : 0,
    addDiscount:0
}

export interface IQuotationItem {
  id: number
  itemId: number
  itemName: string
  itemDesc: string
  hsn: string
  price: number
  unit: string
  discountPercent: number
  discount: number
  taxPercent: number
  taxAmount: number
  amount: number
  grossAmount: number
  quotationId: number
  quantity: number
  freightCharges: number
}

export const qItemInitialValues: IQuotationItem = {
  id: 0,
  itemId: 0,
  itemName: '',
  itemDesc: '',
  hsn: '',
  price: 0,
  unit: '',
  discountPercent: 0,
  discount: 0,
  taxPercent: 0,
  taxAmount: 0,
  amount: 0,
  grossAmount: 0,
  quotationId: 0,
  quantity: 0,
  freightCharges: 0,
}

export interface IBillBy {
  companyName: string
  companyAddress: string
  companyCity: string
  companyState: string
  companyPostalCode: string
  companyGstin: string
}

export const billByInitialValues: IBillBy = {
  companyName: '',
  companyAddress: '',
  companyCity: '',
  companyState: '',
  companyPostalCode: '',
  companyGstin: '',
}

export interface IBillTo {
  billEmail: string
  billContactNo: string
  billName: string
  billAddress: string
  billCity: string
  billState: string
  billPostalCode: string
  customerGstin: string | null
  customerPan: string | null
  customerEmail: string | null
  customerContactNo: string | null
  billBranchGstin: string
}

export const billToInitialValues: IBillTo = {
  billName: '',
  billAddress: '',
  billCity: '',
  billState: '',
  billPostalCode: '',
  billBranchGstin: '',
  customerPan: '',
  billEmail: '',
  billContactNo: '',
  customerGstin: '',
  customerEmail: '',
  customerContactNo: ''
}

export interface IShipTo {
  shipName: string
  shipAddress: string
  shipCity: string
  shipState: string
  shipPostalCode: string
  customerGstin: string | null
  customerPan: string | null
  customerEmail: string | null
  customerContactNo: string | null
}

export const shipToInitialValues: IShipTo = {
  shipName: '',
  shipAddress: '',
  shipCity: '',
  shipState: '',
  shipPostalCode: '',
  customerGstin: '',
  customerPan: '',
  customerEmail: '',
  customerContactNo: '',
}

export interface ICompanyModel {
  id: number;
  companyName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postalCode: string;
  alternateNo: string;
  email: string;
  website: string;
  gstin: string;
  stateId?: number;
}

export interface IQuotationInputDto {
  id?: number;
  billName?: string;
  billAddress?: string;
  billCity?: string;
  billState?: string;
  billPostalCode?: string;
  shipAddress?: string;
  shipName?: string;
  shipCity?: string;
  shipState?: string;
  shipPostalCode?: string;
  quotationNumber?: string;
  quotationDate?: Date | null;
  customerGstin?: string;
  customerPan?: string;
  customerEmail?: string;
  customerContactNo?: string;
  gstin?: string | null;
  subAmount?: number | null;
  totalAmount?: number | null;
  taxAmount?: number | null;
  totalFreightCharges?: number | null;
  companyName?: string | null;
  companyAddress?: string | null;
  companyCity?: string | null;
  companyState?: string | null;
  companyPostalCode?: string | null;
  companyGstin?: string | null;
}
export const quotationInputDtoInitialValues: IQuotationInputDto = {
  id: 0,
  billName: '',
  billAddress: '',
  billCity: '',
  billState: '',
  billPostalCode: '',
  shipAddress: '',
  shipName: '',
  shipCity: '',
  shipState: '',
  shipPostalCode: '',
  quotationNumber: '',
  quotationDate: new Date(),
  customerGstin: '',
  customerPan: '',
  customerEmail: '',
  customerContactNo: '',
  gstin: '',
  subAmount: 0,
  totalAmount: 0,
  taxAmount: 0,
  totalFreightCharges: 0,
  companyName: '',
  companyAddress: '',
  companyCity: '',
  companyState: '',
  companyPostalCode: '',
  companyGstin: '',
}

export interface IQuoteAdditionalTax {
  id: number,
  taxId: number,
  taxName: string,
  taxAmount: number,
  taxPercent: number,
  quotationId: number,
}
export const quoteTaxInitVals: IQuoteAdditionalTax = {
  id: 0,
  taxName: "",
  taxId: 0,
  taxAmount: 0,
  taxPercent: 0,
  quotationId: 0
}

export interface IQuoteAdditionalDiscount {
  id: number,
  discountId: number,
  discountName: string,
  discountAmount: number,
  discountPercent: number,
  quotationId: number,
}
export const quoteDiscountInitVals: IQuoteAdditionalDiscount = {
  id: 0,
  discountName: "",
  discountId: 0,
  discountAmount: 0,
  discountPercent: 0,
  quotationId: 0
}