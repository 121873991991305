/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify'
import { toastNotify } from '../../../../services/NotifyService'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { getStates } from '../../../../services/CommonService'
import { onboardCompany } from '../../../../services/CompanyService'

const initialValues = {
    businessName: '',
    lastname: '',
    businessType: '',
    address: '',
    city: '',
    stateId: null,
    userKey: '',
    gstin: ''
}

const registrationSchema = Yup.object().shape({
    businessName: Yup.string()
        .min(3, 'Minimum 3 characters')
        .max(50, 'Maximum 50 characters')
        .required('Organization name is required'),
    businessType: Yup.string().notOneOf(['', null, "0"], 'Please select a business type'),
    // .email('Wrong email format')
    gstin: Yup.string()
        .trim(),
    //.matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/, 'Please enter a valid GST number'),
    // .max(50, 'Maximum 50 characters')
    // .required('Email is required'),
    city: Yup.string()
        .min(3, 'Minimum 3 characters')
        .max(50, 'Maximum 50 characters')
        .required('City is required'),
    address: Yup.string()
        .min(3, 'Minimum 3 characters')
        .max(50, 'Maximum 50 characters')
        .required('address is required'),
    stateId: Yup.string().required('Please select a state').nullable()
        .notOneOf(['', null, "0"], 'Please select a State'),

})

export function RegistrationSteps() {
    const navigate = useNavigate();
    const { userKey } = useParams();

    const [loading, setLoading] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [statesArr, setStatesArr] = useState<any>([]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema: registrationSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true);
            const id = toast.loading("Please wait...");

            try {
                values.userKey = userKey || '';
                await onboardCompany(values).then((res: any) => {
                    let _data = res.data.data;
                   toastNotify(id, res);

                    if (res.data.success) {
                        setFormSubmitted(true);
                        navigate('/auth');
                    }
                })
                    .catch((err: any) => {
                        const res = { data: { message: 'Something went wrong', success: false } };
                        toastNotify(id, res);
                        console.error(err);
                        setLoading(false);
                    }).finally(() => {
                        setLoading(false);
                    });
            } catch (error) {
                setStatus('Something went wrong. Please retry or contact admin.')
                setSubmitting(false)
                setLoading(false)
            }
        },
    })

    const bindStates = async () => {
        await getStates(
            99
        ).then((res: any) => {
            let _data = res.data.data

            setStatesArr(_data)
        })
            .catch((err: any) => {
                console.error(err)
            }).finally(() => {
            })
    }

    useEffect(() => {
        bindStates();
        // if (email) {
        //     formik.setFieldValue('email', email);
        // }
    }, [])

    return (
        <>
            <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-start bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
                <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
                    <div className="card">
                        <div className="card-body w-900px">
                            <div className="text-center mb-lg-10">
                                {/* begin::Logo */}
                                <Link to='/' className='mb-12'>
                                    <img alt='Amber Bills' title='Amber Bills' src={toAbsoluteUrl('/media/logos/logo1.png')} className='h-50px ' />
                                </Link>
                                {/* end::Logo */}
                            </div>

                            {

                                formSubmitted ?
                                    <div className='w-100'>

                                        {/* begin::Alert*/}
                                        <div className="alert alert-dismissible d-flex flex-center flex-column py-10 px-10 px-lg-20" style={{ margin: '-33px' }}>

                                            {/* begin::Icon*/}
                                            <span className="svg-icon svg-icon-5hx svg-icon-success me-4 mb-5">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="currentColor"></path>
                                                    <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="currentColor"></path>
                                                </svg>
                                            </span>
                                            {/* end::Icon*/}

                                            {/* begin::Wrapper*/}
                                            <div className="text-center">
                                                {/* begin::Title*/}
                                                <h1 className="fw-bold mb-5">Thank you for signing up!</h1>
                                                {/* end::Title*/}

                                                {/* begin::Separator*/}
                                                <div className="separator separator-dashed border-success opacity-25 mb-5"></div>
                                                {/* end::Separator*/}

                                                {/* begin::Content*/}
                                                <div className="mb-9 text-dark fs-5">
                                                    To complete your sign up process, please check your email for the <strong>confirmational email</strong> that we just sent.<br /><br />
                                                    If you do not receive your confirmation email in next two hours, please <a href="#" className="fw-bold me-1">contact us</a>.
                                                </div>
                                                {/* end::Content*/}

                                                {/* begin::Buttons*/}
                                                <div className="d-flex flex-center flex-wrap">
                                                    {/* <a href="#" className="btn btn-outline btn-outline-success btn-active-success m-2">Cancel</a> */}
                                                    <Link to="/auth" className="btn btn-success m-2">Ok, I got it</Link>
                                                </div>
                                                {/* end::Buttons*/}
                                            </div>
                                            {/* end::Wrapper*/}
                                        </div>
                                        {/* end::Alert*/}
                                    </div> :
                                    <form
                                        className='form fv-plugins-bootstrap5 fv-plugins-framework'
                                        noValidate
                                        id='kt_login_signup_form'
                                        onSubmit={formik.handleSubmit}
                                    >
                                        {/* begin::Heading */}
                                        {/* begin::Content*/}
                                        <div className="mb-9 text-dark fs-5">
                                            <h2 className='fw-bolder d-flex align-items-center text-dark'>
                                                Account set up
                                                <i
                                                    className='fas fa-exclamation-circle ms-2 fs-7'
                                                    data-bs-toggle='tooltip'
                                                    title='This information will be utilized in invoices, quotes, and other areas'
                                                ></i>
                                            </h2>
                                            We require a bit more information about your organization in order to complete the setup of your account.
                                        </div>
                                        {/* end::Content*/}


                                        {formik.status && (
                                            <div className='mb-lg-15 alert alert-secondary text-gray-700'>
                                                <div className='alert-text font-weight-bold'>{formik.status}</div>
                                            </div>
                                        )}

                                        {/* begin::Form group businessName */}
                                        <div className='row fv-row mb-7'>
                                            <div className='col-xl-6'>
                                                <label className='form-label fw-bolder text-dark fs-6'>Organization name</label>
                                                <input
                                                    placeholder='Organization name'
                                                    type='text'
                                                    autoComplete='off'
                                                    {...formik.getFieldProps('businessName')}
                                                    className={clsx(
                                                        'form-control form-control-lg form-control-solid',
                                                        {
                                                            'is-invalid': formik.touched.businessName && formik.errors.businessName,
                                                        },
                                                        {
                                                            'is-valid': formik.touched.businessName && !formik.errors.businessName,
                                                        }
                                                    )}
                                                />
                                                {formik.touched.businessName && formik.errors.businessName && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert'>{formik.errors.businessName}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-xl-6">
                                                <label className='form-label fw-bolder text-dark fs-6'>Business type</label>
                                                <select
                                                    placeholder='Business type'
                                                    autoComplete='off'
                                                    {...formik.getFieldProps('businessType')}
                                                    className={clsx(
                                                        'form-control form-control-lg form-control-solid',
                                                        { 'is-invalid': formik.touched.businessType && formik.errors.businessType },
                                                        {
                                                            'is-valid': formik.touched.businessType && !formik.errors.businessType,
                                                        }
                                                    )}
                                                >
                                                    <option value="0">Select</option>
                                                    <option value='1'>Pvt. Ltd.</option>
                                                    <option value='2'>Sole Proprietorship</option>
                                                    <option value='3'>Non-profit</option>
                                                    <option value='4'>Limited Liability</option>
                                                    <option value='5'>General Partnership</option>
                                                    <option value='5'>Other</option>
                                                </select>
                                                {formik.touched.businessType && formik.errors.businessType && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert'>{formik.errors.businessType}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {/* end::Form group */}


                                        {/* begin::Form group address */}
                                        <div className='mb-10 fv-row' data-kt-password-meter='true'>
                                            <div className='mb-1'>
                                                <label className='form-label fw-bolder text-dark fs-6'>Address</label>
                                                <div className='position-relative mb-3'>
                                                    <textarea
                                                        placeholder='Address'
                                                        autoComplete='off'
                                                        {...formik.getFieldProps('address')}
                                                        className={clsx(
                                                            'form-control form-control-lg form-control-solid',
                                                            {
                                                                'is-invalid': formik.touched.address && formik.errors.address,
                                                            },
                                                            {
                                                                'is-valid': formik.touched.address && !formik.errors.address,
                                                            }
                                                        )}
                                                    ></textarea>
                                                    {formik.touched.address && formik.errors.address && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                <span role='alert'>{formik.errors.address}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {/* end::Form group */}

                                        {/* begin::Form group city */}
                                        <div className='row fv-row mb-7'>
                                            <div className='col-xl-6'>
                                                <label className='form-label fw-bolder text-dark fs-6'>City</label>
                                                <input
                                                    placeholder='City'
                                                    type='text'
                                                    autoComplete='off'
                                                    {...formik.getFieldProps('city')}
                                                    className={clsx(
                                                        'form-control form-control-lg form-control-solid',
                                                        {
                                                            'is-invalid': formik.touched.city && formik.errors.city,
                                                        },
                                                        {
                                                            'is-valid': formik.touched.city && !formik.errors.city,
                                                        }
                                                    )}
                                                />
                                                {formik.touched.city && formik.errors.city && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert'>{formik.errors.city}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-xl-6">
                                                <label className='form-label fw-bolder text-dark fs-6'>State</label>
                                                <select
                                                    placeholder='Email'
                                                    autoComplete='off'
                                                    {...formik.getFieldProps('stateId')}
                                                    className={clsx(
                                                        'form-control form-control-lg form-control-solid',
                                                        { 'is-invalid': formik.touched.stateId && formik.errors.stateId },
                                                        {
                                                            'is-valid': formik.touched.stateId && !formik.errors.stateId,
                                                        }
                                                    )}
                                                >
                                                    <option value="0">Select</option>
                                                    {statesArr && statesArr.map((sta: any) =>
                                                        <option key={sta.id} value={sta.id}>{sta.stateName}</option>
                                                    )}
                                                </select>
                                                {formik.touched.stateId && formik.errors.stateId && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert'>{formik.errors.stateId}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {/* end::Form group */}

                                        {/* begin::Form group GST */}
                                        <div className='row fv-row mb-7'>
                                            <div className='col-xl-6'>
                                                <label className='form-label fw-bolder text-dark fs-6'>GST number</label>
                                                <input
                                                    placeholder='GST number'
                                                    type='text'
                                                    autoComplete='off'
                                                    {...formik.getFieldProps('gstin')}
                                                    className={clsx(
                                                        'form-control form-control-lg form-control-solid',
                                                        {
                                                            'is-invalid': formik.touched.gstin && formik.errors.gstin,
                                                        },
                                                        {
                                                            'is-valid': formik.touched.gstin && !formik.errors.gstin,
                                                        }
                                                    )}
                                                />
                                                {formik.touched.gstin && formik.errors.gstin && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert'>{formik.errors.gstin}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {/* end::Form group */}

                                        {/* begin::Form group */}
                                        <div className='text-center mt-5'>
                                            <button
                                                type='submit'
                                                id='kt_sign_up_submit'
                                                className='btn btn-lg btn-primary w-25 mb-5'
                                                disabled={formik.isSubmitting || !formik.isValid}
                                            >
                                                {!loading && <span className='indicator-label'>Submit</span>}
                                                {loading && (
                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                        Please wait...{' '}
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </button>
                                        </div>
                                        {/* end::Form group */}
                                    </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
