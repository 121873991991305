import { Link } from "react-router-dom";

export function Banner() {
    return (<>
        <section className="bg-white dark:bg-gray-900">
            <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
                <div className="mr-auto place-self-center lg:col-span-7">
                    <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl dark:text-white">Take control of your business</h1>
                    <p className="max-w-2xl mb-6 font-light text-gray-700 lg:mb-8 md:text-lg lg:text-xl ">
                        {/* This free and open-source landing page template was built using the utility classes from <a href="https://tailwindcss.com" className="hover:underline">Tailwind CSS</a> and based on the components from the <a href="https://flowbite.com/docs/getting-started/introduction/" className="hover:underline">Flowbite Library</a> and the <a href="https://flowbite.com/blocks/" className="hover:underline">Blocks System</a>. */}
                        Welcome! Are you tired of struggling with maintaining invoices & quotations on spreadsheets? Well, we have the solution for you!
                    </p>
                    <p className="max-w-2xl mb-6 font-light text-gray-700 lg:mb-8 md:text-lg lg:text-xl ">
                        Our brand new SAAS product is designed to make your life easier and more efficient than ever before. With our innovative technology and user-friendly interface, you can easily manage stock, customers, sales, purchase invoices and payments.
                    </p>
                    <p className="max-w-2xl mb-6 font-light text-gray-700 lg:mb-8 md:text-lg lg:text-xl ">
                        So what are you waiting for? Try Amber Bills today and start seeing results!
                    </p>
                    <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                        <Link to={'/auth/registration'} className="btn-primary btn">
                            Try 14 day free trial
                        </Link>

                    </div>
                </div>
                <div className="lg:mt-0 lg:col-span-5 lg:flex">
                    <img src="/media/images/hero3.png" alt="Amber Bills" />
                </div>
            </div>
        </section>
    </>)
}