import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useFormik } from "formik";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Combobox } from "react-widgets/cjs";
import Accordion from 'react-bootstrap/Accordion';
import * as Yup from 'yup'

// lib
import { DateSelect } from "../../../../lib/DateSelect";

// services
import { getCompanyBranches } from "../../../../services/CompanyService";
import { getAllProducts } from "../../../../services/CatalogService";
import { getCustomerBranches, getCustomers } from "../../../../services/CustomerService";
import { getTaxes } from "../../../../services/CommonService";
import { toastNotify } from "../../../../services/NotifyService";

// shared components
import { AddCustomerShort } from "../../../../_shared/components/AddCustomerShort";
import { EditNotes } from "../../../../_shared/components/EditNotes";
import { EditTerms } from "../../../../_shared/components/EditTerms";

//models
//import { IQuotationModel } from "../../../../../_models/quotation-model";
import { ProductEditModal } from "../../catalog/_productModal";
//import { postQuotation } from "../../../../../services/QuotationService";
//import '../../../css/style.css';
import { getSuppliers } from "../../../../services/SuppliersService";
import saveNewPurchaseOrder, { savePurchaseOrder } from "../../../../services/BackendService/PurchaseOrdersService";
import { breadcrumbs } from "../../components/PageTitle";
import { PageTitle } from "../../../../_metronic/layout/core";
import { ValidationDate, ValidationSelect } from "../../components/components";
import { postCreditNote } from "../../../../services/BackendService/CreditNoteService";
import { ToggleSwitch } from "../../components/Toggle";
import { IInvoiceClone } from "../../../../_models/invoice-model";
import { InvoicePrefixModal } from "../../../../_shared/components/InvoicePrefix";

export function DebitNoteAddForm(props: any) {
    const { afterInvoiceUpdate } = props;
    const navigate = useNavigate();

    const [rowsData, setRowsData] = useState<any[]>([]);
    const [taxRowsData, setTaxRowsData] = useState<any[]>([{ taxName: '', taxPercent: 0 }]);

    const [custArr, setCustArr] = useState<any[]>([]);
    const [branchArr, setBranchArr] = useState<any[]>([]);
    const [custBranchArr, setCustBranchArr] = useState<any[]>([]);
    const [prodArr, setProdArr] = useState<any[]>([]);
    const [taxArr, setTaxArr] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [showCustomerForm, setShowCustomerForm] = useState<any>(false);
    const [showProductForm, setShowProductForm] = useState<any>(false);
    const [showTerms, setShowTerms] = useState<any>(false);
    const [showNotes, setShowNotes] = useState<any>(false);
    const [terms, setTerms] = useState<any>('');
    const [notes, setNotes] = useState<any>('');

    const [subTotal, setSubTotal] = useState<any>(0);
    const [discTotal, setDiscTotal] = useState<any>(0);
    const [taxTotal, setTaxTotal] = useState<any>(0);
    const [addTaxTotal, setAddTaxTotal] = useState<any>(0);
    const [total, setTotal] = useState<any>(0);
    const [selectedCustBranch, setSelectedCustBranch] = useState<any>(null);


    const invoiceSchema = Yup.object().shape({
        branchId: Yup.string()
            .required('Please choose billing company/branch')
            .test('len', 'Please choose a billing company/branch', (val: any) => val !== '0'),
        customerId: Yup.string()
            .required('Please choose customer')
            .test('len', 'Please choose a customer', (val: any) => val !== '0'),
        billBranchId: Yup.string()
            .required('Please choose customer branch')
            .test('len', 'Please choose a customer branch', (val: any) => val !== '0'),
        creditNoteDate: Yup.date().min(new Date(),
            'Debit Note date should be greater than today').required('Please choose Debit note date'),
        creditNoteDueDate: Yup.date().min(new Date(),
            'Debit Note due date should be greater than today'
            )
    })

    const today = new Date();
    const initialValues = {
        customerId: '',
        branchId: '',
        billBranchId: '',
        shipBranchId: '',
        creditNoteDate: '',//today.toString(),
        creditNoteDueDate: '',//(today.setDate(today.getDate() + 14)).toString(),
        terms: '',
        notes: '',
        isProforma: false,
        isDraft: false,
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema: invoiceSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {

            const _invoice = {
                creditNoteDate: values.creditNoteDate == "" ? today.toLocaleDateString() : values.creditNoteDate,
                creditNoteDueDate: values.creditNoteDueDate == "" ? new Date(today.setDate(today.getDate() + 14)).toLocaleDateString() : values.creditNoteDueDate,
                customerId: +values.customerId,
                billBranchId: +values.billBranchId,
                shipBranchId: +values.billBranchId,
                branchId: +values.branchId,
                subAmount: subTotal - discTotal,
                taxAmount: taxTotal,
                discount: discTotal,
                totalAmount: total,
                terms: values.terms,
                notes: values.notes,
                isProforma: values.isProforma,
                addTax: addTaxTotal,
                isDraft: values.isDraft,
                isCreditNote: false
            }

            rowsData.forEach(element => {
                element.taxAmount = (((element.price) * element.quantity) - (+element.discount)) * element.taxPercent / 100;
                element.amount = (element.price) * element.quantity - (+element.discount);
                element.grossAmount = ((element.price) * element.quantity - (+element.discount)) + ((((element.price) * element.quantity) - (+element.discount)) * element.taxPercent / 100);
            });

            taxRowsData.forEach(element => {
                element.taxAmount = (subTotal - discTotal) * element.taxPercent / 100;
            });

            var postData = {
                creditNote: _invoice,
                creditNoteItems: rowsData,
                additionalTaxes: taxRowsData
            };
            setLoading(true);
            const toastId = toast.loading("Please wait...");

            try {
                await postCreditNote(postData)
                    .then(async (res: any) => {
                        let _data = res.data.data;
                        toastNotify(toastId, res)

                        if (!afterInvoiceUpdate) {
                            { navigate('/debit-notes'); }
                        }
                        else {
                            afterInvoiceUpdate(res.data.success);
                        }
                    })
                    .catch((err: any) => {
                        const res = { data: { message: 'Something went wrong', success: false } };
                        toastNotify(toastId, res);
                        console.error(err);
                    });

            } catch (error) {
                console.error(error)
                setStatus('Something went wrong')
                setSubmitting(false)
            } finally {
                setLoading(false);
            }
        },
    })

    const handleChange = (index: any, evnt: any) => {
        const { name, value } = evnt.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;
        
        // If the modified field is "quantity" or "price", update the corresponding dependent fields
        if (name === "quantity" || name === "price") {
          const item = rowsInput[index];
          const taxableAmount:any = (item.price * item.quantity - item.discount)?.toFixed(2);
          const taxAmount:any = ((taxableAmount * item.taxPercent) / 100).toFixed(2);
          const totalAmount = (parseFloat(taxableAmount) + parseFloat(taxAmount)).toFixed(2);
          rowsInput[index].taxableAmount = taxableAmount;
          rowsInput[index].taxAmount = taxAmount;
          rowsInput[index].totalAmount = totalAmount;
        }
        
        setRowsData(rowsInput);
      };
      


    const deleteItem = (index: any) => {
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
    }

    const addTaxRow = (index: any) => {
        const newRow: any = { taxName: '', taxPercent: 0 };
        const rowsInput = [...taxRowsData, newRow];
        setTaxRowsData(rowsInput);
    }

    const handleTaxChange = (index: any, evnt: any) => {
        const { name, value } = evnt.target;
        const rowsInput = [...taxRowsData];

        if (+value > 0) {
            var taxInfo = taxArr.filter(c => c.id === +value)[0];

            rowsInput[index]["taxName"] = taxInfo.taxName;
            rowsInput[index]["taxPercent"] = taxInfo.taxValue;
            setTaxRowsData(rowsInput);

            //var arr = taxArr.filter(c => c.id !== +value)
            //setTaxArr(arr);
        } else {
            rowsInput[index]["taxName"] = 'None';
            rowsInput[index]["taxPercent"] = 0;
            setTaxRowsData(rowsInput);
        }
    };


    const deleteTaxItem = (index: any) => {
        const rows = [...taxRowsData];
        rows.splice(index, 1);
        setTaxRowsData(rows);
    }

    const bindCustomers = () => {
        setTimeout(() => {
            getCustomers()
                .then((res: any) => {
                    let _data = res.data.data;
                    _data = _data.map((c: any) => {
                        return { ...c, name: c.fullName, id: c.id }
                    });
                    _data.unshift({ id: 0, name: 'Select Customer' });
                    setCustArr(_data);
                })
                .catch(() => {
                });
        }, 1000);
    };

    const bindCompanyBranches = () => {
        setTimeout(() => {
            getCompanyBranches()
                .then((res: any) => {
                    let _data = res.data.data;
                    const arr: any = [];
                    _data.forEach((element: any) => {
                        arr.push({
                            id: element.id,
                            name: element.companyName
                        });
                    });
                    arr.unshift({ id: 0, name: 'Select' });
                    setBranchArr(arr);
                })
                .catch(() => {
                });
        }, 1000);
    };

    const bindCustomerBranches = (custId: any) => {
        setTimeout(() => {
            getCustomerBranches(custId, 0)
                .then((res: any) => {
                    let _data = res.data.data;
                    _data = _data.map((c: any) => {
                        return { ...c, name: c.name, id: c.customerBarchId }
                    });
                    _data.unshift({ id: 0, name: 'Select' });
                    setCustBranchArr(_data);
                })
                .catch(() => {
                });
        }, 1000);
    };

    const bindProducts = () => {
        setTimeout(() => {
            getAllProducts()
                .then((res: any) => {
                    let _data = res.data.data;
                    setProdArr(_data);
                })
                .catch(() => {
                });
        }, 1000);
    };

    const bindTaxes = () => {
        setTimeout(() => {
            getTaxes(true)
                .then((res: any) => {
                    let _data = res.data.data;
                    setTaxArr(_data);
                    // if (_data.length > 0) {
                    //     addTaxRow(0);
                    // }
                })
                .catch(() => {
                });
        }, 1000);
    };

    useEffect(() => {
        if (formik.values.customerId) {
            setSelectedCustBranch(null);
            bindCustomerBranches(formik.values.customerId);
        }
    }, [formik.values.customerId]);

    useEffect(() => {
        if (formik.values.billBranchId) {
            var _selectedCustBranch = custBranchArr.filter((c: any) => c.customerBarchId === +formik.values.billBranchId)[0];
            setSelectedCustBranch(_selectedCustBranch);
        }
    }, [formik.values.billBranchId]);

    const [value, setValue] = useState<any>(null);


    const productSelected = (val: any) => {
        setValue(val)
    }
    const addProductToInvoice = () => {
        if (value.id && value.id > 0) {
            const selectedProduct = prodArr.filter((product: any) => product.id === value.id)[0];

            const newRow: any = {
                itemId: selectedProduct.id,
                itemName: selectedProduct.productName,
                hsnSac: selectedProduct.hsn,
                quantity: quantity,
                unit: selectedProduct.unitName,
                price: selectedProduct.defaultSP,
                discount: 0,
                taxPercent: selectedProduct.taxPercent,
                taxAmount: ((selectedProduct.defaultSP) * 1) * selectedProduct.taxPercent / 100,
                amount: (selectedProduct.defaultSP) * 1,
                grossAmount: (selectedProduct.defaultSP) * 1 + (((selectedProduct.defaultSP) * 1) * selectedProduct.taxPercent / 100)
            }
            const rowsInput = [...rowsData, newRow];
            setRowsData(rowsInput);
            setValue(null);
            setProdArr([]);
            setProdArr(prodArr.filter((product: any) => product.id !== selectedProduct.id));
        }
    }

    const afterUpdate = (data: any, setFieldValue: any) => {
        bindCustomers();
        setFieldValue('customerId', data);
        setSelectedCustBranch(null);
        setTimeout(() => {
            bindCustomerBranches(data);
        }, 100);
    }

    const afterUpdateProduct = (data: any) => {
        bindProducts();
    }

    const afterUpdateTerms = (data: any) => {
        setShowTerms(false);
        formik.setFieldValue('terms', data);

    }

    const openTermsModal = (data: any) => {
        setTimeout(() => {
            setTerms(data);
            setShowTerms(true);
        }, 500);
    }

    // notes
    const afterUpdateNotes = (data: any) => {
        setShowNotes(false);
        formik.setFieldValue('notes', data);

    }

    const openNotesModal = (data: any) => {
        setTimeout(() => {
            setNotes(data);
            setShowNotes(true);
        }, 500);
    }

    useEffect(() => {
        bindCompanyBranches();
        bindCustomers();
        bindProducts();
        bindTaxes();
    }, [])

    useEffect(() => {
        calcTotals();
    }, [rowsData])


    useEffect(() => {
        calcTotals();
    }, [taxRowsData]);

    function calcTotals() {
        let st = 0;
        rowsData.forEach((element: any) => {
            
            st += (element.price) * element.quantity;
        });
        setSubTotal(st);
        let dt = 0;
        rowsData.forEach((element: any) => {
            if (element.discount)
                dt += (+element.discount);
        });
        setDiscTotal(dt);

        let tt = 0;
        rowsData.forEach((element: any) => {
            
            var t = (((element.price) * element.quantity) - (+element.discount)) * element.taxPercent / 100;
            tt += t;
        });
        setTaxTotal(tt);

        let at = 0;
        taxRowsData.forEach((element: any) => {
            var t = (subTotal - discTotal) * element.taxPercent / 100;
            at += t;
        });
        setAddTaxTotal(at);

        setTotal(st + tt + at - dt);
    }
    const toggleChange = (e: any) => {

        if (e.target.checked) {
            formik.values.isProforma = true;
        }
        else {
            formik.values.isProforma = false;
        }
    }
    const toggleDraft = (e: any) => {
        if (e.target.checked) {
            formik.values.isDraft = true;
        }
        else {
            formik.values.isDraft = false;
        }
    }
    const [quantity, setQuantity] = useState(1);  
      const [showInvPreFrom, setShowInvPreFrom] = useState<any>(false);

    return (
        <>
        <PageTitle
          breadcrumbs={breadcrumbs({
            title: 'Debit Notes',
            path: '/debit-notes',
          })}
        >
          Add Debit Note
        </PageTitle>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='separator mb-5'> </div>
              <div className='card'>
                <div className='card-body'>
                  <form
                    className='col-md-11 mb-lg-10'
                    onSubmit={formik.handleSubmit}
                    noValidate
                    id='ab_invoice_clone_form'
                  >
                    <div className='row'>
                      <div className='col-md-12 mb-lg-12'>
                        {/* 1 */}
                        <div className='row mb-3'>
                        <div className='col-md-6'>
                            <ValidationSelect
                              className='my-2'
                              label='Billed by'
                              isRequired
                              placeholder='Select Branch'
                              name='branchId'
                              formik={formik}
                              options={branchArr}
                            />
                            <div
                              className='d-flex align-items-center alert alert-custom alert-notice alert-light-primary fade show'
                              role='alert'
                            >
                              <div className='alert-icon'>
                                <i className='fas fa-info-circle'></i>
                              </div>
                              <div className='alert-text mx-2 text-gray-600'>
                                Invoices will display this company/branch address and GSTIN
                              </div>
                            </div>
                          </div>
                              <div className='col-md-3'>
                                <button
                                  type='button'
                                  className='btn btn-sm btn-primary mt-12'
                                  onClick={() => setShowInvPreFrom(true)}
                                >
                                  Invoice number settings
                                </button>
                                <div
                                  className='d-flex align-items-center alert alert-custom alert-notice alert-light-primary fade show'
                                  role='alert'
                                >
                                  <div className='alert-icon'>
                                    <i className='fas fa-info-circle'></i>
                                  </div>
                                  <div className='alert-text mx-2 text-gray-600'>
                                    Manage Invoice prefix and serial number
                                  </div>
                                </div>
                              </div>
                          <ValidationDate
                            className='col-lg-4'
                            label='Debit Note Date'
                            isRequired
                            placeholder='Select Date'
                            name='creditNoteDate'
                            formik={formik}
                          />
                          <ValidationDate
                            className='col-lg-4'
                            label='Due Date'
                            isRequired
                            placeholder='Select Date'
                            name='creditNoteDueDate'
                            formik={formik}
                          />
                        </div>
                        {/* 2 */}
                        <div className='row'>
                          <ValidationSelect
                            className='col-md-6 my-2'
                            label='Customer'
                            isRequired
                            placeholder='Select customer'
                            name='customerId'
                            formik={formik}
                            options={custArr}
                          />
                          <button
                            type='button'
                            className='col-md-2 offset-md-3 align-self-center btn btn-sm btn-primary'
                            onClick={() => setShowCustomerForm(true)}
                          >
                            + New customer
                          </button>
                        </div>

                        {/* <div className='row rounded mb-5'> */}
                          {/* <div className='row mb-3 form-group'>
                            <div className='row'>
                             
                              <div className=' col-md-2 d-flex flex-row mb-5'>
                                <div className='my-2'>
                                  <ToggleSwitch
                                    name='isProforma'
                                    label='Proforma'
                                    checked={formik.values.isProforma}
                                    onChange={(e: any) => toggleChange(e)}
                                  />
                                </div>
                                <div className='my-2'>
                                  <ToggleSwitch
                                    name='isDraft'
                                    label='Draft'
                                    checked={formik.values.isDraft}
                                    onChange={(e: any) => toggleDraft(e)}
                                  />
                                </div>
                              </div>
                              <div className='row'>
                                <ValidationSelect
                                  className='col-md-5 my-2'
                                  label='Customer'
                                  isRequired
                                  placeholder='Select Customer'
                                  name='customerId'
                                  formik={formik}
                                  options={custArr}
                                />
                                <button
                                  type='button'
                                  className='col-md-2 offset-md-2 align-self-center btn btn-sm btn-primary'
                                  onClick={() => setShowCustomerForm(true)}
                                >
                                  Add Customer
                                </button>
                              </div>
                            </div>
                          </div> */}
                          <div className='row form-group'>
                            <ValidationSelect
                              className='col-md-6 my-2'
                              placeholder='Bill To Branch'
                              label='Bill To Branch'
                              isRequired
                              name='billBranchId'
                              formik={formik}
                              options={custBranchArr}
                            />
                            <div className='col-md-5 mb-2'>
                              <div className='px-3 py-3'>
                                {selectedCustBranch != null && (
                                  <address>
                                    <p className='mb-0 text-gray-600'>
                                      {selectedCustBranch.name} {selectedCustBranch.address}{' '}
                                    </p>
                                    <p className='mb-0 text-gray-600'>
                                      {selectedCustBranch.city} {selectedCustBranch.stateName}{' '}
                                      {selectedCustBranch.postalCode}{' '}
                                    </p>
                                    <p className='mb-2 text-gray-600'>
                                      {selectedCustBranch.contactNo}| {selectedCustBranch.email}
                                    </p>
                                    <p className='mb-0 text-dark'>
                                      GST: {selectedCustBranch.gstin}{' '}
                                    </p>
                                  </address>
                                )}
                              </div>
                            </div>
                          </div>
                        {/* </div> */}
                        {/* <div className="separator my-10"></div> */}
                        {/* 3 */}
                        <div className='row mb-3 rounded'>
                          <label className='col-lg-7 align-self-center form-label fw-bolder'>
                            Add Items to Debit Note
                          </label>
                          <button
                            className='col-md-2 align-self-center offset-md-2 btn btn-primary btn-sm'
                            type='button'
                            onClick={() => setShowProductForm(true)}
                          >
                            + New product
                          </button>
                        </div>

                        <div className='row bg-light  rounded p-3 mb-3'>
                          <div className='row'>
                            <div className='col-lg-8 align-self-center'>
                              <Combobox
                                name='productSearchBox'
                                autoFocus={true}
                                autoSelectMatches={true}
                                hideCaret
                                hideEmptyPopup
                                data={prodArr}
                                dataKey='id'
                                textField='displayName'
                                placeholder='Search for a product'
                                value={value}
                                onChange={(e: any) => productSelected(e)}
                                filter='contains'
                                //onSelect={(e) => productSelected(e)}
                              />
                            </div>
                            <div className='col-md-5 align-self-center'>
                              <div className='row'>
                                {/* <div className=""> */}
                                <label className='col-lg-4 align-self-center form-label fw-bolder my-1'>
                                  Quantity
                                </label>
                                {/* </div> */}
                                <input
                                  type='number'
                                  className='col-lg-4 my-2'
                                  style={{
                                    border: '1px solid #e4e6ef',
                                    borderRadius: '4px',
                                    padding: '5px',
                                    height: '38px',
                                  }}
                                  placeholder='Quantity'
                                  name='quantity'
                                  min='1'
                                  value={quantity}
                                  onChange={(e: any) => setQuantity(e.target.value)}
                                />
                                <div className='col-lg-1 align-self-center my-2'>
                                  <button
                                    className='btn btn-primary btn-sm'
                                    type='button'
                                    onClick={() => addProductToInvoice()}
                                  >
                                    <i className='fas fa-plus'></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='col-md-12'>
                            <div className='table-responsive'>
                              <div className='display mb-4 dataTablesCard customer-list-table'>
                                <Table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                                  <thead>
                                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                      <th>Description/HSN</th>
                                      <th>Quantity</th>
                                      <th>Unit</th>
                                      <th className='text-end'>Rate</th>
                                      <th className='text-end'>Discount </th>
                                      <th className='text-end'>Taxable Amount</th>
                                      <th className='text-end'>Tax</th>
                                      <th className='text-end'>Total Amount</th>
                                      <th className='text-center'>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {rowsData && rowsData.length > 0 ? (
                                      rowsData.map((item: any, index: any) => (
                                        <tr key={index}>
                                          <td className='text-gray-600 fw-bold'>
                                            <div className='d-flex align-items-center'>
                                              <div>
                                                <p className='text-gray-600 text-hover-secondary mb-0'>
                                                  {' '}
                                                  {item.itemName}
                                                </p>
                                                <span className='text-gray-500 fs-6'>
                                                  {item.hsnSac}
                                                </span>
                                              </div>
                                            </div>
                                          </td>
                                          <td className='text-gray-600 fw-bold w-100px'>
                                            <input
                                              type='number'
                                              name='quantity'
                                              className='form-control '
                                              id={`qty_${item.id}`}
                                              value={item.quantity}
                                              onChange={(evnt) => handleChange(index, evnt)}
                                            />
                                          </td>
                                          <td className='text-gray-600 fw-bold'>{item.unit}</td>
                                          <td className='text-gray-600 fw-bold text-end w-100px'>
                                            <input
                                              type='number'
                                              name='price'
                                              className='form-control '
                                              id={`price_${item.id}`}
                                              value={item.price}
                                              onChange={(evnt) => handleChange(index, evnt)}
                                            />
                                          </td>
                                          <td className='text-gray-600 fw-bold text-end w-100px'>
                                            <input
                                              type='number'
                                              name='discount'
                                              className='form-control '
                                              id={`disc_${item.id}`}
                                              value={item.discount}
                                              onChange={(evnt) => handleChange(index, evnt)}
                                            />
                                          </td>
                                          <td className='text-gray-600 fw-bold text-end'>
                                            {' '}
                                            {(item.price * item.quantity - item.discount)?.toFixed(
                                              2
                                            )}
                                          </td>
                                          <td className='text-gray-600 fw-bold text-end'>
                                            {(
                                              ((item.price * item.quantity - item.discount) *
                                                item.taxPercent) /
                                              100
                                            ).toFixed(2)}{' '}
                                            (
                                            <span className='text-gray-600 fs-7'>
                                              {item.taxPercent}%
                                            </span>
                                            )
                                          </td>
                                          <td className='text-dark fw-bold text-end'>
                                            {(
                                              item.price * item.quantity -
                                              item.discount +
                                              ((item.price * item.quantity - item.discount) *
                                                item.taxPercent) /
                                                100
                                            )?.toFixed(2)}
                                            <br></br>
                                          </td>
                                          <td className='text-center'>
                                            <a href='#' onClick={() => deleteItem(index)}>
                                              <i className='bi bi-trash text-danger fs-5'></i>
                                            </a>
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan={8}>
                                          <div className='text-gray-600 d-flex text-center w-100 align-content-center justify-content-center'>
                                            No matching records found
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='separator my-10'></div>
                        {/* 4 */}
                        {subTotal - discTotal > 0 && (
                          <div className='row mb-lg-10 p-0'>
                            <div className='col-md-6 col-sm-12 '>
                              <label className='form-label'>Add Additional Tax</label>
                              <Table className='table align-middle table-row-dashed fs-6 px-5 dataTable no-footer'>
                                <tbody>
                                  {taxRowsData &&
                                    taxRowsData.length > 0 &&
                                    taxRowsData.map((item: any, index: any) => (
                                      <tr>
                                        <td className='w-250px'>
                                          <select
                                            className='form-control'
                                            name={`taxName`}
                                            id={`taxName_${index}`}
                                            onChange={(evnt) => handleTaxChange(index, evnt)}
                                          >
                                            <option value={0}>None</option>
                                            {taxArr &&
                                              taxArr.map((tax: any) => (
                                                <option value={tax.id}>{tax.taxName}</option>
                                              ))}
                                          </select>
                                        </td>
                                        <td>
                                          <input
                                            className='form-control'
                                            name='taxPercent'
                                            id={`taxPercent_${index}`}
                                            value={item.taxPercent}
                                          />
                                        </td>
                                        <td className='w-100px'>
                                          <a onClick={() => addTaxRow(index)}>
                                            {' '}
                                            <i className='las la-plus fs-2x'></i>
                                          </a>
                                          <a onClick={() => deleteTaxItem(index)} href='#'>
                                            {' '}
                                            <i className='las la-trash fs-2x text-danger me-5'></i>
                                          </a>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        )}
                        {/* 5 */}
                        <div className='row'>
                          <div className='col-md-6 col-sm-12 mb-2'>
                            <Accordion defaultActiveKey='0'>
                              <Accordion.Item eventKey='0'>
                                <Accordion.Header>Terms &amp; Conditions</Accordion.Header>
                                <Accordion.Body>
                                  <div className='card card-custom p-0 card-flush'>
                                    <div className='card-body'>
                                      <div
                                        dangerouslySetInnerHTML={{__html: formik.values.terms}}
                                      ></div>
                                    </div>
                                    <div className='card-footer text-end p-0'>
                                      <div>
                                        <button
                                          type='button'
                                          onClick={() => openTermsModal(formik.values.terms)}
                                          className='btn  text-primary btn-light btn-color-muted btn-active-light-primary px-4 py-2 me-4 w-100px'
                                        >
                                          <span>
                                            {' '}
                                            {formik.values.terms === '' ? 'Add' : 'Edit'}
                                          </span>
                                        </button>

                                        {formik.values.terms !== '' && (
                                          <button
                                            type='button'
                                            onClick={() => formik.setFieldValue('terms', '')}
                                            className='btn  text-danger btn-light btn-color-muted btn-active-light-danger px-4 py-2 w-100px'
                                          >
                                            <span>Remove</span>
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                          <div className='col-md-6 col-sm-12 mb-2'>
                            <Accordion defaultActiveKey='0'>
                              <Accordion.Item eventKey='0'>
                                <Accordion.Header>Notes</Accordion.Header>
                                <Accordion.Body>
                                  <div className='card card-custom p-0 card-flush'>
                                    <div className='card-body'>
                                      <div
                                        dangerouslySetInnerHTML={{__html: formik.values.notes}}
                                      ></div>
                                    </div>
                                    <div className='card-footer text-end p-0'>
                                      <a
                                        href='#'
                                        onClick={() => openNotesModal(formik.values.notes)}
                                        className='btn  text-primary btn-light btn-color-muted btn-active-light-primary px-4 py-2 me-4 w-100px'
                                      >
                                        <span>{formik.values.notes === '' ? 'Add' : 'Edit'}</span>
                                      </a>

                                      {formik.values.notes !== '' && (
                                        <a
                                          href='#'
                                          onClick={() => formik.setFieldValue('notes', '')}
                                          className='btn  text-danger btn-light btn-color-muted btn-active-light-danger px-4 py-2 w-100px'
                                        >
                                          <span>Remove</span>
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                        </div>
                      </div>
                      <div className='row mb-3 mt-5 form-group'>
                          <div className='col-md-3'>
                            <ToggleSwitch
                              name='isProforma'
                              label='Proforma'
                              checked={formik.values.isProforma}
                              onChange={(e: any) => toggleChange(e)}
                            />
                          </div>
                          <div className='col-md-3'>
                            <ToggleSwitch
                              name='isDraft'
                              label='Draft'
                              checked={formik.values.isDraft}
                              onChange={(e: any) => toggleDraft(e)}
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='offset-md-8 col-md-4 col-xs-12'>
                            <div className='form w-100  mb-3'>
                              <div className='card card-custom card-border border  bg-light-primary'>
                                <div className='card-body p-5'>
                                  {formik.status && (
                                    <div className='mb-lg-15 alert alert-secondary text-gray-700'>
                                      <div className='alert-text font-weight-bold'>
                                        {formik.status}
                                      </div>
                                    </div>
                                  )}
                                  <div className='table-responsive'>
                                    <table className='table align-middle table-condensed'>
                                      {/* begin::Table body */}
                                      <tbody>
                                        <tr>
                                          <td>
                                            <a
                                              href='#'
                                              className='text-gray-600  text-hover-primary  fs-6'
                                            >
                                              Sub Total
                                            </a>
                                          </td>
                                          <td className='text-end'>{subTotal.toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <a
                                              href='#'
                                              className='text-gray-600  text-hover-primary  fs-6'
                                            >
                                              Discount(-)
                                            </a>
                                          </td>
                                          <td className='text-end'>{discTotal?.toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <a
                                              href='#'
                                              className='text-gray-600  text-hover-primary  fs-6'
                                            >
                                              Taxable Amount
                                            </a>
                                          </td>
                                          <td className='text-end'>
                                            {(subTotal - discTotal)?.toFixed(2)}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <a
                                              href='#'
                                              className='text-gray-600  text-hover-primary  fs-6'
                                            >
                                              Tax Total
                                            </a>
                                          </td>
                                          <td className='text-end'>{taxTotal.toFixed(2)}</td>
                                        </tr>
                                        {addTaxTotal > 0 && (
                                          <tr>
                                            <td>
                                              <a
                                                href='#'
                                                className='text-gray-600  text-hover-primary  fs-6'
                                              >
                                                Additional Tax
                                              </a>
                                            </td>
                                            <td className='text-end'>{addTaxTotal?.toFixed(2)}</td>
                                          </tr>
                                        )}

                                        <tr>
                                          <td>
                                            <a
                                              href='#'
                                              className='text-dark fw-bold text-hover-primary fs-3'
                                            >
                                              Total
                                            </a>
                                          </td>
                                          <td className='text-end fw-bold  fs-4'>
                                            {total.toFixed(2)}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div className='card-footer'>
                                  {/* begin::Action */}

                                  <div className='text-center'>
                                    <button
                                      type='submit'
                                      id='kt_sign_in_submit'
                                      className='btn btn-lg btn-primary w-100 mb-5'
                                      disabled={
                                        formik.isSubmitting ||
                                        !formik.isValid ||
                                        rowsData.length === 0
                                      }
                                    >
                                      {!loading && (
                                        <span className='indicator-label'>
                                          {' '}
                                          <i className='fa-solid fa-floppy-disk fs-4 me-2'></i> Save
                                          Invoice
                                        </span>
                                      )}
                                      {loading && (
                                        <span
                                          className='indicator-progress'
                                          style={{display: 'inline'}}
                                        >
                                          Please wait...
                                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                      )}
                                    </button>
                                  </div>
                                  {/* end::Action */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                  </form>
                  {showCustomerForm && (
                    <AddCustomerShort
                      setShowCustomerForm={setShowCustomerForm}
                      afterUpdate={(data: any) => afterUpdate(data, formik.setFieldValue)}
                    />
                  )}
                  {showTerms && (
                    <EditTerms
                      setShowTerms={setShowTerms}
                      afterUpdateTerms={afterUpdateTerms}
                      data={terms}
                    />
                  )}
                  {showNotes && (
                    <EditNotes
                      setShowNotes={setShowNotes}
                      afterUpdateNotes={afterUpdateNotes}
                      data={notes}
                    />
                  )}
                  {showProductForm && (
                    <ProductEditModal
                      setShowProductForm={setShowProductForm}
                      afterUpdateProduct={afterUpdateProduct}
                      isService={false}
                    />
                  )}
                  {showInvPreFrom && (
                    <InvoicePrefixModal
                      setShowInvPreFrom={setShowInvPreFrom}
                      afterUpdateProduct={afterUpdateProduct}
                      isService={false}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
}