import React from "react";
import ReactPlayer from 'react-player'
import { accordionData } from "../../../../utils/content";
import Accordion from "./Accordion";

export function Faqs() {
    return (<>

        <section id="faqs" className="bg-white dark:bg-gray-900 my-lg-20 py-lg-20">
            <div className="max-w-screen-xl px-4 pb-8 mx-auto lg:pb-24 lg:px-6 ">
                <h2 className="mb-6 text-3xl font-extrabold tracking-tight text-center text-gray-900 lg:mb-8 lg:text-3xl dark:text-white">Frequently asked questions</h2>
                <div className="max-w-screen-md mx-auto">
                    <div className="text-lg">
                        <div className="accordion">
                            {accordionData.map(({ title, content }) => (
                                <Accordion title={title} content={content} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>)
}