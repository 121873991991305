import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { getSteps } from "../../../services/OnboardingService"
import { KTSVG } from "../../../_metronic/helpers";

export function OnboardingStepsPage() {
    const [steps, setSteps] = useState([]);
    const searchParams = {
        // pageNumber: 1,
        // pageSize: 10,
        // customerId: 128,
        orgId: 1
        // fromDate: "",
        // toDate: "",
        // filter: "",
        // status: 0,
    };

    const bindSteps = () => {
        getSteps(searchParams).then((response) => {
            const array = response.data.data;
            setSteps(array);

        }).catch((err) => {
            console.log(err)
        });;
    }

    useEffect(() => {
        setTimeout(() => {
            bindSteps();
        }, 700);

    }, []);

    return <div className="card card-custom card-flush mb-xl-10">
        <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">Get Started</span>
                <span className="text-muted mt-1 fw-semibold fs-7">Please complete all steps to be able to use portal effectively</span></h3>
            {/* <div className="card-toolbar">
                <button type="button" className="btn btn-sm btn-light">
                    Action
                </button>
            </div> */}
        </div>
        <div className="card-body py-5">
            {steps && steps.map((element: any, index: any) =>
                <div className="mb-5 min-h-175px border-bottom mt-5" key={index}>
                    <h5 className="">
                        <span className="badge badge-circle badge-secondary me-3">{index + 1}</span> {element.title}
                    </h5>
                    <p className=" pt-1">
                        {element.description}
                    </p>
                    <div className='flex-grow-1'>
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                        </div>

                        <div className='d-flex flex-wrap flex-stack'>
                            <div className='d-flex flex-column flex-grow-1 pe-8'>
                                <div className='d-flex my-4'>
                                    <Link to={element.link} className='btn btn-sm btn-secondary me-2 w-100px' id='kt_user_follow_button'>
                                        <KTSVG
                                            path='/media/icons/duotune/arrows/arr012.svg'
                                            className='svg-icon-3 d-none'
                                        />

                                        <span className='indicator-label'>View</span>
                                        <span className='indicator-progress'>
                                            Please wait...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    </Link>
                                    <Link
                                        to={element.addLink} 
                                        className='btn btn-sm btn-primary me-3 w-100px'
                                        data-bs-toggle='modal'
                                        data-bs-target='#kt_modal_offer_a_deal'
                                    >
                                        Add
                                    </Link>
                                </div>
                            </div>

                            {/* <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                                <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                                    <span className='fw-bold fs-6 text-gray-400'> Completion</span>
                                    <span className='fw-bolder fs-6'>50%</span>
                                </div>
                                <div className='h-5px mx-3 w-100 bg-light mb-3'>
                                    <div
                                        className='bg-success rounded h-5px'
                                        role='progressbar'
                                        style={{ width: '50%' }}
                                    ></div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            )}
        </div>
    </div>
}