export interface IInvoiceAdditionalTax  {
    id: number,
    taxId : number,
    taxName : string,
    taxAmount : number,
    taxPercent : number,
    invoiceId : number,
  }
  export const invoiceTaxInitVals : IInvoiceAdditionalTax = {
    id:0,
    taxName: "",
    taxId : 0,
    taxAmount: 0,
    taxPercent: 0,
    invoiceId: 0
  }

  export interface IInvoiceDiscount  {
    id: number,
    discountId : number,
    discountName : string,
    discountAmount : number,
    discountPercent : number,
    invoiceId : number,
  }
  export const invoiceDisInitVals : IInvoiceDiscount = {
    id:0,
    discountName: "",
    discountId : 0,
    discountAmount: 0,
    discountPercent: 0,
    invoiceId: 0
  }


  export interface IInvoice {
    id: number;
    orderNumber: string;
    orderId: number;
    assignedTo: number;
    table: string;
    // orderDate: Date;
    invoiceNumber: string;
    invoiceDate:string;
    invoiceDueDate: string;
    status: number;
    customerId: number;
    totalItems: number;
    billName: string;
    billAddress: string;
    billCity: string;
    billState: string;
    billPostalCode: string;
    shipName: string;
    shipAddress: string;
    shipCity: string;
    shipState: string;
    shipPostalCode: string;
    subAmount: number;
    taxAmount: number;
    totalAmount: number;
    amountInWords: string;
    description: string;
    terms: string;
    notes: string;
    isWithinState: boolean;
    igst: number;
    sgst: number;
    utgst: number;
    cgst: number;
    isUT: boolean;
    amountDue: number;
    isCancel: boolean;
    freightCharges: number;
    isDraft: boolean;
    totalFreightCharges: number;
    isSez: boolean | null;
    lutNumber: string;
    lutAppliedDate: Date | null;
    lutExpiryDate: Date | null;
    branchId: number;
    serialNumber: number;
    custBillBranchId: number;
    custShipBranchId: number;
    companyGstin: string;
    customerGstin: string;
    customerPan: string;
    customerEmail: string;
    customerContactNo: string;
    isProforma: boolean;
    paymentId: number | null;
    discount: number | null;
    addDiscount:number|null;
    roundOff: number | null;
    addTax: number | null;
    companyId : number;
  companyBranchId: number;
  companyName: string;
  companyAddress: string;
  companyCity: string;
  companyState: string;
  companyPostalCode: string;
  invoiceItems: IInvoiceItems[];
  invoiceAdditionalTaxes: IInvoiceAdditionalTax[];
  }
  
  export const initialInvoice: IInvoice = {
    id: 0,
    orderNumber: "",
    orderId: 0,
    assignedTo: 0,
    table: "",
    // orderDate: new Date(),
    invoiceNumber: "",
    invoiceDate: "",
    invoiceDueDate: "",
    status: 0,
    customerId: 0,
    totalItems: 0,
    billName: "",
    billAddress: "",
    billCity: "",
    billState: "",
    billPostalCode: "",
    shipName: "",
    shipAddress: "",
    shipCity: "",
    shipState: "",
    shipPostalCode: "",
    subAmount: 0,
    taxAmount: 0,
    totalAmount: 0,
    amountInWords: "",
    description: "",
    terms: "",
    notes: "",
    isWithinState: false,
    igst: 0,
    sgst: 0,
    utgst: 0,
    cgst: 0,
    isUT: false,
    amountDue: 0,
    isCancel: false,
    freightCharges: 0,
    isDraft: false,
    totalFreightCharges: 0,
    isSez: null,
    lutNumber: "",
    lutAppliedDate: null,
    lutExpiryDate: null,
    branchId: 0,
    serialNumber: 0,
    custBillBranchId: 0,
    custShipBranchId: 0,
    companyGstin: "",
    customerGstin: "",
    customerPan: "",
    customerEmail: "",
    customerContactNo: "",
    isProforma: false,
    paymentId: null,
    discount: null,
    addDiscount:null,
    roundOff: null,
    addTax: null,
    companyBranchId: 0,
    companyName: "",
    companyAddress: "",
    companyCity: "",
    companyState: "",
    companyPostalCode: "",
    companyId: 0,
    invoiceItems: [],
    invoiceAdditionalTaxes: []
  };
  
  export interface IInvoiceItems {
    id: number;
    invoiceId: number;
    itemId: number;
    itemName: string;
    itemDesc: string;
    hsnSac: string;
    price?: number;
    unit: string;
    grossAmount: number;
    amount: number;
    quantity: number;
    discountPercent?: number;
    discount?: number;
    taxPercent: number;
    taxAmount: number;
    paymentId?: number;
    freightCharges: number;
    }
    export const initialInvoiceItems: IInvoiceItems = {
      id: 0,
      invoiceId: 0,
      itemId: 0,
      itemName: "",
      itemDesc: "",
      hsnSac: "",
      price: 0,
      unit: "",
      grossAmount: 0,
      amount: 0,
      quantity: 0,
      discountPercent: 0,
      discount: 0,
      taxPercent: 0,
      taxAmount: 0,
      paymentId: 0,
      freightCharges: 0,
    };
    export interface IInvoiceInputDto {
      id?: number;
      billName?: string;
      billAddress?: string;
      billCity?: string;
      billState?: string;
      billPostalCode?: string;
      shipAddress?: string;
      shipName?: string;
      shipCity?: string;
      shipState?: string;
      shipPostalCode?: string;
      invoiceNumber?: string;
      invoiceDate?: Date | null;
      invoiceDueDate?:Date | null;
      customerGstin?: string;
      customerPan?: string;
      customerEmail?: string;
      customerContactNo?: string;
      subAmount?: number | null;
      totalAmount?: number | null;
      taxAmount?: number | null;
      totalFreightCharges?: number | null;
      companyName?: string | null;
      companyAddress?: string | null;
      companyCity?: string | null;
      companyState?: string | null;
      companyPostalCode?: string | null;
      companyGstin?: string | null;
      }
      export const invoiceInputDtoInitialValues: IInvoiceInputDto = {
        id: 0,
        billName: '',
        billAddress: '',
        billCity: '',
        billState: '',
        billPostalCode: '',
        shipAddress: '',
        shipName: '',
        shipCity: '',
        shipState: '',
        shipPostalCode: '',
        invoiceNumber: '',
        invoiceDate: new Date(),
        invoiceDueDate:new Date(),
        customerGstin: '',
        customerPan: '',
        customerEmail: '',
        customerContactNo: '',
        subAmount: 0,
        totalAmount: 0,
        taxAmount: 0,
        totalFreightCharges: 0,
        companyName: '',
        companyAddress: '',
        companyCity: '',
        companyState: '',
        companyPostalCode: '',
        companyGstin: '',
      }
