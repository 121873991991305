import { PricingInner } from "../../app/pages/PricingInner";

export function TrialOver(props: any) {
    return <>
        <div className="row">
            <div className="col-xs-12 col-md-12">
                <div className="notice d-flex bg-light-warning rounded border-warning-light border p-10 mb-5">
                    <i className="bi bi-info-circle-fill fs-2x text-warning me-4"></i>
                    <div className="d-flex flex-stack flex-grow-1" title='Info'>
                        <div className="">
                            <div className="fs-7 text-gray-700">
                                <p className='mb-3 fs-5'>
                                    We hope you have enjoyed your trial period with <strong>Amber Bills</strong>. Unfortunately, your trial period has now come to an end.
                                </p>
                                <p className='mb-1 fs-5'>
                                    Once your trial period has ended, you may only be able to access limited features of our service. To continue using all the features and functionality, we invite you to sign up for a subscription.
                                    {/* If you would like to continue using this service, we invite you to sign up for a subscription. With a subscription, you will have access to all the features under the given subscription plan. */}
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
                <PricingInner />
            </div>
        </div>
    </>
}