import React from 'react'
interface Props {
  message: string
}

export default function InfoMessage(props: Props) {
  return (
    <div
      className='d-flex align-items-center alert alert-custom alert-notice alert-light-primary fade show'
      role='alert'
    >
      <div className='alert-icon'>
        <i className='fas fa-info-circle'></i>
      </div>
      <div className='alert-text mx-2 text-gray-600'>{props.message} </div>
    </div>
  )
}
