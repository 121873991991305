import axiosInstance from './AxiosInstance';

export function GetStock(params) {
    return axiosInstance.post(`stockadjust/getstock`, params);
}
export function StockDelete(id) {
    return axiosInstance.post(`stockadjust/delete`, id);
}
export function GetAllStock() {
    return axiosInstance.post(`stockadjust/getall`);
}
export function AdjustStock(params) {
    return axiosInstance.post(`stockadjust/adjuststock`,params);
}
export function getProductPics(id) {
    return axiosInstance.get(`shop/product/pics/${id}`);
}
export function updateDefaultPic(id,mappingId) {
    return axiosInstance.put(`products/default/${id}/${mappingId}`);
}