import * as yup from 'yup'

export interface IAdditionalTaxModel {
  id: number
  taxName: string
  taxPercent: number
  taxAmount: number
  tableName: string
  recordId: number
}

export const addtaxInitValues: IAdditionalTaxModel = {
  id: 0,
  taxName: '',
  taxPercent: 0,
  taxAmount: 0,
  tableName: '',
  recordId: 0,
}
export interface IAddTaxModel {
  id:any
  taxName: string
  taxValue: number
  isAdditional: boolean
}
export const addTaxInitValues :  IAddTaxModel = {
  id:0,
  taxName: '',
  taxValue: 0,
  isAdditional: false,
}
export interface IAddDisModel {
  id:any
  discountName: string
  discountValue: number
  isPromo: boolean
}
export const addDisInitValues :  IAddDisModel = {
  id:0,
  discountName: '',
  discountValue: 0,
  isPromo: false,
}
export const addtaxSchema = yup.object().shape({
  taxName: yup.string().required('Tax Name is required'),
  isAdditionalTax: yup.boolean(),
})