/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getTokenLocalStorage } from '../../../../services/AuthServics'
import { getAccountInfo } from '../../../../services/account/AccountService'

export function AsideMenuMain() {
  const intl = useIntl()

  const [loginUser, setLoginUser] = useState<any>();
  const [accountInfo, setAccountInfo] = useState<any>();

  const bindAccountInfo = () => {

    setTimeout(() => {
      getAccountInfo()
        .then((res: any) => {
          let _data = res.data.data;
          setAccountInfo(_data);
          console.log(_data);

        })
        .catch(() => {

        }).finally(() => {

        });
    }, 500);
  }

  useEffect(() => {
    setTimeout(() => {
      const aware_user = getTokenLocalStorage();
      // console.log(aware_user);
      if (aware_user) {
        setLoginUser(aware_user);
        bindAccountInfo();
      }
    }, 100)

  }, []);

  return (
    <> {loginUser && loginUser.roleId != 1 && (<>
      <AsideMenuItem
        to='/dashboard'
        icon={""}
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />


      {/* Sales */}
      <AsideMenuItemWithSub to={''} title='Sale' hasBullet={false}>
        <AsideMenuItem to='/invoices' title='Invoices' hasBullet={true} />
        <AsideMenuItem to='/customers' title='Customers' hasBullet={true} />
        <AsideMenuItem to='/payments' title='Payments' hasBullet={true} />
        {/* <AsideMenuItem to='/credit-notes' title='Credit Notes' hasBullet={true} />
          <AsideMenuItem to='/debit-notes' title='Debit Notes' hasBullet={true} />
          <AsideMenuItem to='/deliverychallan' title='Delivery Challan ' hasBullet={true} /> */}
        <AsideMenuItem to='/quotations' title='Quotations' hasBullet={true} />
      </AsideMenuItemWithSub>

      {/* Purchase */}
      <AsideMenuItemWithSub to={''} title='Purchase' hasBullet={false}>
        <AsideMenuItem to='/purchase-orders' title='Purchase Orders' hasBullet={true} />
        <AsideMenuItem to='/suppliers' title='Suppliers' hasBullet={true} />
      </AsideMenuItemWithSub>

      {/* Catalog */}
      <AsideMenuItemWithSub to={''} title='Catalog' hasBullet={false}>
        <AsideMenuItem to='/products' title='Products' hasBullet={true} />
        <AsideMenuItem to='/services' title='Services' hasBullet={true} />
        <AsideMenuItem to='/category' title='Categories' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to='/reports'
        icon={""}
        title='Reports'
        fontIcon='bi-app-indicator'
      />

      {/* Settings */}
      <AsideMenuItemWithSub to={''} title='Settings' hasBullet={false}>
        <AsideMenuItem to='/taxes' title='Taxes' hasBullet={true} />
        <AsideMenuItem to='/document-settings' title='Document settings' hasBullet={true} />
        <AsideMenuItem to='/terms' title='Terms &amp; Conditions' hasBullet={true} />
        <AsideMenuItem to='/notes' title='Notes' hasBullet={true} />
        <AsideMenuItem to='/subscription-history' title='Subscription History' hasBullet={true} />
      </AsideMenuItemWithSub>



    </>

    )
    }

      {loginUser && loginUser.roleId === 1 &&
        (<>
          <AsideMenuItem
            to='/dashboard'
            icon={""}
            title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
            fontIcon='bi-app-indicator'
          />
          <AsideMenuItem to='/organization' title='Organizations' hasBullet={true} />
          <AsideMenuItem to='/bugs-report' title='Bugs reported' hasBullet={true} />
        </>)
      }



      {/* <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/arrows/arr001.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub> */}
      {/* <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/arrows/arr001.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/arrows/arr001.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub> */}
      {/* <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/arrows/arr001.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/arrows/arr001.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub> */}
      {/* <AsideMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title='User management'
        fontIcon='bi-layers'
      />
       

     */}
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      {accountInfo && accountInfo.subsPlan === 'Trial' &&
        accountInfo.status === null && (
          <div>
            <div className="alert alert-dismissible bg-info py-7 mb-10">
              <div className='pl-5 mx-0'>
                <div className="mb-5 fs-7 text-white" >
                  <span style={{ fontWeight: '400' }}>
                    Don't miss out on the exclusive features and benefits of our subscription.
                  </span>
                </div>
                <div>
                  <Link to={'/pricing'} className='btn btn-light-info w-100'>
                    Buy now <i className="fa-solid fa-rocket"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      {accountInfo && accountInfo.subsPlan === 'Trial' &&
        (accountInfo.status && accountInfo.status === "pending") && (
          <div className="alert alert-dismissible bg-info py-7 mb-10">
            <div className='pl-5 mx-0'>
              <div className="mb-5 fs-7 text-white text-center" >
                <span style={{ fontWeight: '500' }} >
                  Your Subscription is pending. Please wait for the approval.
                  <br />
                  <Link to={'/subscription-history'} className='btn btn-light-info w-120 mt-2'>
                    Manage Subscription 
                  </Link>
                </span>
              </div>
              <div>
                {/* <Link to={'/pricing'} className='btn btn-light-info w-100'>
              Upgrade to Pro <i className="fa-solid fa-rocket"></i>
            </Link> */}
              </div>
            </div>
          </div>
        )}
      {accountInfo && accountInfo.subsPlan === 'Trial' &&
        (accountInfo.status && accountInfo.status === "rejected") && (
          <div>
            <div className="alert alert-dismissible bg-info py-7 mb-10">
              <div className='pl-5 mx-0'>
                <div className="mb-5 fs-7 text-white" >
                  <span style={{ fontWeight: '400' }}>
                    Don't miss out on the exclusive features and benefits of our premium plan.
                  </span>
                </div>
                <div>
                  <Link to={'/pricing'} className='btn btn-light-info w-100'>
                    Upgrade to Pro <i className="fa-solid fa-rocket"></i>
                  </Link>
                  <Link to={'/subscription-history'} className='btn btn-light-info w-100 mt-2'>
                    View History <i className="fa-solid fa-rocket"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}

      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={'/release-notes'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Release notes {process.env.REACT_APP_VERSION}</span>
        </a>
      </div>
    </>
  )
}
