
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { DebitNoteAddModel } from './d-nPartials/DebitNoteAddModel';


export  function DebitNoteAdd() {
    const navigate = useNavigate();
    const setItemIdForUpdate = (val: any) => {
        navigate("/debit-notes");
    }

    return <DebitNoteAddModel setItemIdForUpdate={setItemIdForUpdate} />
}
