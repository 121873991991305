import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { createPortal } from 'react-dom'
import { KTSVG } from '../../../../_metronic/helpers'
import { DCBillBy, DCShipTo } from '../../interface/IInvoiceTypes/IdeliveryChallan'
interface DCData {
    billBy: DCBillBy,
    shipTo: DCShipTo
  }
type Props = {
    type: string;
    show: boolean;
    handleClose: () => void;
    billBy: DCBillBy;
    billTo: DCShipTo;
    setBillBy: (value: any) => void;
    setBillTo: (value: any) => void;
    initialData: DCData;
  };
  
const modalsRoot = document.getElementById('root-modals') || document.body

export default function AddressForm({ show, handleClose, billBy, billTo, type, setBillBy, setBillTo, initialData }: Props) {

    const resetForm = (type: string) => {
        if (type === 'billBy') {
            setBillBy(initialData.billBy);
        } else {
            setBillTo(initialData.shipTo);
        }
    }
    
    const setInitialApiValues = (type: string) => {
        if (type === 'shipTo') {
            setBillBy(initialData.billBy);
        } else {
            setBillTo(initialData.shipTo);
        }
    }
    switch (type) {
        case 'billBy':
            return createPortal(
                <Modal
                    id='kt_modal_create_app'
                    tabIndex={-1}
                    aria-hidden='true'
                    dialogClassName='modal-dialog modal-dialog-centered mw-900px'
                    show={show}
                    onHide={handleClose}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h3 >Bill By </h3>
                        </Modal.Title>
                    </Modal.Header>
                    <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Bill Name</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Enter Supplier  Name'
                                        value={billBy.billName}
                                        onChange={(e) => setBillBy({ ...billBy, billName: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Address Line 1</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Enter Address Line 1'
                                        value={billBy.billAddress}
                                        onChange={(e) => setBillBy({ ...billBy, billAddress: e.target.value })}
                                    />
                                </div>
                            </div>
                            {/* <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Address Line 2</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Enter Address Line 2'
                                        value={billBy.addressLine2}
                                        onChange={(e) => setBillBy({ ...billBy, addressLine2: e.target.value })}
                                    />
                                </div>
                            </div> */}
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>City</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Enter City'
                                        value={billBy.billCity}
                                        onChange={(e) => setBillBy({ ...billBy, billCity: e.target.value })}
                                    />
                                </div>
                            </div>
                          
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Postal Code</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Enter Postal Code'
                                        value={billBy.billPostalCode}
                                        onChange={(e) => setBillBy({ ...billBy, billPostalCode: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>GSTIN</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Enter GSTIN'
                                        value={billBy.companyGstin}
                                        onChange={(e) => setBillBy({ ...billBy, companyGstin: e.target.value })}
                                    />
                                </div>
                            </div>
                            {/* <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Mobile</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Enter Mobile'
                                        value={billBy.billAddress}
                                        onChange={(e) => setBillBy({ ...billBy, billAddress: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Email</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Enter Email'
                                        value={billBy.supplierEmail}
                                        onChange={(e) => setBillBy({ ...billBy, supplierEmail: e.target.value })}
                                    />
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <Modal.Footer className='row justify-content-between'>
                        <div className='col-lg-4'>
                        <button type='button' className='px-10 btn btn-primary font-weight-bold' 
                        onClick={()=>{setInitialApiValues("billBy")}}>
                            Initial
                        </button>
                        <button type='button' className='px-10 btn btn-primary font-weight-bold'
                            onClick={() => { resetForm("billBy") }}>
                            Reset All
                        </button>
                        </div>
                        <div className='col-lg-4'>
                        <button type='button' className='px-10 btn btn-primary font-weight-bold'
                            onClick={handleClose}>
                            Close
                        </button>
                        <button type='button' className='px-10 p-3 btn btn-primary font-weight-bold'
                            onClick={handleClose}>
                            Save changes
                        </button>
                        </div>
                    </Modal.Footer>
                </Modal>,
                modalsRoot
            )
        case 'billTo':
            return createPortal(
                <Modal id='kt_modal_create_app'
                    tabIndex={-1}
                    aria-hidden='true'
                    dialogClassName='modal-dialog modal-dialog-centered mw-900px'
                    show={show}
                    onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Bill To</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Customer Name</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Enter Customer Name'
                                        value={billTo.shipName}
                                        onChange={(e) => setBillTo({ ...billTo, shipName: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Address</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Enter Address'
                                        value={billTo.shipAddress}
                                        onChange={(e) => setBillTo({ ...billTo, shipAddress: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>City</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Enter City'
                                        value={billTo.shipCity}
                                        onChange={(e) => setBillTo({ ...billTo, shipCity: e.target.value })}
                                    />
                                </div>
                            </div>
                        
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Postal Code</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Enter Pin'
                                        value={billTo.shipPostalCode}
                                        onChange={(e) => setBillTo({ ...billTo, shipPostalCode: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>GSTIN</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Enter GSTIN'
                                        value={billTo.customerGstin??""}
                                        onChange={(e) => setBillTo({ ...billTo, customerGstin: e.target.value })}
                                    />
                                </div>
                            </div>
                            
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Email</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Enter Email'
                                        value={billTo.customerEmail??""}
                                        onChange={(e) => setBillTo({ ...billTo, customerEmail: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='row justify-content-between'>
                        <div className='col-lg-4'>
                        <button type='button' className='px-10 btn btn-primary font-weight-bold' onClick={()=>{
                            setInitialApiValues("billTo")
                        }}>
                            Initial
                        </button>
                        <button type='button' className='px-10 btn btn-primary font-weight-bold'
                            onClick={() => { resetForm("billTo") }}>
                            Reset All
                        </button>
                        </div>
                        <div className='col-lg-4'>
                        <button type='button' className='px-10 btn btn-primary font-weight-bold'
                            onClick={handleClose}>
                            Close
                        </button>
                        <button type='button' className='px-10 p-3 btn btn-primary font-weight-bold'
                            onClick={handleClose}>
                            Save changes
                        </button>
                        </div>
                    </Modal.Footer>
                </Modal>,
                modalsRoot
            )
        default:
            return null
    }
}
