
import clsx from 'clsx';
import React from 'react'
import "./Switch.css";
import { useField } from 'formik';
function Switchnew({ ...props }) {
    const [field, meta, helpers] = useField(props.name);
    const { setValue } = helpers;
    const { value } = meta;
    const isSelected = (v: any) => (v === value ? 'selected' : '');

    return (
        <div>
            <label className='switch'>
                <input
                    type='checkbox'
                    //{...field}
                    checked={value}
                    {...props}
                />
                <span className="slider">

                </span>
            </label>
        </div>
    )
}
export default Switchnew;


export const RadioButtonnew = ({ ...props }) => {
    const { leftLabel, rightLabel, label, name, options, ...rest } = props;
    return (
        <div className='form-check form-check-custom form-check-solid'>
            <div className='container-fluid'>
                <span className='col-5 text-right'>{leftLabel}</span>
                {" "}
                <label className="switch">
                    <input type="checkbox" name={name} {...rest} />
                    {label}
                    <span className="slider round"></span>
                </label>
                {" "}
                <span className='col-5 text-left'>{rightLabel}</span>
            </div>
        </div>
    )
}
export const ToggleSwitchnew = (props:any) => {
    const { label } = props;

    return (
        <div className="container">
            
            <div className="toggle-switch">
                <input
                    type="checkbox"
                    className="checkbox"
                    name={label}
                    id={label}
                    onChange={props.onChange}
                />
                <label className="label" htmlFor={label}>
                    <span className="inner" />
                    <span className="switch" />
                </label>
            </div>
        </div>
    );
};
export const ValidationFieldNew = (props: any) => {
    const { formik, className, isRequired, type, ...rest } = props;
    // check if the type is Date 
    // if yes then use the datepicker
    // else use the normal input field

    return (
        <div className={className}>
            
            {isRequired ? <span className='text-danger'>&nbsp;*</span> : null}
            <input
                {...rest}
                placeholder={props.placeholder}
                {...formik.getFieldProps(props.name)}
                type={type}
                name={props.name}
                className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched[props.name] && formik.errors[props.name] },
                    {
                        'is-valid': formik.touched[props.name] && !formik.errors[props.name],
                    }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting}
            />
            {formik.touched[props.name] && formik.errors[props.name] && (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                        <span role='alert'>{formik.errors[props.name]}</span>
                    </div>
                </div>
            )}
        </div>
    );
}




export const ValidationSelectNew = (props: any) => {
    
   const { formik, className, isRequired, name, placeholder, ...rest } = props;
    return (
        <div className={className}>
            
            {isRequired ? <span className='text-danger'>&nbsp;*</span> : null}
            <select
                {...rest}
                placeholder={placeholder}
                {...formik.getFieldProps(name)}
                name={name}
                className={clsx(
                    'form-select form-select-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched[name] && formik.errors[name] },
                    { 'is-valid': formik.touched[name] && !formik.errors[name] },
                )}
                autoComplete='on'
                disabled={formik.isSubmitting}
            >
                  


                {props.options.map((item: any) => (
                    <option key={item.id} value={item.id}>
                        {item.name}
                    </option>
                ))}
            </select>
            {formik.touched[name] && formik.errors[name] && (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                        <span role='alert'>{formik.errors[name]}</span>
                    </div>
                </div>
            )}
        </div>
    );
}
