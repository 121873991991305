import React from "react";

export function ReleaseNotes() {
    return <>
        <div className='card mb-5 mb-xl-10 p-xl-10'>
            <div className='card-body pt-9 pb-0'>
                <div className='mb-xl-20'>
                    <h3 className="fs-3 text-gray-800 fw-bolder mb-3 ms-4">Amber Bills v1.0.1 - Jun 25, 2023</h3>
                    <div className="mb-5 ms-4">
                        <h3 className="fs-6 fw-bolder mb-1">
                            Updates:</h3><ul className="my-0 py-0">
                            <li className="py-2">Point of sale feature introduced for ease-of-use of customers. It makes invoice generation easier and faster. </li>
                        </ul>
                    </div>
                </div>

                <div className='mb-xl-20'>
                    <h3 className="fs-3 text-gray-800 fw-bolder mb-3 ms-4">Amber Bills v1.0.0 - Mar 29, 2023</h3>
                    <div className="mb-5 ms-4">
                        <h3 className="fs-6 fw-bolder mb-1">Updates:</h3>
                        <ul className="my-0 py-0">
                            <li className="py-2 fs-6">Amber Bills launched.</li>
                        </ul>
                    </div>
                </div>

                {/* <div className='mb-xl-20'>
                    <h3 className="fs-3 text-gray-800 fw-bolder mb-0 ms-4">Amber Bills v1.0.0 - Nov X, 2022</h3>
                    <div className="mb-5">
                        <h3 className="fs-6 fw-bolder mb-1">
                            Updates:</h3><ul className="my-0 py-0">
                            <li className="py-2">Updated documentations for the Skeleton version creation</li>
                            <li className="py-2">Updated documentations for CKEditor integration</li>
                        </ul>
                    </div>
                </div> */}
            </div>
        </div>
    </>
}