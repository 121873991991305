import React, { useEffect, useState, Fragment } from 'react';
import { Link } from "react-router-dom";
import { Table } from 'react-bootstrap';
import { IConfirmModel } from '../../../_models/confirm-model';
import { PAGE_SIZE } from '../../../_shared';
import { ConfirmBox } from '../../../lib/ConfirmBox';
import { KTSVG } from '../../../_metronic/helpers';
import { NoDataFound } from '../../../lib/NoData';
import Pagination from '../../../lib/Pagination/Pagination';
import { MenuComponent } from '../../../_metronic/assets/ts/components';
import { GetStock } from '../../../services/ProductService';


export function ListStockAdjust(props: any) {

    const [data, setData] = React.useState([]);
    const [currentTableData, setCurrentTableData] = React.useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = React.useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [showFup, setShowFup] = useState<boolean>(false);
    const [confirmModel, setConfirmModel] = useState<IConfirmModel>();


    const initialValues = {
        id: 0,
        productId: props.data.productId,
        stock: 0,
        operation: 0,
        companyId: 0,
        remark: "",


    }
    const paginate = (pageNumber: any) => {
        setCurrentPage(pageNumber);
        const firstPageIndex = (pageNumber - 1) * PAGE_SIZE;
        const lastPageIndex = firstPageIndex + PAGE_SIZE;
        let _currentTableData = data.slice(firstPageIndex, lastPageIndex);
        setCurrentTableData(_currentTableData);
    };

    const bindGrid = (values: any) => {
        setIsLoading(true);
        setTimeout(() => {
            GetStock(values)
                .then((res) => {
                    let _data = res.data.data;

                    const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
                    const lastPageIndex = firstPageIndex + PAGE_SIZE;
                    let _currentTableData = _data.slice(firstPageIndex, lastPageIndex);

                    setData(_data);
                    setTotalRows(_data.length);
                    if (_data.length > 0) {
                        setCurrentTableData(_currentTableData);

                    }
                })
                .catch(() => {
                    setCurrentTableData([]);
                }).finally(() => {
                    setIsLoading(false);
                });
        }, 1000);
    };

    const filterData = () => {

    }

    const resetData = () => {

    }

    useEffect(() => {
        MenuComponent.reinitialization();
        bindGrid(initialValues);
    }, []);

    return (<>
        {/* Filter space start */}
        <div className='row mt-5'>
            <div className="col-xl-12 col-md-12">
                <div className="card ">
                    <div className='card-header border-bottom'>
                        <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bolder fs-3 mb-1'>Stock adjustments</span>
                        </h3>
                    </div>
                    <div className="card-body">

                        <div className="table-responsive">
                            <div className="display mb-4 dataTablesCard Lead-list-table">
                                <Table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                                    <thead>
                                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                            <th>Stock Adjusted</th>
                                            <th>Operation</th>
                                            <th>By</th>

                                            <th>Remarks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentTableData &&
                                            currentTableData.length > 0 ?
                                            currentTableData.map((item: any, index: any) =>
                                                <tr key={index}>
                                                    <td className='fw-bold'>{item.stock}</td>

                                                    <td>{item.operation > 0 ? <span className='badge badge-light-danger'>Reduced</span> : <span className='badge badge-light-success'>Added</span>}</td>
                                                    <td>{item.firstName} {item.lastName}</td>
                                                    <td>{item.remark}</td>
                                                </tr>
                                            ) : (<tr>
                                                <td colSpan={4}>
                                                    <NoDataFound setIsLoading={setIsLoading} />
                                                </td>
                                            </tr>

                                            )
                                        }

                                    </tbody>
                                </Table>
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={totalRows}
                                    pageSize={PAGE_SIZE}
                                    onPageChange={(page: any) => paginate(page)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {showFup}
    </>)
}