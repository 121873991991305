import React from "react";
import ReactPlayer from 'react-player'

export function FeaturesMain() {
    return (<>


        <section id="features" className="bg-gray-50 dark:bg-gray-800 mt-lg-20">
            <div className="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
                {/* <!-- Row --> */}
                <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
                    <div className="text-gray-500 sm:text-lg ">
                        <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">Intuitive user interface designed for ease of use</h2>
                        <p className="mb-8 font-light lg:text-xl">
                            With our innovative technology and user-friendly interface, <strong>Amber Bills</strong> is designed to make your life easier and more efficient than ever before.</p>
                        {/* <!-- List --> */}
                        <ul role="list" className="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
                            <li className="flex space-x-3">
                                {/* <!-- Icon --> */}
                                <svg className="flex-shrink-0 w-5 h-5 text-purple-700 dark:text-purple-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                                <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">Easy and customizable invoice creation with pre-built templates</span>
                            </li>
                            <li className="flex space-x-3">
                                {/* <!-- Icon --> */}
                                <svg className="flex-shrink-0 w-5 h-5 text-purple-700 dark:text-purple-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                                <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">Cloud-based solution for easy access from anywhere, anytime</span>
                            </li>
                            <li className="flex space-x-3">
                                {/* <!-- Icon --> */}
                                <svg className="flex-shrink-0 w-5 h-5 text-purple-700 dark:text-purple-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                                <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">Robust data analytics and reporting capabilities for valuable insights</span>
                            </li>
                        </ul>
                        <p className="mb-8 font-light lg:text-xl">And the best part? Our dedicated customer support team is here to provide exceptional assistance and ensure that you get the most out of our product.</p>
                    </div>
                    <img className="w-full mb-4 rounded-lg lg:mb-0 lg:flex" src="/media/images/feature-1.png" alt="dashboard feature image" />
                </div>
                {/* <!-- Row --> */}
                <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
                    <img className="w-full mb-4 rounded-lg lg:mb-0 lg:flex" src="/media/misc/sales2.png" alt="feature image 2" />
                    <div className="text-gray-500 sm:text-lg ">
                        <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">Unlock the potential</h2>
                        <p className="mb-8 font-light lg:text-xl">
                            Whether you're a small business owner or a large enterprise, <strong>Amber Bills</strong> can help you streamline your processes, improve productivity, and achieve your business goals.
                        </p>
                        {/* <!-- List --> */}
                        <ul role="list" className="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
                            <li className="flex space-x-3">
                                {/* <!-- Icon --> */}
                                <svg className="flex-shrink-0 w-5 h-5 text-purple-700 dark:text-purple-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                                <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                                    Automated workflows and task management for increased productivity
                                </span>
                            </li>
                            <li className="flex space-x-3">
                                {/* <!-- Icon --> */}
                                <svg className="flex-shrink-0 w-5 h-5 text-purple-700 dark:text-purple-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                                <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">Scalable solution that can grow with your business needs</span>
                            </li>
                            <li className="flex space-x-3">
                                {/* <!-- Icon --> */}
                                <svg className="flex-shrink-0 w-5 h-5 text-purple-700 dark:text-purple-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                                <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">Advanced security measures to protect your data and ensure compliance</span>
                            </li>
                            <li className="flex space-x-3">
                                {/* <!-- Icon --> */}
                                <svg className="flex-shrink-0 w-5 h-5 text-purple-700 dark:text-purple-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                                <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">Seamless integration with popular third-party tools and services</span>
                            </li>
                            <li className="flex space-x-3">
                                {/* <!-- Icon --> */}
                                <svg className="flex-shrink-0 w-5 h-5 text-purple-700 dark:text-purple-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                                <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">Dedicated customer support team to provide assistance and answer any questions</span>
                            </li>
                        </ul>
                        <p className="font-light lg:text-xl">With capabilities including GST invoicing, invoice sharing, reporting and many more, it is a complete billing management software.</p>
                    </div>
                </div>
            </div>
        </section>

        {/*begin::Projects Section*/}
        <div className="mb-lg-n15 position-relative z-index-2 d-none">
            {/*begin::Container*/}
            <div className="container-fluid">
                {/*begin::Card*/}
                <div className="card" style={{ filter: "drop-shadow(0px 0px 40px rgba(68, 81, 96, 0.08))" }}>
                    {/*begin::Card body*/}
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                {/*begin::Heading*/}
                                <div className="text-justify mb-17">
                                    {/*begin::Title*/}
                                    <h3 className="fs-2hx text-dark mb-5" id="online-invoicing-software" data-kt-scroll-offset="{default: 100, lg: 150}">Online Invoicing Software</h3>
                                    {/*end::Title*/}
                                    {/*begin::Text*/}
                                    <div className="fs-5 fw-semibold">
                                        <p className="mb-2">With capabilities including GST invoicing, invoice sharing, reporting and many more, it is a complete billing management software.
                                        </p>
                                    </div>
                                    {/*end::Text*/}
                                </div>
                                {/*end::Heading*/}
                                <div >
                                    <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUM-U' width="540px" />
                                </div>

                            </div>
                            <div className="col-md-5 offset-1">
                                {/* 1 */}
                                <div className="row mb-lg-20">
                                    <div className="col-md-3" >
                                        <img src="media/illustrations/dozzy-1/6.png" className="mh-125px mb-9" alt="" />
                                    </div>
                                    <div className="col-md-8 offset-1" >
                                        {/*begin::Heading*/}
                                        <div className="d-flex flex-start mt-5">
                                            {/*begin::Badge*/}
                                            <span className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">1</span>
                                            {/*end::Badge*/}
                                            {/*begin::Title*/}
                                            <div className="fs-5 fs-lg-3 fw-bold text-dark">Reliable, easy-to-use</div>
                                            {/*end::Title*/}
                                        </div>
                                        {/*end::H eading*/}
                                        {/*begin::Description*/}
                                        <div className=" fs-6  mt-3"> All sorts of users may simply and quickly utilise
                                            Amber Bills since it is made to be an easy-to-use business accounting system.</div>
                                        {/*end::Description*/}
                                    </div>
                                </div>
                                {/* 2 */}
                                <div className="row mb-lg-20">
                                    <div className="col-md-3" >
                                        <img src="media/illustrations/dozzy-1/4.png" className="mh-125px mb-9" alt="" />
                                    </div>
                                    <div className="col-md-8 offset-1" >
                                        {/*begin::Heading*/}
                                        <div className="d-flex flex-start mt-5">
                                            {/*begin::Badge*/}
                                            <span className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">2</span>
                                            {/*end::Badge*/}
                                            {/*begin::Title*/}
                                            <div className="fs-5 fs-lg-3 fw-bold text-dark">Professionally managed</div>
                                            {/*end::Title*/}
                                        </div>
                                        {/*end::H eading*/}
                                        {/*begin::Description*/}
                                        <div className="fs-6 mt-3"> This software is developed and is being maintained by some of the best software professionals in the business.</div>
                                        {/*end::Description*/}
                                    </div>
                                </div>
                                {/* 3 */}
                                <div className="row mb-lg-20">
                                    <div className="col-md-3" >
                                        <img src="media/illustrations/dozzy-1/9.png" className="mh-125px mb-9" alt="" />
                                    </div>
                                    <div className="col-md-8 offset-1" >
                                        {/*begin::Heading*/}
                                        <div className="d-flex flex-start mt-5">
                                            {/*begin::Badge*/}
                                            <span className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">3</span>
                                            {/*end::Badge*/}
                                            {/*begin::Title*/}
                                            <div className="fs-5 fs-lg-3 fw-bold text-dark">Built for you</div>
                                            {/*end::Title*/}
                                        </div>
                                        {/*end::H eading*/}
                                        {/*begin::Description*/}
                                        <div className=" fs-6   mt-3"> This software offers complete customization. It enables you to modify the software to meet the organization's operational requirements.</div>
                                        {/*end::Description*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}