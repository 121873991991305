import { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import * as XLSX from 'xlsx';
import { toastNotify } from '../../../services/NotifyService';
import { toast } from 'react-toastify';
import { importSuppliers } from '../../../services/ImportService';


const ImportModals = (props: any) => {
    const { setShowImportModal, afterUpdateProduct } = props;
    const [importData, setImportData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const onCloseClick = () => {

        if (setShowImportModal) { setShowImportModal(false); }
    }

    const onFileChange = (evt: any) => {
        /* wire up file reader */
        const target: any = (evt.target);
        if (target.files.length !== 1) { throw new Error('Cannot use multiple files') };

        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
            /* read workbook */
            const bstr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

            /* grab first sheet */
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];

            /* save data */
            var data = (XLSX.utils.sheet_to_json(ws, { header: 1 }));
            setImportData(data);
        };
        reader.readAsBinaryString(target.files[0]);
    }

    const save = () => {
        const toastId = toast.loading("Please wait...");
        importSuppliers(importData).then((res: any) => {
            setLoading(true)
            toastNotify(toastId, res);
        }).catch((err: any) => {
            const res = { data: { message: 'Something went wrong', success: false } };
            toastNotify(toastId, res);
            console.error(err);
        });
    }

    useEffect(() => {
    }, [])

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-lg'>
                    {/* begin::Modal content */}
                    <div className='modal-content'>
                        <div className='modal-header'>
                            {/* begin::Modal title */}
                            <h2 className='fw-bolder'>Import Suppliers</h2>
                            {/* end::Modal title */}

                            {/* begin::Close */}
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                onClick={() => onCloseClick()}
                                style={{ cursor: 'pointer' }}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                            </div>
                            {/* end::Close */}
                        </div>
                        <div className='modal-body'>
                            <div className="row mb-5">
                                <div className='col-md-12'>
                                    <div className="d-flex align-items-center">
                                        {/* begin::Icon*/}
                                        <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                            <a href="#">
                                                <div className="symbol-label fs-5 bg-light-success text-success">1</div>
                                            </a>
                                        </div>
                                        {/* end::Icon*/}
                                        <div className="d-flex flex-column">
                                            <a href="#" className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-5">STEP 1</a>
                                            <span className='fs-6'>Download the excel template to import data</span>
                                        </div>
                                        <div className="d-flex flex-column mx-20">
                                            <a href="https://billsapi.amberinfotech.in/api/downloads/import_suppliers.xlsx"
                                                className="btn btn-info btn-xs text-hover-light mb-1 fs-6"><i className='fa fa-download'></i> Download</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="separator my-10"></div>
                            <div className="row mb-5">
                                <div className="col-md-12 form-group">
                                    <div className="d-flex align-items-center">
                                        {/* begin::Icon*/}
                                        <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                            <a href="#">
                                                <div className="symbol-label fs-5 bg-light-success text-success">2</div>
                                            </a>
                                        </div>
                                        {/* end::Icon*/}
                                        <div className="d-flex flex-column">
                                            <a href="#" className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-5">STEP 2</a>
                                            <span className='fs-6'>Fill the data in Items Excel file</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="separator my-10"></div>
                            <div className="row mb-5">
                                <div className="col-md-12 form-group">
                                    <div className="d-flex align-items-center">
                                        {/* begin::Icon*/}
                                        <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                            <a href="#">
                                                <div className="symbol-label fs-5 bg-light-success text-success">3</div>
                                            </a>
                                        </div>
                                        {/* end::Icon*/}
                                        <div className="d-flex flex-column">
                                            <a href="#" className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-5">STEP 3</a>
                                            <span className='mb-1 fs-6'>Upload your .xlsx file after adding your items</span>


                                        </div>
                                        <div className="d-flex flex-column mx-20">
                                            <input type="file" onChange={($event) => onFileChange($event)} multiple={false} />  <span className="text-muted text-small">.xlsx only</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="separator my-10"></div>
                            <div className="row mb-20 text-center">
                                <div className="col-md-12">
                                    <button type="button" className='btn btn-primary' onClick={() => save()}
                                        disabled={loading || !importData} >
                                        <i className="feather icon-check-square"></i>
                                        Import</button>
                                </div>
                            </div>
                        </div>
                        {/* end::Modal body */}
                    </div>
                    {/* end::Modal content */}
                </div>
                {/* end::Modal dialog */}
            </div>
            {/* begin::Modal Backdrop */}
            <div className='modal-backdrop fade show'></div>
            {/* end::Modal Backdrop */}
        </>
    )
}

export { ImportModals }
