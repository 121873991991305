import axiosInstance from '../services/AxiosInstance';

export function importCustomers(postData) {
    return axiosInstance.post(`import/customers`, postData);
}

export function importItems(postData) {
    return axiosInstance.post(`imports/items`, postData);
}
export function importSuppliers(postData) {
    return axiosInstance.post(`Suppliers/import`, postData);
}
export function importItemsServices(postData) {
    return axiosInstance.post(`Imports/PostAsyncServices`, postData);
}