import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom';
import { IPaymentModel, initialPaymentValues as initialValues, IPayment, IInvoicePay } from '../../../_models/payment-model';
import { toastNotify } from '../../../services/NotifyService';
import { getPaymentDetails, getPaymentModes, savePayment, updatePayment } from '../../../services/PaymentService';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { CustomerDdl } from '../../../_shared/components/CustomerDdl';
import { PaymentModeDdl } from '../../../_shared/components/PaymentModeDdl';
import CustomerPaymentDetails from './CustomerPaymentDetails';
import { searchInvoices } from '../../../services/InvoiceService';
import { getCustomers } from '../../../services/CustomerService';
import FormikErrorMessage from '../../../_shared/components/FormikErrorMessage';
import { parse } from 'node:path/win32';
import './submit.css';
import { breadcrumbs } from '../components/PageTitle';
import {ValidationDate, ValidationField, ValidationSelect } from '../components/components';


const profileDetailsSchema = Yup.object().shape({
    customerId: Yup.number().required('Please select customer').nullable(false).min(1, 'Please select customer'),
    amount: Yup.number().required('Enter amount').nullable(false),
    bankCharges: Yup.number().nullable(false),
    paymentDate: Yup.string().required('Enter payment date'),
    paymentModeId: Yup.number().required('Enter payment mode').nullable(false).min(1, 'Enter payment mode'),
    internalNotes: Yup.string().max(1000, 'Cannot exceed 1000 characters'),
})
const profileBreadCrumbs: Array<PageLink> = [
    {
        title: 'Add Payment',
        path: '/payments/add',
        isSeparator: false,
        isActive: false,
    },
]


export function AddPayment(props: any) {
    const intl = useIntl();
    const navigate = useNavigate();
    const initialValues = {
        pageNumber: 1,
        pageSize: 100000,
        customerId: 0,
        fromDate: "",
        toDate: "",
        filter: "",
        status: [],
        isProforma: true,
        isTax: true,
        isBoth: true,
        paymentDate :''
    }

    const [data, setData] = useState<any>({...initialValues})
    const { id } = useParams();
    const [initialLoading, setInitialLoading] = useState<boolean>(false);
    const [title, setTitle] = useState<any>(id == null ? "Add" : "Edit");
    // declaration of Add or Edit
    const [isEdit, setIsEdit] = useState<any>(id == null || id == undefined ? false : true);

    const [loading, setLoading] = useState(false)
    const formik = useFormik<IPayment>({
        enableReinitialize: true,
        initialValues: data,
        validationSchema: profileDetailsSchema,
        onSubmit: async (values, { resetForm }) => {
            const tId = toast.loading("Please wait...");
            setLoading(true);
            try {
                const paymentModel: IPaymentModel = {
                    invoicePays: invoicePayment,
                    payment: values
                };
                let res: any;
                if (isEdit) {
                    res = await updatePayment(paymentModel, id);
                    resetForm();
                    refresh();
                } else {
                    res = await savePayment(paymentModel);
                    resetForm();
                    redirect();
                }
                toastNotify(tId, res);
            } catch (err) {
                const res = { data: { message: 'Something went wrong', success: false } };
                toastNotify(tId, res);
                console.log(err);
            } finally {
                setLoading(false);
                toast.dismiss(tId);
            }
        },
    });
    const refresh = () => {
        bindDetails(id);
    }
    

    const redirect = () => {
        setTimeout(() => {
            navigate("/payments");
        }, 500)
    }

    const updateInvoicePayment = (invoicePayment: IInvoicePay[]) => {
        setInvoicePayment(invoicePayment);
    }
    
    const bindDetails = (paymentId: any) => {
        setLoading(true);
        setTimeout(() => {
            getPaymentDetails(paymentId)
                .then((res: any) => {
                    let _data = res.data.data;
                    setData(_data);
                    formik.values.customerId = _data.customerId;
                })
                .catch(() => {
                }).finally(() => {
                    setLoading(false);
                });
        }, 500);
    }
    const [customers, setCustomers] = useState<any>([]);
    const bindCustomers = () => {
        getCustomers().then((res: any) => {
            const _customers = res.data.data;
            
            const array: any = [];
            _customers.map((item: any) => {
                array.push({ id: item.id, name: item.fullName });
            });
            array.unshift({ id: 0, name: 'Select Customer' });
            setCustomers(array);
        }).catch((err: any) => {
            console.log(err);
        })
    }
    const [paymentModes, setPaymentModes] = useState([]);
    const bindPaymentModes = () => {
        getPaymentModes().then((res: any) => {
            const array = res.data.data;
            const _paymentModes: any = [];
            array.map((item: any) => {
                _paymentModes.push({ id: item.id, name: item.modeText });
            })
            _paymentModes.unshift({ id: 0, name: 'Select Mode' });
            setPaymentModes(_paymentModes);
        }).catch((err: any) => {
            console.log(err);
        })
    }

    useEffect(() => {
        bindCustomers();
        bindPaymentModes();
        console.log(id);
        if (id != null) {
            bindDetails(id)
        }
    }, []);
    const LOADINGSTAGES = {
        IDLE: 0,
        LOADING: 1,
        POSITIVE: 3,
        NEGATIVE: 4,
        ERROR: 5,
        EMPTY: 6
    }
    const [loadingStage, setLoadingStage] = useState<any>(LOADINGSTAGES.IDLE);
    const [invoiceData, setInvoiceData] = useState<any>([]);
    const bindGrid = (values: any) => {
        setInitialLoading(true);
        setLoadingStage(LOADINGSTAGES.LOADING);
        setTimeout(() => {
            searchInvoices(values)
                .then((res: any) => {
                    if (res.data.success) {
                        let _data = res.data.data;
                   const newData =  _data.filter((x: any) => x.status === 1 || x.status === 2);
                        // add new column adjustAmount
                        newData.map((x: any) => {
                            x.adjustAmount = 0;
                        });
                        setInvoiceData(newData);
                        if (_data.length > 0) {
                            setLoadingStage(LOADINGSTAGES.POSITIVE)
                            setInvoiceData(newData);
                        }
                        else {
                            setLoadingStage(LOADINGSTAGES.EMPTY)
                            setInvoiceData([]);
                        }
                    }
                    else {
                        setLoadingStage(LOADINGSTAGES.NEGATIVE)
                        setInvoiceData([]);
                    }
                })
                .catch((err) => {
                    setLoadingStage(LOADINGSTAGES.ERROR)
                    setInvoiceData([]);
                }).finally(() => {
                    setInitialLoading(false);
                });
        }, 1000);
    };
    useEffect(() => {
        if (formik.values.customerId != 0) {
            bindGrid({ ...initialValues, customerId: formik.values.customerId });
        }
    }, [formik.values.customerId]);
    // declaration of error message
    const errors = formik.errors;
    const touched = formik.touched;
    const [invoicePayment, setInvoicePayment] = useState<any>([]);
    const invoicePay = (data: any) => {
        data.map((x: any) => {
            if (id) { x.paymentId = parseInt(id); }
            else { x.paymentId = 0; }
        });
        setInvoicePayment(data);
    }

    return (
      <>
        <PageTitle
          breadcrumbs={breadcrumbs({
            title: 'Payments',
            path: '/payments',
          })}
        >
          {id ? 'Edit Payment' : 'Add Payment'}
        </PageTitle>
        <div className='col-lg-9'>
          <div className='card mb-5 mb-xl-10'>
            <div id='kt_kitty_group_details' className='collapse show'>
              <form onSubmit={formik.handleSubmit} noValidate className='form'>
                <div className='card-body border-top p-9'>
                  {/* 1 */}
                  <div className='row mb-6'>
                    <ValidationSelect
                      className='col-lg-6'
                      label='Customer'
                      isRequired
                      placeholder='Customer Name'
                      name='customerId'
                      formik={formik}
                      options={customers}
                    />
                    <ValidationField
                      className='col mb-6'
                      label='Amount'
                      isRequired
                      placeholder='Enter Amount'
                      name='amount'
                      type='number'
                      formik={formik}
                      min='0'
                    />
                  </div>
                  {/* 3 */}
                  <div className='row mb-6'>
                    {/* <ValidationSelect/> bank charges*/}
                    <ValidationField
                      className='col mb-6'
                      label='Bank Charges'
                      isRequired
                      placeholder='Enter Bank Charges'
                      name='bankCharges'
                      type='number'
                      formik={formik}
                      min='0'
                    />
                    <ValidationSelect
                      className='col mb-6'
                      label='Payment Mode'
                      isRequired
                      placeholder='Select Mode Of Payment'
                      name='paymentModeId'
                      formik={formik}
                      options={paymentModes}
                    />
                  </div>
                  {/* 4 */}
                  <div className='row mb-6'>
                    <ValidationDate
                      className='col mb-6'
                      label='Payment Date'
                      isRequired
                      placeholder='Select Payment Date'
                      name='paymentDate'
                      formik={formik}
                    />
                    <ValidationField
                      className='col mb-6'
                      label='Reference No'
                      placeholder='Enter Reference No'
                      name='reference'
                      formik={formik}
                    />
                    {/* 7 */}
                    {loadingStage === LOADINGSTAGES.POSITIVE && (
                      <CustomerPaymentDetails
                        invoiceData={invoiceData}
                        setIData={updateInvoicePayment}
                        paymentReceived={formik.values.amount}
                      />
                    )}
                    <div className='separator my-10'></div>
                    {/* 8 */}
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                        Internal notes
                      </label>
                      <div className='col-lg-8'>
                        <div className='row'>
                          <div className='col-lg-12 fv-row'>
                            <textarea
                              className='form-control mb-3 mb-lg-0'
                              placeholder='Internal notes'
                              {...formik.getFieldProps('internalNotes')}
                            ></textarea>
                            {errors.internalNotes && touched.internalNotes ? (
                              <FormikErrorMessage message={errors.internalNotes} />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-2 card card-body submit'>
                  <p>Received Amount : &#8377; {formik.values.amount} </p>
                  <p>Bank Charges :&#8377; {formik.values.bankCharges}</p>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    disabled={loading || !formik.isValid || !formik.dirty}
                  >
                    {!loading && 'Save '}
                    {loading && (
                      <span className='indicator-progress submit' style={{display: 'block'}}>
                        Please wait...{' '}
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <CustomerPaymentDetails  invoiceData = {invoiceData} setIData = {invoicePay} paymentReceived = {formik.values.amount}/>
      </>
    )
}