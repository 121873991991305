import { useState, useEffect } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { LoadData, ServerMessage } from '../../../lib/NoData'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { getCompanyCode } from '../../../services/CommonService'
import { getProduct } from '../../../services/CatalogService'
import { ConfirmBox } from '../../../lib/ConfirmBox'
import { IConfirmModel } from '../../../_models/confirm-model'
import { StockAdjustModal } from './_stockAdjustModal'
import { Loader, LOADINGSTAGES } from '../components/components'
import { ListStockAdjust } from './ListStockAdjust'
import { getProductPics } from '../../../services/ProductService'
import { ImageCard } from './ImageCard'
import { AddProductPics } from './AddProductPics'
interface IProductPic {
  iD: number;
  thumbPath: string;
  imagePath: string;
  isDefault: boolean;
  mappingId: number;
}

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Products',
    path: '/products',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export function ProductDetails(props: any) {
  const { id } = useParams()
  const navigate = useNavigate()

  const [data, setData] = useState<any>(null)
  const [companyCode, setCompanyCode] = useState<any>('')
  const [itemId, setItemId] = useState<any>(id || 0)
  const [isLoading, setIsLoading] = useState<any>(id || 0)
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showStock, setShowStock] = useState<boolean>(false)
  const [confirmModel, setConfirmModel] = useState<IConfirmModel>()
  const [title, setTitle] = useState<any>('Add')
  const [itemIdForUpdate, setItemIdForUpdate] = useState<any>()
  const [loadingStages, setLoadingStages] = useState<number>(LOADINGSTAGES.IDLE)
  const [tab, setTab] = useState(1)

  const bindDetails = (values: any) => {
    setIsLoading(true)
    setLoadingStages(LOADINGSTAGES.LOADING)
    setTimeout(() => {
      getProduct(values)
        .then((res: any) => {
          if (res.data.success) {
            let _data = res.data.data

            setData(_data)
            setLoadingStages(LOADINGSTAGES.POSITIVE)
          } else {
            setLoadingStages(LOADINGSTAGES.NEGATIVE)
          }
        })
        .catch(() => {
          setLoadingStages(LOADINGSTAGES.ERROR)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }, 100)
  }

  const openStockModal = () => {
    setTimeout(() => {
      setShowStock(true)
    }, 500)
  }

  const afterStockAdjust = (res: any) => {
    setShowStock(false)
  }

  const openDeleteModal = (id: any) => {
    if (id > 0) {
      const confirmModel: IConfirmModel = {
        title: 'Delete Product',
        btnText: 'Delete this product',
        deleteUrl: `products/${id}`,
        message: 'delete-product',
      }

      setConfirmModel(confirmModel)
      setTimeout(() => {
        setShowConfirm(true)
      }, 500)
    } else {
      setTitle('')
    }
  }

  const afterConfirm = (res: any) => {
    if (res) navigate('/products')

    setShowConfirm(false)
  }

  useEffect(() => {
    if (itemId > 0) {
      bindDetails(itemId)
      bindProductPics(itemId)
    }
    setCompanyCode(getCompanyCode())
  }, [])
  const [productPics, setProductPics] = useState<IProductPic[]>([])
  const bindProductPics = (id: any) => {
    getProductPics(id)
      .then((res: any) => {
        if (res.data.success) {
          setProductPics(res.data.data)
        }
      })
      .catch((err: any) => {
        console.log(err)
      })
  }
  const [show, setShow] = useState<boolean>(false)
  const deleteImage = (id: any) => {
    console.log(id)
  }
  const updateImage = (id: any) => { }
  return (
    <>

      {' '}
      <PageTitle breadcrumbs={profileBreadCrumbs}>Product Detail</PageTitle>
      <div className='card mb-5 mb-xl-10'>
        {loadingStages === LOADINGSTAGES.LOADING && <Loader />}
        {loadingStages === LOADINGSTAGES.NEGATIVE && <ServerMessage message='No result found' />}
        {loadingStages === LOADINGSTAGES.ERROR && (
          <ServerMessage message='Oops! something went wrong' />
        )}
        {loadingStages === LOADINGSTAGES.POSITIVE && (
          <>
            <div className='card-body pt-9 pb-0'>
              <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center mb-2'>
                        <a
                          href='#'
                          className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'
                        >
                          {data?.name}
                        </a>
                        {/* <img style={{width:'200px'}} src={`${toAbsoluteUrl(data.barCodeImage)}`} /> */}
                        {/* <a
                                                href='#'
                                                className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3'
                                                data-bs-toggle='modal'
                                                data-bs-target='#kt_modal_upgrade_plan'
                                            >
                                                active
                                            </a> */}
                      </div>
                    </div>
                    <div className='d-flex my-4'>
                      <Link
                        to={`/product/edit/${id}`}
                        className='btn btn-sm btn-primary me-2'
                        id='kt_user_follow_button'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr012.svg'
                          className='svg-icon-3 d-none'
                        />
                        <i className='bi bi-pencil'></i> Edit Item
                      </Link>

                      {/* show only if bar code exists */}
                      {
                        data?.barCode && <Link
                          onClick={() => setTab(2)}
                          className={`btn btn-sm btn-light btn-active-secondary  px-4 me-2`}
                          to={`/barcode/print/${data?.barCode}`}
                        >
                          <i className='bi bi-printer'></i>  Print barcodes
                        </Link>
                      }
                      <a href="#"
                        onClick={async () => await openStockModal()}
                        className={`btn btn-sm btn-light btn-active-secondary  px-4 me-2`}
                        id='kt_charts_widget_2_year_btn'
                      >
                        <i className='bi bi-arrow-down-up'></i> Adjust stock
                      </a>
                      <a
                        href='#'
                        onClick={async () => await openDeleteModal(id)}
                        className={`btn btn-sm btn-light btn-active-secondary  px-4 me-1`}
                        id='kt_charts_widget_2_year_btn'
                      >
                        <i className='bi bi-trash3'></i> Delete item
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-7'>
                <div className='col-lg-3'>
                  <h6 className='fw-bold text-muted mb-1'>Item name </h6>
                  <span className='fw-bolder fs-6 text-dark'>{data?.name}</span>
                </div>
                <div className='col-lg-3 '>
                  <h6 className='fw-bold text-muted mb-1'>Bar code </h6>
                  <span className='fw-bolder fs-6 text-dark'>{data?.barCode}</span>
                </div>
              </div>

              <div className='row mb-7'>
                <div className='col-lg-3'>
                  <h6 className='fw-bold text-muted mb-1'>HSN </h6>
                  <span className='fw-bolder fs-6 text-dark'>{data?.hsn}</span>
                </div>
                <div className='col-lg-3'>
                  <h6 className='fw-bold text-muted mb-1'>Category </h6>
                  <span className='fw-bolder fs-6 text-dark'>{data?.catName ?? ''}</span>
                </div>
                <div className='col-lg-3'>
                  <h6 className='fw-bold text-muted mb-1'>Selling price </h6>
                  <span className='fw-bolder fs-6 text-dark'>{data?.defaultSP?.toFixed(2)}</span>
                </div>

                <div className='col-lg-3'>
                  <h6 className='fw-bold text-muted mb-1'>GST Rate </h6>
                  <span className='fw-bolder fs-6 text-dark'>
                    {data?.taxPercent?.toFixed(2)} %
                  </span>
                </div>
              </div>

              <div className='row mb-7'>
                <div className='col-lg-3'>
                  <h6 className='fw-bold text-muted mb-1'>SKU </h6>
                  <span className='fw-bolder fs-6 text-dark'>{data?.sku}</span>
                </div>

                <div className='col-lg-3'>
                  <h6 className='fw-bold text-muted mb-1'>Purchase price </h6>
                  <span className='fw-bolder fs-6 text-dark'>
                    {data?.purchasePrice?.toFixed(2)}
                  </span>
                </div>
                <div className='col-lg-3'>
                  <h6 className='fw-bold text-muted mb-1'>Stock in hand </h6>
                  <span className='fw-bolder fs-6 text-dark'>
                    {data?.stockQuantity} {data?.unitName}
                  </span>
                </div>
                <div className='col-lg-3'>
                  <h6 className='fw-bold text-muted mb-1'>Low stock level</h6>
                  <span className='fw-bolder fs-6 text-dark'>
                    {data?.minStockQuantity} {data?.unitName}
                  </span>
                </div>
              </div>
              <div className='row mt-5 mb-7'>
                <h6 className='fw-bold text-muted mb-1'>Description </h6>
                <div className='col-md-12 mt-5'>
                  {data?.fullDescription ? data?.fullDescription : data?.shortDescription}
                </div>
              </div>
            </div>

          </>
        )}
      </div>

      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
        <li className='nav-item me-2'>
          <a
            onClick={() => setTab(1)}
            className={`nav-link ${tab === 1 ? 'active' : ''}`}
            data-bs-toggle='tab'
            href='#'
          >
            Stock adjustments
          </a>
        </li>

        <li className='nav-item me-2'>
          <a
            onClick={() => setTab(2)}
            className={`nav-link ${tab === 2 ? 'active' : ''}`}
            data-bs-toggle='tab'
            href='#'
          >
            Product images
          </a>
        </li>
      </ul>

      {tab == 1 && data && <ListStockAdjust data={data} />}

      {tab == 2 &&
        <div className='card mt-5 mb-5 mb-xl-10'>
          <div className='card-header border-0 pt-9'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>Product Images</span>
              <span className='text-muted mt-1 fw-bold fs-7'> {productPics.length} Images</span>
            </h3>
            < div className='card-toolbar'> <button onClick={() => setShow(true)} className='btn btn-sm btn-light-primary'> <i className='fas fa-plus'></i> Add Image</button></div>
          </div>
          <div className='card-body pt-9 pb-0'>
            <div className='row mt-2 mb-7'>
              {productPics &&
                productPics.length > 0 &&
                productPics.map((pic: any) => {
                  return (
                    <div className='col-md-3 mt-5'>
                      <ImageCard id={pic.id} imageUrl={pic.imagePath} mappingId={pic.mappingId}
                        productId={id}
                        isDefault={pic.isDefault}
                        onUpload={() => {
                          bindProductPics(id)
                        }}
                      />
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      }


      {show && <AddProductPics id={itemId} onUpload={() => {
        bindProductPics(id)
        setShow(false)
      }} setShow={setShow} show={show} type='image' />}
      {/* {itemIdForUpdate !== undefined && <ProductEditModal setItemIdForUpdate={setItemIdForUpdate} productGroupId={3} title={title} productId={id}
            isService={false} />} */}
      {showConfirm && <ConfirmBox confirmModel={confirmModel} afterConfirm={afterConfirm} />}
      {showStock && data && <StockAdjustModal data={data} afterStockAdjust={afterStockAdjust} />}
    </>
  )
}
