import axiosInstance from '../services/AxiosInstance';

export function getCompanyDetails() {
    return axiosInstance.get(`companies`);
}

export function getCompanyBranches() {
    return axiosInstance.get(`companies/branches`);
}

export function onboardCompany(data) {
    return axiosInstance.post(`companies/onboard`, data);
}

export function updateCompany(data, id) {
    return axiosInstance.put(`companies/${id}`, data);
}
export function deactivateUser() {
    return axiosInstance.put('Companies/DeactivateUser');
}
export function searchCompanies(postdata) {
    return axiosInstance.post(`companies/search`,postdata);
}

