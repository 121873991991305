import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { searchProducts } from "../../../services/CatalogService";
import { DisableSidebar, PageLink, PageTitle } from '../../../_metronic/layout/core';
import { KTSVG } from '../../../_metronic/helpers';
import Pagination from '../../../lib/Pagination/Pagination';
import { PAGE_SIZE } from "../../../_shared";

import { NoDataFound, ServerMessage } from '../../../lib/NoData';

import { getCategories } from '../../../services/CategoryService';
import { Link } from 'react-router-dom';
import { ImportModal } from './_importModal';
import { ProductEditModal } from './_productModal';
import { FilterInput, FilterSelect, Loader } from '../components/components';
import { CounterBox } from '../../../lib/CounterBox';
import { getProductCount } from '../../../services/CounterService';

interface BigObject<T> {
    [index: string]: T
}

export function ListProducts() {
    const [counters, setCounters] = React.useState<any>({});
    const [data, setData] = React.useState([]);
    const [currentTableData, setCurrentTableData] = React.useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = React.useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = React.useState<any>("Add");
    const [itemIdForUpdate, setItemIdForUpdate] = useState<any>()
    const [id, setId] = useState<any>(0);

    const [showImportModal, setShowImportModal] = useState<boolean>(false);

    const LOADINGSTAGES = {
        IDLE: 0,
        LOADING: 1,
        POSITIVE: 3,
        NEGATIVE: 4,
        ERROR: 5,
        EMPTY: 6
    }
    const [loadingStage, setLoadingStage] = useState<any>(LOADINGSTAGES.IDLE);

    const initialValues = {
        pageNumber: 1,
        pageSize: 100000,
        customerId: 0,
        productGroupId: 3,
        fromDate: "",
        toDate: "",
        filter: "",
        categoryId: 0,
    }

    const [counterData, setCounterData] = React.useState([]);
    const bindCounters = () => {
        getProductCount(true).then((res: any) => {
            const data = res.data.data;
            setCounters(data);

            const localArray: any = [];
            let bigObject: BigObject<object> = data;
            Object.keys(bigObject).forEach(key => {
                console.log(bigObject[key])
                localArray.push({
                    text: key === "total" ? "Total" : "Inactive",
                    value: bigObject[key]
                })
            })
            setCounterData(localArray);
        }).catch((err: any) => {
            console.log(err);
        })
    }

    const paginate = (pageNumber: any) => {
        setCurrentPage(pageNumber);
        const firstPageIndex = (pageNumber - 1) * PAGE_SIZE;
        const lastPageIndex = firstPageIndex + PAGE_SIZE;
        let _currentTableData = data.slice(firstPageIndex, lastPageIndex);
        setCurrentTableData(_currentTableData);
    };

    const bindGrid = (values: any) => {
        setTimeout(() => {
            setLoadingStage(LOADINGSTAGES.LOADING);
            setIsLoading(true);
            searchProducts(values)
                .then((res: any) => {
                    if (res.data.success) {
                        let _data = res.data.data.data;
                        const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
                        const lastPageIndex = firstPageIndex + PAGE_SIZE;
                        let _currentTableData = _data.slice(firstPageIndex, lastPageIndex);
                        setData(_data);
                        setTotalRows(_data.length);
                        if (_data.length > 0) {
                            setLoadingStage(LOADINGSTAGES.POSITIVE);
                            setCurrentTableData(_currentTableData);
                        }
                        else {
                            setLoadingStage(LOADINGSTAGES.NEGATIVE);
                            setCurrentTableData([]);
                        }
                    }
                    else {
                        setLoadingStage(LOADINGSTAGES.NEGATIVE);
                        setCurrentTableData([]);
                    }
                })
                .catch(() => {
                    setLoadingStage(LOADINGSTAGES.ERROR);
                    setCurrentTableData([]);
                }).finally(() => {
                    setIsLoading(false);
                });
        }, 1000);
    };
    const [categories, setCategories] = useState<any>([]);
    const bindCategories = () => {
        getCategories().then((res: any) => {
            const data = res.data.data;
            // add default value
            data.unshift({ id: 0, name: "Select Category" });
            setCategories(data);
        }).catch((err: any) => {
            console.log(err);
        })
    }
    const [filterValues, setFilterValues] = useState<any>(initialValues);
    const filterData = () => {
        const values = {
            ...initialValues,
            categoryId: parseInt(filterValues.categoryId),
            filter: filterValues.filter,
        }
        bindGrid(values);
    }

    const resetData = () => {
        setFilterValues(initialValues);
        bindGrid(initialValues);
    }

    const openEditModal = (id: any) => {
        if (id > 0) {
            setTitle("Edit");
            setId(id);
            setTimeout(() => {
                setItemIdForUpdate(id)
            }, 500);
        }
        else {
            setTitle("Add");
            setId(0);
            setTimeout(() => {
                setItemIdForUpdate(id)
            }, 500);
        }
    }

    useEffect(() => {
        bindGrid(initialValues);
        bindCounters();
        bindCategories();
    }, []);

    const profileBreadCrumbs: Array<PageLink> = [
        {
            title: 'Products',
            path: '/products',
            isSeparator: false,
            isActive: false,
        },
    ]
    return (<>
        <PageTitle breadcrumbs={profileBreadCrumbs}>Products</PageTitle>
        <DisableSidebar>
            <div className='card mb-xl-5'>
                <div className='card-body pt-9 pb-0'>
                    <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>

                        <div className='flex-grow-1'>
                            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                                <div className='d-flex flex-column'>
                                    <div className='d-flex align-items-center mb-2'>
                                        <CounterBox data={counterData} />
                                    </div>
                                </div>

                                <div className='d-flex my-4'>
                                    {/* begin::Filter Button */}
                                    <button
                                        disabled={isLoading}
                                        type='button'
                                        className='btn btn-sm btn-secondary me-3'
                                        data-kt-menu-trigger='click'
                                        data-kt-menu-placement='bottom-end'
                                    >
                                        <KTSVG path='/media/icons/duotune/general/gen031.svg' />
                                        Filter
                                    </button>
                                    {/* end::Filter Button */}
                                    {/* begin::SubMenu */}
                                    <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
                                        {/* begin::Header */}
                                        <div className='px-7 py-5'>
                                            <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
                                        </div>
                                        {/* end::Header */}

                                        {/* begin::Separator */}
                                        <div className='separator border-gray-200'></div>
                                        {/* end::Separator */}

                                        {/* begin::Content */}
                                        <div className='px-7 py-5' data-kt-user-table-filter='form'>
                                            {/* begin::Input group */}
                                            <div className='mb-10'>
                                                <FilterSelect
                                                    className='col-12'
                                                    placeholder='Select option'
                                                    name='categoryId'
                                                    label='Category'
                                                    value={filterValues.categoryId}
                                                    onChange={(e: any) => {
                                                        setFilterValues({ ...filterValues, categoryId: e.target.value })
                                                    }}
                                                    options={categories}
                                                />
                                            </div>
                                            {/* end::Input group */}
                                            <div className='mb-10'>
                                                <FilterInput
                                                    name='filter'
                                                    label='Keyword'
                                                    value={filterValues.filter}
                                                    onChange={(e: any) => {
                                                        setFilterValues({ ...filterValues, filter: e.target.value })
                                                    }}
                                                />
                                            </div>

                                            {/* begin::Actions */}
                                            <div className='d-flex justify-content-end'>
                                                <button
                                                    type='button'
                                                    disabled={isLoading}
                                                    onClick={resetData}
                                                    className='btn btn-sm btn-light btn-active-light-primary fw-bold me-2 px-6'
                                                    data-kt-menu-dismiss='true'
                                                    data-kt-user-table-filter='reset'
                                                >
                                                    Reset
                                                </button>
                                                <button
                                                    disabled={isLoading}
                                                    type='button'
                                                    onClick={filterData}
                                                    className='btn btn-sm btn-primary fw-bold px-6'
                                                    data-kt-menu-dismiss='true'
                                                    data-kt-user-table-filter='filter'
                                                >
                                                    Apply
                                                </button>
                                            </div>
                                            {/* end::Actions */}
                                        </div>
                                        {/* end::Content */}
                                    </div>
                                    {/* end::SubMenu */}
                                    <Link to='/product/add'><button
                                        title='Add new product'
                                        className='btn btn-sm btn-primary me-3'
                                        data-bs-toggle='modal'
                                        data-bs-target='#kt_modal_offer_a_deal'
                                    >
                                        Add New
                                    </button></Link>
                                    <a href='#' className='btn btn-sm btn-secondary me-2' id='kt_import_toggle' onClick={() => setShowImportModal(true)}>
                                        <KTSVG
                                            path='/media/icons/duotune/arrows/arr012.svg'
                                            className='svg-icon-3 d-none'
                                        />
                                        <span className='indicator-label'>Import</span>
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {loadingStage === LOADINGSTAGES.LOADING && <Loader />}
            {loadingStage === LOADINGSTAGES.NEGATIVE && <NoDataFound />}
            {loadingStage === LOADINGSTAGES.ERROR && <ServerMessage message="Oops! server issue occured" />}
            {loadingStage === LOADINGSTAGES.POSITIVE &&
                <div className='row'>
                    <div className="col-xs-12 col-md-12">
                        <div className="notice d-flex bg-light-primary rounded border-primary-light border p-6 mb-3">
                            <i className="bi bi-info-circle-fill fs-1 text-primary me-4"></i>
                            <div className="d-flex flex-stack flex-grow-1" title='Info'>
                                <div className="">
                                    <div className="fs-7 text-gray-700">Click on the Product Name to view product details &amp; update product information.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <div className="display mb-4 dataTablesCard customer-list-table">
                                        <Table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                                            <thead>
                                                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                                    <th>product Name</th>
                                                    <th>hsn</th>
                                                    <th>stock qty</th>
                                                    <th >unit</th>
                                                    <th className='text-end'>tax Percent</th>
                                                    <th className='text-end'>price (including tax)</th>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentTableData.map((item: any, index: any) =>
                                                    <tr key={index}>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <Link to={`/product/details/${item.id}`} className="text-info">
                                                                    <p className="text-dark fw-bold text-hover-secondary mb-1"> {item.productName}</p>
                                                                    {item.catName ? <span className='badge badge-secondary'>{item.catName}</span> : ""}
                                                                </Link>
                                                            </div>
                                                        </td>
                                                        <td>{item.hsn}</td>
                                                        <td>{item.stockQuantity}</td>
                                                        <td>{item.unitName}</td>
                                                        <td className='fw-bold text-end'>{item.taxPercent?.toFixed(2)}</td>
                                                        <td className='text-end'>{item.amount?.toFixed(2)}</td>
                                                    </tr>
                                                )
                                                }

                                            </tbody>
                                        </Table>
                                        <Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage}
                                            totalCount={totalRows}
                                            pageSize={PAGE_SIZE}
                                            onPageChange={(page: any) => paginate(page)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            {itemIdForUpdate !== undefined && <ProductEditModal setItemIdForUpdate={setItemIdForUpdate} productGroupId={3} title={title} productId={id}
                isService={false} />}
            {showImportModal && <ImportModal setShowImportModal={setShowImportModal} />}
        </DisableSidebar>
    </>)
}