import { useEffect, useState } from 'react'
import { KTSVG } from '../../_metronic/helpers'
import { ServerMessage } from '../../lib/NoData';
import { Loader, LOADINGSTAGES } from '../../app/pages/components/components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { getPrefixes, updateInvoicePrefix } from '../../services/CommonService';
import { toastNotify } from '../../services/NotifyService';

const editSchema = Yup.object().shape({
    invoicePrefix: Yup.string()
        .required('Please enter invoice prefix')
        .max(20, 'Maximum 20 characters allowed'),
    invoiceStart: Yup.number()
        .required('Please enter a valid number')
})

const InvoicePrefixModal = (props: any) => {
    const { setItemIdForUpdate, title, productId, setShowInvPreFrom, afterUpdateProduct } = props;
    const [loadingStages, setLoadingStages] = useState<any>(LOADINGSTAGES.IDLE);

    const onCloseClick = () => {
        if (setItemIdForUpdate) { setItemIdForUpdate(undefined) };
        if (setShowInvPreFrom) { setShowInvPreFrom(false); }
    }

    const onCancel = () => {
        if (setItemIdForUpdate) { setItemIdForUpdate(undefined) };
        if (setShowInvPreFrom) { setShowInvPreFrom(false); }
    }

    const onOk = () => {
        setTimeout(() => {
            afterUpdateProduct(null);
        }, 500);
    }

    const [values, setValues] = useState({ invoicePrefix: '', invoiceStart: '' });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: values,
        validationSchema: editSchema,
        onSubmit: async (values, { setSubmitting }) => {
            
            setSubmitting(true)
            const toastId = toast.loading("Please wait...");
            try {

                // alert(JSON.stringify(values));
                await updateInvoicePrefix(values).then((res: any) => {
                    toastNotify(toastId, res);
                    if (setItemIdForUpdate) {
                        setItemIdForUpdate(undefined);
                        setTimeout(() => { window.location.reload(); }, 1000);
                    }
                    if (setShowInvPreFrom) {
                        setShowInvPreFrom(false)
                        afterUpdateProduct()
                    }

                }).catch((err: any) => {
                    const res = { data: { message: 'Something went wrong', success: false } };
                    toastNotify(toastId, res);
                    console.error(err);
                });
            } catch (ex) {
                console.error(ex)
            } finally {
                setSubmitting(true)
                toast.dismiss();
            }
        },
    })

    const bindPrefixes = () => {
        setTimeout(() => {
            getPrefixes()
                .then((res: any) => {
                    var _data = res.data.data;
                    setValues(_data);
                })
                .catch(() => {
                });
        }, 1000);
    };

    useEffect(() => {
        bindPrefixes();

        setLoadingStages(LOADINGSTAGES.POSITIVE);
        document.body.classList.add('modal-open')
        return () => {
            document.body.classList.remove('modal-open')
        }
    }, []);

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {loadingStages === LOADINGSTAGES.LOADING && <Loader />}
                {loadingStages === LOADINGSTAGES.NEGATIVE && <ServerMessage message="No data found" />}
                {loadingStages === LOADINGSTAGES.ERROR && <ServerMessage message="Something went wrong" />}
                {/* begin::Modal dialog */}
                {loadingStages === LOADINGSTAGES.POSITIVE &&
                    <div className='modal-dialog modal-lg'>
                        {/* begin::Modal content */}
                        <div className='modal-content'>
                            <div className='modal-header'>
                                {/* begin::Modal title */}
                                <h2 className='fw-bolder'>Invoice number settings</h2>
                                {/* end::Modal title */}

                                {/* begin::Close */}
                                <div
                                    className='btn btn-icon btn-sm btn-active-icon-primary'
                                    data-kt-users-modal-action='close'
                                    onClick={() => onCloseClick()}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                </div>
                                {/* end::Close */}
                            </div>
                            <div className='modal-body'>
                                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                                    <div>
                                        <div className="row mx-5">
                                            <div className="col-md-11">
                                                <div className="py-5">
                                                    <div className="d-flex align-items-center rounded py-5 px-5 bg-light-primary">
                                                        <i className="bi bi-info-circle-fill text-primary fs-2x mx-2"></i>
                                                        <div className="text-gray-600 fw-bold fs-6">These changes will be applicabe to new invoices only.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mx-5 my-5'>
                                            <div className='col-lg-4'>
                                                <label className='form-label required fw-bold fs-6'>Prefix</label>
                                                <div className=''>
                                                    <input
                                                        type='text'
                                                        className='form-control   mb-3 mb-lg-0'
                                                        placeholder='INV'
                                                        {...formik.getFieldProps('invoicePrefix')}
                                                    />
                                                    {formik.touched.invoicePrefix && formik.errors.invoicePrefix && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>{formik.errors.invoicePrefix}</div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-1 my-10">&nbsp;</div>
                                            <div className='col-lg-6 '>
                                                <label className='form-label required fw-bold fs-6'>Serial number starts</label>
                                                <input
                                                    type='text'
                                                    className='form-control '
                                                    placeholder='1000'
                                                    {...formik.getFieldProps('invoiceStart')}
                                                />
                                                {formik.touched.invoiceStart && formik.errors.invoiceStart && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.invoiceStart}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal-footer">
                                        <button onClick={() => onCancel()}
                                            type="button"
                                            className="btn btn-light"
                                            data-bs-dismiss="modal"
                                        >
                                            Cancel
                                        </button>
                                        <button onClick={() => onOk()} type="submit" className="btn btn-primary">
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                            {/* end::Modal body */}
                        </div>
                        {/* end::Modal content */}
                    </div>}
                {/* end::Modal dialog */}
            </div>
            {/* begin::Modal Backdrop */}
            <div className='modal-backdrop fade show'></div>
            {/* end::Modal Backdrop */}
        </>
    )
}

export { InvoicePrefixModal }
