import React, { useEffect, useState, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Button, OverlayTrigger, Table, ToggleButton, Tooltip } from 'react-bootstrap'

import {
  getQuotations,
  searchQuotation,
  updateQuotationStatus,
} from '../../../services/QuotationService'

import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import moment from 'moment'
import Pagination from '../../../lib/Pagination/Pagination'
import { PAGE_SIZE } from '../../../_shared'
//import { InvoiceAddModal } from './partials/InvoiceAddModal';
import { NoDataFound } from '../../../lib/NoData'
import { IConfirmModel } from '../../../_models/confirm-model'
import { ConfirmBox } from '../../../lib/ConfirmBox'
import {
  getCategoryCounter,
  getCustomerCounter,
  getEmployeeCounter,
  getInvoiceCount,
  getLeadCounter,
  getPaymentCounts,
  getQuotationCounts,
} from '../../../services/CounterService'

import '../css/style.css'
import { url } from 'inspector'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { LoadData } from '../../../lib/NoData'
import { getCategories } from '../../../services/CategoryService'
import { CounterBox } from '../../../lib/CounterBox'
import { AddCatThumb } from './AddCatThumb'
const profileBreadCrumbs: Array<PageLink> = [
  //{
  //   title: 'Employees',
  //   path: '/Employees',
  //   isSeparator: false,
  //   isActive: false,
  // },
  {
    title: 'Categories',
    path: '/category',
    isSeparator: false,
    isActive: false,
  },
]

interface BigObject<T> {
  [index: string]: T
}

export function ListCategory() {
  const [toggle, setToggle] = useState('')
  const onClick = (name: any) => setToggle(toggle === name ? '' : name)
  const [counters, setCounters] = React.useState<any>({})
  const [data, setData] = React.useState([])
  const [currentTableData, setCurrentTableData] = React.useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRows, setTotalRows] = React.useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = React.useState<any>()
  const [keyword, setKeyword] = React.useState<any>('')
  const [itemIdForUpdate, setItemIdForUpdate] = useState<any>()
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showInvoiceModal, setShowInvoiceModal] = useState<boolean>(false)
  const [confirmModel, setConfirmModel] = useState<IConfirmModel>()
  const [fieldValue, setFieldValue] = useState<any>({ leadStatusId: 0 })
  const [leadStatus, setLeadStatus] = useState<any>([])
  const [apiStatus, setAPIStatus] = useState(false)

  const updateLeadStatus = (leadId: any, statusId: any) => {
    setAPIStatus(true)
    setIsLoading(true)
    updateQuotationStatus({
      quoteId: leadId,
      statusId: statusId,
    })
      .then((res: any) => {
        if (res.data.success) {
          bindGrid(initialValues)
        }
      })
      .catch((err: any) => {
        setAPIStatus(false)
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  const [leadStatusIds, setLeadStatusIds] = useState<any>({
    leadStatusId: 0,
    indexId: 0,
  })
  const [leadId, setLeadId] = useState<any>(0)

  const initialValues = {
    branchId: 0,
    status: 0,
    pageNumber: 1,
    pageSize: 100000,
    fromDate: '',
    toDate: '',
    customerId: 0,
    createdBy: 0,
    assignedTo: 0,
    filter: '',
  }

  const [counterData, setCounterData] = React.useState([])
  const bindCounters = () => {
    getCategoryCounter()
      .then((res: any) => {
        const data = res.data.data
        setCounters(data)

        const localArray: any = []
        let bigObject: BigObject<object> = data
        Object.keys(bigObject).forEach((key) => {
          localArray.push({
            text: key === 'totalCategoryCounter' ? 'Total' : 'Not in use',
            value: bigObject[key],
          })
        })
        setCounterData(localArray)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  const paginate = (pageNumber: any) => {
    setCurrentPage(pageNumber)
    const firstPageIndex = (pageNumber - 1) * PAGE_SIZE
    const lastPageIndex = firstPageIndex + PAGE_SIZE
    let _currentTableData = data.slice(firstPageIndex, lastPageIndex)
    setCurrentTableData(_currentTableData)
  }
  const imageUrl = toAbsoluteUrl('/media/no-image-icon.png');

  const bindGrid = (values: any) => {
    setIsLoading(true)
    setTimeout(() => {
      getCategories()
        .then((res: any) => {
          let _data = res.data.data
          const firstPageIndex = (currentPage - 1) * PAGE_SIZE
          const lastPageIndex = firstPageIndex + PAGE_SIZE
          let _currentTableData = _data.slice(firstPageIndex, lastPageIndex)
          setData(_data)

          setTotalRows(_data.length)

          if (_data.length > 0) {
            setCurrentTableData(_currentTableData)
          }
        })
        .catch(() => {
          setCurrentTableData([])
        })
        .finally(() => {
          setIsLoading(false)
        })
    }, 1000)
  }

  const resetData = () => {
    setStatus(0)
    setKeyword('')
    bindGrid(initialValues)
  }

  const filterData = () => {
    setCurrentTableData([])
    const filterValues = {
      ...initialValues,
      filter: keyword,
      status: parseInt(status || 0),
    }
    bindGrid(filterValues)
  }

  const openDeleteModal = (id: any) => {
    if (id > 0) {
      const confirmModel: IConfirmModel = {
        title: 'Cancel Invoice',
        btnText: 'Cancel this invoice',
        deleteUrl: `quotations/cancel/${id}`,
        message: 'cancel-invoice',
      }

      setConfirmModel(confirmModel)
      setTimeout(() => {
        setShowConfirm(true)
      }, 500)
    }
  }

  const afterConfirm = (res: any) => {
    if (res) bindGrid(initialValues)

    setShowConfirm(false)
  }

  const afterInvoiceUpdate = (res: any) => {
    if (res) bindGrid(initialValues)

    setShowInvoiceModal(false)
  }

  useEffect(() => {
    bindGrid(initialValues)
    bindCounters()
  }, [])
  const [show, setShow] = useState(false)
  const [itemId, setItemId] = useState(0)
  const onUpload = () => {
    bindGrid(initialValues)
    setShow(false)
  }

  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>Categories</PageTitle>
      <Fragment>
        <div className='card mb-5 mb-xl-10'>
          <div className='card-body pt-9 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
              <div className='flex-grow-1'>
                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      <CounterBox data={counterData} />
                    </div>
                  </div>

                  <div className='d-flex my-4'>
                    {/* begin::Filter Button */}
                    <button
                      // disabled={isLoading}
                      type='button'
                      className='btn btn-sm btn-secondary me-3'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                    >
                      <KTSVG path='/media/icons/duotune/general/gen031.svg' />
                      Filter
                    </button>
                    {/* end::Filter Button */}
                    {/* begin::SubMenu */}
                    <div
                      className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'
                      data-kt-menu='true'
                    >
                      {/* begin::Header */}
                      <div className='px-7 py-5'>
                        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
                      </div>
                      {/* end::Header */}

                      {/* begin::Separator */}
                      <div className='separator border-gray-200'></div>
                      {/* end::Separator */}

                      {/* begin::Content */}
                      <div className='px-7 py-5' data-kt-user-table-filter='form'>
                        {/* begin::Input group */}
                        <div className='mb-10'>
                          <label className='form-label fs-6 fw-bold'>Status:</label>
                          <select
                            className='form-select form-select-solid fw-bolder'
                            data-kt-select2='true'
                            data-placeholder='Select option'
                            data-allow-clear='true'
                            data-kt-user-table-filter='role'
                            data-hide-search='true'
                            onChange={(e) => setStatus(e.target.value)}
                            value={status}
                          >
                            <option value=''>All</option>
                            <option value='1'>Pending</option>
                            <option value='2'>Converted To Sale</option>
                          </select>
                        </div>
                        {/* end::Input group */}

                        {/* begin::Input group */}
                        <div className='mb-10'>
                          <label className='form-label fs-6 fw-bold'>Keyword:</label>
                          <input
                            className='form-control fw-bolder'
                            placeholder='Keyword'
                            onChange={(e) => setKeyword(e.target.value)}
                            value={keyword}
                          />
                        </div>
                        {/* end::Input group */}

                        {/* begin::Actions */}
                        <div className='d-flex justify-content-end'>
                          <button
                            type='button'
                            disabled={isLoading}
                            onClick={resetData}
                            className='btn btn-sm btn-light btn-active-light-primary fw-bold me-2 px-6'
                            data-kt-menu-dismiss='true'
                            data-kt-user-table-filter='reset'
                          >
                            Reset
                          </button>
                          <button
                            disabled={isLoading}
                            type='button'
                            onClick={filterData}
                            className='btn btn-sm btn-primary fw-bold px-6'
                            data-kt-menu-dismiss='true'
                            data-kt-user-table-filter='filter'
                          >
                            Apply
                          </button>
                        </div>
                        {/* end::Actions */}
                      </div>
                      {/* end::Content */}
                    </div>
                    {/* end::SubMenu */}
                    <Link to='add'>
                      <button
                        className='btn btn-sm btn-primary me-3'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_offer_a_deal'
                      >
                        Add New
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isLoading === false ? (
          <div className='row'>
            <div className='col-xl-12 col-md-12'>
              <div className='card'>
                <div className='card-body'>
                  <div className='table-responsive'>
                    <div className='display mb-4 dataTablesCard customer-list-table'>
                      <Table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                        <thead>
                          <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                            <th>Image</th>
                            <th>Name</th>
                            <th>Description</th>

                          </tr>
                        </thead>
                        <tbody>
                          {currentTableData && currentTableData.length > 0 ? (
                            currentTableData.map((item: any, index: any) => (
                              <tr key={index}>
                                <td>
                                  {' '}
                                  <div className='row'>
                                    <img
                                      src={item?.thumbPath ?? imageUrl}
                                      alt=''
                                      onError={(e) => {
                                        e.currentTarget.src = imageUrl;
                                      }
                                      }
                                      style={{ width: '100px', height: '70px' }}
                                      className='col-lg-8 col-md-8 col-sm-8 img-thumbnail'
                                    />
                                    <div className='col-lg-4 col-md-4 col-sm-4 align-self-center'>
                                      <i
                                        className='btn btn-sm fa fa-edit'
                                        onClick={
                                          () => {
                                            setShow(true)
                                            setItemId(item.id)
                                          }
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                </td>
                                <td className='text-gray-600 fw-bold'>
                                  <Link
                                    to={`/category/edit/${item.id}`}
                                    className='text-dark fw-bold'
                                  >
                                    <p className='text-hover-secondary mb-1'> {item.name}</p>
                                  </Link>
                                </td>
                                <td>{item.description}</td>

                              </tr>
                            ))
                          ) : (<tr>
                            <td colSpan={3}>
                              <NoDataFound isLoading={isLoading} />
                            </td>
                          </tr>
                          )}
                        </tbody>
                      </Table>
                      <Pagination
                        className='pagination-bar'
                        currentPage={currentPage}
                        totalCount={totalRows}
                        pageSize={PAGE_SIZE}
                        onPageChange={(page: any) => paginate(page)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {show && <AddCatThumb onUpload={onUpload} setShow={setShow} show={show} id={itemId} type='image' />}
          </div>
        ) : (
          <LoadData isLoading={isLoading} />
        )}
        { }
      </Fragment>
    </>
  )
}
