import React from "react";

export function Highlights() {
    return (
        <>
            <div  style={{ marginTop: '150px', marginBottom:'150px' }}>
                <div className="d-flex flex-stack flex-wrap flex-md-nowrap card-rounded shadow p-8 p-lg-12 mb-n5 mb-lg-n13 "
                    style={{ background: "linear-gradient(90deg, #20AA3E 0%, #03A588 100%);" }}>
                    {/*begin::Content*/}
                    <div className="my-2 me-5">
                        {/*begin::Title*/}
                        <div className="fs-1 fs-lg-2qx fw-bold  mb-2">Start With Amber Bills Today, 
                            <span className="fw-normal">enhance productivity!</span></div>
                        {/*end::Title*/}
                        {/*begin::Description*/}
                        <div className="fs-6 fs-lg-5  fw-semibold opacity-75">Try now to Stay Ahead</div>
                        {/*end::Description*/}
                    </div>
                    {/*end::Content*/}
                    {/*begin::Link*/}
                    <a href="/auth/registration/" className="btn btn-lg btn-outline border-2 btn-outline-primary flex-shrink-0 my-2">Try 14-day free trial</a>
                    {/*end::Link*/}
                </div>
            </div>

        </>
    )
}