import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { DisableSidebar } from '../../_metronic/layout/core'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { AddCustomer } from '../pages/customers/AddCustomer'
import { ListCustomers } from '../pages/customers/ListCustomers'
import { ListInvoices } from '../pages/invoices/ListInvoices'
import { ListProducts } from '../pages/catalog/ListProducts'
import { ListServices } from '../pages/catalog/ListServices'
import { SalesSummary } from '../pages/reports/salesSummary'
import { InvoiceDetails } from './../pages/invoices/InvoiceDetails';
import { InvoiceAdd } from '../pages/invoices/InvoiceAdd'
import { ReportIndex } from '../pages/reports/Index'
import { EditOrg } from '../pages/org/EditOrg'
import AccountPage from '../pages/account/AccountPage'
import { TaxSummary } from '../pages/reports/TaxSummary'
import { HsnSummary } from '../pages/reports/HsnSummary'
import { InvoiceAddFormV2 } from '../pages/invoices/partials/InvoiceAddFormV2'
import { InvoiceAddModal } from '../pages/invoices/partials/InvoiceAddModal'
import { ListPayments } from '../pages/payments/ListPayments'
import { AddPayment } from '../pages/payments/AddPayment'
import { ReleaseNotes } from '../pages/ReleaseNotes'
import AddSupplier from '../pages/supplier/AddSupplier'
import { ListSupplier } from '../pages/supplier/ListSupplier'
import { ChartsWidget1 } from '../../_metronic/partials/widgets'
import { Charts } from '../modules/widgets/components/Charts'
import { PaymentGraph } from '../pages/dashboard/partials/PaymentGraph'
import { ListPurchaseOrder } from '../pages/purchaseOrders/ListPurchaseOrder'
import { PurchaseOrderDetails } from '../pages/purchaseOrders/PurchaseOrderDetails'
import { PurchaseAddFormV2 } from '../pages/purchaseOrders/partials/PurchaseOrderAddForm'
import { ProductDetails } from '../pages/catalog/ProductDetails'
import { AddEditProduct } from '../pages/catalog/AddEditProduct'
import { ListCreditNotes } from '../pages/credit notes/ListCreditNotes'
import { CreditNoteAdd } from '../pages/credit notes/CreditNoteAdd'
import { CreditNoteAddForm } from '../pages/credit notes/c-nPartials/CreditNoteAddForm'
import { CreditNoteDetails } from '../pages/credit notes/CreditNoteDetails'
import { ListDebitNotes } from '../pages/debit notes/ListDebitNotes'
import { DebitNoteAdd } from '../pages/debit notes/DebitNoteAdd'
import { DebitNoteAddForm } from '../pages/debit notes/d-nPartials/DebitNoteAddForm'
import { DebitNoteDetails } from '../pages/debit notes/DebitNoteDetails'
import { InvoiceSettings } from '../pages/invoiceSettings/invoiceSettings'
import { ListDeliveryChallan } from '../pages/deliverychallan/ListDeliveryChallan'
import { AddDeliveryChallan } from '../pages/deliverychallan/AddDeliveryChallan'
import { AddBugReport } from '../pages/bugs report/ReportBugs'
import { ListBugs } from '../pages/bugs report/ListBugs'
import { DeliveryChallanDetail } from '../pages/deliverychallan/DeliveryChallanDetail'
import { ListStockAdjust } from '../pages/catalog/ListStockAdjust'
import { ListQuotation } from '../pages/quotations/ListQuotation'

import { QuotationDetails } from '../pages/quotations/QuotationDetails'
import { AddQuotation } from '../pages/quotations/AddQuotations'
import { ListCategory } from '../pages/category/ListCategory'
import { AddCategory } from '../pages/category/AddCategory'
import { Prefixes } from '../pages/settings/Prefixes'
import { ListOrganization } from '../pages/org/ListOrganization'
import { AddOrganization } from '../pages/org/AddOrganization'
import { AddEditService } from '../pages/catalog/AddEditService'
import { ListAdditionalTax } from '../pages/additional tax/ListAdditonalTax'
// import { AddAdditionalTax } from '../pages/additional tax/AddAdditionalTax'
import { Taxes } from '../pages/settings/taxes'
import { DocumentSettings } from '../pages/settings/DocumentSettings'
import { PricingInner } from '../pages/PricingInner'
import { Terms } from '../pages/settings/Terms'
import { Notes } from '../pages/settings/Notes'
import { BarcodePrint } from '../pages/barcode/BarcodePrint'
import { PointOfSale } from '../pages/point of sale/PointOfSale'
import SubHistory from '../modules/auth/components/SubHistory'
import { RegisterComplete } from '../modules/auth/components/RegisterComplete'


const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  //const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))


  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='customers' element={<ListCustomers />} />
        <Route path='customer/add' element={<AddCustomer />} />
        <Route path='customer/edit/:id' element={<AddCustomer />} />
        <Route path='invoices' element={<ListInvoices />} />
        <Route path='products' element={<ListProducts />} />
        <Route path='product/add' element={< AddEditProduct />} />
        <Route path='product/edit/:id' element={< AddEditProduct />} />
        <Route path='product/details/:id' element={<ProductDetails />} />
        <Route path='register-complete/:userKey' element={<RegisterComplete />} />


        <Route path='services' element={<ListServices />} />
        <Route path='service/add' element={<AddEditService />} />
        <Route path='service/edit/:id' element={<AddEditService />} />
        <Route path='purchase-orders' element={< ListPurchaseOrder />} />
        <Route path='purchase-orders/Details/:id' element={< PurchaseOrderDetails />} />
        <Route path='purchase-orders/p-cart' element={< PurchaseAddFormV2 />} />
        <Route path='reports' element={<ReportIndex />} />
        <Route path='reports/sales-summary' element={<SalesSummary />} />
        <Route path='reports/tax-summary' element={<TaxSummary />} />
        <Route path='reports/hsn-summary' element={<HsnSummary />} />
        {/* <Route path='invoice/add' element={<InvoiceAdd />} /> */}
        <Route path='invoices/add' element={<InvoiceAddFormV2 />} />
        <Route path='invoice/details/:id' element={<InvoiceDetails />} />
        <Route path='credit-notes' element={<ListCreditNotes />} />
        <Route path='credit-notes/add' element={<CreditNoteAdd />} />
        <Route path='credit-notes/c-cart' element={<CreditNoteAddForm />} />
        <Route path='credit-notes/details/:id' element={<CreditNoteDetails />} />
        <Route path='debit-notes' element={<ListDebitNotes />} />
        <Route path='debit-notes/add' element={<DebitNoteAdd />} />
        <Route path='debit-notes/d-cart' element={<DebitNoteAddForm />} />
        <Route path='debit-notes/details/:id' element={<DebitNoteDetails />} />
        <Route path='payments' element={<ListPayments />} />
        <Route path='payment/add' element={<AddPayment />} />
        <Route path='suppliers' element={<ListSupplier />} />
        <Route path='payment/edit/:id' element={<AddPayment />} />
        <Route path='suppliers/add' element={<AddSupplier />} />
        <Route path='suppliers/edit/:id' element={<AddSupplier />} />
        <Route path='Release-notes' element={<ReleaseNotes />} />
        <Route path='sample' element={<Charts />} />
        <Route path='invoiceSettings/add' element={<InvoiceSettings />} />
        <Route path='deliverychallan' element={<ListDeliveryChallan />} />
        <Route path='deliverychallan/add' element={<AddDeliveryChallan />} />
        <Route path='deliverychallan/details/:id/:customerId' element={<DeliveryChallanDetail />} />
        <Route path='bugs-report/add' element={<AddBugReport />} />
        <Route path='bugs-report' element={<ListBugs />} />
        <Route path='stocklist' element={<ListStockAdjust />} />
        <Route path='quotations' element={<ListQuotation />} />
        <Route path='quotation/details/:id' element={<QuotationDetails />} />
        <Route path='quotation/add' element={<AddQuotation />} />
        <Route path='category' element={<ListCategory />} />
        <Route path='category/add' element={<AddCategory />} />
        <Route path='category/edit/:id' element={<AddCategory />} />

        <Route path='organization' element={<ListOrganization />} />
        <Route path='organization/add' element={<AddOrganization />} />
        <Route path='taxes' element={<Taxes />} />
        <Route path='pricing' element={<PricingInner />} />
        <Route path='document-settings' element={<DocumentSettings />} />
        <Route path='terms' element={<Terms />} />
        <Route path='notes' element={<Notes />} />
        <Route path='barcode/print/:code' element={<BarcodePrint />} />
        <Route path='point-of-sale' element={<PointOfSale/>}/>
        <Route path= 'subscription-history' element={<SubHistory/>}/>
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return (
    <Suspense fallback={<TopBarProgress />}>
      <DisableSidebar>{children}</DisableSidebar>
    </Suspense>
  )
}

export { PrivateRoutes }
