import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { IConfirmModel } from '../../../_models/confirm-model'
import { PAGE_SIZE } from '../../../_shared'
import { ConfirmBox } from '../../../lib/ConfirmBox'
import { NoDataFound } from '../../../lib/NoData'
import Pagination from '../../../lib/Pagination/Pagination'
import { MenuComponent } from '../../../_metronic/assets/ts/components'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { getAdditionaltaxes, getallAdditionalTax, getAllTaxes } from '../../../services/AdditionalTaxService'
import { AddAdditionalTax } from '../additional tax/AddAdditionalTax'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export function Taxes() {
  const [counters, setCounters] = React.useState<any>({})
  const [data, setData] = React.useState([])
  const [currentTableData, setCurrentTableData] = React.useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRows, setTotalRows] = React.useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [role, setRole] = React.useState<any>()
  const [lastLogin, setLastLogin] = React.useState<any>()
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showFup, setShowFup] = useState<boolean>(false)
  const [confirmModel, setConfirmModel] = useState<IConfirmModel>()
  const [taxId, setTaxId] = useState<any>(0)

  const initialValues = {
    pageNumber: 1,
    pageSize: 100000,
    LeadId: 128,
    fromDate: '',
    toDate: '',
    filter: '',
    status: 0,
  }

  const paginate = (pageNumber: any) => {
    setCurrentPage(pageNumber)
    const firstPageIndex = (pageNumber - 1) * PAGE_SIZE
    const lastPageIndex = firstPageIndex + PAGE_SIZE
    let _currentTableData = data.slice(firstPageIndex, lastPageIndex)
    setCurrentTableData(_currentTableData)
  }

  const bindGrid = (values: any) => {
    setIsLoading(true)
    setTimeout(() => {
      getAllTaxes()
        .then((res) => {
          let _data = res.data.data

          const firstPageIndex = (currentPage - 1) * PAGE_SIZE
          const lastPageIndex = firstPageIndex + PAGE_SIZE
          let _currentTableData = _data.slice(firstPageIndex, lastPageIndex)

          setData(_data)
          setTotalRows(_data.length)
          if (_data.length > 0) {
            setCurrentTableData(_currentTableData)
          }
        })
        .catch(() => {
          setCurrentTableData([])
        })
        .finally(() => {
          setIsLoading(false)
        })
    }, 1000)
  }


  const openDeleteModal = (id: any) => {
    if (id > 0) {
      const confirmModel: IConfirmModel = {
        title: 'Delete enquiry',
        btnText: 'Delete this enquiry',
        deleteUrl: `enquiry/${id}`,
        message: 'delete-enquiry',
      }

      setConfirmModel(confirmModel)
      setTimeout(() => {
        setShowConfirm(true)
      }, 500)
    }
  }

  const afterConfirm = (res: any) => {
    if (res) bindGrid(initialValues)

    setShowConfirm(false)
  }

  const openFupModal = (id: any) => {
    setTaxId(id)
    setShowFup(true)
  }

  const afterFupModal = (res: any) => {
    setShowFup(false)
    bindGrid(initialValues)
  }

  useEffect(() => {
    MenuComponent.reinitialization()
    bindGrid(initialValues)
    getAdditionaltaxes().then((res: any) => {

    })
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>Taxes</PageTitle>
      {/* Filter space start */}
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between flex-wrap mb-2'>
                <div className='d-flex justify-items-center'>
                  {/* begin::Row */}


                  {/* end::Row */}
                </div>

                <div className='d-flex align-items-center'>
                  {/* begin::SubMenu */}
                  <a
                    href='#'
                    onClick={async () => openFupModal(0)}
                    className={`btn btn-sm btn-primary me-2`}
                    id='kt_charts_widget_2_year_btn'
                  >
                    Add New
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* filter space end */}

      <div className='row'>
        <div className="col-xs-12 col-md-12">
          <div className="notice d-flex bg-light-primary rounded border-primary-light border p-6 mb-3">
            <i className="bi bi-info-circle-fill fs-1 text-primary me-4"></i>
            <div className="d-flex flex-stack flex-grow-1" title='Info'>
              <div className="">
                <div className="fs-7 text-gray-700">Default tax slabs cannot be updated.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-12 col-md-12'>
          <div className='card'>
            <div className='card-body'>
              <div className='table-responsive'>
                <div className='display mb-4 dataTablesCard Lead-list-table'>
                  <Table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                    <thead>
                      <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                        <th>Tax Name</th>
                        <th>Tax Percent</th>
                        <th>Additional</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentTableData && currentTableData.length > 0 ? (
                        currentTableData.map((item: any, index: any) => (
                          <tr key={index}>
                            <td className='fw-bold text-dark'>
                              {item.taxName}
                            </td>
                            <td className=' '>{item.taxValue}</td>
                            <td className=' '>
                              {item.isAdditional ? (
                                <div className='badge badge-success'>Yes</div>
                              ) : (
                                <div className='badge badge-secondary'>No</div>
                              )}
                            </td>
                            <td className=''>
                              {item.companyId > 0 &&
                                <span className='mx-1'>
                                  <i
                                    className='bi bi-pencil'
                                    onClick={async () => openFupModal(item.id)}
                                    style={{ cursor: 'pointer' }}
                                  />
                                </span>

                              }
                            </td>
                          </tr>
                        ))
                      ) : (<tr>
                        <td colSpan={4}>
                          <NoDataFound setIsLoading={setIsLoading} />
                        </td>
                      </tr>

                      )}
                    </tbody>
                  </Table>
                  <Pagination
                    className='pagination-bar'
                    currentPage={currentPage}
                    totalCount={totalRows}
                    pageSize={PAGE_SIZE}
                    onPageChange={(page: any) => paginate(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showConfirm && <ConfirmBox confirmModel={confirmModel} afterConfirm={afterConfirm} />}
      {showFup && <AddAdditionalTax afterFupModal={afterFupModal} id={taxId} />}
    </>
  )
}
