export interface IBugReported {
    id: number;
    subject: string;
    message: string;
    reportedOn: Date | null;
    userId: number | null;
    attachmentId: number | null;
    iP: string;
}


export const bugInitValues: IBugReported = {
    id: 0,
    subject: '',
    message: '',
    reportedOn: new Date(),
    userId: 0,
    attachmentId: 0,
    iP: ''
}