export interface IProductModel {
    id: 0,
    productId: 0,
    name: '',
    shortDescription: string;
    fullDescription: string;
    sku: string;
    isTaxExempt: boolean;
    taxPercent: number | null,
    warehouseId: number;
    stockQuantity: number;
    minStockQuantity: number;
    price: number;
    barCode: string;
    uom: number | null;
    isAssembled: boolean | null;
    isComponent: boolean | null;
    hsn: string;
    manufacturerId: number | null;
    productGroupId: number | null;
    categoryId: number;
    currencyId: number;
    cess: number | 0;
    cessPercent: number | 0;
    unitPrice: number | 0;
    purchasePrice: number | null;
}

export const initialUserValues: IProductModel = {
    id: 0,
    productId: 0,
    name: '',
    shortDescription: '',
    fullDescription: '',
    sku: '',
    isTaxExempt: false,
    taxPercent: 0,
    warehouseId: 0,
    stockQuantity: 0,
    minStockQuantity: 0,
    price: 0,
    barCode: '',
    uom: null,
    isAssembled: null,
    isComponent: null,
    hsn: '',
    manufacturerId: null,
    productGroupId: null,
    categoryId: 0,
    currencyId: 0,
    cess: 0,
    cessPercent: 0,
    unitPrice: 0,
    purchasePrice: null
}