import axiosInstance from '../services/AxiosInstance';



export function searchDeliveryChallans(searchParams) {

    const {pageNumber,pageSize,fromDate,toDate,createdBy,status,customerId,dcFor,filter}=searchParams;
    
    return axiosInstance.get(`deliverychallans/search?pageNumber=${pageNumber}&pageSize=${pageSize}&fromDate=${fromDate}&toDate=${toDate}&createdBy=${createdBy}&status=${status}&customerId=${customerId}&dcFor=${dcFor}&filter=${filter}`, searchParams);
}
export function postdeliverychallan(data) {
    return axiosInstance.post(`deliverychallans`, data);
}
export function deliverychallanType() {
    return axiosInstance.get(`Shared/dcTypes`);
}
export function getDeliveryChallanDetails(values) {
    const{id,customerId}=values;
    return axiosInstance.get(`deliverychallans/get?id=${id}&customerId=${customerId}`,values);
}

export function getDeliveryChallanHtml(id, template, label, companyCode) {
    return axiosInstance.get(`deliverychallans/get-html?id=${id}&template=${template}&label=${label}&companyCode=${companyCode}`);
}
export function updateDChallan (data,type){
    // [HttpPut]
    //     [Route("ch-update/{type}")]
    return axiosInstance.put(`deliverychallans/ch-update/${type}`,data)
}