/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { dashboardRecentInvoice } from "../../../../services/InvoiceService"
import { Loader } from '../../../../app/pages/components/components'
import moment from 'moment'
import { Link } from "react-router-dom";

type Props = {
  className: string

}
const RecentInvoicesDashBoard: React.FC<Props> = ({ className }) => {
  const [datas, setData] = useState<any>([]);
  const [ setinvoice,getinvoice] = useState<any>([]);
  const initialValues = {
    pageNumber: 1,
    pageSize: 10,
    customerId: 128,
    fromDate: "",
    toDate: "",
    filter: "",
    status: 0,
  }

  const [isData, setIsData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const bindGrid = () => {
    setIsLoading(true);
    dashboardRecentInvoice().then((res: any) => {
      const array = res.data.data;
      if (res.data.success) {
        setData(array);
console.log(array);
        setIsData(true);
      }
      if (res.data.success === false) {
        setData([]);
      }
    }).catch((err: any) => {
      console.log(err);
    }).finally(() => {
      setIsLoading(false);
    });
  }



  

  useEffect(() => {
    bindGrid();
  }, []);

  return (
    <>
      {isLoading ? <div className=''>
        <Loader />
      </div> : <div className={`card shadow-sm ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Recent Invoices</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>{isData ? `Recent 5 invoices` : "No recent invoices since 1 week"}</span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <a
              href='/invoices/add'
              className='btn btn-sm btn-secondary'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_invite_friends'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              New Invoice
            </a>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 '>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-150px'>Invoice Number</th>
                  <th className='min-w-100px'>Name</th>
                  <th className='min-w-70px'>Date</th>
                  <th className='min-w-80px text-end'>Amount</th>


                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {
                  datas && datas.map((element: any, index: any) => (
                    <tr key={index}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                          { <Link to={`/invoice/details/${element.id}`}  className="text-dark fw-bold text-hover-primary mb-1"
                                                                    >  {element.invoiceNumber}
                                                                    </Link>
                            
                             }
                            
                            {/* <a href='#' onClick={invoiceno} className='text-dark fw-bold text-hover-primary'>
                            {element.invoiceNumber}
                             &nbsp;
                            </a> */}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className=''>
                              {element.billName}
                            </span>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div >
                          <div className=''>
                            <span className=''>
                              {moment(element.invoiceDate).format("DD-MM-yyyy")}
                            </span>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className=' text-end'>
                          <div className=''>
                            <span className='text-dark fw-bold'>
                              {element.amount.toFixed(2)}
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>}
    </>
  )
}

export { RecentInvoicesDashBoard }
