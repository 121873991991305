import React,{ useEffect, useState } from "react";
import { useFormik } from "formik";

import { toast } from "react-toastify";
import * as Yup from 'yup'
// lib
import { useLocation } from "react-router-dom";
// services
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toastNotify } from "../../../services/NotifyService";

import './style.css';
import { ICategoryClone,  categoryinitvalues as initialValues} from "../../../_models/category-model";

import { breadcrumbs } from "../components/PageTitle";
import { PageTitle } from "../../../_metronic/layout/core";
import { addCategory,  getcategorybyId, getcategoryDetails, saveCategory } from "../../../services/CategoryService";
import { left } from "@popperjs/core";
import { dataValue } from "react-widgets/esm/Accessors";
import { Console } from "console";
import { ServerMessage } from '../../../lib/NoData';
import { Loader, LOADINGSTAGES, ValidationField, ValidationSelect, ValidationTextArea } from '../components/components';
const profileDetailsSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').max(150, 'Cannot exceed 150 characters'),
    description: Yup.string()/*.required('Please enter valid Description')*/.max(150, 'Cannot exceed 150 characters'),
  
})

export function AddCategory(props: any) {
    const navigate = useNavigate();

    const [data, setData] = useState<any>(initialValues)
    const [stateNames, setStateNames] = useState<any>([]);
    const [taxNames, setTaxNames] = useState<any>([]);
    const { id } = useParams();
    const [custId, setCustId] = useState<any>(id);
    const [title, setTitle] = useState<any>(id == null ? "Add" : "Edit");

    const updateData = (fieldsToUpdate: Partial<ICategoryClone>): void => {
        const updatedData = Object.assign(data, fieldsToUpdate)
        setData(updatedData)
    }

    const [loading, setLoading] = useState(false)
    const formik = useFormik<ICategoryClone>({
    
        enableReinitialize: true,
        initialValues: data,
        validationSchema: profileDetailsSchema,
        onSubmit: (values, { resetForm }) => {
            const tostid = toast.loading("Please wait...");
            setLoading(true)
            setTimeout(() => {
                const updatedData = Object.assign(data, values);
                setData(updatedData);

                saveCategory(id,updatedData).then((res: any) => {
                    resetForm();
                    toastNotify(tostid, res);

                    redirect(); // redirect

                }).catch((err: any) => {

                    const res = { data: { message: 'Something went wrong', success: false } };
                    toastNotify(tostid, res);

                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                    resetForm();
                })
            }, 1000)
        },
    })

    const redirect = () => {
        setTimeout(() => {
            navigate("/category");
        }, 500)
    }


    
  
    const [loadingStages, setLoadingStages] = useState(LOADINGSTAGES.IDLE);
    const bindDetails = (id: any) => {
        setLoadingStages(LOADINGSTAGES.LOADING);
        setLoading(true);
        setTimeout(() => {
            getcategorybyId(id)
                .then((res: any) => {
                    if (res.data.success) {
                        setData(res.data.data);
                        setLoadingStages(LOADINGSTAGES.POSITIVE);
                    }
                    else { setLoadingStages(LOADINGSTAGES.NEGATIVE); }
                })
                .catch(() => {
                    setLoadingStages(LOADINGSTAGES.ERROR);
                }).finally(() => {
                    setLoading(false);
                });
        }, 500);
    }

    useEffect(() => {
      
        console.log(id);
        if (id != null) { bindDetails(id); }
        else { setLoadingStages(LOADINGSTAGES.POSITIVE); }
    }, []);

    return (
        <>
            <PageTitle breadcrumbs={breadcrumbs(
                {
                    title: "Category",
                    path: `/Category/${title.toLowerCase()}`,
                }
            )}>
                {title + " Category"}
            </PageTitle>
            <div>
                {loadingStages === LOADINGSTAGES.LOADING && <Loader />}
                {loadingStages === LOADINGSTAGES.NEGATIVE && <ServerMessage message="No data found" />}
                {loadingStages === LOADINGSTAGES.ERROR && <ServerMessage message="Something went wrong" />}
                {loadingStages === LOADINGSTAGES.POSITIVE && (
                    <form id='customer_form' onSubmit={formik.handleSubmit} noValidate className='form'>
                        <div className='card mb-5 mb-xl-10'>
                            <div className='card-body p-9'>
                                <div className='card-body border-top p-9'>
                                    {/* 1 */}
                                    <div className='row mb-6'>
                                        <ValidationField
                                            className='col-md-6'
                                            label='Name'
                                            isRequired
                                            name='name'
                                            type='text'
                                            placeholder='Category Name'
                                            formik={formik}
                                        />
                                
                                    </div>
                                    {/* 2 */}
                             
                                    {/* 3 */}
                                    <div className='row mb-6'>
                                        <ValidationTextArea
                                            className='col-md-6'
                                            label='Description'
                                            // isRequired
                                            name='description'
                                            placeholder='Enter Description'
                                            formik={formik}
                                        />
                                   
                                    </div>
                              
                             
                                    <div className='card-footer d-flex justify-content-center py-6 px-9'>
                                        <button type='submit' className='btn btn-primary'
                                            disabled={loading || formik.isSubmitting || !formik.isValid || !formik.touched}>
                                            <span className='indicator-label'>Submit</span>
                                            {(formik.isSubmitting) && (
                                                <span className='indicator-progress'>
                                                    Please wait...{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                        </button>
                                        <Link to={'/category'} className='btn btn-light mx-3'>
                                            Cancel
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>)}
            </div>
        </>
    )
}