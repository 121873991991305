import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getInvoiceHtml } from "../../../../services/InvoiceService";
import { Footer } from "./Footer";
import { InvoiceHeader } from "./InvoiceHeader";
import Navbar from "./Navbar";


export function InvoiceView() {
    const { invoiceId, companyCode, template } = useParams();

    const [isLoading, setIsLoading] = useState<any>(0);
    const [invHtml, setInvHtml] = useState<any>('loading...');

    const bindDetails = (invoiceId: any) => {
        setIsLoading(true);
        setTimeout(() => {
            getInvoiceHtml(invoiceId, template, 'Duplicate', companyCode)
                .then((res: any) => {
                    let _data = res.data.data;
                    setInvHtml(_data);
                    setIsLoading(false);
                })
                .catch(() => {
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }, 100);
    };

    useEffect(() => {
        bindDetails(invoiceId);
    }, [])

    return (
        <>
            <div className="d-flex flex-column flex-root" id="kt_app_root" style={{margin:'20px'}}>
                {/*begin::Header Section*/}
                <Navbar />
                {/*end::Header Section*/}
            </div>

            {/*begin::How It Works Section*/}
            <div className="mb-n10 mb-lg-n20 z-index-2">
                {/*begin::Container*/}
                <div className="container-fluid">
                    <div className="row">
                        <div className='col-md-8 offset-md-2'>
                            <div className="bg-light h-100 rounded">
                                <div className='px-20 py-10'>
                                    <div className='row'>
                                        <div className='col-md-12 py-5'>
                                            <a target={"_blank"} href={`${process.env.REACT_APP_API_URL}/Invoice/pdf/${invoiceId}/${companyCode}/${template}/Duplicate`}
                                                className={`btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1 fs-6`}
                                                id='kt_charts_widget_2_year_btn'
                                            >
                                                <i className='fas fa-print'></i> PDF
                                            </a>
                                        </div>
                                    </div>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <div dangerouslySetInnerHTML={{ __html: invHtml }}>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/*end::Container*/}
            </div>
            {/*end::How It Works Section*/}

            {/*begin::Statistics Section*/}
            <div className="mt-lg-100">
                &nbsp;
            </div>
            <div className="mt-lg-100">
                &nbsp;
            </div>

            {/*end::Statistics Section*/}

            <div style={{ marginTop: '100px' }}>

            </div>
            <Footer />
        </>
    )
}