import { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import * as Yup from 'yup'
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { ValidationField, ValidationTextArea } from '../components/components';
import { AdjustStock } from '../../../services/ProductService';
import { toastNotify } from '../../../services/NotifyService';



const editUserSchema = Yup.object().shape({
    stock: Yup.number()
        .min(1, 'Minimum 1 quantity')
        .max(1000, 'Maximum 1000 quantity')
        .required('Quantity is required'),
})


const StockAdjustModal = (props: any) => {
    const { data } = props;
    const [values, setValues] = useState<any>();

    const onCloseClick = () => {
        props.afterStockAdjust()
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues:{
            productId:data.productId,
            operation:0,
            stock: 0,
            remark:""
        },
        validationSchema: editUserSchema,
        onSubmit: async (values, { setSubmitting }) => {
            formik.setFieldValue('itemId',data.productId)
            setSubmitting(true)
            const toastId = toast.loading("Please wait...");
            setTimeout(() => {
                
                AdjustStock(values).then((res: any) => {
                    toastNotify(toastId, res);
                    onCloseClick();
                }).catch((err: any) => {
                    const res = { data: { message: 'Something went wrong', success: false } };
                    console.log(err);
                }).finally(() => {
                    setSubmitting(true)
                toast.dismiss(toastId)
                })
            }, 1000)
        },
    })

    useEffect(() => {

    }, []);

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {/* begin::Modal dialog */}
                {data &&

                    <form id='product_form' onSubmit={formik.handleSubmit} className='form'>
                        <div className='modal-dialog'>
                            {/* begin::Modal content */}
                            <div className='modal-content'>
                                <div className='modal-header'>
                                    {/* begin::Modal title */}
                                    <h2 className='fw-bolder'>Adjust stock quantity</h2>
                                    {/* end::Modal title */}

                                    {/* begin::Close */}
                                    <div
                                        className='btn btn-icon btn-sm btn-active-icon-primary'
                                        data-kt-users-modal-action='close'
                                        onClick={() => onCloseClick()}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                    </div>
                                    {/* end::Close */}
                                </div>
                                <div className='modal-body'>
                                    <div className='row mb-7'>
                                        <div className='col-lg-6'>
                                            <h6 className='fw-bold text-muted mb-1'>Item name </h6>
                                            <span className='fw-bolder fs-6 text-dark'>
                                                {data?.name}
                                            </span>
                                        </div>
                                        <div className='col-lg-6'>
                                            <h6 className='fw-bold text-muted mb-1'>Stock in hand </h6>
                                            <span className='fw-bolder fs-6 text-dark'>
                                                {data?.stockQuantity}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='row mb-7'>
                                        <div className='col-lg-6'>
                                            <h6 className='fw-bold text-muted mb-2'>Add/Reduce </h6>
                                            <div className="row" onChange={formik.handleChange}>
                                                <div className="col-md-6">
                                                    <input type={'radio'} name="operation" value={0} defaultChecked /> Add (+)
                                                </div>
                                                <div className="col-md-6">
                                                    <input type={'radio'} name="operation" value={1}/> Reduce (-)
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className='row mb-7'>
                                        <div className="col-md-12">
                                            <ValidationField
                                                className='col-md-6'
                                                label={'Adjust quantity'}
                                                min={1}
                                                // isRequired
                                                name='stock'
                                                type='text'
                                                placeholder={'Adjust quantity'}
                                                formik={formik}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mb-7'>
                                        <div className="col-md-12">
                                            <ValidationTextArea
                                                className='col-md-6'
                                                label={'Remarks'}
                                                // isRequired
                                                name='remark'
                                                type='text'
                                                placeholder={'Remarks'}
                                                formik={formik}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button onClick={() => onCloseClick()}
                                        type="button"
                                        className="btn btn-light"
                                        data-bs-dismiss="modal"
                                    >
                                        Cancel
                                    </button>
                                    <button type="submit" className="btn btn-primary">
                                        Save
                                    </button>
                                </div>
                                {/* end::Modal body */}
                            </div>
                            {/* end::Modal content */}
                        </div>
                    </form>
                }
                {/* end::Modal dialog */}
            </div>
            {/* begin::Modal Backdrop */}
            <div className='modal-backdrop fade show'></div>
            {/* end::Modal Backdrop */}
        </>
    )
}

export { StockAdjustModal }