import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ICustomerModel, customerInitValues as initialValues } from '../../modules/accounts/components/settings/SettingsModel'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { getSingleCustomer, saveCustomer } from "../../../services/CustomerService";
import { getStates, getTaxNames } from "../../../services/CommonService";
import { toastNotify } from '../../../services/NotifyService';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { EditTerms } from '../../../_shared/components/EditTerms';
import { PageTitle } from '../../../_metronic/layout/core';
import { breadcrumbs } from '../components/PageTitle';
import { Loader, LOADINGSTAGES, ValidationField, ValidationSelect, ValidationTextArea } from '../components/components';
import { ServerMessage } from '../../../lib/NoData';

const profileDetailsSchema = Yup.object().shape({
    fullName: Yup.string().required('Name is required').max(150, 'Cannot exceed 150 characters'),
    email: Yup.string().email('Please enter valid email').max(150, 'Cannot exceed 150 characters'),
    contactNo: Yup.string().required('Contact number is required').max(15, 'Cannot exceed 15 characters'),
    alternateNo: Yup.string().max(20, 'Cannot exceed 20 characters'),
    address: Yup.string().required('Address is required').max(500, 'Cannot exceed 500 characters'),
    city: Yup.string().required('City is required').max(50, 'Cannot exceed 50 characters'),
    stateId: Yup.string().required('State is required').test
        ('stateId', 'Please select state', function (value) {
            return value !== '0';
        }),
    gstin: Yup.string().max(20, 'Cannot exceed 20 characters'),
    postalCode: Yup.string().max(20, 'Cannot exceed 20 characters'),
    taxId: Yup.string().max(20, 'Tax type is required'),
})


export function AddBugReport(props: any) {
    const navigate = useNavigate();

    const [data, setData] = useState<any>(initialValues)
    const [stateNames, setStateNames] = useState<any>([]);
    const [taxNames, setTaxNames] = useState<any>([]);
    const { id } = useParams();
    const [custId, setCustId] = useState<any>(id);
    const [title, setTitle] = useState<any>(id == null ? "Add" : "Edit");

    const updateData = (fieldsToUpdate: Partial<ICustomerModel>): void => {
        const updatedData = Object.assign(data, fieldsToUpdate)
        setData(updatedData)
    }

    const [loading, setLoading] = useState(false)
    const formik = useFormik<ICustomerModel>({
        enableReinitialize: true,
        initialValues: data,
        validationSchema: profileDetailsSchema,
        onSubmit: (values, { resetForm }) => {
            const id = toast.loading("Please wait...");
            setLoading(true)
            setTimeout(() => {
                const updatedData = Object.assign(data, values)
                setData(updatedData)

                saveCustomer(custId, updatedData).then((res: any) => {
                    resetForm();
                    toastNotify(id, res);

                    redirect(); // redirect

                }).catch((err: any) => {
                    const res = { data: { message: 'Something went wrong', success: false } };
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                    resetForm();
                })
            }, 1000)
        },
    })

    const redirect = () => {
        setTimeout(() => {
            navigate("/customers");
        }, 500)
    }


    const bindStates = (countryId: any) => {
        getStates(countryId).then((res: any) => {
            const array = res.data.data;
            const states = array.map((item: any) => {
                return { id: item.id, name: item.stateName }
            })
            states.unshift({ id: 0, name: 'Select State' })
            setStateNames(states);
        }).catch((err: any) => {
            console.log(err);
        })
    }
    const bindTaxes = () => {
        getTaxNames().then((res: any) => {
            const array = res.data.data;
            const taxes = array.map((item: any) => {
                return { id: item.id, name: item.typeName }
            })
            setTaxNames(taxes);
        }).catch((err: any) => {
            console.log(err);
        })
    }
    const [loadingStages, setLoadingStages] = useState(LOADINGSTAGES.IDLE);
    const bindDetails = (custId: any) => {
        setLoadingStages(LOADINGSTAGES.LOADING);
        setLoading(true);
        setTimeout(() => {
            getSingleCustomer(custId)
                .then((res: any) => {
                    if (res.data.success) {
                        setData(res.data.data);
                        setLoadingStages(LOADINGSTAGES.POSITIVE);
                    }
                    else { setLoadingStages(LOADINGSTAGES.NEGATIVE); }
                })
                .catch(() => {
                    setLoadingStages(LOADINGSTAGES.ERROR);
                }).finally(() => {
                    setLoading(false);
                });
        }, 500);
    }

    useEffect(() => {
        bindStates(99);
        bindTaxes();
        console.log(id);
        if (id != null) { bindDetails(id); }
        else { setLoadingStages(LOADINGSTAGES.POSITIVE); }
    }, []);

    return (
        <>
            <PageTitle breadcrumbs={breadcrumbs(
                {
                    title: "Customers",
                    path: `/customers/${title.toLowerCase()}`,
                }
            )}>
                {title + " Customer"}
            </PageTitle>
            <div>
                {loadingStages === LOADINGSTAGES.LOADING && <Loader />}
                {loadingStages === LOADINGSTAGES.NEGATIVE && <ServerMessage message="No data found" />}
                {loadingStages === LOADINGSTAGES.ERROR && <ServerMessage message="Something went wrong" />}
                {loadingStages === LOADINGSTAGES.POSITIVE && (
                    <form id='customer_form' onSubmit={formik.handleSubmit} noValidate className='form'>
                        <div className='card mb-5 mb-xl-10'>
                            <div className='card-body p-9'>
                                <div className='card-body border-top p-9'>
                                    {/* 1 */}
                                    <div className='row mb-6'>
                                        <ValidationField
                                            className='col-md-6'
                                            label='Name'
                                            isRequired
                                            name='fullName'
                                            type='text'
                                            placeholder='Enter name'
                                            formik={formik}
                                        />
                                        <ValidationField
                                            className='col-md-6'
                                            label='Email'
                                            isRequired
                                            name='email'
                                            type='text'
                                            placeholder='Email'
                                            formik={formik}
                                        />
                                    </div>
                                    {/* 2 */}
                                    <div className='row mb-6'>
                                        <ValidationField
                                            className='col-md-6'
                                            label='Contact Number'
                                            isRequired
                                            name='contactNo'
                                            type='text'
                                            placeholder='Enter contact number'
                                            formik={formik}
                                        />
                                        <ValidationField
                                            className='col-md-6'
                                            label='Alternate Number'
                                            name='alternateNo'
                                            type='text'
                                            placeholder='Enter alternate number'
                                            formik={formik}
                                        />
                                    </div>
                                    {/* 3 */}
                                    <div className='row mb-6'>
                                        <ValidationTextArea
                                            className='col-md-6'
                                            label='Address'
                                            isRequired
                                            name='address'
                                            placeholder='Enter address'
                                            formik={formik}
                                        />
                                        <ValidationField
                                            className='col-md-6'
                                            label='GSTIN'
                                            name='gstin'
                                            type='text'
                                            placeholder='Enter GSTIN'
                                            formik={formik}
                                        />
                                    </div>
                                    <div className='row mb-6'>
                                        <ValidationField
                                            className='col-md-6'
                                            label='City'
                                            isRequired
                                            name='city'
                                            type='text'
                                            placeholder='Enter city'
                                            formik={formik}
                                        />
                                        <ValidationSelect
                                            className='col-md-6'
                                            label='State'
                                            isRequired
                                            name='stateId'
                                            options={stateNames}
                                            placeholder='Select state'
                                            formik={formik}
                                        />
                                    </div>
                                    <div className='row mb-6'>
                                        <ValidationSelect
                                            className='col-md-6'
                                            label='Taxation Type'
                                            isRequired
                                            name='taxId'
                                            options={taxNames}
                                            placeholder='Select taxation type'
                                            formik={formik}
                                        />
                                    </div>
                                    <div className='card-footer d-flex justify-content-center py-6 px-9'>
                                        <button type='submit' className='btn btn-primary'
                                            disabled={loading || formik.isSubmitting || !formik.isValid || !formik.touched}>
                                            <span className='indicator-label'>Submit</span>
                                            {(formik.isSubmitting) && (
                                                <span className='indicator-progress'>
                                                    Please wait...{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                        </button>
                                        <Link to={'/customers'} className='btn btn-light mx-3'>
                                            Cancel
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>)}
            </div>
        </>
    )
}