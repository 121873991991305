import {useState} from 'react'
import {Dropdown} from 'react-bootstrap'

export type IInvoiceType = 'draft' | 'cancel' | 'proforma' | 'invoice' | '';


interface StatusDropdownProps {
  onStatusClick: (status: IInvoiceType) => void
  isCancel: boolean
  isDraft: boolean
  isProforma: boolean
}

const InvoiceStatus: React.FC<StatusDropdownProps> = ({onStatusClick,
isCancel, isDraft, isProforma
}) => {
  const [showMenu, setShowMenu] = useState(false)

  const handleToggle = () => {
    setShowMenu(!showMenu)
  }

  return (
    <Dropdown onToggle={handleToggle} show={showMenu}>
      <Dropdown.Toggle
        variant='primary'
        id='dropdown-basic'
        className='btn btn-sm btn-color-muted btn-active-secondary active px-4 me-1'
      >
        Action
      </Dropdown.Toggle>
      <Dropdown.Menu className='dropdown-menu dropdown-menu-end'>
        <Dropdown.Item className='dropdown-item' onClick={() => onStatusClick('cancel')}
        disabled={isCancel}
        >
          Cancel Invoice
        </Dropdown.Item>
        <Dropdown.Item className='dropdown-item' onClick={() => onStatusClick('draft')}>
          {!isDraft ? 'Mark draft' : 'Publish'}
        </Dropdown.Item>
        <Dropdown.Item className='dropdown-item' onClick={() => onStatusClick('proforma')}>
          {!isProforma ? 'Mark proforma' : 'Mark Tax Invoice'}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default InvoiceStatus
