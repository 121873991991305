import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { createPortal } from 'react-dom'
import { IPurchaseOrderInput } from '../../interface/IInvoiceTypes/IPurchaseOrderModel'
import { IBillBy, IShipTo, IBillTo } from '../../interface/IInvoiceTypes/IQuotation'
import { IInputProps, IUpdateTypes, updatePOInput } from '../pConsts'
interface IPOData {
  supplierInfo: any,
  billBy: IBillBy
  shipTo: any
}
type Props = {
  id: any,
  type: string
  show: boolean
  handleClose: () => void
  billTo: IBillTo,
  shipTo: any,
  supplierInfo: any,
  setBillTo: (value: any) => void
  setShipTo: (value: any) => void
  setSupplierInfo: (value: any) => void
  initialData: IPOData
  onUpdate: (data: any) => void
}
const modalsRoot = document.getElementById('root-modals') || document.body

export default function POAddressForm({
  show,
  handleClose,
  supplierInfo,
  billTo,
  shipTo,
  type,
  setBillTo,
  setShipTo,
  setSupplierInfo,
  initialData,
  id,
  onUpdate }: Props) {


  const updatePOInfo = (type: any) => {
    var dto: any = {};
    dto.id = id;
    if (type === 'supplierInfo') {
      dto.supplierName = supplierInfo.supplierName;
      dto.supplierAddress = supplierInfo.supplierAddress;
      dto.supplierCity = supplierInfo.supplierCity;
      dto.supplierState = supplierInfo.supplierState;
      dto.supplierPostalCode = supplierInfo.supplierPostalCode;
      dto.supplierGstin = supplierInfo.supplierGstin;
    }
    if (type === 'billTo') {
      dto.billName = billTo.billName;
      dto.billAddress = billTo.billAddress;
      dto.billCity = billTo.billCity;
      dto.billState = billTo.billState;
      dto.billPostalCode = billTo.billPostalCode;
      dto.billBranchGstin = billTo.billBranchGstin || '';
      dto.billEmail = billTo.billEmail || '';
      dto.billContactNo = billTo.billContactNo || '';
    }
    if (type === 'shipTo') {
      dto.shipName = shipTo.billName;
      dto.shipAddress = shipTo.shipAddress;
      dto.shipCity = shipTo.shipCity;
      dto.shipState = shipTo.shipState;
      dto.shipPostalCode = shipTo.shipPostalCode;
      dto.shipBranchGstin = shipTo.shipBranchGstin || '';
      dto.shipEmail = shipTo.shipEmail || '';
      dto.shipContactNo = shipTo.billContactNo || '';
    }
    const props: IInputProps = {
      type,
      dto,
      onResponse: (res) => {
        if (res) {
          onUpdate(res);
          handleClose();
        }
      }
    }
    updatePOInput(props);
  }

  switch (type) {
    case 'supplierInfo':
      return createPortal(
        <Modal
          id='kt_modal_create_app'
          tabIndex={-1}
          aria-hidden='true'
          dialogClassName='modal-dialog modal-dialog-centered mw-900px'
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>Supplier info </h3>
            </Modal.Title>
          </Modal.Header>
          <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
            <div className='row'>
              <div className='col-md-6 mb-3'>
                <div className='form-group'>
                  <label className='form-label mb-1'>Supplier name</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Supplier name'
                    value={supplierInfo.supplierName}
                    onChange={(e) => setSupplierInfo({ ...supplierInfo, supplierName: e.target.value })}
                  />
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <div className='form-group'>
                  <label className='form-label mb-1'>Address</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter address'
                    value={supplierInfo.supplierAddress}
                    onChange={(e) => setSupplierInfo({ ...supplierInfo, supplierAddress: e.target.value })}
                  />
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <div className='form-group'>
                  <label className='form-label mb-1'>State</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter State'
                    value={supplierInfo.supplierState}
                    onChange={(e) => setSupplierInfo({ ...supplierInfo, supplierState: e.target.value })}
                  />
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <div className='form-group'>
                  <label className='form-label mb-1'>City</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter City'
                    value={supplierInfo.supplierCity}
                    onChange={(e) => setSupplierInfo({ ...supplierInfo, supplierCity: e.target.value })}
                  />
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <div className='form-group'>
                  <label className='form-label mb-1'>Postal Code</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter postal code'
                    value={supplierInfo.supplierPostalCode}
                    onChange={(e) => setSupplierInfo({ ...supplierInfo, supplierPostalCode: e.target.value })}
                  />
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <div className='form-group'>
                  <label className='form-label mb-1'>GSTIN</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter GSTIN'
                    value={supplierInfo.supplierGstin}
                    onChange={(e) => setSupplierInfo({ ...supplierInfo, supplierGstin: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </div>
          <Modal.Footer className='row justify-content-between'>
            <div className='col-lg-4'>
              <button
                type='button'
                className='px-10 btn btn-light font-weight-bold'
                onClick={() => {
                  handleClose()
                }}
              >
                Close
              </button>
            </div>
            <div className='col-lg-7 text-end'>
              {/* <button type='button' className='px-10 btn btn-primary font-weight-bold'
                                    onClick={handleClose}>
                                    Close
                                </button> */}
              <button
                type='button'
                className='px-10 p-3 btn btn-primary font-weight-bold'
                onClick={() => { updatePOInfo('supplierInfo'); }}
              >
                Save changes
              </button>
            </div>
          </Modal.Footer>
        </Modal>,
        modalsRoot
      )
    case 'billTo':
      return createPortal(
        <Modal
          id='kt_modal_create_app'
          tabIndex={-1}
          aria-hidden='true'
          dialogClassName='modal-dialog modal-dialog-centered mw-900px'
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>Bill To </h3>
            </Modal.Title>
          </Modal.Header>
          <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
            <div className='row'>
              <div className='col-md-6 mb-3'>
                <div className='form-group'>
                  <label className='form-label mb-1'>Bill Name</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Bill  Name'
                    value={billTo.billName}
                    onChange={(e) => setBillTo({ ...billTo, billName: e.target.value })}
                  />
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <div className='form-group'>
                  <label className='form-label mb-1'>Address</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter Address'
                    value={billTo.billAddress}
                    onChange={(e) => setBillTo({ ...billTo, billAddress: e.target.value })}
                  />
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <div className='form-group'>
                  <label className='form-label mb-1'>State</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter State'
                    value={billTo.billState}
                    onChange={(e) => setBillTo({ ...billTo, billState: e.target.value })}
                  />
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <div className='form-group'>
                  <label className='form-label mb-1'>City</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter City'
                    value={billTo.billCity}
                    onChange={(e) => setBillTo({ ...billTo, billCity: e.target.value })}
                  />
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <div className='form-group'>
                  <label className='form-label mb-1'>Postal Code</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter Postal Code'
                    value={billTo.billPostalCode}
                    onChange={(e) => setBillTo({ ...billTo, billPostalCode: e.target.value })}
                  />
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <div className='form-group'>
                  <label className='form-label mb-1'>GSTIN</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter GSTIN'
                    value={billTo.billBranchGstin}
                    onChange={(e) => setBillTo({ ...billTo, billBranchGstin: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </div>
          <Modal.Footer className='row justify-content-between'>
            <div className='col-lg-4'>
              <button
                type='button'
                className='px-10 btn btn-light font-weight-bold'
                onClick={() => {
                  handleClose()
                }}
              >
                Close
              </button>
            </div>
            <div className='col-lg-7 text-end'>
              {/* <button type='button' className='px-10 btn btn-primary font-weight-bold'
                                    onClick={handleClose}>
                                    Close
                                </button> */}
              <button
                type='button'
                className='px-10 p-3 btn btn-primary font-weight-bold'
                onClick={() => { updatePOInfo('billTo'); }}
              >
                Save changes
              </button>
            </div>
          </Modal.Footer>
        </Modal>,
        modalsRoot
      )
    case 'shipTo':
      return createPortal(
        <Modal
          id='kt_modal_create_app'
          tabIndex={-1}
          aria-hidden='true'
          dialogClassName='modal-dialog modal-dialog-centered mw-900px'
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Ship To</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row'>
              <div className='col-md-6 mb-3'>
                <div className='form-group'>
                  <label className='form-label mb-1'>Ship name</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter bill Name'
                    value={shipTo.shipName}
                    onChange={(e) => setShipTo({ ...shipTo, shipName: e.target.value })}
                  />
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <div className='form-group'>
                  <label className='form-label mb-1'>Address</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter address'
                    value={shipTo.shipAddress}
                    onChange={(e) => setShipTo({ ...shipTo, shipAddress: e.target.value })}
                  />
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <div className='form-group'>
                  <label className='form-label mb-1'>City</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter city'
                    value={shipTo.shipCity}
                    onChange={(e) => setShipTo({ ...shipTo, shipCity: e.target.value })}
                  />
                </div>
              </div>

              <div className='col-md-6 mb-3'>
                <div className='form-group'>
                  <label className='form-label mb-1'>Postal code</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter Pin'
                    value={shipTo.shipPostalCode}
                    onChange={(e) => setShipTo({ ...shipTo, shipPostalCode: e.target.value })}
                  />
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <div className='form-group'>
                  <label className='form-label mb-1'>GSTIN</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter bill to GSTIN'
                    value={shipTo.shipBranchGstin ?? ''}
                    onChange={(e) => setShipTo({ ...shipTo, shipBranchGstin: e.target.value })}
                  />
                </div>
              </div>

              <div className='col-md-6 mb-3'>
                <div className='form-group'>
                  <label className='form-label mb-1'>Email</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter email'
                    value={shipTo.shipToEmail ?? ''}
                    onChange={(e) => setBillTo({ ...shipTo, shipToEmail: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className='row justify-content-between'>
            <div className='col-lg-4'>
              <button
                type='button'
                className='btn btn-secondary font-weight-bold mr-2'
                onClick={handleClose}
              >
                Close
              </button>
            </div>
            <div className='col-auto'>
              <button
                type='button'
                className='btn btn-primary font-weight-bold'
                onClick={() => { updatePOInfo('shipTo') }}
              >
                Save changes
              </button>
            </div>
          </Modal.Footer>
        </Modal>,
        modalsRoot
      )
    default:
      return null
  }
}
