import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { number } from 'yup';
import { getDocumentLabels } from '../../../../services/CommonService';
import { getDeliveryChallanHtml } from '../../../../services/DeliveryChallanService';
import { getInvoiceDetails, getInvoiceHtml } from '../../../../services/InvoiceService';
import { KTSVG } from '../../../../_metronic/helpers';
import InvoiceMail from './InvoiceMail';
import { InvoiceShare } from './ShareModal';

const InvoiceTemplateSelect = (props: any) => {
    const navigate = useNavigate();

    const { setShowInvoiceModal, invoiceId, companyCode } = props;
    const [isLoading, setIsLoading] = useState<any>(invoiceId || 0);
    const [invHtml, setInvHtml] = useState<any>('loading...');
    const [template, setTemplate] = useState<any>('classic');
    const [docLabel, setDocLabel] = useState<any>('Original Copy');
    const [labelArr, setLabelArr] = useState<any[]>([]);
    const [showShare, setShowShare] = useState<any>(false);
    const [showEmail, setShowEmail] = useState<any>(false);

    const toggleShowShare = () => {
        setShowShare(true);
    }
    const toggleShowEmail = () => {
        setShowEmail(true);
    }


    const setShowShareModal = () => {
        setShowShare(false);
    }


    const closeModal = () => {
        if (setShowInvoiceModal) {
            setShowInvoiceModal(false);
        } else {
            { navigate('/invoices'); }
        }
    }

    
    const bindDetails = (invoiceId:any) => {
        setIsLoading(true);
    
        setTimeout(() => {
            getDeliveryChallanHtml(1, template, docLabel, companyCode)
                .then((res: any) => {
            
                    let _data = res.data.data;
                    setInvHtml(_data);
                    setIsLoading(false);
                })
                .catch(() => {
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }, 100);
    };

    const bindLabels = () => {
        setIsLoading(true);
        setTimeout(() => {
            getDocumentLabels()
                .then((res: any) => {
                    let _data = res.data.data;
                    setLabelArr(_data);
                    setIsLoading(false);
                })
                .catch(() => {
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }, 100);
    };

    useEffect(() => {
        if (invoiceId > 0) bindDetails(invoiceId);
    }, []);

    useEffect(() => {
        if (invoiceId > 0) bindDetails(invoiceId);
    }, [docLabel]);


    useEffect(() => {
        bindLabels();
        bindDetails(invoiceId);
    }, [template])

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered modal-fullscreen'>
                    {/* begin::Modal content */}
                    <div className='modal-content'>
                        <div className='modal-header'>
                            {/* begin::Modal title */}
                            <h2 className='fw-bolder'>Preview Invoice</h2>
                            {/* end::Modal title */}

                            {/* begin::Close */}
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                onClick={() => closeModal()}
                                style={{ cursor: 'pointer' }}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                            </div>
                            {/* end::Close */}
                        </div>
                        <div className='modal-body'>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <div className='fv-row mb-10'>
                                        <div className='mb-0 fv-row'>
                                            <label className='d-flex align-items-center form-label mb-5'>
                                                Select invoice template
                                                <i
                                                    className='fas fa-exclamation-circle ms-2 fs-7'
                                                    data-bs-toggle='tooltip'
                                                    title='Select invoice template of your choice'
                                                ></i>
                                            </label>

                                            <div className='mb-0'>
                                                <label className='d-flex flex-stack mb-5 cursor-pointer'>
                                                    <span className='d-flex align-items-center me-2'>
                                                        <span className='symbol symbol-50px me-6'>
                                                            <span className='symbol-label'>
                                                                <KTSVG
                                                                    path='/media/icons/duotune/finance/fin001.svg'
                                                                    className='svg-icon-1 svg-icon-gray-600'
                                                                />
                                                            </span>
                                                        </span>

                                                        <span className='d-flex flex-column'>
                                                            <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                                                                Classic
                                                            </span>
                                                            <span className='fs-6 fw-bold text-gray-400'>
                                                                Plain old school invoice template
                                                            </span>
                                                        </span>
                                                    </span>

                                                    <span className='form-check form-check-custom form-check-solid'>
                                                        <input className='form-check-input' type='radio' name='template' value={template} onChange={() => setTemplate('classic')}
                                                            checked={template === 'classic'}
                                                        />
                                                    </span>
                                                </label>

                                                <label className='d-flex flex-stack mb-5 cursor-pointer'>
                                                    <span className='d-flex align-items-center me-2'>
                                                        <span className='symbol symbol-50px me-6'>
                                                            <span className='symbol-label'>
                                                                <KTSVG
                                                                    path='/media/icons/duotune/graphs/gra006.svg'
                                                                    className='svg-icon-1 svg-icon-gray-600'
                                                                />
                                                            </span>
                                                        </span>

                                                        <span className='d-flex flex-column'>
                                                            <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                                                                Modern
                                                            </span>
                                                            <span className='fs-6 fw-bold text-gray-400'>Impress clients with a beautiful, modern invoice</span>
                                                        </span>
                                                    </span>

                                                    <span className='form-check form-check-custom form-check-solid'>
                                                        <input className='form-check-input' type='radio' name='template' value={template} onChange={() => setTemplate('modern')} />
                                                    </span>
                                                </label>

                                                <label className='d-flex flex-stack mb-0 cursor-pointer'>
                                                    <span className='d-flex align-items-center me-2'>
                                                        <span className='symbol symbol-50px me-6'>
                                                            <span className='symbol-label'>
                                                                <KTSVG
                                                                    path='/media/icons/duotune/graphs/gra008.svg'
                                                                    className='svg-icon-1 svg-icon-gray-600'
                                                                />
                                                            </span>
                                                        </span>

                                                        <span className='d-flex flex-column'>
                                                            <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                                                                Custom
                                                            </span>
                                                            <span className='fs-6 fw-bold text-gray-400'>
                                                                Use images to enhance time travel rivers
                                                            </span>
                                                        </span>
                                                    </span>

                                                    <span className='form-check form-check-custom form-check-solid'>
                                                        <input className='form-check-input' type='radio' name='template' value={template} onChange={() => setTemplate('custom')} />
                                                    </span>
                                                </label>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='fv-row'>
                                        <div className='mb-0 fv-row'>
                                            <label className='d-flex align-items-center form-label mb-5'>
                                                Document label
                                                <i
                                                    className='fas fa-exclamation-circle ms-2 fs-7'
                                                    data-bs-toggle='tooltip'
                                                    title='Document label'
                                                ></i>
                                            </label>
                                            <div>
                                                <select className='form-control' onChange={(e) => setDocLabel(e.target.value)}>
                                                    {labelArr && labelArr.map((lbl: any, index: number) => (
                                                        <option value={lbl.labelText}>{lbl.labelText}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-9'>
                                    <div className="bg-light h-100 rounded">
                                        <div className='px-20 py-10'>
                                            <div className='row'>
                                                <div className='col-md-12 py-5'>
                                                    <a target={"_blank"} href={`${process.env.REACT_APP_API_URL}/deliverychallans/pdf/${invoiceId}/${companyCode}/${template}/Duplicate`}
                                                        className={`btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1`}
                                                        id='kt_charts_widget_2_year_btn'
                                                    >
                                                        <i className='fas fa-print'></i> PDF
                                                    </a>
                                                    <a href="#"
                                                        onClick={() => toggleShowShare()}
                                                        className={`btn btn-sm btn-color-muted btn-active btn-active-success active px-4 me-1`}
                                                        id='kt_charts_widget_2_year_btn'
                                                    >
                                                        <i className='fas fa-share'></i> Share
                                                    </a>
                                                    <a href="#"
                                                        onClick={() => toggleShowEmail()}
                                                        className={`btn btn-sm
                                                         btn-color-muted btn-active btn-active-info active px-4 me-1`}
                                                        id='kt_charts_widget_2_year_btn'
                                                    >
                                                        <i className='fas fa-voicemail'></i> Email
                                                    </a>
                                                </div>
                                            </div>
                                            <div className='card'>
                                                <div className='card-body'>
                                                    <div dangerouslySetInnerHTML={{ __html: invHtml }}>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* end::Modal body */}
                    </div>
                    {/* end::Modal content */}
                </div>
                {/* end::Modal dialog */}
            </div>
            {showShare && <InvoiceShare 
            setShowShareModal={setShowShareModal} invoiceId={invoiceId} companyCode={companyCode} template={template} />}
            {/* begin::Modal Backdrop */}
            {showEmail && <InvoiceMail setShowEmail={setShowEmail} invoiceId={invoiceId} companyCode={companyCode} template={template}/>}

            <div className='modal-backdrop fade show'></div>
  {/* end::Modal Backdrop */}
        </>
    )
}

export { InvoiceTemplateSelect }
