

import React from 'react'
import "./Switch.css";
import { useField } from 'formik';
function Switch({ ...props }) {
    const [field, meta, helpers] = useField(props.name);
    const { setValue } = helpers;
    const { value } = meta;
    const isSelected = (v: any) => (v === value ? 'selected' : '');

    return (
        <div>
            <label className='switch'>
                <input
                    type='checkbox'
                    //{...field}
                    checked={value}
                    {...props}
                />
                <span className="slider">

                </span>
            </label>
        </div>
    )
}

export default Switch;

export const RadioButton = ({ ...props }) => {
    const { leftLabel, rightLabel, label, name, options, ...rest } = props;
    return (
        <div className='form-check form-check-custom form-check-solid'>
            <div className='container-fluid'>
                <span className='col-5 text-right'>{leftLabel}</span>
                {" "}
                <label className="switch">
                    <input type="checkbox" name={name} {...rest} />
                    {label}
                    <span className="slider round"></span>
                </label>
                {" "}
                <span className='col-5 text-left'>{rightLabel}</span>
            </div>
        </div>
    )
}
export const ToggleSwitch = (props: any) => {
    const { label } = props;

    return (
      <div className='container'>
        <label className='fw-bold fs-6'>{label}</label>
        {''}
        <br />
        <div className='toggle-switch'>
          <input
            type='checkbox'
            className='checkbox'
            name={label}
            id={label}
            onChange={props.onChange}
          />
          <label className='label' htmlFor={label}>
            <span className='inner' />
            <span className='switch' />
          </label>
        </div>
      </div>
    )
};
export interface ToggleSwitchProps {
  value: boolean;
  onChange: (e: any) => void;
  name: string;
}

export const ToggleSwitchNonLabel = (props: ToggleSwitchProps) => {
  const { value, onChange } = props;

  return (
    <div className='container'>
      <div className='toggle-switch'>
        <input
          type='checkbox'
          className='checkbox'
          checked={value}
          name={props.name}
          id={props.name}
          onChange={(e:any) => onChange(e)}
        />
        <label className='label' htmlFor={props.name}>
          <span className='inner' />
          <span className='switch' />
        </label>
      </div>
    </div>
  );
};

export const ToggleSwitchTax = (props: any) => {
    const { label } = props;

    return (
      <div className='container'>
        <label className='fw-bold fs-6'>{label}</label>
        {''}
        <br />
        <div className='toggle-switch'>
          <input
            type='checkbox'
            className='checkbox'
            name={label}
            id={label}
            checked={props.checked}
            onChange={props.onChange}
          />
          <label className='label' htmlFor={label}>
            <span className='inner' />
            <span className='switch' />
          </label>
        </div>
      </div>
    )
};

