import ApexCharts, { ApexOptions } from 'apexcharts'
import React, { useEffect, useState, useRef } from "react";
import { getCSSVariableValue } from "../../../_metronic/assets/ts/_utils";
import { KTSVG } from "../../../_metronic/helpers";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { useThemeMode } from "../../../_metronic/partials/layout/theme-mode/ThemeModeProvider";
import { dailySales } from "../../../services/InvoiceService"
import { PAGE_SIZE } from "../../../_shared";
import { Table } from 'react-bootstrap';
import Pagination from '../../../lib/Pagination/Pagination';
import moment from 'moment';
import { LoadData, NoDataFound, ServerMessage } from '../../../lib/NoData';
import { DatePicker } from 'react-widgets/cjs';
import { IReportSearchParams } from '../../../_models/report-search-model';
import * as XLSX from "xlsx";

const profileBreadCrumbs: Array<PageLink> = [
    {
        title: 'Home',
        path: '/',
        isSeparator: false,
        isActive: false,
    },
    {
        title: 'Reports',
        path: '/reports',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const chartOptions = (chartHeight: string, chartColor: string): ApexOptions => {
    const labelColor = getCSSVariableValue('--kt-gray-800')
    const strokeColor = getCSSVariableValue('--kt-gray-300')
    const baseColor = getCSSVariableValue('--kt-' + chartColor)
    const lightColor = getCSSVariableValue('--kt-' + chartColor + '-light')

    return {
        series: [
            {
                name: 'Net Profit',
                data: [30, 25, 45, 30, 55, 55],
            },
        ],
        chart: {
            fontFamily: 'inherit',
            type: 'area',
            height: chartHeight,
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
            sparkline: {
                enabled: true,
            },
        },
        plotOptions: {},
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        fill: {
            type: 'solid',
            opacity: 1,
        },
        stroke: {
            curve: 'smooth',
            show: true,
            width: 3,
            colors: [baseColor],
        },
        xaxis: {
            categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: false,
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
            crosshairs: {
                show: false,
                position: 'front',
                stroke: {
                    color: strokeColor,
                    width: 1,
                    dashArray: 3,
                },
            },
            tooltip: {
                enabled: false,
            },
        },
        yaxis: {
            min: 0,
            max: 60,
            labels: {
                show: false,
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
            y: {
                formatter: function (val: any) {
                    return '$' + val + ' thousands'
                },
            },
        },
        colors: [lightColor],
        markers: {
            colors: [lightColor],
            strokeColors: [baseColor],
            strokeWidth: 3,
        },
    }
}

export function SalesSummary() {
    const chartRef = useRef<HTMLDivElement | null>(null)
    const { mode } = useThemeMode()
    const refreshChart = () => {
        if (!chartRef.current) {
            return
        }
        const chartColor = 'primary'
        const chartHeight = '150px'
        const chart = new ApexCharts(chartRef.current, chartOptions(chartHeight, chartColor))
        if (chart) {
            chart.render()
        }

        return chart
    }

    // grid data
    const [data, setData] = React.useState<any[]>([]);
    const [metaData, setMetaData] = React.useState<any>({ totalSaleWithTax: 0, totalSaleWithoutTax: 0, totalTax: 0, invoiceCount: 0 });
    const [currentTableData, setCurrentTableData] = React.useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState<any>(1);
    const [totalRows, setTotalRows] = React.useState(0);
    const [active, setActive] = useState({ tm: true, lm: false, thm: false });
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const currentMonth = new Date().getMonth() + 1;
    const [monthArr] = useState<any>({
        thisMonth: [currentMonth],
        lastMonth: [currentMonth - 1],
        last3Month: [currentMonth, currentMonth - 1, currentMonth - 2],
    });


    const [year] = useState<any>(new Date().getFullYear());

    const monthName = (mon: any) => {
        return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][mon - 1];
    }
    const [selectedMonths, setSelectedMonths] = useState<any>(monthName(currentMonth));

    const initialValues = {
        months: monthArr.thisMonth,
        year: year,
    }

    const [filterValues, setFilterValues] = useState<any>({
        pageNumber: 1,
        pageSize: 100000,
        status: [],
        isProforma: false,
        isTax: false,
        isBoth: false,
        customerId: 0,
        filter: "",
        fromDate: "",
        toDate: "",
    });

    const paginate = (pageNumber: any) => {
        setCurrentPage(pageNumber);
        const firstPageIndex = (pageNumber - 1) * PAGE_SIZE;
        const lastPageIndex = firstPageIndex + PAGE_SIZE;
        let _currentTableData = data.slice(firstPageIndex, lastPageIndex);
        setCurrentTableData(_currentTableData);
    };

    const LOADINGSTAGES = {
        IDLE: 0,
        LOADING: 1,
        POSITIVE: 3,
        NEGATIVE: 4,
        ERROR: 5,
        EMPTY: 6
    }

    const downloadExcel = () => {

        const array: any[] = [];
        const obj: any[] = data;
    
        obj.forEach((element) => {
          array.push({
            'Invoice date (dd-mm-yyyy)': moment(element.invoiceDate).format('DD-MM-YYYY'),
            'Taxable amount': element.taxableAmount.toFixed(2),
            'Tax amount': element.taxAmount.toFixed(2)
          });
        });

        const worksheet = XLSX.utils.json_to_sheet(array);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "SaleSummary");

        XLSX.writeFile(workbook, "Sales-summary.xlsx");
    }


    const [loadingStage, setLoadingStage] = useState<any>(LOADINGSTAGES.IDLE);
    const [isLoading, setIsLoading] = useState<any>(false);

    const bindGrid = (cp: any, values: any) => {
        setTimeout(() => {
            setIsLoading(true);
            setLoadingStage(LOADINGSTAGES.LOADING);
            dailySales(values)
                .then((res: any) => {
                    if (res.data.success) {
                        let _data = res.data.data.invoiceDailyModel;

                        const firstPageIndex = (cp > 0 ? 0 : currentPage - 1) * PAGE_SIZE;
                        const lastPageIndex = firstPageIndex + PAGE_SIZE;
                        let _currentTableData = _data.slice(firstPageIndex, lastPageIndex);

                        setData(_data);
                        setMetaData(res.data.data);
                        setTotalRows(_data.length);
                        if (_data.length > 0) {
                            setLoadingStage(LOADINGSTAGES.POSITIVE);
                            setCurrentTableData(_currentTableData);
                        } else {
                            setLoadingStage(LOADINGSTAGES.EMPTY);
                            setCurrentTableData([]);
                        }
                    } else {
                        setLoadingStage(LOADINGSTAGES.EMPTY);
                    }
                })
                .catch(() => {
                    setLoadingStage(LOADINGSTAGES.ERROR);
                    setCurrentTableData([]);
                }).finally(() => {
                    setIsLoading(false);
                });
        }, 1000);
    };


    const refreshData = (args: any, monthArr: any) => {
        setCurrentPage(1);
        args === 'tmState' ? setActive({ tm: true, lm: false, thm: false }) :
            args === 'lmState' ? setActive({ tm: false, lm: true, thm: false }) :
                setActive({ tm: false, lm: false, thm: true });

        const filterValues = {
            months: monthArr,
            year: year,
        }
        let monNames = "";
        monthArr.reverse().forEach((element: any) => {
            monNames = monNames.concat(monthName(element), ", ");
        });
        setSelectedMonths(monNames.replace(/,\s*$/, ""));

        bindGrid(1, filterValues);
    }

    const filterData = () => {
        // const arrayToint = filterValues.status.map((item: any) => parseInt(item));
        const values: IReportSearchParams = {
            companyId: 0,
            months: [],
            year: 0,
            fromDate: startDate.toLocaleDateString(),
            toDate: endDate.toLocaleDateString(),
        }
        bindGrid(0, values);
    }

    const resetData = () => {
        setFilterValues(
            { filter: "", customerId: 0 }
        );
        setTimeout(() => {
            bindGrid(0, initialValues);
        }, 100);
    }

    const setFromDate = (e: any) => {
        
    }

    useEffect(() => {
        bindGrid(0, initialValues);
    }, []);

    useEffect(() => {
        const chart = refreshChart()

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartRef, mode])

    const [dates, setDates] = useState<any>({
        fromDate: "",
        toDate: "",
    })

    return <>
        <PageTitle breadcrumbs={profileBreadCrumbs}>Sales Summary</PageTitle>
        <div className="d-flex flex-row">
            <div className="d-flex flex-column flex-row-fluid">

                <div className="d-flex flex-row flex-column-fluid">
                    <div className="d-flex flex-row-fluid flex-start mb-5">
                        {
                            data && data.length > 0 &&
                            <button type='button' onClick={() => { refreshData('tmState', monthArr.thisMonth); downloadExcel() }}
                                className={`btn btn-sm btn-color-muted btn-active btn-active-secondary active px-4 me-1`}
                                id='kt_charts_widget_2_year_btn'
                            >
                                <i className='fas fa-download'></i> Download
                            </button>
                        }
                    </div>

                    <div className="d-flex flex-row-fluid flex-end mb-5">
                        {/* begin::Toolbar */}
                        <div className='card-toolbar align-items-end ' data-kt-buttons='true'>
                            <a onClick={() => refreshData('tmState', monthArr.thisMonth)}
                                className={`btn btn-sm btn-color-muted btn-active btn-active-primary ${active.tm === true ? "active" : ""} px-4 me-1`}
                                id='kt_charts_widget_2_year_btn'
                            >
                                This Month
                            </a>

                            <a onClick={() => refreshData('lmState', monthArr.lastMonth)}
                                className={`btn btn-sm btn-color-muted btn-active btn-active-primary ${active.lm === true ? "active" : ""} px-4 me-1`}
                                id='kt_charts_widget_2_month_btn'
                            >
                                Last Month
                            </a>

                            <a onClick={() => refreshData('thmState', monthArr.last3Month)}
                                className={`btn btn-sm btn-color-muted btn-active btn-active-primary ${active.thm === true ? "active" : ""} px-4 me-1`}
                                id='kt_charts_widget_2_week_btn'
                            >
                                Last 3 Months
                            </a>

                            {/* begin::Filter Button */}
                            <button onClick={() => setActive({ tm: false, lm: false, thm: false })}
                                type='button'
                                className={`btn btn-sm btn-color-primary btn-active  ${active.lm === false
                                    && active.tm === false
                                    && active.thm === false ? "active" : ""} px-4 me-1`}
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                            >
                                <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                            </button>
                            {/* end::Filter Button */}
                            {/* begin::SubMenu */}
                            <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
                                {/* begin::Header */}
                                <div className='px-7 py-5'>
                                    <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
                                    <input className='form-select' placeholder='From Date'
                                        type={"date"}
                                        value={dates.fromDate}
                                        onChange={(e: any) => {
                                            setDates({
                                                ...dates, fromDate: e.target.value
                                            });
                                        }}
                                    />
                                </div>
                                {/* end::Header */}

                                {/* begin::Separator */}
                                <div className='separator border-gray-200'></div>
                                {/* end::Separator */}

                                {/* begin::Content */}
                                <div className='px-7 py-5' data-kt-user-table-filter='form'>

                                    {/* begin::Input group */}
                                    <div className='mb-10'>
                                        <label className='form-label fs-6 fw-bold'>From date:</label>

                                        <DatePicker
                                            placeholder={'From date'}
                                            onChange={(date: any) => setStartDate(date)}
                                            value={startDate}
                                            valueFormat={{ dateStyle: 'medium' }}
                                        />
                                    </div>
                                    {/* end::Input group */}

                                    {/* begin::Input group */}
                                    <div className='mb-10'>
                                        <label className='form-label fs-6 fw-bold'>To date:</label>

                                        <DatePicker
                                            placeholder={'To date'}
                                            onChange={(date: any) => setEndDate(date)}
                                            value={endDate}
                                            valueFormat={{ dateStyle: 'medium' }}
                                        />
                                    </div>
                                    {/* end::Input group */}
                                    {/* begin::Actions */}

                                    <div className='d-flex justify-content-end'>
                                        <button
                                            type='button'
                                            disabled={isLoading}
                                            onClick={resetData}
                                            className='btn btn-sm btn-light btn-active-light-primary fw-bold me-2 px-6'
                                            data-kt-menu-dismiss='true'
                                            data-kt-user-table-filter='reset'
                                        >
                                            Reset
                                        </button>
                                        <button
                                            disabled={isLoading}
                                            type='button'
                                            onClick={filterData}
                                            className='btn btn-sm btn-primary fw-bold px-6'
                                            data-kt-menu-dismiss='true'
                                            data-kt-user-table-filter='filter'
                                        >
                                            Apply
                                        </button>
                                    </div>
                                    {/* end::Actions */}
                                </div>
                                {/* end::Content */}
                            </div>
                            {/* end::SubMenu */}


                        </div>
                        {/* end::Toolbar */}
                    </div>
                </div>
            </div>
        </div>
        <div className="d-flex flex-row">
        </div>
        {loadingStage === LOADINGSTAGES.LOADING && <LoadData isLoading={isLoading} />}
        {loadingStage === LOADINGSTAGES.EMPTY && <NoDataFound isLoading={isLoading} />}
        {loadingStage === LOADINGSTAGES.NEGATIVE && <ServerMessage message="Unable to fetch info" />}
        {loadingStage === LOADINGSTAGES.ERROR && <ServerMessage message="Oops! server issue occured" />}
        {loadingStage === LOADINGSTAGES.POSITIVE &&
            (<div className="row g-5 g-xl-8">

                <div className="col-xl-12">
                    <div className={`card mb-xl-1`}>
                        {/* begin::Beader */}
                        {/* <div className='card-header border-0 pt-5'>
                            <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bold fs-4 mb-1'>{selectedMonths}.{year}</span>

                                <span className='text-muted fw-semibold fs-7'>Monthly sales statistics</span>
                            </h3>
                        </div> */}
                        {/* end::Header */}

                        {/* begin::Body */}
                        <div className='card-body p-0 d-flex flex-column'>
                            {/* begin::Stats */}
                            <div className='card-p bg-white'>
                                {/* begin::Row */}
                                <div className='row g-0'>
                                    {/* begin::Col */}
                                    <div className='col mr-8'>
                                        {/* begin::Label */}
                                        <div className='fs-7 text-muted fw-semibold'>Total Sale (with Tax)</div>
                                        {/* end::Label */}

                                        {/* begin::Stat */}
                                        <div className='d-flex align-items-center'>
                                            <div className='fs-4 fw-bold'>{metaData?.totalSaleWithTax.toFixed(2)} </div>
                                            {/* <KTSVG
                                                path='/media/icons/duotune/arrows/arr066.svg'
                                                className='svg-icon-5 svg-icon-success ms-1'
                                            /> */}
                                        </div>
                                        {/* end::Stat */}
                                    </div>
                                    {/* end::Col */}

                                    {/* begin::Col */}
                                    <div className='col'>
                                        {/* begin::Label */}
                                        <div className='fs-7 text-muted fw-semibold'>Total Sale (without Tax)</div>
                                        {/* end::Label */}

                                        {/* begin::Stat */}
                                        <div className='fs-4 fw-bold'>{metaData?.totalSaleWithoutTax.toFixed(2)}</div>
                                        {/* end::Stat */}
                                    </div>
                                    {/* end::Col */}

                                    {/* begin::Col */}
                                    <div className='col mr-8'>
                                        {/* begin::Label */}
                                        <div className='fs-7 text-muted fw-semibold'>Total Tax</div>
                                        {/* end::Label */}

                                        {/* begin::Stat */}
                                        <div className='fs-4 fw-bold'>{metaData?.totalTax.toFixed(2)}</div>
                                        {/* end::Stat */}
                                    </div>
                                    {/* end::Col */}

                                    {/* begin::Col */}
                                    <div className='col'>
                                        {/* begin::Label */}
                                        <div className='fs-7 text-muted fw-semibold'>Invoice Count</div>
                                        {/* end::Label */}

                                        {/* begin::Stat */}
                                        <div className='d-flex align-items-center'>
                                            <div className='fs-4 fw-bold'>{metaData?.invoiceCount}</div>
                                            {/* <KTSVG
                                                path='/media/icons/duotune/arrows/arr065.svg'
                                                className='svg-icon-5 svg-icon-danger ms-1'
                                            /> */}
                                        </div>
                                        {/* end::Stat */}
                                    </div>
                                    {/* end::Col */}
                                </div>
                                {/* end::Row */}
                            </div>
                            {/* end::Stats */}


                        </div>
                        {/* end::Body */}
                    </div>
                </div>
                <div className="col-xl-12">
                    <div className={`card card-xl-stretch mb-xl-8`}>
                        {/* begin::Body */}
                        <div className='card-body'>
                            <div className="table-responsive">
                                <div className="display mb-4 dataTablesCard customer-list-table">
                                    <Table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                                        <thead>
                                            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                                <th>invoice date</th>
                                                <th>invoice count</th>
                                                <th className='text-end'>Taxable Amount</th>
                                                <th className='text-end'>Tax Amount</th>
                                                <th className='text-end'>total Amount</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentTableData.map((item: any, index: any) =>
                                                <tr key={index}>
                                                    <td className='text-gray-600 fw-bold'>{moment(item.invoiceDate).format("DD-MM-yyyy")}</td>
                                                    <td className='text-gray-600 fw-bold'>{item.invoiceCount}</td>
                                                    <td className='text-dark fw-bold text-end'>{item.taxableAmount?.toFixed(2)}</td>
                                                    <td className='text-dark fw-bold text-end'>{item.taxAmount?.toFixed(2)}</td>
                                                    <td className='text-dark fw-bold text-end'>{item.totalAmount?.toFixed(2)}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={totalRows}
                                        pageSize={PAGE_SIZE}
                                        onPageChange={(page: any) => paginate(page)}
                                    />
                                </div>
                            </div>


                        </div>
                        {/* end::Body */}
                    </div>
                </div>
            </div>)
        }


    </>
}