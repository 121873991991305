export interface ICategoryClone {
    name:string;
    description:string;
    productgroupId:number;
    parentcategoryId:number;
    pictureId:number;
    pageSize:number;
    allowcustomertoSelectpagesize:boolean;
    showonHomePage:boolean;
    includeIntopmenu:boolean;
    subjectToacl:boolean;
    limitedToStore:boolean;
    published:boolean;
    deleted:boolean;
    displayOrder:number;
    companyId:number;
    isDeleted:number;
}
export const categoryinitvalues:ICategoryClone={

    name: '',
    description: '',
    productgroupId: 1,
    parentcategoryId: 1,
    pictureId: 1,
    pageSize: 10,
    allowcustomertoSelectpagesize: false,
    showonHomePage: false,
    includeIntopmenu: false,
    subjectToacl: false,
    limitedToStore: false,
    published: false,
    deleted: false,
    displayOrder: 1,
    companyId: 1,
    isDeleted: 1,

}




