import { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { getStates } from '../../services/CommonService';
import { saveCustomer } from '../../services/CustomerService';
import { KTSVG } from '../../_metronic/helpers';
import { ICustomerModel, customerInitValues as initialValues } from '../../app/modules/accounts/components/settings/SettingsModel';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { toastNotify } from '../../services/NotifyService';


const profileDetailsSchema = Yup.object().shape({
    fullName: Yup.string().required('Name is required').max(150, 'Cannot exceed 150 characters'),
    email: Yup.string().email('Please enter valid email').max(150, 'Cannot exceed 150 characters'),
    contactNo: Yup.string().required('Contact number is required').max(15, 'Cannot exceed 15 characters'),
    alternateNo: Yup.string().max(20, 'Cannot exceed 20 characters'),
    address: Yup.string().max(500, 'Cannot exceed 500 characters'),
    city: Yup.string().max(50, 'Cannot exceed 50 characters'),
    stateId: Yup.string(),
    gstin: Yup.string().max(20, 'Cannot exceed 20 characters'),
    postalCode: Yup.string().max(20, 'Cannot exceed 20 characters'),
})


const AddCustomerShort = (props: any) => {
    console.log("props", props.number)
    const { setShowCustomerForm, afterUpdate,number } = props;

    const [data, setData] = useState<any>(initialValues)
    const [stateNames, setStateNames] = useState<any>([])

    const updateData = (fieldsToUpdate: Partial<ICustomerModel>): void => {
        const updatedData = Object.assign(data, fieldsToUpdate)
        setData(updatedData)
    }

    const [loading, setLoading] = useState(false)
    const formik = useFormik<ICustomerModel>({
        initialValues,
        validationSchema: profileDetailsSchema,
        onSubmit: (values) => {
            const id = toast.loading("Please wait");
            setLoading(true)
            setTimeout(() => {

                const updatedData = Object.assign(data, values)
                setData(updatedData)

                saveCustomer(0, updatedData).then((res: any) => {
                    toastNotify(id, res);
                    if (res.data.success) {
                        afterUpdate(res.data.data);
                    }
                }).catch((err: any) => {
                    const res = { data: { message: 'Something went wrong', success: false } };
                    toastNotify(id, res);
                }).finally(() => {
                    setLoading(false);
                    setShowCustomerForm(false);
                })

            }, 1000)
        },
    })



    const bindStates = (countryId: any) => {
        getStates(countryId).then((res: any) => {
            const array = res.data.data;
            setStateNames(array);
        }).catch((err: any) => {
            console.log(err);
        })
    }
    useEffect(() => {
        bindStates(99);
    }, []);

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                    {/* begin::Modal content */}
                    <div className='modal-content'>
                        <div className='modal-header'>
                            {/* begin::Modal title */}
                            <h2 className='fw-bolder'>Add Customer</h2>
                            {/* end::Modal title */}

                            {/* begin::Close */}
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                onClick={() => setShowCustomerForm(false)}
                                style={{ cursor: 'pointer' }}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                            </div>
                            {/* end::Close */}
                        </div>
                        <div className='modal-body'>
                            <form onSubmit={formik.handleSubmit} noValidate className='form'>
                                <div className='card-body p-9'>
                                    {/* 1 */}
                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Name</label>

                                        <div className='col-lg-8'>
                                            <div className='row'>
                                                <div className='col-lg-12 fv-row'>
                                                    <input
                                                        type='text'
                                                        className='form-control  mb-3 mb-lg-0'
                                                        placeholder='Name'
                                                        {...formik.getFieldProps('fullName')}
                                                    />
                                                    {formik.touched.fullName && formik.errors.fullName && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>{formik.errors.fullName}</div>
                                                        </div>
                                                    )}
                                                </div>
                                                {/* <div className='col-lg-6 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control  mb-3 mb-lg-0'
                                            placeholder='Last name'
                                            {...formik.getFieldProps('lastName')}
                                        />
                                        {formik.touched.lastName && formik.errors.lastName && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.lastName}</div>
                                            </div>
                                        )}
                                    </div> */}

                                            </div>
                                        </div>
                                    </div>
                                    {/* 2 */}
                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Contact/Alternate Number</label>

                                        <div className='col-lg-8'>
                                            <div className='row'>
                                                <div className='col-lg-6 fv-row'>
                                                    <input
                                                        type='text'
                                                        className='form-control mb-3 mb-lg-0'
                                                        placeholder='Mobile'
                                                        
                                                        {...formik.getFieldProps('contactNo')}
                                                    />
                                                    {formik.touched.contactNo && formik.errors.contactNo && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>{formik.errors.contactNo}</div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='col-lg-6 fv-row'>
                                                    <input
                                                        type='text'
                                                        className='form-control mb-3 mb-lg-0'
                                                        placeholder='Alternate Number'
                                                        {...formik.getFieldProps('alternateNo')}
                                                    />
                                                    {formik.touched.alternateNo && formik.errors.alternateNo && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>{formik.errors.alternateNo}</div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    {/* 3 */}
                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label  fw-bold fs-6'>Address</label>

                                        <div className='col-lg-8'>
                                            <div className='row'>
                                                <div className='col-lg-12 fv-row'>
                                                    <input
                                                        type='text'
                                                        className='form-control '
                                                        placeholder='Address'
                                                        {...formik.getFieldProps('address')}
                                                    />
                                                    {formik.touched.address && formik.errors.address && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>{formik.errors.address}</div>
                                                        </div>
                                                    )}
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    {/* 4 */}
                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>City/State</label>

                                        <div className='col-lg-8'>
                                            <div className='row'>
                                                <div className='col-lg-6 fv-row'>
                                                    <input
                                                        type='text'
                                                        className='form-control '
                                                        placeholder='City'
                                                        {...formik.getFieldProps('city')}
                                                    />
                                                    {formik.touched.city && formik.errors.city && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>{formik.errors.city}</div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='col-lg-6 fv-row'>
                                                    <select
                                                        className='form-control '
                                                        placeholder='State '
                                                        {...formik.getFieldProps('stateId')}
                                                    >
                                                        <option value="">Select State</option>
                                                        {stateNames && stateNames.map(((sta: any) =>
                                                            <option key={sta.id} value={sta.id}  >{sta.stateName}</option>
                                                        ))}
                                                    </select>

                                                    {formik.touched.stateId && formik.errors.stateId && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>{formik.errors.stateId}</div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    {/* 5 */}
                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>GST Number</label>
                                        <div className='col-lg-8'>
                                            <div className='row'>
                                                <div className='col-lg-6 fv-row'>
                                                    <input
                                                        type='text'
                                                        className='form-control '
                                                        placeholder='GST number'
                                                        {...formik.getFieldProps('gstin')}
                                                    />
                                                    {formik.touched.gstin && formik.errors.gstin && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>{formik.errors.gstin}</div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* 6 */}
                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>Email</label>
                                        <div className='col-lg-8'>
                                            <div className='row'>
                                                <div className='col-lg-6 fv-row'>
                                                    <input
                                                        type='email'
                                                        className='form-control '
                                                        placeholder='Email'
                                                        {...formik.getFieldProps('email')}
                                                    />
                                                    {formik.touched.email && formik.errors.email && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>{formik.errors.email}</div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                    <button type='submit' className='btn btn-primary' disabled={loading}>
                                        {!loading && 'Save Changes'}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                        {/* end::Modal body */}
                    </div>
                    {/* end::Modal content */}
                </div>
                {/* end::Modal dialog */}
            </div>
            {/* begin::Modal Backdrop */}
            <div className='modal-backdrop fade show'></div>
            {/* end::Modal Backdrop */}
        </>
    )
}

export { AddCustomerShort }


