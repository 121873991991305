import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik';
import { SupplierField, SupplierSelect } from './SupplierConst';
import { ISupplierModel, supplierModelInitValues } from '../../modules/accounts/components/settings/SettingsModel';
import { saveSupplier, getSupplierDetails, updateSupplier } from '../../../services/SuppliersService';
import { toast } from 'react-toastify';
import { getStates } from '../../../services/CommonService';
import { Link, useNavigate, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import {Loader, LOADINGSTAGES, ValidationField, ValidationSelect } from '../components/components';
import { ServerMessage } from '../../../lib/NoData';
import { PageTitle } from '../../../_metronic/layout/core';
import { breadcrumbs } from '../components/PageTitle';
import { toastNotify } from '../../../services/NotifyService';


export default function AddSupplier(props: any) {
    const { id } = useParams<string>();
    const navigate = useNavigate();
    const Schema = Yup.object().shape({
        supplierName: Yup.string().required('Supplier Name is required'),
        supplierCode: Yup.string(),
        gstin: Yup.string(),
        vat: Yup.string().nullable(),
        pan: Yup.string().nullable(),
        address: Yup.string().required('Address is required'),
        city: Yup.string().required('City is required'),
        stateId: Yup.number().required('State is required').
            test('is-valid', 'No State Selected', (value: any) => {
                return value !== 0;
            }),
        website: Yup.string(),
        email: Yup.string().required('Email is required'),
        ownerName: Yup.string(),
        contactNo: Yup.string().required('Contact No is required'),
        alternateNo: Yup.string(),
        postalCode: Yup.string().required('Postal Code is required')
    });
    const [values, setValues] = React.useState<ISupplierModel>(supplierModelInitValues);
    const [edit, setEdit] = useState(false);
    const [stateList, setStateList] = React.useState<any>([]);
    const [loading, setLoading] = React.useState<boolean>(false);

    const formik = useFormik<ISupplierModel>({
        enableReinitialize: true,
        validationSchema: Schema,
        initialValues: values,
        onSubmit: (values) => {
            addSupplier(values);
        }
    });
    const redirect = () => {
        setTimeout(() => {
            navigate("/suppliers");
        }, 500)
    }

    const [laodingStages, setLoadingStages] = useState<number>(LOADINGSTAGES.IDLE);
    const bindSupplier = (id: any) => {
        setLoadingStages(LOADINGSTAGES.LOADING);
        getSupplierDetails(id).then((res: any) => {
            if (res.data.success) {
                setValues(res.data.data);
                setLoadingStages(LOADINGSTAGES.POSITIVE);
            }
            else {
                setLoadingStages(LOADINGSTAGES.NEGATIVE);
            }
        }).catch((err: any) => {
            setLoadingStages(LOADINGSTAGES.ERROR);
        }
        )
    }
    const addSupplier = (values: any) => {
        setLoading(true);
        let tId = toast.loading('Please wait...');
        if (!edit) {
            saveSupplier(values).then((res: any) => {
                if (res.data.success) {
                    toastNotify(tId,res)
                    redirect();
                }
                else {
                    toastNotify(tId,res)
                }
            }).catch((err: any) => {
                const data ={data : {success : false, message : "Something went wrong"}}
                toastNotify(tId,data)
            }).finally(() => {
                setLoading(false);
                toast.dismiss(tId);
            });
        }
        else {
            updateSupplier(values, id).then((res: any) => {
                if (res.data.success) {
                    toastNotify(tId,res)
                    redirect();
                }
                else {
                    toastNotify(tId,res)
                }
            }).catch((err: any) => {
                const data ={data : {success : false, message : "Something went wrong"}}
                toastNotify(tId,data)
            }).finally(() => {
                setLoading(false);
                toast.dismiss(tId);
            });
        }
    }
    const bindStates = (countryId: number) => {
        getStates(countryId).then((res: any) => {
            let data = res.data.data;
            
            let array: any = [];
            data.forEach((item: any) => {
                array.push({ id: item.id, name: item.stateName });
            });
            // default state
            array.unshift({ id: 0, name: 'Select State' });
            setStateList(array);
        }).catch((err: any) => {
        }
        );
    }
    useEffect(() => {
        bindStates(99);
        if (id || id != null) {
            setEdit(true);
            bindSupplier(id);
        }
        else {
            setEdit(false);
            setLoadingStages(LOADINGSTAGES.POSITIVE);
        }
    }, []);

    return (
        <>
            <PageTitle breadcrumbs={breadcrumbs({
                title: 'Suppliers',
                path: '/suppliers'
            })}
            >
                {edit ? 'Edit Supplier' : 'Add Supplier'}
            </PageTitle>
            <div className='card mb-5 mb-xl-10'>
                {laodingStages === LOADINGSTAGES.ERROR && <ServerMessage message="Something went wrong" />}
                {laodingStages === LOADINGSTAGES.LOADING && <Loader />}
                {laodingStages === LOADINGSTAGES.POSITIVE &&
                    <div id='kt_kitty_group_details' className='collapse show'>
                        <div className='card mb-5 mb-xl-10'>
                            <div className='card-body p-9'>
                                <div className='card-body pt-9'>
                                    <form onSubmit={formik.handleSubmit} noValidate className='form'>
                                        <div className='row mb-7'>
                                            {/* is required will be as per schema */}
                                            <ValidationField
                                                className='col-md-6'
                                                label='Name'
                                                isRequired
                                                name='supplierName'
                                                type='text'
                                                placeholder='Supplier Name'
                                                formik={formik}
                                            />
                                            <ValidationField
                                                className='col-md-6'
                                                label='Code'
                                                name='supplierCode'
                                                type='text'
                                                placeholder='Supplier Code'
                                                formik={formik}
                                            />
                                        </div>
                                        <div className='row mb-7'>
                                            <ValidationField
                                                className='col-md-6'
                                                label='GSTIN'
                                                name='gstin'
                                                type='text'
                                                placeholder='GSTIN'
                                                formik={formik}
                                            />
                                            <ValidationField
                                                className='col-md-6'
                                                label='Owner Name'
                                                name='ownerName'
                                                type='text'
                                                placeholder='Owner Name'
                                                formik={formik}
                                            />
                                        </div>
                                        <div className='row mb-7'>
                                            <ValidationField
                                                className='col-md-6'
                                                label='VAT'
                                                name='vat'
                                                type='text'
                                                placeholder='VAT'
                                                formik={formik}
                                            />
                                            <ValidationField
                                                className='col-md-6'
                                                label='PAN'
                                                name='pan'
                                                type='text'
                                                placeholder='PAN'
                                                formik={formik}
                                            />
                                        </div>

                                        <div className='row mb-7'>
                                            <ValidationField
                                                className='col-md-6'
                                                label='Address'
                                                isRequired
                                                name='address'
                                                type='text'
                                                placeholder='Address'
                                                formik={formik}
                                            />
                                            <ValidationField
                                                className='col-md-6'
                                                label='City'
                                                isRequired
                                                name='city'
                                                type='text'
                                                placeholder='City'
                                                formik={formik}
                                            />
                                        </div>
                                        <div className='row mb-7'>
                                            <ValidationSelect
                                                className='col-md-6'
                                                label='State'
                                                isRequired
                                                name='stateId'
                                                formik={formik}
                                                options={stateList}
                                            />
                                            <ValidationField
                                                className='col-md-6'
                                                label='Website'
                                                name='website'
                                                type='text'
                                                placeholder='Website'
                                                formik={formik}
                                            />
                                        </div>
                                        <div className='row mb-7'>
                                            <ValidationField className='col-md-6' label='Contact No' isRequired name='contactNo' type='text' placeholder='Contact No' formik={formik} />
                                            <ValidationField className='col-md-6' label='Alternate No' name='alternateNo' type='text' placeholder='Alternate No' formik={formik} />
                                        </div>
                                        <div className='row mb-7'>
                                            <ValidationField className='col-md-6' label='Email' isRequired name='email' type='text' placeholder='Email' formik={formik} />
                                            <ValidationField className='col-md-6' label='Postal Code' isRequired name='postalCode' type='text' placeholder='Postal Code' formik={formik} />
                                        </div>
                                        <div className='card-footer d-flex justify-content-center py-6 px-9'>
                                            <button type='submit' className='btn btn-primary'
                                                >
                                                <span className='indicator-label'>Submit</span>
                                                {(formik.isSubmitting) && (
                                                    <span className='indicator-progress'>
                                                        Please wait...{' '}
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </button>
                                            <Link to={'/suppliers'} className='btn btn-light mx-3'>
                                                Cancel
                                            </Link>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>

    );
}
