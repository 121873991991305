import axiosInstance from '../services/AxiosInstance';

export function getLeadCounter() {
    return axiosInstance.get(`Counters/GetLead`);
}

export function getCustomerCounter() {
    return axiosInstance.get(`counters/GetCustomerCounts`);
}

export function getEmployeeCounter() {
    return axiosInstance.get(`Counters/GetEmployeeCounts`);
}

export function getInvoiceCount() {
    return axiosInstance.get(`Counters/GetInvoiceCount`);
}
export function getDeliveryChallanCount() {
    return axiosInstance.get(`Counters/GetDeliveryChallanCounts`);
}


export function getCategoryCounter() {
    return axiosInstance.get(`Counters/CategoryCounter`);
}

export function getQuotationCounts() {
    return axiosInstance.get(`Counters/GetQuotationCounts`);
}
export function getOrderCounts() {
    return axiosInstance.get(`Counters/GetOrderCounts`);
}
export function getPurchaseOrderCounts() {
    return axiosInstance.get(`Counters/GetPurchaseOrderCounts`);
}



export function getPaymentCounts() {
    return axiosInstance.get(`Counters/GetPaymentCounts`);
}
export function getClusterCount() {
    return axiosInstance.get(`Counters/GetClusterCounts`);
}

export function getCreditNoteCount() {
    return axiosInstance.get(`Counters/GetCreditNoteCount`);
}

export function getDebitNoteCount() {
    return axiosInstance.get(`Counters/GetDebitNoteCount`);
}

export function getDeviceCounts() {
    return axiosInstance.get(`Counters/GetDeviceCounts`);
}

export function getSupplierCount() {
    return axiosInstance.get('Counters/getSupplierCount');
}

export function getProductCount(isProduct) {
    return axiosInstance.get('Counters/ProductCounter?isProduct='+isProduct);
}

export function paymentgraph() {
    return axiosInstance.get('Payments/paymentgraph');
}
export function salegraph() {
    return axiosInstance.get('Sales/salegraph');
}

export function invoicesgraph() {
    return axiosInstance.get('invoices/invoicegraph');
}

