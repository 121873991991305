export interface ITermCondition {
    id: number;
    termText: string;
    isActive: boolean;
}

export const termsInitValues: ITermCondition = {
    id: 0,
    termText: "",
    isActive: false
}

export interface INote {
    id: number;
    noteText: string;
    isActive: boolean;
}

export const noteInitValues: INote = {
    id: 0,
    noteText: "",
    isActive: false
}