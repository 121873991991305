import { updateQuotationFields, updateQuotationTaxes } from "../../../services/QuotationService";
import { IQuotationInputDto, IQuoteAdditionalTax } from "../interface/IInvoiceTypes/IQuotation"
export type IUpdateTypes =  'quotationDate' | 'quotationNumber' | 'billBy' | 'billTo';
export type QInputProps = {
    type : IUpdateTypes,
    dto : IQuotationInputDto,
    onResponse : (res : any) => void

}

export const updateQuoteInput = (props:QInputProps) => {
    const {type, dto} = props;
    switch (type) 
    {
        case 'quotationDate':
        dto.quotationDate = dto.quotationDate || new Date();
        break;
        case 'quotationNumber':
        dto.quotationNumber = dto.quotationNumber || '';
        break;
        case 'billBy':
        dto.companyName = dto.companyName || '';
        dto.companyAddress = dto.companyAddress || '';
        dto.companyCity = dto.companyCity || '';
        dto.companyState = dto.companyState || '';
        dto.companyPostalCode = dto.companyPostalCode || '';
        dto.companyGstin = dto.companyGstin || '';
        break;
        case 'billTo':
        dto.shipName = dto.billName || '';
        dto.shipAddress = dto.billAddress || '';
        dto.shipCity = dto.billCity || '';
        dto.shipState = dto.billState || '';
        dto.shipPostalCode = dto.billPostalCode || '';
        dto.customerGstin = dto.customerGstin || '';
        break;
    }
    dto.id = dto.id || 0;
    // don't update if id is 0 and show error
    if(dto.id === 0){
        const data = {success: false, message: 'Quotation not found'}
        props.onResponse(data);
        return;
    }
    updateQuotationFields(dto).then((res) => {
        if(res.data.success){
            props.onResponse(res.data.data);
        }
    }).catch((err) => {
        const data = {success: false, message: err.message}
        props.onResponse(data);
    });
}
export type ITaxProps = {
    tax : IQuotationDto,
    onResponse : (res : any) => void
}
export type IQuotationDto = {
    id : number,
    subAmount : number,
    totalAmount : number,
    taxAmount : number,
    totalFreightCharges : number,
    addTax : number,
    quotationAdditionalTaxes : IQuoteAdditionalTax[]
}
export const updateTaxes = (props:ITaxProps) => {
    const {tax} = props;
    const dto : IQuotationDto = {
        id: tax.id,
        subAmount: tax.subAmount,
        totalAmount: tax.totalAmount,
        taxAmount: tax.taxAmount,
        totalFreightCharges: tax.totalFreightCharges,
        addTax: tax.addTax,
        quotationAdditionalTaxes: tax.quotationAdditionalTaxes
    }
    updateQuotationTaxes(dto).then((res) => {
        if(res.data.success){
            props.onResponse(res.data.data);
        }
    }).catch((err) => {
        const data = {success: false, message: err.message}
        props.onResponse(data);
    });
    
}

