import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { getDocumentLabels } from '../../../../services/CommonService';
import { getquotationHtml } from '../../../../services/QuotationService';
import { KTSVG } from '../../../../_metronic/helpers';

import { InvoiceShare } from './ShareModal';
import {
    getQuotationDetails,
    getQuotationTaxes,
    quotationUpdate,
    updateQuotationFields,
} from '../../../../services/QuotationService';
import { useParams } from 'react-router-dom';
import {
    billByInitialValues,
    billToInitialValues,
    IBillBy,
    IBillTo,
    IQuotation,
    IQuotationItem,
    IQuoteAdditionalTax,
    quoteTaxInitVals,
} from '../../interface/IInvoiceTypes/IQuotation';
import { Loader, LOADINGSTAGES, N2WIndian } from '../../components/components';
import QuoteMail from './QuoteMail';
const QuoteTemplateSelect = (props: any) => {
    const navigate = useNavigate();
    const [isCancelled, setIsCancelled] = useState<any>(false)
    const { id, supplierId, customerId } = useParams()
    const [quotationId, setquotationId] = useState<any>(id || 0)
    const [dataItems, setItemData] = useState<IQuotationItem[]>([])
    const { setShowInvoiceModal, invoiceId, companyCode } = props;
    const [isLoading, setIsLoading] = useState<any>(invoiceId || 0);
    const [invHtml, setInvHtml] = useState<any>('loading...');
    const [template, setTemplate] = useState<any>('quotation');
    const [docLabel, setDocLabel] = useState<any>('Original Copy');
    const [labelArr, setLabelArr] = useState<any[]>([]);
    const [showShare, setShowShare] = useState<any>(false);
    const [showEmail, setShowEmail] = useState<any>(false);
    const [adressShipTo, setAddressShipTo] = useState<IBillTo>(billToInitialValues)
    const [showMail, setShowMail] = useState<any>(false);
    const [data, setData] = useState<any>(null);
    const [totalAmount, setTotalAmount] = useState<any>(null);
    const [amtInWords, setAmtInWords] = useState<any>(null);
    const [adressBillBy, setAddressBillBy] = useState<IBillBy>(billByInitialValues)
    const [stages, setStages] = useState<any>(LOADINGSTAGES.IDLE);
    const [saveInitialData, setSaveInitialData] = useState<any>({});
    const toggleShowShare = () => {
        setShowShare(true);
    }
    const toggleShowEmail = () => {
        setShowMail(true)
    }
    const [mailDetails, setMailDetails] = useState<any>({
        to: '',
        cc: '',
        bcc: '',
        subject: '',
        message: '',
    })


    const setShowShareModal = () => {
        setShowShare(false);
    }


    const closeModal = () => {
        if (setShowInvoiceModal) {
            setShowInvoiceModal(false);
        } else {
            { navigate('/quotation'); }
        }
    }


    const bindDetails = (invoiceId: any) => {
        setIsLoading(true);

        setTimeout(() => {
            getquotationHtml(invoiceId, template, docLabel, companyCode)
                .then((res: any) => {

                    let _data = res.data.data;
                    setInvHtml(_data);
                    
                    setIsLoading(false);
                })
                .catch(() => {
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }, 100);
    };
    const [taxes, setTaxes] = useState<IQuoteAdditionalTax[]>([quoteTaxInitVals])
    const bindQuoteTaxes = (taxId: number) => {
        getQuotationTaxes(taxId)
            .then((res: any) => {
                if (res.data.success) {
                    setTaxes(res.data.data)
                } else {
                }
            })
            .catch((err: any) => {
                console.log(err)
            })
    }

    const bindDetailsEmail = (values: any) => {
        setStages(LOADINGSTAGES.LOADING)
        setTimeout(() => {
            getQuotationDetails(id)
                .then((res: any) => {
                    if (res.data.success) {
                        const _data = res.data.data as IQuotation
                        setData(_data)
                        setquotationId(_data.id)
                        bindQuoteTaxes(_data.id)
                        let items = _data.quotationItems
                        setItemData(items)
                        setMailDetails({
                            to: mailDetails.custEmail,
                            subject: `Quotation: ${_data.quotationNumber}`,
                            message: `Dear ${_data.custFullName},\n\nPlease find the attached quotation for your reference.\n\nThanks & Regards,\n${_data.companyName}`,
                            invoiceNo: _data.quotationNumber,
                            customerName: _data.custFullName,
                            quotationNumber: _data.quotationNumber,
                        })
                        setIsCancelled(_data.isCancel)
                        setTotalAmount(_data.totalAmount)
                        const [rupees, paisa] = _data.totalAmount?.toString().split('.') ?? []
                        const amtInWords = `${N2WIndian.convert(rupees)} Rupees${paisa ? ` and ${N2WIndian.convert(paisa)} Paisa` : ''
                            }`
                        setAmtInWords(amtInWords)
                        const addressBillBy = { ..._data }
                        setAddressBillBy(addressBillBy)
                        const addressShipTo: any = {
                            ..._data,
                            customerGstin: _data.GSTIN,
                            customerPan: _data.PAN,
                            customerEmail: _data.custEmail,
                            customerContactNo: _data.custContactNo,
                        }
                        setAddressShipTo(addressShipTo)
                        const saveInitialData = {
                            billBy: addressBillBy,
                            shipTo: addressShipTo,
                        }
                        setSaveInitialData(saveInitialData)
                        setStages(LOADINGSTAGES.POSITIVE)
                    } else {
                        setData({})
                        setStages(LOADINGSTAGES.NEGATIVE)
                    }
                })
                .catch(() => {
                    setData({})
                    setStages(LOADINGSTAGES.ERROR)
                })
        }, 100)
    }


































    const bindLabels = () => {
        setIsLoading(true);
        setTimeout(() => {
            getDocumentLabels()
                .then((res: any) => {
                    let _data = res.data.data;
                    setLabelArr(_data);
                    setIsLoading(false);
                })
                .catch(() => {
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }, 100);
    };

    useEffect(() => {
        if (invoiceId > 0) bindDetails(invoiceId);
        bindDetailsEmail(invoiceId);
    }, []);

    // useEffect(() => {
    //     if (invoiceId > 0) bindDetails(invoiceId);
    // }, [docLabel]);


    // useEffect(() => {
    //     bindLabels();
    //     bindDetails(invoiceId);
    // }, [template])

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered modal-fullscreen'>
                    {/* begin::Modal content */}
                    <div className='modal-content'>
                        <div className='modal-header'>
                            {/* begin::Modal title */}
                            <h2 className='fw-bolder'>Preview Quotation</h2>
                            {/* end::Modal title */}

                            {/* begin::Close */}
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                onClick={() => closeModal()}
                                style={{ cursor: 'pointer' }}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                            </div>
                            {/* end::Close */}
                        </div>
                        <div className='modal-body'>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <div className='fv-row mb-10'>
                                        <div className='mb-0 fv-row'>
                                            <label className='d-flex align-items-center form-label mb-5'>
                                                Select quotation template
                                                <i
                                                    className='fas fa-exclamation-circle ms-2 fs-7'
                                                    data-bs-toggle='tooltip'
                                                    title='Select invoice template of your choice'
                                                ></i>
                                            </label>

                                            <div className='mb-0'>
                                                <label className='d-flex flex-stack mb-5 cursor-pointer'>
                                                    <span className='d-flex align-items-center me-2'>
                                                        <span className='symbol symbol-50px me-6'>
                                                            <span className='symbol-label'>
                                                                <KTSVG
                                                                    path='/media/icons/duotune/finance/fin001.svg'
                                                                    className='svg-icon-1 svg-icon-gray-600'
                                                                />
                                                            </span>
                                                        </span>

                                                        <span className='d-flex flex-column'>
                                                            <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                                                                Classic
                                                            </span>
                                                            <span className='fs-6 fw-bold text-gray-400'>
                                                                Plain old school quotation template
                                                            </span>
                                                        </span>
                                                    </span>

                                                    <span className='form-check form-check-custom form-check-solid'>
                                                        <input className='form-check-input' type='radio' name='template' value={template} onChange={() => setTemplate('quotation')}
                                                            checked={template === 'quotation'}
                                                        />
                                                    </span>
                                                </label>

                                                {/* <label className='d-flex flex-stack mb-5 cursor-pointer'>
                                                    <span className='d-flex align-items-center me-2'>
                                                        <span className='symbol symbol-50px me-6'>
                                                            <span className='symbol-label'>
                                                                <KTSVG
                                                                    path='/media/icons/duotune/graphs/gra006.svg'
                                                                    className='svg-icon-1 svg-icon-gray-600'
                                                                />
                                                            </span>
                                                        </span>

                                                        <span className='d-flex flex-column'>
                                                            <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                                                                Modern
                                                            </span>
                                                            <span className='fs-6 fw-bold text-gray-400'>Impress clients with a beautiful, modern invoice</span>
                                                        </span>
                                                    </span>

                                                    <span className='form-check form-check-custom form-check-solid'>
                                                        <input className='form-check-input' type='radio' name='template' value={template} onChange={() => setTemplate('modern')} />
                                                    </span>
                                                </label> */}

                                                {/* <label className='d-flex flex-stack mb-0 cursor-pointer'>
                                                    <span className='d-flex align-items-center me-2'>
                                                        <span className='symbol symbol-50px me-6'>
                                                            <span className='symbol-label'>
                                                                <KTSVG
                                                                    path='/media/icons/duotune/graphs/gra008.svg'
                                                                    className='svg-icon-1 svg-icon-gray-600'
                                                                />
                                                            </span>
                                                        </span>

                                                        <span className='d-flex flex-column'>
                                                            <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                                                                Custom
                                                            </span>
                                                            <span className='fs-6 fw-bold text-gray-400'>
                                                                Use images to enhance time travel rivers
                                                            </span>
                                                        </span>
                                                    </span>

                                                    <span className='form-check form-check-custom form-check-solid'>
                                                        <input className='form-check-input' type='radio' name='template' value={template} onChange={() => setTemplate('custom')} />
                                                    </span>
                                                </label> */}
                                            </div>
                                        </div>

                                    </div>

                                    {/* <div className='fv-row'>
                                        <div className='mb-0 fv-row'>
                                            <label className='d-flex align-items-center form-label mb-5'>
                                                Document label
                                                <i
                                                    className='fas fa-exclamation-circle ms-2 fs-7'
                                                    data-bs-toggle='tooltip'
                                                    title='Document label'
                                                ></i>
                                            </label>
                                            <div>
                                                <select className='form-control' onChange={(e) => setDocLabel(e.target.value)}>
                                                    {labelArr && labelArr.map((lbl: any, index: number) => (
                                                        <option value={lbl.labelText}>{lbl.labelText}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                <div className='col-md-9'>
                                    <div className="bg-light h-100 rounded">
                                        <div className='px-20 py-10'>
                                            <div className='row'>
                                                <div className='col-md-12 py-5'>
                                                    <a target={"_blank"} href={`${process.env.REACT_APP_API_URL}/quotations/pdf/${invoiceId}/${companyCode}/${template}/Duplicate`}
                                                        className={`btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1`}
                                                        id='kt_charts_widget_2_year_btn'
                                                    >
                                                        <i className='fas fa-print'></i> PDF
                                                    </a>
                                                    {/* <a href="#"
                                                        onClick={() => toggleShowShare()}
                                                        className={`btn btn-sm btn-color-muted btn-active btn-active-success active px-4 me-1`}
                                                        id='kt_charts_widget_2_year_btn'
                                                    >
                                                        <i className='fas fa-share'></i> Share
                                                    </a> */}
                                                    {/* <a href="#"
                                                        onClick={() => toggleShowEmail()}
                                                        className={`btn btn-sm
                                                         btn-color-muted btn-active btn-active-secondary active px-4 me-1`}
                                                        id='kt_charts_widget_2_year_btn'
                                                    >
                                                        <i className='fas fa-envelope'></i> Email
                                                    </a> */}
                                                </div>
                                            </div>
                                            <div className='card'>
                                                <div className='card-body'>
                                                    <div dangerouslySetInnerHTML={{ __html: invHtml }}>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* end::Modal body */}
                    </div>
                    {/* end::Modal content */}
                </div>
                {/* end::Modal dialog */}
            </div>
            {showShare && <InvoiceShare
                setShowShareModal={setShowShareModal} invoiceId={invoiceId} companyCode={companyCode} template={template} />}
            {/* begin::Modal Backdrop */}
            {showMail && (<>
                <QuoteMail
                    mailDetails={mailDetails}
                    setShowEmail={setShowMail}
                    handleClose={() => setShowMail(false)}
                    data={data}
                />
                <div className='modal-backdrop fade show'></div>
                </>
            )}
            
            {/* end::Modal Backdrop */}
        </>
    )
}

export { QuoteTemplateSelect }
