
import { KTSVG, toAbsoluteUrl } from "../../../../_metronic/helpers";
import { getTotalDashBoardCounter } from "../../../../../src/services/CommonService";
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
export function ShortCuts() {
  const image1 = 'abstract-1.svg';
  const image2 = 'abstract-2.svg';
  const image3 = 'abstract-3.svg';
  const image4 = 'abstract-4.svg';
  const [dashboardcounters, setdashboardcounters] = React.useState<any>({});
  const navigate = useNavigate();
  const bindCounters = () => {
    getTotalDashBoardCounter().then((res: any) => {
      const data = res.data.data;
      setdashboardcounters(data);
    }).catch((err: any) => {
      console.log(err);
    })
  }
  const onclickCustomer=()=>{

navigate("/customers");

}
const onclickproduct=()=>{
  navigate("/products");
  }
  const onclickinvoices=()=>{
    navigate("/invoices");
    }
    const onclickservices=()=>{
      navigate("/services");
      }
      const onclickPurchase=()=>{
        navigate("/purchase-orders");
        }

  useEffect(() => {
    setTimeout(() => {
      bindCounters();
    }, 600)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <>

      <div className="row g-6 g-xl-9 mb-lg-10">
        {/* 1 */}
        <div className="col-sm-12 col-xl-6" onClick={onclickCustomer}>
          <div className='card h-100'>
            <div className='card-header flex-nowrap border-0 pt-9'>
              <div className='card-title m-0' >
                <div className='symbol symbol-45px w-45px bg-light me-5'>
                  {/* <img src={toAbsoluteUrl(image)} alt='Metronic' className='p-3' /> */}
                </div>

                <a href="#" className='fs-1 fw-bolder text-hover-primary text-gray-700 m-0'>
                  {dashboardcounters?.customerCounter}
                </a>
              </div>
            </div>
            <div className='d-flex flex-column pb-8'>
              <div className='fs-2 mb-3 text-center text-gray-400 '>{'Customers'}</div>
            </div>
          </div>
        </div>

        {/* 2 */}
        <div className="col-sm-12 col-xl-6" onClick={onclickproduct}>
          <div className='card h-100'>
            <div className='card-header flex-nowrap border-0 pt-9'>
              <div className='card-title m-0'>
                <div className='symbol symbol-45px w-45px bg-light me-5'>
                  {/* <img src={toAbsoluteUrl(image)} alt='Metronic' className='p-3' /> */}
                </div>

                <a href='#' className='fs-1 fw-bolder text-hover-primary text-gray-700 m-0'>
                  {dashboardcounters?.productCounter}
                </a>
              </div>
            </div>
            <div className='d-flex flex-column pb-8'>
              <div className='fs-2 mb-3 text-center text-gray-400 '>{'Products'}</div>
            </div>
          </div>
        </div>

        {/* 3 */}
        <div className="col-sm-12 col-xl-6" onClick={onclickservices}>
          <div className='card h-100'>
            <div className='card-header flex-nowrap border-0 pt-9'>
              <div className='card-title m-0'>
                <div className='symbol symbol-45px w-45px bg-light me-5'>
                  {/* <img src={toAbsoluteUrl(image)} alt='Metronic' className='p-3' /> */}
                </div>

                <a href='#' className='fs-1 fw-bolder text-hover-primary text-gray-700 m-0'>
                  {dashboardcounters?.serviceCounter}
                </a>
              </div>
            </div>
            <div className='d-flex flex-column pb-8'>
              <div className='fs-2 mb-3 text-center text-gray-400 '>{'Services'}</div>
            </div>
          </div>
        </div>

        {/* 4 */}
        <div className="col-sm-12 col-xl-6" onClick={onclickinvoices}>
          <div className='card h-100'>
            <div className='card-header flex-nowrap border-0 pt-9'>
              <div className='card-title m-0'>
                <div className='symbol symbol-45px w-45px bg-light me-5'>
                  {/* <img src={toAbsoluteUrl(image)} alt='Metronic' className='p-3' /> */}
                </div>

                <a href='#' className='fs-1 fw-bolder text-hover-primary text-gray-700 m-0'>
                  {dashboardcounters?.invoiceCounter}
                </a>
              </div>
            </div>
            <div className='d-flex flex-column pb-8'>
              <div className='fs-2 mb-3 text-center text-gray-400 '>{'Invoices'}</div>
            </div>
          </div>
        </div>

        {/* 5 */}
        <div className="col-sm-12 col-xl-6" onClick={onclickPurchase} >
          <div className='card h-100'>
            <div className='card-header flex-nowrap border-0 pt-9' >
              <div className='card-title m-0'>
                <div className='symbol symbol-45px w-45px bg-light me-5'>
                  {/* <img src={toAbsoluteUrl(image)} alt='Metronic' className='p-3' /> */}
                </div>

                <a href='#' className='fs-1 fw-bolder text-hover-primary text-gray-700 m-0'>
                  {dashboardcounters?.poCounter}
                </a>
              </div>
            </div>
            <div className='d-flex flex-column pb-8'>
              <div className='fs-2 mb-3 text-center text-gray-400 '>{'Purchase Orders'}</div>
            </div>
          </div>
        </div>

       
      </div>
    </>
  )
}