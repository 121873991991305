/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { AUTH_LOCAL_STORAGE_KEY } from '../../../../app/modules/auth'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { UserMenu } from '../user-menu/UserMenu'
import {Link} from 'react-router-dom'

const AsideUserMenu: FC = () => {
  const [loginUser, setLoginUser] = useState<any>();
  
  useEffect(() => {
    setTimeout(() => {
      const aware_user = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || '');
      if (aware_user) {
        setLoginUser(aware_user);
      }
    }, 100)

  }, []);

  return (
    <>
      <div className='d-flex flex-stack'>
        {/* begin::Wrapper */}
        <div className='d-flex align-items-center'>
          {/* begin::Avatar */}
          <div className='symbol symbol-circle symbol-40px'>
            <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='avatar' />
          </div>
          {/* end::Avatar */}
          {/* begin::User info */}
          <div className='ms-2'>
            <Link to='account' className='text-gray-800 text-hover-primary fs-6 fw-bolder lh-1'>
              {loginUser?.firstName} {loginUser?.lastName}
            </Link>
            <span className='text-muted fw-bold d-block fs-7 lh-1'>{loginUser?.companyName}</span>
          </div>
          {/* end::User info */}
        </div>
        {/* end::Wrapper */}

        {/* begin::User menu */}
        <div className='ms-1'>
          <div
            className='btn btn-sm btn-icon btn-active-color-primary position-relative me-n2'
            data-kt-menu-trigger='click'
            data-kt-menu-overflow='false'
            data-kt-menu-placement='top-end'
          >
            <KTSVG path='/media/icons/duotune/coding/cod001.svg' className='svg-icon-1' />
          </div>
          <UserMenu />
        </div>
        {/* end::User menu */}
      </div>
    </>
  )
}

export { AsideUserMenu }
