/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { register } from '../core/_requests'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify'
import { toastNotify } from '../../../../services/NotifyService'
import { AppType } from '../../../../_models/enums'

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('First name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
  lastname: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Last name is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 characters')
    .max(50, 'Maximum 50 characters')
    .required('Password is required'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
  const navigate = useNavigate();
  const { email } = useParams();

  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      const id = toast.loading("Please wait...");

      try {
        await register(
          values.email,
          values.firstname,
          values.lastname,
          values.password,
          AppType.AmberBills
        ).then((res: any) => {
          let _data = res.data.data;
          toastNotify(id, res);

          if (_data) {
            setFormSubmitted(true);
            //navigate('/auth');
          }
        })
          .catch((err: any) => {
            const res = { data: { message: 'Something went wrong', success: false } };
            toastNotify(id, res);
            console.error(err);
            setLoading(false);
          }).finally(() => {
            setLoading(false);
          });
      } catch (error) {
        setStatus('Something went wrong. Please retry or contact admin.')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap();

    if (email) {
      formik.setFieldValue('email', email);
    }
  }, [])

  return (
    <>
      {
        formSubmitted ?
          <div className='w-100'>

            {/* begin::Alert*/}
            <div className="alert alert-dismissible d-flex flex-center flex-column py-10 px-10 px-lg-20" style={{ margin: '-33px' }}>
              {/* begin::Close*/}
              {/* <button type="button" className="position-absolute top-0 end-0 m-2 btn btn-icon btn-icon-success" data-bs-dismiss="alert">
              <img src="/media/icons/duotune/abstract/abs012.svg" className="h-20px me-10" />
            </button> */}
              {/* end::Close*/}

              {/* begin::Icon*/}
              <span className="svg-icon svg-icon-5hx svg-icon-success me-4 mb-5">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="currentColor"></path>
                  <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="currentColor"></path>
                </svg>
              </span>
              {/* end::Icon*/}

              {/* begin::Wrapper*/}
              <div className="text-center">
                {/* begin::Title*/}
                <h1 className="fw-bold mb-5">Thank you for signing up!</h1>
                {/* end::Title*/}

                {/* begin::Separator*/}
                <div className="separator separator-dashed border-success opacity-25 mb-5"></div>
                {/* end::Separator*/}

                {/* begin::Content*/}
                <div className="mb-9 text-dark fs-5">
                  To complete your sign up process, please check your email for the <strong>confirmational email</strong> that we just sent.<br /><br />
                  If you do not receive your confirmation email in next two hours, please <a href="#" className="fw-bold me-1">contact us</a>.
                </div>
                {/* end::Content*/}

                {/* begin::Buttons*/}
                <div className="d-flex flex-center flex-wrap">
                  {/* <a href="#" className="btn btn-outline btn-outline-success btn-active-success m-2">Cancel</a> */}
                  <Link to="/auth" className="btn btn-success m-2">Ok, I got it</Link>
                </div>
                {/* end::Buttons*/}
              </div>
              {/* end::Wrapper*/}
            </div>
            {/* end::Alert*/}
          </div> :
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_signup_form'
            onSubmit={formik.handleSubmit}
          >
            {/* begin::Heading */}
            <div className='mb-10 text-center'>
              {/* begin::Title */}
              <h1 className='text-dark mb-3'>Create an Account</h1>
              {/* end::Title */}

              {/* begin::Link */}
              <div className='text-gray-400  fs-4'>
                Already have an account?
                <Link to='/auth/login' className='link-primary ' style={{ marginLeft: '5px' }}>
                  Forgot Password ?
                </Link>
              </div>
              {/* end::Link */}
            </div>
            {/* end::Heading */}

            {/* begin::Action */}
            {/* <button type='button' className='btn btn-primary fw-bolder w-100 mb-10'>
      <img
        alt='Logo'
        src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
        className='h-20px me-3'
      />
      Sign in with Google
    </button> */}
            {/* end::Action */}

            {/* <div className='d-flex align-items-center mb-10'>
      <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      <span className='fw-bold text-gray-400 fs-7 mx-2'>OR</span>
      <div className='border-bottom border-gray-300 mw-50 w-100'></div>
    </div> */}

            {formik.status && (
              <div className='mb-lg-15 alert alert-secondary text-gray-700'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}

            {/* begin::Form group Firstname */}
            <div className='row fv-row mb-7'>
              <div className='col-xl-6'>
                <label className='form-label fw-bolder text-dark fs-6'>First name</label>
                <input
                  placeholder='First name'
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('firstname')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.firstname && formik.errors.firstname,
                    },
                    {
                      'is-valid': formik.touched.firstname && !formik.errors.firstname,
                    }
                  )}
                />
                {formik.touched.firstname && formik.errors.firstname && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.firstname}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-xl-6'>
                {/* begin::Form group Lastname */}
                <div className='fv-row mb-5'>
                  <label className='form-label fw-bolder text-dark fs-6'>Last name</label>
                  <input
                    placeholder='Last name'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('lastname')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.lastname && formik.errors.lastname,
                      },
                      {
                        'is-valid': formik.touched.lastname && !formik.errors.lastname,
                      }
                    )}
                  />
                  {formik.touched.lastname && formik.errors.lastname && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.lastname}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* end::Form group */}
              </div>
            </div>
            {/* end::Form group */}

            {/* begin::Form group Email */}
            <div className='fv-row mb-7'>
              <label className='form-label fw-bolder text-dark fs-6'>Email</label>
              <input
                placeholder='Email'
                type='email'
                autoComplete='off'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  { 'is-invalid': formik.touched.email && formik.errors.email },
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group Password */}
            <div className='mb-10 fv-row' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label fw-bolder text-dark fs-6'>Password</label>
                <div className='position-relative mb-3'>
                  <input
                    type='password'
                    placeholder='Password'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.password && formik.errors.password,
                      },
                      {
                        'is-valid': formik.touched.password && !formik.errors.password,
                      }
                    )}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.password}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* begin::Meter */}
                <div
                  className='d-flex align-items-center mb-3'
                  data-kt-password-meter-control='highlight'
                >
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                </div>
                {/* end::Meter */}
              </div>
              <div className='text-muted'>
                Use 8 or more characters 
              </div>
            </div>
            {/* end::Form group */}

            {/* begin::Form group Confirm password */}
            <div className='fv-row mb-5'>
              <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
              <input
                type='password'
                placeholder='Password confirmation'
                autoComplete='off'
                {...formik.getFieldProps('changepassword')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                  },
                  {
                    'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                  }
                )}
              />
              {formik.touched.changepassword && formik.errors.changepassword && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.changepassword}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
              <div className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='kt_login_toc_agree'
                  {...formik.getFieldProps('acceptTerms')}
                />
                <label
                  className='form-check-label fw-bold text-gray-700 fs-6'
                  htmlFor='kt_login_toc_agree'
                >
                  I Agree the{' '}
                  <a href='https://www.amberinfotech.com/page-terms' target='_blank' className='ms-1 link-primary'>
                    terms and conditions
                  </a>
                  .
                </label>
                {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.acceptTerms}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='text-center'>
              <button
                type='submit'
                id='kt_sign_up_submit'
                className='btn btn-lg btn-primary w-100 mb-5'
                disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
              >
                {!loading && <span className='indicator-label'>Submit</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              <Link to='/auth/login'>
                <button
                  type='button'
                  id='kt_login_signup_form_cancel_button'
                  className='btn btn-lg btn-secondary w-100 mb-5'
                >
                  Cancel
                </button>
              </Link>
            </div>
            {/* end::Form group */}
          </form>
      }

    </>

  )
}
