import axiosInstance from '../AxiosInstance';

export function searchPurchaseOrders(params) {
    
    const {pageNumber,pageSize,fromDate,toDate,createdBy,statusId,branchId,supplierId,filter}=params;
    return axiosInstance.get(`/purchaseOrders/search?pageNumber=${pageNumber}&pageSize=${pageSize}&fromDate=${fromDate}&toDate=${toDate}&createdBy=${createdBy}&statusId=${statusId}&branchId=${branchId}&supplierId=${supplierId}&filter=${filter}`);
}

export function savePurchaseOrder(data) {
    return axiosInstance.post(`purchaseorders`, data);
}

export default function saveNewPurchaseOrder(data){
    return axiosInstance.post(`purchaseorders/new`, data);
}

export function getPurchaseOrder(id) {
    return axiosInstance.get(`purchaseorders/${id}`);
}
export function updatePurchaseOrder(data,type) {
    return axiosInstance.put(`purchaseorders/purchaseOrder-update/${type}`, data);
}
export function getPoTaxes (id){
    return axiosInstance.get(`additionaltax/purchase-order-additional-taxes/${id}`)
}
export function updatePoTaxes (dto){
    return axiosInstance.put(`purchaseorders/update/purchase-order-taxes`,dto)
}
export function updatePOFields(dto) {
    return axiosInstance.put(`purchaseorders/update/details`,dto);
}
export function getPoHtml(id, template, label, companyCode) {
    return axiosInstance.get(`purchaseorders/get-html?id=${id}&template=${template}&label=${label}&companyCode=${companyCode}`);
}
export const sendPoMail = (id, data, label) => {
    const email = data;
    return axiosInstance.post(`purchaseorders/${id}/send-email`, email);
}

export function getPoSerialNumber() {
    return axiosInstance.get(`purchaseorders/po-number`);
}