/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { useThemeMode } from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import moment from 'moment'
import { paymentgraph } from '../../../../services/CounterService'
import { Loader, LOADINGSTAGES } from "../../../pages/components/components";
import { NoDataFound } from '../../../../lib/NoData'



const PaymentGraph: React.FC = ({ }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()
  const [payments, setPayments] = useState([]);
  const [laodingStages, setLoadingStages] = useState<number>(LOADINGSTAGES.LOADING);
  const paymentBind = () => {
    setLoadingStages(LOADINGSTAGES.LOADING);
    paymentgraph().then((res) => {
      if (res.data.success) {
        setPayments(res.data.data);
        setLoadingStages(LOADINGSTAGES.POSITIVE);
      } else {
        setLoadingStages(LOADINGSTAGES.NEGATIVE);
      }
    }).catch((err: any) => {
      setLoadingStages(LOADINGSTAGES.ERROR);
    });
  }

  useEffect(() => {
    if (laodingStages === LOADINGSTAGES.LOADING) {
      paymentBind();
    }
    const chart = refreshChart()
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, laodingStages]);

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }
    const height = parseInt(getCSS(chartRef.current, 'height'))
    const payment = payments;
    const chart = new ApexCharts(chartRef.current, getChartOptions(height, payment))
    if (chart) {
      chart.render()
    }
    return chart
  }

  return (
    <div className={`card shadow-sm `}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        {/* begin::Title */}
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Payments</span>
          <span className='text-muted fw-semibold fs-7'>Last 30 days</span>
        </h3>
        {/* end::Title */}


      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {laodingStages === LOADINGSTAGES.LOADING && <Loader />}
        {laodingStages === LOADINGSTAGES.NEGATIVE && <NoDataFound />}
        {laodingStages === LOADINGSTAGES.ERROR && <div className='alert alert-secondary text-gray-700'>Something went wrong</div>}
        {laodingStages === LOADINGSTAGES.POSITIVE &&
          <div ref={chartRef} id='kt_charts_widget_1_chart' style={{ height: '350px' }} />}
      </div>
      {/* end::Body */}
    </div>
  )
}

export { PaymentGraph }

function getChartOptions(height: number, payment: any): ApexOptions {
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const baseColor = getCSSVariableValue('--kt-success')
  const secondaryColor = getCSSVariableValue('--kt-gray-300')
  const payments = payment;
  return {

    series: [
      {
        name: 'Payments',
        data: payments.map((x: any) => x.amount)
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: payments.map((x: any) => (moment(x.paymentDate)).format("DD/MM/YYYY")),
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val.toFixed(2)
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}