/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { App } from '../App'
import { HomePage } from '../modules/auth/components/Home'
import { RegisterComplete } from '../modules/auth/components/RegisterComplete'
import { CreateInvoice } from '../modules/auth/components/free-invoice/CreateInvoice'
import { InvoiceView } from '../modules/auth/components/InvoiceView'
import { InvoiceShare } from '../pages/invoices/partials/ShareModal'
import { RegistrationSteps } from '../modules/auth/components/RegistrationSteps'
import { VerificationScreen } from '../modules/auth/components/VerificationScreen'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  // get current route
  
  const { currentUser } = useAuth()
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          <Route path='shared-invoice/:invoiceId/:companyCode/:template' element={<InvoiceView />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='/' element={<HomePage />} />
              <Route path='shared-invoice/:invoiceId/:companyCode/:template' element={<InvoiceView />} />
              <Route path='register-complete/:userKey' element={<RegisterComplete />} />
              <Route path='onboarding-steps/:userKey' element={<RegistrationSteps />} />
              <Route path='email-verification' element={<VerificationScreen />} />
              <Route path='free-online-invoice-generator' element={<CreateInvoice />} />
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
