import axios from "axios";
import { useState } from "react";
import { Button, Col, Form, Modal, ProgressBar, Row } from "react-bootstrap";
import { baseURL } from "../../../services/BaseUrl";
import { AUTH_LOCAL_STORAGE_KEY } from "../../modules/auth";


export const FileInput = (props: any) => {
    const [formData, setFormData] = useState<any>({});
    const [erorMessage, setErrorMessage] = useState<string>('');
    const handleFileUpload = (files: FileList) => {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
        }
        // check size of file and should be less than 1MB
        if (files[0].size > 1000000) {
            setErrorMessage('File size should be less than 1MB');
            return;
        }
        // check invalid files 
        setFormData(formData);
    };

    return (
        <div>
            <div className="row">
                <input
                    className="form-control form-control-sm form-control-solid p-1"
                    type="file"
                    // jpg , jpeg  and png only
                    accept="image/jpeg, image/png"
                    // only one file
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        handleFileUpload(event.target.files as FileList);
                    }}
                />
                <span className="text-danger">{erorMessage}</span>
                <div className="col-lg-2">
                    <button
                        className="btn btn-sm btn-primary fw-bolder ms-2 fs-8 py-1 px-3 p-1"
                        onClick={() => {
                            props.onUpload(formData);
                        }}>Upload
                    </button>
                </div>
            </div>
        </div>
    );
}
interface IFileUploadModalProps {
    show: boolean;
    setShow: (show: boolean) => void;
    mb?: number;
    // use only listed types as mentioned in the FileInput  Form
    type: 'image' | 'video' | 'audio' | 'document' | 'pdf' | 'excel' | 'csv' | 'zip' | 'rar' | 'other' | 'doc' | 'docx';
    title?: string;
}


export function FileUploadModal(props: IFileUploadModalProps) {
    const { show, setShow, type, mb } = props;
    const handleClose = () => setShow(false);
    const [progress, setProgress] = useState(0);
    const stages = [
        'Upload',
        'Processing',
        'Completed',
        'Error'
    ]
    const [uploading, setUploading] = useState(stages[0]);
    const [validationError, setValidationError] = useState<string>('');
    const handleSubmit = (event: any) => {
        // validate file size and type
        setUploading(stages[1]);
        event.preventDefault();
        if (!event.target.file.files[0]) {
            setValidationError('Please select a file');
            return;
        }
        if (event.target.file.files[0].size > 1000000) {
            setValidationError('File size is not valid');
            return;
        }
        setValidationError('');
        const formData = new FormData(event.target);
        let token = '';
        const aware_user = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || '{}');
        if (aware_user) {
            token = aware_user?.token;
        }
        const config = {
            onUploadProgress: (progressEvent: { loaded: number; total: number; }) => {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(progress);
            }
        };
        const axiosInstance = axios.create(config);
        const s = "orgLogo";

        axiosInstance.post(`/FileUploads/${s}`, formData, {
            baseURL: baseURL,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
                Authorization: 'Bearer ' + token,
            },
        }
        ).then((res) => {
            if (res.data.success) {
                setUploading(stages[2]);
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            } else {
                setUploading(stages[3]);
            }
        }
        ).catch((err) => {
            setUploading(stages[3]);
        }
        );
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Form.Label>File</Form.Label>
                            <Col md={10}
                            >
                                <Form.Group>
                                    <Form.Control
                                        name="file"
                                        accept={type === "image" ? "image/jpeg, image/png"
                                            : type === "video" ? "video/mp4,video/x-m4v,video/*"
                                                : type === "audio" ? "audio/*"
                                                    : type === "pdf" ? "application/pdf"
                                                        : type === "doc" ? "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                            : type === "excel" ? "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                                : type === "zip" ? "application/zip, application/x-compressed-zip"
                                                                    : type === "csv" ? "text/csv" : "image/jpeg, image/png"
                                        }
                                        type="file"
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={12} className='d-flex align-items-start justify-content-start'>
                                <div className="border border-dashed  p-3 my-5 alert bg-light">
                                    <span className="fs-5">Recommendations</span>
                                    <ul className="fs-7 p-3" style={{ lineHeight: '2.5' }}>
                                        <li>
                                            <span className="fw-bold">Square (1:1)</span> : If your logo is square i.e. tt has equal width and height, the recommended size is 200 x 200 px.
                                        </li>
                                        <li>
                                            <span className="fw-bold">Landscape (16:9)</span>: For a landscape-oriented logo, with a wider width compared to its height, the recommended width is 300 px.
                                        </li>
                                        <li>
                                            <span className="fw-bold">Max file size</span>: 1 MB.
                                        </li>
                                    </ul>

                                </div>

                            </Col>
                            <Col md={6} className='d-flex align-items-center justify-content-start p-2'>
                                <Button type="submit"
                                    className="btn btn-sm btn-primary btn-block w-100px">
                                    Upload
                                </Button>
                                <Button type="button"
                                    variant="light"
                                    className="btn btn-sm btn-light btn-block mx-3 w-100px" onClick={() => handleClose()}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    {uploading === stages[1]
                        &&
                        <Row className="mt-2">
                            <ProgressBar
                                striped variant={progress === 100 ? 'success' : 'primary'}
                                animated
                                now={progress} label={`${progress}%`}
                            />
                        </Row>
                    }
                    {validationError && <div className="text-danger">{validationError}</div>
                    }
                    {uploading === 'Error' && <div className="text-danger">Error in uploading file</div>}
                    {uploading === 'Completed' &&
                        <Row className="d-flex align-items-center justify-content-center">
                            <Col md={2} className='d-flex align-items-center justify-content-center p-2'>
                                <i className="fas fa-check-circle text-success"></i>
                            </Col>
                            <Col md={10} className='d-flex align-items-center justify-content-center p-2'>
                                <div className="text-success">File uploaded successfully</div>
                            </Col>
                        </Row>}
                </Modal.Body>
            </Modal>
        </>
    );
}