import axios from 'axios';
import { baseURL } from "./BaseUrl";
import { AUTH_LOCAL_STORAGE_KEY } from "../../src/app/modules/auth/core/AuthHelpers";

let token = '';
const aware_user = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY));
if (aware_user) {
  token = aware_user?.token;
}

export const multiPartAxiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
    Authorization: 'Bearer ' + token,
  },
});

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
    Authorization: 'Bearer ' + token,
  }
});

axiosInstance.interceptors.request.use((config) => {
    // const state = store.getState();
    // const token = state.auth.auth.idToken;
    // config.params = config.params || {};
    // config.params['auth'] = token;
	 // console.log(config);
   if(config.status===401){alert('hi')}
    return config;
});

axiosInstance.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY) 
    window.location.reload();
    //window.location = '/auth/login';
  }
});
export default axiosInstance;
