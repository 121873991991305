import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {ButtonGroup, Table} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {Combobox} from 'react-widgets/cjs'
import Accordion from 'react-bootstrap/Accordion'
import {ImageCard} from '../catalog/ImageCard'
import {searchProducts} from '../../../services/CatalogService'
import {PAGE_SIZE} from '../../../_shared'
// lib
import {Loader} from '../components/components'
import {NoDataFound, ServerMessage} from '../../../lib/NoData'
// services
import {getInvoiceSerialNumber, postInvoice} from '../../../services/InvoiceService'
import {toastNotify} from '../../../services/NotifyService'

// shared components
import {AddCustomerShort} from '../../../_shared/components/AddCustomerShort'
import {EditNotes} from '../../../_shared/components/EditNotes'
import {EditTerms} from '../../../_shared/components/EditTerms'

//models
import {checkPhoneNumber} from '../../../services/CustomerService'

import {ProductEditModal} from '../catalog/_productModal'
// import './style.css'
import Switch, {RadioButton, ToggleSwitch} from '../components/Toggle'
import {
  FilterInput,
  ValidationDate,
  ValidationField,
  ValidationSelect,
} from '../components/components'
import {breadcrumbs} from '../components/PageTitle'
import {PageTitle} from '../../../_metronic/layout/core'
import {InvoicePrefixModal} from '../../../_shared/components/InvoicePrefix'
import {
  IInvoiceAdditionalTax,
  invoiceTaxInitVals,
  IInvoiceDiscount,
  invoiceDisInitVals,
} from '../interface/IInvoiceTypes/IInvoice'
import {
  addTaxInitValues,
  IAddTaxModel,
  addDisInitValues,
  IAddDisModel,
} from '../../../_models/additionaltax-model'

import {
  bindCompanyBranches,
  bindCustomerBranches,
  bindCustomers,
  bindTaxes,
  bindDiscount,
  billInitialValues as initialValues,
  billSchema,
  bindProductsCategories,
} from '../invoices/iconsts'
import {getAccountInfo} from '../../../services/account/AccountService'
import Pagination from '../../../lib/Pagination/Pagination'
import {TrialOver} from '../../../_shared/components/TrialOver'

import './/style.css'

export function PointOfSale(props: any) {
  const {afterInvoiceUpdate} = props
  const navigate = useNavigate()
const ImageUrl ='/media/images/No-image-found.jpg'
  const [rowsData, setRowsData] = useState<any[]>([])

  const [taxRowsData, setTaxRowsData] = useState<IInvoiceAdditionalTax[]>([invoiceTaxInitVals])
  const [disRowsData, setDisRowsData] = useState<IInvoiceDiscount[]>([invoiceDisInitVals])
  const [totalRows, setTotalRows] = React.useState(0)
  const [custArr, setCustArr] = useState<any[]>([])
  const [branchArr, setBranchArr] = useState<any[]>([])
  const [custBranchArr, setCustBranchArr] = useState<any[]>([])
  const [prodArr, setProdArr] = useState<any[]>([])
  const [prodCateArr, setProdCateArr] = useState<any[]>([])
  const [taxArr, setTaxArr] = useState<IAddTaxModel[]>([addTaxInitValues])
  const [disArr, setDisArr] = useState<IAddDisModel[]>([addDisInitValues])
  const [isLoading, setIsLoading] = useState(false)
  const [currentTableData, setCurrentTableData] = React.useState<any>([])
  const [loading, setLoading] = useState(false)
  const [showCustomerForm, setShowCustomerForm] = useState<any>(false)
  const [showProductForm, setShowProductForm] = useState<any>(false)
  const [showTerms, setShowTerms] = useState<any>(false)
  const [showNotes, setShowNotes] = useState<any>(false)
  const [terms, setTerms] = useState<any>('')
  const [notes, setNotes] = useState<any>('')
  const [showInvPreFrom, setShowInvPreFrom] = useState<any>(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [subTotal, setSubTotal] = useState<any>(0)
  const [discTotal, setDiscTotal] = useState<any>(0)
  const [taxTotal, setTaxTotal] = useState<any>(0)
  const [addTaxTotal, setAddTaxTotal] = useState<any>(0)
  const [addDiscTotal, setAddDiscTotal] = useState<any>(0)
  const [total, setTotal] = useState<any>(0)
  const [selectedCustBranch, setSelectedCustBranch] = useState<any>(null)
  const today = new Date()
  const [buttonShow, setButtonShow] = useState<any>(false)
  const [message, setMessage] = useState<any>()
  const [number, setNumber] = useState<any>()

  const handleSubmit = async (values: any, formik: any, afterInvoiceUpdate: any) => {
    
    const _invoice = {
      invoiceDate: values.invoiceDate === '' ? today.toLocaleDateString() : values.invoiceDate,
      invoiceDueDate:
        values.invoiceDueDate === '' ? today.toLocaleDateString() : values.invoiceDueDate,
      customerId: +values.customerId,
      billBranchId: +values.billBranchId,
      shipBranchId: +values.billBranchId,
      branchId: branchArr[1].id,
      subAmount: subTotal - discTotal - addDiscTotal,
      taxAmount: taxTotal,
      discount: discTotal,
      totalAmount: total,
      terms: values.terms,
      notes: values.notes,
      isProforma: values.isProforma,
      addTax: addTaxTotal,
      addDiscount: addDiscTotal,
      isDraft: values.isDraft,
      invoiceNumber: values.invoiceNumber,
    }

    rowsData.forEach((element) => {
      element.taxAmount =
        ((element.price * element.quantity - +element.discount) * element.taxPercent) / 100
      element.amount = element.price * element.quantity - +element.discount
      element.grossAmount =
        element.price * element.quantity -
        +element.discount +
        ((element.price * element.quantity - +element.discount) * element.taxPercent) / 100
    })

    var temp = subTotal
    disRowsData.forEach((element) => {
      temp = temp - (temp * element.discountPercent) / 100
      element.discountAmount = temp
    })
    taxRowsData.forEach((element) => {
      element.taxAmount = ((subTotal - discTotal) * element.taxPercent) / 100
    })

    var postData = {
      invoice: _invoice,
      invoiceItems: rowsData,
      additionalTaxes: taxRowsData,
      additionalDiscount: disRowsData,
    }

    setLoading(true)
    const toastId = toast.loading('Please wait...')

    try {
      const res = await postInvoice(postData)
      let _data = res.data.data
      console.log("return value",_data)
      toastNotify(toastId, res)

      if (!afterInvoiceUpdate) {
        navigate('/invoices')
      } else {
        afterInvoiceUpdate(res.data.success)
      }
    } catch (err) {
      const res = {data: {message: 'Something went wrong', success: false}}
      toastNotify(toastId, res)
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: billSchema,
    onSubmit: (values) => handleSubmit(values, formik, afterInvoiceUpdate),
  })

  // const handleChange = (index: any, evnt: any) => {
  //     const { name, value } = evnt.target;
  //     const rowsInput = [...rowsData];
  //     rowsInput[index][name] = value;
  //     setRowsData(rowsInput);
  // };
  const handleChange = (index: any, evnt: any) => {
    const {name, value} = evnt.target
    const rowsInput = [...rowsData]
    rowsInput[index][name] = value

    // If the modified field is "quantity" or "price", update the corresponding dependent fields
    if (name === 'quantity' || name === 'price') {
      const item = rowsInput[index]
      const taxableAmount: any = (item.price * item.quantity - item.discount)?.toFixed(2)
      const taxAmount: any = ((taxableAmount * item.taxPercent) / 100).toFixed(2)
      const totalAmount = (parseFloat(taxableAmount) + parseFloat(taxAmount)).toFixed(2)
      rowsInput[index].taxableAmount = taxableAmount
      rowsInput[index].taxAmount = taxAmount
      rowsInput[index].totalAmount = totalAmount
    }

    setRowsData(rowsInput)
  }

  const deleteItem = (index: any) => {
    const rows = [...rowsData]
    const deletedItem = rows.splice(index, 1)[0]
    const newTableRow = [...currentTableData, deletedItem]
    setCurrentTableData(newTableRow)
    setRowsData(rows)
  }
  const LOADINGSTAGES = {
    IDLE: 0,
    LOADING: 1,
    POSITIVE: 3,
    NEGATIVE: 4,
    ERROR: 5,
    EMPTY: 6,
  }
  const [loadingStage, setLoadingStage] = useState<any>(LOADINGSTAGES.IDLE)

  const productValues = {
    pageNumber: 1,
    pageSize: 1000,
    customerId: 0,
    productGroupId: 0,
    fromDate: '',
    toDate: '',
    filter: '',
    categoryId: 0,
  }

  const paginate = (pageNumber: any) => {
    setCurrentPage(pageNumber)
    const firstPageIndex = (pageNumber - 1) * PAGE_SIZE
    const lastPageIndex = firstPageIndex + PAGE_SIZE
    let _currentTableData = prodArr.slice(firstPageIndex, lastPageIndex)
    setCurrentTableData(_currentTableData)
  }

  const checkPhone = (number: string) => {
    setButtonShow(false)
    if (number.length !== 10) {
      console.log('Invalid phone number format')
      return
    }
    checkPhoneNumber(number)
      .then((res: any) => {
        console.log(res)
        if (res.data.success) {
          setMessage('Customer exists')
          console.log('customerId', res.data.data)
          if (res.data.data > 0) {
            formik.setFieldValue('customerId', res.data.data)
          }
        } else {
          setMessage('Customer does not exist')
          setSelectedCustBranch(null)
          setNumber(number)
          setButtonShow(true)
        }
      })
      .catch((err: any) => {})
      .finally(() => {})
  }

  const bindGrid = (values: any) => {
    setTimeout(() => {
      setLoadingStage(LOADINGSTAGES.LOADING)
      setIsLoading(true)
      searchProducts(values)
        .then((res: any) => {
          if (res.data.success) {
            let _data = res.data.data.data
            const firstPageIndex = (currentPage - 1) * PAGE_SIZE
            const lastPageIndex = firstPageIndex + PAGE_SIZE
            let _currentTableData = _data.slice(firstPageIndex, lastPageIndex)
            setProdArr(_data)
            console.log('data', _data)
            setTotalRows(_data.length)
            if (_data.length > 0) {
              setLoadingStage(LOADINGSTAGES.POSITIVE)
              setCurrentTableData(_currentTableData)
            } else {
              setLoadingStage(LOADINGSTAGES.NEGATIVE)
              setCurrentTableData([])
            }
          } else {
            setLoadingStage(LOADINGSTAGES.NEGATIVE)
            setCurrentTableData([])
          }
        })
        .catch(() => {
          setLoadingStage(LOADINGSTAGES.ERROR)
          setCurrentTableData([])
        })
        .finally(() => {
          setIsLoading(false)
        })
    }, 1000)
  }

  const addTaxRow = (index: any) => {
    const newRow: any = {taxName: '', taxPercent: 0}
    const rowsInput = [...taxRowsData, newRow]
    setTaxRowsData(rowsInput)
  }
  const addDiscountRow = (index: any) => {
    const newRow: any = {discountName: '', discountPercent: 0}
    const rowsInput = [...disRowsData, newRow]
    setDisRowsData(rowsInput)
  }

  const handleTaxChange = (index: any, evnt: any) => {
    const {name, value} = evnt.target
    const rowsInput = [...taxRowsData]

    if (+value > 0) {
      var taxInfo = taxArr.filter((c) => c.id === +value)[0]
      rowsInput[index]['taxId'] = taxInfo.id
      rowsInput[index]['taxName'] = taxInfo.taxName
      rowsInput[index]['taxPercent'] = taxInfo.taxValue
      setTaxRowsData(rowsInput)

      //var arr = taxArr.filter(c => c.id !== +value)
      //setTaxArr(arr);
    } else {
      rowsInput[index]['taxName'] = 'None'
      rowsInput[index]['taxPercent'] = 0
      rowsInput[index]['taxId'] = 0
      setTaxRowsData(rowsInput)
    }
  }

  const handleDiscountChange = (index: any, evnt: any) => {
    const {name, value} = evnt.target
    const rowsInput = [...disRowsData]

    if (+value > 0) {
      var disInfo = disArr.filter((c) => c.id === +value)[0]
      rowsInput[index]['discountId'] = disInfo.id
      rowsInput[index]['discountName'] = disInfo.discountName
      rowsInput[index]['discountPercent'] = disInfo.discountValue
      setDisRowsData(rowsInput)

      //var arr = taxArr.filter(c => c.id !== +value)
      //setTaxArr(arr);
    } else {
      rowsInput[index]['discountName'] = 'None'
      rowsInput[index]['discountPercent'] = 0
      rowsInput[index]['discountId'] = 0
      setDisRowsData(rowsInput)
    }
  }

  const isDisabled = (taxId: number) => {
    return taxRowsData.some((tax) => tax.taxId === taxId)
  }

  const isDiscountDisabled = (discountId: number) => {
    return disRowsData.some((dis) => dis.discountId === discountId)
  }

  const deleteTaxItem = (index: any) => {
    const rows = [...taxRowsData]
    rows.splice(index, 1)
    setTaxRowsData(rows)
  }

  const deleteDisItem = (index: any) => {
    const rows = [...disRowsData]
    rows.splice(index, 1)
    setDisRowsData(rows)
  }

  useEffect(() => {
    if (formik.values.customerId) {
      setSelectedCustBranch(null)
      bindCustomerBranches(formik.values.customerId, setCustBranchArr)
    }
  }, [formik.values.customerId])
  useEffect(() => {
    console.log('billbranch:', custBranchArr)
    if (custBranchArr.length > 0) {
      formik.setFieldValue('billBranchId', custBranchArr[1].id)
    }
  }, [custBranchArr])

  useEffect(() => {
    if (formik.values.billBranchId) {
      var _selectedCustBranch = custBranchArr.filter(
        (c: any) => c.customerBarchId === +formik.values.billBranchId
      )[0]
      setSelectedCustBranch(_selectedCustBranch)
    }
  }, [formik.values.billBranchId])

  const [value, setValue] = useState<any>(null)

  const productSelected = (val: any) => {
    setValue(val)
  }

  const addProductToInvoice = () => {
    let newRow: any = {}
    let rowsInput: any = []
    if (value.id && value.id > 0) {
      const selectedProduct = currentTableData.filter((product: any) => product.id === value.id)[0]
      const isExist = rowsData.filter((product: any) => product.itemId === value.id)[0]

      if (isExist) {
        isExist.quantity = isExist.quantity + 1
        rowsInput = [...rowsData]
      } else {
        newRow = {
          itemId: selectedProduct.id,
          itemName: selectedProduct.productName,
          hsnSac: selectedProduct.hsn,
          quantity: quantity,
          unit: selectedProduct.unitName,
          price: selectedProduct.defaultSP,
          discount: 0,
          taxPercent: selectedProduct.taxPercent,
          taxAmount: (selectedProduct.defaultSP * 1 * selectedProduct.taxPercent) / 100,
          amount: selectedProduct.defaultSP * 1,
          grossAmount:
            selectedProduct.defaultSP * 1 +
            (selectedProduct.defaultSP * 1 * selectedProduct.taxPercent) / 100,
        }
        rowsInput = [...rowsData, newRow]
      }
      setRowsData(rowsInput)
      setValue(null)
      // setProdArr([])
      // setCurrentTableData([])
      // setProdArr(prodArr.filter((product: any) => product.id !== selectedProduct.id))
      // setCurrentTableData(
      //   currentTableData.filter((product: any) => product.id !== selectedProduct.id)
      // )
    }
  }
  const addProductToInvoiceDirect = (item: any) => {
    let newRow: any = {}
    let rowsInput: any = []
    if (item.id && item.id > 0) {
      const selectedProduct = currentTableData.filter((product: any) => product.id === item.id)[0]
      const isExist = rowsData.filter((product: any) => product.itemId === item.id)[0]

      if (isExist) {
        isExist.quantity = isExist.quantity + 1
        rowsInput = [...rowsData]
      } else {
        newRow = {
          itemId: selectedProduct.id,
          itemName: selectedProduct.productName,
          hsnSac: selectedProduct.hsn,
          quantity: quantity,
          unit: selectedProduct.unitName,
          price: selectedProduct.defaultSP,
          discount: 0,
          taxPercent: selectedProduct.taxPercent,
          taxAmount: (selectedProduct.defaultSP * 1 * selectedProduct.taxPercent) / 100,
          amount: selectedProduct.defaultSP * 1,
          grossAmount:
            selectedProduct.defaultSP * 1 +
            (selectedProduct.defaultSP * 1 * selectedProduct.taxPercent) / 100,
        }
        rowsInput = [...rowsData, newRow]
      }

      setRowsData(rowsInput)
      setValue(null)
    }
  }
  const [showTaxes, setShowTaxes] = useState<any>(false)
  const afterUpdate = (data: any, setFieldValue: any) => {
    bindCustomers(setCustArr)
    setFieldValue('customerId', data)
    setSelectedCustBranch(null)
    setTimeout(() => {
      bindCustomerBranches(data, setCustBranchArr)
    }, 100)
  }

  const afterUpdateProduct = (data: any) => {
    bindGrid(productValues)
    bindProductsCategories(setProdCateArr)
  }

  const afterUpdateTerms = (data: any) => {
    setShowTerms(false)
    formik.setFieldValue('terms', data)
  }

  const filterValues = (data: any) => {
    const filters = {...productValues}
    filters.categoryId = data
    bindGrid(filters)
  }

  const openTermsModal = (data: any) => {
    setTimeout(() => {
      setTerms(data)
      setShowTerms(true)
    }, 500)
  }

  // notes
  const afterUpdateNotes = (data: any) => {
    setShowNotes(false)
    formik.setFieldValue('notes', data)
  }

  const openNotesModal = (data: any) => {
    setTimeout(() => {
      setNotes(data)
      setShowNotes(true)
    }, 500)
  }

  useEffect(() => {
    bindGrid(productValues)
    bindAccountInfo()
    bindCompanyBranches(setBranchArr)
    bindCustomers(setCustArr)
    bindProductsCategories(setProdCateArr)
    bindTaxes(setTaxArr)
    bindDiscount(setDisArr)
    bindInvoiceNumber(false)
    console.log('products', prodArr)
  }, [])

  useEffect(() => {
    calcTotals()
  }, [rowsData, disRowsData, taxRowsData])

  function calcTotals() {
    //Calculation for bottom box
    let st = 0
    rowsData.forEach((element: any) => {
      st += element.price * element.quantity
    })
    setSubTotal(st)

    let dt = 0
    rowsData.forEach((element: any) => {
      dt += element.discount * element.quantity
    })
    setDiscTotal(dt)

    let adt = 0
    let temp = subTotal - dt
    disRowsData.forEach((element: any) => {
      if (element.discountPercent) adt += (temp * +element.discountPercent) / 100
      temp = temp - (temp * +element.discountPercent) / 100
    })
    setAddDiscTotal(adt)

    let tt = 0
    rowsData.forEach((element: any) => {
      var t = ((element.price * element.quantity - +element.discount) * element.taxPercent) / 100
      tt += t
    })
    setTaxTotal(tt)

    let at = 0
    taxRowsData.forEach((element: any) => {
      var t = ((subTotal - discTotal - addDiscTotal) * element.taxPercent) / 100
      at += t
    })
    setAddTaxTotal(at)

    setTotal(st + tt + at - dt - adt)
  }

  const toggleChange = (e: any) => {
    if (e.target.checked) {
      formik.values.isProforma = true
    } else {
      formik.values.isProforma = false
    }
  }

  const toggleDraft = (e: any) => {
    if (e.target.checked) {
      formik.values.isDraft = true
    } else {
      formik.values.isDraft = false
    }
  }

  const showTaxToggle = (value: boolean) => {
    setShowTaxes(value)
  }

  const [quantity, setQuantity] = useState(1)
  const [accountInfo, setAccountInfo] = useState<any>()

  const bindAccountInfo = () => {
    setTimeout(() => {
      getAccountInfo()
        .then((res: any) => {
          let _data = res.data.data
          setAccountInfo(_data)
        })
        .catch(() => {})
        .finally(() => {})
    }, 500)
  }

  const [invNumber, setInvNumber] = useState(null)

  const bindInvoiceNumber = (isProforma: boolean) => {
    setTimeout(() => {
      getInvoiceSerialNumber(isProforma)
        .then((res: any) => {
          let _data = res.data.data
          setInvNumber(_data)
          initialValues.invoiceNumber = _data
          console.log(_data)
        })
        .catch(() => {})
        .finally(() => {})
    }, 500)
  }

  return (
    <>
      <PageTitle
        breadcrumbs={breadcrumbs({
          title: 'Sales',
          path: '/point-of-sale',
        })}
      >
        Point of sale
      </PageTitle>
      {}

      {accountInfo && accountInfo.planPrice == 0 && (
        <>
          <TrialOver />
        </>
      )}

      {accountInfo && accountInfo.planPrice > 0 && (
        <div className='container-fluid'>
          {/* Search start*/}

          <form onSubmit={formik.handleSubmit} noValidate id='ab_invoice_clone_form'>
            <div className='row '>
              <div className='col-lg-8'>
                <div className='row mb-10'>
                  <div className='col-lg-8 '>
                    <Combobox
                      name='productSearchBox'
                      autoFocus={true}
                      autoSelectMatches={true}
                      hideCaret
                      hideEmptyPopup
                      data={prodArr}
                      dataKey='id'
                      textField='displayName'
                      placeholder='Search for a product'
                      value={value}
                      onChange={(e: any) => productSelected(e)}
                      filter={(item) =>
                        (item.displayName &&
                          item.displayName.toLowerCase().includes(value.toLowerCase())) ||
                        (item.barCode && item.barCode.includes(value))
                      }
                      //onSelect={(e) => productSelected(e)}
                    />
                  </div>
                  <div className='col-lg-2  '>
                    <button
                      className='btn btn-primary btn-sm'
                      type='button'
                      onClick={() => addProductToInvoice()}
                    >
                      <span className='fa fa-sm fa-plus '></span> Add
                    </button>
                  </div>
                </div>

                {/* search ends */}
                {/* Categories Section Start*/}
                <div className='row mb-4'>
                  <ButtonGroup
                    className='button-group col-md-10'
                    role='group'
                    aria-label='Button group'
                  >
                    <button
                      type='button'
                      className='btn btn-sm btn-light-primary m-2 '
                      style={{width: '230px'}}
                      onClick={() => filterValues(0)}
                    >
                      All
                    </button>
                    {prodCateArr &&
                      prodCateArr.map((item: any) => (
                        <div className='m-2'>
                          <button
                            type='button'
                            className='btn btn-sm btn-light-primary '
                            style={{width: '230px'}}
                            onClick={() => filterValues(item.id)}
                          >
                            {item.name}
                          </button>
                        </div>
                      ))}
                  </ButtonGroup>
                </div>

                {/* Categories Section End */}
                {/* Items listing start*/}
                <div className=' row fs-7  '>
                  {loadingStage === LOADINGSTAGES.POSITIVE &&
                    currentTableData &&
                    currentTableData.map((item: any) => (
                      <div
                        className=' col-md-2 shadow mr-3 mb-2 p-3'
                        style={{height: '210px', maxWidth: '200px'}}
                        onClick={(e: any) => addProductToInvoiceDirect(item)}
                      >
                        <div className='d-flex flex-column '>
                          <img
                            src={
                              item.defaultImagePath
                                ? item.defaultImagePath
                                : ImageUrl
                            }
                            alt='Example Image'
                            style={{maxHeight: '150px', maxWidth: '150px'}}
                          />
                          <label className='mt-3 '>{item.name} </label>
                          <label className='text-primary  '>₹{item.amount}</label>
                        </div>
                      </div>
                    ))}

                  {loadingStage === LOADINGSTAGES.LOADING && <Loader />}
                  {loadingStage === LOADINGSTAGES.ERROR && (
                    <ServerMessage message='Oops! server issue occured' />
                  )}
                  {loadingStage === LOADINGSTAGES.NEGATIVE && <NoDataFound />}
                  <Pagination
                    className='pagination-bar'
                    currentPage={currentPage}
                    totalCount={totalRows}
                    pageSize={PAGE_SIZE}
                    onPageChange={(page: any) => paginate(page)}
                  />
                </div>
                {/* Item listing ends */}
              </div>
              {/* Left side end */}
              <div className=' col-lg-4'>
                {/* Customer detail start */}
                <div className='card mb-5'>
                  <div className='card-body'>
                    <div className='row '>
                      {/* <div className='col-md-12'>
                        <ValidationSelect
                          className='my-2'
                          label='Billed by'
                          isRequired
                          placeholder='Select Branch'
                          name='branchId'
                          formik={formik}
                          options={branchArr}
                        />
                        <div
                          className='d-flex align-items-center alert alert-custom alert-notice alert-light-primary fade show'
                          role='alert'
                        >
                          <div className='alert-icon'>
                            <i className='fas fa-info-circle'></i>
                          </div>
                          <div className='alert-text mx-2 text-gray-600'>
                            Invoices will display this company/branch address and GSTIN
                          </div>
                        </div>
                      </div> */}
                      <div className='col-md-12'>
                        <h3 className='card-title align-items-start flex-column'>
                          Bill number : {invNumber}
                        </h3>
                      </div>

                      {/* <div className='col-md-12'>
                        <button
                          type='button'
                          className='btn btn-sm btn-light mt-11'
                          onClick={() => setShowInvPreFrom(true)}
                        >
                          Invoice number settings
                        </button>
                        <div
                          className='d-flex align-items-center alert alert-custom alert-notice alert-light-primary fade show'
                          role='alert'
                        >
                          <div className='alert-icon'>
                            <i className='fas fa-info-circle'></i>
                          </div>
                          <div className='alert-text mx-2 text-gray-600'>
                            Manage Invoice prefix and serial number
                          </div>
                        </div>
                      </div> */}
                      {/* <ValidationDate
                    className='col-lg-4'
                    label='Invoice date'
                    isRequired
                    placeholder='Select Date'
                    name='invoiceDate'
                    formik={formik}
                  />
                  <ValidationDate
                    className='col-lg-4'
                    label='Due date'
                    isRequired
                    placeholder='Select Date'
                    name='invoiceDueDate'
                    formik={formik}
                  /> */}
                    </div>

                    {/* 2 */}
                    <div className='row'>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Mobile Number'
                        onChange={(e) => checkPhone(e.target.value)}
                      />

                      {buttonShow && message === 'Customer does not exist' && (
                        <div className='d-flex justify-content-between'>
                          <label className='text-danger'> {message}</label>
                          <button
                            className='btn btn-primary btn-sm m-2 '
                            type='button'
                            onClick={() => setShowCustomerForm(true)}
                          >
                            Add Customer
                          </button>
                        </div>
                      )}
                      {/* onClick={() => setShow(true)} 
                      
                      <button
                    type='button'
                    className='col-md-2 offset-md-3 align-self-center btn btn-sm btn-secondary'
                    onClick={() => setShowCustomerForm(true)}
                  >
                    + New customer
                  </button> */}
                    </div>

                    <div className='row form-group'>
                      {/* <ValidationSelect
                        className='col-md-12 my-2'
                        placeholder='Bill to branch'
                        label='Bill to branch'
                        isRequired
                        name='billBranchId'
                        formik={formik}
                        options={custBranchArr}
                      /> */}

                      <div className='col-md-12 mb-2'>
                        <div className='px-3 py-3'>
                          {selectedCustBranch != null && (
                            <address>
                              <p className='mb-0 text-gray-600'>
                                {selectedCustBranch.name} {selectedCustBranch.address}{' '}
                              </p>
                              <p className='mb-0 text-gray-600'>
                                {selectedCustBranch.city} {selectedCustBranch.stateName}{' '}
                                {selectedCustBranch.postalCode}{' '}
                              </p>
                              <p className='mb-2 text-gray-600'>
                                {selectedCustBranch.contactNo}| {selectedCustBranch.email}
                              </p>
                              <p className='mb-0 text-dark'>GST: {selectedCustBranch.gstin} </p>
                            </address>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* customer details finish */}
                {/* order details start */}
                <div className='card mb-5'>
                  <div className='card-header'>
                    <h3 className='card-title align-items-start flex-column'>
                      Order details
                      {/* <span className='card-label fw-bold text-dark'>Tasks Overview</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Pending 10 tasks</span> */}
                    </h3>
                  </div>
                  <div className='card-body'>
                    <Table className='table align-middle  fs-7 condensed no-footer'>
                      <tbody>
                        {rowsData && rowsData.length > 0 ? (
                          rowsData.map((item: any, index: any) => (
                            <>
                              <tr>
                                <td className='text-gray-900 ' colSpan={3}>
                                  {item.itemName}
                                </td>
                              </tr>
                              <tr key={index} className='border-bottom'>
                                <td className='text-gray-900  w-100px'>
                                  <input
                                    type='number'
                                    name='quantity'
                                    className='form-control '
                                    id={`qty_${item.id}`}
                                    min='1'
                                    value={item.quantity}
                                    onChange={(evnt) => handleChange(index, evnt)}
                                  />
                                </td>
                                <td className='text-gray-600 fw-bold'>{item.unit}</td>
                                <td className='text-dark fw-bold '>
                                  {(
                                    item.price * item.quantity -
                                    item.discount +
                                    ((item.price * item.quantity - item.discount) *
                                      item.taxPercent) /
                                      100
                                  )?.toFixed(2)}
                                  <br></br>
                                </td>
                                <td className='text-center'>
                                  <a href='#' onClick={() => deleteItem(index)}>
                                    <i className='bi bi-trash text-danger fs-5'></i>
                                  </a>
                                </td>
                              </tr>
                            </>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8}>
                              <div className='text-gray-600 d-flex text-center w-100 align-content-center justify-content-center'>
                                No matching records found
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
                {/* order details end */}
                {/* Total section details start*/}
                <div className='card card-custom card-border border  bg-light-primary'>
                  <div className='card-body p-5'>
                    {formik.status && (
                      <div className='mb-lg-15 alert alert-secondary text-gray-700'>
                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                      </div>
                    )}
                    <div className='table-responsive'>
                      <table className='table align-middle table-condensed'>
                        {/* begin::Table body */}
                        <tbody>
                          <tr>
                            <td>
                              <a href='#' className='text-gray-600  text-hover-primary  fs-6'>
                                Sub Total
                              </a>
                            </td>
                            <td className='text-end'>{subTotal.toFixed(2)}</td>
                          </tr>
                          <tr>
                            <td>
                              <a href='#' className='text-gray-600  text-hover-primary  fs-6'>
                                Discount(-)
                              </a>
                            </td>
                            <td className='text-end'>{discTotal?.toFixed(2)}</td>
                          </tr>
                          {addDiscTotal > 0 && (
                            <tr>
                              <td>
                                <a href='#' className='text-gray-600  text-hover-primary  fs-6'>
                                  Additional Discount
                                </a>
                              </td>
                              <td className='text-end'>{addDiscTotal?.toFixed(2)}</td>
                            </tr>
                          )}
                          <tr>
                            <td>
                              <a href='#' className='text-gray-600  text-hover-primary  fs-6'>
                                Taxable Amount
                              </a>
                            </td>
                            <td className='text-end'>
                              {(subTotal - discTotal - addDiscTotal)?.toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <a href='#' className='text-gray-600  text-hover-primary  fs-6'>
                                Tax Total
                              </a>
                            </td>
                            <td className='text-end'>{taxTotal.toFixed(2)}</td>
                          </tr>
                          {addTaxTotal > 0 && (
                            <tr>
                              <td>
                                <a href='#' className='text-gray-600  text-hover-primary  fs-6'>
                                  Additional Tax
                                </a>
                              </td>
                              <td className='text-end'>{addTaxTotal?.toFixed(2)}</td>
                            </tr>
                          )}

                          <tr>
                            <td>
                              <a href='#' className='text-dark fw-bold text-hover-primary fs-3'>
                                Total
                              </a>
                            </td>
                            <td className='text-end fw-bold  fs-4'>{total.toFixed(2)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className='card-footer'>
                    {/* begin::Action */}

                    <div className='text-center'>
                      <button
                        type='submit'
                        id='kt_sign_in_submit'
                        className='btn btn-lg btn-primary w-100 mb-5'
                        disabled={formik.isSubmitting || !formik.isValid || rowsData.length === 0}
                      >
                        {!loading && (
                          <span className='indicator-label'>
                            {' '}
                            <i className='fa-solid fa-floppy-disk fs-4 me-2'></i> Generate Bill
                          </span>
                        )}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'inline'}}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                    {/* end::Action */}
                  </div>
                </div>
                {/* Total section details end*/}
              </div>
            </div>
          </form>
          {showCustomerForm && (
            <AddCustomerShort
              setShowCustomerForm={setShowCustomerForm}
              afterUpdate={(data: any) => afterUpdate(data, formik.setFieldValue)}
              number={number}
            />
          )}
        </div>
      )}
    </>
  )
}
