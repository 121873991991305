import axiosInstance from '../services/AxiosInstance';


export function searchProducts(searchParams) {
    return axiosInstance.post(`products/search`, searchParams);
}

export function getAllProducts() {
    return axiosInstance.get(`products`);
}


export function getProduct(id) {
    return axiosInstance.get(`products/${id}`);
}

export function saveProduct(data) {
    return axiosInstance.post(`products`, data);
}

export function updateProduct(id, data) {
    return axiosInstance.put(`products/${id}`, data);
}

export function searchCategories(searchParams) {
    return axiosInstance.post(`categories/search`, searchParams);
}

export function getAllProductsGroups() {
    return axiosInstance.get(`products/productGroup`);
}
export function getAllProductCategories(searchParams){
    
    return axiosInstance.post(`categories/search`, searchParams);
}


