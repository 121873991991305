import { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { IProductModel, initialUserValues } from '../../../_models/product-model';
import { ProductEditModalForm } from './_productForm';
import { getProduct } from "../../../services/CatalogService";
import { Loader, LOADINGSTAGES } from '../components/components';
import { ServerMessage } from '../../../lib/NoData';

const ProductEditModal = (props: any) => {
    const { setItemIdForUpdate, title, productId, setShowProductForm, afterUpdateProduct, isService } = props;
    const [product, setProduct] = useState<IProductModel>(initialUserValues);
    const [loadingStages, setLoadingStages] = useState<any>(LOADINGSTAGES.IDLE);

    const bindDetails = () => {
        setLoadingStages(LOADINGSTAGES.LOADING);
        setTimeout(() => {
            getProduct(productId)
                .then((res: any) => {
                    if (res.data.success) {
                        let _data = res.data.data;
                        setProduct(_data);
                        setTimeout(() => {
                            document.body.classList.add('modal-open')
                            return () => {
                                document.body.classList.remove('modal-open')
                            }
                        }, 700);
                        setLoadingStages(LOADINGSTAGES.POSITIVE);
                    }
                    else {
                        setLoadingStages(LOADINGSTAGES.NEGATIVE);
                    }
                }
                )
                .catch(() => {
                    setLoadingStages(LOADINGSTAGES.ERROR);
                    setProduct(initialUserValues);
                });
        }, 500);
    }

    const onCloseClick = () => {
        setProduct(initialUserValues);
        if (setItemIdForUpdate) { setItemIdForUpdate(undefined) };
        if (setShowProductForm) { setShowProductForm(false); }
    }

    useEffect(() => {
        if (productId > 0) {
            bindDetails();
        }
        else {
            setLoadingStages(LOADINGSTAGES.POSITIVE);
            document.body.classList.add('modal-open')
            return () => {
                document.body.classList.remove('modal-open')
            }
        }
    }, []);

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {loadingStages === LOADINGSTAGES.LOADING && <Loader />}
                {loadingStages === LOADINGSTAGES.NEGATIVE && <ServerMessage message="No data found" />}
                {loadingStages === LOADINGSTAGES.ERROR && <ServerMessage message="Something went wrong" />}
                {/* begin::Modal dialog */}
                {loadingStages === LOADINGSTAGES.POSITIVE && 
                <div className='modal-dialog modal-xl'>
                    {/* begin::Modal content */}
                    <div className='modal-content'>
                        <div className='modal-header'>
                            {/* begin::Modal title */}
                            <h2 className='fw-bolder'>{title} Product</h2>
                            {/* end::Modal title */}

                            {/* begin::Close */}
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                onClick={() => onCloseClick()}
                                style={{ cursor: 'pointer' }}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                            </div>
                            {/* end::Close */}
                        </div>
                        <div className='modal-body'>
                            {
                                productId > 0 ?
                                    product.name != '' && (
                                        <ProductEditModalForm
                                            isUserLoading={false}
                                            product={product}
                                            setItemIdForUpdate={setItemIdForUpdate}
                                            setShowProductForm={setShowProductForm}
                                            afterUpdateProduct={afterUpdateProduct}
                                            isService={isService}
                                        />
                                    )
                                    :
                                    <ProductEditModalForm
                                        isUserLoading={false}
                                        product={initialUserValues}
                                        setItemIdForUpdate={setItemIdForUpdate}
                                        setShowProductForm={setShowProductForm}
                                        afterUpdateProduct={afterUpdateProduct}
                                        isService={isService}
                                    />
                            }
                        </div>
                        {/* end::Modal body */}
                    </div>
                    {/* end::Modal content */}
                </div>}
                {/* end::Modal dialog */}
            </div>
            {/* begin::Modal Backdrop */}
            <div className='modal-backdrop fade show'></div>
            {/* end::Modal Backdrop */}
        </>
    )
}

export { ProductEditModal }
