import { useEffect, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import { initialUserValues } from '../../../_models/product-model';
import { ProductEditModalForm } from './_productForm';
import { DisableSidebar, PageLink, PageTitle } from '../../../_metronic/layout/core';

const profileBreadCrumbs: Array<PageLink> = [
    {
        title: 'Services',
        path: '/services',
        isSeparator: false,
        isActive: false,
    }
]

export function AddEditService(props: any) {

    const { id } = useParams();
    const [title, setTitle] = useState<any>(id == null ? "Add" : "Edit");

    useEffect(() => {

    }, []);

    return (
        <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>{title} Service</PageTitle>
            <DisableSidebar>
                <ProductEditModalForm
                    isUserLoading={false}
                    product={initialUserValues}
                    setItemIdForUpdate={props.setItemIdForUpdate}
                    setShowProductForm={props.setShowProductForm}
                    afterUpdateProduct={props.afterUpdateProduct}
                    isService={true}
                />
            </DisableSidebar>
        </>


    )
}