import moment from 'moment'
import {useState, useEffect} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {ServerMessage} from '../../../lib/NoData'
import {
  getInvoiceDetails,
  invoiceUpdate,
  invoiceDetailsUpdate,
  updateType,
} from '../../../services/InvoiceService'
import {KTSVG} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {getCompanyCode, getTaxes} from '../../../services/CommonService'
import {InvoiceCloneModal} from './partials/InvoiceCloneModal'
import {ListInvoiceItems} from './partials/InvoiceItems'
import AddressForm from './partials/AddressForm'
import {Loader, LOADINGSTAGES, N2WIndian} from '../components/components'
import AddressList from './partials/AddressList'
import {Button, Table} from 'react-bootstrap'
import {InvoiceTemplateSelect} from './partials/InvoiceTemplateSelecter'
import {InvoiceShare} from './partials/ShareModal'
import {toast} from 'react-toastify'
import {toastNotify} from '../../../services/NotifyService'
import InvoiceMail from './partials/InvoiceMail'
import {
  billByInitialValues,
  billToInitialValues,
  IBillBy,
  IBillTo,
} from '../interface/IInvoiceTypes/IQuotation'
import {
  IInvoice,
  IInvoiceAdditionalTax,
  IInvoiceItems,
  initialInvoiceItems,
  invoiceTaxInitVals,
  IInvoiceInputDto,
  initialInvoice,
} from '../interface/IInvoiceTypes/IInvoice'
import {getInvoiceTaxes} from '../../../services/QuotationService'
import {IInvoiceClone} from '../../../_models/invoice-model'
import {CompanyAddress, CustomerAddress} from '../components/AddressComponent'
import {IInvoiceDto, updateInvoiceInput, updateInvTaxes} from './iconsts'
import {addTaxInitValues, IAddTaxModel} from '../../../_models/additionaltax-model'
import {ChangeAlert} from '../../../_shared/components/ChangeAlert'
import StatusDropdown from '../components/QuotationSelectBar'
import InvoiceStatus, { IInvoiceType } from '../components/InvoiceStatus'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Invoices',
    path: '/invoices',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export function InvoiceDetails(props: any) {
  const location = useLocation()
  const [itemIdForUpdate, setItemIdForUpdate] = useState<any>()
  const {id} = useParams()
  const [data, setData] = useState<any>(null)
  const [tab, setTab] = useState(1)
  const [companyCode, setCompanyCode] = useState<any>('')
  const [invoiceId, setInvoiceId] = useState<any>(id || 0)
  const [saveInitialData, setSaveInitialData] = useState<any>({})
  const [showTemplate, setShowTemplate] = useState<any>(false)
  const [showShare, setShowShare] = useState<any>(false)
  const [isCancelled, setIsCancelled] = useState<any>(false)
  const [showMail, setShowMail] = useState<any>(false)
  const [loadingStages, setLoadingStages] = useState<any>(LOADINGSTAGES.IDLE)
  const [taxAmount, setTaxAmount] = useState<any>('0.00')
  const [adressBillBy, setAddressBillBy] = useState<IBillBy>(billByInitialValues)
  const [adressShipTo, setAddressShipTo] = useState<IBillTo>(billToInitialValues)
  const [subAmount, setSubAmount] = useState<any>(0)
  const [invoiceItems, setInvoiceItems] = useState<IInvoiceItems[]>([])

  const bindDetails = (values: any) => {
    setLoadingStages(LOADINGSTAGES.LOADING)
    setTimeout(() => {
      getInvoiceDetails(values)
        .then((res: any) => {
          if (res.data.success) {
            const _data = res.data.data as IInvoice
            console.log(_data)
            setData(_data)
            setInvoiceItems(_data.invoiceItems)
            bindInvTaxes(_data.id)
            setSubAmount(_data.subAmount ?? 0)
            setTaxAmount(_data.taxAmount ?? 0)
            setMailDetails({
              to: _data.customerEmail,
              subject: `Invoice ${_data.invoiceNumber}`,
              message: `Dear ${_data.billName},\n\nPlease find the attached invoice for your reference.\n\nThanks & Regards,\n${_data.companyName}`,
              invoiceNo: _data.invoiceNumber,
              customerName: _data.billName,
              invoiceId: _data.id,
            })
            setIsCancelled(_data.isCancel)
            setTotalAmount(_data.totalAmount)
            const [rupees, paisa] = _data.totalAmount?.toString().split('.') ?? []
            const amtInWords = `${N2WIndian.convert(rupees)} Rupees${
              paisa ? ` and ${N2WIndian.convert(paisa)} Paisa` : ''
            }`
            setAmtInWords(amtInWords)
            
            const addressBillBy = {..._data}
            
            setAddressBillBy(addressBillBy)
            const addressShipTo: any = {..._data}
            console.log("bill to",addressShipTo)
            setAddressShipTo(addressShipTo)
            const saveInitialData = {
              billBy: addressBillBy,
              shipTo: addressShipTo,
            }
            setSaveInitialData(saveInitialData)
            setLoadingStages(LOADINGSTAGES.POSITIVE)
          } else {
            setLoadingStages(LOADINGSTAGES.NEGATIVE)
          }
        })
        .catch(() => {
          setLoadingStages(LOADINGSTAGES.ERROR)
        })
        .finally(() => {})
    }, 100)
  }

  useEffect(() => {
    if (invoiceId > 0) {
      bindDetails(invoiceId)
    }
    bindTaxes()
    setCompanyCode(getCompanyCode())
  }, [])

  const [addressForm, setAddressForm] = useState<any>({type: '', status: null})
  const [editField, setEditField] = useState<any>({
    invoiceDate: false,
    invoiceDueDate: false,
    discount: false,
    addTax: false,
    invoiceNumber: false,
  })
  const bindInvTaxes = (invId: number) => {
    getInvoiceTaxes(invId)
      .then((res: any) => {
        if (res.data.success) {
          setTaxes(res.data.data)
        } else {
        }
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  const [invoiceEdited, setInvoiceEdited] = useState<any>({
    invoiceDate: data?.invoiceDate,
    invoiceDueDate: data?.invoiceDueDate,
    invoiceNumber: data?.invoiceNumber,
    discount: data?.discount,
    subAmount: data?.subAmount,
    taxAmount: data?.taxAmount,
    addTax: data?.addTax,
  })
  const [totalAmount, setTotalAmount] = useState<any>(null)
  const [amtInWords, setAmtInWords] = useState<any>(null)
  const [itemsData, setItemsData] = useState<any>({
    taxableAmount: 0,
    taxAmount: 0,
    totalAmount: 0,
  })
  const [taxes, setTaxes] = useState<IInvoiceAdditionalTax[]>([invoiceTaxInitVals])

  const [additionalTax, setAdditoinalTax] = useState<string>('0.00')
  const additionaTaxChange = () => {
    const additonalTax = taxes.reduce((acc: number, item: IInvoiceAdditionalTax) => {
      return acc + (item.taxPercent / 100) * subAmount ?? 0
    }, 0)
    setAdditoinalTax(additonalTax.toFixed(2))
  }
  useEffect(() => {
    additionaTaxChange()
  }, [taxes, subAmount])
  const [isItemsEdited, setIsItemsEdited] = useState<any>(false)
  const updateItemsData = (data: any) => {
    if (data) {
      setItemsData(data)
      setIsItemsEdited(true)
    }
  }

  useEffect(() => {
    let newData = {...data}
    newData.discount = editField.discount ? invoiceEdited.discount : newData.discount
    newData.addTax = additionalTax ? additionalTax : newData.addTax
    newData.subAmount = itemsData.taxableAmount ? itemsData.taxableAmount : newData.subAmount
    setSubAmount(itemsData.taxableAmount || 0)
    newData.taxAmount = itemsData.taxAmount ? itemsData.taxAmount : newData.taxAmount
    setTaxAmount(itemsData.taxAmount || 0)
    newData.invoiceNumber = editField.invoiceNumber
      ? invoiceEdited.invoiceNumber
      : newData.invoiceNumber

    const amt =
      Number.parseFloat(newData?.taxAmount || 0) +
      Number.parseFloat(newData?.subAmount || 0) +
      Number.parseFloat(newData?.addTax || 0) -
      Number.parseFloat(newData?.discount || 0)
    setTotalAmount(amt)

    const [rupees, paisa = '00'] = (amt >= 0 ? amt : -amt).toFixed(2).toString().split('.')
    setAmtInWords(
      `${amt >= 0 ? '' : 'Minus '}${N2WIndian.convert(rupees)} Rupees and ${N2WIndian.convert(
        paisa
      )} Paisa`
    )
  }, [
    invoiceEdited.discount,
    itemsData.subAmount,
    itemsData.taxAmount,
    invoiceEdited.addTax,
    itemsData.totalAmount,
  ])
  useEffect(() => {
    const amt =
      Number.parseFloat(taxAmount || 0) +
      Number.parseFloat(subAmount || 0) +
      Number.parseFloat(additionalTax ?? 0)
    setTotalAmount(amt)
  }, [additionalTax, taxAmount, subAmount])

  const UpdateInvoiceDetails = () => {
    const id = toast.loading('Please wait...')
    const __data = {...data}
    __data.subAmount = subAmount
    __data.taxAmount = taxAmount
    __data.discount = invoiceEdited.discount
    __data.addTax = additionalTax
    __data.totalAmount = totalAmount
    __data.amountInWords = amtInWords
    __data.invoiceItems = invoiceItems
    __data.invoiceAdditionalTaxes = taxes

    setTimeout(() => {
      // __data.totalAmount = totalAmount;
      // __data.amountInWords = amtInWords;
      invoiceUpdate(__data, isItemsEdited ? 'items' : 'invoice')
        .then((res: any) => {
          if (res.data.success) {
            toastNotify(id, res)
            bindDetails(invoiceId)
          } else {
            toastNotify(id, res)
          }
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {})
    }, 1000)
  }

  const [mailDetails, setMailDetails] = useState<any>({
    to: '',
    cc: '',
    bcc: '',
    subject: '',
    message: '',
  })

  const toggleShowTemplate = (invoiceId: any) => {
    setInvoiceId(invoiceId)
    setShowTemplate(true)
  }

  const setShowInvoiceModal = () => {
    setShowTemplate(false)
  }

  const toggleShowShare = (invoiceId: any) => {
    setInvoiceId(invoiceId)
    setShowShare(true)
  }

  const toggleShowMail = (invoiceId: any) => {
    setInvoiceId(invoiceId)
    setShowMail(true)
  }

  const setShowShareModal = () => {
    setShowShare(false)
  }
  const [defaultTaxes, setDefaultTaxes] = useState<IAddTaxModel[]>([addTaxInitValues])

  const bindTaxes = () => {
    setTimeout(() => {
      getTaxes(true)
        .then((res: any) => {
          let _data = res.data.data
          setDefaultTaxes(_data)
        })
        .catch(() => {})
    }, 1000)
  }
  const [addTax, setAddTax] = useState(false)

  const deleteTaxItem = (index: number) => {
    const newTaxRowsData = [...taxes]
    newTaxRowsData.splice(index, 1)
    setTaxes(newTaxRowsData)
  }

  const addTaxRow = () => {
    setTaxes([...taxes, {...invoiceTaxInitVals, taxId: 0}])
  }

  const handleTaxChange = (index: number, evnt: any) => {
    const {name, value} = evnt.target
    // pass value for each name
    const rowsInput = [...taxes]
    const taxInfo = defaultTaxes.find((c) => c.id === +value)
    if (+value > 0) {
      if (taxInfo) {
        rowsInput[index]['taxId'] = taxInfo.id
        rowsInput[index]['taxName'] = taxInfo.taxName
        rowsInput[index]['taxPercent'] = taxInfo.taxValue
        setTaxes(rowsInput)
      }
    } else {
      rowsInput[index]['taxName'] = 'None'
      rowsInput[index]['taxPercent'] = taxInfo?.taxValue || 0
      rowsInput[index]['taxId'] = taxInfo?.id || 0
      setTaxes(rowsInput)
    }
  }
  const isDisabled = (taxId: number) => {
    if (taxId === 0) return false
    return taxes.some((c) => c.taxId === taxId)
  }
  const updateInvTxs = () => {
    const dto: IInvoiceDto = {
      addTax: additionalTax as any,
      taxAmount: data.taxAmount,
      subAmount: subAmount,
      totalAmount: totalAmount,
      invoiceAdditionalTaxes: taxes,
      id: invoiceId,
      totalFreightCharges: data.totalFreightCharges,
    }
    const onResponse = (res: any) => {
      if (res) {
        bindDetails(invoiceId)
      }
    }
    const newDto = {
      tax: dto,
      onResponse,
    }
    updateInvTaxes(newDto)
  }

  const afterUpdate = () => {
    bindDetails(invoiceId)
  }
  const afterTaxUpdate = () => {
    setAddTax(false)
    // bindDetails(quotationId)
  }
  const [showAlert, setShowAlert] = useState(false)
  const [invType, setInvType] = useState<IInvoiceType>('');
  
  function handleTypeChange(type: IInvoiceType) {
    setShowAlert(true)
    setInvType(type)
  }
  return (
    <>
      <>
        {' '}
        <PageTitle breadcrumbs={profileBreadCrumbs}>Invoice Detail</PageTitle>
        <div className='card mb-5 mb-xl-10'>
          <div className='card-body pt-9 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
              <div className='flex-grow-1'>
                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      {loadingStages === LOADINGSTAGES.POSITIVE && (
                        <a
                          href='#'
                          className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'
                        >
                          # {data?.invoiceNumber}
                          {data?.isDraft ? (
                            <span
                              className='badge badge-light-warning ms-2'
                            >
                              Draft
                            </span>
                          ) : (
                            ''
                          )}
                          {data?.isProforma ? (
                            <span
                              className='badge badge-light-info ms-2'
                            >
                              Proforma
                            </span>
                          ) : (
                            ''
                          )}
                        </a>
                      )}
                    </div>
                  </div>
                  <div className='d-flex my-4'>
                    {companyCode != '' && (
                      <a
                        href='#'
                        onClick={() => toggleShowTemplate(data?.id)}
                        className={`btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1`}
                        id='kt_charts_widget_2_year_btn'
                      >
                        <i className='fas fa-print'></i> Preview &amp; Print
                      </a>
                    )}
                    <a
                      href='#'
                      onClick={() => toggleShowShare(data?.id)}
                      className={`btn btn-sm btn-color-muted btn-active btn-active-secondary active px-4 me-1`}
                      id='kt_charts_widget_2_year_btn'
                    >
                      <i className='fas fa-share'></i> Share
                    </a>
                    <a
                      href='#'
                      onClick={() => toggleShowMail(data?.id)}
                      className={`btn btn-sm btn-color-muted btn-active btn-active-secondary active px-4 me-1`}
                      id='kt_charts_widget_2_year_btn'
                    >
                      <i className='fas fa-envelope'></i> Email
                    </a>
                    <InvoiceStatus
                    isCancel={isCancelled}
                    isDraft={data?.isDraft}
                    isProforma={data?.isProforma}
                    onStatusClick={handleTypeChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            {loadingStages === LOADINGSTAGES.LOADING && <Loader />}
            {loadingStages === LOADINGSTAGES.NEGATIVE && <ServerMessage message='No Data Found' />}
            {loadingStages === LOADINGSTAGES.ERROR && (
              <ServerMessage message='Oops! server issue occured' />
            )}
            {loadingStages === LOADINGSTAGES.POSITIVE && (
              <>
                <div className='row mb-3'>
                  <div className='col-md-12'>
                    <div className='row mb-7'>
                      <div className='col-lg-4'>
                        <h6 className='fw-bold text-muted mb-1'>
                          Bill By
                          {!isCancelled && (
                            <i
                              className=' text-info fas fa-edit px-15 d-none'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_upgrade_plan'
                              onClick={() =>
                                setAddressForm({
                                  type: 'billBy',
                                  status: true,
                                })
                              }
                            ></i>
                          )}
                          <i
                            className=' text-danger fas fa-details px-15 d-none'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_upgrade_plan'
                            onClick={() =>
                              setAddressForm({
                                type: 'companyAddresses',
                                status: true,
                              })
                            }
                          ></i>
                        </h6>
                        {/* // edit Icon  */}

                        <CompanyAddress {...adressBillBy} />
                      </div>

                      <div className='col-lg-4'>
                        <h6 className=' fw-bold text-muted mb-1'>
                          Bill To
                          {!isCancelled && (
                            <i
                              className=' text-info fas fa-edit px-15 d-none'
                              onClick={() =>
                                setAddressForm({
                                  type: 'shipTo',
                                  status: true,
                                })
                              }
                            ></i>
                          )}
                        </h6>

                        <CustomerAddress {...adressShipTo} />
                      </div>
                      <div className='col-lg-4'>
                        <div>
                          <h6 className=' fw-bold text-muted mb-1'>Invoice Date</h6>
                          {!editField.invoiceDate ? (
                            <>
                              <span className='fw-bolder fs-6 text-dark'>
                                {moment(data?.invoiceDate).format('DD-MM-YYYY')}
                              </span>
                              <i
                                className=' text-info fas fa-edit px-15'
                                onClick={() =>
                                  setEditField({
                                    ...editField,
                                    invoiceDate: !editField.invoiceDate,
                                  })
                                }
                              ></i>
                            </>
                          ) : (
                            <div className='d-flex align-items-center '>
                              <input
                                type='date'
                                className='form-control me-2'
                                defaultValue={moment(data?.invoiceDate).format('YYYY-MM-DD')}
                                value={invoiceEdited.invoiceDate}
                                onChange={(e) =>
                                  setInvoiceEdited({
                                    ...invoiceEdited,
                                    invoiceDate: e.target.value,
                                  })
                                }
                              />
                              <div className='d-flex'>
                                <i
                                  className='fa fa-close btn btn-sm btn-danger me-2'
                                  onClick={() =>
                                    setEditField({
                                      ...editField,
                                      invoiceDate: !editField.invoiceDate,
                                    })
                                  }
                                ></i>
                                <i
                                  className='fa fa-check btn btn-sm btn-success'
                                  onClick={() => {
                                    updateInvoiceInput({
                                      type: 'invoiceDate',
                                      dto: {
                                        id: parseInt(invoiceId),
                                        invoiceDate: invoiceEdited.invoiceDate,
                                      },
                                      onResponse: (e) => {
                                        if (e) {
                                          setEditField({
                                            ...editField,
                                            invoiceDate: !editField.invoiceDate,
                                          })
                                          bindDetails(id)
                                        }
                                      },
                                    })
                                  }}
                                ></i>
                              </div>
                            </div>
                          )}
                        </div>
                        {/* <div>
                          <h6 className=' fw-bold text-muted mb-1'>Invoice Date</h6>
                          {!editField.invoiceDate ? (
                            <>
                              <span className='fw-bolder fs-6 text-dark'>
                                {moment(data?.invoiceDate).format('DD-MM-YYYY')}
                              </span>
                              {isCancelled === false && (
                                <i
                                  className=' text-info fas fa-edit px-15'
                                  onClick={() =>
                                    setEditField({
                                      ...editField,
                                      invoiceDate: !editField.invoiceDate,
                                    })
                                  }
                                ></i>
                              )}
                            </>
                          ) : (
                            <div className='d-flex align-items-center '>
                              <input
                                type='date'
                                className='form-control me-2'
                                defaultValue={moment(data?.invoiceDate).format('YYYY-MM-DD')}
                                value={invoiceEdited.invoiceDate}
                                onChange={(e) =>
                                  setInvoiceEdited({
                                    ...invoiceEdited,
                                    invoiceDate: e.target.value,
                                  })
                                }
                              />
                              <div className='d-flex'>
                                <i
                                  className='fa fa-close btn btn-sm btn-danger me-2'
                                  onClick={() =>
                                    setEditField({
                                      ...editField,
                                      invoiceDate: !editField.invoiceDate,
                                    })
                                  }
                                ></i>
                                <i
                                  className='fa fa-check btn btn-sm btn-success'
                                  onClick={() => {
                                    updateInvoiceInput({
                                      type: 'invoiceDate',
                                      dto: {
                                        id: parseInt(invoiceId),
                                        invoiceDate: invoiceEdited.invoiceDate,
                                      },
                                      onResponse: (e) => {
                                        if (e) {
                                          setEditField({
                                            ...editField,
                                            invoiceDate: !editField.invoiceDate,
                                          })
                                          bindDetails(id)
                                        }
                                      },
                                    })
                                  }}
                                ></i>
                              </div>
                            </div>
                          )}
                        </div> */}
                        <div>
                          <h6 className=' fw-bold text-muted mb-1' style={{marginTop: '1em'}}>
                            Invoice Due Date
                          </h6>
                          {!editField.invoiceDueDate ? (
                            <>
                              <span className='fw-bolder fs-6 text-dark'>
                                {moment(data?.invoiceDueDate).format('DD-MM-yyyy')}
                              </span>
                              {isCancelled === false && (
                                <i
                                  className=' text-info fas fa-edit px-15'
                                  onClick={() =>
                                    setEditField({
                                      ...editField,
                                      invoiceDueDate: !editField.invoiceDueDate,
                                    })
                                  }
                                ></i>
                              )}
                            </>
                          ) : (
                            <div className='d-flex align-items-center'>
                              <input
                                type='date'
                                className='form-control me-2'
                                defaultValue={moment(data?.invoiceDueDate).format('YYYY-MM-DD')}
                                value={invoiceEdited.invoiceDueDate}
                                onChange={(e) =>
                                  setInvoiceEdited({
                                    ...invoiceEdited,
                                    invoiceDueDate: e.target.value,
                                  })
                                }
                              />
                              <div className='d-flex'>
                                <i
                                  className='fa fa-close btn btn-sm btn-danger me-2'
                                  onClick={() =>
                                    setEditField({
                                      ...editField,
                                      invoiceDueDate: !editField.invoiceDueDate,
                                    })
                                  }
                                ></i>
                                <i
                                  className='fa fa-check btn btn-sm btn-success'
                                  onClick={() => {
                                    updateInvoiceInput({
                                      type: 'invoiceDueDate',
                                      dto: {
                                        id: parseInt(invoiceId),
                                        invoiceDueDate: invoiceEdited.invoiceDueDate,
                                      },
                                      onResponse: (e) => {
                                        if (e) {
                                          setEditField({
                                            ...editField,
                                            invoiceDueDate: !editField.invoiceDueDate,
                                          })
                                          bindDetails(id)
                                        }
                                      },
                                    })
                                  }}
                                ></i>
                              </div>
                            </div>
                          )}
                        </div>
                        <div>
                          <h6 className=' fw-bold text-muted mb-1' style={{marginTop: '1em'}}>
                            Invoice Number
                          </h6>
                          {!editField.invoiceNumber ? (
                            <>
                              <span className='fw-bolder fs-6 text-dark'>
                                {data?.invoiceNumber}
                              </span>
                              {/* < i className=' text-info fas fa-edit px-15'
                                                        onClick={() => setEditField({
                                                            ...editField,
                                                            invoiceNumber: !editField.invoiceNumber
                                                        })}></i> */}
                              {isCancelled === false && (
                                <i
                                  className=' text-info fas fa-edit px-15'
                                  onClick={() =>
                                    setEditField({
                                      ...editField,
                                      invoiceNumber: !editField.invoiceNumber,
                                    })
                                  }
                                ></i>
                              )}
                            </>
                          ) : (
                            <div className='d-flex align-items-center'>
                              <input
                                type='numeric'
                                className='form-control me-2'
                                defaultValue={data?.invoiceNumber}
                                value={invoiceEdited.invoiceNumber}
                                onChange={(e) =>
                                  setInvoiceEdited({
                                    ...invoiceEdited,
                                    invoiceNumber: e.target.value,
                                  })
                                }
                              />
                              <div className='d-flex'>
                                <i
                                  className='fa fa-close btn btn-sm btn-danger me-2'
                                  onClick={() =>
                                    setEditField({
                                      ...editField,
                                      invoiceNumber: !editField.invoiceNumber,
                                    })
                                  }
                                ></i>
                                <i
                                  className='fa fa-check btn btn-sm btn-success'
                                  onClick={() => {
                                    updateInvoiceInput({
                                      type: 'invoiceNumber',
                                      dto: {
                                        id: parseInt(invoiceId),
                                        invoiceNumber: invoiceEdited.invoiceNumber,
                                      },
                                      onResponse: (e) => {
                                        if (e) {
                                          setEditField({
                                            ...editField,
                                            invoiceNumber: !editField.invoiceNumber,
                                          })
                                          bindDetails(id)
                                        }
                                      },
                                    })
                                  }}
                                ></i>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='row mb-7'>
                      <div className='col-lg-3'>
                        <h6 className=' fw-bold text-muted mb-1'>Taxable Amount</h6>
                        {/* {!editField.subAmount ? (
                                            <> */}
                        <span className='fw-bolder fs-6 text-dark'>{subAmount.toFixed(2)}</span>
                        <br />
                        <i>Discount:</i>
                        {!editField.discount ? (
                          <>
                            <span className='fw-bolder fs-6 text-dark'>
                              {' '}
                              {data?.discount?.toFixed(2)}
                            </span>
                            {/* {isCancelled===false&&<i className=' text-info fas fa-edit px-15' onClick={() => setEditField({
                                                    ...editField,
                                                    discount: !editField.discount
                                                })}></i>} */}
                          </>
                        ) : (
                          <input
                            type='numeric'
                            className='form-control'
                            defaultValue={data?.discount ? data?.discount?.toFixed(2) : 0}
                            value={invoiceEdited?.discount}
                            onChange={(e) =>
                              setInvoiceEdited({
                                ...invoiceEdited,
                                discount: e.target.value,
                              })
                            }
                          />
                        )}
                        
                         <i>&nbsp; Add Discount:</i>
                        {!editField.addDiscount ? (
                          <>
                            <span className='fw-bolder fs-6 text-dark'>
                              {' '}
                              {data?.addDiscount?.toFixed(2)}
                            </span>
                            {/* {isCancelled===false&&<i className=' text-info fas fa-edit px-15' onClick={() => setEditField({
                                                    ...editField,
                                                    discount: !editField.discount
                                                })}></i>} */}
                          </>
                        ) : (
                          <input
                            type='numeric'
                            className='form-control'
                            defaultValue={data?.addDiscount ? data?.addDiscount?.toFixed(2) : 0}
                            value={invoiceEdited?.addDiscount}
                            onChange={(e) =>
                              setInvoiceEdited({
                                ...invoiceEdited,
                                addDiscount: e.target.value,
                              })
                            }
                          />
                        )}
                      </div>
                      <div className='col-lg-3'>
                        <h6 className=' fw-bold text-muted mb-1'>Tax Amount</h6>
                        <span className='fw-bolder fs-6 text-dark'>{taxAmount.toFixed(2)}</span>
                      </div>
                      <div className='col-lg-3'>
                        <h6 className=' fw-bold text-muted mb-1'>Additional Tax</h6>
                        <span className='fw-bolder fs-6 text-dark'>
                          {parseFloat(additionalTax).toFixed(2)}
                        </span>
                      </div>
                      <div className='col-lg-3'>
                        <h6 className=' fw-bold text-muted mb-1'>Amount</h6>
                        <span className='fw-bolder fs-6 text-dark'>{totalAmount.toFixed(2)}</span>
                      </div>
                    </div>
                    <div className='row mb-7'>
                      <div className='col-lg-9'>
                        <h6 className=' fw-bold text-muted mb-1'>Amount in Words</h6>
                        <span className='fw-bolder fs-6 text-dark text-capitalize'>
                          {amtInWords}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='separator'></div>
                <div className='row my-3'>
                  <h6 className='fw-bold text-muted mb-1'>
                    Additonal Taxes{' '}
                    <button
                      className='btn btn-sm btn-light p-5'
                      style={{fontSize: '0.2rem'}}
                      onClick={() => setAddTax(true)}
                    >
                      <i className='fa fa-plus' />
                    </button>
                  </h6>
                  <div className='row mb-lg-10 p-0'>
                    <Table className='table align-middle table-row-dashed fs-6 px-5 dataTable no-footer'>
                      <tbody>
                        {taxes.map((item: IInvoiceAdditionalTax, index: number) => {
                          // disable all rows which are available in initialTaxes
                          return (
                            <tr key={index}>
                              <td className='w-350px'>
                                <select
                                  className='form-control'
                                  name='taxName'
                                  id={`taxName_${index}`}
                                  value={item.taxId}
                                  onChange={(evnt) => handleTaxChange(index, evnt)}
                                  disabled={isDisabled(item.taxId)}
                                >
                                  <option value={0}>None</option>
                                  {isDisabled(item.taxId)
                                    ? defaultTaxes.filter((tax) => tax.id === item.taxId).length >
                                        0 && <option value={item.taxId}>{item.taxName}</option>
                                    : defaultTaxes.map((tax) => (
                                        <option
                                          key={tax.id}
                                          value={tax.id}
                                          disabled={isDisabled(tax.id)}
                                        >
                                          {tax.taxName}
                                        </option>
                                      ))}
                                </select>
                              </td>
                              <td className='w-350px'>
                                <input
                                  className='form-control'
                                  name='taxPercent'
                                  id={`taxPercent_${index}`}
                                  value={item.taxPercent}
                                  disabled={
                                    defaultTaxes.filter((tax) => tax.id === item.taxId).length > 0
                                  }
                                  onChange={(evnt) => handleTaxChange(index, evnt)}
                                />
                              </td>
                              <td>
                                <span className='fw-bolder fs-6 text-dark'>
                                  {((item.taxPercent / 100) * subAmount ?? 0).toFixed(2)}
                                </span>
                              </td>
                              <td className='w-200px'>
                                <a onClick={() => setAddTax(true)}>
                                  {' '}
                                  <i className='las la-plus fs-2x'></i>
                                </a>
                                <a onClick={() => deleteTaxItem(index)} href='#'>
                                  {' '}
                                  <i className='las la-trash fs-2x text-danger me-5'></i>
                                </a>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                    <div className='d-flex justify-content-end'>
                      <div className='d-flex flex-column align-items-end me-5'>
                        <button
                          className='btn btn-sm btn-light-primary'
                          onClick={() => {
                            addTaxRow()
                          }}
                        >
                          <i className='fa fa-plus' />
                          Add Tax
                        </button>
                        <span className='fw-bolder fs-6 text-dark mt-2'>
                          Add Tax Amount:{' '}
                          <span className='fw-bolder fs-6 text-dark'>{additionalTax}</span>
                          <button
                            className='btn btn-sm btn-light-primary ms-2'
                            onClick={() => {
                              updateInvTxs()
                            }}
                          >
                            Update Taxes
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='separator'></div>
                <div className='row my-3'>
                  {/* <h6 className='fw-bold text-muted mb-1'>
                    Change Draft Status{' '}
                    <button
                      className='btn btn-sm btn-light p-5'
                      style={{fontSize: '0.2rem'}}
                      onClick={() => setAddTax(true)}
                    >
                      <i className='fa fa-plus' />
                    </button>
                  </h6> */}
                </div>
                <div className='row mt-5'>
                  <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                    <li className='nav-item'>
                      <a
                        onClick={() => setTab(1)}
                        className='nav-link active'
                        data-bs-toggle='tab'
                        href='#'
                      >
                        Items
                      </a>
                    </li>
                  </ul>
                  <div className='separator'></div>
                  <div className='d-flex justify-content-end'>
                    <div className='d-flex flex-column align-items-end me-5 mt-3'>
                      <button
                        className='btn btn-sm btn-light-primary'
                        onClick={() => UpdateInvoiceDetails()}
                      >
                        Update item(s)
                      </button>
                    </div>
                  </div>
                  <div className='col-md-12 mt-5'>
                    {tab == 1 && data && invoiceItems?.length > 0 && (
                      <ListInvoiceItems
                        invoiceId={id}
                        data={invoiceItems}
                        isCancelled={isCancelled}
                        setItemsData={updateItemsData}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {showAlert && (
          <ChangeAlert
            message={`Are you sure you want to change ${invType} status?`}
            onConfirm={() => {
              const id = toast.loading('Please wait...')
              updateType(invoiceId, invType)
                .then((res: any) => {
                  if (res.data.success) {
                    toastNotify(id, res)
                    bindDetails(invoiceId)
                  }
                })
                .catch((err) => {
                  console.log(err)
                  const res = {data: {success: false, message: 'Something went wrong'}}
                  toastNotify(id, res)
                })
                .finally(() => {
                  toast.dismiss(id)
                  setShowAlert(false)
                })
            }}
            onCancel={() => setShowAlert(false)}
          />
        )}
        {itemIdForUpdate !== undefined && (
          <InvoiceCloneModal data={data} setItemIdForUpdate={setItemIdForUpdate} />
        )}
        <AddressForm
          id={invoiceId}
          initialData={saveInitialData}
          setBillBy={setAddressBillBy}
          setBillTo={setAddressShipTo}
          type={addressForm.type}
          billBy={adressBillBy}
          billTo={adressShipTo}
          show={addressForm.status}
          onUpdate={() => afterUpdate()}
          handleClose={() => setAddressForm(false)}
        />
        {addressForm.type === 'companyAddresses' ? (
          <AddressList
            show={addressForm.status}
            companyId={data?.companyId}
            customerId={data?.customerId}
            handleClose={() => setAddressForm(false)}
          />
        ) : null}
        {showMail && (
          <InvoiceMail
            mailDetails={mailDetails}
            setShowEmail={setShowMail}
            handleClose={() => setShowMail(false)}
            data={data}
          />
        )}
        {showTemplate && (
          <InvoiceTemplateSelect
            setShowInvoiceModal={setShowInvoiceModal}
            invoiceId={invoiceId}
            companyCode={companyCode}
          />
        )}
        {showShare && (
          <InvoiceShare
            setShowShareModal={setShowShareModal}
            invoiceId={invoiceId}
            companyCode={companyCode}
            template={'classic'}
          />
        )}
      </>
    </>
  )
}
