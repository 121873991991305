import axiosInstance from '../AxiosInstance';

export function searchCreditNotes(searchParams) {
    return axiosInstance.post(`creditnote/search`, searchParams);
}
export function postCreditNote(data) {
    return axiosInstance.post(`creditnote/addNew`, data);
}

export function getCreditNote(id) {
    return axiosInstance.get(`creditnote/get/${id}`);
}
export function updateCreditNote(data,type) {
    return axiosInstance.put(`creditnote/cn-update/${type}`, data);
}
