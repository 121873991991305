import moment from 'moment'
import React, {useState, useEffect} from 'react'
import {Link, Navigate, Outlet, Route, Routes, useLocation, useNavigate, useParams} from 'react-router-dom'
import {ServerMessage} from '../../../lib/NoData'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {getCompanyCode} from '../../../services/CommonService'
import {InvoiceCloneModal} from './partials/InvoiceCloneModal'
import {ListDeliveryChallan} from './partials/deliverychallan_items.tsx'
import AddressForm from './partials/AddressForm'
import {convertAmountToWords, Loader, LOADINGSTAGES, N2WIndian} from '../components/components'
import AddressList from './partials/AddressList'
import {Button} from 'react-bootstrap'
import {InvoiceTemplateSelect} from './partials/InvoiceTemplateSelecter'
import {InvoiceShare} from './partials/ShareModal'
import {toast} from 'react-toastify'
import {toastNotify} from '../../../services/NotifyService'
import InvoiceMail from './partials/InvoiceMail'
import {getDeliveryChallanDetails, updateDChallan} from '../../../services/DeliveryChallanService'
import {
  DCBillBy,
  dcBillByInitialValues,
  DCPartial,
  DCShipTo,
  dcShipToInitialValues,
  deliveryChallan,
  DeliveryChallan,
  DeliveryChallanItem,
  deliveryChallanItemInitialValues,
  DeliveryChallanModel,
  IInvoicePart,
} from '../interface/IInvoiceTypes/IdeliveryChallan'
const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Delivery Challan',
    path: '/deliverychallan/details',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
function parseParam(param: any) {
  const parsedParam = parseInt(param, 10)
  const finalParam = isNaN(parsedParam) ? 0 : parsedParam
  return finalParam
}

export function DeliveryChallanDetail(props: any) {
  const location = useLocation()
  const navigate = useNavigate()
  const [itemIdForUpdate, setItemIdForUpdate] = useState<any>()
  const {id, supplierId, customerId} = useParams()
  const [data, setData] = useState<DeliveryChallan>(deliveryChallan)
  const [dataItems, setItemData] = useState<DeliveryChallanItem[]>([])
  const [tab, setTab] = useState(1)
  const [companyCode, setCompanyCode] = useState<any>('')
  const [invoiceId, setInvoiceId] = useState<any>(id || 0)
  const [isLoading, setIsLoading] = useState<any>(id || 0)
  interface DCData {
    billBy: DCBillBy
    shipTo: DCShipTo
  }
  const [saveInitialData, setSaveInitialData] = useState<DCData>({
    billBy: dcBillByInitialValues,
    shipTo: dcShipToInitialValues,
  })
  const [showTemplate, setShowTemplate] = useState<any>(false)
  const [showShare, setShowShare] = useState<any>(false)
  const [isCancelled, setIsCancelled] = useState<any>(false)
  const [showMail, setShowMail] = useState<any>(false)

  const [billBy, setBillBy] = useState<DCBillBy>(dcBillByInitialValues)
  const values = {
    id: 0,
    supplierId: 0,
    customerId: 0,
    companyId: 1,
  }
  const [shipTo, setShipTo] = useState<DCShipTo>(dcShipToInitialValues)
  const [stages, setStages] = useState(LOADINGSTAGES.IDLE)

  const bindDetails = (values: any) => {
    setStages(LOADINGSTAGES.LOADING)
    getDeliveryChallanDetails(values)
      .then((res: any) => {
        if (res.data.success) {
          let _data: DeliveryChallanModel = res.data.data
          try {
            setData(_data.deliveryChallan)
          } catch (error) {
            throw error
          }
          const {
            shipName,
            shipAddress,
            shipCity,
            customerContactNo,
            customerEmail,
            shipState,
            customerGstin,
            customerPan,
            shipPostalCode,
          } = _data.deliveryChallan as DCShipTo
          const shippingInfo = {
            shipName,
            shipAddress,
            shipCity,
            customerContactNo,
            customerEmail,
            shipState,
            customerGstin,
            customerPan,
            shipPostalCode,
          }
          setShipTo(shippingInfo)
          const dcBillBy: DCBillBy = {
            billName: _data.deliveryChallan.billName ?? '',
            billAddress: _data.deliveryChallan.billAddress ?? '',
            billCity: _data.deliveryChallan.billCity ?? '',
            billState: _data.deliveryChallan.billState ?? '',
            billPostalCode: _data.deliveryChallan.billPostalCode ?? '',
            companyGstin: _data.deliveryChallan.companyGstin ?? '',
          }
          setBillBy(dcBillBy)
          setItemData(_data.deliveryChallanItems ?? [])
          
          setMailDetails({
            to: _data.deliveryChallan.customerEmail,
            subject: `deliverychallan ${_data.deliveryChallan.challanNumber}`,
            message: `Dear ${
              _data.deliveryChallan.billName
            },\n\nPlease find the attached invoice for your reference.\n\nThanks & Regards,\n${
              _data.organization.companyName ?? ''
            }`,
            invoiceNo: _data.deliveryChallan.challanNumber,
            customerName: _data.deliveryChallan.shipName,
            invoiceId: _data.deliveryChallan.challanNumber,
          })
          setIsCancelled(_data.deliveryChallan.isCancel)
          setTotalAmount(_data.deliveryChallan.totalAmount)
          if (_data.deliveryChallan.totalAmount.toString().includes('.')) {
            const [r, p] = _data.deliveryChallan.totalAmount.toString().split('.')
            setAmtInWords(`${N2WIndian.convert(r)} Rupees and ${N2WIndian.convert(p)} Paisa`)
          } else {
            setAmtInWords(
              `${N2WIndian.convert(_data.deliveryChallan.totalAmount.toString())} Rupees`
            )
          }
          setSaveInitialData({
            billBy: {
              billName: _data.deliveryChallan.billName ?? '',
              billAddress: _data.deliveryChallan.billAddress ?? '',
              billCity: _data.deliveryChallan.billCity ?? '',
              billPostalCode: _data.deliveryChallan.billPostalCode ?? '',
              companyGstin: _data.deliveryChallan.companyGstin ?? '',
              billState: _data.deliveryChallan.billState ?? '',
              // mobile: _data.organization.Mobile ?? '',
              // : _data.organization.Email ?? '',const [dataItems, setItemData] = useState<DeliveryChallanItem[]>([]);
            },
            shipTo: shippingInfo,
          })
          setStages(LOADINGSTAGES.POSITIVE)
        } else {
          setStages(LOADINGSTAGES.NEGATIVE)
        }
      })
      .catch((error) => {
        setStages(LOADINGSTAGES.ERROR)
        console.log(error)
      })
  }

  useEffect(() => {
    if (invoiceId > 0) {
      const finalId = parseParam(id)
      const finalSupplierId = parseParam(supplierId)
      const finalCustomerId = parseParam(customerId)
      values.customerId = finalCustomerId
      values.supplierId = finalSupplierId
      values.id = finalId
      bindDetails(values)
    }
    setCompanyCode(getCompanyCode())
  }, [])
  const [addressForm, setAddressForm] = useState<any>({type: '', status: null})
  const [editField, setEditField] = useState<any>({
    challanDate: false,

    discount: false,
    addTax: false,
    challanNumber: false,
  })

  const [invoiceEdited, setInvoiceEdited] = useState<IInvoicePart>({
    challanDate: data?.challanDate??"",

    challanNumber: data?.challanNumber,
    // discount: data?.discount,
    subAmount: data?.subAmount,
    taxAmount: data?.taxAmount,
    // addTax: data?.addTax,
  })
  const [totalAmount, setTotalAmount] = useState<any>(null)
  const [amtInWords, setAmtInWords] = useState<any>(null)
  const [itemsData, setItemsData] = useState<any>({
    taxableAmount: 0,
    taxAmount: 0,
    totalAmount: 0,
  })
  const [isItemsEdited, setIsItemsEdited] = useState<any>(false)
  const updateItemsData = (data: any) => {
    if (data) {
      setItemsData(data)
      setIsItemsEdited(true)
    }
  }
  useEffect(() => {
    if (itemsData.taxableAmount) {
      data.subAmount = itemsData.taxableAmount
    }
    if (itemsData.taxAmount) {
      data.taxAmount = itemsData.taxAmount
    }
    if (itemsData.totalAmount) {
      setTotalAmount(itemsData.totalAmount)
    }
    if (editField.challanNumber) {
      data.challanNumber = invoiceEdited.challanNumber
    }
    let amt =
      Number(data?.taxAmount) +
      Number(data?.subAmount)
    if (amt >= 0) {
      setTotalAmount(amt)
      const [r, p] = amt.toString().split('.')
      if (amt.toString().includes('.')) {
        setAmtInWords(`${N2WIndian.convert(r)} Rupees and ${N2WIndian.convert(p)} Paisa`)
      } else {
        setAmtInWords(`${N2WIndian.convert(amt.toString())} Rupees`)
      }
    } else {
      setTotalAmount(amt)
      const [r, p] = amt.toString().split('.')
      if (amt.toString().includes('.')) {
        setAmtInWords(`Minus ${N2WIndian.convert(r)} Rupees and ${N2WIndian.convert(p)} Paisa`)
      } else {
        setAmtInWords(`Minus ${N2WIndian.convert(amt.toString())} Rupees`)
      }
    }
  }, [
    // invoiceEdited.discount,
    itemsData.subAmount,
    itemsData.taxAmount,
    // invoiceEdited.addTax,
    itemsData.totalAmount,
  ])

  const UpdateInvoiceDetails = () => {
    if (editField.challanNumber) {
          data.challanNumber = invoiceEdited.challanNumber
        }
        if (invoiceEdited.challanDate) {
          data.challanDate = invoiceEdited.challanDate
        }
        if (invoiceEdited.challanNumber) {
          data.challanNumber = invoiceEdited.challanNumber
        }
        
        if (invoiceEdited.subAmount) {
          data.subAmount = invoiceEdited.subAmount
        }
        if (invoiceEdited.taxAmount) {
          data.taxAmount = invoiceEdited.taxAmount
        }
        if (totalAmount) {
          data.totalAmount = totalAmount
        }
        if (amtInWords) {
          data.amountInWords = amtInWords
        }
        // if (invoiceId > 0) {
        //   data.id = invoiceId
        // }
    const id = toast.loading('Please wait...')
    setIsLoading(true)
    const updatedInvoice: DCPartial = {
      id: invoiceId as number,
      challanNumber: data.challanNumber ?? '',
      challanDate: data.challanDate ??'',
      totalAmount: totalAmount,
      amountInWords: amtInWords,
      dcBillBy: {
        billName: billBy.billName,
        billAddress: billBy.billAddress,
        billCity: billBy.billCity,
        billPostalCode: billBy.billPostalCode,
        billState: billBy.billState,
        companyGstin: billBy.companyGstin,
      },
      dcShipTo: {
        customerContactNo: shipTo.customerContactNo,
        customerEmail: shipTo.customerEmail,
        customerGstin: shipTo.customerGstin,
        customerPan: shipTo.customerPan,
        shipAddress: shipTo.shipAddress,
        shipCity: shipTo.shipCity,
        shipName: shipTo.shipName,
        shipPostalCode: shipTo.shipPostalCode,
        shipState: shipTo.shipState,
      },
      status: data.status,
      customerId: data.customerId??0,
      isCancel: data.isCancel,
      branchId: data.branchId,
      deliveryChallanItems: dataItems,
      subAmount: invoiceEdited.subAmount??0.0,
      taxAmount: invoiceEdited.taxAmount??0.0
    }

    const updateType = isItemsEdited ? 'items' : 'invoice'

    setTimeout(() => {
      updateDChallan(updatedInvoice, updateType)
        .then((res: any) => {
          if (res.data.success) {
            toastNotify(id, res)
            redirect()
            
          } else {
            toastNotify(id, res)
          }
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          setIsLoading(false)
          toast.dismiss(id)
        })
    }, 1000)
  }
  const redirect = () => {
    navigate('/deliverychallan')
  }

  // const UpdateInvoiceDetails = () => {
  //   const id = toast.loading('Please wait...')
  //   setIsLoading(true)
  //   if (editField.challanNumber) {
  //     data.challanNumber = invoiceEdited.challanNumber
  //   }
  //   if (invoiceEdited.invoiceDate) {
  //     data.invoiceDate = invoiceEdited.invoiceDate
  //   }
  //   if (invoiceEdited.invoiceDueDate) {
  //     data.invoiceDueDate = invoiceEdited.invoiceDueDate
  //   }
  //   if (invoiceEdited.challanNumber) {
  //     data.challanNumber = invoiceEdited.challanNumber
  //   }
  //   if (invoiceEdited.discount) {
  //     data.discount = invoiceEdited.discount
  //   }
  //   if (invoiceEdited.subAmount) {
  //     data.subAmount = invoiceEdited.subAmount
  //   }
  //   if (invoiceEdited.taxAmount) {
  //     data.taxAmount = invoiceEdited.taxAmount
  //   }
  //   if (invoiceEdited.addTax) {
  //     data.addTax = invoiceEdited.addTax
  //   }
  //   if (totalAmount) {
  //     data.totalAmount = totalAmount
  //   }
  //   if (amtInWords) {
  //     data.amountInWords = amtInWords
  //   }
  //   if (invoiceId > 0) {
  //     data.invoiceId = invoiceId
  //   }

  //   const __data = {
  //     ...data,
  //     // add invoiceBillBy as object in data
  //     invoiceBillBy: {
  //       // billName: adressBillBy.companyName,
  //       supplierName: adressBillBy.supplierName,
  //       supplierAddress: adressBillBy.supplierAddress,
  //       addressLine2: adressBillBy.addressLine2,
  //       supplierCity: adressBillBy.supplierCity,

  //       postalCode: adressBillBy.postalCode,
  //       supplierGstin: adressBillBy.supplierGstin,
  //       mobile: adressBillBy.mobile,
  //       supplierEmail: adressBillBy.supplierEmail,
  //     },
  //     // add invoiceShipTo as object in data
  //     invoiceShipTo: {
  //       shipName: adressShipTo.billName,
  //       shipAddress: adressShipTo.billAddress,
  //       shipCity: adressShipTo.billCity,
  //       shipState: adressShipTo.billState,
  //       shipPostalCode: adressShipTo.billPostalCode,
  //       customerGstin: adressShipTo.gstin,
  //       customerEmail: adressShipTo.email,
  //       customerContactNo: adressShipTo.contactNo,
  //     },
  //   }

  //   setTimeout(() => {
  //     // __data.totalAmount = totalAmount;
  //     // __data.amountInWords = amtInWords;
  //     updateDChallan(__data, isItemsEdited ? 'items' : 'invoice')
  //       .then((res: any) => {
  //         if (res.data.success) {
  //           toastNotify(id, res)
  //           bindDetails(invoiceId)
  //         } else {
  //           toastNotify(id, res)
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err)
  //       })
  //       .finally(() => {
  //         setIsLoading(false)
  //       })
  //   }, 1000)
  // }
  const [mailDetails, setMailDetails] = useState<any>({
    to: '',
    cc: '',
    bcc: '',
    subject: '',
    message: '',
  })

  const toggleShowTemplate = (invoiceId: any) => {
    setShowTemplate(true)
  }

  const setShowInvoiceModal = () => {
    setShowTemplate(false)
  }

  const toggleShowShare = (invoiceId: any) => {
    setShowShare(true)
  }
  const toggleShowMail = (invoiceId: any) => {
    setInvoiceId(invoiceId)
    setShowMail(true)
  }

  const setShowShareModal = () => {
    setShowShare(false)
  }

  return (
    <>
      <>
        {' '}
        <PageTitle breadcrumbs={profileBreadCrumbs}>Delivery Challan Detail</PageTitle>
        <div className='card mb-5 mb-xl-10'>
          {stages === LOADINGSTAGES.LOADING && <Loader />}
          {stages === LOADINGSTAGES.NEGATIVE && <ServerMessage message='No data found' />}
          {stages === LOADINGSTAGES.ERROR && <ServerMessage message='Something went wrong' />}
          {stages === LOADINGSTAGES.POSITIVE && (
            <div className='card-body pt-9 pb-0'>
              <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center mb-2'>
                        <a
                          href='#'
                          className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'
                        >
                          DeliveryChallan#:{data.challanNumber}
                        </a>
                        {/* <a
                                                href='#'
                                                className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3'
                                                data-bs-toggle='modal'
                                                data-bs-target='#kt_modal_upgrade_plan'
                                            >
                                                active
                                            </a> */}
                      </div>
                    </div>
                    <div className='d-flex my-4'>
                      {
                        <a
                          href='#'
                          onClick={() => toggleShowTemplate(data?.id)}
                          className={`btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1`}
                          id='kt_charts_widget_2_year_btn'
                        >
                          <i className='fas fa-print'></i> Preview &amp; Print
                        </a>
                      }

                      <Button
                        onClick={() => UpdateInvoiceDetails()}
                        className='btn btn-sm btn-light me-2'
                        id='kt_user_follow_button'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr012.svg'
                          className='svg-icon-3 d-none'
                        />
                        Update
                      </Button>
                      <a
                        href='#'
                        onClick={() => toggleShowShare(data?.id)}
                        className={`btn btn-sm btn-color-muted btn-active btn-active-success active px-4 me-1`}
                        id='kt_charts_widget_2_year_btn'
                      >
                        <i className='fas fa-share'></i> Share
                      </a>
                      <a
                        href='#'
                        onClick={() => toggleShowMail(data?.id)}
                        className={`btn btn-sm btn-color-muted btn-active btn-active-info active px-4 me-1`}
                        id='kt_charts_widget_2_year_btn'
                      >
                        <i className='fas fa-envelope'></i> Mail
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-md-12'>
                  <div className='row mb-7'>
                    <div className='col-lg-4'>
                      <h6 className='fw-bold text-muted mb-1'>
                        Bill By
                        {isCancelled === false && (
                          <i
                            className=' text-info fas fa-edit px-15'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_upgrade_plan'
                            onClick={() =>
                              setAddressForm({
                                type: 'billBy',
                                status: true,
                              })
                            }
                          ></i>
                        )}
                        <i
                          className=' text-danger fas fa-details px-15'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_upgrade_plan'
                          onClick={() =>
                            setAddressForm({
                              type: 'companyAddresses',
                              status: true,
                            })
                          }
                        ></i>
                      </h6>
                      {/* // edit Icon  */}
                      <span className='fw-bolder fs-6 text-dark'></span>
                      <address>
                        <p className='mb-0'>
                          <>
                            {billBy?.billAddress}
                            {/* {billBy?.addressLine2} */}
                          </>
                        </p>
                        <p className='mb-1'>
                          <>
                            {billBy?.billCity} {billBy?.billPostalCode}
                          </>
                        </p>

                        <p className='mb-0'>
                          <>GSTIN: {billBy?.companyGstin}</>
                        </p>
                        <p className='mb-0'>
                          <>Mobile: {billBy?.billState}</>
                        </p>
                        <p className='mb-0'>
                          <>Email: {billBy?.billName}</>
                        </p>
                      </address>
                    </div>
                    <div className='col-lg-4'>
                      <h6 className=' fw-bold text-muted mb-1'>
                        Bill To
                        {isCancelled === false && (
                          <i
                            className=' text-info fas fa-edit px-15'
                            onClick={() =>
                              setAddressForm({
                                type: 'billTo',
                                status: true,
                              })
                            }
                          ></i>
                        )}
                      </h6>
                      <span className='fw-bolder fs-6 text-dark'>{shipTo?.shipName}</span>
                      <address>
                        <p className='mb-0'>
                          {shipTo?.shipAddress} {shipTo?.shipCity}{' '}
                        </p>
                        <p className='mb-1'>
                          {shipTo?.shipState} {shipTo?.shipPostalCode}
                        </p>

                        <p className='mb-0'>GSTIN:{shipTo?.customerGstin}</p>
                        <p className='mb-0'>Contact No: {shipTo?.customerContactNo}</p>
                        <p className='mb-0'>Email: {shipTo?.customerEmail}</p>
                      </address>
                    </div>
                    <div className='col-lg-4'>
                      <div>
                        <h6 className=' fw-bold text-muted mb-1'>Challan Date</h6>
                        {!editField.challanDate ? (
                          <>
                            <span className='fw-bolder fs-6 text-dark'>
                              {moment(data?.challanDate).format('DD-MM-YYYY')}
                            </span>
                            {isCancelled === false && (
                              <i
                                className=' text-info fas fa-edit px-15'
                                onClick={() =>
                                  setEditField({
                                    ...editField,
                                    challanDate: !editField.challanDate,
                                  })
                                }
                              ></i>
                            )}
                          </>
                        ) : (
                          <input
                            type='date'
                            className='form-control'
                            name = 'challanDate'
                            defaultValue={moment(data?.challanDate).format('YYYY-MM-DD')}
                            value={invoiceEdited.challanDate??""}
                            onChange={(e) =>
                              setInvoiceEdited({
                                ...invoiceEdited,
                                challanDate: e.target.value,
                              })
                            }
                          />
                        )}
                      </div>

                      <div>
                        <h6 className=' fw-bold text-muted mb-1' style={{marginTop: '1em'}}>
                          Challan Number
                        </h6>
                        {!editField.challanNumber ? (
                          <>
                            <span className='fw-bolder fs-6 text-dark'>{data?.challanNumber}</span>
                            {/* < i className=' text-info fas fa-edit px-15'
                                                        onClick={() => setEditField({
                                                            ...editField,
                                                            challanNumber: !editField.challanNumber
                                                        })}></i> */}
                            {isCancelled === false && (
                              <i
                                className=' text-info fas fa-edit px-15'
                                onClick={() =>
                                  setEditField({
                                    ...editField,
                                    challanNumber: !editField.challanNumber,
                                  })
                                }
                              ></i>
                            )}
                          </>
                        ) : (
                          <input
                            type='numeric'
                            className='form-control'
                            defaultValue={data?.challanNumber??""}
                            value={invoiceEdited.challanNumber??""}
                            onChange={(e) =>
                              setInvoiceEdited({
                                ...invoiceEdited,
                                challanNumber: e.target.value,
                              })
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <div className='col-lg-3'>
                      <h6 className=' fw-bold text-muted mb-1'>Amount</h6>
                      <span className='fw-bolder fs-6 text-dark'>{totalAmount}</span>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <div className='col-lg-9'>
                      <h6 className=' fw-bold text-muted mb-1'>Amount in Words</h6>
                      <span className='fw-bolder fs-6 text-dark text-capitalize'>{amtInWords}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mt-5'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                  <li className='nav-item'>
                    <a
                      onClick={() => setTab(1)}
                      className='nav-link active'
                      data-bs-toggle='tab'
                      href='#'
                    >
                      Items
                    </a>
                  </li>
                </ul>
                <div className='col-md-12 mt-5'>
                  {tab == 1 && data && dataItems?.length > 0 && (
                    <ListDeliveryChallan
                      invoiceId={id}
                      data={dataItems}
                      isCancelled={isCancelled}
                      setItemsData={updateItemsData}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        {itemIdForUpdate !== undefined && (
          <InvoiceCloneModal data={data} setItemIdForUpdate={setItemIdForUpdate} />
        )}
        <AddressForm
          initialData={saveInitialData}
          setBillBy={setBillBy}
          setBillTo={setShipTo}
          type={addressForm.type}
          billBy={billBy}
          billTo={shipTo}
          show={addressForm.status}
          handleClose={() => setAddressForm(false)}
        />
        {addressForm.type === 'companyAddresses' ? (
          <AddressList
            show={addressForm.status}
            companyId={data?.companyId}
            customerId={data?.customerId}
            handleClose={() => setAddressForm(false)}
          />
        ) : null}
        {showMail && (
          <InvoiceMail
            mailDetails={mailDetails}
            setShowEmail={setShowMail}
            handleClose={() => setShowMail(false)}
            data={data}
          />
        )}
        {showTemplate && (
          <InvoiceTemplateSelect
            setShowInvoiceModal={setShowInvoiceModal}
            invoiceId={invoiceId}
            companyCode={companyCode}
          />
        )}
        {showShare && (
          <InvoiceShare
            setShowShareModal={setShowShareModal}
            invoiceId={invoiceId}
            companyCode={companyCode}
            template={'classic'}
          />
        )}
      </>
    </>
  )
}
