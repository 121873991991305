/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByToken, login } from '../core/_requests'
import { useAuth } from '../core/Auth'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { toast } from 'react-toastify'


const loginSchema = Yup.object().shape({
  username: Yup.string()
    //.email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Username is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  username: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      const toastId = toast.loading("Please wait...");
      const start = performance.now();

      try {
        await login(values.username, values.password)
          .then(async (res: any) => {
            toast.update(toastId, {
              render: res.data.message,
              type: res.data.success ? "success" : "info",
              isLoading: false,
              autoClose: 3000,
            });
            if (res.data.success) {
              saveAuth(res.data.data);
              if (res.data.data.isProfileComplete) {
                setTimeout(() => {
                  window.location.reload();
                }, 1000)
                setTimeout(async () => {
                  const user = await getUserByToken(res.data.data.token);
                  setCurrentUser(user)
                }, 2000);
              }
              else {
               
                // navigate(`/onboarding-steps/${res.data.data.userKey}`);

              }
            }
            else {
              if (res.data.data === "NotVerified") {
                navigate(`/email-verification`);
              }
              else {
                if (res.data.data !== null) {
                  if(res.data.data.status==="pending"){
                    navigate(`/onboarding-steps/${res.data.data.data}`);
                  }
                }
              }
            }
            setLoading(false);
          })
          .catch((err: any) => {
            toast.update(toastId, {
              render: "Error. Please try again.",
              type: "error",
              isLoading: false,
              autoClose: 3000,
            });
            console.error(err);
            setLoading(false);
          });;

        // if (auth.success) {
        //   saveAuth(auth.data)
        //   const user = await getUserByToken(auth.data.token);
        //   setCurrentUser(user)
        // } else {
        //   saveAuth(undefined)
        //   setStatus('The login detail is incorrect')
        //   setSubmitting(false)
        //   setLoading(false)
        // }

      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login detail is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <Fragment>

      <form
        className='form w-100 '
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
        style={{ filter: "drop-shadow(0px 0px 40px rgba(68, 81, 96, 0.08))" }}
      >
        {/* begin::Heading */}
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Sign In</h1>
          <div className='text-gray-400 fs-5'>
            New Here?{' '}
            <Link to='/auth/registration' className='link-primary'>
              Create an Account
            </Link>
          </div>
        </div>
        {/* begin::Heading */}

        {formik.status ? (
          <div className='mb-lg-15 alert alert-secondary text-gray-700'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        ) : (<></>
          // <div className='mb-10 bg-light-info p-8 rounded'>
          //   <div className='text-info'>
          //     Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
          //     continue.
          //   </div>
          // </div>
        )}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Username</label>
          <input
            placeholder='Username'
            {...formik.getFieldProps('username')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.username && formik.errors.username },
              {
                'is-valid': formik.touched.username && !formik.errors.username,
              }
            )}
            type='text'
            name='username'
            autoComplete='off'
          />
          {formik.touched.username && formik.errors.username && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.username}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2'>
              {/* begin::Label */}
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
              {/* end::Label */}
              {/* begin::Link */}
              <Link
                to='/auth/forgot-password'
                className='link-primary fs-6 fw-bolder'
                style={{ marginLeft: '5px' }}
              >
                Forgot Password ?
              </Link>
              {/* end::Link */}
            </div>
          </div>
          <input
            type='password'
            placeholder='Password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Action */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Continue</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>


        </div>
        {/* end::Action */}
      </form>
    </Fragment>
  )
}
