import React, { useEffect, useState, Fragment } from 'react';
import { Link } from "react-router-dom";
import { Table } from 'react-bootstrap';
import { searchCustomers } from "../../../services/CustomerService";
import { DisableSidebar, PageLink, PageTitle } from '../../../_metronic/layout/core';
import { KTSVG } from '../../../_metronic/helpers';
import Pagination from '../../../lib/Pagination/Pagination';
import { PAGE_SIZE } from "../../../_shared";
import { LoadData, NoDataFound, ServerMessage } from '../../../lib/NoData';
import { IConfirmModel } from '../../../_models/confirm-model';
import { ConfirmBox } from '../../../lib/ConfirmBox';
import { Counter, Loader } from '../components/components';
import { CounterBox } from '../../../lib/CounterBox';
import { getCustomerCounter } from '../../../services/CounterService';

const profileBreadCrumbs: Array<PageLink> = [
    {
        title: 'Customers',
        path: '/customers',
        isSeparator: false,
        isActive: false,
    },
    //    {
    //        title: '',
    //        path: '',
    //        isSeparator: true,
    //        isActive: false,
    //    },
]

interface BigObject<T> {
    [index: string]: T
}

export function ListCustomers() {
    const [toggle, setToggle] = useState("");
    const onClick = (name: any) => setToggle(toggle === name ? "" : name);
    const [counters, setCounters] = React.useState<any>({});
    const [data, setData] = React.useState([]);
    const [currentTableData, setCurrentTableData] = React.useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = React.useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [role, setRole] = React.useState<any>();
    const [lastLogin, setLastLogin] = React.useState<any>();
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [confirmModel, setConfirmModel] = useState<IConfirmModel>();
    const LOADINGSTAGES = {
        IDLE: 0,
        LOADING: 1,
        POSITIVE: 3,
        NEGATIVE: 4,
        ERROR: 5,
        EMPTY: 6
    }
    const [loadingStage, setLoadingStage] = useState<any>(LOADINGSTAGES.IDLE);
    const [status, setStatus] = React.useState<any>();
    const [keyword, setKeyword] = React.useState<any>();
    const [counterData, setCounterData] = React.useState([]);

    const initialValues = {
        pageNumber: 1,
        pageSize: 100000,
        customerId: 128,
        fromDate: "",
        toDate: "",
        filter: "",
        status: 0,
    }

    const bindCounters = () => {
        getCustomerCounter().then((res: any) => {
            const data = res.data.data;
           
            setCounters(data);

            const localArray: any = [];
            let bigObject: BigObject<object> = data;
            Object.keys(bigObject).forEach(key => {
                
                localArray.push({
                    text: key === "active" ? "Active" : "Inactive",
                    value: bigObject[key]
                })
            })
            setCounterData(localArray);
        }).catch((err: any) => {
            console.log(err);
        })
    }

    const paginate = (pageNumber: any) => {
        setCurrentPage(pageNumber);
        const firstPageIndex = (pageNumber - 1) * PAGE_SIZE;
        const lastPageIndex = firstPageIndex + PAGE_SIZE;
        let _currentTableData = data.slice(firstPageIndex, lastPageIndex);
        setCurrentTableData(_currentTableData);
    };

    const filterData = () => {
        const values = {
            ...initialValues,
            filter: keyword,
            status: parseInt(status),
        }
        bindGrid(values);
    }

    const resetData = () => {
        setKeyword("");
        setStatus(0);
        bindGrid(initialValues);
    }

    const bindGrid = (values: any) => {
        setLoadingStage(LOADINGSTAGES.LOADING);
        setIsLoading(true);
        setTimeout(() => {
            searchCustomers(values)
                .then((res) => {
                    if (res.data.success) {
                        let _data = res.data.data.data;
                        const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
                        const lastPageIndex = firstPageIndex + PAGE_SIZE;
                        let _currentTableData = _data.slice(firstPageIndex, lastPageIndex);
                        setData(_data);
                        setTotalRows(_data.length);
                        if (_data.length > 0) {
                            setLoadingStage(LOADINGSTAGES.POSITIVE);
                            setCurrentTableData(_currentTableData);
                        }
                        else {
                            setLoadingStage(LOADINGSTAGES.NEGATIVE);
                            setCurrentTableData([]);
                        }
                    }
                    else {
                        setLoadingStage(LOADINGSTAGES.NEGATIVE);
                        setCurrentTableData([]);
                    }
                })
                .catch(() => {
                    setLoadingStage(LOADINGSTAGES.ERROR);
                    setCurrentTableData([]);
                }).finally(() => {

                    setIsLoading(false);
                });
        }, 1000);
    };


    const openDeleteModal = (id: any) => {
        if (id > 0) {
            const confirmModel: IConfirmModel = {
                title: 'Delete Customer',
                btnText: 'Delete this customer',
                deleteUrl: `customers/${id}`,
                message: 'delete-customer',
            }

            setConfirmModel(confirmModel);
            setTimeout(() => {
                setShowConfirm(true)
            }, 500);
        }
    }

    const afterConfirm = (res: any) => {
        if (res) bindGrid(initialValues);

        setShowConfirm(false);
    }


    useEffect(() => {
        bindGrid(initialValues);
        bindCounters();
    }, []);

    return (<>
        <PageTitle breadcrumbs={profileBreadCrumbs}>Customers</PageTitle>
        <DisableSidebar>
            <div className='card mb-xl-5'>
                <div className='card-body pt-9 pb-0'>
                    <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                        <div className='flex-grow-1'>
                            <div className='d-flex justify-content-between flex-wrap mb-2'>
                                <div className='d-flex justify-items-center'>
                                    <CounterBox data={counterData} />
                                </div>
                                <div className='d-flex justify-items-center'>
                                    <div>
                                        {/* begin::Filter Button */}
                                        <button
                                            disabled={isLoading}
                                            type='button'
                                            className='btn btn-sm btn-secondary me-3'
                                            data-kt-menu-trigger='click'
                                            data-kt-menu-placement='bottom-end'
                                        >
                                            <KTSVG path='/media/icons/duotune/general/gen031.svg' />
                                            Filter
                                        </button>
                                        {/* end::Filter Button */}
                                        {/* begin::SubMenu */}
                                        <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
                                            {/* begin::Header */}
                                            <div className='px-7 py-5'>
                                                <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
                                            </div>
                                            {/* end::Header */}

                                            {/* begin::Separator */}
                                            <div className='separator border-gray-200'></div>
                                            {/* end::Separator */}

                                            {/* begin::Content */}
                                            <div className='px-7 py-5' data-kt-user-table-filter='form'>
                                                {/* begin::Input group */}
                                                <div className='mb-10'>
                                                    <label className='form-label fs-6 fw-bold'>Status:</label>
                                                    <select
                                                        className='form-select form-select-solid fw-bolder'
                                                        data-kt-select2='true'
                                                        data-placeholder='Select option'
                                                        data-allow-clear='true'
                                                        data-kt-user-table-filter='role'
                                                        data-hide-search='true'
                                                        onChange={(e) => setStatus(e.target.value)}
                                                        value={status}
                                                    >
                                                        <option value='0' selected>All</option>
                                                        <option value='1'>Active</option>
                                                        <option value='2'>Inactive</option>
                                                    </select>
                                                </div>
                                                {/* end::Input group */}

                                                {/* begin::Input group */}
                                                <div className='mb-10'>
                                                    <label className='form-label fs-6 fw-bold'>Keyword:</label>
                                                    <input
                                                        className='form-control fw-bolder'
                                                        placeholder='Keyword'
                                                        onChange={(e) => setKeyword(e.target.value)}
                                                        value={keyword}
                                                    />

                                                </div>
                                                {/* end::Input group */}

                                                {/* begin::Actions */}
                                                <div className='d-flex justify-content-end'>
                                                    <button
                                                        type='button'
                                                        disabled={isLoading}
                                                        onClick={resetData}
                                                        className='btn btn-sm btn-light btn-active-light-primary fw-bold me-2 px-6'
                                                        data-kt-menu-dismiss='true'
                                                        data-kt-user-table-filter='reset'
                                                    >
                                                        Reset
                                                    </button>
                                                    <button
                                                        disabled={isLoading}
                                                        type='button'
                                                        onClick={filterData}
                                                        className='btn btn-sm btn-primary fw-bold px-6'
                                                        data-kt-menu-dismiss='true'
                                                        data-kt-user-table-filter='filter'
                                                    >
                                                        Apply
                                                    </button>
                                                </div>
                                                {/* end::Actions */}
                                            </div>
                                            {/* end::Content */}
                                        </div>
                                        {/* end::SubMenu */}
                                        <Link
                                            to='/customer/add'
                                            className='btn btn-sm btn-primary
                                            
                                            me-3'
                                        >
                                            Add New
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loadingStage === LOADINGSTAGES.LOADING && <Loader />}
            {loadingStage === LOADINGSTAGES.NEGATIVE && <NoDataFound />}
            {loadingStage === LOADINGSTAGES.ERROR && <ServerMessage message="Oops! server issue occured" />}
            {loadingStage === LOADINGSTAGES.POSITIVE ?
                <div className='row'>
                    <div className="col-xs-12 col-md-12">
                        <div className="notice d-flex bg-light-primary rounded border-primary-light border p-6 mb-3">
                            {/* <i className="ki-duotone ki-information-5 fs-2tx text-primary me-4">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span></i> */}
                            <i className="bi bi-info-circle-fill fs-1 text-primary me-4"></i>
                            <div className="d-flex flex-stack flex-grow-1" title='Info'>
                                <div className="">
                                    <div className="fs-7 text-gray-700">Click on the Customer Name to update customer information.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <div className="display mb-4 dataTablesCard customer-list-table">
                                        <Table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                                            <thead>
                                                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                                    <th>Name</th>
                                                    <th>Contact Number</th>
                                                    <th>Address</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentTableData.map((item: any, index: any) =>
                                                    <tr key={index}>
                                                        <td className='text-gray-900 '>
                                                            <div className="d-flex align-items-center">
                                                                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                                    <a href="#">
                                                                        <div className={`symbol-label bg-light-${index % 2 === 0 ? 'primary' : 'info'} 
                                                                        text-${index % 2 === 0 ? 'primary' : 'info'} fs-5 `}>
                                                                            {item.fullName.charAt(0)}</div>
                                                                    </a>
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <Link to={`/customer/edit/${item.id}`} className="text-dark fw-bold text-hover-primary mb-1"
                                                                    > {item.fullName}
                                                                    </Link>
                                                                    <span className='fs-7'>{item.city}</span>
                                                                </div>
                                                            </div>

                                                        </td>
                                                        <td>{item.contactNo}</td>
                                                        <td>{item.address}</td>

                                                        <td>
                                                            {item.isActive ? <span className='badge badge-light-success'>Active</span> : <span className='badge badge-light-danger'>Inactive</span>}
                                                        </td>
                                                    </tr>)}
                                            </tbody>
                                        </Table>
                                        <Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage}
                                            totalCount={totalRows}
                                            pageSize={PAGE_SIZE}
                                            onPageChange={(page: any) => paginate(page)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null}
        </DisableSidebar>
        {showConfirm && <ConfirmBox confirmModel={confirmModel} afterConfirm={afterConfirm} />}
    </>)
}