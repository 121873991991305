import React, { useEffect, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { toastNotify } from '../../../services/NotifyService'
import { KTSVG } from '../../../_metronic/helpers'
import { getNoteById, saveNotes, updateNotes } from '../../../services/CommonService'
import { INote, noteInitValues } from '../../../_models/term-model'

const profileDetailsSchema = Yup.object().shape({
    noteText: Yup.string().required("Text is required"),
})

type Props = {
    id: number,
    afterFupModal: any,
}

const AddNote = (props: Props) => {
    const { id } = props;
    const intl = useIntl()
    const navigate = useNavigate()

    const [data, setData] = useState<INote>(noteInitValues);
    const [title, setTitle] = useState<any>(props.id < 1 ? 'Add' : 'Edit')

    const [loading, setLoading] = useState(false)
    const formik = useFormik<INote>({
        enableReinitialize: true,
        initialValues: data,
        validationSchema: profileDetailsSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                setLoading(true);
                const updatedData = { ...data, ...values };
                setData(updatedData);
                let res;
               
                if (id) {
                    values.id = id;
                    res = await updateNotes(id, values);
                } else {
                    res = await saveNotes(values);
                }
                if (res.data.success) {
                    toastNotify(id, res);
                    props.afterFupModal();
                    redirect();
                } else {
                    toastNotify(id, res);
                }
            } catch (err) {
                const res = { data: { message: 'Something went wrong', success: false } };
                toastNotify(id, res);
                console.log(err);
            } finally {
                setLoading(false);
                resetForm();
            }
        },

    });


    const redirect = () => {
        setTimeout(() => {
            navigate('/notes')
        }, 500)
    }

    const bindDetails = (id: any) => {
        setLoading(true)
        setTimeout(() => {
            getNoteById(id)
                .then((res: any) => {
                    let _data = res.data.data
                    toggleChange(res.data.data.isAdditional)
                    setData(_data)
                })
                .catch(() => { })
                .finally(() => {
                    setLoading(false)
                })
        }, 500)
    }

    const onCloseClick = () => {
        props.afterFupModal()
    }

    useEffect(() => {
        if (props.id > 0) bindDetails(props.id)
    }, [])
    const toggleChange = (value: boolean) => {
        formik.setFieldValue("isAdditional", value)
    }

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='modal-dialog'>
                        {/* 1 */}
                        <div className='modal-content'>
                            <div className='modal-header'>
                                {/* begin::Modal title */}
                                <h2 className='fw-bolder'>{title} Notes</h2>
                                {/* end::Modal title */}

                                {/* begin::Close */}
                                <div
                                    className='btn btn-icon btn-sm btn-active-icon-primary'
                                    data-kt-users-modal-action='close'
                                    onClick={() => onCloseClick()}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                </div>
                                {/* end::Close */}
                            </div>
                            <div className='modal-body'>
                                <div className='fv-row my-3'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Text</label>
                                    <textarea
                                        rows={5}
                                        className='form-control  mb-3 mb-lg-0'
                                        placeholder='Text for notes'
                                        {...formik.getFieldProps('noteText')}
                                    ></textarea>
                                    {formik.touched.noteText && formik.errors.noteText && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.noteText}</div>
                                        </div>
                                    )}
                                </div>

                                {/* Test Scores End */}
                                <div className='modal-footer mt-5'>
                                    <button type='submit' className='btn btn-primary' disabled={loading}>
                                        {!loading && 'Save Changes'}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className='modal-backdrop fade show'></div>
        </>
    )
}
export { AddNote }
