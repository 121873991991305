/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import { useLayout } from '../core'
import { ReportBug } from './ReportBug'

const Footer: FC = () => {
  const { classes } = useLayout();
  const [showReportBugModal, setShowReportBugModal] = useState<boolean>(false);

  return (<>
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      {/* begin::Container */}
      <div className={`${classes.footerContainer} d-flex flex-column flex-md-row flex-stack`}>
        {/* begin::Copyright */}
        <div className='text-dark order-2 order-md-1'>
          <span className='text-gray-400 me-1'>Created by</span>
          <a href='#' target='_blank' className='text-muted text-hover-primary me-2 fs-6'>
            Amber Infotech
          </a>
          &nbsp;
          <button className='btn btn-xs btn-secondary py-2' onClick={() => setShowReportBugModal(true)}>
            Report a bug
          </button>
        </div>
        {/* end::Copyright */}

        {/* begin::Menu */}
        <ul className='menu menu-gray-600 menu-hover-primary order-1'>
          <li className='menu-item'>
            <a href='https://www.amberinfotech.com/about-us' target="_blank" className='menu-link px-2'>
              About
            </a>
          </li>
          <li className='menu-item'>
            <a href='https://www.amberinfotech.com/contact-us' target="_blank" className='menu-link px-2'>
              Contact
            </a>
          </li>
          <li className='menu-item'>
            <a href='https://www.amberinfotech.com/page-terms' target="_blank" className='menu-link px-2'>
              Privacy Policy
            </a>
          </li>
        </ul>
        {/* end::Menu */}
      </div>
      {/* end::Container */}

    </div>
    {showReportBugModal && <ReportBug setShowReportBugModal={setShowReportBugModal} />}
  </>


  )
}

export { Footer }
