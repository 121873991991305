import { Link } from "react-router-dom";

export function Trial() {
    return (<>

        <section className="bg-gray-50 dark:bg-gray-800 my-lg-20 py-lg-20">
            <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-16 lg:px-6">
                <div className="max-w-screen-sm mx-auto text-center">
                    <h2 className="mb-4 text-3xl font-extrabold leading-tight tracking-tight text-gray-900 dark:text-white">Start your free trial today</h2>
                    <p className="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg">Try Amber Bills Platform for 14 days. No credit card required.</p>
                    <Link to={'/auth/registration'} className="btn btn-primary">Free trial for 14 days</Link>
                </div>
            </div>
        </section>

    </>)
}