import React, {useEffect, useState, Fragment} from 'react'
import {Link} from 'react-router-dom'
import {Button, OverlayTrigger, Table, ToggleButton, Tooltip} from 'react-bootstrap'

import {
  getQuotations,
  searchQuotation,
  updateQuotationStatus,
} from '../../../services/QuotationService'

import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import moment from 'moment'
import Pagination from '../../../lib/Pagination/Pagination'
import {PAGE_SIZE} from '../../../_shared'
import {ConditionalWrapper, NoDataFound, ServerMessage} from '../../../lib/NoData'
import {IConfirmModel} from '../../../_models/confirm-model'
import {getQuotationCounts} from '../../../services/CounterService'

import '../css/style.css'
import {url} from 'inspector'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {LoadData} from '../../../lib/NoData'
import {CounterBox} from '../../../lib/CounterBox'
import {Loader} from '../components/components'

interface BigObject<T> {
  [index: string]: T
}

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Quotations',
    path: '/quotations',
    isSeparator: false,
    isActive: false,
  },
]

export function ListQuotation() {
  const [toggle, setToggle] = useState('')
  const onClick = (name: any) => setToggle(toggle === name ? '' : name)
  const [counters, setCounters] = React.useState<any>({})
  const [data, setData] = React.useState([])
  const [currentTableData, setCurrentTableData] = React.useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRows, setTotalRows] = React.useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = React.useState<any>()
  const [keyword, setKeyword] = React.useState<any>('')
  const [itemIdForUpdate, setItemIdForUpdate] = useState<any>()
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showInvoiceModal, setShowInvoiceModal] = useState<boolean>(false)
  const [confirmModel, setConfirmModel] = useState<IConfirmModel>()
  const [fieldValue, setFieldValue] = useState<any>({leadStatusId: 0})
  const [leadStatus, setLeadStatus] = useState<any>([])
  const [apiStatus, setAPIStatus] = useState(false)

  const leadStatusEventHandler = (data: any, fieldName: any) => {
    setFieldValue({leadStatusId: data.leadStatusId})
  }
  const updateLeadStatus = (leadId: any, statusId: any) => {
    setAPIStatus(true)
    setIsLoading(true)
    updateQuotationStatus({
      quoteId: leadId,
      statusId: statusId,
    })
      .then((res: any) => {
        if (res.data.success) {
          bindGrid(initialValues)
        }
      })
      .catch((err: any) => {
        setAPIStatus(false)
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  const [leadStatusIds, setLeadStatusIds] = useState<any>({
    leadStatusId: 0,
    indexId: 0,
  })
  const [leadId, setLeadId] = useState<any>(0)
  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>, leadId: any, indexId: any) => {
    setLeadId(leadId)
    setLeadStatusIds({
      leadStatusId: event.target.value,
      indexId: indexId,
    })
    // updateLeadStatus(leadId, parseInt(event.target.value));
  }
  const handleStatusChange = () => {
    setIsLoading(true)
    updateLeadStatus(leadId, parseInt(leadStatusIds.leadStatusId))
  }

  const initialValues = {
    branchId: 0,
    status: 0,
    pageNumber: 1,
    pageSize: 100000,
    fromDate: '',
    toDate: '',
    customerId: 0,
    createdBy: 0,
    assignedTo: 0,
    filter: '',
  }

  const [counterData, setCounterData] = React.useState([])
  const bindCounters = () => {
    getQuotationCounts()
      .then((res: any) => {
        const data = res.data.data
        setCounters(data)

        const localArray: any = []
        let bigObject: BigObject<object> = data
        Object.keys(bigObject).forEach((key) => {
          
          localArray.push({
            text:
              key === 'totalQuotation'
                ? 'Total'
                : key === 'convertedToSale'
                ? 'Converted to sale'
                : '',
            value: bigObject[key],
          })
        })
        setCounterData(localArray)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  const paginate = (pageNumber: any) => {
    setCurrentPage(pageNumber)
    const firstPageIndex = (pageNumber - 1) * PAGE_SIZE
    const lastPageIndex = firstPageIndex + PAGE_SIZE
    let _currentTableData = data.slice(firstPageIndex, lastPageIndex)
    setCurrentTableData(_currentTableData)
  }
  type ILoadingStage = 'idle' | 'loading' | 'success' | 'empty' | 'error'
  const [stage, setStage] = useState<ILoadingStage>('idle')

  const bindGrid = (values: any) => {
    setStage('loading')
    getQuotations(values)
      .then((res: any) => {

        let _data = res.data.data.data

        if (res.data.data.page.totalElements > 0) {
          const firstPageIndex = (currentPage - 1) * PAGE_SIZE
          const lastPageIndex = firstPageIndex + PAGE_SIZE
          let _currentTableData = _data.slice(firstPageIndex, lastPageIndex)
          setData(_data)
          setTotalRows(_data.length)

          setStage('success')
          setCurrentTableData(_currentTableData)
        } else {
          setStage('empty')
        }
      })
      .catch(() => {
        setCurrentTableData([])
        setStage('error')
      })
  }

  const resetData = () => {
    setStatus(0)
    setKeyword('')
    bindGrid(initialValues)
  }
  const filterData = () => {
    setCurrentTableData([])
    const filterValues = {
      ...initialValues,
      filter: keyword,
      status: parseInt(status || 0),
    }
    bindGrid(filterValues)
  }
  const openDeleteModal = (id: any) => {
    if (id > 0) {
      const confirmModel: IConfirmModel = {
        title: 'Cancel Invoice',
        btnText: 'Cancel this invoice',
        deleteUrl: `quotations/cancel/${id}`,
        message: 'cancel-invoice',
      }

      setConfirmModel(confirmModel)
      setTimeout(() => {
        setShowConfirm(true)
      }, 500)
    }
  }

  const afterConfirm = (res: any) => {
    if (res) bindGrid(initialValues)

    setShowConfirm(false)
  }

  const afterInvoiceUpdate = (res: any) => {
    if (res) bindGrid(initialValues)

    setShowInvoiceModal(false)
  }

  useEffect(() => {
    bindGrid(initialValues)
    bindCounters()
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>Quotations</PageTitle>
      <Fragment>
        <div className='card mb-xl-5'>
          <div className='card-body pt-9 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
              <div className='flex-grow-1'>
                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                  <div className='d-flex justify-items-center flex-grow-1'>
                    <CounterBox data={counterData} />
                  </div>

                  <div className='d-flex justify-items-center '>
                    {/* begin::Filter Button */}
                    <button
                      // disabled={isLoading}
                      type='button'
                      className='btn btn-sm btn-secondary me-3'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen031.svg'
                        className='svg-icon-2'
                      />
                      Filter
                    </button>
                    {/* end::Filter Button */}
                    {/* begin::SubMenu */}
                    <div
                      className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'
                      data-kt-menu='true'
                    >
                      {/* begin::Header */}
                      <div className='px-7 py-5'>
                        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
                      </div>
                      {/* end::Header */}

                      {/* begin::Separator */}
                      <div className='separator border-gray-200'></div>
                      {/* end::Separator */}

                      {/* begin::Content */}
                      <div className='px-7 py-5' data-kt-user-table-filter='form'>
                        {/* begin::Input group */}
                        <div className='mb-10'>
                          <label className='form-label fs-6 fw-bold'>Status:</label>
                          <select
                            className='form-select form-select-solid fw-bolder'
                            data-kt-select2='true'
                            data-placeholder='Select option'
                            data-allow-clear='true'
                            data-kt-user-table-filter='role'
                            data-hide-search='true'
                            onChange={(e) => setStatus(e.target.value)}
                            value={status}
                          >
                            <option value=''>All</option>
                            <option value='1'>Pending</option>
                            <option value='2'>Converted To Sale</option>
                          </select>
                        </div>
                        {/* end::Input group */}

                        {/* begin::Input group */}
                        <div className='mb-10'>
                          <label className='form-label fs-6 fw-bold'>Keyword:</label>
                          <input
                            className='form-control fw-bolder'
                            placeholder='Keyword'
                            onChange={(e) => setKeyword(e.target.value)}
                            value={keyword}
                          />
                        </div>
                        {/* end::Input group */}

                        {/* begin::Actions */}
                        <div className='d-flex justify-content-end'>
                          <button
                            type='button'
                            disabled={isLoading}
                            onClick={resetData}
                            className='btn btn-sm btn-light btn-active-light-primary fw-bold me-2 px-6'
                            data-kt-menu-dismiss='true'
                            data-kt-user-table-filter='reset'
                          >
                            Reset
                          </button>
                          <button
                            disabled={isLoading}
                            type='button'
                            onClick={filterData}
                            className='btn btn-sm btn-primary fw-bold px-6'
                            data-kt-menu-dismiss='true'
                            data-kt-user-table-filter='filter'
                          >
                            Apply
                          </button>
                        </div>
                        {/* end::Actions */}
                      </div>
                      {/* end::Content */}
                    </div>
                    {/* end::SubMenu */}
                    <Link to='/quotation/add'>
                      <button
                        className='btn btn-sm btn-primary me-3'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_offer_a_deal'
                      >
                        Add New
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConditionalWrapper stage={stage} errorMessage='Something went wrong'>
          <div className='row'>
            <div className="col-xs-12 col-md-12">
              <div className="notice d-flex bg-light-primary rounded border-primary-light border p-6 mb-3">
                <i className="bi bi-info-circle-fill fs-1 text-primary me-4"></i>
                <div className="d-flex flex-stack flex-grow-1" title='Info'>
                  <div className="">
                    <div className="fs-7 text-gray-700">Click on the Quotation Number to view quotation details, edit quotation, view pdf and send email.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-12 col-md-12'>
              <div className='card'>
                <div className='card-body'>
                  <div className='table-responsive'>
                    <div className='display mb-4 dataTablesCard customer-list-table'>
                      <Table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                        <thead>
                          <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                            <th>Quotation Number</th>
                            <th>Date</th>
                            <th>Name</th>
                            <th>GSTIN </th>
                            <th>Status</th>
                            <th className='text-end'>total Amount</th>
                          </tr>
                        </thead>
                        <ConditionalWrapper stage={stage} errorMessage='Something went wrong'>
                          <tbody>
                            {currentTableData && currentTableData.length > 0 ? (
                              currentTableData.map((item: any, index: any) => (
                                <tr key={index}>
                                  <td>
                                    <div className='d-flex align-items-center'>
                                      <div className='d-flex flex-column'>
                                        <Link
                                          to={`/quotation/details/${item.id}`}
                                          className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                                        >
                                          {item.quotationNumber}
                                        </Link>
                                      </div>
                                    </div>
                                  </td>
                                  <td>{moment(item.quotationDate).format('DD-MM-yyyy')}</td>
                                  <td>{item.billName}</td>

                                  {/* <td>{item.billAddress}</td> */}
                                  <td>{item.gstin}</td>
                                  {/* <td>{item.taxAmount}</td> */}

                                  <td>
                                    <div className='d-flex align-items-center'>
                                      <div className='d-flex flex-column'>
                                        <span className='badge badge-secondary fs-8'>
                                          {item.statusText}
                                        </span>

                                        {/* <div
                                          className={`text-${
                                            item.status === 1
                                              ? 'warning'
                                              : item.status === 2 || item.status === 2
                                              ? 'info'
                                              : item.status === 4
                                              ? 'success'
                                              : 'danger'
                                          }`}
                                        >
                                          {item.status === 1
                                            ? 'Open'
                                            : item.status === 2
                                            ? 'In Pipeline'
                                            : item.status === 3
                                            ? 'Meeting Set'
                                            : item.status === 4
                                            ? 'Converted To Sale'
                                            : item.status === 5
                                            ? 'Abandoned'
                                            : null}
                                        </div> */}
                                        {/* <div className="d-flex flex-column">
                                                                        <select
                                                                            className='btn btn-light btn-active-light-primary'
                                                                            data-kt-menu-placement='bottom-end'
                                                                            defaultValue={item.status}
                                                                            onChange={(e) => handleSelect(e, item.id, index)}
                                                                        >
                                                                            {leadStatus.map((status: any, index: any) =>
                                                                                <option
                                                                                    key={index}
                                                                                    value={status.id}
                                                                                    disabled={status.id === item.status}
                                                                                >
                                                                                    {status.title}
                                                                                </option>
                                                                            )}
                                                                        </select>
                                                                        {leadStatusIds.indexId === index && leadStatusIds.leadStatusId
                                                                            !== 0 ? <button className="btn btn-sm btn-primary mt-2"
                                                                                onClick={() => handleStatusChange()}
                                                                            >
                                                                            Update
                                                                        </button> : null}
                                                                    </div> */}
                                      </div>
                                    </div>
                                  </td>
                                  <td className='text-dark fw-bold text-end'>
                                    <div>{item.totalAmount?.toFixed(2)}</div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <td colSpan={6}>
                                <NoDataFound isLoading={isLoading} />
                              </td>
                            )}
                          </tbody>
                        </ConditionalWrapper>
                      </Table>
                      <Pagination
                        className='pagination-bar'
                        currentPage={currentPage}
                        totalCount={totalRows}
                        pageSize={PAGE_SIZE}
                        onPageChange={(page: any) => paginate(page)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ConditionalWrapper>
      </Fragment>
    </>
  )
}
