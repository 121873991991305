import React, { useEffect, useState } from 'react'
import { getAllSubs, getCurrentPlan } from '../../../../services/account/BankService'
import { DisableSidebar, PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { LOADINGSTAGES, Loader } from '../../../pages/components/components'
import { PAGE_SIZE } from '../../../../_shared'
import { NoDataFound, ServerMessage } from '../../../../lib/NoData'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import moment from 'moment';
import Pagination from '../../../../lib/Pagination/Pagination'
import { Accordion, Card, Button } from 'react-bootstrap';
import { PlanDetail } from './PlanDetail'

export default function SubHistory() {
    const profileBreadCrumbs: Array<PageLink> = [
        {
            title: 'Subscription History',
            path: '/subscription-history',
            isSeparator: false,
            isActive: false,
        },
    ]

    useEffect(() => {
        getSubscriptions();
        getPlanDetail()
    }, [])

    function getSubscriptions() {
        setLoadingStage(LOADINGSTAGES.LOADING)
        getAllSubs().then((res: any) => {
            if (res.data.success) {
                let _data = res.data.data;
                const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
                const lastPageIndex = firstPageIndex + PAGE_SIZE;
                let _currentTableData = _data.slice(firstPageIndex, lastPageIndex);
                setData(_data);
                setTotalRows(_data.length);
                if (_data.length > 0) {
                    setLoadingStage(LOADINGSTAGES.POSITIVE)
                    setCurrentTableData(_currentTableData);
                }
                else {
                    setLoadingStage(LOADINGSTAGES.NEGATIVE)
                    setCurrentTableData([]);
                }
            }
            else {
                setLoadingStage(LOADINGSTAGES.NEGATIVE)
                setCurrentTableData([]);
            }
        }).catch((err) => {
            setLoadingStage(LOADINGSTAGES.ERROR)
            setCurrentTableData([]);
        })
    }
    function getPlanDetail() {
        getCurrentPlan().then((res: any) => {
            if (res.data.success) {
                setPlan(res.data.data)
            }
        }).catch((err) => {
            setLoadingStage(LOADINGSTAGES.ERROR)
            setCurrentTableData([]);
        })

    }



    const [loadingStage, setLoadingStage] = useState<any>(LOADINGSTAGES.IDLE);
    const [plan, setPlan] = useState<any>({});
    const [data, setData] = React.useState([]);
    const [currentTableData, setCurrentTableData] = React.useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = React.useState(0);
    const paginate = (pageNumber: any) => {
        setCurrentPage(pageNumber);
        const firstPageIndex = (pageNumber - 1) * PAGE_SIZE;
        const lastPageIndex = firstPageIndex + PAGE_SIZE;
        let _currentTableData = data.slice(firstPageIndex, lastPageIndex);
        setCurrentTableData(_currentTableData);
    };

    return (
        <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Subscription</PageTitle>
            <DisableSidebar>
                <div className='card mb-xl-5'>

                    <div className='row'>
                        <div className="col-xl-12 col-md-12">
                            <div className="card">
                                <PlanDetail plan={plan} />
                                {loadingStage === LOADINGSTAGES.LOADING && <Loader />}
                                {loadingStage === LOADINGSTAGES.NEGATIVE && <NoDataFound />}
                                {loadingStage === LOADINGSTAGES.ERROR && <ServerMessage message="Oops! server issue occured" />}
                                {loadingStage === LOADINGSTAGES.POSITIVE &&
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <div className="display mb-4 dataTablesCard customer-list-table">
                                                <Table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                                                    <thead>
                                                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                                            <th className='text-start'>Transaction Date</th>
                                                            <th className='text-start'>Transaction Id</th>
                                                            <th className='text-start'>Remarks</th>
                                                            <th className='text-start'>Amount</th>
                                                            <th className='text-start'>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {currentTableData.map((item: any) =>
                                                            <tr key={item.id}>
                                                                <td className='text-dark fw-bold text-start'>{moment(item.transactionDate).format("DD-MM-yyyy")}</td>
                                                                <td className='text-dark  text-start'>
                                                                    <div>
                                                                        {item.transactionId}
                                                                    </div>
                                                                </td>
                                                                <td className='text-dark  text-start'>
                                                                    <div>
                                                                        {item.remarks}
                                                                    </div>
                                                                </td>
                                                                <td className='text-dark  text-start'>
                                                                    <div>
                                                                        {item.amount?.toFixed(2)}
                                                                    </div>
                                                                </td>
                                                                <td className='text-dark fw-semibold text-start'>
                                                                    {item.status}
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                                <Pagination
                                                    className="pagination-bar"
                                                    currentPage={currentPage}
                                                    totalCount={totalRows}
                                                    pageSize={PAGE_SIZE}
                                                    onPageChange={(page: any) => paginate(page)}
                                                />
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </DisableSidebar>
        </>
    )
}

