import React, { useEffect } from 'react'
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';

export default function CustomerPaymentDetails(props: any) {
    const { invoiceData, paymentReceived } = props;
    // payment details table 
    const TabelColumns = [
        "Date",
        "Due Date",
        "Invoice No",
        "Invoice Amount(Total Due)",
        "Amount Paid",
        "Amount Due(Net Due)",
        "Adjust Amount",
    ]
    // const handleAdjustAmount = (index: any, event: any) => {
    //     const { name, value } = event.target;
    //     const rowsInput = [...invoiceData];
    //     rowsInput[index][name] = parseInt(value);
    //     // get id as invoiceId, paymentId as 0 and amountPaid as adjustAmount for each row
    //     const array: any = [];
    //     rowsInput.map((item: any) => {
    //         array.push({
    //             invoiceId: item.id,
    //             paymentId: item.paymentId,
    //             amountPaid: item.adjustAmount
    //         })
    //     })
    //     props.setIData(array);
    // }
    const handleAdjustAmount = (index: any, event: any) => {
        // create a new copy of the invoiceData state
        const newInvoiceData = [...invoiceData];
        // update the new copy of the invoiceData state
        const { name, value } = event.target;
        newInvoiceData[index][name] = parseInt(value);
        // get id as invoiceId, paymentId as 0 and amountPaid as adjustAmount for each row
        const array: any = [];
        newInvoiceData.map((item: any) => {
            let paidAmount = Number(item.totalAmount?.toFixed(2) - item.amountDue?.toFixed(2) || 0)
            let adjustAmount = item.adjustAmount + paidAmount;
            array.push({
                invoiceId: item.id,
                paymentId: item.paymentId,
                amountPaid: adjustAmount
            });
        });
        
        // set the new copy of the invoiceData state
        props.setIData(array);
    }
    

    return (
        <div className='row'>
            <div className="col-xl-12 col-md-12">
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
                            <div className="display mb-4 dataTablesCard customer-list-table">
                                <Table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                                    <thead>
                                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                            {TabelColumns.map((item, index) => {
                                                return (
                                                    <th key={index}>{item}</th>
                                                )
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoiceData.map((item: any, index: any) =>
                                            <tr key={index}>
                                                <td className='text-gray-600 fw-bold'>{moment(item.invoiceDate).format("DD-MM-yyyy")}</td>
                                                <td className='text-gray-600 fw-bold'>{moment(item.invoiceDueDate).format("DD-MM-yyyy")}</td>
                                                <td className='text-gray-600 fw-bold'>
                                                    <div className="d-flex align-items-center">
                                                        <div className="d-flex flex-column">
                                                            <Link to={`/invoice/details/${item.id}`}
                                                                className="text-primary text-hover-secondary mb-1">
                                                                {item.invoiceNumber}</Link>
                                                            {item.isCancel ?
                                                                <span className='badge badge-light-danger'>Cancelled</span> : ""}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='text-dark fw-bold '>
                                                    <div>
                                                        {item?.totalAmount?.toFixed(2)}
                                                    </div>
                                                </td>
                                                <td className='text-dark fw-bold '>
                                                    <div>
                                                        {item.totalAmount?.toFixed(2) - item.amountDue?.toFixed(2)}
                                                    </div>
                                                </td>
                                                < td className='text-dark fw-bold '>
                                                    <div>
                                                        {item.amountDue?.toFixed(2)}
                                                    </div>
                                                </td>
                                                <td className='text-dark fw-bold '>
                                                    <div>
                                                        <input type="number"
                                                            className="form-control"
                                                            id={`aA_${item.id}`}
                                                            minLength={1}
                                                            min={0}
                                                            name="adjustAmount"
                                                            value={Number.isNaN(item.adjustAmount) ? 0 : item.adjustAmount}
                                                            onChange={(e) => handleAdjustAmount(index, e)} />
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                            <td colSpan={2} className='text-end'>Total</td>
                                            <td className='text-dark fw-bold '>
                                                <div>
                                                    <span className='badge badge-light-success'>
                                                        Received :{paymentReceived}</span>
                                                </div>
                                            </td>
                                            <td className='text-dark fw-bold '>
                                                <div>
                                                    {invoiceData.reduce((a: any, b: any) => a +
                                                        (b.totalAmount || 0), 0).toFixed(2) -
                                                        invoiceData.reduce((a: any, b: any) =>
                                                            a + (b.amountDue || 0), 0).toFixed(2)}
                                                </div>
                                            </td>
                                            <td className='text-dark fw-bold '>
                                                <div>
                                                    {invoiceData.reduce((a: any, b: any) => a + (b.amountPaid || 0), 0).toFixed(2)}
                                                </div>
                                            </td>
                                            <td className='text-dark fw-bold '>
                                                <div>
                                                    {invoiceData.reduce((a: any, b: any) => a + (b.amountDue || 0), 0).toFixed(2)}
                                                </div>
                                            </td>
                                            <td className='text-dark fw-bold '>
                                                <div>
                                                    {invoiceData.reduce((a: any, b: any) =>
                                                        a + (b.adjustAmount || 0), 0).toFixed(2)}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                            <td colSpan={3} className='text-dark fw-bold bg-secondary rounded'>
                                                <div className='row justify-content-between'>
                                                    <div className='col-lg-4'>
                                                        <span className='text-muted fw-bolder fs-7'>
                                                            Received Amount :&#8377; {paymentReceived}
                                                        </span>
                                                    </div>
                                                    <div className='col-lg-4'>
                                                        <span className='text-muted fw-bolder fs-7'>
                                                            Amount Entered :{" "}
                                                            &#8377; {invoiceData.reduce((a: any, b: any) =>
                                                                a + (b.adjustAmount || 0), 0).toFixed(2)}
                                                        </span>
                                                    </div>
                                                    <div className='col-lg-4 text-danger'>
                                                        {invoiceData.reduce((a: any, b: any) =>
                                                            a + (b.adjustAmount || 0), 0).toFixed(2) > 0 ?
                                                            `Excess Amount : ${paymentReceived - invoiceData.reduce((a: any, b: any) =>
                                                             a + (b.adjustAmount || 0), 0).toFixed(2)}` : ""}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
