import moment from "moment";
import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { getCustomers } from "../../../../services/CustomerService";
import { getAllProducts } from "../../../../services/CatalogService";
import { cloneInvoice } from "../../../../services/InvoiceService";
import { useFormik } from 'formik';
import { toast } from "react-toastify";
import * as Yup from 'yup'
import clsx from "clsx";
import { DateSelect } from "../../../../lib/DateSelect";
import { IInvoiceClone } from "../../../../_models/invoice-model";

import Combobox from "react-widgets/Combobox";
import { useNavigate } from "react-router-dom";

export function InvoiceCloneModalForm(props: any) {
    const { setItemIdForUpdate, data } = props;
    const navigate = useNavigate();

    const [rowsData, setRowsData] = useState<any[]>([]);
    const [custArr, setCustArr] = useState<any[]>([])
    const [prodArr, setProdArr] = useState<any[]>([])
    const [loading, setLoading] = useState(false);

    const invoiceSchema = Yup.object().shape({
        // customerId: Yup.string()
        //     .min(3, 'Minimum 3 symbols')
        //     .max(50, 'Maximum 50 symbols')
        //     .required('Email is required'),
        // invoiceDate: Yup.string()
        //     .min(3, 'Minimum 3 symbols')
        //     .max(50, 'Maximum 50 symbols')
        //     .required('Password is required'),
        // invoiceDueDate: Yup.string()
        //     .min(3, 'Minimum 3 symbols')
        //     .max(50, 'Maximum 50 symbols')
        //     .required('Password is required'),
    })

    const initialValues = {
        // customerId: +data?.customerId,
        // invoiceDate: moment(data.invoiceDate).format("DD-MM-YYYY"),
        // invoiceDueDate: moment(data.invoiceDueDate).format("DD-MM-YYYY"),
        // terms: '',
        // notes: '',
        customerId: '',
        invoiceDate: '',
        invoiceDueDate: '',
        terms: '',
        notes: '',
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema: invoiceSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            
            const _invoice: IInvoiceClone = {
                invoiceDate: values.invoiceDate,
                invoiceDueDate: values.invoiceDueDate,
                customerId: +values.customerId,
                billBranchId: +values.customerId,
                shipBranchId: +values.customerId,
                branchId: 0,
                subAmount: 0,
                taxAmount: 0,
                discount: 0,
                totalAmount: 0,
                terms: values.terms,
                notes: values.notes,
                isProforma: false,
                addTax: 0,
                isDraft: false,
            }
            var postData = {
                invoice: _invoice,
                invoiceItems: rowsData
            };
            alert(JSON.stringify(postData, null, 2));
            setLoading(true);
            const toastId = toast.loading("Please wait...");

            try {
                await cloneInvoice(postData)
                    .then(async (res: any) => {
                        let _data = res.data.data;

                        toast.update(toastId, {
                            render: res.data.message,
                            type: res.data.success ? "success" : "error",
                            isLoading: false,
                            autoClose: 3000,
                        });

                        if (res.data.success > 0) {
                            // setItemIdForUpdate(null);
                            navigate("/invoices");
                        }
                    })
                    .catch((err: any) => {
                        toast.update(toastId, {
                            render: "Error. Please try again.",
                            type: "error",
                            isLoading: false,
                            autoClose: 3000,
                        });
                        console.error(err);
                    });;

            } catch (error) {
                console.error(error)
                setStatus('Something went wrong')
                setSubmitting(false)
            } finally {
                setLoading(false);
            }
        },
    })

    const handleChange = (index: any, evnt: any) => {
        const { name, value } = evnt.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;
        setRowsData(rowsInput);
    };

    const handleDateSelect = (args: any) => {
        
    }

    const deleteItem = (index: any) => {
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
    }

    const bindCustomers = () => {
        setTimeout(() => {
            getCustomers()
                .then((res: any) => {
                    let _data = res.data.data;
                    setCustArr(_data);
                })
                .catch(() => {
                });
        }, 1000);
    };

    const bindProducts = () => {
        setTimeout(() => {
            getAllProducts()
                .then((res: any) => {
                    let _data = res.data.data;
                    setProdArr(_data);
                })
                .catch(() => {
                });
        }, 1000);
    };

    const dateEventHandler = (data: any, fieldName: any, setFieldValue: any) => {
        setFieldValue(fieldName, new Date(data.year, data.month - 1, data.day));
    }

    const [value, setValue] = useState<any>(null);


    const productSelected = (val: any) => {
        setValue(val)
        if (val.id && val.id > 0) {
            const selectedProduct = prodArr.filter((product: any) => product.id === val.id)[0];

            const newRow: any = {
                itemId: selectedProduct.id,
                itemName: selectedProduct.productName,
                hsnSac: selectedProduct.hsn,
                quantity: 1,
                unit: selectedProduct.unitName,
                price: selectedProduct.defaultSP,
                taxPercent: selectedProduct.taxPercent,
            }
            const rowsInput = [...rowsData, newRow];
            setRowsData(rowsInput);
            setValue(null);
            setProdArr([]);

            setProdArr(prodArr.filter((product: any) => product.id !== selectedProduct.id));
        }
    }

    useEffect(() => {
        setRowsData(data.invoiceItems);
        bindCustomers();
        bindProducts();
    }, [])


    return (
        <>

            <div className="row">
                <div className="col-md-12">
                    <div>
                        Please select data points you want to replicate on the left. On the right, you can filter the fields by farm name, harvest year, and crops. Then please select the fields to create new records for (e.g. all fields with winter wheat crop in 2022).
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="table-responsive">
                        <div className="display mb-4 dataTablesCard customer-list-table">
                            <Table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                                <thead>
                                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                        <th>Description/HSN</th>
                                        <th>Quantity</th>
                                        <th>Unit</th>
                                        <th className='text-end'>Rate</th>
                                        <th className='text-end'>Taxable Amount</th>
                                        <th className='text-end'>Tax</th>
                                        <th className='text-end'>Total Amount</th>
                                        <th className='text-center'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <Combobox
                                                name="productSearchBox"
                                                autoFocus={true}
                                                autoSelectMatches={true}
                                                hideCaret
                                                hideEmptyPopup
                                                data={prodArr}
                                                dataKey='id'
                                                textField='productName'
                                                placeholder="Search for a product"
                                                value={value}
                                                onChange={(e: any) => productSelected(e)}
                                                filter='contains'
                                            //onSelect={(e) => productSelected(e)}
                                            />
                                        </td>
                                        <td colSpan={6}>

                                        </td>
                                    </tr>
                                    {rowsData &&
                                        rowsData.length > 0 ?
                                        rowsData.map((item: any, index: any) =>
                                            <tr key={index}>
                                                <td className='text-gray-600 fw-bold'>
                                                    <div className="d-flex align-items-center">
                                                        <div>
                                                            <p className="text-gray-600 text-hover-secondary mb-0"> {item.itemName}</p>
                                                            <span className="text-gray-500 fs-6">{item.hsnSac}</span>
                                                        </div>
                                                    </div>

                                                </td>
                                                <td className='text-gray-600 fw-bold w-100px'>
                                                    <input type="text"
                                                        name="quantity"
                                                        className="form-control form-control-solid"
                                                        id={`qty_${item.id}`}
                                                        value={item.quantity}
                                                        onChange={(evnt) => handleChange(index, evnt)} />
                                                </td>
                                                <td className='text-gray-600 fw-bold'>{item.unit}</td>
                                                <td className='text-gray-600 fw-bold text-end'> {item.price.toFixed(2)}</td>
                                                <td className='text-gray-600 fw-bold text-end'> {(item.price * item.quantity)?.toFixed(2)}</td>
                                                <td className='text-gray-600 fw-bold text-end'>
                                                    {((item.price * item.quantity) * item.taxPercent / 100).toFixed(2)} (<span className='text-gray-600 fs-7'>{item.taxPercent}%</span>)
                                                </td>
                                                <td className='text-dark fw-bold text-end'>
                                                    {((item.price * item.quantity) + ((item.price * item.quantity) * item.taxPercent / 100))?.toFixed(2)}<br></br>
                                                </td>
                                                <td className='text-center'>
                                                    <a href="#" onClick={() => deleteItem(index)}>
                                                        <i className="bi bi-trash text-danger fs-5"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        ) : (
                                            <tr>
                                                <td colSpan={8}>
                                                    <div className='text-gray-600 d-flex text-center w-100 align-content-center justify-content-center'>
                                                        No matching records found
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }


                                </tbody>
                            </Table>

                        </div>
                    </div>
                </div>
                <div className="col-md-4 offset-1 ">
                    <form
                        className='form w-100'
                        onSubmit={formik.handleSubmit}

                        noValidate
                        id='ab_invoice_clone_form'
                    >
                        <div className="mb-10">
                            {formik.status && (
                                <div className='mb-lg-15 alert alert-secondary text-gray-700'>
                                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                                </div>
                            )}
                            <div className="row">
                                <div className="col-md-10 mb-10">
                                    <div className="card card-custom card-border border position-relative">
                                        <div className="card-header ">
                                            <h3 className="card-title">Clone To</h3>
                                            <div className="card-toolbar">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <Table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                                                    <tbody>
                                                        <tr>
                                                            <td>Customer</td>
                                                            <td>
                                                                <select
                                                                    placeholder='Customer '
                                                                    {...formik.getFieldProps('customerId')}
                                                                    className={clsx(
                                                                        'form-control form-control-solid from-control-sm w-300px',
                                                                        { 'is-invalid': formik.touched.customerId && formik.errors.customerId },
                                                                        {
                                                                            'is-valid': formik.touched.customerId && !formik.errors.customerId,
                                                                        }
                                                                    )}
                                                                    name="customerId"
                                                                >
                                                                    <option value="">Select Customer</option>
                                                                    {custArr && custArr.map(((sta: any) =>
                                                                        <option key={sta.id} value={sta.id}>{sta.fullName}</option>
                                                                    ))}
                                                                </select>
                                                                {formik.touched.customerId && formik.errors.customerId && (
                                                                    <div className='fv-plugins-message-container'>
                                                                        <span role='alert'>{formik.errors.customerId}</span>
                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Invoice Date </td>
                                                            <td>
                                                                <DateSelect
                                                                    {...formik.getFieldProps('invoiceDate')}
                                                                    onChange={(data: any) => dateEventHandler(data, 'invoiceDate', formik.setFieldValue)}
                                                                    name="invoiceDate"
                                                                />
                                                                {/* <input
                                                                    type='text'
                                                                    autoComplete='off'
                                                                    {...formik.getFieldProps('invoiceDate')}
                                                                    className={clsx(
                                                                        'form-control form-control-lg form-control-solid',
                                                                        {
                                                                            'is-invalid': formik.touched.invoiceDate && formik.errors.invoiceDate,
                                                                        },
                                                                        {
                                                                            'is-valid': formik.touched.invoiceDate && !formik.errors.invoiceDate,
                                                                        }
                                                                    )}
                                                                /> */}

                                                                {/* {moment(data.invoiceDate).format('DD-MM-YYYY')} */}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Invoice Due Date</td>
                                                            <td>
                                                                <DateSelect
                                                                    {...formik.getFieldProps('invoiceDueDate')}
                                                                    onChange={(data: any) => dateEventHandler(data, 'invoiceDueDate', formik.setFieldValue)}
                                                                    name="invoiceDueDate"
                                                                />
                                                                {/* <input
                                                                    type='text'
                                                                    autoComplete='off'
                                                                    {...formik.getFieldProps('invoiceDueDate')}
                                                                    className={clsx(
                                                                        'form-control form-control-lg form-control-solid',
                                                                        {
                                                                            'is-invalid': formik.touched.invoiceDueDate && formik.errors.invoiceDueDate,
                                                                        },
                                                                        {
                                                                            'is-valid': formik.touched.invoiceDueDate && !formik.errors.invoiceDueDate,
                                                                        }
                                                                    )}
                                                                />
                                                                {formik.touched.invoiceDueDate && formik.errors.invoiceDueDate && (
                                                                    <div className='fv-plugins-message-container'>
                                                                        <div className='fv-help-block'>
                                                                            <span role='alert'>{formik.errors.invoiceDueDate}</span>
                                                                        </div>
                                                                    </div>
                                                                )} */}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Terms &amp; Conditions</td>
                                                            <td>
                                                                <div className='d-flex align-items-center mb-5'>
                                                                    <a
                                                                        href='#'
                                                                        className='btn  text-primary btn-light btn-color-muted btn-active-light-primary px-4 py-2 me-4 w-100px'
                                                                    >
                                                                        <span>View</span>
                                                                    </a>

                                                                    <a
                                                                        href='#'
                                                                        className='btn  text-danger btn-light btn-color-muted btn-active-light-danger px-4 py-2 w-100px'
                                                                    >
                                                                        <span>Remove</span>
                                                                    </a>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Notes</td>
                                                            <td>
                                                                <div className='d-flex align-items-center mb-5'>
                                                                    <a
                                                                        href='#'
                                                                        className='btn  text-primary btn-light btn-color-muted btn-active-light-primary px-4 py-2 me-4 w-100px'
                                                                    >
                                                                        <span>View</span>
                                                                    </a>

                                                                    <a
                                                                        href='#'
                                                                        className='btn  text-danger btn-light btn-color-muted btn-active-light-danger px-4 py-2 w-100px'
                                                                    >
                                                                        <span>Remove</span>
                                                                    </a>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            {/* begin::Action */}
                                            <div className='text-center'>
                                                <button
                                                    type='submit'
                                                    id='kt_sign_in_submit'
                                                    className='btn btn-lg btn-primary w-100 mb-5'
                                                    disabled={formik.isSubmitting || !formik.isValid}
                                                >
                                                    {!loading && <span className='indicator-label'>Clone</span>}
                                                    {loading && (
                                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                                            Please wait...
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                    )}
                                                </button>


                                            </div>
                                            {/* end::Action */}
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </form>

                </div>
            </div>

        </>
    )
}