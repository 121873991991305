import { getInvoiceTaxes, updateInvoiceTaxes } from "../../../services/QuotationService";
import { invoiceDetailsUpdate } from "../../../services/InvoiceService";
import { IInvoiceInputDto, IInvoiceAdditionalTax } from "../interface/IInvoiceTypes/IInvoice";
import { getCustomerBranches, getCustomers } from "../../../services/CustomerService";
import { getCompanyBranches } from "../../../services/CompanyService";
import { getAllProducts ,getAllProductCategories} from "../../../services/CatalogService";
import { getTaxes } from "../../../services/CommonService";
import { getDiscount } from "../../../services/DiscountService";
import * as Yup from 'yup'

// interfaces
//#region interfaces
export type IUpdateTypes = 'invoiceDate' | 'invoiceNumber' | 'billBy' | 'billTo' | 'invoiceDueDate';
export type IInputProps = {
  type: IUpdateTypes,
  dto: IInvoiceInputDto,
  onResponse: (res: any) => void
}
export type ITaxProps = {
  tax: IInvoiceDto,
  onResponse: (res: any) => void
}
export type IInvoiceDto = {
  id: number,
  subAmount: number,
  totalAmount: number,
  taxAmount: number,
  totalFreightCharges: number,
  addTax: number,
  invoiceAdditionalTaxes: IInvoiceAdditionalTax[]
  
}
const params = {
  status: 0,
  categoryId: 0,
  pageNumber: 1,
  pageSize: 1000,
  fromDate: "",
  toDate: "",
  companyId: 0,
  customerId: 0,
  createdBy: 0,
  assignedTo: 0,
  filter: ""
}
//#endregion

//#region update invoice
export const updateInvoiceInput = (props: IInputProps) => {
  const { type, dto } = props;
  switch (type) {
    case 'invoiceDate':
      dto.invoiceDate = dto.invoiceDate || new Date();
      break;
    case 'invoiceDueDate':
      dto.invoiceDueDate = dto.invoiceDueDate || new Date();
      break;
    case 'invoiceNumber':
      dto.invoiceNumber = dto.invoiceNumber || '';
      break;
    case 'billBy':
      dto.companyName = dto.companyName || '';
      dto.companyAddress = dto.companyAddress || '';
      dto.companyCity = dto.companyCity || '';
      dto.companyState = dto.companyState || '';
      dto.companyPostalCode = dto.companyPostalCode || '';
      dto.companyGstin = dto.companyGstin || '';
      break;
    case 'billTo':
      dto.shipName = dto.billName || '';
      dto.shipAddress = dto.billAddress || '';
      dto.shipCity = dto.billCity || '';
      dto.shipState = dto.billState || '';
      dto.shipPostalCode = dto.billPostalCode || '';
      dto.customerGstin = dto.customerGstin || '';
      break;
  }
  dto.id = dto.id || 0;
  // don't update if id is 0 and show error
  if (dto.id === 0) {
    const data = { success: false, message: 'Invoice not found' }
    props.onResponse(data);
    return;
  }
  invoiceDetailsUpdate(dto).then((res) => {
    if (res.data.success) {
      props.onResponse(res.data.data);
    }
  }).catch((err) => {
    const data = { success: false, message: err.message }
    props.onResponse(data);
  });
}
//#endregion

//#region update invoice taxes
export const updateInvTaxes = (props: ITaxProps) => {
  const { tax } = props;
  const dto: IInvoiceDto = {
    id: tax.id,
    subAmount: tax.subAmount,
    totalAmount: tax.totalAmount,
    taxAmount: tax.taxAmount,
    totalFreightCharges: tax.totalFreightCharges,
    addTax: tax.addTax,
    invoiceAdditionalTaxes: tax.invoiceAdditionalTaxes
  }
  updateInvoiceTaxes(dto).then((res) => {
    if (res.data.success) {
      props.onResponse(res.data.data);
    }
  }).catch((err) => {
    const data = { success: false, message: err.message }
    props.onResponse(data);
  });

}
//#endregion

const bindCustomers = (setCustArr: Function) => {
  setTimeout(() => {
    getCustomers()
      .then((res: any) => {
        let _data = res.data.data
        _data = _data.map((c: any) => {
          return { ...c, name: c.fullName, id: c.id }
        })
        _data.unshift({ id: 0, name: 'Select Customer' })
        setCustArr(_data)
      })
      .catch(() => { })
  }, 1000)
}

const bindCompanyBranches = (setBranchArr: Function) => {
  getCompanyBranches()
    .then((res: any) => {
      let _data = res.data.data
      const arr: any = []
      _data.forEach((element: any) => {
        arr.push({
          id: element.id,
          name: element.companyName,
        })
      })
      arr.unshift({ id: 0, name: 'Select' })
      setBranchArr(arr)
    })
    .catch(() => { })
}

const bindCustomerBranches = (custId: any, setCustBranchArr: Function) => {
  setTimeout(() => {
    getCustomerBranches(custId, 0)
      .then((res: any) => {
        let _data = res.data.data
        _data = _data.map((c: any) => {
          return { ...c, name: c.name, id: c.customerBarchId }
        })
        _data.unshift({ id: 0, name: 'Select' })
        setCustBranchArr(_data)
      })
      .catch(() => { })
  }, 1000)
}

const bindProducts = (setProdArr: Function) => {
  setTimeout(() => {
    getAllProducts()
      .then((res: any) => {
        let _data = res.data.data
        
        setProdArr(_data)
      })
      .catch(() => { })
  }, 1000)
}
const bindProductsCategories = (setProdCateArr: Function) => {
  
  setTimeout(() => {
    getAllProductCategories(params)
      .then((res: any) => {
        let _data = res.data.data
        
        setProdCateArr(_data)
      })
      .catch(() => { })
  }, 1000)
}

const bindTaxes = (setTaxArr: Function) => {
  setTimeout(() => {
    getTaxes(true)
      .then((res: any) => {
        let _data = res.data.data
        console.log("discount", _data)
        setTaxArr(_data)
      })
      .catch(() => { })
  }, 1000)
}

const bindDiscount = (setDisArr: Function) => {
  setTimeout(() => {
    getDiscount()
      .then((res: any) => {
        let _data = res.data.data
        console.log("discount", _data)
        setDisArr(_data)
      })
      .catch(() => { })
  }, 1000)
}

export const invoiceSchema = Yup.object().shape({
  branchId: Yup.string()
    .required('Please choose billing company/branch')
    .test('len', 'Please choose a billing company/branch', (val: any) => val !== '0'),
  customerId: Yup.string()
    .required('Please choose customer')
    .test('len', 'Please choose a customer', (val: any) => val !== '0'),
  billBranchId: Yup.string()
    .required('Please choose customer branch')
    .test('len', 'Please choose a customer branch', (val: any) => val !== '0'),
  invoiceDate: Yup.date().required('Please choose invoice date'),
  invoiceDueDate: Yup.date().test('is-greater-than', 'Due date must be greater than invoice date', function (value: any) {
    return new Date(this.parent.invoiceDate) < new Date(value)
  }),
})


export const billSchema = Yup.object().shape({
  // branchId: Yup.string()
  //   .required('Please choose billing company/branch')
  //   .test('len', 'Please choose a billing company/branch', (val: any) => val !== '0'),
  customerId: Yup.string()
    .required('Please choose customer')
    .test('len', 'Please choose a customer', (val: any) => val !== '0'),
  billBranchId: Yup.string()
    .required('Please choose customer branch')
    .test('len', 'Please choose a customer branch', (val: any) => val !== '0'),
  // invoiceDate: Yup.date().required('Please choose invoice date'),
  // invoiceDueDate: Yup.date().test('is-greater-than', 'Due date must be greater than invoice date', function (value: any) {
  //   return new Date(this.parent.invoiceDate) < new Date(value)
  // }),
})

export const initialValues = {
  customerId: '',
  branchId: '',
  billBranchId: '',
  shipBranchId: '',
  invoiceDate: new Date(),
  invoiceDueDate: '', //(today.setDate(today.getDate() + 14)).toLocaleDateString(),
  terms: '',
  notes: '',
  isProforma: false,
  isDraft: false,
  additionalTax: [],
  invoiceNumber: ''
}
export const billInitialValues = {
  customerId: '',
  branchId: '',
  billBranchId: '',
  shipBranchId: '',
  invoiceDate: new Date(),
  invoiceDueDate: new Date(), 
  terms: '',
  notes: '',
  isProforma: false,
  isDraft: false,
  additionalTax: [],
  invoiceNumber: ''
}

export { bindCustomers, bindCompanyBranches, bindCustomerBranches, bindProducts,bindProductsCategories, bindTaxes, bindDiscount };
