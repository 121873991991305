import { FC, useState, useEffect } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { toast } from 'react-toastify';
import clsx from 'clsx'
import { UsersListLoading } from '../../modules/apps/user-management/users-list/components/loading/UsersListLoading'
import { IProductModel, initialUserValues as initValues } from '../../../_models/product-model'
import { getTaxes, getUnits } from "../../../services/CommonService"
import { getProduct, saveProduct, updateProduct } from '../../../services/CatalogService'
import { toastNotify } from '../../../services/NotifyService';
import { DatePicker } from 'react-widgets/cjs';
import { Link, useParams } from 'react-router-dom';
import { getCategories } from '../../../services/CategoryService';
import { DisableSidebar, PageLink, PageTitle } from '../../../_metronic/layout/core';
import { ValidationField, ValidationSelect, ValidationTextArea } from '../components/components';
import { AddCategoryModel } from './AddCategoryModel';

type Props = {
    isUserLoading: boolean
    product: IProductModel,
    setItemIdForUpdate: any,
    setShowProductForm: any,
    afterUpdateProduct: any,
    isService: any
}

const editUserSchema = Yup.object().shape({
    hsn: Yup.string()
        .min(3, 'Minimum 3 characters')
        .max(50, 'Maximum 50 characters')
        .required('HSN/SAC is required'),
    name: Yup.string()
        .min(3, 'Minimum 3 characters')
        .max(150, 'Maximum 150 characters')
        .required('Product name is required'),
    shortDescription: Yup.string()
        .nullable(true)
        //.min(10, 'Minimum 10 characters')
        .max(500, 'Maximum 500 characters'),
    price: Yup.number()
        .required('Price is required'),
    uom: Yup.string()
        .required('Please select unit'),
    taxPercent: Yup.string()
        .required('Please select tax'),
    categoryId: Yup.string()
        .required('Please select category').test('is-0', 'Please select valid category', (value) => {
            return value !== '0'
        }),
    barCode: Yup.string()
        .max(50, 'Maximum 50 characters')
})

const ProductEditModalForm: FC<Props> = ({ product, isUserLoading, setItemIdForUpdate,
    setShowProductForm, afterUpdateProduct, isService = false }) => {

    const { id } = useParams();

    const [initialValues] = useState<IProductModel>({
        ...product,
        id: product.productId || initValues.productId,
        name: product.name || initValues.name,
        hsn: product.hsn || initValues.hsn,
        shortDescription: product.shortDescription || initValues.shortDescription,
        price: product.price || initValues.price,
        taxPercent: product.taxPercent || initValues.taxPercent,
        productGroupId: product.productGroupId || (isService ? 6 : 3),
        categoryId: product.categoryId || initValues.categoryId,
        uom: product.uom || initValues.uom,
    });

    const [taxArr, setTaxArr] = useState<any>([]);
    const [unitArr, setUnitArr] = useState<any>([]);
    const [categories, setCategories] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<any>(id || 0);
    const [values, setValues] = useState<IProductModel>(initValues);

    const bindTaxes = () => {
        setTimeout(() => {
            getTaxes()
                .then((res: any) => {
                    let _data = res.data.data;
                    let _taxArr = _data.map((item: any) => {
                        return {
                            id: item.taxValue,
                            name: item.taxName
                        }
                    })
                    setTaxArr(_taxArr);
                })
                .catch(() => {
                    setTaxArr([]);
                });
        }, 1000);
    }

    const bindUnits = () => {
        setTimeout(() => {
            getUnits()
                .then((res: any) => {
                    let _data = res.data.data;
                    let _arr: any = [];
                    _data.forEach((item: any) => {
                        _arr.push({ id: item.id, name: item.unitName });
                    });
                    // default unit
                    _arr.unshift({ id: 0, name: 'Select unit' });
                    setUnitArr(_arr);
                })
                .catch(() => {
                    setTaxArr([]);
                });
        }, 1000);
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: values,
        validationSchema: editUserSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true)
            const toastId = toast.loading("Please wait...");
            try {
                values.productGroupId = isService ? 6 : 3;
                if (values.productId > 0) {
                    await editProduct(values, toastId);
                } else {
                    await addProduct(values, setItemIdForUpdate, toastId);
                }

            } catch (ex) {
                console.error(ex)
            } finally {
                setSubmitting(true)
            }
        },
    })

    async function addProduct(values: IProductModel, setItemIdForUpdate: any, toastId: any) {
        await saveProduct(values).then((res: any) => {
            toastNotify(toastId, res);
            if (setItemIdForUpdate) {
                setItemIdForUpdate(undefined);
                setTimeout(() => { window.location.reload(); }, 1000);
            }
            if (setShowProductForm) {
                setShowProductForm(false)
                afterUpdateProduct()
            }

        }).catch((err: any) => {
            const res = { data: { message: 'Something went wrong', success: false } };
            toastNotify(toastId, res);
            console.error(err);
        });
    }

    async function editProduct(values: IProductModel, toastId: any) {
        await updateProduct(values.productId, values).then((res: any) => {
            toastNotify(toastId, res);
            if (setItemIdForUpdate) {
                setItemIdForUpdate(undefined);
            }
            if (setShowProductForm) {
                setShowProductForm(false)
                afterUpdateProduct()
            }
            setTimeout(() => { window.location.reload(); }, 1000);
        }).catch((err: any) => {
            const res = { data: { message: 'Something went wrong', success: false } };
            toastNotify(toastId, res);
            console.error(err);
        });
    }

    const bindCategories = () => {
        getCategories().then((res: any) => {
            const data = res.data.data;
            // add default value
            data.unshift({ id: 0, name: "Select Category" });
            setCategories(data);
        }).catch((err: any) => {
            console.log(err);
        })
    }

    const bindDetails = (values: any) => {
        setIsLoading(true);
        setTimeout(() => {
            getProduct(values)
                .then((res: any) => {
                    let _data = res.data.data;
                    setValues(_data);
                    setIsLoading(false);
                })
                .catch(() => {
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }, 100);
    };

    useEffect(() => {
        if (id) {
            bindDetails(id);
        }
        bindCategories();
        bindTaxes();
        bindUnits();
    }, []);
    const profileBreadCrumbs: Array<PageLink> = [
        {
            title: isService ? 'Services' : 'Products',
            path: isService ? '/services' : '/products',
            isSeparator: false,
            isActive: false,
        }
    ]
    const [categoryOpen, setCategoryOpen] = useState(false);
    const handleAddCategory = () => {
        // recall categories
        bindCategories();
    };
    return (
        <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>
                {id ? isService ? 'Edit Service' : 'Edit Product' :
                    isService ? 'Add Service' : 'Add Product'}</PageTitle>
            <DisableSidebar>
                <div>
                    <form id='product_form' onSubmit={formik.handleSubmit} noValidate className='form'>
                        <div className='card mb-5 mb-xl-10'>
                            <div className='card-body p-9'>
                                <div className='row mb-7'>
                                    <ValidationField
                                        className='col-md-6'
                                        label={isService ? 'Service' : 'Product'}
                                        isRequired
                                        name='name'
                                        type='text'
                                        placeholder={isService ? 'Service Name' : 'Product Name'}
                                        formik={formik}
                                    />
                                    <ValidationSelect
                                        className='col-md-4'
                                        label='Category'
                                        isRequired
                                        name='categoryId'
                                        formik={formik}
                                        options={categories}
                                    />
                                    <div className={`col-md-2 my-9  ${formik.values.categoryId == 0 ?
                                        'align-items-center' : 'align-items-start'}`}>
                                        <button type='button' className='btn btn-secondary btn-block btn-sm'
                                            onClick={() =>
                                                setCategoryOpen(true)
                                            }
                                        >+ Add new category</button>
                                    </div>
                                </div>
                                <div className='row mb-7'>
                                    <ValidationTextArea
                                        className='col-md-12'
                                        label='Short Description'
                                        name='shortDescription'
                                        type='text'
                                        placeholder='Short Description'
                                        formik={formik}
                                    />
                                    <div className='text-gray-600 mt-1 d-flex justify-content-end'>
                                        Max 500 characters
                                    </div>
                                </div>
                                <div className='row mb-7'>
                                    <ValidationField
                                        isRequired
                                        className='col-md-3'
                                        label={isService ? 'SAC (Service Access Code)' : 'HSN'}
                                        name='hsn'
                                        type='text'
                                        placeholder={isService ? 'SAC (Service Access Code)' : 'HSN'}
                                        formik={formik}
                                    />
                                    <ValidationField
                                        className='col-md-3'
                                        label='Selling Price'
                                        isRequired
                                        name='price'
                                        min='0'
                                        type='number'
                                        placeholder='Selling Price'
                                        formik={formik}
                                    />
                                    <ValidationSelect
                                        className='col-md-3'
                                        label="Unit"
                                        isRequired
                                        name='uom'
                                        formik={formik}
                                        options={unitArr}
                                    />
                                    <ValidationSelect className='col-md-3 '
                                        label="Tax"
                                        isRequired
                                        name='taxPercent'
                                        formik={formik}
                                        options={taxArr}
                                    />
                                </div>
                                {
                                    !isService && (
                                        <>
                                            <div className='row mb-7'>
                                                <div className='col-md-12'>
                                                    <h4 className='text-gray-600 mt-7 mb-5 pb-3 border-bottom'>Purchase information :</h4>
                                                </div>
                                                <ValidationField className='col-md-3'

                                                    label='Purchase price'
                                                    name='purchasePrice'
                                                    type='number'
                                                    min='0'
                                                    placeholder='Purchase price'
                                                    formik={formik}
                                                />
                                            </div>
                                            <div className='row mb-7'>
                                                <div className='col-md-12'>
                                                    <h4 className='text-gray-600 mt-7 mb-5 pb-3 border-bottom'>Stock information</h4>
                                                </div>
                                                <ValidationField className='col-md-3'
                                                    isRequired
                                                    label='Opening Stock'
                                                    name='stockQuantity'
                                                    type='number'
                                                    min='0'
                                                    placeholder='Stock Quantity'
                                                    formik={formik}
                                                />
                                                <ValidationField
                                                    className='col-md-3'
                                                    label='SKU (Stock keeping unit)'
                                                    name='sku'
                                                    type='text'
                                                    placeholder='Stock keeping unit'
                                                    formik={formik}
                                                />
                                                <ValidationField
                                                    className='col-md-3'
                                                    label='Bar code'
                                                    name='barCode'
                                                    type='text'
                                                    placeholder='Bar code'
                                                    formik={formik}
                                                />
                                            </div>
                                        </>
                                    )
                                }

                                {/* begin::Actions */}
                                <div className='text-center pt-15'>


                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-users-modal-action='submit'
                                        disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
                                    >
                                        <span className='indicator-label'>Submit</span>
                                        {(formik.isSubmitting || isUserLoading) && (
                                            <span className='indicator-progress'>
                                                Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                    <Link to={'/products'} className='btn btn-light mx-3'>
                                        Cancel
                                    </Link>
                                </div>
                                {/* end::Actions */}
                            </div>
                        </div>
                    </form>
                    {categoryOpen &&
                        <AddCategoryModel open={categoryOpen} onClose={() => setCategoryOpen(false)}
                            handleAddCategory={handleAddCategory}
                        />
                    }
                </div>
            </DisableSidebar>
            {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
        </>

    )
}

export { ProductEditModalForm }

