import React from 'react';
import styled from 'styled-components';
import Burger from './Burger';

const Nav = styled.nav`
  height: 55px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  margin: auto 14%;

  .logo {
    padding: 5px 0;
  }
`

const Navbar = () => {
  return (
    <div className="container-fluid">
      <Nav>
        <div className="logo0">
          <a href="/home">
            {/* <img alt="Logo" src="/media/logos/logo11.png" className="logo-default h-25px h-lg-30px" /> */}
            <img alt="Logo" src="/media/logos/logo11.png" className="logo-sticky h-40px h-lg-60px" />
          </a>
        </div>
        <Burger />
      </Nav>
    </div>
  )
}

export default Navbar