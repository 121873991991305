import { useState, useEffect } from 'react'
import moment from 'moment'
import { ServerMessage } from '../../../lib/NoData'
import {
  getPoTaxes,
  getPurchaseOrder,
  updatePurchaseOrder,
} from '../../../services/BackendService/PurchaseOrdersService'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { Button, Table } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { ListPurchaseOrderItems } from './partials/PurchaseOrderItems'
import { Loader, LOADINGSTAGES, N2WIndian } from '../components/components'
import { toastNotify } from '../../../services/NotifyService'
import PoAddressForm from './partials/poAddressForm'
import {
  billByInitialValues,
  billToInitialValues,
  IBillBy,
  IBillTo,
  IShipTo,
} from '../interface/IInvoiceTypes/IQuotation'
import {
  IPOAdditionalTax,
  IPurchaseOrderItem,
  IPurchaseOrderSearch,
  poAddtaxInitValues,
  poItemInitValues,
} from '../interface/IInvoiceTypes/IPurchaseOrderModel'
import { addTaxInitValues, IAddTaxModel } from '../../../_models/additionaltax-model'
import { getCompanyCode, getTaxes } from '../../../services/CommonService'
import { IPODto, updatePOInput, updatePOTaxes } from './pConsts'
import { BillAddress, CustomerAddress, ShipAddress, SupplierAddress } from '../components/AddressComponent'
import { POTemplateSelect } from './partials/POTemplateSelecter'
import POEMail from './partials/POEmail'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Purchase Orders',
    path: '/Purchase-Orders/Details',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
export function PurchaseOrderDetails(props: any) {
  const { id } = useParams()
  const [data, setData] = useState<any>(null)
  const [isLoading, setIsLoading] = useState<any>(id || 0)
  const [error, setError] = useState(false)
  const [showTemplate, setShowTemplate] = useState<any>(false)
  const [isCancelled, setIsCancelled] = useState<any>(false)
  const [tab, setTab] = useState(1)
  const [loadingStages, setLoadingStages] = useState<number>(LOADINGSTAGES.IDLE)
  const [companyCode, setCompanyCode] = useState<any>('')

  const [editField, setEditField] = useState<any>({
    purchaseOrderDate: false,
    purchaseOrderDueDate: false,
    purchaseOrderNumber: false,
  })
  const [poEdited, setPoEdited] = useState<any>({
    purchaseOrderDate: data?.purchaseOrderDate,
    purchaseOrderDueDate: data?.purchaseOrderDueDate,
    purchaseOrderNumber: data?.purchaseOrderNumber,
  })
  const [itemsData, setItemsData] = useState<any>({
    taxableAmount: 0,
    taxAmount: 0,
    totalAmount: 0,
  })
  const [addressForm, setAddressForm] = useState<any>({ type: '', status: null })
  const [saveInitialData, setSaveInitialData] = useState<any>({})
  const [supplierInfo, setSupplierInfo] = useState<any>()
  const [adressShipTo, setAddressShipTo] = useState<any>()
  const [adressBillTo, setAddressBillTo] = useState<any>()
  const [amtInWords, setAmtInWords] = useState<any>(null)
  const [poId, setPoId] = useState<any>(id || 0)
  const [totalAmount, setTotalAmount] = useState<any>(0)
  const [subAmount, setSubAmount] = useState<any>(0)
  const [taxAmount, setTaxAmount] = useState<any>(0)
  const [mailDetails, setMailDetails] = useState<any>(null)
  const [poItems, setPoItems] = useState<IPurchaseOrderItem[]>([poItemInitValues])
  const [isCancelledPo, setIsCancelledPo] = useState<any>(false)
  const [showMail, setShowMail] = useState<any>(false)

  const bindDetails = (values: any) => {
    setLoadingStages(LOADINGSTAGES.LOADING)
    setTimeout(() => {
      getPurchaseOrder(values)
        .then((res: any) => {
          if (res.data.success) {
            let _data = res.data.data as any
            setData(_data)
            setPoId(_data.id)
            bindPoTaxes(_data.id)
            setTotalAmount(_data.totalAmount)
            setSubAmount(_data.subAmount)
            setTaxAmount(_data.taxAmount)
            let items = _data.purchaseOrderItem
            setPoItems(items)
            setTotalAmount(_data.totalAmount)
            setMailDetails({
              to: _data.supplierEmail,
              subject: `Purchase Order #${_data.id}`,
              message: `Dear ${_data.billName},\n\nPlease find the attached Purchase Order for your reference.\n\nRegards,\n${_data.shipName}`,
              purchaseOrderNumber: _data.purchaseOrderNumber,
              customerName: _data.billName,
            })
            setIsCancelled(_data.isLapsed)

            const [rupees, paisa] = _data.totalAmount?.toString().split('.') ?? []
            const amtInWords = `${N2WIndian.convert(rupees)} Rupees${paisa ? ` and ${N2WIndian.convert(paisa)} Paisa` : ''
              }`
            setAmtInWords(amtInWords)
            const {
              supplierName,
              supplierAddress,
              supplierCity,
              supplierState,
              supplierGstin,
              supplierPostalCode,
              billName,
              billAddress,
              billCity,
              billState,
              billEmail,
              billContactNo,
              billBranchGstin,
              billPostalCode,
              shipName,
              shipAddress,
              shipCity,
              shipState,
              shipPostalCode,
              shipEmail,
              shipContactNo,
              supplierEmail,
              supplierContactNo,
              shipBranchGstin,
            } = _data

            const _supplierInfo: any = {
              supplierName,
              supplierAddress,
              supplierCity,
              supplierState,
              supplierGstin,
              supplierPostalCode,
              supplierEmail,
              supplierContactNo
            }

            setSupplierInfo({ ..._supplierInfo })

            const _billTo: any = {
              billName,
              billAddress,
              billCity,
              billState,
              billPostalCode,
              customerPan: null,
              billEmail: billEmail,
              billContactNo: billContactNo,
              billBranchGstin: billBranchGstin
            }
            setAddressBillTo({ ..._billTo })

            const _shipTo: any = {
              shipName,
              shipAddress,
              shipCity,
              shipState,
              shipPostalCode,
              shipBranchGstin,
              shipEmail,
              shipContactNo
            }
            setAddressShipTo({ ..._shipTo })

            const saveInitialData = {
              supplierInfo: _supplierInfo,
              billTo: _billTo,
              shipTo: _shipTo,
            }
            setSaveInitialData(saveInitialData)
            setLoadingStages(LOADINGSTAGES.POSITIVE)
          } else {
            setLoadingStages(LOADINGSTAGES.NEGATIVE)
          }
        })
        .catch((err: any) => {
          setData({})
          setLoadingStages(LOADINGSTAGES.ERROR)
          setError(true)
        })
    }, 1000)
  }

  const afterUpdate = () => {
    bindDetails(poId)
  }

  const setShowInvoiceModal = () => {
    setShowTemplate(false)
  }

  const toggleShowMail = (invoiceId: any) => {
    setPoId(invoiceId)
    setShowMail(true)
  }

  useEffect(() => {
    bindTaxes()
    if (poId > 0) {
      bindDetails(poId)
    }
    setCompanyCode(getCompanyCode())
  }, [])

  const [taxes, setTaxes] = useState<IPOAdditionalTax[]>([poAddtaxInitValues])
  const [initialTaxes, setInitialTaxes] = useState<IPOAdditionalTax[]>([poAddtaxInitValues])
  const bindPoTaxes = (taxId: number) => {
    getPoTaxes(taxId)
      .then((res: any) => {
        if (res.data.success) {
          setInitialTaxes(res.data.data)
          setTaxes(res.data.data)
        } else {
        }
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  const [additionalTax, setAdditoinalTax] = useState<string>('0.00')
  const additionaTaxChange = () => {
    const additonalTax = taxes.reduce((acc: number, item: IPOAdditionalTax) => {
      return acc + (item.taxPercent / 100) * subAmount ?? 0
    }, 0)
    setAdditoinalTax(additonalTax.toFixed(2))
  }
  useEffect(() => {
    additionaTaxChange()
  }, [taxes, subAmount])
  const toggleShowTemplate = (poId: any) => {
    setPoId(poId)
    setShowTemplate(true)
  }

  const [isItemsEdited, setIsItemsEdited] = useState<any>(false)
  const updateItemsData = (data: any) => {
    if (data) {
      setItemsData(data)
      setIsItemsEdited(true)
    }
  }

  const updatePODetails = () => {
    const id = toast.loading('Please wait...')
    const __data = { ...data }
    __data.subAmount = subAmount
    __data.taxAmount = taxAmount
    __data.discount = data.discount
    __data.addTax = additionalTax
    __data.totalAmount = totalAmount
    __data.amountInWords = amtInWords
    __data.purchaseOrderItems = poItems
    __data.purchaseOrderAdditionalTaxes = taxes
    __data.id = poId
    setTimeout(() => {
      updatePurchaseOrder(__data, isItemsEdited ? 'items' : 'invoice')
        .then((res: any) => {
          if (res.data.success) {
            toastNotify(id, res)
            bindDetails(poId)
          } else {
            toastNotify(id, res)
          }
        })
        .catch((err) => {
          console.log(err)
          const data = { success: false, message: 'Something went wrong' }
          toastNotify(id, data)
        })
        .finally(() => {
          setIsLoading(false)
          toast.dismiss(id)
        })
    }, 1000)
  }
  // modify total amount when there is change in additional tax
  useEffect(() => {
    const amt =
      Number.parseFloat(taxAmount || 0) +
      Number.parseFloat(subAmount || 0) +
      Number.parseFloat(additionalTax ?? 0)
    setTotalAmount(amt.toFixed(2))
  }, [additionalTax, taxAmount, subAmount])


  useEffect(() => {
    let newData = { ...data }
    newData.discount = editField.discount ? poEdited.discount : newData.discount
    newData.addTax = additionalTax ? additionalTax : newData.addTax
    newData.subAmount = itemsData.taxableAmount ? itemsData.taxableAmount : newData.subAmount
    setSubAmount(itemsData.taxableAmount || 0)
    newData.taxAmount = itemsData.taxAmount ? itemsData.taxAmount : newData.taxAmount
    setTaxAmount(itemsData.taxAmount || 0)
    newData.quotationNumber = editField.quotationNumber
      ? poEdited.quotationNumber
      : newData.quotationNumber

    const amt =
      Number.parseFloat(newData?.taxAmount || 0) +
      Number.parseFloat(newData?.subAmount || 0) +
      Number.parseFloat(additionalTax ?? 0) -
      Number.parseFloat(newData?.discount || 0)
    setTotalAmount(amt.toFixed(2))

    const [rupees, paisa = '00'] = (amt >= 0 ? amt : -amt).toFixed(2).toString().split('.')
    setAmtInWords(
      `${amt >= 0 ? '' : 'Minus '}${N2WIndian.convert(rupees)} Rupees and ${N2WIndian.convert(
        paisa
      )} Paisa`
    )
  }, [itemsData.subAmount, itemsData.taxAmount, itemsData.totalAmount])
  const [defaultTaxes, setDefaultTaxes] = useState<IAddTaxModel[]>([addTaxInitValues])

  const bindTaxes = () => {
    setTimeout(() => {
      getTaxes(true)
        .then((res: any) => {
          let _data = res.data.data
          setDefaultTaxes(_data)
        })
        .catch(() => { })
    }, 1000)
  }
  const [addTax, setAddTax] = useState(false)

  const deleteTaxItem = (index: number) => {
    const newTaxRowsData = [...taxes]
    newTaxRowsData.splice(index, 1)
    setTaxes(newTaxRowsData)
  }

  const addTaxRow = () => {
    setTaxes([...taxes, { ...poAddtaxInitValues, taxId: 0 }])
  }

  const handleTaxChange = (index: number, evnt: any) => {
    const { name, value } = evnt.target
    // pass value for each name
    const rowsInput = [...taxes]
    const taxInfo = defaultTaxes.find((c) => c.id === +value)
    if (+value > 0) {
      if (taxInfo) {
        rowsInput[index]['taxId'] = taxInfo.id
        rowsInput[index]['taxName'] = taxInfo.taxName
        rowsInput[index]['taxPercent'] = taxInfo.taxValue
        setTaxes(rowsInput)
      }
    } else {
      rowsInput[index]['taxName'] = 'None'
      rowsInput[index]['taxPercent'] = taxInfo?.taxValue || 0
      rowsInput[index]['taxId'] = taxInfo?.id || 0
      setTaxes(rowsInput)
      // add same row in InitialTaxes
    }
  }
  const isDisabled = (taxId: number) => {
    if (taxId === 0) return false
    return taxes.some((c) => c.taxId === taxId)
  }
  const updateTxs = () => {
    const dto: IPODto = {
      addTax: additionalTax as any,
      taxAmount: data.taxAmount,
      subAmount: subAmount,
      totalAmount: totalAmount,
      poAdditionalTaxes: taxes,
      id: poId,
      totalFreightCharges: data.totalFreightCharges,
    }
    const onResponse = (res: any) => {
      if (res) {
        bindDetails(poId)
      }
    }
    const newDto = {
      tax: dto,
      onResponse,
    }
    updatePOTaxes(newDto)
  }

  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>Purchase Order Details</PageTitle>
      {loadingStages === LOADINGSTAGES.LOADING && <Loader />}
      {loadingStages === LOADINGSTAGES.NEGATIVE && <ServerMessage message='No data found' />}
      {loadingStages === LOADINGSTAGES.ERROR && <ServerMessage message='Something went wrong' />}
      {loadingStages === LOADINGSTAGES.POSITIVE && (
        <>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-0'>
              <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center mb-2'>
                        <a
                          href='#'
                          className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'
                        >
                          # {data?.purchaseOrderNumber}
                        </a>
                      </div>
                    </div>
                    <div className='d-flex my-4'>
                      <a
                        href='#'
                        onClick={() => toggleShowTemplate(data?.id)}
                        className={`btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1`}
                        id='kt_charts_widget_2_year_btn'
                      >
                        <i className='fas fa-print'></i> Preview &amp; Print
                      </a>
                      <a
                        href='#'
                        onClick={() => toggleShowMail(data?.id)}
                        className={`btn btn-sm btn-color-muted btn-active btn-active-secondary active px-4 me-1`}
                        id='kt_charts_widget_2_year_btn'
                      >
                        <i className='fas fa-envelope'></i> Email
                      </a>
                      {/* <a
                        href='#'
                        className={`btn btn-sm btn-color-muted btn-active btn-active-success active px-4 me-1`}
                        id='kt_charts_widget_2_year_btn'
                      >
                        <i className='fas fa-share'></i> Share
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-md-12'>
                  <div className='row mb-7'>
                    <div className='col-lg-3'>
                      <h6 className='fw-bold text-muted mb-1'>
                        Supplier
                        <i
                          className=' text-info fas fa-edit px-15 d-none'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_upgrade_plan'
                          onClick={() =>
                            setAddressForm({
                              type: 'supplierInfo',
                              status: true,
                            })
                          }
                        ></i>
                      </h6>
                      <SupplierAddress {...supplierInfo} />
                    </div>

                    <div className='col-lg-3'>
                      <h6 className=' fw-bold text-muted mb-1'>
                        Bill To
                        <i
                          className=' text-info fas fa-edit px-15 d-none'
                          onClick={() =>
                            setAddressForm({
                              type: 'billTo',
                              status: true,
                            })
                          }
                        ></i>
                      </h6>
                      <BillAddress {...adressBillTo} />
                    </div>

                    <div className='col-lg-3'>
                      <h6 className=' fw-bold text-muted mb-1'>
                        Ship To
                        <i
                          className=' text-info fas fa-edit px-15 d-none'
                          onClick={() =>
                            setAddressForm({
                              type: 'shipTo',
                              status: true,
                            })
                          }
                        ></i>
                      </h6>
                      <ShipAddress {...adressShipTo} />
                    </div>

                    <div className='col-lg-3'>
                      <div>
                        <h6 className=' fw-bold text-muted mb-1'>Purchase Order Date</h6>
                        {!editField.purchaseOrderDate ? (
                          <>
                            <span className='fw-bolder fs-6 text-dark'>
                              {moment(data?.purchaseOrderDate).format('DD-MM-YYYY')}
                            </span>
                            <i
                              className=' text-info fas fa-edit px-15'
                              onClick={() =>
                                setEditField({
                                  ...editField,
                                  purchaseOrderDate: !editField.purchaseOrderDate,
                                })
                              }
                            ></i>
                          </>
                        ) : (
                          <div className='d-flex align-items-center '>
                            <input
                              type='date'
                              className='form-control me-2'
                              defaultValue={moment(data?.purchaseOrderDate).format('YYYY-MM-DD')}
                              value={poEdited.purchaseOrderDate}
                              onChange={(e) =>
                                setPoEdited({
                                  ...poEdited,
                                  purchaseOrderDate: e.target.value,
                                })
                              }
                            />
                            <div className='d-flex'>
                              <i
                                className='fa fa-close btn btn-sm btn-danger me-2'
                                onClick={() =>
                                  setEditField({
                                    ...editField,
                                    purchaseOrderDate: !editField.purchaseOrderDate,
                                  })
                                }
                              ></i>
                              <i
                                className='fa fa-check btn btn-sm btn-success'
                                onClick={() => {
                                  updatePOInput({
                                    type: 'purchaseOrderDate',
                                    dto: {
                                      id: parseInt(poId),
                                      purchaseOrderDate: poEdited.purchaseOrderDate,
                                    },
                                    onResponse: (e) => {
                                      if (e) {
                                        setEditField({
                                          ...editField,
                                          invoiceDate: !editField.invoiceDate,
                                        })
                                        bindDetails(id)
                                      }
                                    },
                                  })
                                }}
                              ></i>
                            </div>
                          </div>
                        )}
                      </div>
                      <div>
                        <h6 className=' fw-bold text-muted mb-1' style={{ marginTop: '1em' }}>
                          Purchase Order Due Date
                        </h6>
                        {!editField.purchaseOrderDueDate ? (
                          <>
                            <span className='fw-bolder fs-6 text-dark'>
                              {moment(data?.purchaseOrderDueDate).format('DD-MM-yyyy')}
                            </span>
                            <i
                              className=' text-info fas fa-edit px-15'
                              onClick={() =>
                                setEditField({
                                  ...editField,
                                  purchaseOrderDueDate: !editField.purchaseOrderDueDate,
                                })
                              }
                            ></i>
                          </>
                        ) : (
                          <div className='d-flex align-items-center'>
                            <input
                              type='date'
                              className='form-control me-2'
                              defaultValue={moment(data?.purchaseOrderDueDate).format('YYYY-MM-DD')}
                              value={poEdited.purchaseOrderDueDate}
                              onChange={(e) =>
                                setPoEdited({
                                  ...poEdited,
                                  purchaseOrderDueDate: e.target.value,
                                })
                              }
                            />
                            <div className='d-flex'>
                              <i
                                className='fa fa-close btn btn-sm btn-danger me-2'
                                onClick={() =>
                                  setEditField({
                                    ...editField,
                                    purchaseOrderDueDate: !editField.purchaseOrderDueDate,
                                  })
                                }
                              ></i>
                              <i
                                className='fa fa-check btn btn-sm btn-success'
                                onClick={() => {
                                  updatePOInput({
                                    type: 'purchaseOrderDueDate',
                                    dto: {
                                      id: parseInt(poId),
                                      purchaseOrderDueDate: poEdited.purchaseOrderDueDate,
                                    },
                                    onResponse: (e) => {
                                      if (e) {
                                        setEditField({
                                          ...editField,
                                          purchaseOrderDueDate: !editField.purchaseOrderDueDate,
                                        })
                                        bindDetails(id)
                                      }
                                    },
                                  })
                                }}
                              ></i>
                            </div>
                          </div>
                        )}
                      </div>
                      <div>
                        <h6 className=' fw-bold text-muted mb-1' style={{ marginTop: '1em' }}>
                          Purchase Order Number
                        </h6>
                        {!editField.purchaseOrderNumber ? (
                          <>
                            <span className='fw-bolder fs-6 text-dark'>
                              {data?.purchaseOrderNumber}
                            </span>
                            <i
                              className=' text-info fas fa-edit px-15'
                              onClick={() =>
                                setEditField({
                                  ...editField,
                                  purchaseOrderNumber: !editField.purchaseOrderNumber,
                                })
                              }
                            ></i>
                          </>
                        ) : (
                          <div className='d-flex align-items-center'>
                            <input
                              type='numeric'
                              className='form-control me-2'
                              defaultValue={data?.purchaseOrderNumber}
                              value={poEdited.purchaseOrderNumber}
                              onChange={(e) =>
                                setPoEdited({
                                  ...poEdited,
                                  purchaseOrderNumber: e.target.value,
                                })
                              }
                            />
                            <div className='d-flex'>
                              <i
                                className='fa fa-close btn btn-sm btn-danger me-2'
                                onClick={() =>
                                  setEditField({
                                    ...editField,
                                    purchaseOrderNumber: !editField.purchaseOrderNumber,
                                  })
                                }
                              ></i>
                              <i
                                className='fa fa-check btn btn-sm btn-success'
                                onClick={() => {
                                  updatePOInput({
                                    type: 'purchaseOrderNumber',
                                    dto: {
                                      id: parseInt(poId),
                                      purchaseOrderNumber: poEdited.invoiceNumber,
                                    },
                                    onResponse: (e) => {
                                      if (e) {
                                        setEditField({
                                          ...editField,
                                          purchaseOrderNumber: !editField.purchaseOrderNumber,
                                        })
                                        bindDetails(id)
                                      }
                                    },
                                  })
                                }}
                              ></i>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <div className='col-lg-3'>
                      <h6 className=' fw-bold text-muted mb-1'>Taxable Amount</h6>
                      <p className='fw-bolder fs-6 text-dark'>{parseFloat(subAmount).toFixed(2)}</p>
                    </div>
                    <div className='col-lg-3'>
                      <h6 className=' fw-bold text-muted mb-1'>Tax Amount</h6>
                      <p className='fw-bolder fs-6 text-dark'>{parseFloat(taxAmount).toFixed(2)}</p>
                    </div>
                    <div className='col-lg-3'>
                      <h6 className=' fw-bold text-muted mb-1'>Additional Tax</h6>
                      <p className='fw-bolder fs-6 text-dark'>
                        {parseFloat(additionalTax).toFixed(2)}
                      </p>
                    </div>
                    <div className='col-lg-3'>
                      <h6 className=' fw-bold text-muted mb-1'>Amount</h6>
                      <p className='fw-bolder fs-6 text-dark'>{totalAmount}</p>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <div className='col-lg-9'>
                      <h6 className=' fw-bold text-muted mb-1'>Amount in Words</h6>
                      <span className='fw-bolder fs-6 text-dark text-capitalize'>{amtInWords}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='separator'></div>
              <div className='row my-3 mx-5'>
                <h6 className='fw-bold text-muted mb-1'>
                  Additonal Taxes{' '}
                  <button
                    className='btn btn-sm btn-light p-5'
                    style={{ fontSize: '0.2rem' }}
                    onClick={() => setAddTax(true)}
                  >
                    <i className='fa fa-plus' />
                  </button>
                </h6>
                <div className='row mb-lg-10 p-0'>
                  <Table className='table align-middle table-row-dashed fs-6 px-5 dataTable no-footer'>
                    <tbody>
                      {taxes.map((item: IPOAdditionalTax, index: number) => {
                        // disable all rows which are available in initialTaxes
                        return (
                          <tr key={index}>
                            <td className='w-350px'>
                              <select
                                className='form-control'
                                name='taxName'
                                id={`taxName_${index}`}
                                value={item.taxId}
                                onChange={(evnt) => handleTaxChange(index, evnt)}
                                disabled={isDisabled(item.taxId)}
                              >
                                <option value={0}>None</option>
                                {isDisabled(item.taxId)
                                  ? defaultTaxes.filter((tax) => tax.id === item.taxId).length >
                                  0 && <option value={item.taxId}>{item.taxName}</option>
                                  : defaultTaxes.map((tax) => (
                                    <option
                                      key={tax.id}
                                      value={tax.id}
                                      disabled={isDisabled(tax.id)}
                                    >
                                      {tax.taxName}
                                    </option>
                                  ))}
                              </select>
                            </td>
                            <td>
                              <input
                                className='form-control'
                                name='taxPercent'
                                id={`taxPercent_${index}`}
                                value={item.taxPercent}
                                disabled={
                                  defaultTaxes.filter((tax) => tax.id === item.taxId).length > 0
                                }
                                onChange={(evnt) => handleTaxChange(index, evnt)}
                              />
                            </td>
                            <td className='w-350px'>
                              <span className='fw-bolder fs-6 text-dark'>
                                {((item.taxPercent / 100) * subAmount ?? 0).toFixed(2)}
                              </span>
                            </td>
                            <td className='w-200px'>
                              <a onClick={() => setAddTax(true)}>
                                {' '}
                                <i className='las la-plus fs-2x'></i>
                              </a>
                              <a onClick={() => deleteTaxItem(index)} href='#'>
                                {' '}
                                <i className='las la-trash fs-2x text-danger me-5'></i>
                              </a>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                  <div className='d-flex justify-content-end'>
                    <div className='d-flex flex-column align-items-end me-5'>
                      <button
                        className='btn btn-sm btn-light w-125px'
                        onClick={() => {
                          addTaxRow()
                        }}
                      >
                        <i className='fa fa-plus' />
                        Add Tax
                      </button>
                      <span className='fw-bolder fs-6 text-dark mt-2 '>
                        Add Tax Amount:{' '}
                        <span className='fw-bolder fs-6 text-dark'>{additionalTax}</span>
                        <button
                          className='btn btn-sm btn-light-primary ms-2 w-125px'
                          onClick={() => {
                            updateTxs()
                          }}
                        >
                          Update Taxes
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='separator'></div>
              <div className='row mt-5 mx-3'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                  <li className='nav-item'>
                    <a
                      onClick={() => setTab(1)}
                      className='nav-link active'
                      data-bs-toggle='tab'
                      href='#'
                    >
                      Items
                    </a>
                  </li>
                </ul>
                <div className='separator'></div>
                <div className='d-flex justify-content-end'>
                  <div className='d-flex flex-column align-items-end me-5 mt-3'>
                    <button className='btn btn-sm btn-light-primary' onClick={() => updatePODetails()}>
                      Update item(s)
                    </button>
                  </div>
                </div>
                <div className='col-md-12 mt-5'>
                  <PoAddressForm
                    id={poId}
                    initialData={saveInitialData}
                    setBillTo={setAddressBillTo}
                    setShipTo={setAddressShipTo}
                    setSupplierInfo={setSupplierInfo}
                    type={addressForm.type}
                    billTo={adressBillTo}
                    shipTo={adressShipTo}
                    supplierInfo={supplierInfo}
                    show={addressForm.status}
                    onUpdate={() => afterUpdate()}
                    handleClose={() => setAddressForm(false)}
                  />
                  {tab == 1 && data && data.purchaseOrderItem?.length > 0 && (
                    <ListPurchaseOrderItems
                      Id={id}
                      data={poItems}
                      isCancelled={isCancelled}
                      setItemsData={updateItemsData}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showTemplate && (
        <POTemplateSelect
          setShowInvoiceModal={setShowInvoiceModal}
          invoiceId={poId}
          companyCode={companyCode}
        />
      )}
      {showMail && (
        <POEMail
          mailDetails={mailDetails}
          setShowEmail={setShowMail}
          handleClose={() => setShowMail(false)}
          data={data}
        />
      )}
    </>
  )
}
