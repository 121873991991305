import axiosInstance from '../services/AxiosInstance';

export function postInvoice(data) {
    return axiosInstance.post(`invoices/addNew`, data);
}

export function generateFromQuote(data) {
    return axiosInstance.post(`invoice/generateFromQuotation?quotationId=${data}`, data);
}

export function cloneInvoice(data) {
    return axiosInstance.post(`invoices/clone`, data);
}

export function searchInvoices(searchParams) {
    return axiosInstance.post(`invoices/search`, searchParams);
}

export function getInvoice(data) {
    return axiosInstance.post(`invoices/search`,
        data);
}

export function getInvoiceDetails(id) {
    return axiosInstance.get(`invoices/${id}`);
}

export function dailySales(searchParams) {
    return axiosInstance.post(`invoices/daily-sales-report`, searchParams);
}

export function taxReport(searchParams) {
    return axiosInstance.post(`invoices/tax-report`, searchParams);
}

export function hsnReport(searchParams) {
    return axiosInstance.post(`invoices/hsn-report`, searchParams);
}
export function invoiceUpdate(data, type) {
    return axiosInstance.put(`invoices/inv-update/${type}`, data);
}
export function invoiceDetailsUpdate(data) {
    return axiosInstance.put(`invoices/update/details`, data);
}

export function getInvoiceHtml(id, template, label, companyCode) {
    return axiosInstance.get(`invoice/get-html?id=${id}&template=${template}&label=${label}&companyCode=${companyCode}`);
}
export const sendInvoiceMail = (id, data, label) => {
    const email = data;
    return axiosInstance.post(`invoice/send-email/${id}?label=${label}`, email);
}
export function companyAddresses(companyId) {
    return axiosInstance.post(`address/company/${companyId}`);
}
export function cutomerAddresses(customerId) {
    return axiosInstance.post(`address/customer/${customerId}`);
}
export function dashboardRecentInvoice() {
    return axiosInstance.get('Invoices/InvoicRecentDashBoard');
}

export function updateType(id,type) {
    return axiosInstance.put(`invoices/type-change/${id}/${type}`);
}

export function getInvoiceSerialNumber(isProforma) {
    return axiosInstance.get(`invoice/invoice-number?isProforma=${isProforma}`);
}