import React, { useEffect, useState, Fragment } from 'react';
import { Link } from "react-router-dom";
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { getAllBugs } from '../../../services/BugsReportService';
import { getTotalDashBoardCounter } from "../../../services/CommonService"
import { DisableSidebar, PageLink, PageTitle } from '../../../_metronic/layout/core';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';
import Pagination from '../../../lib/Pagination/Pagination';
import { PAGE_SIZE } from "../../../_shared";
import { LoadData, NoDataFound, ServerMessage } from '../../../lib/NoData';
import { IConfirmModel } from '../../../_models/confirm-model';
import { ConfirmBox } from '../../../lib/ConfirmBox';
import { Loader } from '../components/components';
import moment from 'moment';
const profileBreadCrumbs: Array<PageLink> = [
    {
        title: 'Report Bugs',
        path: '/reportbugs',
        isSeparator: false,
        isActive: false,
    },
    //    {
    //        title: '',
    //        path: '',
    //        isSeparator: true,
    //        isActive: false,
    //    },
]

export function ListBugs() {
    const [toggle, setToggle] = useState("");
    const onClick = (name: any) => setToggle(toggle === name ? "" : name);
    const [counters, setCounters] = React.useState<any>({});
    const [data, setData] = React.useState([]);
    const [currentTableData, setCurrentTableData] = React.useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = React.useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [role, setRole] = React.useState<any>();
    const [lastLogin, setLastLogin] = React.useState<any>();
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [confirmModel, setConfirmModel] = useState<IConfirmModel>();
    const LOADINGSTAGES = {
        IDLE: 0,
        LOADING: 1,
        POSITIVE: 3,
        NEGATIVE: 4,
        ERROR: 5,
        EMPTY: 6
    }
    const [loadingStage, setLoadingStage] = useState<any>(LOADINGSTAGES.IDLE);
    const [status, setStatus] = React.useState<any>();
    const [keyword, setKeyword] = React.useState<any>();

    const initialValues = {
        pageNumber: 1,
        pageSize: 100000,
        customerId: 128,
        fromDate: "",
        toDate: "",
        filter: "",

    }

    const bindCounters = () => {
        getTotalDashBoardCounter().then((res: any) => {
            const data = res.data.data;
            setCounters(data);
        }).catch((err: any) => {
            console.log(err);
        })
    }

    const paginate = (pageNumber: any) => {
        setCurrentPage(pageNumber);
        const firstPageIndex = (pageNumber - 1) * PAGE_SIZE;
        const lastPageIndex = firstPageIndex + PAGE_SIZE;
        let _currentTableData = data.slice(firstPageIndex, lastPageIndex);
        setCurrentTableData(_currentTableData);
    };
    const filterData = () => {
        const values = {
            ...initialValues,
            filter: keyword,
            status: parseInt(status),
        }
        bindGrid();
    }

    const resetData = () => {
        setKeyword("");
        setStatus(0);
        bindGrid();
    }
    const bindGrid = () => {
        setLoadingStage(LOADINGSTAGES.LOADING);
        setIsLoading(true);
        setTimeout(() => {
            getAllBugs()
                .then((res) => {
                    if (res.data.success) {
                        let _data = res.data.data;
                    
                        const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
                        const lastPageIndex = firstPageIndex + PAGE_SIZE;
                        let _currentTableData = _data.slice(firstPageIndex, lastPageIndex);
                        setData(_data);
                        setTotalRows(_data.length);
                        if (_data.length > 0) {
                            setLoadingStage(LOADINGSTAGES.POSITIVE);
                            setCurrentTableData(_currentTableData);
                        }
                        else {
                            setLoadingStage(LOADINGSTAGES.NEGATIVE);
                            setCurrentTableData([]);
                        }
                    }
                    else {
                        setLoadingStage(LOADINGSTAGES.NEGATIVE);
                        setCurrentTableData([]);
                    }
                })
                .catch(() => {
                    setLoadingStage(LOADINGSTAGES.ERROR);
                    setCurrentTableData([]);
                }).finally(() => {

                    setIsLoading(false);
                });
        }, 1000);
    };


    const openDeleteModal = (id: any) => {
        if (id > 0) {
            const confirmModel: IConfirmModel = {
                title: 'Delete Customer',
                btnText: 'Delete this customer',
                deleteUrl: `customers/${id}`,
                message: 'delete-customer',
            }

            setConfirmModel(confirmModel);
            setTimeout(() => {
                setShowConfirm(true)
            }, 500);
        }
    }

    const afterConfirm = (res: any) => {
        if (res) bindGrid();

        setShowConfirm(false);
    }


    useEffect(() => {
        bindGrid();
        bindCounters();
    }, []);

    return (<>
        <PageTitle breadcrumbs={profileBreadCrumbs}>Bugs Report</PageTitle>
        <DisableSidebar>


            {loadingStage === LOADINGSTAGES.LOADING && <Loader />}
            {loadingStage === LOADINGSTAGES.NEGATIVE && <ServerMessage message="No Data Found" />}
            {loadingStage === LOADINGSTAGES.ERROR && <ServerMessage message="Oops! server issue occured" />}
            {loadingStage === LOADINGSTAGES.POSITIVE ?
                <div className='row'>
                    <div className="col-xl-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <div className="display mb-4 dataTablesCard customer-list-table">
                                        <Table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                                            <thead>
                                                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                                    <th>User</th>
                                                    <th>Subject</th>
                                                    <th>Date</th>
                                                    <th>Message</th>
                                                    <th>IP</th>
                                                    <th>Attachment</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentTableData.map((item: any, index: any) =>
                                                    <tr key={index}>
                                                        <td>

                                                            <div className="d-flex align-items-center">

                                                                <div className="d-flex flex-column">
                                                                    <Link to={`/customer/edit/${item.id}`} className="text-dark fw-900 text-hover-secondary mb-1"
                                                                    > {item.username}
                                                                    </Link>
                                                                    <span className='fs-7'>{item.email}</span>
                                                                </div>
                                                            </div>

                                                        </td>
                                                        <td>{item.subject}</td>
                                                        <td>{moment(item.reportedOn).format("DD-MM-yyyy")}</td>
                                                        <td>{item.message}</td>
                                                        <td>{item.ip}</td>
                                                        <td>
                                                            {item.attachmentId > 0 && <a target={'_blank'} href={`${process.env.REACT_APP_BASE_URL}/${item.imagePath}`}>View</a>}
                                                        </td>
                                                    </tr>)}
                                            </tbody>
                                        </Table>
                                        <Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage}
                                            totalCount={totalRows}
                                            pageSize={PAGE_SIZE}
                                            onPageChange={(page: any) => paginate(page)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null}
        </DisableSidebar>
        {showConfirm && <ConfirmBox confirmModel={confirmModel} afterConfirm={afterConfirm} />}
    </>)
}