/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { recentCustomers, searchCustomers } from "../../../../services/CustomerService"
import { Loader } from '../../../../app/pages/components/components'

type Props = {
  className: string,
  memberCount: number
}

const RecentCustomers: React.FC<Props> = ({ className, memberCount }) => {
  const [data, setData] = useState<any>([]);
  const initialValues = {
    pageNumber: 1,
    pageSize: 10,
    customerId: 128,
    fromDate: "",
    toDate: "",
    filter: "",
    status: 0,
  }

  const [isData, setIsData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const bindGrid = () => {
    setIsLoading(true);
    recentCustomers().then((res: any) => {
      const array = res.data.data;
      if (res.data.success) {
        setData(array);
        if (array.length === 0) {
          setIsData(false);
        }
        else {
          setIsData(true);
        }
      }
      if (res.data.success === false) {
        setData([]);
      }
    }).catch((err: any) => {
      console.log(err);
    }).finally(() => {
      setIsLoading(false);
    });
  }

  useEffect(() => {
    bindGrid();
  }, []);

  return (
    <>
      {isLoading ? <div className=''>
        <Loader />
      </div> : <div className={`card shadow-sm ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Recent Customers</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>{isData ? `Total ${data.length} customer(s) since one week` : "No recent customer(s) since one week"}</span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <a
              href='/customer/add'
              className='btn btn-sm btn-secondary'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_invite_friends'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              New Customer
            </a>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>

                  <th className='min-w-150px'>Name</th>
                  <th className='min-w-120px'>Mobile</th>
                  <th className='min-w-100px text-end'>Email</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {
                  data && data.map((element: any, index: any) => (
                    <tr key={index}>
                      <td>
                        <div className='d-flex align-items-center'>

                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {element.fullName}&nbsp;
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {element.city}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        {element.contactNo}
                        {/* <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2'>
                          <span className='text-muted me-2 fs-7 fw-semibold'>50%</span>
                        </div>
                        <div className='progress h-6px w-100'>
                          <div
                            className='progress-bar bg-primary'
                            role='progressbar'
                            style={{ width: '50%' }}
                          ></div>
                        </div>
                      </div> */}
                      </td>
                      <td>
                        {element.email}
                      </td>
                    </tr>
                  ))
                }
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>}
    </>
  )
}

export { RecentCustomers }
