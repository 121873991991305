import moment from 'moment';
export const PlanDetail = (props: any) => {
    const { plan } = props;
    return (<div>
        <div className='card mb-xl-5'>
            <header className='card-header'>
                <h5 className="card-title">Plan Details</h5>
            </header>
            <section className='card-body'>
                <div style={{ display: 'flex', marginBottom: '10px', fontWeight: 'bold' }}>
                    <div style={{ flex: 1 }}>Plan</div>
                    <div style={{ flex: 1 }}>Start Date</div>
                    <div style={{ flex: 1 }}>End Date</div>
                    <div style={{ flex: 1 }}>Remaining Days</div>
                    <div style={{ flex: 1 }}>Status </div>
                </div>
                    <div style={{ display: 'flex', marginBottom: '10px' }}>
                        <div style={{ flex: 1 }} className='text-start fs-6'>{plan.plan}</div>
                        <div style={{ flex: 1 }}>{moment(plan.fromDate).format('DD-MM-YYYY')}</div>
                        <div style={{ flex: 1 }}>{moment(plan.toDate).format('DD-MM-YYYY')}</div>
                        <div style={{ flex: 1 }}>{plan.remainingDays}</div>
                        <div style={{ flex: 1 }}>{plan.status} </div>
                    </div>
            </section>
        </div>
        <div className='separator mx-1 my-4'></div>
    </div>
    );
};