import React from 'react';
import { Dropdown } from 'react-bootstrap';

export interface IStatus {
  id: number;
  statusText: string;
}

interface StatusDropdownProps {
  statusList: IStatus[];
  onStatusClick: (statusId: number, statusText: string) => void;
}

const StatusDropdown: React.FC<StatusDropdownProps> = ({
  statusList,
  onStatusClick,
}) => {
  const [showMenu, setShowMenu] = React.useState(false);

  const handleToggle = () => {
    setShowMenu(!showMenu);
  };

  return (
    <Dropdown onToggle={handleToggle} show={showMenu}>
      <Dropdown.Toggle
        variant='light'
        id='dropdown-basic'
        className='btn btn-sm btn-active-secondary btn-light border px-4 me-2 w-150px'
      >
        Set Status
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {statusList.map((status) => (
          <Dropdown.Item
            key={status.id}
            onClick={() => onStatusClick(status.id, status.statusText)}
          >
            {status.statusText}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default StatusDropdown;
