import React, { useEffect, useState, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'react-bootstrap'

import { searchDeliveryChallans } from '../../../services/DeliveryChallanService'
import { DisableSidebar, PageLink, PageTitle } from '../../../_metronic/layout/core'
import { KTSVG } from '../../../_metronic/helpers'
import moment from 'moment'
import Pagination from '../../../lib/Pagination/Pagination'
import { PAGE_SIZE } from '../../../_shared'
import { LoadData, NoDataFound, ServerMessage } from '../../../lib/NoData'
import { IConfirmModel } from '../../../_models/confirm-model'
import { getCustomers } from '../../../services/CustomerService'
import { getDeliveryChallanCount } from '../../../services/CounterService'
import { Loader } from '../components/components'
import { CounterBox } from '../../../lib/CounterBox'

interface BigObject<T> {
  [index: string]: T
}

export function ListDeliveryChallan() {
  const [toggle, setToggle] = useState('')
  const [counters, setCounters] = React.useState<any>({})
  const [data, setData] = React.useState([])
  const [currentTableData, setCurrentTableData] = React.useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRows, setTotalRows] = React.useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showInvoiceModal, setShowInvoiceModal] = useState<boolean>(false)
  const [confirmModel, setConfirmModel] = useState<IConfirmModel>()
  const [filterValues, setFilterValues] = useState<any>({
    pageNumber: 1,
    pageSize: 100000,
    fromDate: '',
    toDate: '',
    createdBy: 0,
    status: 0,
    customerId: 0,
    dcFor: 0,
    filter: '',
  })

  const initialValues = {
    pageNumber: 1,
    pageSize: 100000,
    fromDate: '',
    toDate: '',
    createdBy: 0,
    status: 0,
    customerId: 0,
    dcFor: 0,
    filter: '',
  }

  const LOADINGSTAGES = {
    IDLE: 0,
    LOADING: 1,
    POSITIVE: 3,
    NEGATIVE: 4,
    ERROR: 5,
    EMPTY: 6,
  }
  const [loadingStage, setLoadingStage] = useState<any>(LOADINGSTAGES.IDLE)
  const [counterData, setCounterData] = React.useState([])

  const bindCounters = () => {
    getDeliveryChallanCount()
      .then((res: any) => {
        const datas = res.data.data
        setCounters(datas)

        const localArray: any = []
        let bigObject: BigObject<object> = datas
        Object.keys(bigObject).forEach((key) => {
          
          localArray.push({
            text: key === 'totalDeliveryChallanCounter' ? 'Total' : '',
            value: bigObject[key],
          })
        })
        setCounterData(localArray)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  const paginate = (pageNumber: any) => {
    setCurrentPage(pageNumber)
    const firstPageIndex = (pageNumber - 1) * PAGE_SIZE
    const lastPageIndex = firstPageIndex + PAGE_SIZE
    let _currentTableData = data.slice(firstPageIndex, lastPageIndex)
    setCurrentTableData(_currentTableData)
  }

  const [loadingStages, setLoadingStages] = useState(LOADINGSTAGES.IDLE)
  const bindGrid = (values: any) => {
    setIsLoading(true)
    setLoadingStage(LOADINGSTAGES.LOADING)
    setTimeout(() => {
      searchDeliveryChallans(values)
        .then((res: any) => {
          if (res.data.success) {
            let _data = res.data.data.data
            
            const firstPageIndex = (currentPage - 1) * PAGE_SIZE
            const lastPageIndex = firstPageIndex + PAGE_SIZE
            let _currentTableData = _data
            setData(_data)
            setTotalRows(_data.length)
            if (_data.length > 0) {
              setCurrentTableData(_currentTableData)
              setLoadingStage(LOADINGSTAGES.POSITIVE)
            } else {
              setLoadingStage(LOADINGSTAGES.NEGATIVE)
              setCurrentTableData([])
            }
          } else {
            setLoadingStages(LOADINGSTAGES.NEGATIVE)
            setCurrentTableData([])
          }
        })
        .catch((err) => {
          setLoadingStage(LOADINGSTAGES.ERROR)
          setCurrentTableData([])
        })
        .finally(() => {
          setIsLoading(false)
        })
    }, 1000)
  }

  const filterData = () => {
    // const arrayToint = filterValues.status.map((item: any) => parseInt(item));
    const values = {
      pageNumber: 1,
      pageSize: 100,
      fromDate: filterValues.fromDate,
      toDate: filterValues.toDate,
      createdBy: filterValues.createdBy,
      status: 1,
      customerId: filterValues.customerId || 0,
      dcFor: filterValues.dcFor,
      filter: filterValues.filter,
    }
    bindGrid(values)
  }

  const resetData = () => {
    IsProformaHandler('0')
    setFilterValues({ filter: '', customerId: 0 })
    setTimeout(() => {
      bindGrid(initialValues)
    }, 100)
  }

  const openDeleteModal = (id: any) => {
    if (id > 0) {
      const confirmModel: IConfirmModel = {
        title: 'Cancel Delivery Challan',
        btnText: 'Cancel Delivery Challan',
        deleteUrl: `invoices/cancel/${id}`,
        message: 'cancel-invoice',
      }

      setConfirmModel(confirmModel)
      setTimeout(() => {
        setShowConfirm(true)
      }, 500)
    }
  }

  const afterConfirm = (res: any) => {
    if (res) bindGrid(initialValues)

    setShowConfirm(false)
  }

  const afterInvoiceUpdate = (res: any) => {
    if (res) bindGrid(initialValues)

    setShowInvoiceModal(false)
  }

  const [customers, setCustomers] = useState<any>([])
  const bindCustomers = () => {
    getCustomers()
      .then((res: any) => {
        const data = res.data.data
        if (data.length > 0) {
          data.unshift({ id: 0, fullName: 'Select' })
          setCustomers(data)
        }
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  const IsProformaHandler = (value: any) => {
    setFilterValues({
      ...filterValues,
      isProforma: value === '1' ? true : false,
      isTax: value === '2' ? true : false,
    })
  }

  useEffect(() => {
    bindGrid(initialValues)
    bindCounters()
    bindCustomers()
  }, [])

  const profileBreadCrumbs: Array<PageLink> = [
    {
      title: 'Delivery Challan',
      path: '/deliverychallan',
      isSeparator: false,
      isActive: false,
    },
  ]
  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>Delivery Challan</PageTitle>
      <Fragment>
        <div className='card mb-5 mb-xl-10'>
          <div className='card-body pt-9 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
              <div className='flex-grow-1'>
                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                  <div className='d-flex justify-items-center'>
                    <CounterBox data={counterData} />
                  </div>

                  <div className='d-flex align-items-center'>
                    {/* <div className=''> */}
                    {/* begin::Filter Button */}
                    <button
                      disabled={isLoading}
                      type='button'
                      className='btn btn-sm btn-secondary me-3'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen031.svg'
                        className='svg-icon-2'
                      />
                      Filter
                    </button>
                    {/* end::Filter Button */}
                    {/* begin::SubMenu */}
                    <div
                      className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'
                      data-kt-menu='true'
                    >
                      {/* begin::Header */}
                      <div className='px-7 py-5'>
                        <div className='fs-5 text-dark'>Filter Options</div>
                      </div>
                      {/* end::Header */}

                      {/* begin::Separator */}
                      <div className='separator border-gray-200'></div>
                      {/* end::Separator */}

                      {/* begin::Content */}
                      <div className='px-7 py-5' data-kt-user-table-filter='form'>
                        {/* begin::Input group */}

                        <div className='mb-10'>
                          <label className='form-label fs-6 fw-bold'>Customer :</label>
                          <select
                            className='form-select form-select-solid'
                            data-kt-select2='true'
                            data-placeholder='Select Customer'
                            data-allow-clear='true'
                            data-kt-user-table-filter='two-step'
                            data-hide-search='true'
                            id='customerId'
                            name='customerId'
                            value={filterValues.customerId}
                            onChange={(e) => {
                              setFilterValues({ ...filterValues, customerId: e.target.value })
                            }}
                          >
                            {customers.map((item: any) => (
                              <option key={item.id} value={item.id}>
                                {item.fullName}
                              </option>
                            ))}
                          </select>
                        </div>
                        {/* end::Input group */}

                        {/* begin::Input group */}
                        {/* begin::Input group */}
                        <div className='mb-10'>
                          <label className='form-label fs-6 fw-bold'>Type:</label>
                          <select
                            className='form-select form-select-solid'
                            data-kt-select2='true'
                            data-placeholder='Select option'
                            data-allow-clear='true'
                            data-kt-user-table-filter='two-step'
                            data-hide-search='true'
                            name='isProforma'
                            onChange={(e) => IsProformaHandler(e.target.value)}
                            value={filterValues.isProforma ? '1' : filterValues.isTax ? '2' : '0'}
                          >
                            <option value='0'>Select</option>
                            <option value='1'>Proforma</option>
                            <option value='2'>Tax</option>
                            {/* <option value='3'>Both</option> */}
                          </select>
                        </div>
                        {/* end::Input group */}

                        {/* begin::Input group */}
                        <div className='mb-10'>
                          <label className='form-label fs-6 fw-bold'>Keyword:</label>
                          <input
                            className='form-control'
                            placeholder='Keyword'
                            name='filter'
                            id='filter'
                            value={filterValues.filter}
                            onChange={(e) =>
                              setFilterValues({ ...filterValues, filter: e.target.value })
                            }
                          />
                        </div>
                        {/* end::Input group */}

                        {/* begin::Actions */}

                        <div className='d-flex justify-content-end'>
                          <button
                            type='button'
                            disabled={isLoading}
                            onClick={resetData}
                            className='btn btn-sm btn-light btn-active-light-primary fw-bold me-2 px-6'
                            data-kt-menu-dismiss='true'
                            data-kt-user-table-filter='reset'
                          >
                            Reset
                          </button>
                          <button
                            disabled={isLoading}
                            type='button'
                            onClick={filterData}
                            className='btn btn-sm btn-primary fw-bold px-6'
                            data-kt-menu-dismiss='true'
                            data-kt-user-table-filter='filter'
                          >
                            Apply
                          </button>
                        </div>
                        {/* end::Actions */}
                      </div>
                      {/* end::Content */}
                    </div>
                    {/* end::SubMenu */}
                    <Link to='add'>
                      <button
                        className='btn btn-sm btn-primary me-3'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_offer_a_deal'
                      >
                        Add New
                      </button>
                    </Link>
                    {/* <a href='#' className='btn btn-sm btn-secondary me-2' id='kt_import_toggle'>
                                        <KTSVG
                                            path='/media/icons/duotune/arrows/arr012.svg'
                                            className='svg-icon-3 d-none'
                                        />
                                        <span className='indicator-label'>Import</span>
                                    </a> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loadingStage === LOADINGSTAGES.LOADING && <Loader />}
        {loadingStage === LOADINGSTAGES.NEGATIVE && <ServerMessage message='No Data Found' />}
        {loadingStage === LOADINGSTAGES.ERROR && (
          <ServerMessage message='Oops! server issue occured' />
        )}
        {loadingStage === LOADINGSTAGES.POSITIVE && (
          <div className='row'>
            <div className='col-xl-12 col-md-12'>
              <div className='card'>
                <div className='card-body'>
                  <div className='table-responsive'>
                    <div className='display mb-4 dataTablesCard customer-list-table'>
                      <Table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                        <thead>
                          <tr className='text-start text-muted fs-7 text-uppercase gs-0'>
                            <th>Challan Number</th>
                            <th>Name</th>
                            <th>Challan Date</th>
                            <th>Vehicle No</th>
                            <th className='text-end'>Total Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentTableData.map((item: any, index: any) => (
                            <tr key={index}>
                              <td className='text-gray-600 fw-bold'>
                                <div className='d-flex align-items-center justify-content-start text-center'>
                                  <div className='d-flex flex-column'>
                                    <Link
                                      to={`/deliverychallan/details/${item.id}/${item.customerId}`}
                                      className='text-dark fw-bold text-center text-primary text-hover-secondary mb-1'
                                    >
                                      {item.challanNumber}
                                    </Link>
                                  </div>
                                </div>
                              </td>

                              <td>{item.billName}</td>
                              <td>
                                {moment(item.challanDate).format('DD-MM-yyyy')}
                              </td>
                              <td>
                                {item.vehicleNo}
                              </td>
                              <td className='text-end text-dark fw-bold text-end'>
                                <div>{item?.totalAmount.toFixed(2)}</div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <Pagination
                        className='pagination-bar'
                        currentPage={currentPage}
                        totalCount={totalRows}
                        pageSize={PAGE_SIZE}
                        onPageChange={(page: any) => paginate(page)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>

      {/* {showInvoiceModal && <InvoiceAddModal setShowInvoiceModal={setShowInvoiceModal} afterInvoiceUpdate={afterInvoiceUpdate} />}
        {showConfirm && <ConfirmBox confirmModel={confirmModel} afterConfirm={afterConfirm} />}
    
     */}
    </>
  )
}
