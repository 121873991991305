import React, { useEffect, useRef, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate, useParams } from 'react-router-dom'
import Barcode from 'react-barcode'
import ReactToPrint from 'react-to-print'

const pageStyle = `
@page{
    size: 30mm 20mm
};

@media all {
.pageBreak{
    display: none;
 };

 @media print {
    .pageBreak{
        page-break-before: always;
     };
    
 }
}
`

const BarcodePrint = (props) => {
    const navigate = useNavigate();
    const { code } = useParams()
    const ref = useRef();
    const [codeArr, setCodeArr] = useState([]);

    useEffect(() => {
        var localArr = [];
        for (let i = 0; i < 6; i++) {
            localArr.push(i);
        }
        setCodeArr(localArr);
    }, []);

    return (
        <>
            <div className='card'>
                <div className="card-header">
                    <div className='card-title mt-2'>
                        <ReactToPrint
                            trigger={() => <button className='btn btn-sm btn-primary btn-active-primary-light px-4 me-2 w-100px'><i className='bi bi-printer'></i> Print</button>}
                            content={() => ref.current}
                            pageStyle={pageStyle}
                        />
                        <button className='btn btn-sm btn-light btn-active-secondary  px-4 me-2 w-100px' onClick={() => navigate(-1)}>Cancel</button>
                    </div>
                </div>
                <div className="card-body">
                    <div className='row' ref={ref}>
                        {codeArr && codeArr.length > 0 && codeArr.map((item, index) => (
                            <div className='col' style={{ paddingTop: '36pt', paddingLeft:'28pt' }}>

                                <Barcode width={1} height={40} value={code || ''} />
                            </div>
                        ))}

                    </div>

                </div>
            </div>
        </>
    )
}
export { BarcodePrint }
