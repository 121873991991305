import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { getDocumentLabels } from '../../../../services/CommonService';
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { Loader, LOADINGSTAGES, N2WIndian } from "../../components/components";
import POEMail from './POEmail';
import { POShare } from './POShareModal';
import { getPoHtml, getPurchaseOrder } from '../../../../services/BackendService/PurchaseOrdersService';

const POTemplateSelect = (props: any) => {
    const navigate = useNavigate();
    const [showMail, setShowMail] = useState<any>(false);
    const { setShowInvoiceModal, invoiceId, companyCode } = props;
    const [isLoading, setIsLoading] = useState<any>(invoiceId || 0);
    const [invHtml, setInvHtml] = useState<any>('loading...');
    const [template, setTemplate] = useState<any>('PurchaseOrder');
    const [docLabel, setDocLabel] = useState<any>('Original Copy');
    const [labelArr, setLabelArr] = useState<any[]>([]);
    const [showShare, setShowShare] = useState<any>(false);
    const [loadingStages, setLoadingStages] = useState<any>(LOADINGSTAGES.IDLE);
    const [data, setData] = useState<any>(null);
    const [isCancelled, setIsCancelled] = useState<any>(false);
    const [totalAmount, setTotalAmount] = useState<any>(null);
    const [amtInWords, setAmtInWords] = useState<any>(null);
    const [saveInitialData, setSaveInitialData] = useState<any>({});
    const toggleShowShare = () => {
        setShowShare(true);
    }
    const toggleShowEmail = () => {
        setShowMail(true);
    }
    const [adressBillBy, setAddressBillBy] = useState<any>({
        companyName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        gstin: '',
        mobile: '',
        email: '',
    });

    const [adressShipTo, setAddressShipTo] = useState<any>({
        billName: '',
        billAddress: '',
        billCity: '',
        billState: '',
        billPostalCode: '',
        gstin: '',
        contactNo: '',
        email: '',
    });

    const setShowShareModal = () => {
        setShowShare(false);
    }

    const [mailDetails, setMailDetails] = useState<any>({
        to: '',
        cc: '',
        bcc: '',
        subject: '',
        message: ''
    })

    const closeModal = () => {
        if (setShowInvoiceModal) {
            setShowInvoiceModal(false);
        } else {
            { navigate('/invoices'); }
        }
    }

    const bindDetailsEmail = (values: any) => {
        setLoadingStages(LOADINGSTAGES.LOADING);
        setIsLoading(true);
        setTimeout(() => {
            getPurchaseOrder(values)
                .then((res: any) => {
                    if (res.data.success) {
                        let _data = res.data.data;
                        setData(_data);

                        setMailDetails({
                            to: _data.email,
                            subject: `Invoice ${_data.invoiceNumber}`,
                            message: `Dear ${_data.billName},\n\nPlease find the attached invoice for your reference.\n\nThanks & Regards,\n${_data.organization.companyName}`,
                            invoiceNo: _data.invoiceNumber,
                            customerName: _data.billName,
                            invoiceId: _data.id
                        })
                        setIsCancelled(_data.isCancel);
                        setTotalAmount(_data.totalAmount);
                        if (_data.totalAmount.toString().includes('.')) {
                            const [r, p] = _data.totalAmount.toString().split('.');
                            setAmtInWords(`${N2WIndian.convert(r)} Rupees and ${N2WIndian.convert(p)} Paisa`);
                        }
                        else {
                            setAmtInWords(`${N2WIndian.convert(_data.totalAmount.toString())} Rupees`);
                        }
                        setAddressBillBy({
                            companyName: _data.organization.companyName,
                            addressLine1: _data.organization?.addressLine1,
                            addressLine2: _data.organization?.addressLine2,
                            city: _data.organization?.city,
                            state: _data.organization?.state,
                            postalCode: _data.organization?.postalCode,
                            gstin: _data.organization?.gstin,
                            mobile: _data.organization?.mobile,
                            email: _data.organization?.email
                        });

                        setSaveInitialData({
                            billBy: {
                                companyName: _data.organization.companyName,
                                addressLine1: _data.organization?.addressLine1,
                                addressLine2: _data.organization?.addressLine2,
                                city: _data.organization?.city,
                                state: _data.organization?.state,
                                postalCode: _data.organization?.postalCode,
                                gstin: _data.organization?.gstin,
                                mobile: _data.organization?.mobile,
                                email: _data.organization?.email
                            },
                            billTo: {
                                billName: _data.billName,
                                billAddress: _data.billAddress,
                                billCity: _data.billCity,
                                billState: _data.billState,
                                billPostalCode: _data.billPostalCode,
                                gstin: _data.gstin,
                                contactNo: _data.contactNo,
                                email: _data.customerEmail
                            }
                        });
                        setAddressShipTo({
                            billName: _data.billName,
                            billAddress: _data.billAddress,
                            billCity: _data.billCity,
                            billState: _data.billState,
                            billPostalCode: _data.billPostalCode,
                            gstin: _data.gstin,
                            contactNo: _data.contactNo,
                            email: _data.customerEmail
                        });
                        setIsLoading(false);
                        setLoadingStages(LOADINGSTAGES.POSITIVE);
                    }
                    else {
                        setLoadingStages(LOADINGSTAGES.NEGATIVE);
                    }
                })
                .catch(() => {
                    setLoadingStages(LOADINGSTAGES.ERROR);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }, 100);
    };

    const bindDetails = (invoiceId: any) => {
        setIsLoading(true);
        setTimeout(() => {
            getPoHtml(invoiceId, template, docLabel, companyCode)
                .then((res: any) => {
                    let _data = res.data.data;
                    setInvHtml(_data);
                    setIsLoading(false);
                })
                .catch(() => {
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }, 100);
    };

    const bindLabels = () => {
        setIsLoading(true);
        setTimeout(() => {
            getDocumentLabels()
                .then((res: any) => {
                    let _data = res.data.data;
                    setLabelArr(_data);
                    setIsLoading(false);
                })
                .catch(() => {
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }, 100);
    };

    useEffect(() => {
        if (invoiceId > 0) bindDetails(invoiceId);
        //bindDetailsEmail(invoiceId);
    }, []);

    useEffect(() => {
        if (invoiceId > 0) bindDetails(invoiceId);
    }, [docLabel]);


    useEffect(() => {
        bindLabels();
        bindDetails(invoiceId);
    }, [template])

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered modal-fullscreen'>
                    {/* begin::Modal content */}
                    <div className='modal-content'>
                        <div className='modal-header'>
                            {/* begin::Modal title */}
                            <h2 className='fw-bolder'>Preview Purchase Order</h2>
                            {/* end::Modal title */}

                            {/* begin::Close */}
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                onClick={() => closeModal()}
                                style={{ cursor: 'pointer' }}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                            </div>
                            {/* end::Close */}
                        </div>
                        <div className='modal-body'>

                            <div className='row'>
                                <div className='col-md-3'>
                                    <div className="row mb-10">
                                        <div className="col-xs-12 col-md-12">
                                            <div className="notice d-flex bg-light-primary rounded border-primary-light border p-6 mb-3">
                                                <img src={toAbsoluteUrl("/media/icons/duotune/communication/com007.svg")} className='svg-icon-2' />
                                                <div className="d-flex flex-stack flex-grow-1" title='Info'>
                                                    <div className="">
                                                        <div className="fs-6 text-gray-700 px-5">
                                                            If you're not happy with the available templates, kindly send us an email with a sample template, and we will make an effort to include it for you.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='fv-row mb-10'>
                                        <div className='mb-0 fv-row'>
                                            <label className='d-flex align-items-center form-label mb-5'>
                                                Select invoice template
                                                <i
                                                    className='fas fa-exclamation-circle ms-2 fs-7'
                                                    data-bs-toggle='tooltip'
                                                    title='Select invoice template of your choice'
                                                ></i>
                                            </label>

                                            <div className='mb-0'>
                                                <label className='d-flex flex-stack mb-5 cursor-pointer'>
                                                    <span className='d-flex align-items-center me-2'>
                                                        <span className='symbol symbol-50px me-6'>
                                                            <span className='symbol-label'>
                                                                <KTSVG
                                                                    path='/media/icons/duotune/arrows/arr001.svg'
                                                                    className='svg-icon-1 svg-icon-gray-600'
                                                                />
                                                            </span>
                                                        </span>

                                                        <span className='d-flex flex-column'>
                                                            <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                                                                Classic
                                                            </span>
                                                            <span className='fs-6 fw-bold text-gray-400'>
                                                                Plain old school invoice template
                                                            </span>
                                                        </span>
                                                    </span>

                                                    <span className='form-check form-check-custom form-check-solid'>
                                                        <input className='form-check-input' type='radio' name='template' value={template} onChange={() => setTemplate('PurchaseOrder')}
                                                            checked={template === 'classic'}
                                                        />
                                                    </span>
                                                </label>

                                                {/* <label className='d-flex flex-stack mb-5 cursor-pointer'>
                                                    <span className='d-flex align-items-center me-2'>
                                                        <span className='symbol symbol-50px me-6'>
                                                            <span className='symbol-label'>
                                                                <KTSVG
                                                                    path='/media/icons/duotune/arrows/arr001.svg'
                                                                    className='svg-icon-1 svg-icon-gray-600'
                                                                />
                                                            </span>
                                                        </span>

                                                        <span className='d-flex flex-column'>
                                                            <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                                                                Mini
                                                            </span>
                                                            <span className='fs-6 fw-bold text-gray-400'>Simple and straightforward design with just the essential information </span>
                                                        </span>
                                                    </span>

                                                    <span className='form-check form-check-custom form-check-solid'>
                                                        <input className='form-check-input' type='radio' name='template' value={template} onChange={() => setTemplate('invoice-simple')} />
                                                    </span>
                                                </label> */}

                                                {/* <label className='d-flex flex-stack mb-5 cursor-pointer'>
                                                    <span className='d-flex align-items-center me-2'>
                                                        <span className='symbol symbol-50px me-6'>
                                                            <span className='symbol-label'>
                                                                <KTSVG
                                                                    path='/media/icons/duotune/arrows/arr001.svg'
                                                                    className='svg-icon-1 svg-icon-gray-600'
                                                                />
                                                            </span>
                                                        </span>

                                                        <span className='d-flex flex-column'>
                                                            <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                                                                Modern
                                                            </span>
                                                            <span className='fs-6 fw-bold text-gray-400'>Impress clients with a beautiful, modern invoice</span>
                                                        </span>
                                                    </span>

                                                    <span className='form-check form-check-custom form-check-solid'>
                                                        <input className='form-check-input' type='radio' name='template' value={template} onChange={() => setTemplate('modern')} />
                                                    </span>
                                                </label> */}

                                                {/* <label className='d-flex flex-stack mb-0 cursor-pointer'>
                                                    <span className='d-flex align-items-center me-2'>
                                                        <span className='symbol symbol-50px me-6'>
                                                            <span className='symbol-label'>
                                                                <KTSVG
                                                                    path='/media/icons/duotune/graphs/gra008.svg'
                                                                    className='svg-icon-1 svg-icon-gray-600'
                                                                />
                                                            </span>
                                                        </span>

                                                        <span className='d-flex flex-column'>
                                                            <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                                                                Custom
                                                            </span>
                                                            <span className='fs-6 fw-bold text-gray-400'>
                                                                Use images to enhance time travel rivers
                                                            </span>
                                                        </span>
                                                    </span>

                                                    <span className='form-check form-check-custom form-check-solid'>
                                                        <input className='form-check-input' type='radio' name='template' value={template} onChange={() => setTemplate('custom')} />
                                                    </span>
                                                </label> */}
                                            </div>
                                        </div>

                                    </div>

                                    {/* <div className='fv-row mb-10'>
                                        <div className='mb-0 fv-row'>
                                            <label className='d-flex align-items-center form-label mb-5'>
                                                Document label
                                                <i
                                                    className='fas fa-exclamation-circle ms-2 fs-7'
                                                    data-bs-toggle='tooltip'
                                                    title='Document label'
                                                ></i>
                                            </label>
                                            <div>
                                                <select className='form-control' onChange={(e) => setDocLabel(e.target.value)}>
                                                    {labelArr && labelArr.map((lbl: any, index: number) => (
                                                        <option value={lbl.labelText}>{lbl.labelText}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div> */}

                                </div>
                                <div className='col-md-9'>
                                    <div className="bg-light h-100 rounded">
                                        <div className='px-20 py-10'>
                                            <div className='row'>
                                                <div className='col-md-12 py-5'>
                                                    <a target={"_blank"} href={`${process.env.REACT_APP_API_URL}/purchaseOrders/pdf/${invoiceId}/${companyCode}/${template}`}
                                                        className={`btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1`}
                                                        id='kt_charts_widget_2_year_btn'
                                                    >
                                                        <i className='fas fa-print'></i> PDF
                                                    </a>
                                                    {/* <a href="#"
                                                        onClick={() => toggleShowShare()}
                                                        className={`btn btn-sm btn-color-muted btn-active btn-active-secondary active px-4 me-1`}
                                                        id='kt_charts_widget_2_year_btn'
                                                    >
                                                        <i className='fas fa-share'></i> Share
                                                    </a> */}
                                                    {/* <a href="#"
                                                        onClick={() => toggleShowEmail()}
                                                        className={`btn btn-sm
                                                         btn-color-muted btn-active btn-active-secondary active px-4 me-1`}
                                                        id='kt_charts_widget_2_year_btn'
                                                    >
                                                        <i className='fas fa-envelope'></i> Email
                                                    </a> */}
                                                </div>
                                            </div>
                                            <div className='card'>
                                                <div className='card-body'>
                                                    <div dangerouslySetInnerHTML={{ __html: invHtml }}>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* end::Modal body */}
                    </div>
                    {/* end::Modal content */}
                </div>
                {/* end::Modal dialog */}
            </div>
            {showShare && <POShare
                setShowShareModal={setShowShareModal} invoiceId={invoiceId} companyCode={companyCode} template={template} />}
            {/* begin::Modal Backdrop */}
            {/* //{showEmail && <POEMail setShowEmail={setShowEmail} invoiceId={invoiceId} companyCode={companyCode} template={template}/>} */}
            {showMail && <POEMail mailDetails={mailDetails} setShowEmail={setShowMail} handleClose={() => setShowMail(false)} data={data} />}
            <div className='modal-backdrop fade show'></div>
            {/* end::Modal Backdrop */}
        </>
    )
}

export { POTemplateSelect }
