import React, { useEffect, useState, Fragment } from 'react';
import { Link } from "react-router-dom";
import { Table } from 'react-bootstrap';
import { KTSVG } from '../../../_metronic/helpers';
import moment from 'moment';
import { PAGE_SIZE } from '../../../_shared';
import { NoDataFound, ServerMessage } from '../../../lib/NoData';
import { IConfirmModel } from '../../../_models/confirm-model';
import { getPurchaseOrderCounts } from "../../../services/CounterService"
import { PurchaseOrderAddModal } from './partials/PurchaseOrderModels';
import Pagination from '../../../lib/Pagination/Pagination';
import { searchPurchaseOrders } from '../../../services/BackendService/PurchaseOrdersService';
import { getSupplier } from '../../../services/BackendService/SupplierService';
import { breadcrumbs } from '../components/PageTitle';
import { Counter, FilterInput, FilterSelect, Loader, LOADINGSTAGES } from '../components/components';
import { PageTitle } from '../../../_metronic/layout/core';
import { CounterBox } from '../../../lib/CounterBox';

interface BigObject<T> {
    [index: string]: T
}

export function ListPurchaseOrder() {
    const [toggle, setToggle] = useState("");
    const [counters, setCounters] = React.useState<any>({});
    const [data, setData] = React.useState([]);
    const [currentTableData, setCurrentTableData] = React.useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = React.useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [status, setStatus] = React.useState<any>();

    const [keyword, setKeyword] = React.useState<any>("");
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [showInvoiceModal, setShowInvoiceModal] = useState<boolean>(false);
    const [confirmModel, setConfirmModel] = useState<IConfirmModel>();
    const [fieldValue, setFieldValue] = useState<any>({ leadStatusId: 0 });

    const [leadStatusIds, setLeadStatusIds] = useState<any>({
        leadStatusId: 0,
        indexId: 0
    });
    const [leadId, setLeadId] = useState<any>(0);
    const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>, leadId: any, indexId: any) => {
        setLeadId(leadId);
        setLeadStatusIds({
            leadStatusId: event.target.value,
            indexId: indexId
        });
        // updateLeadStatus(leadId, parseInt(event.target.value));
    };


    const initialValues = {
        pageNumber: 1,
        pageSize: 1000,
        fromDate: "",
        toDate: "",
        createdBy: 0,
        statusId: 0,
        branchId: 0,
        supplierId: 0,
        filter: ""
    }

    const [counterData, setCounterData] = React.useState([]);
    const bindCounters = () => {
        getPurchaseOrderCounts().then((res: any) => {
            const data = res.data.data;
            setCounters(data);

            const localArray: any = [];
            let bigObject: BigObject<object> = data;
            Object.keys(bigObject).forEach(key => {
                
                localArray.push({
                    text:
                        key === "total" ? "Total" :
                            key === "unPaid" ? "Unpaid" :
                                key === "partialPaid" ? "Partially paid" : "Paid",
                    value: bigObject[key]
                })
            })
            setCounterData(localArray);
        }).catch((err: any) => {
            console.log(err);
        })
    }

    const paginate = (pageNumber: any) => {
        setCurrentPage(pageNumber);
        const firstPageIndex = (pageNumber - 1) * PAGE_SIZE;
        const lastPageIndex = firstPageIndex + PAGE_SIZE;
        let _currentTableData = data.slice(firstPageIndex, lastPageIndex);
        setCurrentTableData(_currentTableData);
    };
    const [loadingStages, setLoadingStages] = useState(LOADINGSTAGES.IDLE);

    const bindGrid = (values: any) => {
        setIsLoading(true);
        setLoadingStages(LOADINGSTAGES.LOADING)
        setTimeout(() => {
            searchPurchaseOrders(values)
                .then((res: any) => {
                    if (res.data.success) {
                        let _data = res.data.data.data;
                        const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
                        const lastPageIndex = firstPageIndex + PAGE_SIZE;
                        let _currentTableData = _data.slice(firstPageIndex, lastPageIndex);
                        setData(_data);
                        setTotalRows(_data.length);
                        if (_data.length > 0) {
                            setCurrentTableData(_currentTableData);
                            setLoadingStages(LOADINGSTAGES.POSITIVE)
                        }
                    }
                    else {
                        setCurrentTableData([])
                        setLoadingStages(LOADINGSTAGES.NEGATIVE);
                    }
                })
                .catch(() => {
                    setCurrentTableData([]);
                    setLoadingStages(LOADINGSTAGES.ERROR);
                }).finally(() => {
                    setIsLoading(false);
                });
        }, 1000);
    };

    const resetData = () => {
        setStatus(0);
        setKeyword("");
        setFilterValues({ ...initialValues });
        bindGrid(initialValues);
    }
    const [filterValues, setFilterValues] = useState<any>({ ...initialValues });
    const filterData = () => {
        setIsLoading(true)
        setCurrentTableData([]);
        
        const values = {
            ...filterValues,
            supplierId: filterValues.supplierId ? filterValues.supplierId : 0,
            filter: keyword ?? ""
        }
        bindGrid(values);
    }

    const openDeleteModal = (id: any) => {
        if (id > 0) {
            const confirmModel: IConfirmModel = {
                title: 'Cancel Invoice',
                btnText: 'Cancel this invoice',
                deleteUrl: `quotations/cancel/${id}`,
                message: 'cancel-invoice',
            }

            setConfirmModel(confirmModel);
            setTimeout(() => {
                setShowConfirm(true)
            }, 500);
        }
    }
    const [suppliers, setSuppliers] = useState<any>([])
    const bindSupplier = () => {
        getSupplier().then((res: any) => {
            const data = res.data.data;
            if (data.length > 0) {
                const array: any = [];
                data.unshift({ id: 0, supplierName: "All" });
                data.map((item: any) => {
                    array.push({
                        id: item.id,
                        name: item.supplierName
                    })
                })
                setSuppliers(array);
            }
        }).catch((err: any) => {
            console.log(err);
        })
    }
    const afterConfirm = (res: any) => {
        if (res) bindGrid(initialValues);

        setShowConfirm(false);
    }

    const afterInvoiceUpdate = (res: any) => {
        if (res) bindGrid(initialValues);

        setShowInvoiceModal(false);
    }

    useEffect(() => {
        bindGrid(initialValues)
        bindCounters();
        bindSupplier();
    }, []);


    return (<>
        <PageTitle breadcrumbs={breadcrumbs({
            title: 'Orders',
            path: "/purchase-orders"
        })}
        >
            Purchase Orders
        </PageTitle>
        <Fragment>
            <div className='card mb-xl-5'>
                <div className='card-body pt-9 pb-0'>
                    <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                        <div className='flex-grow-1'>
                            <div className='d-flex justify-content-between flex-wrap mb-2'>
                                <div className='d-flex justify-items-center'>
                                    <CounterBox data={counterData} />
                                </div>
                                <div className='d-flex my-4 align-items-center'>
                                    {/* begin::Filter Button */}
                                    <button
                                        // disabled={isLoading}
                                        type='button'
                                        className='btn btn-sm btn-secondary me-3'
                                        data-kt-menu-trigger='click'
                                        data-kt-menu-placement='bottom-end'
                                    >
                                        <KTSVG path='/media/icons/duotune/general/gen031.svg' />
                                        Filter
                                    </button>
                                    {/* end::Filter Button */}
                                    {/* begin::SubMenu */}
                                    <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
                                        {/* begin::Header */}
                                        <div className='px-7 py-5'>
                                            <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
                                        </div>
                                        {/* end::Header */}

                                        {/* begin::Separator */}
                                        <div className='separator border-gray-200'></div>
                                        {/* end::Separator */}

                                        {/* begin::Content */}
                                        <div className='px-7 py-5' data-kt-user-table-filter='form'>
                                            {/* begin::Input group */}
                                            <div className='mb-10'>
                                                <FilterSelect
                                                    label="Supplier"
                                                    options={suppliers}
                                                    name="supplierId"
                                                    value={filterValues.supplierId}
                                                    onChange={(e: any) => {
                                                        setFilterValues({
                                                            ...filterValues,
                                                            supplierId: e.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className='mb-10'>
                                                <FilterInput
                                                    label="Keyword" name="keyword" value={keyword}
                                                    onChange={(e: any) => {
                                                        setKeyword(e.target.value)
                                                    }}
                                                />
                                            </div>
                                            {/* end::Input group */}

                                            {/* begin::Actions */}
                                            <div className='d-flex justify-content-end'>
                                                <button
                                                    type='button'
                                                    disabled={isLoading}
                                                    onClick={resetData}
                                                    className='btn btn-sm btn-light btn-active-light-primary fw-bold me-2 px-6'
                                                    data-kt-menu-dismiss='true'
                                                    data-kt-user-table-filter='reset'
                                                >
                                                    Reset
                                                </button>
                                                <button
                                                    disabled={isLoading}
                                                    type='button'
                                                    onClick={filterData}
                                                    className='btn btn-sm btn-primary fw-bold px-6'
                                                    data-kt-menu-dismiss='true'
                                                    data-kt-user-table-filter='filter'
                                                >
                                                    Apply
                                                </button>
                                            </div>
                                            {/* end::Actions */}
                                        </div>
                                        {/* end::Content */}
                                    </div>
                                    {/* end::SubMenu */}
                                    <Link to='p-cart'>
                                        <button
                                            className='btn btn-sm btn-primary me-3'
                                            data-bs-toggle='modal'
                                            data-bs-target='#kt_modal_offer_a_deal'
                                        >
                                            Add New
                                        </button></Link>
                                </div>
                            </div>
                            {/* begin::Row */}
                            <div className='d-flex flex-wrap flex-stack'>
                            </div>

                            {/* end::Row */}
                        </div>
                    </div>
                </div>
            </div>
            {loadingStages === LOADINGSTAGES.LOADING && <Loader />}
            {loadingStages === LOADINGSTAGES.NEGATIVE && <NoDataFound />}
            {loadingStages === LOADINGSTAGES.ERROR && <ServerMessage message="Someting went wrong" />}
            {loadingStages === LOADINGSTAGES.POSITIVE &&
                <div className='row'>
                    <div className="col-xs-12 col-md-12">
                        <div className="notice d-flex bg-light-primary rounded border-primary-light border p-6 mb-3">
                            {/* <i className="ki-duotone ki-information-5 fs-2tx text-primary me-4">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span></i> */}
                            <i className="bi bi-info-circle-fill fs-1 text-primary me-4"></i>
                            <div className="d-flex flex-stack flex-grow-1" title='Info'>
                                <div className="">
                                    <div className="fs-7 text-gray-700">Click on the PO Number to view PO details, edit PO, view pdf and send email.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <div className="display mb-4 dataTablesCard customer-list-table">
                                        <Table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                                            <thead>
                                                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                                    <th>Purchase Order Number</th>
                                                    <th>Supplier Name</th>
                                                    <th>Supplier Email</th>
                                                    <th>Date</th>
                                                    {/* <th>Bill Address</th> */}
                                                    <th className='text-end'>Tax </th>
                                                    {/* <th>Tax Amount</th> */}
                                                    <th className='text-end'>total Amount</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentTableData &&
                                                    currentTableData.length > 0 ?
                                                    currentTableData.map((item: any, index: any) =>
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="d-flex flex-column">
                                                                        <Link to={`/purchase-orders/details/${item.id}`} className="text-dark fw-bold
                                                                    text-hover-secondary mb-1 fs-6">{item.purchaseOrderNumber}</Link>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>{item.supplierName}</td>
                                                            <td>{item.supplierEmail}</td>
                                                            <td>{moment(item.purchaseOrderDate).format("DD-MM-yyyy")}</td>
                                                            {/* <td>{item.billAddress}</td> */}
                                                            <td className='text-gray-600 fw-bold text-end' >{item.taxAmount.toFixed(2)}</td>
                                                            {/* <td>{item.taxAmount}</td> */}

                                                            <td className='text-dark fw-bold text-end'>
                                                                <div>
                                                                    {item.totalAmount?.toFixed(2)}
                                                                </div>
                                                            </td>


                                                        </tr>
                                                    ) : (
                                                        <td colSpan={5}>
                                                            <NoDataFound isLoading={isLoading} />
                                                        </td>

                                                    )
                                                }

                                            </tbody>
                                        </Table>
                                        <Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage}
                                            totalCount={totalRows}
                                            pageSize={PAGE_SIZE}
                                            onPageChange={(page: any) => paginate(page)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </Fragment>
        {showInvoiceModal && <PurchaseOrderAddModal setShowInvoiceModal={setShowInvoiceModal} afterInvoiceUpdate={afterInvoiceUpdate} />}
    </>)
}