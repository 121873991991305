import React, { useState } from "react";
import { Link } from "react-router-dom";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import { DisableSidebar, PageLink, PageTitle } from "../../../_metronic/layout/core";
import { IConnectedAccounts, connectedAccounts } from "../../modules/accounts/components/settings/SettingsModel";

export function ReportIndex() {
    const [data, setData] = useState<IConnectedAccounts>(connectedAccounts)

    const updateData = (fieldsToUpdate: Partial<IConnectedAccounts>) => {
        const updatedData = { ...data, ...fieldsToUpdate }
        setData(updatedData)
    }

    const [loading, setLoading] = useState(false)

    const click = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }
    const profileBreadCrumbs: Array<PageLink> = [
        {
            title: 'Reports',
            path: '/reports',
            isSeparator: false,
            isActive: false,
        },
    ]
    return (
        <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Reports</PageTitle>
            <DisableSidebar>
                <div className='card mb-5 mb-xl-10'>
                    <div
                        className='card-header border-0 cursor-pointer'
                        role='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_account_connected_accounts'
                        aria-expanded='true'
                        aria-controls='kt_account_connected_accounts'
                    >
                        <div className='card-title m-0'>
                            <h3 className='fw-bolder m-0'>Reports</h3>
                        </div>
                    </div>

                    <div id='kt_account_connected_accounts' className='collapse show'>
                        <div className='card-body border-top p-9'>
                            <div className='notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6'>
                                <KTSVG
                                    path='/media/icons/duotune/art/art006.svg'
                                    className='svg-icon-2tx svg-icon-primary me-4'
                                />
                                <div className='d-flex flex-stack flex-grow-1'>
                                    <div className='fw-bold'>
                                        <div className='fs-6 text-gray-600'>
                                            Make accurate operational and strategic decisions with up to the minute, relevant and accurate data points.
                                            {/* <a href='#' className='fw-bolder'>
                                    Learn More
                                </a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='py-2'>
                                <div className='d-flex flex-stack'>
                                    <div className='d-flex'>

                                        <div className='d-flex flex-column'>
                                            <Link to='/reports/sales-summary' className='fs-5 text-dark text-hover-primary fw-bolder'>
                                                Sales summary
                                            </Link>
                                            <div className='fs-6  text-gray-600'>Track and analyze the sales performance, identify opportunities for growth, and make data-driven decisions to improve the sales effectiveness.</div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-end'>
                                        <Link to='/reports/sales-summary'>Generate</Link>
                                    </div>
                                </div>

                                <div className='separator separator-dashed my-5'></div>

                                <div className='d-flex flex-stack'>
                                    <div className='d-flex'>

                                        <div className='d-flex flex-column'>
                                            <Link to='/reports/tax-summary' className='fs-5 text-dark text-hover-primary fw-bolder'>
                                                Tax summary
                                            </Link>
                                            <div className='fs-6 text-gray-600'>Get the total amount of tax liability or tax payable, based on their taxable income or revenue, categorized into different tax slabs.</div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-end'>
                                        <Link to='/reports/tax-summary'>Generate</Link>
                                    </div>
                                </div>

                                <div className='separator separator-dashed my-5'></div>

                                <div className='d-flex flex-stack'>
                                    <div className='d-flex'>
                                        <div className='d-flex flex-column'>
                                            <Link to='/reports/hsn-summary' className='fs-5 text-dark text-hover-primary fw-bolder'>
                                                HSN summary
                                            </Link>
                                            <div className='fs-6 text-gray-600'> This report provides a detailed breakdown of the products sold or purchased and their corresponding HSN codes</div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-end'>
                                        <Link to='/reports/hsn-summary'>Generate</Link>
                                    </div>
                                </div>

                                <div className='separator separator-dashed my-5'></div>

                                <div className='d-flex flex-stack'>
                                    <div className='d-flex'>
                                        <div className='d-flex flex-column'>
                                            <Link to='/reports/hsn-summary' className='fs-5 text-dark text-hover-primary fw-bolder'>
                                                Low stock report
                                            </Link>
                                            <div className='fs-6 text-gray-600'>identify the items that are running low in stock and may need to be replenished soon</div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-end'>
                                        <Link to=''  aria-disabled>Coming soon</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button className='btn btn-light btn-active-light-primary me-2'>Discard</button>
                <button onClick={click} className='btn btn-primary'>
                    {!loading && 'Save Changes'}
                    {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div> */}
                    </div>
                </div>
            </DisableSidebar>
        </>)
}