import React, { useEffect, useState } from "react";

export function DateSelect(props: any) {
    const { inputDay } = props;

    const [formData, setFormData] = React.useState({
        day: inputDay == null ? new Date().getDate() : inputDay,
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear()
    });


    const handleChange = (e: any) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const [monthArr, setMonthArr] = useState<any>([]);

    const monthName = (mon: any) => {
        return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][mon - 1];
    }

    useEffect(() => {
        // bind months
        const localArr: any[] = [];
        for (var i = 1; i <= 12; i++) {
            localArr.push(i)
        }
        setMonthArr(localArr);
    }, []);

    useEffect(() => {
        if (props.onChange) {
            props.onChange(formData)
        }
    }, [formData.day, formData.month, formData.year])

    return <>
        <div >
            {/* begin::Toolbar */}
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                <input type={"number"} name="day" min={1} max={31} maxLength={2} className="form-control form-control-solid w-75px me-2" placeholder="DD"
                    value={formData.day}
                    onChange={(e) => {
                        handleChange(e);
                        //setDay(e.target.value)
                    }} />

                {/* <input type={"text"} min={1} max={12} maxLength={2} className="form-control  form-control-solid w-100px me-1" placeholder="MM"
                    value={monthName(month)}
                    onChange={(e) => setMonth(e.target.value)} /> */}
                <select
                    placeholder='Customer '
                    className={'form-control form-control-solid from-control-sm w-100px me-2'}
                    name="month"
                    value={formData.month}
                    onChange={(e) => {
                        handleChange(e);
                        // setMonth(e.target.value)
                    }}
                >
                    {monthArr && monthArr.map(((sta: any) =>
                        <option key={sta} value={sta}>{monthName(sta)}</option>
                    ))}
                </select>

                <input type={"number"}
                    min={formData.year - 1}
                    max={formData.year}
                    maxLength={4} className="form-control  form-control-solid w-100px me-2" placeholder="YYYY"
                    value={formData.year}
                    onChange={(e) => {
                        handleChange(e);
                        // setYear(e.target.value)
                    }}
                />
            </div>
            {/* end::Toolbar */}
        </div>
    </>
}