import React, { Fragment, useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { getBankById, saveSubScriptionDetails } from '../../../../services/account/BankService'
import { ErrorMessage, useField, useFormik } from 'formik'
import * as Yup from 'yup'
import { toastNotify } from '../../../../services/NotifyService'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'

type Props = {
    onClose: () => void
    open: boolean
}
// const modalStyle = {
//     position: "fixed",
//     top: "40%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     width: "700px",
//     height: "700px",
//     backgroundColor: "white",
//     border: "1px solid #ccc",
//     zIndex: 100,
//     borderRadius: "10px",
// };
const accountDetails = {
    accountNumber: '',
    accountName: '',
    ifsc: '',
    bankName: '',
    branchId: '',
    accountType: ''
}

export default function PurchaseSubModal(props: Props) {
    const navigate = useNavigate();
    const initialValues = {
        amount: 2500,
        transactionId: "",
        remarks: "",
        transactionDate: "",
    }
    const [bankInfo, setBankInfo] = useState(accountDetails);
    useEffect(() => {
        getBankById(2).then((res: any) => {
            if (res.data.success) {
                setBankInfo(res.data.data)
            }
        }
        ).catch((err: any) => {
            console.log(err);

        });
    }, [])

    const Schema = Yup.object().shape({
        amount: Yup.number()
            .required('Amount is required')
            .min(1, 'Amount must be greater than 0'),
        transactionId: Yup.string()
            .required('Transaction Id is required'),
        remarks: Yup.string(),
        transactionDate: Yup.string()
            .required('Transaction Date is required'),
    })
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: Schema,
        onSubmit: async (values) => {
            const tId = toast.loading('Saving Data...')
            try {
                const response = await saveSubScriptionDetails(values);
                if (response.data.success) {
                    toastNotify(tId, response); props.onClose()
                    navigate('/subscription-history')
                }
                toastNotify(tId, response);
            }
            catch (error) {
                const res = { success: false, data: { message: "something went wrong!" } }
                toastNotify(tId, res);
            }
        }
    });
  
    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                <div className='modal-dialog modal-xl' >
                    <form
                        className='form'
                        id='kt_modal_add_user_form'
                        onSubmit={formik.handleSubmit}
                    >
                        <div className='modal-dialog modal-md'>
                            <div className='modal-content'>
                                <div className='modal-header'>
                                    <h2 className='fw-bolder'>Buy Subscription</h2>
                                    <div
                                        className='btn btn-icon btn-sm  btn-active-icon-primary'
                                        data-kt-users-modal-action='close'
                                        onClick={() => props.onClose()}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                    </div>
                                </div>
                                <div className='modal-body'>
                                    <div className='card'>
                                        <div className='row'>
                                            <span className='text-gray-900 mb-3 fs-6'>
                                                You can send requested amount on below details. <br />
                                            </span>
                                            <div className='row justify-content-center my-2'>
                                                <div className='col-lg-5 mx-2'>
                                                    <Fragment>
                                                        <span className='text-gray-500'>Account Name:</span>
                                                        <strong className='text-gray-900'>
                                                            &nbsp; {bankInfo.accountName}
                                                        </strong>
                                                        <br />
                                                        <span className='text-gray-500'>Account No:</span>
                                                        <strong className='text-gray-900'>
                                                            &nbsp;{bankInfo.accountNumber}
                                                        </strong>
                                                        <br />
                                                        <span className='text-gray-500'>Account Type:</span>
                                                        <strong className='text-gray-900'>
                                                            &nbsp;{bankInfo.accountType}
                                                        </strong>
                                                    </Fragment>
                                                </div>
                                                <div className='col-lg-5 mx-2'>
                                                    <span className='text-gray-500'>IFSC Code:</span>
                                                    <strong className='text-gray-900'>
                                                        &nbsp;{bankInfo.ifsc}
                                                    </strong>
                                                    <br />
                                                    <span className='text-gray-500'>Bank Name:</span>
                                                    <strong className='text-gray-900'>
                                                        &nbsp;{bankInfo.bankName}
                                                    </strong>
                                                    <br />
                                                    <span className='text-gray-500'>Branch ID:</span>
                                                    <strong className='text-gray-900'>
                                                        &nbsp;{bankInfo.branchId}
                                                    </strong>
                                                </div>
                                            </div>
                                            {/* <div className='row justify-content-end'>
                                            <Link to='/subscription-history' className='btn btn-primary btn-sm'>
                                                View Previous
                                            </Link>
                                        </div> */}
                                        </div>
                                        <div className='separator separator-solid'></div>
                                        <div className='row my-10'>
                                            <span className='text-gray-900 mb-3 fs-6 mt-3'>
                                                Once payment is done, please share the details.
                                            </span>
                                            <br />
                                            <div className='row mt-5'>
                                                <div className='col-lg-12'>
                                                    <div className='row'>
                                                        <div className='col-lg-6'>
                                                            <label className='form-label'>Amount</label>
                                                            <input type='text' className='form-control form-control-solid' placeholder='Enter Amount'
                                                                {...formik.getFieldProps('amount')}
                                                            />
                                                            {formik.touched.amount && formik.errors.amount ?
                                                                <div className='text-danger'>{formik.errors.amount}</div> : null}

                                                        </div>
                                                        <div className='col-lg-6'>
                                                            <label className='form-label'>Transaction Date</label>
                                                            <input type='date' className='form-control form-control-solid'
                                                                placeholder='Enter Transaction Date'
                                                                {...formik.getFieldProps('transactionDate')}
                                                            />
                                                            {formik.touched.transactionDate && formik.errors.transactionDate ?
                                                                <div className='text-danger'>{formik.errors.transactionDate}</div> : null}
                                                        </div>
                                                    </div>


                                                    <label className='form-label'>Transaction Number</label>
                                                    <input type='text' className='form-control form-control-solid'
                                                        placeholder='Enter Transaction Id'
                                                        {...formik.getFieldProps('transactionId')}
                                                    />
                                                    {formik.touched.transactionId && formik.errors.transactionId ?
                                                        <div className='text-danger'>{formik.errors.transactionId}</div> : null}

                                                    <br />
                                                    <label className='form-label'>Remarks</label>
                                                    <input type='text' className='form-control form-control-solid'
                                                        placeholder='Enter Remarks'
                                                        {...formik.getFieldProps('remarks')}
                                                    />
                                                    {formik.touched.remarks && formik.errors.remarks ?
                                                        <div className='text-danger'>{formik.errors.remarks}</div> : null}

                                                    <br />

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button onClick={() => props.onClose()}
                                        type="button"
                                        className="btn btn-light"
                                        data-bs-dismiss="modal"
                                    >
                                        Cancel
                                    </button>
                                    <button type="submit" className="btn btn-danger"
                                        disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className='modal-backdrop fade show'></div>
        </>
    )
}
