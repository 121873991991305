import React from "react";
import { Highlights } from './Highlight';

export function Testimonials() {
    return (
        <>
            <section className="bg-gray-50 dark:bg-gray-800 mt-lg-10 mb-lg-10 pt-lg-20 pb-lg-20">
                <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-24 lg:px-6">
                    <figure className="max-w-screen-md mx-auto">
                        <svg className="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor" />
                        </svg>
                        <blockquote>
                            <p className="text-xl font-medium text-gray-900 md:text-2xl dark:text-white">"Amber Bills is just awesome. 
                            Its mobile accessibility is something I appreciate, as it allows me to generate and manage invoices while on the move. Perfect choice for your next financial management application."</p>
                        </blockquote>
                        <figcaption className="flex items-center justify-center mt-6 space-x-3">
                            <img className="w-16 rounded-full" src="/media/images/cotton-soft.jpg" alt="cotton soft" />
                            <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                                <div className="pr-3 text-gray-900 dark:text-white">Navneet Arora</div>
                                <div className="pl-3  font-light text-gray-500 ">MD at Cotton Soft</div>
                            </div>
                        </figcaption>
                    </figure>
                </div>
            </section>

            {/*begin::Testimonials Section*/}
            <div className="mt-20 mb-n20 position-relative z-index-2 d-none">
                {/*begin::Container*/}
                <div className="container">
                    {/*begin::Heading*/}
                    <div className="text-center mb-17">
                        {/*begin::Title*/}
                        <h3 className="fs-2hx text-dark mb-5" id="clients" data-kt-scroll-offset="{default: 125, lg: 150}">What Our Clients Say</h3>
                        {/*end::Title*/}
                        {/*begin::Description*/}
                        <div className="fs-5 text-muted fw-bold">Save thousands to millions of bucks by using single tool
                            <br />for different amazing and great useful admin</div>
                        {/*end::Description*/}
                    </div>
                    {/*end::Heading*/}
                    {/*begin::Row*/}
                    <div className="row g-lg-10 mb-10 mb-lg-20">
                        {/*begin::Col*/}
                        <div className="col-lg-4">
                            {/*begin::Testimonial*/}
                            <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                                {/*begin::Wrapper*/}
                                <div className="mb-7">
                                    {/*begin::Rating*/}
                                    <div className="rating mb-6">
                                        <div className="rating-label me-2 checked">
                                            <i className="bi bi-star-fill fs-5"></i>
                                        </div>
                                        <div className="rating-label me-2 checked">
                                            <i className="bi bi-star-fill fs-5"></i>
                                        </div>
                                        <div className="rating-label me-2 checked">
                                            <i className="bi bi-star-fill fs-5"></i>
                                        </div>
                                        <div className="rating-label me-2 checked">
                                            <i className="bi bi-star-fill fs-5"></i>
                                        </div>
                                        <div className="rating-label me-2 checked">
                                            <i className="bi bi-star-fill fs-5"></i>
                                        </div>
                                    </div>
                                    {/*end::Rating*/}
                                    {/*begin::Title*/}
                                    <div className="fs-2 fw-bold text-dark mb-3">This is by far the cleanest template
                                        <br />and the most well structured</div>
                                    {/*end::Title*/}
                                    {/*begin::Feedback*/}
                                    <div className="text-gray-500 fw-semibold fs-4">The most well thought out design theme I have ever used. The codes are up to tandard. The css styles are very clean. In fact the cleanest and the most up to standard I have ever seen.</div>
                                    {/*end::Feedback*/}
                                </div>
                                {/*end::Wrapper*/}
                                {/*begin::Author*/}
                                <div className="d-flex align-items-center">
                                    {/*begin::Avatar*/}
                                    <div className="symbol symbol-circle symbol-50px me-5">
                                        <img src="media/avatars/300-1.jpg" className="" alt="" />
                                    </div>
                                    {/*end::Avatar*/}
                                    {/*begin::Name*/}
                                    <div className="flex-grow-1">
                                        <a href="#" className="text-dark fw-bold text-hover-primary fs-6">Paul Miles</a>
                                        <span className="text-muted d-block fw-bold">Development Lead</span>
                                    </div>
                                    {/*end::Name*/}
                                </div>
                                {/*end::Author*/}
                            </div>
                            {/*end::Testimonial*/}
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className="col-lg-4">
                            {/*begin::Testimonial*/}
                            <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                                {/*begin::Wrapper*/}
                                <div className="mb-7">
                                    {/*begin::Rating*/}
                                    <div className="rating mb-6">
                                        <div className="rating-label me-2 checked">
                                            <i className="bi bi-star-fill fs-5"></i>
                                        </div>
                                        <div className="rating-label me-2 checked">
                                            <i className="bi bi-star-fill fs-5"></i>
                                        </div>
                                        <div className="rating-label me-2 checked">
                                            <i className="bi bi-star-fill fs-5"></i>
                                        </div>
                                        <div className="rating-label me-2 checked">
                                            <i className="bi bi-star-fill fs-5"></i>
                                        </div>
                                        <div className="rating-label me-2 checked">
                                            <i className="bi bi-star-fill fs-5"></i>
                                        </div>
                                    </div>
                                    {/*end::Rating*/}
                                    {/*begin::Title*/}
                                    <div className="fs-2 fw-bold text-dark mb-3">This is by far the cleanest template
                                        <br />and the most well structured</div>
                                    {/*end::Title*/}
                                    {/*begin::Feedback*/}
                                    <div className="text-gray-500 fw-semibold fs-4">The most well thought out design theme I have ever used. The codes are up to tandard. The css styles are very clean. In fact the cleanest and the most up to standard I have ever seen.</div>
                                    {/*end::Feedback*/}
                                </div>
                                {/*end::Wrapper*/}
                                {/*begin::Author*/}
                                <div className="d-flex align-items-center">
                                    {/*begin::Avatar*/}
                                    <div className="symbol symbol-circle symbol-50px me-5">
                                        <img src="media/avatars/300-2.jpg" className="" alt="" />
                                    </div>
                                    {/*end::Avatar*/}
                                    {/*begin::Name*/}
                                    <div className="flex-grow-1">
                                        <a href="#" className="text-dark fw-bold text-hover-primary fs-6">Janya Clebert</a>
                                        <span className="text-muted d-block fw-bold">Development Lead</span>
                                    </div>
                                    {/*end::Name*/}
                                </div>
                                {/*end::Author*/}
                            </div>
                            {/*end::Testimonial*/}
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className="col-lg-4">
                            {/*begin::Testimonial*/}
                            <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                                {/*begin::Wrapper*/}
                                <div className="mb-7">
                                    {/*begin::Rating*/}
                                    <div className="rating mb-6">
                                        <div className="rating-label me-2 checked">
                                            <i className="bi bi-star-fill fs-5"></i>
                                        </div>
                                        <div className="rating-label me-2 checked">
                                            <i className="bi bi-star-fill fs-5"></i>
                                        </div>
                                        <div className="rating-label me-2 checked">
                                            <i className="bi bi-star-fill fs-5"></i>
                                        </div>
                                        <div className="rating-label me-2 checked">
                                            <i className="bi bi-star-fill fs-5"></i>
                                        </div>
                                        <div className="rating-label me-2 checked">
                                            <i className="bi bi-star-fill fs-5"></i>
                                        </div>
                                    </div>
                                    {/*end::Rating*/}
                                    {/*begin::Title*/}
                                    <div className="fs-2 fw-bold text-dark mb-3">This is by far the cleanest template
                                        <br />and the most well structured</div>
                                    {/*end::Title*/}
                                    {/*begin::Feedback*/}
                                    <div className="text-gray-500 fw-semibold fs-4">The most well thought out design theme I have ever used. The codes are up to tandard. The css styles are very clean. In fact the cleanest and the most up to standard I have ever seen.</div>
                                    {/*end::Feedback*/}
                                </div>
                                {/*end::Wrapper*/}
                                {/*begin::Author*/}
                                <div className="d-flex align-items-center">
                                    {/*begin::Avatar*/}
                                    <div className="symbol symbol-circle symbol-50px me-5">
                                        <img src="media/avatars/300-16.jpg" className="" alt="" />
                                    </div>
                                    {/*end::Avatar*/}
                                    {/*begin::Name*/}
                                    <div className="flex-grow-1">
                                        <a href="#" className="text-dark fw-bold text-hover-primary fs-6">Steave Brown</a>
                                        <span className="text-muted d-block fw-bold">Development Lead</span>
                                    </div>
                                    {/*end::Name*/}
                                </div>
                                {/*end::Author*/}
                            </div>
                            {/*end::Testimonial*/}
                        </div>
                        {/*end::Col*/}
                    </div>
                    {/*end::Row*/}
                    {/*begin::Highlight*/}

                    <Highlights />
                    {/*end::Highlight*/}
                </div>
                {/*end::Container*/}
            </div>
            {/*end::Testimonials Section*/}
        </>
    )
}