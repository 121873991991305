import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { ServerMessage } from '../../../../../lib/NoData';
import { getBanksByCompanyId } from '../../../../../services/account/BankService';
import { Loader, LOADINGSTAGES } from '../../../components/components';

export  function Listbanks() {

    const [data, setData] = React.useState<any>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [laodingStages, setLoadingStages] = React.useState<number>(LOADINGSTAGES.IDLE);
    const bindbankDetails = (companyId:number) => {
        setLoading(true);
        setLoadingStages(LOADINGSTAGES.LOADING);
        setTimeout(() => {
            getBanksByCompanyId(companyId)
                .then((res: any) => {
                    if(res.data.success){
                        setData(res.data.data);
                        if(res.data.data.length === 0){
                            setLoadingStages(LOADINGSTAGES.NEGATIVE);
                        }
                        setLoadingStages(LOADINGSTAGES.POSITIVE);
                    }
                    else{
                        setLoadingStages(LOADINGSTAGES.NEGATIVE);
                    }
                })
                .catch(() => {
                    setLoadingStages(LOADINGSTAGES.ERROR);
                }
                ).finally(() => {
                    setLoading(false);
                });
        }, 500);
    }


    useEffect(() => {
        let cId = 0;
        bindbankDetails(cId);
    }, [])
  return (
    <>
        <div className="row">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header">
                        <head className="card-title">Bank Details</head>
                        <div className="card-toolbar">
                            <Link to="/account/bank/add" className="btn btn-primary">Add Bank</Link>
                        </div>
                    </div>
                    {laodingStages === LOADINGSTAGES.LOADING && <Loader />}
                    {laodingStages === LOADINGSTAGES.NEGATIVE && <ServerMessage message = "No Bank Information Found" />}
                    {laodingStages === LOADINGSTAGES.ERROR && <ServerMessage message = "Something went wrong" />}
                    {laodingStages === LOADINGSTAGES.POSITIVE &&
                    <div className="card-body">
                        <div className="table-responsive">  
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Bank Name</th>
                                        <th>Account Number</th>
                                        <th>Account Name</th>
                                        <th>Account Type</th>
                                        <th>Branch</th>
                                        <th>Swift Code</th>
                                        </tr>
                                </thead>
                                <tbody>
                                    {data.map((item: any) => (
                                        <tr key={item.id}>
                                            <td>
                                                <Link to={`/account/bank/edit/${item.id}`}>{item.bankName}</Link>                                                
                                            </td>   
                                            <td>{item.accountNumber}</td>
                                            <td>{item.accountName}</td>
                                            <td>{item.accountType}</td>
                                            <td>{item.branchId}</td>
                                            <td>{item.swiftCode}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>    
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    </>
  )
}
