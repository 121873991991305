import { toast } from "react-toastify";
import { deleteEntity } from "../services/CommonService";
import { toastNotify } from "../services/NotifyService";
import { KTSVG } from "../_metronic/helpers";

export function ConfirmBox(props: any) {
    const { confirmModel, afterConfirm } = props;

    const onCancel = () => {
        afterConfirm(false);
    }

    const onOk = () => {
        const id = toast.loading("Please wait...");
        setTimeout(() => { }, 1000);
        deleteEntity(confirmModel.deleteUrl)
            .then((res: any) => {
                toastNotify(id, res);
                
                if (res.data.success) {
                    afterConfirm(true);
                }
            })
            .catch(() => {
                const res = { data: { message: 'Something went wrong', success: false } };
                toastNotify(id, res);
            }).finally(() => {

            });
    }
    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_confiem_box'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-md'>
                    {/* begin::Modal content */}
                    <div className='modal-content'>
                        <div className='modal-header'>
                            {/* begin::Modal title */}
                            <h2 className='fw-bolder'>{confirmModel.title}</h2>
                            {/* end::Modal title */}

                            {/* begin::Close */}
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                onClick={() => afterConfirm(false)}
                                style={{ cursor: 'pointer' }}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                            </div>
                            {/* end::Close */}
                        </div>
                        <div className='modal-body'>
                            <div className="lh-lg">
                                {
                                    confirmModel.message === "delete-product" && (
                                        <>
                                            <p>This action <strong>cannot</strong> be undone.
                                                This will permanently delete the product, associated images (if any) etc. However, you will still be able to see this product in already created invoices.
                                            </p>
                                            <p>Please click the <strong>delete</strong> button to confirm.</p>
                                        </>
                                    )
                                }
                                {
                                    confirmModel.message === "delete-customer" && (
                                        <>
                                            <p>This action <strong>cannot</strong> be undone.
                                                This will permanently delete the customer, associated invoices (if any) etc.
                                            </p>
                                            <p>Please click the <strong>delete</strong> button to confirm.</p>
                                        </>
                                    )
                                }
                                {
                                    confirmModel.message === "cancel-invoice" && (
                                        <>
                                            <p>This action <strong>cannot</strong> be undone.
                                                This will cancel the current invoice, associated payment info (if any) etc.
                                            </p>
                                            <p>Please click the <strong>cancel</strong> button to confirm.</p>
                                        </>
                                    )
                                }
                            </div>


                        </div>
                        <div className="modal-footer">
                            <button onClick={() => onCancel()}
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                            >
                                Cancel
                            </button>
                            <button onClick={() => onOk()} type="button" className="btn btn-danger">
                                {confirmModel.btnText}
                            </button>
                        </div>
                        {/* end::Modal body */}
                    </div>
                    {/* end::Modal content */}
                </div>
                {/* end::Modal dialog */}
            </div>
            {/* begin::Modal Backdrop */}
            <div className='modal-backdrop fade show'></div>
            {/* end::Modal Backdrop */}
        </>

    )
}