import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;

  li {
    padding: 18px 16px;
    font-weight:500;
    font-size:15px;
  }

  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: #0D2538;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;

    li {
      color: #fff;
    }
  }
`;

const RightNav = ({ open }) => {
    return (
        <Ul open={open}>
            <li>
                <Link to={'/home'}>Home</Link>
            </li>
            <li>
                <a href="https://www.amberinfotech.com" target={'_blank'}>Company</a>
            </li>
            <li>
                <a href="#features">Features</a>
            </li>
            <li>
                <a href="#faqs">FAQs</a>
            </li>
            <li>
                <a href="https://www.amberinfotech.com/contact-us" target={'_blank'}>Contact</a>
            </li>
            <li>
                <Link to={'/auth/login'} className="btn btn-sm btn-secondary text-lg font-medium w-100px" style={{ marginTop: '-10px' }}>
                    Login
                </Link>

            </li>
            <li>
                <Link to={'/auth/registration'} className="btn btn-sm btn-primary text-lg font-medium mr-4 w-100px" style={{ marginTop: '-10px' }}>
                    Sign up
                </Link>
            </li>
        </Ul>
    )
}

export default RightNav