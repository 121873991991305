import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useLocation, useParams } from 'react-router-dom';
import { getCreditNote, updateCreditNote } from '../../../services/BackendService/CreditNoteService';
import AddressForm from '../invoices/partials/AddressForm';
import AddressList from '../invoices/partials/AddressList';
import { toastNotify } from '../../../services/NotifyService';
import { N2WIndian } from '../components/components';
import { getCompanyCode } from '../../../services/CommonService';
import { ListDebitNoteItems } from './d-nPartials/DebitNoteItems';

const profileBreadCrumbs: Array<PageLink> = [
    {
        title: 'Debit Notes',
        path: '/debit-notes ',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]
export function DebitNoteDetails(props: any) {

    const location = useLocation();
    const [itemIdForUpdate, setItemIdForUpdate] = useState<any>()
    const { id } = useParams();
    const [data, setData] = useState<any>(null);
    const [tab, setTab] = useState(1);
    const [companyCode, setCompanyCode] = useState<any>('');
    const [invoiceId, setInvoiceId] = useState<any>(id || 0);
    const [isLoading, setIsLoading] = useState<any>(id || 0);
    const [saveInitialData, setSaveInitialData] = useState<any>({});
    const [showTemplate, setShowTemplate] = useState<any>(false);
    const [showShare, setShowShare] = useState<any>(false);
    const [isCancelled, setIsCancelled] = useState<any>(false);
    const [showMail, setShowMail] = useState<any>(false);


    const [adressBillBy, setAddressBillBy] = useState<any>({
        companyName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        gstin: '',
        mobile: '',
        email: '',
    });
    const [adressShipTo, setAddressShipTo] = useState<any>({
        billName: '',
        billAddress: '',
        billCity: '',
        billState: '',
        billPostalCode: '',
        gstin: '',
        contactNo: '',
        email: '',
    });
    const bindDetails = (values: any) => {
        setIsLoading(true);
        setTimeout(() => {
            getCreditNote(values)
                .then((res: any) => {
                    let _data = res.data.data;
                    setData(_data);
                    setMailDetails({
                        to: _data.email,
                        subject: `Invoice ${_data.creditNoteNumber}`,
                        message: `Dear ${_data.billName},\n\nPlease find the attached credit note for your reference.\n\nThanks & Regards,\n${_data.organization.companyName}`,
                        invoiceNo: _data.creditNoteNumber,
                        customerName: _data.billName,
                        invoiceId : _data.id
                    })
                    setIsCancelled(_data.isCancel);
                    setTotalAmount(_data.totalAmount);
                    if (_data.totalAmount.toString().includes('.')) {
                        const [r, p] = _data.totalAmount.toString().split('.');
                        setAmtInWords(`${N2WIndian.convert(r)} Rupees and ${N2WIndian.convert(p)} Paisa`);
                    }
                    else {
                        setAmtInWords(`${N2WIndian.convert(_data.totalAmount.toString())} Rupees`);
                    }
                    setAddressBillBy({
                        companyName: _data.organization.companyName,
                        addressLine1: _data.organization?.addressLine1,
                        addressLine2: _data.organization?.addressLine2,
                        city: _data.organization?.city,
                        state: _data.organization?.state,
                        postalCode: _data.organization?.postalCode,
                        gstin: _data.organization?.gstin,
                        mobile: _data.organization?.mobile,
                        email: _data.organization?.email
                    });

                    setSaveInitialData({
                        billBy: {
                            companyName: _data.organization.companyName,
                            addressLine1: _data.organization?.addressLine1,
                            addressLine2: _data.organization?.addressLine2,
                            city: _data.organization?.city,
                            state: _data.organization?.state,
                            postalCode: _data.organization?.postalCode,
                            gstin: _data.organization?.gstin,
                            mobile: _data.organization?.mobile,
                            email: _data.organization?.email
                        },
                        billTo: {
                            billName: _data.billName,
                            billAddress: _data.billAddress,
                            billCity: _data.billCity,
                            billState: _data.billState,
                            billPostalCode: _data.billPostalCode,
                            gstin: _data.gstin,
                            contactNo: _data.contactNo,
                            email: _data.email
                        }
                    });
                    setAddressShipTo({
                        billName: _data.billName,
                        billAddress: _data.billAddress,
                        billCity: _data.billCity,
                        billState: _data.billState,
                        billPostalCode: _data.billPostalCode,
                        gstin: _data.gstin,
                        contactNo: _data.contactNo,
                        email: _data.email
                    });
                    setIsLoading(false);

                })
                .catch(() => {
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }, 100);
    };
    useEffect(() => {
        if (invoiceId > 0) {
            bindDetails(invoiceId);
        }
        setCompanyCode(getCompanyCode());
    }, []);
    const [addressForm, setAddressForm] = useState<any>({ type: '', status: null });
    const [editField, setEditField] = useState<any>({
        creditNoteDate: false,
        creditNoteDueDate: false,
        discount: false,
        addTax: false,
        invoiceNumber: false

    });

    const [invoiceEdited, setInvoiceEdited] = useState<any>(
        {
            creditNoteDate: data?.creditNoteDate,
            creditNoteDueDate: data?.creditNoteDueDate,
            creditNoteNumber: data?.creditNoteNumber,
            discount: data?.discount,
            subAmount: data?.subAmount,
            taxAmount: data?.taxAmount,
            addTax: data?.addTax,
        }
    );
    const [totalAmount, setTotalAmount] = useState<any>(null);
    const [amtInWords, setAmtInWords] = useState<any>(null);
    const [itemsData, setItemsData] = useState<any>({
        taxableAmount: 0,
        taxAmount: 0,
        totalAmount: 0,
    });
    const [isItemsEdited, setIsItemsEdited] = useState<any>(false);
    const updateItemsData = (data: any) => {
        if (data) {
            setItemsData(data);
            setIsItemsEdited(true);
        }
    }
    useEffect(() => {
        if (editField.discount) {
            data.discount = invoiceEdited.discount;
        }
        if (editField.addTax) {
            data.addTax = invoiceEdited?.addTax;
        }
        if (itemsData.taxableAmount) {
            data.subAmount = itemsData.taxableAmount;
        }
        if (itemsData.taxAmount) {
            data.taxAmount = itemsData.taxAmount;
        }
        if (itemsData.totalAmount) {
            setTotalAmount(itemsData.totalAmount);
        }
        if (editField.creditNoteNumber) {
            data.creditNoteNumber = invoiceEdited.creditNoteNumber;
        }
        let amt = ((Number(data?.taxAmount) + Number(data?.subAmount) + Number(data?.addTax)) - Number(data?.discount));
        if (amt >= 0) {
            setTotalAmount(amt);
            const [r, p] = amt.toString().split('.');
            if (amt.toString().includes('.')) {
                setAmtInWords(`${N2WIndian.convert(r)} Rupees and ${N2WIndian.convert(p)} Paisa`);
            }
            else {
                setAmtInWords(`${N2WIndian.convert(amt.toString())} Rupees`);
            }
        }
        else {
            setTotalAmount(amt);
            const [r, p] = amt.toString().split('.');
            if (amt.toString().includes('.')) {
                setAmtInWords(`Minus ${N2WIndian.convert(r)} Rupees and ${N2WIndian.convert(p)} Paisa`);
            }
            else {
                setAmtInWords(`Minus ${N2WIndian.convert(amt.toString())} Rupees`);
            }
        }
    }, [invoiceEdited.discount, itemsData.subAmount, itemsData.taxAmount, invoiceEdited.addTax, itemsData.totalAmount]);

    const [setCompanyAddress, setSetCompanyAddress] = useState<any>(
        {
            companyName: '',
            addressLine1: 'abc',
            addressLine2: 'abc',
            city: 'abc',
            state: 'abc',
            postalCode: 'abc',
            gstin: 'abc',
            mobile: 'abc',
            email: 'abc'
        }
    );
    const [setBillToAddress, setSetBillToAddress] = useState<any>({
        billName: 'abc',
        billAddress: 'abc',
        billCity: 'abc',
        billState: 'abc',
        billPostalCode: 'abc',
        gstin: 'abc',
        contactNo: 'abc',
        email: 'abc'
    });
    const UpdateInvoiceDetails = () => {
        const id = toast.loading("Please wait...");
        setIsLoading(true);
        if (editField.creditNoteNumber) {
            data.creditNoteNumber = invoiceEdited.creditNoteNumber;
        }
        if (invoiceEdited.creditNoteDate) {
            data.creditNoteDate = invoiceEdited.creditNoteDate;
        }
        if (invoiceEdited.creditNoteDueDate) {
            data.creditNoteDueDate = invoiceEdited.creditNoteDueDate;
        }
        if (invoiceEdited.discount) {
            data.discount = invoiceEdited.discount;
        }
        if (invoiceEdited.subAmount) {
            data.subAmount = invoiceEdited.subAmount;
        }
        if (invoiceEdited.taxAmount) {
            data.taxAmount = invoiceEdited.taxAmount;
        }
        if (invoiceEdited.addTax) {
            data.addTax = invoiceEdited.addTax;
        }
        if (totalAmount) {
            data.totalAmount = totalAmount;
        }
        if (amtInWords) {
            data.amountInWords = amtInWords;
        }
        if (invoiceId > 0) {
            data.invoiceId = invoiceId;
        }

        const __data = {
            ...data,
            // add invoiceBillBy as object in data
            creditNoteBillBy: {
                billName: adressBillBy.companyName,
                billAddress: adressBillBy.addressLine1 + " " + adressBillBy.addressLine2,
                billCity: adressBillBy.city,
                billState: adressBillBy?.state,
                billPostalCode: adressBillBy.postalCode,
                companyGstin: adressBillBy.gstin
            },
            // add invoiceShipTo as object in data
            creditNoteShipTo: {
                shipName: adressShipTo.billName,
                shipAddress: adressShipTo.billAddress,
                shipCity: adressShipTo.billCity,
                shipState: adressShipTo.billState,
                shipPostalCode: adressShipTo.billPostalCode,
                customerGstin: adressShipTo.gstin,
                customerEmail: adressShipTo.email,
                customerContactNo: adressShipTo.contactNo
            },
        }
        setTimeout(() => {
            // __data.totalAmount = totalAmount;
            // __data.amountInWords = amtInWords;
            updateCreditNote(__data, isItemsEdited ? "items" : "invoice")
                .then((res: any) => {
                    if (res.data.success) {
                        toastNotify(id, res);
                        bindDetails(invoiceId);
                    }
                    else {
                        toastNotify(id, res);
                    }
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setIsLoading(false);
                    toast.dismiss(id);
                });
        }, 1000);
    }
    const [mailDetails, setMailDetails] = useState<any>({
        to: '',
        cc: '',
        bcc: '',
        subject: '',
        message : ''
    })

    const toggleShowTemplate = (invoiceId: any) => {
        setInvoiceId(invoiceId);
        setShowTemplate(true);
    }

    const setShowInvoiceModal = () => {
        setShowTemplate(false);
    }

    const toggleShowShare = (invoiceId: any) => {
        setInvoiceId(invoiceId);
        setShowShare(true);
    }
    const toggleShowMail = (invoiceId: any) => {
        setInvoiceId(invoiceId);
        setShowMail(true);
    }

    const setShowShareModal = () => {
        setShowShare(false);
    }


    return (
            <> <PageTitle breadcrumbs={profileBreadCrumbs}>Debit Note Detail</PageTitle>
                <div className='card mb-5 mb-xl-10'>
                    <div className='card-body pt-9 pb-0'>
                        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                            <div className='flex-grow-1'>
                                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                                    <div className='d-flex flex-column'>
                                        <div className='d-flex align-items-center mb-2'>
                                            <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                                                Number#:{data?.creditNoteNumber}
                                            </a>
                                            {/* <a
                                                href='#'
                                                className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3'
                                                data-bs-toggle='modal'
                                                data-bs-target='#kt_modal_upgrade_plan'
                                            >
                                                active
                                            </a> */}
                                        </div>
                                    </div>
                                    <div className='d-flex my-4'>
                                        {companyCode != '' && (

                                            <a href="#"
                                                onClick={() => toggleShowTemplate(data?.id)}
                                                className={`btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1`}
                                                id='kt_charts_widget_2_year_btn'
                                            >
                                                <i className='fas fa-print'></i> Preview &amp; Print
                                            </a>
                                        )}
                                        {/* <a href='#' onClick={() => setItemIdForUpdate(1)} className='btn btn-sm btn-light me-2' id='kt_user_follow_button'>
                                            <KTSVG
                                                path='/media/icons/duotune/arrows/arr012.svg'
                                                className='svg-icon-3 d-none'
                                            />
                                            <span className='indicator-label'>Clone</span>
                                            <span className='indicator-progress'>
                                                Please wait...
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        </a> */}
                                        <Button onClick={() => UpdateInvoiceDetails()}
                                            className='btn btn-sm btn-light me-2'
                                            id='kt_user_follow_button'>
                                            <KTSVG
                                                path='/media/icons/duotune/arrows/arr012.svg'
                                                className='svg-icon-3 d-none'
                                            />
                                            Update
                                        </Button>
                                        {/* <FileInput/> */}
                                        {/* <a href="#"
                                            onClick={() => toggleShowShare(data?.id)}
                                            className={`btn btn-sm btn-color-muted btn-active btn-active-success active px-4 me-1`}
                                            id='kt_charts_widget_2_year_btn'
                                        >
                                            <i className='fas fa-share'></i> Share
                                        </a>
                                        <a href="#"
                                            onClick={() => toggleShowMail(data?.id)}
                                            className={`btn btn-sm btn-color-muted btn-active btn-active-info active px-4 me-1`}
                                            id='kt_charts_widget_2_year_btn'
                                        >
                                            <i className='fas fa-envelope'></i> Mail
                                        </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-12">
                                <div className='row mb-7'>
                                    <div className='col-lg-4'>
                                        <h6 className='fw-bold text-muted mb-1'>Bill By
                                            {isCancelled === false &&
                                                <i className=' text-info fas fa-edit px-15'
                                                    data-bs-toggle='modal' data-bs-target='#kt_modal_upgrade_plan'
                                                    onClick={() => setAddressForm({
                                                        type: 'billBy',
                                                        status: true
                                                    })}
                                                ></i>}
                                            < i className=' text-danger fas fa-details px-15'
                                                data-bs-toggle='modal' data-bs-target='#kt_modal_upgrade_plan'
                                                onClick={() => setAddressForm({
                                                    type: 'companyAddresses',
                                                    status: true
                                                })}
                                            ></i>
                                        </h6>
                                        {/* // edit Icon  */}
                                        <span className='fw-bolder fs-6 text-dark'>
                                            {adressBillBy.companyName}
                                        </span>
                                        <address>
                                            <p className='mb-0'>
                                                {adressBillBy?.addressLine1}
                                                {adressBillBy?.addressLine2} </p>
                                            <p className='mb-1'>{adressBillBy?.city} {adressBillBy?.state} {adressBillBy?.postalCode} </p>

                                            <p className='mb-0'>GSTIN: {adressBillBy?.gstin}</p>
                                            <p className='mb-0'>Mobile: {adressBillBy?.mobile}</p>
                                            <p className='mb-0'>Email: {adressBillBy?.email}</p>
                                        </address>
                                    </div>

                                    <div className='col-lg-4'>
                                        <h6 className=' fw-bold text-muted mb-1'>Bill To
                                            {isCancelled === false && <i className=' text-info fas fa-edit px-15' onClick={() => setAddressForm({
                                                type: 'billTo',
                                                status: true
                                            })}></i>}
                                        </h6>
                                        <span className='fw-bolder fs-6 text-dark'>{adressShipTo?.billName}</span>
                                        <address>
                                            <p className='mb-0'>{adressShipTo?.billAddress} {adressShipTo?.billCity} </p>
                                            <p className='mb-1'>{adressShipTo?.billState} {adressShipTo?.billPostalCode}</p>

                                            <p className='mb-0'>GSTIN: {adressShipTo?.gstin}</p>
                                            <p className='mb-0'>Contact No: {adressShipTo?.contactNo}</p>
                                            <p className='mb-0'>Email: {adressShipTo?.email}</p>
                                        </address>
                                    </div>
                                    <div className='col-lg-4'>
                                        <div >
                                            <h6 className=' fw-bold text-muted mb-1'>Debit Note Date</h6>
                                            {!editField.creditNoteDate ? (
                                                <>
                                                    <span className='fw-bolder fs-6 text-dark' >
                                                        {moment(data?.creditNoteDate).format('DD-MM-YYYY')}
                                                    </span>
                                                    {isCancelled === false && < i className=' text-info fas fa-edit px-15' onClick={() => setEditField({
                                                        ...editField,
                                                        creditNoteDate: !editField.creditNoteDate
                                                    })}></i>}
                                                </>
                                            )
                                                : (
                                                    <input type="date" className="form-control"
                                                        defaultValue={
                                                            moment(data?.creditNoteDate).format("YYYY-MM-DD")
                                                        }
                                                        value={
                                                            invoiceEdited.creditNoteDate
                                                        }
                                                        onChange={(e) => setInvoiceEdited({
                                                            ...invoiceEdited,
                                                            creditNoteDate: e.target.value
                                                        })} />

                                                )}
                                        </div>
                                        <div  >
                                            <h6 className=' fw-bold text-muted mb-1' style={{ marginTop: "1em" }}> Due Date</h6>
                                            {!editField.creditNoteDueDate ? (
                                                <>
                                                    <span className='fw-bolder fs-6 text-dark'>
                                                        {moment(data?.creditNoteDueDate).format("DD-MM-yyyy")}
                                                    </span>
                                                    {isCancelled === false && < i className=' text-info fas fa-edit px-15' onClick={() => setEditField({
                                                        ...editField,
                                                        creditNoteDueDate: !editField.creditNoteDueDate
                                                    })}>
                                                    </i>}
                                                </>
                                            )
                                                : (
                                                    <input type="date" className="form-control"
                                                        defaultValue={
                                                            moment(data?.creditNoteDueDate).format("YYYY-MM-DD")
                                                        }
                                                        value={
                                                            invoiceEdited.creditNoteDueDate
                                                        }
                                                        onChange={(e) => setInvoiceEdited({
                                                            ...invoiceEdited,
                                                            creditNoteDueDate: e.target.value
                                                        })} />
                                                )}
                                        </div>
                                        <div  >
                                            <h6 className=' fw-bold text-muted mb-1' style={{ marginTop: "1em" }}>
                                                 Number
                                            </h6>
                                            {!editField.creditNoteNumber ? (
                                                <>
                                                    <span className='fw-bolder fs-6 text-dark'>
                                                        {data?.creditNoteNumber}
                                                    </span>
                                                    {/* < i className=' text-info fas fa-edit px-15'
                                                        onClick={() => setEditField({
                                                            ...editField,
                                                            invoiceNumber: !editField.invoiceNumber
                                                        })}></i> */}
                                                    {isCancelled === false && < i className=' text-info fas fa-edit px-15'
                                                        onClick={() => setEditField({
                                                            ...editField,
                                                            creditNoteNumber: !editField.creditNoteNumber
                                                        })}></i>}
                                                </>
                                            )
                                                : (
                                                    <input type="numeric" className="form-control"
                                                        defaultValue={data?.creditNoteNumber}
                                                        value={
                                                            invoiceEdited.creditNoteNumber
                                                        }
                                                        onChange={(e) => setInvoiceEdited({
                                                            ...invoiceEdited,
                                                            creditNoteNumber: e.target.value
                                                        })} />
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-7'>
                                    <div className='col-lg-3'>
                                        <h6 className=' fw-bold text-muted mb-1'>Taxable Amount</h6>
                                        {/* {!editField.subAmount ? (
                                            <> */}
                                        <span className='fw-bolder fs-6 text-dark'>
                                            {data?.subAmount.toFixed(2)}
                                        </span>
                                        <br />
                                        <i>Discount:</i>
                                        {!editField.discount ? (
                                            <>
                                                <span className='fw-bolder fs-6 text-dark'> {data?.discount.toFixed(2)}</span>
                                                {/* {isCancelled===false&&<i className=' text-info fas fa-edit px-15' onClick={() => setEditField({
                                                    ...editField,
                                                    discount: !editField.discount
                                                })}></i>} */}
                                            </>
                                        )
                                            : (
                                                <input type="numeric" className="form-control"
                                                    defaultValue={
                                                        data?.discount

                                                    }
                                                    value={invoiceEdited?.discount}
                                                    onChange={(e) => setInvoiceEdited({
                                                        ...invoiceEdited,
                                                        discount: e.target.value
                                                    })} />
                                            )}
                                    </div>
                                    <div className='col-lg-3'>
                                        <h6 className=' fw-bold text-muted mb-1'>Tax Amount</h6>
                                        <span className='fw-bolder fs-6 text-dark'>{data?.taxAmount.toFixed(2)}</span>
                                    </div>
                                    <div className='col-lg-3'>
                                        <h6 className=' fw-bold text-muted mb-1'>Additional Tax</h6>
                                        {!editField.addTax ? (
                                            <>
                                                <span className='fw-bolder fs-6 text-dark'>{data?.addTax}</span>
                                                {isCancelled === false && < i className=' text-info fas fa-edit px-15' onClick={() => setEditField({
                                                    ...editField,
                                                    addTax: !editField.addTax
                                                })}></i>}
                                            </>
                                        ) : (
                                            <input type="numeric" className="form-control"
                                                defaultValue={
                                                    data?.addTax
                                                }
                                                value={invoiceEdited?.addTax}
                                                onChange={(e) => setInvoiceEdited({
                                                    ...invoiceEdited,
                                                    addTax: e.target.value
                                                })} />
                                        )}
                                    </div>
                                    <div className='col-lg-3'>
                                        <h6 className=' fw-bold text-muted mb-1'>Amount</h6>
                                        <span className='fw-bolder fs-6 text-dark'>{totalAmount}</span>
                                    </div>

                                </div>
                                <div className='row mb-7'>
                                    <div className='col-lg-9'>
                                        <h6 className=' fw-bold text-muted mb-1'>Amount in Words</h6>
                                        <span className='fw-bolder fs-6 text-dark text-capitalize'>{amtInWords}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mt-5'>
                            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                                <li className='nav-item'>
                                    <a onClick={() => setTab(1)}
                                        className="nav-link active"
                                        data-bs-toggle="tab"
                                        href="#"
                                    >
                                        Items
                                    </a>
                                </li>
                            </ul>
                            <div className="col-md-12 mt-5">
                                {tab == 1 && data && data.creditNoteItems?.length > 0 &&
                                    <ListDebitNoteItems invoiceId={id} data={data.creditNoteItems} isCancelled={isCancelled} setItemsData={updateItemsData} />}
                            </div>
                        </div>
                    </div>
                </div>
                {/* {itemIdForUpdate !== undefined && 
                <InvoiceCloneModal data={data} setItemIdForUpdate={setItemIdForUpdate} />} */}
                <AddressForm 
                id={data?.id}
                onUpdate={(data) => console.log(data)}
                initialData={saveInitialData} setBillBy={setAddressBillBy} setBillTo={setAddressShipTo}
                    type={addressForm.type} billBy={adressBillBy} billTo={adressShipTo} show={addressForm.status}
                    handleClose={() => setAddressForm(false)} />
                {addressForm.type === "companyAddresses" ?
                    <AddressList show={addressForm.status}
                        companyId={data?.companyId}
                        customerId={data?.customerId}
                        handleClose={() => setAddressForm(false)}
                    /> : null}
                {/* {showMail && <InvoiceMail mailDetails = {mailDetails} setShowEmail={setShowMail} handleClose={() => setShowMail(false)} data={data} />}
                {showTemplate && <InvoiceTemplateSelect setShowInvoiceModal={setShowInvoiceModal} invoiceId={invoiceId} companyCode={companyCode} />}
                {showShare && <InvoiceShare setShowShareModal={setShowShareModal} invoiceId={invoiceId} companyCode={companyCode} template={'classic'} />} */}
            </> 
    )
}
