import React, { useEffect, useRef } from 'react'

import { PageTitle } from '../../../_metronic/layout/core'
import {
  ListsWidget4,
  ListsWidget5,
  RecentCustomers,
} from '../../../_metronic/partials/widgets'

//import { OnboardingStepsPage } from './OnboardingSteps';
import { getTotalDashBoardCounter } from "../../../services/CommonService"
import { ShortCuts } from './partials/Shortcuts';
import { OnboardingStepsPage } from './OnboardingSteps';
import { PaymentGraph } from './partials/PaymentGraph';
import { SaleGraph } from './partials/SaleGraph';
import { RecentInvoicesDashBoard } from '../../../_metronic/partials/widgets/tables/RecentDashBoardInvoice';
import { Link } from 'react-router-dom';


const DashboardPage = (props: any) => (
  <>


    <div className='row gy-5 g-xl-10'>
      <div className='col-xl-8'>
        <OnboardingStepsPage />
      </div>
      <div className='col-xl-4'>
        <ShortCuts />
        <div className='card mt-10 mb-5'>
          <div className='card-body'>
            <div className='row'>
              <div className='col-md-5 col-sm-12'>
                <img src='/media/illustrations/sigma-1/20.png' style={{ verticalAlign: 'middle' }} height={120} />
              </div>
              <div className='col-md-7 col-sm-12 pt-10 '>

                <span style={{ display: 'inline' }} className='text-muted'>Need assistance with any task?</span>
                <h2>Write to us</h2>
                <div className='mt-3 me-2 fs-6'>
                  <i className="fas fa-envelope fs-4 me-3"></i><a href={'mailto:info@amberinfotech.com'}>info@amberinfotech.com</a>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div className='row gy-5 g-xl-10 mb-20'>
      <div className='col-xl-6 '>
        <SaleGraph />
      </div>
      <div className='col-xl-6'>
        <PaymentGraph />
      </div>
    </div>
    <div className='row gy-5 g-xl-10 mt-lg-10'>

      {/* <div className='col-xl-12'>
     
      </div> */}
    </div>
    <div className='row gy-5 g-xl-10'>
      {/*begin::Col*/}
      <div className='col-xl-6'>
        <RecentCustomers className='mb-6 mb-xl-10' memberCount={props?.customerCounter} />
      </div>


      <div className='col-xl-6'>
        <RecentInvoicesDashBoard className='mb-6 mb-xl-10' />
      </div>
      {/*end::Col*/}

      {/*begin::Col*/}
      {/* <div className='col-xl-4'>
        <MixedWidget14
          className='card-xl-stretch mb-xl-10'
          backGroundColor='#CBF0F4'
          chartHeight='100px'
          counters={props.counters}
        />
      </div> */}
      {/*end::Col*/}

      {/*begin::Col*/}
      {/* <div className='col-xl-4'>
        <MixedWidget15 className='card-xl-stretch mb-xl-10' backGroundColor='#CBD4F4' />
      </div> */}
      {/*end::Col*/}
    </div>
    {/*end::Row*/}



    <div style={{ display: 'none' }}>
      {/*begin::Row*/}
      <div className='row gy-5 g-xl-10'>
        {/*begin::Col*/}
        <div className='col-xxl-6'>
          <ListsWidget5 className='card-xl-stretch mb-xl-10' />
        </div>
        {/*end::Col*/}

        {/*begin::Col*/}
        <div className='col-xxl-6'>
          <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-10' items={5} />
        </div>
        {/*end::Col*/}
      </div>
    </div>

  </>
)

const DashboardWrapper = () => {
  const [counters, setCounters] = React.useState<any>({});

  const bindCounters = () => {
    getTotalDashBoardCounter().then((res: any) => {
      const data = res.data.data;
      setCounters(data);
    }).catch((err: any) => {
      console.log(err);
    })
  }

  useEffect(() => {
    setTimeout(() => {
      bindCounters();
    }, 600)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    // <EnableSidebar>
    <>

      <PageTitle description='Welcome to Amber Bills' breadcrumbs={[]}>
      </PageTitle>
      <DashboardPage counters={counters} />
    </>
    // </EnableSidebar>
  )
}

export { DashboardWrapper }
