import axiosInstance from './AxiosInstance';
export function getSuppliers() {
    return axiosInstance.get(`suppliers/getSupplier`);
}
export function saveSupplier(data) {
    return axiosInstance.post(`suppliers`, data);
}
export function getSupplierDetails(id) {
    return axiosInstance.get(`suppliers/${id}`);
}
export function getAllSuppliers(values) {
    const { pageNumber, pageSize, filter } = values;
    return axiosInstance.get(`suppliers/all?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${filter.toString()}`);
}
export function updateSupplier(data,id) {
    return axiosInstance.put(`suppliers/${id}`, data);
}

export function getSupplierBranches(id) {
    return axiosInstance.get(`suppliers/branches/${id}/${0}`);
}