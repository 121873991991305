export const accordionData = [
    {
        title: 'Can I see the demo before purchasing?',
        content: `Yes. Our free trials provide you with a full version of the software for 14 days. You get the full experience of the software, so you know exactly what you're getting when you become an Amber customer.`
    },
    {
        title: 'Can I cancel my subscription at any time?',
        content: `Yes. The subscription can be cancelled anytime with in the trial period. After the 14 days trial your account will be put on hold. If you choose to keep your Business plan, you will need to enter and confirm your payment details. After the confirmation you continue with your account and data as you left it during the free trial.`
    },
    {
        title: 'Is there an additional discount when paid annually?',
        content: `Yes, those who pay us annually are eligible for a flat 20% reduction off the cost of their subscription.`
    },
    {
        title: 'I have an issue with my account',
        content: `Please share a snaphot of the error encountered during the usage of the software and email us to support@amberinfotech.com.\n\n\n
        If the issue is still not resolved within 72 hours, you may call us anytime within the working hours, Monday through Friday.`
    },
    {
        title: 'Can I use your system on different devices?',
        content: `Yes. Our technology is set up to make sure the website is efficient and simple to use on any device.`
    }
];