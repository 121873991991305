import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Combobox } from 'react-widgets/cjs'
import Accordion from 'react-bootstrap/Accordion'
import * as Yup from 'yup'
import './style.css'

// lib

// services
import { getCompanyBranches } from '../../../../services/CompanyService'
import { getAllProducts } from '../../../../services/CatalogService'
import { getTaxes } from '../../../../services/CommonService'
import { toastNotify } from '../../../../services/NotifyService'

// shared components
import { AddCustomerShort } from '../../../../_shared/components/AddCustomerShort'
import { EditNotes } from '../../../../_shared/components/EditNotes'
import { EditTerms } from '../../../../_shared/components/EditTerms'

//models
import { ProductEditModal } from '../../catalog/_productModal'
import { getSuppliers } from '../../../../services/SuppliersService'
import saveNewPurchaseOrder, { getPoSerialNumber } from '../../../../services/BackendService/PurchaseOrdersService'
import { breadcrumbs } from '../../components/PageTitle'
import { PageTitle } from '../../../../_metronic/layout/core'
import { ValidationDate, ValidationField, ValidationSelect } from '../../components/components'
import { IAddTaxModel, IAddDisModel } from '../../../../_models/additionaltax-model'
import {
  IPOAdditionalDiscount,
  IPOAdditionalTax,
  poAddtaxInitValues,
  poAddDisInitValues,
} from '../../interface/IInvoiceTypes/IPurchaseOrderModel'
import { getDiscount } from '../../../../services/DiscountService'
import { PurchaseOrderPrefixModal } from '../../../../_shared/components/PurchaseOrderPrefix'
import { initialValues } from '../iconsts'

export function PurchaseAddFormV2(props: any) {
  const { afterInvoiceUpdate } = props
  const navigate = useNavigate()

  const [rowsData, setRowsData] = useState<any[]>([])
  const [taxRowsData, setTaxRowsData] = useState<IPOAdditionalTax[]>([poAddtaxInitValues])
  const [disRowsData, setDisRowsData] = useState<IPOAdditionalDiscount[]>([poAddDisInitValues])

  const [supplierArr, setSupplierArr] = useState<any[]>([])
  const [branchArr, setBranchArr] = useState<any[]>([])
  const [prodArr, setProdArr] = useState<any[]>([])
  const [taxArr, setTaxArr] = useState<any[]>([])
  const [disArr, setDisArr] = useState<any[]>([])
  const [showInvPreFrom, setShowInvPreFrom] = useState<any>(false)

  const [loading, setLoading] = useState(false)
  const [showCustomerForm, setShowCustomerForm] = useState<any>(false)
  const [showProductForm, setShowProductForm] = useState<any>(false)
  const [showTerms, setShowTerms] = useState<any>(false)
  const [showNotes, setShowNotes] = useState<any>(false)
  const [terms, setTerms] = useState<any>('')
  const [notes, setNotes] = useState<any>('')

  const [subTotal, setSubTotal] = useState<any>(0)
  const [discTotal, setDiscTotal] = useState<any>(0)
  const [taxTotal, setTaxTotal] = useState<any>(0)
  const [addTaxTotal, setAddTaxTotal] = useState<any>(0)
  const [total, setTotal] = useState<any>(0)
  const [selectedCompanyBranch, setSelectedCompanyBranch] = useState<any>(
    {}
  )

  const invoiceSchema = Yup.object().shape({
    //branchId: Yup.string().required('Please choose billing company/branch'),
    supplierId: Yup.string()
      .required('Please choose supplier')
      .test('len', 'Please choose a supplier', (val: any) => val !== '0'),
    billBranchId: Yup.string()
      .required('Please choose bill to branch')
      .test('len', 'Please choose a bill to branch', (val: any) => val !== '0'),
    shipBranchId: Yup.string()
      .required('Please choose ship to branch')
      .test('len', 'Please choose a ship to branch', (val: any) => val !== '0'),
  })

  const today = new Date()
  

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: invoiceSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const _invoice: any = {
        purchaseOrderNumber: values.purchaseOrderNumber,
        purchaseOrderDate:
          values.purchaseOrderDate == '' ? today.toLocaleDateString() : values.purchaseOrderDate,
        purchaseOrderDueDate:
          values.purchaseOrderDueDate == '' ? today.toLocaleDateString() : values.purchaseOrderDueDate,
        revisionNo: values.revisionNo,
        supplierId: +values.supplierId,
        notes: values.notes,
        branchId: +values.branchId,
        subAmount: subTotal - discTotal,
        taxAmount: taxTotal,
        discount: discTotal,
        totalAmount: total,
        addTax: addTaxTotal,
        status: 1,
        terms: terms,
        billBranchId: +values.billBranchId,
        // excriseRange: values.excriseRange,
        shipBranchId: +values.shipBranchId,
      }
      rowsData.forEach((element) => {
        element.taxAmount =
          ((element.price * element.quantity - +element.discount) * element.taxPercent) / 100
        element.amount = element.price * element.quantity - +element.discount
        element.grossAmount =
          element.price * element.quantity -
          +element.discount +
          ((element.price * element.quantity - +element.discount) * element.taxPercent) / 100
      })

      taxRowsData.forEach((element) => {
        element.taxAmount = ((subTotal - discTotal) * element.taxPercent) / 100
      })

      var postData = {
        purchaseOrder: _invoice,
        purchaseOrderItems: rowsData,
        purchaseOrderAdditionalTaxes: taxRowsData,
        purchaseOrderAdditionalDiscount: disRowsData,
      }

      setLoading(true)
      const toastId = toast.loading('Please wait...')

      try {
        await saveNewPurchaseOrder(postData)
          .then(async (res: any) => {
            let _data = res.data.data
            toastNotify(toastId, res)
            if (!afterInvoiceUpdate) {
              {
                navigate('/purchase-orders')
              }
            } else {
              afterInvoiceUpdate(res.data.success)
            }
          })
          .catch((err: any) => {
            const res = { data: { message: 'Something went wrong', success: false } }
            toastNotify(toastId, res)
            console.error(err)
          })
      } catch (error) {
        console.error(error)
        setStatus('Something went wrong')
        setSubmitting(false)
      } finally {
        setLoading(false)
      }
    },
  })
  // useEffect(() => {
  //   if (formik.values.supplierId) {
  //     setSelectedSupplierBranch(null)
  //     bindSupplierBranches(formik.values.supplierId)
  //   }
  // }, [formik.values.supplierId])

  useEffect(() => {
    if (formik.values.billBranchId) {
      var _selectedBranch = branchArr.filter(
        (c: any) => c.billBranchId === +formik.values.billBranchId
      )[0]
      setSelectedCompanyBranch(_selectedBranch)
    }
  }, [formik.values.billBranchId])

  const handleChange = (index: any, evnt: any) => {
    const { name, value } = evnt.target
    const rowsInput = [...rowsData]
    rowsInput[index][name] = value

    // If the modified field is "quantity" or "price", update the corresponding dependent fields
    if (name === 'quantity' || name === 'price') {
      const item = rowsInput[index]
      const taxableAmount: any = (item.price * item.quantity - item.discount)?.toFixed(2)
      const taxAmount: any = ((taxableAmount * item.taxPercent) / 100).toFixed(2)
      const totalAmount = (parseFloat(taxableAmount) + parseFloat(taxAmount)).toFixed(2)
      rowsInput[index].taxableAmount = taxableAmount
      rowsInput[index].taxAmount = taxAmount
      rowsInput[index].totalAmount = totalAmount
    }

    setRowsData(rowsInput)
  }

  const deleteItem = (index: any) => {
    const rows = [...rowsData]
    rows.splice(index, 1)
    setRowsData(rows)
  }

  const addTaxRow = (index: number) => {
    const newRow: IPOAdditionalTax = { ...poAddtaxInitValues }
    const rowsInput = [...taxRowsData]
    rowsInput.splice(index + 1, 0, newRow)
    setTaxRowsData(rowsInput)
  }
  const addDisRow = (index: number) => {
    const newRow: IPOAdditionalDiscount = { ...poAddDisInitValues } // use spread operator to create copy
    const rowsInput = [...disRowsData]
    rowsInput.splice(index + 1, 0, newRow) // insert the new row at the correct index
    setDisRowsData(rowsInput)
  }
  const deleteDisItem = (index: any) => {
    const rows = [...disRowsData]
    rows.splice(index, 1)
    setDisRowsData(rows)
  }

  const handleTaxChange = (index: any, evnt: any) => {
    const { name, value } = evnt.target
    const rowsInput = [...taxRowsData]

    if (+value > 0) {
      var taxInfo = taxArr.filter((c) => c.id === +value)[0]
      rowsInput[index]['taxId'] = taxInfo.id
      rowsInput[index]['taxName'] = taxInfo.taxName
      rowsInput[index]['taxPercent'] = taxInfo.taxValue
      setTaxRowsData(rowsInput)

      //var arr = taxArr.filter(c => c.id !== +value)
      //setTaxArr(arr);
    } else {
      rowsInput[index]['taxName'] = 'None'
      rowsInput[index]['taxPercent'] = 0
      rowsInput[index]['taxId'] = 0

      setTaxRowsData(rowsInput)
    }
  }

  const handleDisChange = (index: any, evnt: any) => {
    const { name, value } = evnt.target
    const rowsInput = [...disRowsData]

    if (+value > 0) {
      var disInfo = disArr.filter((c) => c.id === +value)[0]
      rowsInput[index]['discountId'] = disInfo.id
      rowsInput[index]['discountName'] = disInfo.discountName
      rowsInput[index]['discountPercent'] = disInfo.discountValue
      setDisRowsData(rowsInput)

      //var arr = taxArr.filter(c => c.id !== +value)
      //setTaxArr(arr);
    } else {
      rowsInput[index]['discountName'] = 'None'
      rowsInput[index]['discountPercent'] = 0
      rowsInput[index]['discountId'] = 0
      setDisRowsData(rowsInput)
    }
  }

  const deleteTaxItem = (index: any) => {
    const rows = [...taxRowsData]
    rows.splice(index, 1)
    setTaxRowsData(rows)
  }

  const bindSuppliers = () => {
    setTimeout(() => {
      getSuppliers()
        .then((res: any) => {
          let _data = res.data.data
          _data = _data.map((c: any) => {
            return { ...c, name: c.fullName, id: c.id }
          })
          _data.unshift({ id: 0, name: 'Select Supplier' })
          setSupplierArr(_data)
        })
        .catch(() => { })
    }, 1000)
  }

  const bindCompanyBranches = () => {
    getCompanyBranches()
      .then((res: any) => {
        let _data = res.data.data
        const arr: any = []
        _data.forEach((element: any) => {
          arr.push({
            id: element.id,
            name: element.companyName,
          })
        })
        arr.unshift({ id: 0, name: 'Select' })
        setBranchArr(arr)
      })
      .catch(() => { })
  }

  // const [suppBranchArr, setSuppBranchArr] = useState<SupplierBranchesModel[]>([])
  // const bindSupplierBranches = (suppId: any) => {
  //   getSupplierBranches(suppId)
  //     .then((res: any) => {
  //       let _data = res.data.data
  //       _data = _data.map((c: SupplierBranchesModel) => {
  //         return { ...c, name: c.name, id: c.suppBranchId }
  //       })
  //       _data.unshift({ id: 0, name: 'Select' })
  //       setSuppBranchArr(_data)
  //     })
  //     .catch(() => { })
  // }

  // const bindCustomerBranches = (custId: any) => {
  //   setTimeout(() => {
  //     getCustomerBranches(custId, 0)
  //       .then((res: any) => {
  //         let _data = res.data.data
  //         _data = _data.map((c: any) => {
  //           return { ...c, name: c.name, id: c.suppBranchId }
  //         })
  //         _data.unshift({ id: 0, name: 'Select' })
  //         setCustBranchArr(_data)
  //       })
  //       .catch(() => { })
  //   }, 1000)
  // }

  const bindProducts = () => {
    setTimeout(() => {
      getAllProducts()
        .then((res: any) => {
          let _data = res.data.data
          setProdArr(_data)
        })
        .catch(() => { })
    }, 1000)
  }

  const bindTaxes = () => {
    setTimeout(() => {
      getTaxes(true)
        .then((res: any) => {
          let _data = res.data.data
          setTaxArr(_data)
          // if (_data.length > 0) {
          //     addTaxRow(0);
          // }
        })
        .catch(() => { })
    }, 1000)
  }
  const bindDiscount = () => {
    setTimeout(() => {
      getDiscount()
        .then((res: any) => {
          let _data = res.data.data
          setDisArr(_data)
          // if (_data.length > 0) {
          //     addTaxRow(0);
          // }
        })
        .catch(() => { })
    }, 1000)
  }

  const dateEventHandler = (data: any, fieldName: any, setFieldValue: any) => {
    setFieldValue(fieldName, new Date(data.year, data.month - 1, data.day))
  }

  // const customerEventHandler = (event: any, fieldName: any, setFieldValue: any) => {
  //   setFieldValue(fieldName, event.target.value)
  //   setSelectedCustBranch({ ...initialSupplierBranchesModel })
  //   setTimeout(() => {
  //     bindSupplierBranches(event.target.value)
  //   }, 100)
  // }

  // const custBranchEventHandler = (event: any, fieldName: any, setFieldValue: any) => {
  //   setFieldValue(fieldName, event.target.value)
  //   setTimeout(() => {
  //     var _selectedCustBranch = suppBranchArr.filter(
  //       (c: SupplierBranchesModel) => c.suppBranchId === +event.target.value
  //     )[0]
  //     setSelectedCustBranch(_selectedCustBranch)
  //   }, 100)
  // }

  const [value, setValue] = useState<any>(null)
  const [quantity, setQuantity] = useState<any>(1)

  const productSelected = (val: any) => {
    setValue(val)
  }
  const addProductToInvoice = () => {
    if (value.id && value.id > 0) {
      const selectedProduct = prodArr.filter((product: any) => product.id === value.id)[0]

      const newRow: any = {
        itemId: selectedProduct.id,
        itemName: selectedProduct.productName,
        hsnSac: selectedProduct.hsn,
        quantity: quantity,
        unit: selectedProduct.unitName,
        price: selectedProduct.defaultSP,
        discount: 0,
        taxPercent: selectedProduct.taxPercent,
        taxAmount: (selectedProduct.defaultSP * 1 * selectedProduct.taxPercent) / 100,
        amount: selectedProduct.defaultSP * 1,
        grossAmount:
          selectedProduct.defaultSP * 1 +
          (selectedProduct.defaultSP * 1 * selectedProduct.taxPercent) / 100,
      }
      const rowsInput = [...rowsData, newRow]
      setRowsData(rowsInput)
      setValue(null)
      setProdArr([])

      setProdArr(prodArr.filter((product: any) => product.id !== selectedProduct.id))
    }
  }

  const afterUpdate = (data: any, setFieldValue: any) => {
    bindSuppliers()
    setFieldValue('supplierId', data)
    // setSelectedSupplierBranch(initialSupplierBranchesModel)
  }

  const afterUpdateProduct = (data: any) => {
    bindProducts()
  }

  const afterUpdateTerms = (data: any) => {
    setShowTerms(false)
    formik.setFieldValue('terms', data)
  }

  const openTermsModal = (data: any) => {
    setTimeout(() => {
      setTerms(data)
      setShowTerms(true)
    }, 500)
  }

  // notes
  const afterUpdateNotes = (data: any) => {
    setShowNotes(false)
    formik.setFieldValue('notes', data)
  }

  const openNotesModal = (data: any) => {
    setTimeout(() => {
      setNotes(data)
      setShowNotes(true)
    }, 500)
  }

  const [poNumber, setPoNumber] = useState(null);
  const bindPoNumber = () => {

    setTimeout(() => {
      getPoSerialNumber()
        .then((res: any) => {
          debugger
          let _data = res.data.data;
          setPoNumber(_data);
          initialValues.purchaseOrderNumber = res.data.data;
          console.log(_data);
        })
        .catch(() => {

        }).finally(() => {

        });
    }, 500);
  }


  useEffect(() => {
    bindPoNumber();
    bindCompanyBranches();
    bindSuppliers();
    bindProducts();
    bindTaxes();
    bindDiscount();
   
  }, [])

  useEffect(() => {
    calcTotals()
  }, [rowsData, disRowsData])

  useEffect(() => {
    calcTotals()
  }, [taxRowsData])

  function calcTotals() {
    let st = 0
    rowsData.forEach((element: any) => {
      st += element.price * element.quantity
    })
    setSubTotal(st)
    let dt = 0
    let temp = subTotal
    disRowsData.forEach((element: any) => {

      if (element.discountPercent) dt += temp * +element.discountPercent / 100
      temp = temp - (temp - dt) * +element.discountPercent / 100
    })
    setDiscTotal(dt)

    let tt = 0
    rowsData.forEach((element: any) => {
      var t = ((element.price * element.quantity - +element.discount) * element.taxPercent) / 100
      tt += t
    })
    setTaxTotal(tt)

    let at = 0
    taxRowsData.forEach((element: any) => {
      var t = ((subTotal - discTotal) * element.taxPercent) / 100
      at += t
    })
    setAddTaxTotal(at)

    setTotal(st + tt + at - dt)
  }

  const isDisabled = (taxId: number) => {
    return taxRowsData.some((tax) => tax.taxId === taxId)
  }
  return (
    <>
      <PageTitle
        breadcrumbs={breadcrumbs({
          title: 'Purchase Order',
          path: '/purchase-orders',
        })}
      >
        Add Order
      </PageTitle>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='separator mb-5'></div>
            <div className='card'>
              <div className='card-body'>
                <form onSubmit={formik.handleSubmit} noValidate id='ab_invoice_clone_form'>
                  <div className='row'>
                    <div className='col-md-12 mb-lg-12'>
                      {/* 1 */}
                      <div className='row mb-3'>
                        <div className='col-md-3'>
                          <ValidationSelect
                            className='my-2'
                            label='Supplier'
                            isRequired
                            placeholder='Select supplier'
                            name='supplierId'
                            formik={formik}
                            options={supplierArr}
                          />
                        </div>
                        <div className="col-md-3">
                          <ValidationField
                            className='my-2'
                            label='PO number'
                            isRequired
                            placeholder='PO number'
                            name='purchaseOrderNumber'
                            formik={formik}
                          />
                        </div>
                        <div className='col-md-3'>
                          <button
                            type='button'
                            className='btn btn-sm btn-light mt-11'
                            onClick={() => setShowInvPreFrom(true)}
                          >
                            PO number settings
                          </button>
                          <div
                            className='d-flex align-items-center alert alert-custom alert-notice alert-light-primary fade show'
                            role='alert'
                          >
                            <div className='alert-icon'>
                              <i className='fas fa-info-circle'></i>
                            </div>
                            <div className='alert-text mx-2 text-gray-600'>
                              Manage PO prefix and serial number
                            </div>
                          </div>
                        </div>
                        {/* <div
                            className='d-flex align-items-center alert alert-custom alert-notice alert-light-primary fade show'
                            role='alert'
                          >
                            <div className='alert-icon'>
                              <i className='fas fa-info-circle'></i>
                            </div>
                            <div className='alert-text mx-2 text-gray-600'>
                              Purchase Order will display this company/branch address and GSTIN
                            </div>
                          </div> */}

                        {/* <div className='col-md-2 pt-10 d-none'>
                          <button
                            type='button'
                            className='align-self-center offset-md-2 btn btn-secondary btn-sm'
                            onClick={() => setShowCustomerForm(true)}
                          >
                            <i className='las la-plus fs-1x'></i> Add Supplier
                          </button>
                        </div> */}

                        {/* <div className='col-md-3'>
                          <button
                            type='button'
                            className='btn btn-sm btn-secondary mt-12'
                            onClick={() => setShowInvPreFrom(true)}
                          >
                            Order number settings
                          </button>
                          <div
                            className='d-flex align-items-center alert alert-custom alert-notice alert-light-primary fade show'
                            role='alert'
                          >
                            <div className='alert-icon'>
                              <i className='fas fa-info-circle'></i>
                            </div>
                            <div className='alert-text mx-2 text-gray-600'>
                              Manage PO's prefix and serial number
                            </div>
                          </div>
                        </div> */}

                      </div>
                      <div className="row">
                        <ValidationDate
                          className='col-lg-4'
                          label='Purchase Order Date'
                          isRequired
                          placeholder='Select Date'
                          name='purchaseOrderDate'
                          formik={formik}
                        />
                        <ValidationDate
                          className='col-lg-4'
                          label='Purchase Order Due Date'
                          placeholder='Select Date'
                          name='purchaseOrderDueDate'
                          formik={formik}
                        />
                      </div>
                      <div className='row'>
                        <ValidationSelect
                          className='col-md-6 my-2'
                          label='Bill to branch'
                          isRequired
                          placeholder='Select bill to branch'
                          name='billBranchId'
                          formik={formik}
                          options={branchArr}
                        />

                      </div>
                      <div className='row mt-3'>
                        <div className='col-md-6 mb-2'>
                          <ValidationSelect
                            className='my-2'
                            placeholder='Ship to branch'
                            label='Ship to branch'
                            isRequired
                            name='shipBranchId'
                            formik={formik}
                            options={branchArr}
                          />

                          <div className='px-3 py-3'>
                            {/* {selectedCompanyBranch !== null && (
                              <address>
                                <p className='mb-0 text-gray-600'>
                                  {selectedCompanyBranch.name} {selectedCompanyBranch.address}
                                </p>
                                <p className='mb-0 text-gray-600'>
                                  {selectedCompanyBranch.city}{' '}
                                  {selectedCompanyBranch.stateName}{' '}
                                  {selectedCompanyBranch.postalCode}
                                </p>
                                <p className='mb-2 text-gray-600'>
                                  {selectedCompanyBranch.contactNo} |{' '}
                                  {selectedCompanyBranch.email}
                                </p>

                                <p className='mb-0 text-dark'>
                                  GST: {selectedCompanyBranch.gstin}
                                </p>
                              </address>
                            )} */}
                          </div>
                        </div>
                      </div>


                      {/* <div className="separator my-10"></div> */}
                      {/* 3 */}
                      <div className='row mb-3 rounded'>
                        <label className='col-lg-8 align-self-center form-label fw-bolder'>
                          Add Items to Purchase Orders
                        </label>
                        <button
                          className='col-md-2 align-self-center offset-md-2 btn btn-secondary btn-sm'
                          type='button'
                          onClick={() => setShowProductForm(true)}
                        >
                          Add Product
                        </button>
                      </div>
                      <div className='row bg-light  rounded p-5 mb-5 '>
                        <div className='row'>
                          <div className='col-lg-8 align-self-center'>
                            <Combobox
                              name='productSearchBox'
                              autoFocus={true}
                              autoSelectMatches={true}
                              hideCaret
                              hideEmptyPopup
                              data={prodArr}
                              dataKey='id'
                              textField='displayName'
                              placeholder='Search for a product'
                              value={value}
                              onChange={(e: any) => productSelected(e)}
                              filter='contains'
                            //onSelect={(e) => productSelected(e)}
                            />
                          </div>
                          <div className='col-md-4 align-self-center'>
                            <div className='row'>
                              {/* <div className=""> */}
                              <label className='col-lg-4 align-self-center form-label fw-bolder my-1'>
                                Quantity
                              </label>
                              {/* </div> */}
                              <input
                                type='number'
                                className='col-lg-4 my-2'
                                style={{
                                  border: '1px solid #e4e6ef',
                                  borderRadius: '4px',
                                  padding: '5px',
                                  height: '38px',
                                }}
                                placeholder='Quantity'
                                name='quantity'
                                min='1'
                                value={quantity}
                                onChange={(e: any) => setQuantity(e.target.value)}
                              />
                              <div className='col-lg-1 align-self-center my-2'>
                                <button
                                  className='btn btn-primary btn-sm'
                                  type='button'
                                  onClick={() => addProductToInvoice()}
                                >
                                  <i className='fas fa-plus'></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='col-md-12'>
                          <div className='table-responsive '>
                            <div className='display mb-4 dataTablesCard customer-list-table'>
                              <Table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                                <thead>
                                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                    <th>Description/HSN</th>
                                    <th>Quantity</th>
                                    <th>Unit</th>
                                    <th className='text-end'>Rate</th>
                                    <th className='text-end'>Discount </th>
                                    <th className='text-end'>Taxable Amount</th>
                                    <th className='text-end'>Tax</th>
                                    <th className='text-end'>Total Amount</th>
                                    <th className='text-center'>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {rowsData && rowsData.length > 0 ? (
                                    rowsData.map((item: any, index: any) => (
                                      <tr key={index}>
                                        <td className='text-gray-600 fw-bold'>
                                          <div className='d-flex align-items-center'>
                                            <div>
                                              <p className='text-gray-600 text-hover-secondary mb-0'>
                                                {' '}
                                                {item.itemName}
                                              </p>
                                              <span className='text-gray-500 fs-6'>
                                                {item.hsnSac}
                                              </span>
                                            </div>
                                          </div>
                                        </td>
                                        <td className='text-gray-600 fw-bold w-100px'>
                                          <input
                                            type='number'
                                            name='quantity'
                                            min='1'
                                            className='form-control '
                                            id={`qty_${item.id}`}
                                            value={item.quantity}
                                            onChange={(evnt) => handleChange(index, evnt)}
                                          />
                                        </td>
                                        <td className='text-gray-600 fw-bold'>{item.unit}</td>
                                        <td className='text-gray-600 fw-bold text-end w-100px'>
                                          <input
                                            type='number'
                                            name='price'
                                            className='form-control '
                                            id={`price_${item.id}`}
                                            value={item.price}
                                            onChange={(evnt) => handleChange(index, evnt)}
                                          />
                                        </td>{' '}
                                        <td className='text-gray-600 fw-bold text-end w-100px'>
                                          <input
                                            type='number'
                                            name='discount'
                                            className='form-control '
                                            id={`disc_${item.id}`}
                                            value={item.discount}
                                            onChange={(evnt) => handleChange(index, evnt)}
                                          />
                                        </td>
                                        <td className='text-gray-600 fw-bold text-end'>
                                          {' '}
                                          {(item.price * item.quantity - item.discount)?.toFixed(2)}
                                        </td>
                                        <td className='text-gray-600 fw-bold text-end'>
                                          {(
                                            ((item.price * item.quantity - item.discount) *
                                              item.taxPercent) /
                                            100
                                          ).toFixed(2)}{' '}
                                          (
                                          <span className='text-gray-600 fs-7'>
                                            {item.taxPercent}%
                                          </span>
                                          )
                                        </td>
                                        <td className='text-dark fw-bold text-end'>
                                          {(
                                            item.price * item.quantity -
                                            item.discount +
                                            ((item.price * item.quantity - item.discount) *
                                              item.taxPercent) /
                                            100
                                          )?.toFixed(2)}
                                          <br></br>
                                        </td>
                                        <td className='text-center'>
                                          <a href='#' onClick={() => deleteItem(index)}>
                                            <i className='bi bi-trash text-danger fs-5'></i>
                                          </a>
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan={8}>
                                        <div className='text-gray-600 d-flex text-center w-100 align-content-center justify-content-center'>
                                          No matching records found
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='separator my-10'></div>
                      {/* 4 */}
                      {subTotal - discTotal > 0 && (
                        <div className="d-flex">
                          <div className='row '>
                            <div className='col-md-12 '>
                              <label className='form-label'>Add Additional Tax</label>
                              <Table className='table align-middle table-row-dashed fs-6 px-5 dataTable no-footer'>
                                <tbody>
                                  {taxRowsData &&
                                    taxRowsData.length > 0 &&
                                    taxRowsData.map((item: any, index: any) => (
                                      <tr>
                                        <td className='w-250px'>
                                          <select
                                            className='form-control'
                                            name={`taxName`}
                                            id={`taxName_${index}`}
                                            onChange={(evnt) => handleTaxChange(index, evnt)}
                                          >
                                            <option value={0}>None</option>
                                            {taxArr &&
                                              taxArr.map((tax: IAddTaxModel) => {
                                                return (
                                                  <option
                                                    key={tax.id}
                                                    value={tax.id}
                                                    // disable tax which is already added
                                                    disabled={isDisabled(tax.id)}
                                                  >
                                                    {tax.taxName}
                                                  </option>
                                                )
                                              })}
                                          </select>
                                        </td>
                                        <td>
                                          <input
                                            className='form-control'
                                            name='taxPercent'
                                            id={`taxPercent_${index}`}
                                            value={item.taxPercent}
                                          />
                                        </td>
                                        <td className='w-100px'>
                                          <a onClick={() => addTaxRow(index)}>
                                            {' '}
                                            <i className='las la-plus fs-2x'></i>
                                          </a>
                                          <a onClick={() => deleteTaxItem(index)} href='#'>
                                            {' '}
                                            <i className='las la-trash fs-2x text-danger me-5'></i>
                                          </a>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </Table>
                            </div>
                          </div>




                          <div className='row d-none'>
                            <div className='col-md-12  '>
                              <label className='form-label'>Add Additional Discount</label>
                              <Table className='table align-middle table-row-dashed fs-6 px-5 dataTable no-footer'>
                                <tbody>
                                  {disRowsData &&
                                    disRowsData.length > 0 &&
                                    disRowsData.map((item: IPOAdditionalDiscount, index: any) => (
                                      <tr>
                                        <td className='w-250px'>
                                          <select
                                            className='form-control'
                                            name={`discountName`}
                                            id={`discountName_${index}`}
                                            onChange={(evnt) => handleDisChange(index, evnt)}
                                          >
                                            <option value={0}>None</option>
                                            {disArr &&
                                              disArr.map((dis: IAddDisModel) => {
                                                return (
                                                  <option
                                                    key={dis.id}
                                                    value={dis.id}
                                                    // disable tax which is already added
                                                    disabled={isDisabled(dis.id)}
                                                  >
                                                    {dis.discountName}
                                                  </option>
                                                )
                                              })}
                                          </select>
                                        </td>
                                        <td>
                                          <input
                                            className='form-control'
                                            name='discountPercent'
                                            id={`discountPercent_${index}`}
                                            value={item.discountPercent}
                                          />
                                        </td>
                                        <td className='w-100px'>
                                          <a onClick={() => addDisRow(index)}>
                                            {' '}
                                            <i className='las la-plus fs-2x'></i>
                                          </a>
                                          <a onClick={() => deleteDisItem(index)} href='#'>
                                            {' '}
                                            <i className='las la-trash fs-2x text-danger me-5'></i>
                                          </a>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>

                      )}

                      {/* 5 */}
                      <div className='row'>
                        {/* <div className="col-md-6 col-sm-12 mb-2">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Terms &amp; Conditions</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="card card-custom p-0 card-flush">
                                                <div className="card-body">
                                                    <div dangerouslySetInnerHTML={{ __html: formik.values.terms }}>
                                                    </div>
                                                </div>
                                                <div className="card-footer text-end p-0">
                                                    <div>
                                                        <button type="button"
                                                            onClick={() => openTermsModal(formik.values.terms)}
                                                            className='btn  text-primary btn-light btn-color-muted btn-active-light-primary px-4 py-2 me-4 w-100px'
                                                        >
                                                            <span> {formik.values.terms === '' ? "Add" : "Edit"}</span>
                                                        </button>

                                                        {formik.values.terms !== '' && (
                                                            <button type="button"
                                                                onClick={() => formik.setFieldValue('terms', '')}
                                                                className='btn  text-danger btn-light btn-color-muted btn-active-light-danger px-4 py-2 w-100px'
                                                            >
                                                                <span>Remove</span>
                                                            </button>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Body>

                                    </Accordion.Item>
                                </Accordion>


                            </div> */}
                        <div className='col-md-6 col-sm-12 mb-2'>
                          <Accordion defaultActiveKey='0'>
                            <Accordion.Item eventKey='0'>
                              <Accordion.Header>Notes</Accordion.Header>
                              <Accordion.Body>
                                <div className='card card-custom p-0 card-flush'>
                                  <div className='card-body'>
                                    <div
                                      dangerouslySetInnerHTML={{ __html: formik.values.notes }}
                                    ></div>
                                  </div>
                                  <div className='card-footer text-end p-0'>
                                    <a
                                      href='#'
                                      onClick={() => openNotesModal(formik.values.notes)}
                                      className='btn  text-primary btn-light btn-color-muted btn-active-light-primary px-4 py-2 me-4 w-100px'
                                    >
                                      <span>{formik.values.notes === '' ? 'Add' : 'Edit'}</span>
                                    </a>

                                    {formik.values.notes !== '' && (
                                      <a
                                        href='#'
                                        onClick={() => formik.setFieldValue('notes', '')}
                                        className='btn  text-danger btn-light btn-color-muted btn-active-light-danger px-4 py-2 w-100px'
                                      >
                                        <span>Remove</span>
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>
                      </div>

                    </div>
                    <div className='row'>
                      <div className='offset-md-8 col-md-4 col-xs-12'>
                        <div className='form w-100  mb-3 mt-5'>
                          <div className='card card-custom card-border border  bg-light-primary'>
                            <div className='card-body p-5'>
                              {formik.status && (
                                <div className='mb-lg-15 alert alert-secondary text-gray-700'>
                                  <div className='alert-text font-weight-bold'>
                                    {formik.status}
                                  </div>
                                </div>
                              )}
                              <div className='table-responsive'>
                                <table className='table align-middle table-condensed'>
                                  {/* begin::Table body */}
                                  <tbody>
                                    <tr>
                                      <td>
                                        <a
                                          href='#'
                                          className='text-gray-600  text-hover-primary  fs-6'
                                        >
                                          Sub Total
                                        </a>
                                      </td>
                                      <td className='text-end'>{subTotal.toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          href='#'
                                          className='text-gray-600  text-hover-primary  fs-6'
                                        >
                                          Discount(-)
                                        </a>
                                      </td>
                                      <td className='text-end'>{discTotal?.toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          href='#'
                                          className='text-gray-600  text-hover-primary  fs-6'
                                        >
                                          Taxable Amount
                                        </a>
                                      </td>
                                      <td className='text-end'>
                                        {(subTotal - discTotal)?.toFixed(2)}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          href='#'
                                          className='text-gray-600  text-hover-primary  fs-6'
                                        >
                                          Tax Total
                                        </a>
                                      </td>
                                      <td className='text-end'>{taxTotal.toFixed(2)}</td>
                                    </tr>
                                    {addTaxTotal > 0 && (
                                      <tr>
                                        <td>
                                          <a
                                            href='#'
                                            className='text-gray-600  text-hover-primary  fs-6'
                                          >
                                            Additional Tax
                                          </a>
                                        </td>
                                        <td className='text-end'>{addTaxTotal?.toFixed(2)}</td>
                                      </tr>
                                    )}

                                    <tr>
                                      <td>
                                        <a
                                          href='#'
                                          className='text-dark fw-bold text-hover-primary fs-3'
                                        >
                                          Total
                                        </a>
                                      </td>
                                      <td className='text-end fw-bold  fs-4'>
                                        {total.toFixed(2)}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className='card-footer'>
                              {/* begin::Action */}

                              <div className='text-center'>
                                <button
                                  type='submit'
                                  id='kt_sign_in_submit'
                                  className='btn btn-lg btn-primary w-100 mb-5'
                                  disabled={
                                    formik.isSubmitting ||
                                    !formik.isValid ||
                                    rowsData.length === 0
                                  }
                                >
                                  {!loading && (
                                    <span className='indicator-label'>
                                      {' '}
                                      <i className='fa-solid fa-floppy-disk fs-4 me-2'></i> Save
                                      Purchase Order
                                    </span>
                                  )}
                                  {loading && (
                                    <span
                                      className='indicator-progress'
                                      style={{ display: 'inline' }}
                                    >
                                      Please wait...
                                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                  )}
                                </button>
                              </div>
                              {/* end::Action */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                {showCustomerForm && (
                  <AddCustomerShort
                    setShowCustomerForm={setShowCustomerForm}
                    afterUpdate={(data: any) => afterUpdate(data, formik.setFieldValue)}
                  />
                )}
                {showTerms && (
                  <EditTerms
                    setShowTerms={setShowTerms}
                    afterUpdateTerms={afterUpdateTerms}
                    data={terms}
                  />
                )}
                {showNotes && (
                  <EditNotes
                    setShowNotes={setShowNotes}
                    afterUpdateNotes={afterUpdateNotes}
                    data={notes}
                  />
                )}
                {showProductForm && (
                  <ProductEditModal
                    setShowProductForm={setShowProductForm}
                    afterUpdateProduct={afterUpdateProduct}
                    isService={false}
                  />
                )}
                {showInvPreFrom && (
                  <PurchaseOrderPrefixModal
                    setShowInvPreFrom={setShowInvPreFrom}
                    afterUpdateProduct={afterUpdateProduct}
                    isService={false}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
