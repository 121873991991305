import axiosInstance from '../services/AxiosInstance';

export function getAllBugs() {
    return axiosInstance.get(`shared/getallbugreport`);
}

export function saveCustomer(postData) {
    return axiosInstance.post(`shared/reportbug`, postData);
}

