const today = new Date();

export const initialValues = {
    purchaseOrderNumber: '',
    supplierId: '',
    branchId: '',
    billBranchId: '',
    shipBranchId: '',
    purchaseOrderDate: today.toLocaleDateString(),
    purchaseOrderDueDate: '',
    revisionNo: '',
    notes: '',
    excriseRange: '',
  }