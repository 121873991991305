import React, { useState, useEffect } from 'react';
import { getaccountloginhistoryTimeOut } from '../../../../services/account/AccountService';
import { KTSVG } from '../../../helpers'
import { Dropdown2, Search, ThemeModeSwitcher } from '../../../partials'
import { Link } from 'react-router-dom';
import moment from "moment";

const Topbar = () => {
  const [dates, setdatetime] = React.useState<any>({});

  useEffect(() => {
    bindLastLoginTime();




  }, []);


  const bindLastLoginTime = () => {
    getaccountloginhistoryTimeOut().then((res: any) => {
      if (res.data.success) {
        const datas = res.data.data;
        setdatetime(datas);
      }
    }).catch((err: any) => {
      console.log(err);
    })


  }




  return (
    <div className='d-flex align-items-center flex-shrink-0'>
      <div className='d-flex align-items-center ms-3 ms-lg-4'>

      </div>

      {/* Search */}
      {/* <Search
        className='w-lg-250px'
        mobileToggleBtnClass='btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline btn-outline-secondary w-40px h-40px'
      /> */}

      {/* begin::Activities */}
      <div className='d-flex align-items-center ms-3 ms-lg-4'>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-light me-n4'

            data-kt-menu-overflow='true'
            data-kt-menu-placement='bottom-end'
          >
            <span>Last Login: </span>{moment(dates?.timeStamp).format("D MMM YYYY, h:mm a")}
          </button>
          &nbsp;
          &nbsp;
          &nbsp;

          <button
            type='button'
            className='btn btn-sm btn-primary mx-2'
            data-kt-menu-trigger='hover'
            data-kt-menu-overflow='true'
            data-kt-menu-placement='bottom-end'
          >
            Quick Actions
          </button>
          &nbsp;&nbsp;
          <Dropdown2 />

        </div>
        <Link to="/point-of-sale" aria-hidden><button className='btn btn-sm btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary'>Point of sale</button></Link>
        {/* begin::Drawer toggle */}
        {/* <div
          className='btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline btn-outline-secondary w-40px h-40px'
          id='kt_activities_toggle'
        >
          <KTSVG path='/media/icons/duotune/general/gen007.svg' className='svg-icon-1' />
        </div> */}
        {/* end::Drawer toggle */}
      </div>
      {/* end::Activities */}

      {/* CHAT */}
      <div className='d-flex align-items-center ms-3 ms-lg-4'>
        {/* begin::Drawer wrapper */}
        {/* <div
          className='btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline btn-outline-secondary w-40px h-40px position-relative'
          id='kt_drawer_chat_toggle'
        >
          <KTSVG path='/media/icons/duotune/communication/com003.svg' className='svg-icon-1' />

          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
        </div> */}
        {/* end::Drawer wrapper */}
      </div>

      {/* begin::Sidebar Toggler */}
      <button
        className='btn btn-icon btn-active-icon-primary w-40px h-40px d-xxl-none ms-2 me-n2 d-none'
        id='kt_sidebar_toggler'
      >
        <KTSVG path='/media/icons/duotune/coding/cod001.svg' className='svg-icon-2x' />
      </button>
      {/* end::Sidebar Toggler */}

      {/* begin::Theme mode */}
      <div className={'d-flex align-items-center ms-3 ms-lg-4'}>
        <ThemeModeSwitcher toggleBtnClass='btn-color-gray-700 btn-active-color-primary btn-outline btn-outline-secondary w-40px h-40px' />
      </div>
      {/* end::Theme mode */}
    </div>
  )
}

export { Topbar }
