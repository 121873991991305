import React, { Fragment } from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { UpdateProductPic } from './UpdateProductPic'
import { updateDefaultPic } from '../../../services/ProductService'
import { toast } from 'react-toastify'

interface ImageCardProps {
  imageUrl: string
  id: number
  onUpload: () => void
  mappingId: number
  isDefault: boolean
  productId: string | undefined
}

export function ImageCard({ imageUrl, mappingId, id, productId, onUpload, isDefault }: ImageCardProps) {
  const noImageUrl = toAbsoluteUrl('/media/images/No-image-found.jpg')
  const [show, setShow] = React.useState(false)
  const markAsDefault = (mappingId: number, productId: number) => {
    updateDefaultPic(productId, mappingId).then((res) => {
      if (res.data.success) {
        toast.success(res.data.message)
        onUpload()
      }
      else {
        toast.error(res.data.message)
      }
    }).catch((err) => {
      toast.error(err.message)
    }
    )
  }
  return (
    <Fragment>
      <div className='card mb-4 mx-3 shadow-sm' key={id} style={{ width: '18rem' }}>
        <img
          src={imageUrl}
          className='card-img-top'
          alt='...'
          onError={(e) => {
            e.currentTarget.src = noImageUrl
          }}
        />
        <div className='card-body d-flex flex-column justify-content-center'>
          <div className='d-flex justify-content-between align-items-center'>
            <button className='btn btn-sm btn-primary' onClick={() => setShow(true)}>
              Update
            </button>
            <button className='btn btn-sm btn-secondary'
              disabled={isDefault}
              type='button'
              onClick={() => markAsDefault(mappingId, parseInt(productId as string))}
            >
              Default
            </button>
          </div>
        </div>
      </div>
      {show && (
        <UpdateProductPic
          id={id}
          setShow={setShow}
          onUpload={() => {
            onUpload()
            setShow(false)
          }}
          show={show}
          type='image'
        />
      )}
    </Fragment>
  )
}
