import { CompanyDetail } from "../IOrg/ICompanyModel";

// interface for view
interface DeliveryChallanModel {
    deliveryChallan: DeliveryChallan;
    deliveryChallanItems: DeliveryChallanItem[] | null;
    organization : CompanyDetail;
  }
  
  interface DeliveryChallan {
    challanNumber: string | null;
    challanDate: string | null; // Date time string
    challanTypeId: number;
    referenceNo: string | null;
    customerId: number | null;
    status: number;
    terms: string | null;
    notes: string | null;
    subAmount: number
    taxAmount: number
    totalAmount: number
    amountInWords: string | null;
    branchId: number;
    grnId: number;
    supplierId: number | null;
    vehicleNo: string | null;
    isCancel: boolean;
    totalItems: number;
    billName: string | null;
    billAddress: string | null;
    billCity: string | null;
    billState: string | null;
    billPostalCode: string | null;
    shipName: string | null;
    shipAddress: string | null;
    shipCity: string | null;
    shipState: string | null;
    shipPostalCode: string | null;
    companyGstin: string | null;
    customerGstin: string | null;
    customerPan: string | null;
    customerEmail: string | null;
    customerContactNo: string | null;
    id: number;
    createdOn: string; // Date time string
    createdBy: number;
    modifiedOn: string | null; // Date time string
    modifiedBy: number | null;
    companyId: number;
  }
  interface IInvoicePart {
    challanDate: string | null;
    challanNumber: string | null;
    // discount: number | null;
    subAmount: number | null;
    taxAmount: number | null;
    // addTax: number | null;
  }
  
  // interface for update
interface DCPartial {
  id: number
  challanNumber: string
  challanDate: string
  status: number
  customerId: number
  subAmount: number
  taxAmount: number
  totalAmount: number
  amountInWords: string
  // description: string
  isCancel: boolean
  branchId: number
  deliveryChallanItems: DeliveryChallanItem[]
  dcBillBy: DCBillBy
  dcShipTo: DCShipTo
}

interface DeliveryChallanItem {
  id: number
  challanId: number
  productId: number
  quantity: number
  unitPrice: number
  discount: number
  taxPercentage: number
  taxAmount: number
  totalAmount: number
}

interface DCBillBy {
  billName: string
  billAddress: string
  billCity: string
  billState: string
  billPostalCode: string
  companyGstin: string
}

interface DCShipTo {
  shipName: string
  shipAddress: string
  shipCity: string
  shipState: string
  shipPostalCode: string
  customerGstin: string|null
  customerPan: string|null
  customerEmail: string|null
  customerContactNo: string|null
}
const dcPartialInitialValues: DCPartial = {
  id: 0,
  challanNumber: '',
  challanDate: '',
  status: 0,
  customerId: 0,
  subAmount: 0,
  taxAmount: 0,
  totalAmount: 0,
  amountInWords: '',
  isCancel: false,
  branchId: 0,
  deliveryChallanItems: [],
  dcBillBy: {
    billName: '',
    billAddress: '',
    billCity: '',
    billState: '',
    billPostalCode: '',
    companyGstin: '',
  },
  dcShipTo: {
    shipName: '',
    shipAddress: '',
    shipCity: '',
    shipState: '',
    shipPostalCode: '',
    customerGstin: '',
    customerPan: '',
    customerEmail: '',
    customerContactNo: '',
  },
}

const deliveryChallanItemInitialValues: DeliveryChallanItem = {
  id: 0,
  challanId: 0,
  productId: 0,
  quantity: 0,
  unitPrice: 0,
  discount: 0,
  taxPercentage: 0,
  taxAmount: 0,
  totalAmount: 0,
}

const dcBillByInitialValues: DCBillBy = {
  billName: '',
  billAddress: '',
  billCity: '',
  billState: '',
  billPostalCode: '',
  companyGstin: '',
}

const dcShipToInitialValues: DCShipTo = {
  shipName: '',
  shipAddress: '',
  shipCity: '',
  shipState: '',
  shipPostalCode: '',
  customerGstin: '',
  customerPan: '',
  customerEmail: '',
  customerContactNo: '',
}
const deliveryChallan: DeliveryChallan = {
    challanNumber: null,
    challanDate: null,
    challanTypeId: 0,
    referenceNo: null,
    customerId: null,
    status: 0,
    terms: null,
    notes: null,
    subAmount: 0,
    taxAmount: 0,
    totalAmount: 0,
    amountInWords: null,
    branchId: 0,
    grnId: 0,
    supplierId: null,
    vehicleNo: null,
    isCancel: false,
    totalItems: 0,
    billName: null,
    billAddress: null,
    billCity: null,
    billState: null,
    billPostalCode: null,
    shipName: null,
    shipAddress: null,
    shipCity: null,
    shipState: null,
    shipPostalCode: null,
    companyGstin: null,
    customerGstin: null,
    customerPan: null,
    customerEmail: null,
    customerContactNo: null,
    id: 0,
    createdOn: '', // current datetime string, e.g. new Date().toISOString()
    createdBy: 0,
    modifiedOn: null,
    modifiedBy: null,
    companyId: 0,
  };
  
export {
  dcPartialInitialValues,
  deliveryChallanItemInitialValues,
  dcBillByInitialValues,
  dcShipToInitialValues,
  deliveryChallan
}
export type {DCPartial, DeliveryChallanItem, DCBillBy, DCShipTo,DeliveryChallan,DeliveryChallanModel,IInvoicePart}
