
export interface IPurchaseOrderModel {
    id: number
    poNumber: string
}
export interface IPOAdditionalTax {
    id: number,
    taxId: number,
    taxName: string,
    taxAmount: number,
    taxPercent: number,
    purchaseOrderId: number,
}


export interface IPOAdditionalDiscount {
    id: number,
    discountId: number,
    discountName: string,
    discountAmount: number,
    discountPercent: number,
    purchaseOrderId: number,
}
export const poAddDisInitValues: IPOAdditionalDiscount =
{
    id: 0,
    discountId: 0,
    discountName: "",
    discountAmount: 0,
    discountPercent: 0,
    purchaseOrderId: 0
}
export const poAddtaxInitValues: IPOAdditionalTax =
{
    id: 0,
    taxId: 0,
    taxName: "",
    taxAmount: 0,
    taxPercent: 0,
    purchaseOrderId: 0
}
export interface IPurchaseOrderSearch {
    id: number;
    supplierId: number | null;
    billName: string;
    billAddress: string;
    billCity: string;
    billPostalCode: string;
    billState: string;
    shipName: string;
    shipAddress: string;
    shipCity: string;
    shipState: string;
    shipPostalCode: string;
    purchaseOrderNumber: string;
    purchaseOrderDate: Date | null;
    purchaseOrderDueDate: Date | null;
    branchGstin: string;
    branchPan: string;
    amountInWords: string;
    subAmount: number | null;
    taxAmount: number | null;
    totalAmount: number | null;
    amountDue: number | null;
    status: number;
    addTax: number | null;
    terms: string;
    notes: string;
    isLapsed: boolean | null;
    lapseReason: string;
    branchId: number;
    serialNumber: number;
    itemsCount: number;
    itemsCompleted: number;
    ict: number | null;
    companyBranchId: number;
    companyName: string;
    companyAddress: string;
    companyCity: string;
    companyState: string;
    companyPostalCode: string;
    supplierName: string;
    supplierEmail: string;
    supplierContactNo: string;
    supplierAddress: string;
    supplierStateId: number;
    billStateName: string;
    statusText: string;
    purchaseOrderItem: IPurchaseOrderItem[];
    totalCount: number;
    daysDiff: number;
    thumbPath: string;
    createdByName: string;
    totalDispatched: number;
    companyGstin: string;
}

export interface IPurchaseOrderItem {
    id: number;
    purchaseOrderId: number;
    itemId: number;
    itemName: string;
    itemDesc: string;
    hsnSac: string;
    price: number;
    unit: string;
    discountPercent: number;
    discount: number;
    taxPercent: number;
    taxAmount: number;
    grossAmount: number;
    amount: number;
    quantity: number;
    dispatched: number | null;
    accepted: number | null;
    rejected: number | null;
    replaced: number | null;
    returned: number | null;
    freightCharges: number;
    poQty: number;
    invoicedQty: number;
    pendingQty: number;
    totalDispatched: number;

}
export const poInitValues: IPurchaseOrderSearch =
{
    id: 0,
    supplierId: 0,
    billName: "",
    billAddress: "",
    billCity: "",
    billPostalCode: "",
    billState: "",
    shipName: "",
    shipAddress: "",
    shipCity: "",
    shipState: "",
    shipPostalCode: "",
    purchaseOrderNumber: "",
    purchaseOrderDate: new Date(),
    purchaseOrderDueDate: new Date(),
    branchGstin: "",
    branchPan: "",
    amountInWords: "",
    subAmount: 0,
    taxAmount: 0,
    totalAmount: 0,
    amountDue: 0,
    status: 0,
    addTax: 0,
    terms: "",
    notes: "",
    isLapsed: false,
    lapseReason: "",
    branchId: 0,
    serialNumber: 0,
    itemsCount: 0,
    itemsCompleted: 0,
    ict: 0,
    companyBranchId: 0,
    companyName: "",
    companyAddress: "",
    companyCity: "",
    companyState: "",
    companyPostalCode: "",
    supplierName: "",
    supplierEmail: "",
    supplierContactNo: "",
    supplierAddress: "",
    supplierStateId: 0,
    billStateName: "",
    statusText: "",
    purchaseOrderItem: [],
    totalCount: 0,
    daysDiff: 0,
    thumbPath: "",
    createdByName: "",
    totalDispatched: 0,
    companyGstin: "",
}
export const poItemInitValues: IPurchaseOrderItem =
{
    id: 0,
    purchaseOrderId: 0,
    itemId: 0,
    itemName: "",
    itemDesc: "",
    hsnSac: "",
    price: 0,
    unit: "",
    discountPercent: 0,
    discount: 0,
    taxPercent: 0,
    taxAmount: 0,
    grossAmount: 0,
    amount: 0,
    quantity: 0,
    dispatched: 0,
    accepted: 0,
    rejected: 0,
    replaced: 0,
    returned: 0,
    freightCharges: 0,
    poQty: 0,
    invoicedQty: 0,
    pendingQty: 0,
    totalDispatched: 0,
}

export interface IPurchaseOrderInput {
    id?: number;
    supplierId?: number | null;
    billName?: string | null;
    billAddress?: string | null;
    billCity?: string | null;
    billPostalCode?: string | null;
    billState?: string | null;
    billGstin?: string | null;
    shipName?: string | null;
    shipAddress?: string | null;
    shipCity?: string | null;
    shipState?: string | null;
    shipPostalCode?: string | null;
    shipGstin?: string | null;
    purchaseOrderNumber?: string | null;
    purchaseOrderDate?: Date | null;
    purchaseOrderDueDate?: Date | null;
    supplierName?: string | null;
    supplierAddress?: string | null;
    supplierCity?: string | null;
    supplierState?: string | null;
    supplierPostalCode?: string | null;
    supplierGstin?: string | null;
    companyPan?: string | null;
    customerGstin?: string | null;
}

export const poInputInitValues: IPurchaseOrderInput =
{
    id: 0,
    supplierId: 0,
    billName: "",
    billAddress: "",
    billCity: "",
    billPostalCode: "",
    billState: "",
    shipName: "",
    shipAddress: "",
    shipCity: "",
    shipState: "",
    shipPostalCode: "",
    shipGstin: "",
    purchaseOrderNumber: "",
    purchaseOrderDate: new Date(),
    purchaseOrderDueDate: new Date(),
    supplierName: "",
    supplierAddress: "",
    supplierCity: "",
    supplierState: "",
    supplierPostalCode: "",
    supplierGstin: "",
    companyPan: "",
    customerGstin: "",
}
