import axiosInstance from '../services/AxiosInstance';

export function postQuotation(data) {
    return axiosInstance.post(`Quotations/addNew`, data);
}
export function sendQuotationMail(id, data){
    const email = data;
    return axiosInstance.post(`Quotations/${id}/send-email`, email);
}

export function searchQuotation(searchParams) {
    return axiosInstance.get(`quotations/search`,searchParams);
}

export function getQuotations(values) {
    return axiosInstance.post(`Quotations/quotationsearch`, values);
}
    
export function getQuotationDetails(id) {
    return axiosInstance.get(`Quotations/${id}`);
}

export function updateQuotationStatus({ quoteId, statusId }) {
    return axiosInstance.put(`quotations/update/quotation/${quoteId}/status/${statusId}`);
}
export function getquotationHtml(id, template, label, companyCode) {
    return axiosInstance.get(`quotations/get-html?id=${id}&template=${template}&label=${label}&companyCode=${companyCode}`);
}
export function quotationUpdate(data,type){
    return axiosInstance.put(`quotations/quote-update/${type}`,data);
}
export function updateQuotationFields(dto) {
    return axiosInstance.put(`quotations/update/details`,dto);
}
export function getQuotationTaxes (id){
    return axiosInstance.get(`additionaltax/quotes-additional-taxes/${id}`)
}
export function getInvoiceTaxes (id){
    return axiosInstance.get(`additionaltax/invoices-additional-taxes/${id}`)
}
export function updateQuotationTaxes (dto){
    return axiosInstance.put(`quotations/update/quotation-taxes`,dto)
}
export function updateInvoiceTaxes (dto){
    return axiosInstance.put(`invoices/update/invoice-taxes`,dto)
}
export function getQuotationSerialNumber() {
    return axiosInstance.get(`quotations/quotation-number`);
}