import React from "react";
import { Pricing } from "../modules/auth/components/Pricing";

export function PricingInner() {
    const text: string = 'Don\'t miss out on the exclusive features and benefits of our premium plan. Upgrade now to unlock more storage, advanced tools, and priority support.';

    return <>
        <div className='card'>
            <div className='card-body'>
                <Pricing text={text} />
            </div>
        </div>
    </>
}