import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import { getStates } from '../../../services/CommonService'
import { getCompanyDetails } from '../../../services/CompanyService'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { ICompanyDetails, companyInitValues as initialValues } from '../../../_models/company-model'
import { AccountHeader } from './AccountHeader'
import Addbank from './components/bankInformation/Addbank'
import {Listbanks} from './components/bankInformation/Listbanks'
import { PasswordChange } from './components/ChangePassword'
import { Overview } from './components/Overview'
import { Settings } from './components/settings/Settings'


const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Account',
    path: '/account/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const AccountPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [stateArr, setStateArr] = useState<any>([]);
  const [data, setData] = useState<ICompanyDetails>(initialValues)

  const bindStates = (countryId: any) => {
    getStates(countryId).then((res: any) => {
      const array = res.data.data;
      setStateArr(array);
    }).catch((err: any) => {
      console.log(err);
    })
  }

  const bindDetails = () => {
    setLoading(true);
    setTimeout(() => {
      getCompanyDetails()
        .then((res: any) => {
          let _data = res.data.data;
          setData(_data);
        })
        .catch(() => {

        }).finally(() => {
          setLoading(false);
        });
    }, 500);
  }

  useEffect(() => {
    bindStates(99);
    bindDetails();
  }, []);


  return (
    <Routes>
      <Route
        element={
          <>
            <AccountHeader data={data} />
            <Outlet />
          </>
        }
      >
        <Route
          path='overview'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Overview</PageTitle>
              <Overview data={data} />
            </>
          }
        />
        <Route
          path='settings'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Settings</PageTitle>
              <Settings />
            </>
          }
        />
         <Route
          path='change-password'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Change Password</PageTitle>
              <PasswordChange />
            </>
          }
        />
        <Route
          path='list-banks'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Bank Information</PageTitle>
              <Listbanks />
            </>
          }
        />
        <Route 
          path='bank/add'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Add Bank</PageTitle>
              <Addbank />
            </>
          }
        />
        <Route 
          path='bank/edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Add Bank</PageTitle>
              <Addbank />
            </>
          }
        />
        <Route index element={<Navigate to='/account/overview' />} />
      </Route>
    </Routes>
  )
}

export default AccountPage
