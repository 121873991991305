import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { KTSVG } from '../../../../_metronic/helpers';
import { InvoiceAddFormV2 } from './InvoiceAddFormV2';
import { InvoiceAddModalForm } from './InvoiceAddModalForm';

const InvoiceAddModal = (props: any) => {
    const { setItemIdForUpdate, setShowInvoiceModal, afterInvoiceUpdate } = props;

    const navigate = useNavigate();

    const closeModal = () => {
        if (setShowInvoiceModal) {
            setShowInvoiceModal(false);
        } else {
            { navigate('/invoices'); }
        }
    }

    useEffect(() => {

    }, [])

    return (
        <>
            <div className='card'>
                <div className="card-body">
                    <div
                        className='modal fade show d-block'
                        id='kt_modal_add_user'
                        role='dialog'
                        tabIndex={-1}
                        aria-modal='true'
                    >
                        {/* begin::Modal dialog */}
                        <div className='modal-dialog modal-dialog-centered modal-fullscreen'>
                            {/* begin::Modal content */}
                            <div className='modal-content'>
                                <div className='modal-header'>
                                    {/* begin::Modal title */}
                                    <h2 className='fw-bolder'>Add Invoice</h2>
                                    {/* end::Modal title */}

                                    {/* begin::Close */}
                                    <div
                                        className='btn btn-icon btn-sm btn-active-icon-primary'
                                        data-kt-users-modal-action='close'
                                        onClick={() => closeModal()}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                    </div>
                                    {/* end::Close */}
                                </div>
                                <div className='modal-body'>
                                    <InvoiceAddFormV2 setShowInvoiceModal={setShowInvoiceModal} afterInvoiceUpdate={afterInvoiceUpdate} />
                                </div>
                                {/* end::Modal body */}
                            </div>
                            {/* end::Modal content */}
                        </div>
                        {/* end::Modal dialog */}
                    </div>
                </div>
            </div>

            {/* begin::Modal Backdrop */}
            <div className='modal-backdrop fade show'></div>
            {/* end::Modal Backdrop */}
        </>
    )
}

export { InvoiceAddModal }
