import React from "react";
import ReactPlayer from 'react-player'
import { Link } from "react-router-dom";

export function FeaturesMore() {
    return (<>
        <section className="bg-white dark:bg-gray-900  pt-lg-20 pb-lg-20 mt-lg-20">
            <div className="items-center max-w-screen-xl px-4 py-8 mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24 lg:py-24 lg:px-6 pt-lg-20  pb-lg-20">
                <div className="col-span-2 mb-8">
                    <p className="text-lg font-medium text-purple-600 dark:text-purple-700">Trusted Worldwide</p>
                    <h2 className="mt-3 mb-4 text-3xl font-extrabold tracking-tight text-gray-900 md:text-3xl dark:text-white">Trustes by both small and large enterprises.</h2>
                    <p className="font-light text-gray-500 sm:text-xl ">
                        We believe that these characteristics have the potential to transform this software into a potent instrument for enterprises seeking to simplify their activities and enhance their management of cash flow.
                    </p>
                    <div className="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
                        <div>
                            <a href="https://www.amberinfotech.com" target={'_blank'} className="inline-flex items-center text-base font-medium text-purple-600 hover:text-purple-800 dark:text-purple-700 dark:hover:text-purple-700">
                                Know the company
                                <svg className="w-5 h-5 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            </a>
                        </div>
                        <div>
                            <Link to={'/auth/registration'} className="inline-flex items-center text-base font-medium text-purple-600 hover:text-purple-800 dark:text-purple-700 dark:hover:text-purple-700">
                                Try a free demo
                                <svg className="w-5 h-5 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col-span-2 space-y-8 md:grid md:grid-cols-2 md:gap-12 md:space-y-0">
                    <div>
                        <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path opacity="0.4" d="M20.2693 17.1899C19.2693 18.0999 17.9793 18.5999 16.6293 18.5899H16.5393C16.8393 17.9699 16.9993 17.2699 16.9993 16.5299C16.9993 13.7699 14.7593 11.5299 11.9993 11.5299C9.23932 11.5299 6.99932 13.7699 6.99932 16.5299C6.99932 17.2699 7.15934 17.9699 7.45934 18.5899H5.5393C0.859297 18.2599 0.859297 11.4499 5.5393 11.1199H5.58935C2.37935 2.1899 15.9193 -1.3801 17.4693 7.9999C21.7993 8.5499 23.5493 14.3199 20.2693 17.1899Z" fill="#0818a8"></path> <path d="M12 11.53C9.24 11.53 7 13.77 7 16.53C7 17.27 7.16002 17.97 7.46002 18.59C7.54002 18.77 7.62998 18.94 7.72998 19.1C8.58998 20.55 10.18 21.53 12 21.53C13.82 21.53 15.41 20.55 16.27 19.1C16.37 18.94 16.46 18.77 16.54 18.59C16.84 17.97 17 17.27 17 16.53C17 13.77 14.76 11.53 12 11.53ZM14.07 16.1L11.94 18.07C11.8 18.2 11.61 18.27 11.43 18.27C11.24 18.27 11.05 18.2 10.9 18.05L9.90997 17.06C9.61997 16.77 9.61997 16.29 9.90997 16C10.2 15.71 10.68 15.71 10.97 16L11.45 16.48L13.05 15C13.36 14.72 13.83 14.74 14.11 15.04C14.39 15.34 14.37 15.81 14.07 16.1Z" fill="#0818a8"></path> </g></svg>
                        <h3 className="mb-2 text-2xl font-bold dark:text-white">Cloud Based</h3>
                        <p className="font-light text-gray-500 ">For easy access from anywhere, anytime</p>
                    </div>
                    <div>
                        <svg fill="#0818a8" width="64px" height="64px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M10 3H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zm10 0h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM10 13H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1zm8 1h-2v2h-2v2h2v2h2v-2h2v-2h-2z"></path></g></svg>
                        <h3 className="mb-2 text-2xl font-bold dark:text-white">Customizable</h3>
                        <p className="font-light text-gray-500 ">To meet the unique needs of your business</p>
                    </div>
                    <div>
                        <svg width="64px" height="64px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke=""><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill="#0818a8" fill-rule="evenodd" d="M3 6a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V6zm10 2a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0V8zm-4 3a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0v-5zm8 3a1 1 0 1 0-2 0v2a1 1 0 1 0 2 0v-2z" clip-rule="evenodd"></path></g></svg>
                        <h3 className="mb-2 text-2xl font-bold dark:text-white">Insights</h3>
                        <p className="font-light text-gray-500 ">Data analytics for valuable insights</p>
                    </div>
                    <div>
                        <svg fill="#0818a8" width="64px" height="64px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>expand</title> <path d="M13.816 5.989l-7.785 0.046 0.003 7.735 2.59-2.591 3.454 3.454 2.665-2.665-3.453-3.454 2.526-2.525zM12.079 17.35l-3.454 3.455-2.59-2.592-0.003 7.799 7.785-0.018-2.526-2.525 3.454-3.453-2.666-2.666zM19.922 14.633l3.453-3.454 2.59 2.591 0.004-7.735-7.785-0.046 2.526 2.525-3.454 3.454 2.666 2.665zM23.375 20.805l-3.453-3.455-2.666 2.666 3.454 3.453-2.526 2.525 7.785 0.018-0.004-7.799-2.59 2.592z"></path> </g></svg>
                        <h3 className="mb-2 text-2xl font-bold dark:text-white">Scalable</h3>
                        <p className="font-light text-gray-500 ">Solution that can grow with your business needs</p>
                    </div>
                </div>
            </div>
        </section>
        {/*begin::Projects Section*/}
        <div className="mb-lg-100 position-relative z-index-2 d-none">
            {/*begin::Container*/}
            <div className="container-fluid">

                <div className="row w-100 gy-10 mt-20 mb-md-20">
                    <div className="col-md-12">
                        {/*begin::Heading*/}
                        <div className="text-center mb-17">
                            {/*begin::Title*/}
                            <h3 className="fs-2hx text-dark mb-5" id="online-invoicing-software"
                                data-kt-scroll-offset="{default: 100, lg: 150}">Amber Bills is for everyone</h3>
                            {/*end::Title*/}
                            {/*begin::Text*/}
                            <div className="fs-5 fw-semibold">
                                <p className="mb-2">
                                    Billing software for all types of organisations, Amber Bills is straightforward and user-friendly.
                                </p>
                            </div>
                            {/*end::Text*/}
                        </div>
                        {/*end::Heading*/}

                    </div>
                    {/*begin::Col*/}
                    <div className="col-md-4">
                        {/*begin::Story*/}
                        <div className="card">
                            <div className="card-body">
                                <div className="text-center mb-10 mb-md-0">
                                    {/*begin::Illustration*/}
                                    <img src="media/illustrations/sketchy-1/2.png" className="mh-125px mb-9" alt="" />
                                    {/*end::Illustration*/}
                                    {/*begin::Heading*/}
                                    <div className="d-flex flex-center mb-5">
                                        {/*begin::Badge*/}
                                        <span className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">1</span>
                                        {/*end::Badge*/}
                                        {/*begin::Title*/}
                                        <div className="fs-5 fs-lg-3 fw-bold text-dark">Jane Miller</div>
                                        {/*end::Title*/}
                                    </div>
                                    {/*end::Heading*/}
                                    {/*begin::Description*/}
                                    <div className="fw-semibold fs-6 fs-lg-4 text-muted">Save thousands to millions of bucks
                                        <br />by using single tool for different
                                        <br />amazing and great</div>
                                    {/*end::Description*/}
                                </div>
                            </div>

                        </div>

                        {/*end::Story*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-md-4">
                        {/*begin::Story*/}
                        <div className="card">
                            <div className="card-body"></div>
                            <div className="text-center mb-10 mb-md-0">
                                {/*begin::Illustration*/}
                                <img src="media/illustrations/sketchy-1/8.png" className="mh-125px mb-9" alt="" />
                                {/*end::Illustration*/}
                                {/*begin::Heading*/}
                                <div className="d-flex flex-center mb-5">
                                    {/*begin::Badge*/}
                                    <span className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">2</span>
                                    {/*end::Badge*/}
                                    {/*begin::Title*/}
                                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Setup Your App</div>
                                    {/*end::Title*/}
                                </div>
                                {/*end::Heading*/}
                                {/*begin::Description*/}
                                <div className="fw-semibold fs-6 fs-lg-4 text-muted">Save thousands to millions of bucks
                                    <br />by using single tool for different
                                    <br />amazing and great</div>
                                {/*end::Description*/}
                            </div>
                        </div>
                    </div>
                    {/*begin::Col*/}
                    <div className="col-md-4">
                        {/*begin::Story*/}
                        <div className="card">
                            <div className="card-body"></div>
                            <div className="text-center mb-10 mb-md-0">
                                {/*begin::Illustration*/}
                                <img src="media/illustrations/sketchy-1/12.png" className="mh-125px mb-9" alt="" />
                                {/*end::Illustration*/}
                                {/*begin::Heading*/}
                                <div className="d-flex flex-center mb-5">
                                    {/*begin::Badge*/}
                                    <span className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">3</span>
                                    {/*end::Badge*/}
                                    {/*begin::Title*/}
                                    <div className="fs-5 fs-lg-3 fw-bold text-dark">Enjoy Nautica App</div>
                                    {/*end::Title*/}
                                </div>
                                {/*end::Heading*/}
                                {/*begin::Description*/}
                                <div className="fw-semibold fs-6 fs-lg-4 text-muted">Save thousands to millions of bucks
                                    <br />by using single tool for different
                                    <br />amazing and great</div>
                                {/*end::Description*/}
                            </div>
                        </div>
                        {/*end::Story*/}
                    </div>
                    {/*end::Col*/}
                </div>


                {/*end::Story*/}
            </div>
            {/*end::Col*/}
        </div>



    </>)
}