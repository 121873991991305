import axios from 'axios';
import axiosInstance from '../services/AxiosInstance';
export function getallAdditionalTax() {
    return axiosInstance.get(`additionalTax/getall`);
}

export function saveAdditionalTax(id, postData) {
    if (id > 0) {
        return axiosInstance.put(`additionalTax/update`, postData); 
    }
    return axiosInstance.post(`additionalTax/add`, postData); 
}

export function getSingleAdditonalTax(id) {
    return axiosInstance.get(`additionalTax/${id}`);
}

export function getAllTaxes(){
    return axiosInstance.get('tax/getAll')
}
export function getTaxById(id){
    return axiosInstance.get(`tax/${id}`)
}
export function addTax(data){
    return axiosInstance.post(`tax/add`,data)
}
export function updateTax(data){
    return axiosInstance.put('tax/update',data)
}
export function getAdditionaltaxes(){
    return axiosInstance.get('tax/additional')
}