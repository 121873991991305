import axiosInstance from '../services/AxiosInstance';

export function getCategories() {
    return axiosInstance.get(`categories`);
}
export function addCategory(category) {
    return axiosInstance.post(`categories`, category);
}
export function getcategoryDetails(values) {
    const{id}=values;
    return axiosInstance.get(`categories?id=${id}`,values);
}
export function getcategorybyId(id) {
    
    return axiosInstance.get(`categories/${id}`);
}

export function saveCategory(id, postData) {
    if (id>1) {
        return axiosInstance.put(`categories/${id}`, postData);
    }
    return axiosInstance.post(`categories`, postData);
}