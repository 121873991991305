import React, { CSSProperties, useEffect, useState } from "react";
import PurchaseSubModal from "./PurchaseSubModal";
import { getAllSubs, getCurrentPlan } from "../../../../services/account/BankService";

export function Pricing(props: any) {
   const [currentPlan,setCurrentPlan] = useState<any>(null);  
    useEffect(()=>{
        getPlanDetail();
    },[]);
    function getPlanDetail() {
        getCurrentPlan().then((res: any) => {
            if (res.data.success) {
                setCurrentPlan(res.data.data)
            }
        }).catch((err) => {
        })
        
    }
    const basicSettings = [
        { feature: '1 User', avail: true },
        { feature: 'Unlimited Invoices', avail: true },
        { feature: '3 Invoice Templates', avail: true },
        { feature: 'Analytics', avail: true },
        { feature: 'Invoice Sharing', avail: true },
        { feature: 'Inventory management', avail: false },
        { feature: 'Payments', avail: false },
        { feature: 'Custom domain', avail: false },
        { feature: 'Customized features', avail: false },
        { feature: 'In-premise hosting', avail: false },
    ];

    const businessSettings = [
        { feature: '5 Users', avail: true },
        { feature: 'Unlimited Invoices', avail: true },
        { feature: '3 Invoice Templates', avail: true },
        { feature: 'Analytics', avail: true },
        { feature: 'Invoice Sharing', avail: true },
        { feature: 'Inventory management', avail: true },
        { feature: 'Payments', avail: true },
        { feature: 'Custom domain', avail: false },
        { feature: 'Customized features', avail: false },
        { feature: 'In-premise hosting', avail: false },
    ];

    const enterpriseSettings = [
        { feature: 'Unlimited Users', avail: true },
        { feature: 'Unlimited Invoices', avail: true },
        { feature: 'Unlimited Invoice Templates', avail: true },
        { feature: 'Analytics', avail: true },
        { feature: 'Invoice Sharing', avail: true },
        { feature: 'Payments', avail: true },
        { feature: 'Custom domain', avail: true },
        { feature: 'Customized features', avail: true },
        { feature: 'In-premise hosting', avail: true },
    ]


    const [showPaymentModal, setShowPaymentModal] = useState(false);
    return (
        <>

            {/*begin::Pricing Section*/}
            <div className="mt-lg-20">

                {/*begin::Wrapper*/}
                <div className="bg-white dark:bg-gray-900 mb-lg-20 py-lg-20">
                    {/*begin::Container*/}
                    <div className="container">
                        {/*begin::Plans*/}
                        <div className="d-flex flex-column container pt-lg-20">
                            {/*begin::Heading*/}
                            <div className="mb-13 text-center">
                                <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">Crafted specifically for teams in the business sphere such as yours.</h2>
                                <div className="text-gray-900 fw-semibold fs-5"></div>
                                <p className="mb-5 font-light text-gray-500 sm:text-xl ">
                                    {props.text}</p>
                            </div>
                            {/*end::Heading*/}
                            {/*begin::Pricing*/}
                            <div className="text-center" id="kt_pricing">
                                {/*begin::Nav group*/}
                                {/* <div className="nav-group landing-dark-bg d-inline-flex mb-15" data-kt-buttons="true" style={{ border: "1px dashed #2B4666;" }}>
                                    <a href="#" className="btn btn-color-gray-600 btn-active btn-active-success px-6 py-3 me-2 active" data-kt-plan="month">Monthly</a>
                                    <a href="#" className="btn btn-color-gray-600 btn-active btn-active-success px-6 py-3" data-kt-plan="annual">Annual</a>
                                </div> */}
                                {/*end::Nav group*/}
                                {/*begin::Row*/}
                                <div className="row g-10">
                                    {/*begin::Col*/}
                                    <div className="col-xl-4">
                                        <div className="d-flex h-100 align-items-center">
                                            {/*begin::Option*/}
                                            <div className="w-100 d-flex flex-column flex-center rounded-3 bg-body py-15 px-10">
                                                {/*begin::Heading*/}
                                                <div className="mb-7 text-center">
                                                    {/*begin::Title*/}
                                                    <h1 className="text-dark mb-5 fw-boldest">Startup</h1>
                                                    {/*end::Title*/}
                                                    {/*begin::Description*/}
                                                    <div className="text-gray-400 fw-semibold mb-5">Best Settings for Startups</div>
                                                    {/*end::Description*/}
                                                    {/*begin::Price*/}
                                                    <div className="text-center">
                                                        <PriceContainer />
                                                    </div>
                                                    {/*end::Price*/}
                                                </div>
                                                {/*end::Heading*/}
                                                {/*begin::Features*/}
                                                <div className="w-100 mb-10">
                                                    {/*begin::Item*/}
                                                    {
                                                        basicSettings && basicSettings.map((item: any) => (
                                                            <div className="d-flex flex-stack mb-5">
                                                                <span className="fw-semibold fs-6 text-gray-800 text-start pe-3">{item.feature}</span>
                                                                {/*begin::Svg Icon | path: icons/duotune/general/gen043.svg*/}

                                                                {item.avail ?
                                                                    <span className="svg-icon svg-icon-1 svg-icon-success">
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
                                                                            <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor" />
                                                                        </svg>
                                                                    </span> : <span className="svg-icon svg-icon-1">
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
                                                                            <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)" fill="currentColor" />
                                                                            <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)" fill="currentColor" />
                                                                        </svg>
                                                                    </span>
                                                                }

                                                                {/*end::Svg Icon*/}
                                                            </div>
                                                        ))
                                                    }
                                                    {/*end::Item*/}

                                                </div>
                                                {/*end::Features*/}
                                                {/*begin::Select*/}
                                                {currentPlan && currentPlan.id === 2 ?
                                                    <button type="button" className="btn btn-primary w-100"
                                                    >Current Plan</button> :
                                                    <button type="button" className="btn btn-primary w-100"
                                                        disabled={currentPlan && currentPlan.id === 2}
                                                        onClick={() => { setShowPaymentModal(true) }}
                                                    >Select</button>
                                                }
                                            </div>
                                            {/*end::Option*/}
                                        </div>
                                    </div>
                                    {/*end::Col*/}
                                    {/*begin::Col*/}
                                    <div className="col-xl-4">
                                        <div className="d-flex h-100 align-items-center">
                                            {/*begin::Option*/}
                                            <div className="w-100 d-flex flex-column flex-center rounded-3 bg-primary py-20 px-10">
                                                {/*begin::Heading*/}
                                                <div className="mb-7 text-center">
                                                    {/*begin::Title*/}
                                                    <h1 className="text-white mb-5 fw-boldest">Business</h1>
                                                    {/*end::Title*/}
                                                    {/*begin::Description*/}
                                                    <div className="text-white opacity-75 fw-semibold mb-5">Best Settings for Business</div>
                                                    {/*end::Description*/}
                                                    {/*begin::Price*/}
                                                    <div className="text-center">
                                                        <span className="mb-2 text-white">₹</span>
                                                        <span className="fs-3x fw-bold text-white" data-kt-plan-price-month="199" data-kt-plan-price-annual="1999">8000</span>
                                                        <span className="fs-7 fw-semibold text-white opacity-75" data-kt-plan-price-month="Mon" data-kt-plan-price-annual="Ann">/ Year</span>
                                                    </div>
                                                    {/*end::Price*/}
                                                </div>
                                                {/*end::Heading*/}
                                                {/*begin::Features*/}
                                                <div className="w-100 mb-10">

                                                    {/*begin::Item*/}
                                                    {
                                                        businessSettings && businessSettings.map((item: any) => (
                                                            <div className="d-flex flex-stack mb-5">
                                                                <span className="fw-semibold fs-6 text-white opacity-75 text-start pe-3">{item.feature}</span>
                                                                {/*begin::Svg Icon | path: icons/duotune/general/gen043.svg*/}
                                                                {item.avail ?

                                                                    <span className="svg-icon svg-icon-1 svg-icon-white">
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
                                                                            <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor" />
                                                                        </svg>
                                                                    </span>
                                                                    : <span className="svg-icon svg-icon-1 svg-icon-white">
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
                                                                            <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)" fill="currentColor" />
                                                                            <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)" fill="currentColor" />
                                                                        </svg>
                                                                    </span>
                                                                }
                                                                {/*end::Svg Icon*/}
                                                            </div>
                                                        ))
                                                    }
                                                    {/* <div className="d-flex flex-stack mb-5">
                                                        <span className="fw-semibold fs-6 text-white opacity-75 text-start pe-3">Up to 30 Project Integrations</span>
                                                        <span className="svg-icon svg-icon-1 svg-icon-white">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
                                                                <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor" />
                                                            </svg>
                                                        </span>
                                                    </div> */}
                                                    {/*end::Item*/}

                                                </div>
                                                {/*end::Features*/}
                                                {/*begin::Select*/}
                                                <a href="#" className="btn btn-color-primary btn-active-light-primary btn-light w-100 disabled">Select</a>
                                                <p className="text-gray-400 py-3">
                                                    This plan will be available soon.
                                                </p>
                                                {/*end::Select*/}
                                            </div>
                                            {/*end::Option*/}
                                        </div>
                                    </div>
                                    {/*end::Col*/}
                                    {/*begin::Col*/}
                                    <div className="col-xl-4">
                                        <div className="d-flex h-100 align-items-center">
                                            {/*begin::Option*/}
                                            <div className="w-100 d-flex flex-column flex-center rounded-3 bg-body py-15 px-10">
                                                {/*begin::Heading*/}
                                                <div className="mb-7 text-center">
                                                    {/*begin::Title*/}
                                                    <h1 className="text-dark mb-5 fw-boldest">Enterprise</h1>
                                                    {/*end::Title*/}
                                                    {/*begin::Description*/}
                                                    <div className="text-gray-400 fw-semibold mb-5">Best Settings for Enterprise</div>
                                                    {/*end::Description*/}
                                                    {/*begin::Price*/}
                                                    <div className="text-center">
                                                        {/* <span className="mb-2 text-primary">₹</span> */}
                                                        <span className="fs-3x fw-bold text-primary" data-kt-plan-price-month="999" data-kt-plan-price-annual="9999">Ask</span>
                                                        {/* <span className="fs-7 fw-semibold opacity-50" data-kt-plan-price-month="Mon" data-kt-plan-price-annual="Ann">/ Mon</span> */}
                                                    </div>
                                                    {/*end::Price*/}
                                                </div>
                                                {/*end::Heading*/}
                                                {/*begin::Features*/}
                                                <div className="w-100 mb-10">
                                                    {/*begin::Item*/}
                                                    {
                                                        enterpriseSettings && enterpriseSettings.map((item: any) => (
                                                            <div className="d-flex flex-stack mb-5">
                                                                <span className="fw-semibold fs-6 text-gray-800 text-start pe-3">{item.feature}</span>
                                                                {/*begin::Svg Icon | path: icons/duotune/general/gen043.svg*/}

                                                                {item.avail ?
                                                                    <span className="svg-icon svg-icon-1 svg-icon-success">
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
                                                                            <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor" />
                                                                        </svg>
                                                                    </span> : <span className="svg-icon svg-icon-1">
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
                                                                            <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)" fill="currentColor" />
                                                                            <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)" fill="currentColor" />
                                                                        </svg>
                                                                    </span>
                                                                }

                                                                {/*end::Svg Icon*/}
                                                            </div>
                                                        ))
                                                    }
                                                    {/*end::Item*/}
                                                </div>
                                                {/*end::Features*/}
                                                {/*begin::Select*/}
                                                <a href="#" className="btn btn-primary w-100">Select</a>
                                                {/*end::Select*/}
                                            </div>
                                            {/*end::Option*/}
                                        </div>
                                    </div>
                                    {/*end::Col*/}
                                </div>
                                {/*end::Row*/}
                            </div>
                            {/*end::Pricing*/}
                        </div>
                        {/*end::Plans*/}
                    </div>
                    {/*end::Container*/}
                </div>
                {/*end::Wrapper*/}

            </div>
            {/*end::Pricing Section*/}

            <section className="bg-white dark:bg-gray-900 mt-lg-10 mb-lg-10 pt-lg-20 pm-lg-20 d-none">
                <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
                    <div className="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
                        <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">Crafted specifically for teams in the business sphere such as yours.</h2>
                        <p className="mb-5 font-light text-gray-500 sm:text-xl ">Here at Landwind we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.</p>
                    </div>
                    <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
                        {/* <!-- Pricing Card --> */}
                        <div className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-100 rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                            <h3 className="mb-4 text-2xl font-semibold">Starter</h3>
                            <p className="font-light text-gray-500 sm:text-lg ">Best option for personal use & for your next project.</p>
                            <div className="flex items-baseline justify-center my-8">
                                <span className="mr-2 text-5xl font-extrabold">$29</span>
                                <span className="text-gray-500 ">/month</span>
                            </div>
                            {/* <!-- List --> */}
                            <ul role="list" className="mb-8 space-y-4 text-left">
                                <li className="flex items-center space-x-3">
                                    {/* <!-- Icon --> */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                    <span>Individual configuration</span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* <!-- Icon --> */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                    <span>No setup, or hidden fees</span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* <!-- Icon --> */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                    <span>Team size: <span className="font-semibold">1 developer</span></span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* <!-- Icon --> */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                    <span>Premium support: <span className="font-semibold">6 months</span></span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* <!-- Icon --> */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                    <span>Free updates: <span className="font-semibold">6 months</span></span>
                                </li>
                            </ul>
                            <a href="#" className="text-white bg-purple-600 hover:bg-purple-700 focus:ring-4 focus:ring-purple-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-purple-900">Get started</a>
                        </div>
                        {/* <!-- Pricing Card --> */}
                        <div className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-100 rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                            <h3 className="mb-4 text-2xl font-semibold">Company</h3>
                            <p className="font-light text-gray-500 sm:text-lg ">Relevant for multiple users, extended & premium support.</p>
                            <div className="flex items-baseline justify-center my-8">
                                <span className="mr-2 text-5xl font-extrabold">$99</span>
                                <span className="text-gray-500 " >/month</span>
                            </div>
                            {/* <!-- List --> */}
                            <ul role="list" className="mb-8 space-y-4 text-left">
                                <li className="flex items-center space-x-3">
                                    {/* <!-- Icon --> */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                    <span>Individual configuration</span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* <!-- Icon --> */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                    <span>No setup, or hidden fees</span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* <!-- Icon --> */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                    <span>Team size: <span className="font-semibold">10 developers</span></span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* <!-- Icon --> */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                    <span>Premium support: <span className="font-semibold">24 months</span></span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* <!-- Icon --> */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                    <span>Free updates: <span className="font-semibold">24 months</span></span>
                                </li>
                            </ul>
                            <a href="#" className="text-white bg-purple-600 hover:bg-purple-700 focus:ring-4 focus:ring-purple-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-purple-900">Get started</a>
                        </div>
                        {/* <!-- Pricing Card --> */}
                        <div className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-100 rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                            <h3 className="mb-4 text-2xl font-semibold">Enterprise</h3>
                            <p className="font-light text-gray-500 sm:text-lg ">Best for large scale uses and extended redistribution rights.</p>
                            <div className="flex items-baseline justify-center my-8">
                                <span className="mr-2 text-5xl font-extrabold">$499</span>
                                <span className="text-gray-500 ">/month</span>
                            </div>
                            {/* <!-- List --> */}
                            <ul role="list" className="mb-8 space-y-4 text-left">
                                <li className="flex items-center space-x-3">
                                    {/* <!-- Icon --> */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                    <span>Individual configuration</span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* <!-- Icon --> */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                    <span>No setup, or hidden fees</span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* <!-- Icon --> */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                    <span>Team size: <span className="font-semibold">100+ developers</span></span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* <!-- Icon --> */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                    <span>Premium support: <span className="font-semibold">36 months</span></span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* <!-- Icon --> */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                    <span>Free updates: <span className="font-semibold">36 months</span></span>
                                </li>
                            </ul>
                            <a href="#" className="text-white bg-purple-600 hover:bg-purple-700 focus:ring-4 focus:ring-purple-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-purple-900">Get started</a>
                        </div>
                    </div>
                </div>
            </section>
            {showPaymentModal && <PurchaseSubModal open={showPaymentModal} onClose={() => { setShowPaymentModal(false) }} />}
        </>
    )

}


const PriceContainer = () => {
    const containerStyle: CSSProperties = {
        position: 'relative',
        display: 'inline-block',
        overflow: 'hidden',
    };

    const originalPriceStyle: CSSProperties = {
        position: 'relative',
        display: 'inline-block',
        paddingRight: '10px',
    };

    const cutLineStyle: CSSProperties = {
        content: "''",
        position: 'absolute',
        top: '50%',
        right: '0',
        height: '1px',
        width: '45%',
        backgroundColor: 'black',
        padding: '0px',
    };

    const newPriceStyle: CSSProperties = {
        display: 'inline-block',
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <div style={containerStyle}>
                        <span className="mb-2 text-primary">₹</span>
                        <span className="fs-3x fw-bold text-primary" style={originalPriceStyle}>2500</span>
                        <span className="fs-3x fw-normal text-muted" style={newPriceStyle}>5000</span>
                        <span style={cutLineStyle}></span>
                    </div>
                </div>
            </div>
            <span className="fs-7 fw-semibold opacity-50" data-kt-plan-price-month="Mon" data-kt-plan-price-annual="Ann">/ Year</span>

        </div>
    );
};

export default PriceContainer;
