import { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { ErrorMessage, Field, useFormik } from 'formik';

import { useNavigate } from 'react-router-dom';
import { KTSVG, toAbsoluteUrl } from '../../helpers';
import { toast } from 'react-toastify';
import { toastNotify } from '../../../services/NotifyService';
import { IBugReported, bugInitValues as initValues } from '../../../_models/bug-model';
import { reportBug } from '../../../services/CommonService';
import axios from 'axios';
import { baseURL } from "../../../services/BaseUrl";

const profileDetailsSchema = Yup.object().shape({
    subject: Yup.string().required('Enter subject'),
    message: Yup.string().required('Enter issue details'),
})


const ReportBug = (props: any) => {
    const { setShowReportBugModal, invoiceId, companyCode } = props;
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<IBugReported>(initValues);
    const [pictureId, setPictureId] = useState(0);

    const formik = useFormik<IBugReported>({
        enableReinitialize: true,
        initialValues: data,
        validationSchema: profileDetailsSchema,
        onSubmit: (values, { resetForm }) => {
            const id = toast.loading("Please wait...");
            setLoading(true)
            setTimeout(() => {
                values.attachmentId = pictureId;
                reportBug(values).then((res: any) => {
                    resetForm();
                    toastNotify(id, res);
                    setShowReportBugModal(false);
                }).catch((err: any) => {
                    const res = { data: { message: 'Something went wrong', success: false } };
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                    resetForm();
                })
            }, 1000)
        },
    })
    const navigate = useNavigate();

    const closeModal = () => {
        if (setShowReportBugModal) {
            setShowReportBugModal(false);
        } else {
            { navigate('/invoices'); }
        }
    }

    const handleFileInput = (e: any) => {
        const id = toast.loading("Please wait...");
        // handle validations
        // handle validations
        const file = e.target.files[0];
        if (file.size > 1024 * 1024) {
            const res = { data: { message: 'File size cannot exceed more than 1MB', success: false } };
            // onFileSelectError({ error: "File size cannot exceed more than 1MB" });
            toastNotify(id, res);
        }
        else {

            if (!file.name.match(/\.(jpg|jpeg|png|gif|doc|docx|txt|pdf)$/)) {
                const res = { data: { message: 'Please select valid image', success: false } };
                toastNotify(id, res);
                return false;
            }

            const formData = new FormData();
            formData.append("file", file, file.name);
            axios
                .post(baseURL + '/fileUploads/bugs', formData)
                .then(response => {
                    if (response.data.success) {
                        setPictureId(response.data.data[0]);
                    }
                    toast.dismiss(id);
                })
                .catch(err => {
                    alert(err.message);
                });
        }
    }
    useEffect(() => {

    }, [])

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                    {/* begin::Modal content */}
                    <div className='modal-content'>
                        <div className='modal-header'>
                            {/* begin::Modal title */}
                            <h2 className='fw-bolder'>Report a bug</h2>
                            {/* end::Modal title */}

                            {/* begin::Close */}
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                onClick={() => closeModal()}
                                style={{ cursor: 'pointer' }}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                            </div>
                            {/* end::Close */}
                        </div>
                        <div className='modal-body' style={{ height: '500px', paddingTop: '10px', paddingBottom: '50px' }}>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div id='kt_account_profile_details' className='collapse show'>
                                        <form onSubmit={formik.handleSubmit} noValidate className='form'>
                                            <div className='card-body p-9'>
                                                {/* 1 */}
                                                <div className='row mb-6'>
                                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Issue subject</label>

                                                    <div className='col-lg-6 fv-row'>
                                                        <select
                                                            className='form-control form-control-lg form-control-solid'
                                                            placeholder='Issue subject'
                                                            {...formik.getFieldProps('subject')}
                                                        >
                                                            <option value={0}>Select issue subject</option>
                                                            <option value={1}>Issue in using portal</option>
                                                            <option value={2}>Billing related</option>
                                                            <option value={3}>Other</option>
                                                        </select>
                                                        {formik.touched.subject && formik.errors.subject && (
                                                            <div className='fv-plugins-message-container'>
                                                                <div className='fv-help-block'>{formik.errors.subject}</div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                {/* 2 */}
                                                <div className='row mb-6'>
                                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Tell us what went wrong</label>

                                                    <div className='col-lg-8'>
                                                        <div className='row'>
                                                            <div className='col-lg-12 fv-row'>
                                                                <textarea
                                                                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                                                    placeholder='Issue details'
                                                                    {...formik.getFieldProps('message')}
                                                                ></textarea>
                                                                {formik.touched.message && formik.errors.message && (
                                                                    <div className='fv-plugins-message-container'>
                                                                        <div className='fv-help-block'>{formik.errors.message}</div>
                                                                    </div>
                                                                )}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                {/* 3 */}
                                                <div className='row mb-6'>
                                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                        <span>Upload attachment, if any</span>
                                                    </label>

                                                    <div className='col-lg-8 fv-row'>
                                                        <input type={'file'} className="form-control"
                                                            onChange={handleFileInput} />
                                                    </div>
                                                </div>

                                                <div className='card-footer d-flex justify-content-end py-6'>
                                                    <button type='submit' className='btn btn-primary' disabled={loading}>
                                                        {!loading && 'Send'}
                                                        {loading && (
                                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                                Please wait...{' '}
                                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                            </span>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* end::Modal body */}
                    </div>
                    {/* end::Modal content */}
                </div>
                {/* end::Modal dialog */}
            </div>
            {/* begin::Modal Backdrop */}
            <div className='modal-backdrop fade show'></div>
            {/* end::Modal Backdrop */}
        </>
    )
}

export { ReportBug }
