import axios from 'axios';
// import swal from "sweetalert";
// import {
//     loginConfirmedAction,
//     logout,
// } from '../store/actions/AuthActions';

import { baseURL } from './BaseUrl';
import { AUTH_LOCAL_STORAGE_KEY } from '../../src/app/modules/auth/core/AuthHelpers'

export function signUp(username, password) {
    //axios call
    const postData = {
        username,
        password,
    };

    return axios.post(
        `${baseURL}Auth/login`,
        postData,
    );
}

export function login(values) {
    const postData = {
        username: values.username,
        password: values.password,

    };
    return axios.post(
        `${baseURL}auth/login`,
        postData,
    );
}

export function forgotPassword(email) {
    return axios.post(
        `${baseURL}ChangePassword/Forgot?email=${email}`,
    );
}



export function formatError(errorResponse) {
    switch (errorResponse.error.message) {
        case 'EMAIL_EXISTS':
            // return 'Email already exists';
            // swal("Oops", "Email already exists", "error");
            break;
        case 'EMAIL_NOT_FOUND':
            // return 'Email not found';
            // swal("Oops", "Email not found", "error", { button: "Try Again!", });
            break;
        case 'INVALID_PASSWORD':
            //return 'Invalid Password';
            // swal("Oops", "Invalid Password", "error", { button: "Try Again!", });
            break;
        case 'USER_DISABLED':
            return 'User Disabled';

        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    tokenDetails.expireDate = new Date(
        new Date().getTime() + 1000001000,
    );
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, JSON.stringify(tokenDetails));
}

export function getTokenLocalStorage() {
    const aware_user = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || '');
    if (aware_user) {
        return aware_user;
    }
    return null;
}

export function runLogoutTimer(dispatch, timer, history) {
    setTimeout(() => {
        // dispatch(logout(history));
    }, timer);
}

export function checkAutoLogin(dispatch, history) {
    const tokenDetailsString = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
    let tokenDetails = '';
    if (!tokenDetailsString) {
        // dispatch(logout(history));
        return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    let expireDate = new Date();
    let todaysDate = new Date();

    if (todaysDate > expireDate) {
        // dispatch(logout(history));
        return;
    }
    // dispatch(loginConfirmedAction(tokenDetails));

    const timer = expireDate.getTime() - todaysDate.getTime();
    runLogoutTimer(dispatch, timer, history);
}

export function signUpComplete(data) {

    return axios.post(
        `${baseURL}/account/complete-signup?userKey=${data}`,
        null,
    );
}

// export function saveTokenInLocalStorage(tokenDetails) {
//     localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
// }

// export function runLogoutTimer(dispatch, timer, history) {
//     setTimeout(() => {
//         dispatch(logout(history));
//     }, 1000000000);
// }

// export function checkAutoLogin(dispatch, history) {
//     const tokenDetailsString = localStorage.getItem('userDetails');
//     if (!tokenDetailsString) {
//         dispatch(logout(history));
//         return;
//     }

//     // tokenDetails = JSON.parse(tokenDetailsString);
//     // let expireDate = new Date(tokenDetails.expireDate);
//     // let todaysDate = new Date();

//     // if (todaysDate > expireDate) {
//     //     dispatch(logout(history));
//     //     return;
//     // }
//     // dispatch(loginConfirmedAction(tokenDetails));

//     // const timer = expireDate.getTime() - todaysDate.getTime();
//     // runLogoutTimer(dispatch, timer, history);
// }
