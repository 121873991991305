import React, { useEffect, useState } from 'react'
import { ProfileDetails } from './cards/ProfileDetails'
import { SignInMethod } from './cards/SignInMethod'
import { ConnectedAccounts } from './cards/ConnectedAccounts'
import { EmailPreferences } from './cards/EmailPreferences'
import { Notifications } from './cards/Notifications'
import { DeactivateAccount } from './cards/DeactivateAccount'
import { getCompanyDetails } from './../../../../../services/CompanyService'
import { ICompanyDetails, companyInitValues as initialValues } from '../../../../../_models/company-model'


export function Settings() {
  const [data, setData] = useState<ICompanyDetails>(initialValues)
  const [loading, setLoading] = useState(false)

  const bindDetails = () => {
    setLoading(true);
    setTimeout(() => {
      getCompanyDetails()
        .then((res: any) => {
          let _data = res.data.data;
          setData(_data);
        })
        .catch(() => {

        }).finally(() => {
          setLoading(false);
        });
    }, 500);
  }

  return (
    <>
   
      <ProfileDetails />
      {/* <SignInMethod />
      <ConnectedAccounts />
      <EmailPreferences />
      <Notifications /> */}
      {/* {data.isActive ? <DeactivateAccount /> :
        <div className='col-md-12 d-flex justify-items-center'>
          <div className='col-md-12 d-flex my-4 bg-light-danger px-6 py-4 rounded-2 '>
            <div className='d-flex justify-items-center text-center'>
              <a href='#' className='text-danger fw-semibold fs-6 mt-2'>
                Account Inactive
              </a>
            </div>
          </div>
        </div>
        } */}
    </>
  )
}
