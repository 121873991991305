import React, { useEffect, useState, Fragment } from 'react';
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { PAGE_SIZE } from '../../../../_shared';
import { getInvoiceDetails } from '../../../../services/InvoiceService';
import Pagination from '../../../../lib/Pagination/Pagination';
import { Calculator } from '../../interface/ICalculator';

export function ListQuoteItems(props: any) {

    const [data, setData] = React.useState(props.data);

    const [currentTableData, setCurrentTableData] = React.useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = React.useState(0);


    const paginate = (pageNumber: any) => {
        setCurrentPage(pageNumber);
        const firstPageIndex = (pageNumber - 1) * PAGE_SIZE;
        const lastPageIndex = firstPageIndex + PAGE_SIZE;
        let _currentTableData = data.slice(firstPageIndex, lastPageIndex);
        setCurrentTableData(_currentTableData);
    };

    const bindGrid = () => {
        setTimeout(() => {
            let _data = data;
            
            const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
            const lastPageIndex = firstPageIndex + PAGE_SIZE;
            let _currentTableData = _data.slice(firstPageIndex, lastPageIndex);

            setData(_data);

            setTotalRows(_data.length);
            if (_data.length > 0) {
                setCurrentTableData(_currentTableData);
            }
        }, 1000);
    };

    const deleteItem = (index: any): void => {
        throw new Error('Function not implemented.');
    }
    const [editField, setEditField] = useState<any>({
        quantity: 0,
        status: false
    });
    // const handleClickChange = (index: any, evnt: any) => {
    //     const { name, value } = evnt.target;
    //     const rowsInput = [...data];
    //     rowsInput[index][name] = parseInt(value);
    //     // update taxAmount of every row
    //     rowsInput[index].taxAmount = (rowsInput[index].taxPercent * (rowsInput[index].price-rowsInput[index].discount) / 100)*rowsInput[index].quantity;
    //     // update taxableAmount of every row with lower value
    //     rowsInput[index].amount = (rowsInput[index].price-rowsInput[index].discount)* rowsInput[index].quantity;
    //     // update grossAmount of every row
    //     rowsInput[index].grossAmount = (rowsInput[index].amount - rowsInput[index].discount) + rowsInput[index].taxAmount.toFixed(2);
    //     // update sum of discount of every row
    //     setData(rowsInput);
    //     const taxAmtSum = rowsInput.reduce((acc: any, curr: any) => acc + curr.taxAmount, 0);
    //     const grossAmtSum = rowsInput.reduce((acc: any, curr: any) => acc + curr.grossAmount, 0);
    //     const totalAmtSum = rowsInput.reduce((acc: any, curr: any) => acc + curr.amount, 0);
    //     // reduce discount amount from gross amount
    //     const discountAmtSum = rowsInput.reduce((acc: any, curr: any) => acc + curr.discount, 0);
    //     const netAmtSum = grossAmtSum - discountAmtSum;

    //     props.setItemsData({
    //         taxableAmount: totalAmtSum,
    //         taxAmount: taxAmtSum,
    //         totalAmount: netAmtSum
    //     });
    // };


    const calculator: Calculator = {
        calculateTaxAmount: (taxPercent: number, price: number, discount: number, quantity: number) => {
            return (taxPercent * (price - discount) / 100) * quantity;
        },
        calculateAmount: (price: number, discount: number, quantity: number) => {
            return (price - discount) * quantity;
        },
        calculateGrossAmount: (amount: number, discount: number, taxAmount: number) => {
            return (amount - discount) + taxAmount;
        },
        calculateDiscountSum: (rowsInput: any[]) => {
            return rowsInput.reduce((acc: any, curr: any) => acc + curr.discount, 0);
        }
    };

    const memoizedCalculations: { [key: string]: number } = {};

    const handleClickChange = (index: number, evnt: React.ChangeEvent<HTMLInputElement>) => {
        const name = evnt.target.name;
        const value = evnt.target.value;
        const rowsInput = [...data];

        // Use memoization to avoid recalculating the same values
        if (memoizedCalculations[value]) {
            rowsInput[index][name] = memoizedCalculations[value];
        } else {
            rowsInput[index][name] = parseInt(value);
            memoizedCalculations[value] = rowsInput[index][name];
        }

        // Calculate taxAmount before discount
        rowsInput[index].taxAmount = calculator.calculateTaxAmount(rowsInput[index].taxPercent, rowsInput[index].price, rowsInput[index].discount, rowsInput[index].quantity);
        // Calculate taxableAmount with discount
        rowsInput[index].amount = calculator.calculateAmount(rowsInput[index].price, rowsInput[index].discount, rowsInput[index].quantity);
        // Calculate grossAmount
        rowsInput[index].grossAmount = calculator.calculateGrossAmount(rowsInput[index].amount, rowsInput[index].discount, rowsInput[index].taxAmount);
        setData(rowsInput);

        // Calculate sum of taxAmount and grossAmount
        const taxAmtSum = rowsInput.reduce((acc: any, curr: any) => acc + curr.taxAmount, 0);
        const grossAmtSum = rowsInput.reduce((acc: any, curr: any) => acc + curr.grossAmount, 0);
        // Calculate sum of taxableAmount
        const totalAmtSum = rowsInput.reduce((acc: any, curr: any) => acc + curr.amount, 0);
        // Calculate sum of discount
        const discountAmtSum = calculator.calculateDiscountSum(rowsInput);
        // Calculate netAmount by subtracting discount sum from grossAmount sum
        const netAmtSum = grossAmtSum - discountAmtSum;

        props.setItemsData({
            taxableAmount: totalAmtSum,
            taxAmount: taxAmtSum,
            totalAmount: netAmtSum
        });
    };
    const [editPrice, setEditPrice] = useState<any>({
        price: 0,
        status: false,
    })
    const handleEditClick = (index: number, status: boolean, quantity: number) => {
        setEditField({
            quantity: quantity,
            status: status
        })
    }
    const handlePriceClick = (index: number, status: boolean, price: number) => {
        setEditPrice({
            price: price,
            status: status
        })
    }
    useEffect(() => {
        bindGrid();
    }, []);

    return (<>
        <div className="table-responsive">
            <div className="display mb-4 dataTablesCard customer-list-table">
                <Table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                    <thead>
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                            <th>#</th>
                            <th>Description</th>
                            <th>HSN</th>
                            <th>Quantity</th>
                            <th>Unit</th>
                            <th className='text-end'>Rate</th>
                            <th className='text-end'>Discount Amount</th>
                            <th className='text-end'>Taxable Amount</th>
                            <th className='text-end'>Taxable Amount</th>
                            <th className='text-end'>Tax</th>
                            <th className='text-end'>Total Amount</th>
                            <th className='text-center'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentTableData &&
                            currentTableData.length > 0 ?
                            currentTableData.map((item: any, index: any) =>
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <p className="text-gray-600 text-hover-secondary mb-0"> {item.itemName}</p>
                                            </div>
                                        </div>

                                    </td>
                                    <td>{item.hsn}</td>
                                    <td className='text-end'>
                                        {!editField.quantity ? <>
                                            {item.quantity} {" "}
                                            {props?.isCancelled === false && <i className=' text-info fas fa-edit px-8'
                                                onClick={() => {
                                                    handleEditClick(index, true, item.quantity)
                                                }}
                                            ></i>}
                                        </> :
                                            <input type="number"
                                                name="quantity"
                                                className="form-control"
                                                id={`qty_${item.id}`}
                                                min={1}
                                                value={item.quantity}
                                                onChange={(evnt) => handleClickChange(index, evnt)} />
                                        }
                                    </td>
                                    <td>{item.unit}</td>
                                    <td className='text-end'>
                                        {!editPrice.price ? <>
                                            {item.price.toFixed(1)} {" "}
                                            {props?.isCancelled === false && <i className=' text-info fas fa-edit px-8'
                                                onClick={() => {
                                                    handlePriceClick(index, true, item.price)
                                                }}
                                            ></i>}
                                        </> :
                                            <input type="number"
                                                name="price"
                                                className="form-control"
                                                id={`prc_${item.id}`}
                                                min={1}
                                                value={item.price}
                                                onChange={(evnt) => handleClickChange(index, evnt)} />
                                        }
                                    </td>
                                    <td className='text-end'> {item.discount?.toFixed(2)}</td>
                                    <td className='text-end'> {(item.amount)?.toFixed(2)}</td>
                                    
                                    <td className='text-end'> {(item.amount)?.toFixed(2)}</td>
                                    <td className='text-end'>
                                        {item.taxAmount?.toFixed(2)} (
                                        <span className='text-gray-600 fs-7'>
                                            {item.taxPercent}%
                                        </span>)
                                    </td>
                                    <td className='text-dark fw-bold text-end'>
                                        {((item.price - item.discount) * item.quantity
                                            + (item.taxPercent * (item.price - item.discount) / 100) * item.quantity).toFixed(2)}
                                        <br></br>
                                    </td>
                                    


                                    <td className='text-center'>
                                        <div>
                                            <a href="#" onClick={() => deleteItem(index)}>
                                                <i className="bi bi-trash text-danger fs-5"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            ) :
                            (
                                <tr>
                                    <td colSpan={8}>
                                        <div className='text-gray-600 d-flex text-center w-100 align-content-center justify-content-center'>
                                            No matching records found
                                        </div>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={totalRows}
                    pageSize={PAGE_SIZE}
                    onPageChange={(page: any) => paginate(page)}
                />
            </div>
        </div>
    </>)
}

