import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../../../lib/Pagination/Pagination";
import { getAllSuppliers } from "../../../services/SuppliersService";
import { DisableSidebar, PageLink, PageTitle } from "../../../_metronic/layout/core";
import { FilterInput, FilterSelect, Loader, LOADINGSTAGES } from "../components/components";
import { KTSVG } from '../../../_metronic/helpers';
import { ImportModals } from './_importModels';
import { breadcrumbs } from "../components/PageTitle";
import { NoDataFound } from "../../../lib/NoData";
import React from "react";
import { getSupplierCount } from "../../../services/CounterService";
import { CounterBox } from "../../../lib/CounterBox";

interface BigObject<T> {
    [index: string]: T
}

export function ListSupplier(props: any) {
    const iV = {
        pageNumber: 1,
        pageSize: 100000,
        filter: ""
    }
    const [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 10,
        totalItems: 0,
        totalPages: 0,
    });

    const [showImportModal, setShowImportModal] = useState<boolean>(false);
    const [filterValues, setFilterValues] = useState<any>(iV);
    const paginate = (pageNumber: number) => {
        iV.pageNumber = pageNumber;
        setPagination({ ...pagination, currentPage: pageNumber });
        const values = {
            ...filterValues,
            pageNumber: pageNumber,
        }
        bindSuppliers(values);
    }
    const [isLoading, setIsLoading] = useState(false);
    const [suppliers, setSuppliers] = useState<any>([]);
    const [laodingStages, setLoadingStages] = useState<number>(LOADINGSTAGES.IDLE);

    const filterData = () => {
        const values = {
            ...iV,
            filter: filterValues.filter,
        }
        bindSuppliers(values);
    }

    const bindSuppliers = (values: any) => {
        setLoadingStages(LOADINGSTAGES.LOADING);
        getAllSuppliers(values).then((res: any) => {
            if (res.data.success) {
                setSuppliers(res.data.data.data);
                setLoadingStages(LOADINGSTAGES.POSITIVE);
                const pagination = {
                    currentPage: 1,
                    pageSize: 10,
                    totalItems: res.data.data.data.length,
                    totalPages: res.data.data.data.length / 10,
                }
                setPagination({ ...pagination });
            }
            else {
                setLoadingStages(LOADINGSTAGES.NEGATIVE);
            }
        }).catch((err: any) => {
            setLoadingStages(LOADINGSTAGES.ERROR);
        }).finally(() => {

        });
    }

    const [counterData, setCounterData] = React.useState([]);

    const bindCounters = () => {
        getSupplierCount().then((res: any) => {
            const data = res.data.data;

            const localArray: any = [];
            let bigObject: BigObject<object> = data;
            Object.keys(bigObject).forEach(key => {
                
                localArray.push({
                    text: key === "active" ? "Active" :
                        key === "inActive" ? "Inactive" : "",
                    value: bigObject[key]
                })
            })
            setCounterData(localArray);

        }).catch((err: any) => {
            console.log(err);
        })
    }

    useEffect(() => {
        bindCounters();
        bindSuppliers(iV);
    }, [])

    const resetData = () => {
        setFilterValues(iV);
        bindSuppliers(iV);
    }

    const profileBreadCrumbs: Array<PageLink> = [
        {
            title: 'Suppliers',
            path: '/suppliers',
            isSeparator: false,
            isActive: false,
        },
    ]

    return (
        <>
            <PageTitle breadcrumbs={
                breadcrumbs({
                    title: 'Suppliers',
                    path: '/suppliers'
                })
            }>
                Suppliers
            </PageTitle>
            <DisableSidebar>
                <div className='card mb-xl-5'>

                    <div className='card-body pt-9 pb-0'>
                        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                            <div className='flex-grow-1'>
                                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                                    <div className='d-flex flex-column'>
                                        <div className='d-flex align-items-center mb-2'>
                                            <CounterBox data={counterData} />
                                        </div>
                                    </div>

                                    <div className='d-flex my-4'>
                                        {/* begin::Filter Button */}
                                        <button
                                            disabled={isLoading}
                                            type='button'
                                            className='btn btn-sm btn-secondary me-3'
                                            data-kt-menu-trigger='click'
                                            data-kt-menu-placement='bottom-end'
                                        >
                                            <KTSVG path='/media/icons/duotune/general/gen031.svg' />
                                            Filter
                                        </button>
                                        {/* end::Filter Button */}
                                        {/* begin::SubMenu */}
                                        <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
                                            {/* begin::Header */}
                                            <div className='px-7 py-5'>
                                                <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
                                            </div>
                                            {/* end::Header */}

                                            {/* begin::Separator */}
                                            <div className='separator border-gray-200'></div>
                                            {/* end::Separator */}

                                            {/* begin::Content */}
                                            <div className='px-7 py-5' data-kt-user-table-filter='form'>
                                                {/* <div className='mb-10'>

                                                </div> */}
                                                <div className='mb-10'>
                                                    <FilterInput className='col-12'
                                                        placeholder='Search' name='keyword'
                                                        label='Keyword' value={filterValues.filter}
                                                        onChange={(e: any) => {
                                                            setFilterValues({ ...filterValues, filter: e.target.value });
                                                        }}
                                                    />
                                                </div>

                                                {/* begin::Actions */}
                                                <div className='d-flex justify-content-end'>
                                                    <button
                                                        type='button'
                                                        disabled={isLoading}
                                                        onClick={resetData}
                                                        className='btn btn-sm btn-light btn-active-light-primary fw-bold me-2 px-6'
                                                        data-kt-menu-dismiss='true'
                                                        data-kt-user-table-filter='reset'
                                                    >
                                                        Reset
                                                    </button>
                                                    <button
                                                        disabled={isLoading}
                                                        type='button'
                                                        onClick={filterData}
                                                        className='btn btn-sm btn-primary fw-bold px-6'
                                                        data-kt-menu-dismiss='true'
                                                        data-kt-user-table-filter='filter'
                                                    >
                                                        Apply
                                                    </button>
                                                </div>
                                                {/* end::Actions */}
                                            </div>
                                            {/* end::Content */}
                                        </div>
                                        {/* end::SubMenu */}
                                        <Link to='/suppliers/add'><button
                                            title='Add new product'
                                            className='btn btn-sm btn-primary me-3'
                                            data-bs-toggle='modal'
                                            data-bs-target='#kt_modal_offer_a_deal'
                                        >
                                            Add New
                                        </button></Link>
                                        <a href='#' className='btn btn-sm btn-secondary me-2' id='kt_import_toggle' onClick={() => setShowImportModal(true)}>
                                            <KTSVG
                                                path='/media/icons/duotune/arrows/arr012.svg'
                                                className='svg-icon-3 d-none'
                                            />
                                            <span className='indicator-label'>Import</span>
                                        </a>
                                    </div>
                                </div>
                                {/* begin::Row */}

                                {/* end::Row */}
                            </div>
                        </div>
                    </div>
                </div>
                {laodingStages === LOADINGSTAGES.LOADING && <Loader />}
                {laodingStages === LOADINGSTAGES.NEGATIVE && <NoDataFound />}
                {laodingStages === LOADINGSTAGES.ERROR && <div className='alert alert-secondary text-gray-700 m-20'>Something went wrong</div>}
                {laodingStages === LOADINGSTAGES.POSITIVE &&
                    <div className='row '>
                        <div className="col-xs-12 col-md-12">
                            <div className="notice d-flex bg-light-primary rounded border-primary-light border p-6 mb-3">
                                <i className="bi bi-info-circle-fill fs-1 text-primary me-4"></i>
                                <div className="d-flex flex-stack flex-grow-1" title='Info'>
                                    <div className="">
                                        <div className="fs-7 text-gray-700">Click on the Supplier Name to update supplier information.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <div className="display mb-4 dataTablesCard customer-list-table">
                                            <Table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                                                <thead>
                                                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                                        <th>Supplier Name </th>
                                                        <th>Code</th>
                                                        <th>Owner Name</th>
                                                        <th>Contact</th>
                                                        <th>Email</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {suppliers.map((item: any, index: any) =>
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="d-flex flex-column">
                                                                        <Link to={`/suppliers/edit/${item.id}`}
                                                                            className="text-dark fw-bold text-hover-secondary mb-1">
                                                                            {item.fullName}
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>{item.supplierCode}</td>
                                                            <td>{item.ownerName}</td>
                                                            <td>{item.contactNo}</td>
                                                            <td>{item.email}</td>

                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                            <Pagination
                                                className="pagination pagination-sm"
                                                currentPage={pagination.currentPage}
                                                totalCount={pagination.totalItems}
                                                pageSize={pagination.pageSize}
                                                onPageChange={(page: any) => paginate(page)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                {showImportModal && <ImportModals setShowImportModal={setShowImportModal} />}

            </DisableSidebar>
        </>
    );
}
