import axiosInstance from '../AxiosInstance';

export function getBanksByCompanyId(companyId) {
    return axiosInstance.get(`bankaccount/company/${companyId}`);
}
export function getBankById(id) {
    return axiosInstance.get(`bankaccount/${id}`);
}
export function saveBank(data) {
    return axiosInstance.post(`bankaccount`, data);
}
export function updateBank(data) {
    return axiosInstance.put(`bankaccount`, data);
}
export function saveSubScriptionDetails(data) {
    return axiosInstance.post(`bankaccount/subscription`, data);
}
export function getAllSubs() {
    return axiosInstance.get(`bankaccount/subscription/all`);
}
export function getCurrentPlan() {
    return axiosInstance.get(`bankaccount/subscription/current`);
}