import { updatePOFields, updatePoTaxes } from "../../../services/BackendService/PurchaseOrdersService";
import { IPOAdditionalTax, IPurchaseOrderInput, IPurchaseOrderItem, IPurchaseOrderSearch } from "../interface/IInvoiceTypes/IPurchaseOrderModel";
//#region  types
export type IUpdateTypes = 'shipTo' | 'billTo' | 'supplierInfo' | 'purchaseOrderDate' | 'purchaseOrderDueDate' | 'purchaseOrderNumber';
export type ITaxProps = {
    tax: IPODto,
    onResponse: (res: any) => void
}
export type IPODto = {
    id: number,
    subAmount: number,
    totalAmount: number,
    taxAmount: number,
    totalFreightCharges: number,
    addTax: number,
    poAdditionalTaxes: IPOAdditionalTax[]
}
export type IInputProps = {
    type: IUpdateTypes,
    dto: IPurchaseOrderInput,
    onResponse: (res: any) => void
}

//#endregion types

//#region functions
export function updatePOInput(props: IInputProps) {
    const { type, dto } = props;
    switch (type) {
        case 'purchaseOrderDate':
            dto.purchaseOrderDate = dto.purchaseOrderDate || new Date();
            break;
        case 'purchaseOrderDueDate':
            dto.purchaseOrderDueDate = dto.purchaseOrderDueDate || new Date();
            break;
        case 'purchaseOrderNumber':
            dto.purchaseOrderNumber = dto.purchaseOrderNumber || '';
            break;
        case 'billTo':
            dto.billName = dto.billName || '';
            dto.billAddress = dto.billAddress || '';
            dto.billCity = dto.billCity || '';
            dto.billState = dto.billState || '';
            dto.billPostalCode = dto.billPostalCode || '';
            dto.billGstin = dto.billGstin || '';
            break;
        case 'shipTo':
            dto.shipName = dto.shipName || '';
            dto.shipAddress = dto.shipAddress || '';
            dto.shipCity = dto.shipCity || '';
            dto.shipState = dto.shipState || '';
            dto.shipPostalCode = dto.shipPostalCode || '';
            dto.shipGstin = dto.shipGstin || '';
            break;
        case 'supplierInfo':
            dto.supplierName = dto.supplierName || '';
            dto.supplierAddress = dto.supplierAddress || '';
            dto.supplierCity = dto.supplierCity || '';
            dto.supplierState = dto.supplierState || '';
            dto.supplierPostalCode = dto.supplierPostalCode || '';
            dto.supplierGstin = dto.supplierGstin || '';
            break;
    }
    dto.id = dto.id || 0;
    // don't update if id is 0 and show error
    if (dto.id === 0) {
        const data = { success: false, message: 'PO not found' };
        props.onResponse(data);
        return;
    }
    updatePOFields(dto).then((res) => {
        if (res.data.success) {
            props.onResponse(res.data.data);
        }
    }).catch((err) => {
        const data = { success: false, message: err.message };
        props.onResponse(data);
    });
}

export const updatePOTaxes = (props: ITaxProps) => {
    const { tax } = props;
    const dto: IPODto = {
        id: tax.id,
        subAmount: tax.subAmount,
        totalAmount: tax.totalAmount,
        taxAmount: tax.taxAmount,
        totalFreightCharges: tax.totalFreightCharges,
        addTax: tax.addTax,
        poAdditionalTaxes: tax.poAdditionalTaxes
    }
    updatePoTaxes(dto).then((res) => {
        if (res.data.success) {
            props.onResponse(res.data.data);
        }
    }).catch((err) => {
        const data = { success: false, message: err.message }
        props.onResponse(data);
    });

}
//#endregion functions