import React from 'react'

export default function FormikErrorMessage(props:any) {
    const { message } = props;
  return (
      <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{
                message
          }</div>
      </div>
  )
}
