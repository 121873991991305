import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { createPortal } from 'react-dom'
import { updateQuotationFields } from '../../../../services/QuotationService'
import { IBillBy, IBillTo, IQuotationInputDto, IShipTo } from '../../interface/IInvoiceTypes/IQuotation'
import { IUpdateTypes, QInputProps, updateQuoteInput } from '../qconsts'
interface IQData {
  billBy: IBillBy
  shipTo: IShipTo
}
type Props = {
  id: any,
  type: string
  show: boolean
  handleClose: () => void
  billBy: IBillBy
  billTo: IBillTo
  setBillBy: (value: any) => void
  setBillTo: (value: any) => void
  initialData: IQData
  onUpdate: (data: any) => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

export default function AddressForm({
  show,
  handleClose,
  billBy,
  billTo,
  type,
  setBillBy,
  setBillTo,
  initialData,
  id,
  onUpdate
}: Props) {
  const updateQuotes = (type: IUpdateTypes) => {
    var dto: IQuotationInputDto = {};
    dto.id = id;
    if (type === 'billBy') {
      dto.companyName = billBy.companyName;
      dto.companyAddress = billBy.companyAddress;
      dto.companyCity = billBy.companyCity;
      dto.companyState = billBy.companyState;
      dto.companyPostalCode = billBy.companyPostalCode;
      dto.companyGstin = billBy.companyGstin;
    }
    if (type === 'billTo') {
      dto.billName = billTo.billName;
      dto.billAddress = billTo.billAddress;
      dto.billCity = billTo.billCity;
      dto.billState = billTo.billState;
      dto.billPostalCode = billTo.billPostalCode;
      dto.gstin = billTo.customerGstin;
    }
    const props: QInputProps = {
      type,
      dto,
      onResponse: (res) => {
        if (res) {
          onUpdate(res);
          handleClose();
        }
      }
    }
    updateQuoteInput(props);
  }

  switch (type) {
    case 'billBy':
      return createPortal(
        <Modal
          id='kt_modal_create_app'
          tabIndex={-1}
          aria-hidden='true'
          dialogClassName='modal-dialog modal-dialog-centered mw-900px'
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>Bill By </h3>
            </Modal.Title>
          </Modal.Header>
          <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
            <div className='row'>
              <div className='col-md-6 mb-3'>
                <div className='form-group'>
                  <label className='form-label mb-1'>Bill Name</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Bill  Name'
                    value={billBy.companyName}
                    onChange={(e) => setBillBy({ ...billBy, companyName: e.target.value })}
                  />
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <div className='form-group'>
                  <label className='form-label mb-1'>Address</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter Address'
                    value={billBy.companyAddress}
                    onChange={(e) => setBillBy({ ...billBy, companyAddress: e.target.value })}
                  />
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <div className='form-group'>
                  <label className='form-label mb-1'>State</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter State'
                    value={billBy.companyState}
                    onChange={(e) => setBillBy({ ...billBy, companyState: e.target.value })}
                  />
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <div className='form-group'>
                  <label className='form-label mb-1'>City</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter City'
                    value={billBy.companyCity}
                    onChange={(e) => setBillBy({ ...billBy, companyCity: e.target.value })}
                  />
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <div className='form-group'>
                  <label className='form-label mb-1'>Postal Code</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter Postal Code'
                    value={billBy.companyPostalCode}
                    onChange={(e) => setBillBy({ ...billBy, companyPostalCode: e.target.value })}
                  />
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <div className='form-group'>
                  <label className='form-label mb-1'>GSTIN</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter GSTIN'
                    value={billBy.companyGstin}
                    onChange={(e) => setBillBy({ ...billBy, companyGstin: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </div>
          <Modal.Footer className='row justify-content-between'>
            <div className='col-lg-4'>
              <button
                type='button'
                className='px-10 btn btn-light font-weight-bold'
                onClick={() => {
                  handleClose()
                }}
              >
                Close
              </button>
            </div>
            <div className='col-lg-7 text-end'>
              {/* <button type='button' className='px-10 btn btn-primary font-weight-bold'
                            onClick={handleClose}>
                            Close
                        </button> */}
              <button
                type='button'
                className='px-10 p-3 btn btn-primary font-weight-bold'
                onClick={() => { updateQuotes('billBy'); }}
              >
                Save changes
              </button>
            </div>
          </Modal.Footer>
        </Modal>,
        modalsRoot
      )
    case 'shipTo':
      return createPortal(
        <Modal
          id='kt_modal_create_app'
          tabIndex={-1}
          aria-hidden='true'
          dialogClassName='modal-dialog modal-dialog-centered mw-900px'
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Bill To</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row'>
              <div className='col-md-6 mb-3'>
                <div className='form-group'>
                  <label className='form-label mb-1'>Customer Name</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter Customer Name'
                    value={billTo.billName}
                    onChange={(e) => setBillTo({ ...billTo, billName: e.target.value })}
                  />
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <div className='form-group'>
                  <label className='form-label mb-1'>Address</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter Address'
                    value={billTo.billAddress}
                    onChange={(e) => setBillTo({ ...billTo, billAddress: e.target.value })}
                  />
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <div className='form-group'>
                  <label className='form-label mb-1'>City</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter City'
                    value={billTo.billCity}
                    onChange={(e) => setBillTo({ ...billTo, billCity: e.target.value })}
                  />
                </div>
              </div>

              <div className='col-md-6 mb-3'>
                <div className='form-group'>
                  <label className='form-label mb-1'>Postal Code</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter Pin'
                    value={billTo.billPostalCode}
                    onChange={(e) => setBillTo({ ...billTo, billPostalCode: e.target.value })}
                  />
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <div className='form-group'>
                  <label className='form-label mb-1'>GSTIN</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter GSTIN'
                    value={billTo.customerGstin ?? ''}
                    onChange={(e) => setBillTo({ ...billTo, customerGstin: e.target.value })}
                  />
                </div>
              </div>

              <div className='col-md-6 mb-3'>
                <div className='form-group'>
                  <label className='form-label mb-1'>Email</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter Email'
                    value={billTo.customerEmail ?? ''}
                    onChange={(e) => setBillTo({ ...billTo, customerEmail: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className='row justify-content-between'>
            <div className='col-lg-4'>
              <button
                type='button'
                className='btn btn-light font-weight-bold mr-2'
                onClick={handleClose}
              >
                Close
              </button>
            </div>
            <div className='col-auto'>
              <button
                type='button'
                className='btn btn-primary font-weight-bold'
                onClick={() => { updateQuotes('billTo') }}
              >
                Save changes
              </button>
            </div>
          </Modal.Footer>
        </Modal>,
        modalsRoot
      )
    default:
      return null
  }
}
