import { useEffect, useState } from 'react';
import { DisableSidebar, PageLink, PageTitle } from '../../../_metronic/layout/core';
import { InvoicePrefixModal } from '../../../_shared/components/InvoicePrefix';
import { getPrefixes } from '../../../services/CommonService';
import { PurchaseOrderPrefixModal } from '../../../_shared/components/PurchaseOrderPrefix';
import { QuotePrefixModal } from '../../../_shared/components/QuotePrefix';

const profileBreadCrumbs: Array<PageLink> = [
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]


export function DocumentSettings() {
    const [toggle, setToggle] = useState("");
    const [showInvPreFrom, setShowInvPreFrom] = useState<any>(false);
    const [showQuotePreFrom, setShowQuotePreFrom] = useState<any>(false);
    const [showPOPreFrom, setShowPOPreFrom] = useState<any>(false);

    const [docSetting, setDocSetting] = useState(
        {
            invoicePrefix: '',
            invoiceStart: '',
            quotationPrefix: '',
            quotationStart: '',
            purchaseOrderPrefix: '',
            purchaseOrderStart: ''
        });

    const afterUpdateProduct = (data: any) => {
        bindPrefixes();
    }

    const bindPrefixes = () => {
        setTimeout(() => {
            getPrefixes()
                .then((res: any) => {
                    var _data = res.data.data;
                    setDocSetting(_data);
                })
                .catch(() => {
                });
        }, 500);
    };

    useEffect(() => {
        bindPrefixes();
    }, []);

    return (
        <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Document settings</PageTitle>
            <DisableSidebar>
                <div className='row'>
                    <div className="col-xl-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-3">
                                        <h2>Invoice</h2>
                                    </div>
                                    <div className="col-md-3">
                                        <span className='fs-7 text-muted'>Prefix</span>
                                        <h3>{docSetting.invoicePrefix}</h3>
                                    </div>
                                    <div className="col-md-3">
                                        <span className='fs-7 text-muted'>Serial number starts</span>
                                        <h3>{docSetting.invoiceStart}</h3>
                                    </div>
                                    <div className="col-md-3">
                                        <button className="btn btn-secondary" onClick={() => setShowInvPreFrom(true)}>Edit</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 col-md-12 mt-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-3">
                                        <h2>Quotation</h2>
                                    </div>
                                    <div className="col-md-3">
                                        <span className='fs-7 text-muted'>Prefix</span>
                                        <h3>{docSetting.quotationPrefix}</h3>
                                    </div>
                                    <div className="col-md-3">
                                        <span className='fs-7 text-muted'>Serial number starts</span>
                                        <h3>{docSetting.quotationStart}</h3>
                                    </div>
                                    <div className="col-md-3">
                                        <button className="btn btn-secondary" onClick={() => setShowQuotePreFrom(true)}>Edit</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 col-md-12 mt-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-3">
                                        <h2>Purchase order</h2>
                                    </div>
                                    <div className="col-md-3">
                                        <span className='fs-7 text-muted'>Prefix</span>
                                        <h3>{docSetting.purchaseOrderPrefix}</h3>
                                    </div>
                                    <div className="col-md-3">
                                        <span className='fs-7 text-muted'>Serial number starts</span>
                                        <h3>{docSetting.purchaseOrderStart}</h3>
                                    </div>
                                    <div className="col-md-3">
                                        <button className="btn btn-secondary" onClick={() => setShowPOPreFrom(true)}>Edit</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </DisableSidebar>

            {showInvPreFrom && (
                <InvoicePrefixModal
                    setShowInvPreFrom={setShowInvPreFrom}
                    afterUpdateProduct={afterUpdateProduct}
                    isService={false}
                />
            )}

            {showPOPreFrom &&
                (<PurchaseOrderPrefixModal
                    setShowInvPreFrom={setShowPOPreFrom}
                    afterUpdateProduct={afterUpdateProduct}
                />)
            }

            {showQuotePreFrom &&
                (<QuotePrefixModal
                    setShowInvPreFrom={setShowQuotePreFrom}
                    afterUpdateProduct={afterUpdateProduct}
                />)
            }
        </>
    )
} 			