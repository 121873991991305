
import React,{useEffect, useState} from 'react'
import { Modal } from 'react-bootstrap'
import { createPortal } from 'react-dom'
import {companyAddresses,cutomerAddresses } from '../../../../services/InvoiceService'


const modalsRoot = document.getElementById('root-modals') || document.body

export default function AddressList(props:any) {
    const getCompanyAddressList = [
        {
            "id": 1,
            "name": "Company Address",
            "address": "Address 1",
            "city": "City 1",
            "state": "State 1",
            "country": "Country 1",
            "pincode": "Pincode 1",
            "companyId": 1
        },
        {
            "id": 2,
            "name": "Company Address",
            "address": "Address 2",
            "city": "City 2",
            "state": "State 2",
            "country": "Country 2",
            "pincode": "Pincode 2",
            "companyId": 1

        },
        {
            "id": 3,
            "name": "Company Address",
            "address": "Address 3",
            "city": "City 3",
            "state": "State 3",
            "country": "Country 3",
            "pincode": "Pincode 3",
            "companyId": 1
        },

    ]
   const apiId = props.type === 'companyAddresses' ? props.companyId : props.customerId;
   const [data, setData] = useState<any>(null);

    useEffect(() => {     
        if (props.type === 'companyAddresses') {
            setData(companyAddresses(apiId))
            // getCompanyAddressList(apiId).then((res: any) => {
            //     setData(res.data.data);
            }
        else {
            setData(cutomerAddresses(apiId))
        }
    }, []);
    return (
        <CompanyAddressList type = {props.type} data={cutomerAddresses} show={props.show} handleClose={props.handleClose} />
    )
}

const CompanyAddressList = (props:any) => {  
    
    return createPortal(
        <Modal
         id='kt_modal_create_app'
         tabIndex={-1}
         aria-hidden='true'
         dialogClassName='modal-dialog modal-dialog-centered mw-900px'
         show={props.show}
         onHide={props.handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>{props.type==="companyAddresses"?"Company Addresses": "Customer Addresses"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="col-md-4 col-sm-6 col-xs-12">
             {props.data.map((item:any)=>{
               <div className="address-list">
                   <div className="address-list-header">
                 <h4> {item.companyName}
                        <span className="address-list-header-edit">
                            <i className="fas fa-edit"></i>
                        </span>
                </h4>
                </div>
                {props.type === "companyAddresses"?
                <div className="address-list-body">
                    <p>{item.addressLine1}</p>
                    <p>{item.addressLine2}</p>
                    <p>{item.city}</p>
                    <p>{item.state}</p>
                    <p>{item.postalCode}</p>
                    <p>{item.gstin}</p>
                    <p>{item.mobile}</p>
                    <p>{item.email}</p>
                </div>:
                <div className="address-list-body">
                    <p>{item.billName}</p>
                    <p>{item.billAddress}</p>
                    <p>{item.billCity}</p>
                    <p>{item.billState}</p> 
                    <p>{item.billPostalCode}</p>
                    <p>{item.gstin}</p>
                    <p>{item.contactNo}</p>
                    <p>{item.email}</p>
                </div>
                }
                </div>
                })}
            </div>
            </Modal.Body>
        </Modal>,
        modalsRoot
    )
}
