import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { ServerMessage } from '../../../lib/NoData'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  getQuotationDetails,
  getQuotationTaxes,
  quotationUpdate,
  updateQuotationFields,
  updateQuotationStatus,
} from '../../../services/QuotationService'
import { KTSVG } from '../../../_metronic/helpers'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { Button, Dropdown, Table } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { toastNotify } from '../../../services/NotifyService'
import {
  getCompanyCode,
  getQuotationStatus,
  getTaxes,
  updateInvoicePrefix,
} from '../../../services/CommonService'
import { useParams } from 'react-router-dom'

import { InvoiceCloneModal } from './partials/InvoiceCloneModal'
import { ListQuoteItems } from './partials/InvoiceItems'
import AddressForm from './partials/AddressForm'
import { Loader, LOADINGSTAGES, N2WIndian } from '../components/components'
import AddressList from './partials/AddressList'
import { QuoteTemplateSelect } from './partials/QuoteTemplateSelecter'
import { InvoiceShare } from './partials/ShareModal'
import QuoteMail from './partials/QuoteMail'
import {
  billByInitialValues,
  billToInitialValues,
  IBillBy,
  IBillTo,
  IQuotation,
  IQuotationItem,
  IQuoteAdditionalTax,
  quoteTaxInitVals,
} from '../interface/IInvoiceTypes/IQuotation'
import { IQuotationDto, ITaxProps, updateQuoteInput, updateTaxes } from './qconsts'
import { CompanyAddress, CustomerAddress } from '../components/AddressComponent'
import { AddAdditionalTax } from '../additional tax/AddAdditionalTax'
import {
  addTaxInitValues,
  IAdditionalTaxModel,
  IAddTaxModel,
} from '../../../_models/additionaltax-model'
import QuotationSelectBar, { IStatus } from '../components/QuotationSelectBar'
import { Dropdown3 } from '../../../_metronic/partials/content/dropdown/Dropdown3'
import { Dropdown2 } from '../../../_metronic/partials'
import { ChangeAlert } from '../../../_shared/components/ChangeAlert'
import StatusDropdown from '../components/QuotationSelectBar'
import { generateFromQuote } from '../../../services/InvoiceService'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Quotations',
    path: '/quotations',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
export function QuotationDetails(props: any) {
  const navigate = useNavigate();

  const [itemIdForUpdate, setItemIdForUpdate] = useState<any>()
  const { id, supplierId, customerId } = useParams()
  const [data, setData] = useState<any>(null)
  const [dataItems, setDataItems] = useState<IQuotationItem[]>([])
  const [tab, setTab] = useState(1)
  const [companyCode, setCompanyCode] = useState<any>('')
  const [quotationId, setquotationId] = useState<any>(id || 0)
  const [subAmount, setSubAmount] = useState<any>(0)
  const [invoiceId, setInvoiceId] = useState<any>(id || 0)
  const [saveInitialData, setSaveInitialData] = useState<any>({})
  const [showTemplate, setShowTemplate] = useState<any>(false)
  const [showShare, setShowShare] = useState<any>(false)
  const [isCancelled, setIsCancelled] = useState<any>(false)
  const [showMail, setShowMail] = useState<any>(false)
  const [taxAmount, setTaxAmount] = useState<any>('0.00')

  const [adressBillBy, setAddressBillBy] = useState<IBillBy>(billByInitialValues)
  const [adressShipTo, setAddressShipTo] = useState<IBillTo>(billToInitialValues)
  const [stages, setStages] = useState<any>(LOADINGSTAGES.IDLE)
  const [loading, setLoading] = useState(false);

  const bindDetails = (values: any) => {
    setStages(LOADINGSTAGES.LOADING)
    getQuotationDetails(id)
      .then((res: any) => {
        if (res.data.success) {
          const _data = res.data.data as IQuotation
          console.log("_data", _data)
          setData(_data)
          setquotationId(_data.id)
          bindQuoteTaxes(_data.id)
          setSubAmount(_data.subAmount ?? 0)
          setTaxAmount(_data.taxAmount ?? 0)
          let items = _data.quotationItems
          setDataItems(items)
          setMailDetails({
            to: _data.email,
            subject: `Quotation: ${_data.quotationNumber}`,
            message: `Dear ${_data.custFullName},\n\nPlease find the attached quotation for your reference.\n\nThanks & Regards,\n${_data.companyName}`,
            invoiceNo: _data.quotationNumber,
            customerName: _data.custFullName,
            quotationNumber: _data.quotationNumber,
          })
          setIsCancelled(_data.isCancel)
          setTotalAmount(_data.totalAmount)
          const [rupees, paisa] = _data.totalAmount?.toString().split('.') ?? []
          const amtInWords = `${N2WIndian.convert(rupees)} Rupees${paisa ? ` and ${N2WIndian.convert(paisa)} Paisa` : ''
            }`
          setAmtInWords(amtInWords)
          const addressBillBy: IBillBy = {
            companyAddress: _data.companyAddress,
            companyCity: _data.companyCity,
            companyGstin: _data.companyGstin,
            companyName: _data.companyName,
            companyPostalCode: _data.companyPostalCode,
            companyState: _data.companyState,
          }
          setAddressBillBy(addressBillBy)
          const addressShipTo: any = {
            ..._data,
            customerGstin: _data.GSTIN,
            customerPan: _data.PAN,
            customerEmail: _data.custEmail,
            customerContactNo: _data.custContactNo,
          }
          setAddressShipTo(addressShipTo)
          const saveInitialData = {
            billBy: addressBillBy,
            shipTo: addressShipTo,
          }
          setSaveInitialData(saveInitialData)
          setStages(LOADINGSTAGES.POSITIVE)
        } else {
          setData({})
          setStages(LOADINGSTAGES.NEGATIVE)
        }
      })
      .catch(() => {
        setData({})
        setStages(LOADINGSTAGES.ERROR)
      })
  }

  const [taxes, setTaxes] = useState<IQuoteAdditionalTax[]>([quoteTaxInitVals])
  const [additionalTax, setAdditoinalTax] = useState<string>('0.00')
  const additionaTaxChange = () => {
    const additonalTax = taxes.reduce((acc: number, item: IQuoteAdditionalTax) => {
      return acc + (item.taxPercent / 100) * subAmount ?? 0
    }, 0)
    setAdditoinalTax(additonalTax.toFixed(2))
  }

  useEffect(() => {
    additionaTaxChange()
  }, [taxes, subAmount])

  useEffect(() => {
    if (quotationId > 0) {
      bindDetails(quotationId)
    }
    bindTaxes()
    bindQuoteStatus()
    setCompanyCode(getCompanyCode())
  }, [])

  useEffect(() => {
    const amt =
      Number.parseFloat(taxAmount || 0) +
      Number.parseFloat(subAmount || 0) +
      Number.parseFloat(additionalTax ?? 0)
    setTotalAmount(amt.toFixed(2))
  }, [additionalTax, taxAmount, subAmount])

  const [addressForm, setAddressForm] = useState<any>({ type: '', status: null })
  const [editField, setEditField] = useState<any>({
    quotationDate: false,
    discount: false,
    addTax: false,
    quotationNumber: false,
  })

  const [invoiceEdited, setInvoiceEdited] = useState<any>({
    quotationDate: data?.quotationDate,
    quotationNumber: data?.quotationNumber,
    discount: data?.discount,
    subAmount: data?.subAmount,
    taxAmount: data?.taxAmount,
    addTax: data?.addTax,
  })

  const [totalAmount, setTotalAmount] = useState<any>(null)
  const [amtInWords, setAmtInWords] = useState<any>(null)
  const [itemsData, setItemsData] = useState<any>({
    taxableAmount: 0,
    taxAmount: 0,
    totalAmount: 0,
  })
  const [isItemsEdited, setIsItemsEdited] = useState<any>(false)
  const updateItemsData = (data: any) => {
    if (data) {
      setItemsData(data)
      setIsItemsEdited(true)
    }
  }

  useEffect(() => {
    let newData = { ...data }
    newData.discount = editField.discount ? invoiceEdited.discount : newData.discount
    newData.addTax = additionalTax ? additionalTax : newData.addTax
    newData.subAmount = itemsData.taxableAmount ? itemsData.taxableAmount : newData.subAmount
    setSubAmount(itemsData.taxableAmount || 0)
    newData.taxAmount = itemsData.taxAmount ? itemsData.taxAmount : newData.taxAmount
    setTaxAmount(itemsData.taxAmount || 0)
    newData.quotationNumber = editField.quotationNumber
      ? invoiceEdited.quotationNumber
      : newData.quotationNumber

    const amt =
      Number.parseFloat(newData?.taxAmount || 0) +
      Number.parseFloat(newData?.subAmount || 0) +
      Number.parseFloat(newData?.addTax || 0) -
      Number.parseFloat(newData?.discount || 0)
    setTotalAmount(amt)

    const [rupees, paisa = '00'] = (amt >= 0 ? amt : -amt).toFixed(2).toString().split('.')
    setAmtInWords(
      `${amt >= 0 ? '' : 'Minus '}${N2WIndian.convert(rupees)} Rupees and ${N2WIndian.convert(
        paisa
      )} Paisa`
    )
  }, [
    invoiceEdited.discount,
    itemsData.subAmount,
    itemsData.taxAmount,
    invoiceEdited.addTax,
    itemsData.totalAmount,
  ])

  useEffect(() => {
    const amt =
      Number.parseFloat(taxAmount || 0) +
      Number.parseFloat(subAmount || 0) +
      Number.parseFloat(additionalTax ?? 0)
    setTotalAmount(amt)
  }, [additionalTax, taxAmount, subAmount])

  const UpdateInvoiceDetails = () => {
    const id = toast.loading('Please wait...')
    const __data = { ...data }
    __data.addTax = additionalTax
    __data.subAmount = subAmount
    __data.taxAmount = taxAmount
    __data.discount = invoiceEdited.discount
    __data.totalAmount = totalAmount
    __data.amountInWords = amtInWords
    __data.quotationItems = dataItems
    __data.quotationAdditionalTaxes = taxes
    setTimeout(() => {
      quotationUpdate(__data, isItemsEdited ? 'items' : 'invoice')
        .then((res: any) => {
          if (res.data.success) {
            toastNotify(id, res)
            bindDetails(invoiceId)
          } else {
            toastNotify(id, res)
          }
        })
        .catch((err) => {
          let message = err?.response?.data?.message
          const data = { success: false, message }
          if (message) {
            toastNotify(id, data)
          } else {
            toastNotify(id, data)
          }
        })
        .finally(() => {
          toast.dismiss(id)
        })
    }, 1000)
  }

  const [mailDetails, setMailDetails] = useState<any>({
    to: '',
    cc: '',
    bcc: '',
    subject: '',
    message: '',
  })

  const toggleShowTemplate = (invoiceId: any) => {
    setShowTemplate(true)
  }

  const setShowInvoiceModal = () => {
    setShowTemplate(false)
  }

  const toggleShowShare = (invoiceId: any) => {
    setShowShare(true)
  }

  const toggleShowMail = (invoiceId: any) => {
    setInvoiceId(invoiceId)
    setShowMail(true)
  }

  const setShowShareModal = () => {
    setShowShare(false)
  }

  const afterUpdate = () => {
    bindDetails(quotationId)
  }

  const afterTaxUpdate = () => {
    setAddTax(false)
    // bindDetails(quotationId)
  }

  const bindQuoteTaxes = (taxId: number) => {
    getQuotationTaxes(taxId)
      .then((res: any) => {
        if (res.data.success) {
          setTaxes(res.data.data)
        } else {
        }
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  const convertToInvoice = (quotationId: number) => {
    setLoading(true);
    generateFromQuote(quotationId)
      .then((res: any) => {
        if (res.data.success) {
          navigate(`/invoice/details/${res.data.data}`);
        } else {
        }
      })
      .catch((err: any) => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false);
      })
  }

  const [defaultTaxes, setDefaultTaxes] = useState<IAddTaxModel[]>([addTaxInitValues])

  const bindTaxes = () => {
    setTimeout(() => {
      getTaxes(true)
        .then((res: any) => {
          let _data = res.data.data
          setDefaultTaxes(_data)
        })
        .catch(() => { })
    }, 1000)
  }
  const [addTax, setAddTax] = useState(false)
  const [quoteStatus, setQuoteStatus] = useState<IStatus[]>([])
  const bindQuoteStatus = () => {
    getQuotationStatus()
      .then((res: any) => {
        if (res.data.success) {
          setQuoteStatus(res.data.data)
        } else {
        }
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  const onStatusChange = (invType: IStatus) => {
    const id = toast.loading('Please wait...')
    const values = {
      quoteId: quotationId,
      statusId: invType.id,
    }
    updateQuotationStatus(values)
      .then((res: any) => {
        if (res.data.success) {
          toastNotify(id, res)
          bindDetails(quotationId)
        } else {
          toastNotify(id, res)
        }
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  const deleteTaxItem = (index: number) => {
    const newTaxRowsData = [...taxes]
    newTaxRowsData.splice(index, 1)
    setTaxes(newTaxRowsData)
  }

  const addTaxRow = () => {
    setTaxes([...taxes, { ...quoteTaxInitVals, taxId: 0 }])
  }

  const handleTaxChange = (index: number, evnt: any) => {
    const { name, value } = evnt.target
    // pass value for each name
    const rowsInput = [...taxes]
    const taxInfo = defaultTaxes.find((c) => c.id === +value)
    if (+value > 0) {
      if (taxInfo) {
        rowsInput[index]['taxId'] = taxInfo.id
        rowsInput[index]['taxName'] = taxInfo.taxName
        rowsInput[index]['taxPercent'] = taxInfo.taxValue
        setTaxes(rowsInput)
      }
    } else {
      rowsInput[index]['taxName'] = 'None'
      rowsInput[index]['taxPercent'] = taxInfo?.taxValue || 0
      rowsInput[index]['taxId'] = taxInfo?.id || 0
      setTaxes(rowsInput)
    }
  }

  const isDisabled = (taxId: number) => {
    if (taxId === 0) return false
    return taxes.some((c) => c.taxId === taxId)
  }

  const updateTxs = () => {
    const dto: IQuotationDto = {
      addTax: additionalTax as any,
      taxAmount: data.taxAmount,
      subAmount: subAmount,
      totalAmount: totalAmount,
      quotationAdditionalTaxes: taxes,
      id: quotationId,
      totalFreightCharges: data.totalFreightCharges,
    }
    const onResponse = (res: any) => {
      if (res) {
        bindDetails(quotationId)
      }
    }
    const newDto = {
      tax: dto,
      onResponse,
    }
    updateTaxes(newDto)
  }

  const [showAlert, setShowAlert] = useState(false)
  const [invType, setInvType] = useState<IStatus>({ id: 0, statusText: '' })
  const onStatusClick = (statusId: number, statusText: string) => {
    setShowAlert(true)
    setInvType({ id: statusId, statusText: statusText })
  }

  const [showD, setShowD] = useState(false)
  const onShowD = () => {
    setShowD(!showD)
  }

  const [showMenu, setShowMenu] = useState(false);
  const handleToggle = () => {
    setShowMenu(!showMenu);
  };

  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>Quotation Detail</PageTitle>
      {stages === LOADINGSTAGES.LOADING && <Loader />}
      {stages === LOADINGSTAGES.NEGATIVE && <ServerMessage message='No data found!' />}
      {stages === LOADINGSTAGES.ERROR && <ServerMessage message='Something went wrong!' />}
      {stages === LOADINGSTAGES.POSITIVE && (
        <div className='row'>
          <div className='col-xs-12 col-md-12'>
            <div className='notice d-flex bg-light-primary rounded border-primary-light border p-6 mb-3'>
              <i className='bi bi-info-circle-fill fs-1 text-primary me-4'></i>
              <div className='d-flex flex-stack flex-grow-1' title='Info'>
                <div className=''>
                  <div className='fs-7 text-gray-700'>
                    Quotation status will be updated to <strong>Sent</strong> after sending it
                    through email.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='card mb-5 mb-xl-10'>
        {stages === LOADINGSTAGES.POSITIVE && (
          <div className='card-body pt-9 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
              <div className='flex-grow-1'>
                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                        # {data.quotationNumber}
                      </a>
                      <a
                        href='#'
                        className={`badge badge-${data.statusText === 'draft' ? 'secondary' : 'light-success'
                          } ms-2 fs-8 py-1 px-3`}
                      >
                        {data.statusText}
                      </a>
                      {
                        data?.invoiceCount > 0 &&
                        <span className={`badge badge-secondary ms-2 fs-8 py-2 px-3`}                      >
                          Invoice created
                        </span>
                      }

                    </div>
                  </div>
                  <div className='d-flex my-4'>
                    {
                      <a
                        href='#'
                        onClick={() => toggleShowTemplate(data?.id)}
                        className={`btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-2 w-150px`}
                        id='kt_charts_widget_2_year_btn'
                      >
                        <i className='fas fa-print'></i> Preview &amp; Print
                      </a>
                    }
                    <button
                      type='button'
                      disabled={data?.invoiceCount > 0}
                      onClick={() => convertToInvoice(data?.id)}
                      className={`btn btn-sm btn-active-secondary btn-light border px-4 me-2 w-150px`}
                    >

                      {!loading && 'Convert to Invoice'}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle mx-2'></span>
                        </span>
                      )}
                    </button>
                    <a
                      href='#'
                      onClick={() => toggleShowMail(data?.id)}
                      className={`btn btn-sm btn-active-secondary btn-light border px-4 me-2 w-150px`}
                      id='kt_charts_widget_2_year_btn'
                    >
                      <i className='fas fa-envelope'></i> Email
                    </a>
                    <StatusDropdown
                      statusList={quoteStatus}
                      onStatusClick={onStatusClick}
                    />

                  </div>
                </div>
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-md-12'>
                <div className='row mb-7'>
                  <div className='col-lg-4'>
                    <h6 className='fw-bold text-muted mb-1'>
                      Bill By
                      {isCancelled === false && (
                        <i
                          className=' text-info fas fa-edit px-15 d-none'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_upgrade_plan'
                          onClick={() =>
                            setAddressForm({
                              type: 'billBy',
                              status: true,
                            })
                          }
                        ></i>
                      )}
                      <i
                        className=' text-danger fas fa-details px-15 d-none'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_upgrade_plan'
                        onClick={() =>
                          setAddressForm({
                            type: 'companyAddresses',
                            status: true,
                          })
                        }
                      ></i>
                    </h6>
                    {/* // edit Icon  */}
                    <CompanyAddress {...adressBillBy} />
                  </div>
                  <div className='col-lg-4'>
                    <h6 className=' fw-bold text-muted mb-1'>
                      Bill To
                      {isCancelled === false && (
                        <i
                          className=' text-info fas fa-edit px-15'
                          onClick={() =>
                            setAddressForm({
                              type: 'shipTo',
                              status: true,
                            })
                          }
                        ></i>
                      )}
                    </h6>
                    <CustomerAddress {...adressShipTo} />
                  </div>
                  <div className='col-lg-4'>
                    <div>
                      <h6 className='fw-bold text-muted mb-1'>Quotation Date</h6>
                      {!editField.quotationDate ? (
                        <>
                          <span className='fw-bolder fs-6 text-dark'>
                            {moment(data?.quotationDate).format('DD-MM-YYYY')}
                          </span>
                          {isCancelled === false && (
                            <i
                              className='text-info fas fa-edit px-15'
                              onClick={() =>
                                setEditField({
                                  ...editField,
                                  quotationDate: !editField.quotationDate,
                                })
                              }
                            ></i>
                          )}
                        </>
                      ) : (
                        <div className='d-flex align-items-center'>
                          <input
                            type='date'
                            className='form-control me-3'
                            defaultValue={moment(data?.quotationDate).format('YYYY-MM-DD')}
                            value={invoiceEdited.quotationDate}
                            onChange={(e) =>
                              setInvoiceEdited({
                                ...invoiceEdited,
                                quotationDate: e.target.value,
                              })
                            }
                          />
                          <div className='d-flex'>
                            <i
                              className='fa fa-close btn btn-sm btn-danger me-2'
                              onClick={() =>
                                setEditField({
                                  ...editField,
                                  quotationDate: !editField.quotationDate,
                                })
                              }
                            ></i>
                            <i
                              className='fa fa-check btn btn-sm btn-success'
                              onClick={() => {
                                updateQuoteInput({
                                  type: 'quotationDate',
                                  dto: {
                                    id: parseInt(id ?? data?.id),
                                    quotationDate: invoiceEdited.quotationDate,
                                  },
                                  onResponse: (e) => {
                                    if (e) {
                                      setEditField({
                                        ...editField,
                                        quotationDate: !editField.quotationDate,
                                      })
                                      bindDetails(id)
                                    }
                                  },
                                })
                              }}
                            ></i>
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      <h6 className=' fw-bold text-muted mb-1' style={{ marginTop: '1em' }}>
                        Quotation Number
                      </h6>
                      {!editField.quotationNumber ? (
                        <>
                          <span className='fw-bolder fs-6 text-dark'>{data?.quotationNumber}</span>
                          {!isCancelled && (
                            <i
                              className=' text-info fas fa-edit px-15'
                              onClick={() =>
                                setEditField({
                                  ...editField,
                                  quotationNumber: !editField.quotationNumber,
                                })
                              }
                            ></i>
                          )}
                        </>
                      ) : (
                        <div className='d-flex align-items-center'>
                          <input
                            type='numeric'
                            className='form-control me-3'
                            defaultValue={data?.quotationNumber}
                            value={invoiceEdited.quotationNumber}
                            onChange={(e) =>
                              setInvoiceEdited({
                                ...invoiceEdited,
                                quotationNumber: e.target.value,
                              })
                            }
                          />
                          <div className='d-flex'>
                            <button
                              className='btn btn-sm btn-danger me-2'
                              onClick={() =>
                                setEditField({
                                  ...editField,
                                  quotationNumber: !editField.quotationNumber,
                                })
                              }
                            >
                              <i className='fa fa-close' />
                            </button>
                            <button
                              className='btn btn-sm btn-success'
                              onClick={() => {
                                updateQuoteInput({
                                  type: 'quotationNumber',
                                  dto: {
                                    id: parseInt(id ?? data?.id),
                                    quotationNumber: invoiceEdited.quotationNumber,
                                  },
                                  onResponse: (e) => {
                                    if (e) {
                                      setEditField({
                                        ...editField,
                                        quotationNumber: !editField.quotationNumber,
                                      })
                                      bindDetails(id)
                                    }
                                  },
                                })
                              }}
                            >
                              <i
                                aria-disabled={invoiceEdited.quotationNumber === ''}
                                className='fa fa-check'
                              />
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='row mb-7'>
                  <div className='col-lg-3'>
                    <h6 className=' fw-bold text-muted mb-1'>Taxable Amount</h6>
                    {/* {!editField.subAmount ? (
                                            <> */}
                    <span className='fw-bolder fs-6 text-dark'>{subAmount.toFixed(2)}</span>
                    <br />
                    <i>Discount:</i>
                    {!editField.discount ? (
                      <>
                        <span className='fw-bolder fs-6 text-dark'>
                          {' '}
                          {data?.discount?.toFixed(2)}
                        </span>

                      </>
                    ) : (
                      <input
                        type='numeric'
                        className='form-control'
                        defaultValue={data?.discount}
                        value={invoiceEdited?.discount}
                        onChange={(e) =>
                          setInvoiceEdited({
                            ...invoiceEdited,
                            discount: e.target.value,
                          })
                        }
                      />
                    )}
                  </div>
                  <div className='col-lg-3'>
                    <h6 className=' fw-bold text-muted mb-1'>Tax Amount</h6>
                    <span className='fw-bolder fs-6 text-dark'>{taxAmount.toFixed(2)}</span>
                  </div>
                  <div className='col-lg-3'>
                    <h6 className=' fw-bold text-muted mb-1'>Additional Tax</h6>
                    <span className='fw-bolder fs-6 text-dark'>{additionalTax}</span>
                  </div>
                  <div className='col-lg-3'>
                    <h6 className=' fw-bold text-muted mb-1'>Amount</h6>
                    <span className='fw-bolder fs-6 text-dark'>{totalAmount.toFixed(2)}</span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <div className='col-lg-9'>
                    <h6 className=' fw-bold text-muted mb-1'>Amount in Words</h6>
                    <span className='fw-bolder fs-6 text-dark text-capitalize'>{amtInWords}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='separator'></div>
            <div className='row my-3'>
              <h6 className='fw-bold text-muted mb-1'>
                Additonal Taxes{' '}
                <button
                  className='btn btn-sm btn-light p-6'
                  style={{ fontSize: '0.2rem' }}
                  onClick={() => setAddTax(true)}
                >
                  <i className='fa fa-plus' />
                </button>
              </h6>
              <div className='row mb-lg-10 p-0'>
                <Table className='table align-middle table-row-dashed fs-6 px-5 dataTable no-footer'>
                  <tbody>
                    {taxes.map((item: IQuoteAdditionalTax, index: number) => {
                      // disable all rows which are available in initialTaxes
                      return (
                        <tr key={index}>
                          <td className='w-350px'>
                            <select
                              className='form-control'
                              name='taxName'
                              id={`taxName_${index}`}
                              value={item.taxId}
                              onChange={(evnt) => handleTaxChange(index, evnt)}
                              disabled={isDisabled(item.taxId)}
                            >
                              <option value={0}>None</option>
                              {isDisabled(item.taxId)
                                ? defaultTaxes.filter((tax) => tax.id === item.taxId).length >
                                0 && <option value={item.taxId}>{item.taxName}</option>
                                : defaultTaxes.map((tax) => (
                                  <option
                                    key={tax.id}
                                    value={tax.id}
                                    disabled={isDisabled(tax.id)}
                                  >
                                    {tax.taxName}
                                  </option>
                                ))}
                            </select>
                          </td>
                          <td>
                            <input
                              className='form-control'
                              name='taxPercent'
                              id={`taxPercent_${index}`}
                              value={item.taxPercent}
                              disabled={
                                defaultTaxes.filter((tax) => tax.id === item.taxId).length > 0
                              }
                              onChange={(evnt) => handleTaxChange(index, evnt)}
                            />
                          </td>
                          <td className='w-350px'>
                            <span className='fw-bolder fs-6 text-dark'>
                              {((item.taxPercent / 100) * subAmount ?? 0).toFixed(2)}
                            </span>
                          </td>
                          <td className='w-200px'>
                            <a onClick={() => setAddTax(true)}>
                              {' '}
                              <i className='las la-plus fs-2x'></i>
                            </a>
                            <a onClick={() => deleteTaxItem(index)} href='#'>
                              {' '}
                              <i className='las la-trash fs-2x text-danger me-5'></i>
                            </a>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
                <div className='d-flex justify-content-end'>
                  <div className='d-flex flex-column align-items-end me-5'>
                    <button
                      className='btn btn-sm btn-light w-125px'
                      onClick={() => {
                        addTaxRow()
                      }}
                    >
                      <i className='fa fa-plus' />
                      Add Tax
                    </button>
                    <span className='fw-bolder fs-6 text-dark mt-2'>
                      Add Tax Amount:{' '}
                      <span className='fw-bolder fs-6 text-dark'>{additionalTax}</span>
                      <button
                        className='btn btn-sm btn-light-primary ms-2 w-125px'
                        onClick={() => {
                          updateTxs()
                        }}
                      >
                        Update Taxes
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='row mt-5'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                <li className='nav-item me-2'>
                  <a
                    onClick={() => setTab(1)}
                    className='nav-link active'
                    data-bs-toggle='tab'
                    href='#'
                  >
                    Items
                  </a>
                </li>
              </ul>
              <div className='separator'></div>
              <div className='d-flex justify-content-end'>
                <div className='d-flex flex-column align-items-end me-5 mt-3'>
                  <button
                    className='btn btn-sm btn-light-primary'
                    onClick={() => UpdateInvoiceDetails()}
                  >
                    Update item(s)
                  </button>
                </div>
              </div>
              <div className='col-md-12 mt-5'>
                {tab == 1 && data && dataItems?.length > 0 && (
                  <ListQuoteItems
                    quotationId={id}
                    data={dataItems}
                    isCancelled={isCancelled}
                    setItemsData={updateItemsData}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        <></>
      </div>
      {showAlert && (
        <ChangeAlert
          message={`Are you sure you want to change the status to ${invType.statusText}?`}
          onConfirm={() => {
            onStatusChange(invType)
            toast.dismiss(id)
          }}
          onCancel={() => setShowAlert(false)}
        />
      )}
      {itemIdForUpdate !== undefined && (
        <InvoiceCloneModal data={data} setItemIdForUpdate={setItemIdForUpdate} />
      )}
      {addTax && <AddAdditionalTax id={0} afterFupModal={() => afterTaxUpdate()} />}
      <AddressForm
        id={quotationId}
        initialData={saveInitialData}
        setBillBy={setAddressBillBy}
        setBillTo={setAddressShipTo}
        type={addressForm.type}
        billBy={adressBillBy}
        billTo={adressShipTo}
        show={addressForm.status}
        handleClose={() => setAddressForm(false)}
        onUpdate={() => afterUpdate()}
      />
      {addressForm.type === 'companyAddresses' ? (
        <AddressList
          show={addressForm.status}
          companyId={data?.companyId}
          customerId={data?.customerId}
          handleClose={() => setAddressForm(false)}
        />
      ) : null}
      {showMail && (
        <QuoteMail
          mailDetails={mailDetails}
          setShowEmail={setShowMail}
          handleClose={() => {
            setShowMail(false)
            bindDetails(quotationId)
          }}
          data={data}
        />
      )}
      {showTemplate && (
        <QuoteTemplateSelect
          setShowInvoiceModal={setShowInvoiceModal}
          invoiceId={invoiceId}
          companyCode={companyCode}
        />
      )}
      {showShare && (
        <InvoiceShare
          setShowShareModal={setShowShareModal}
          invoiceId={invoiceId}
          companyCode={companyCode}
          template={'classic'}
        />
      )}
    </>
  )
}
