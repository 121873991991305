
import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { getBankById, saveBank } from '../../../../../services/account/BankService';
import { IBankAccountModel } from '../../../../modules/accounts/components/settings/SettingsModel';
import { LOADINGSTAGES, MyLgSixField } from '../../../components/components';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function Addbank(props: any) {
    const initialValues: IBankAccountModel = {
        id: 0,
        bankName: '',
        accountType: '',
        accountNumber: 0,
        bankId: 0,
        ifsc: '',
        adCode: '',
        branchId: '',
        swiftCode: '',
        companyId: 0,
        accountName: ''
    }
    const Schema = Yup.object().shape({
        bankName: Yup.string().required('Bank Name is required'),
        accountType: Yup.string().required('Account Type is required'),
        accountNumber: Yup.number().required('Account Number is required'),
        bankId: Yup.number().required('Bank Id is required'),
        ifsc: Yup.string().required('IFSC is required'),
        adCode: Yup.string().required('AD Code is required'),
        branchId: Yup.string().required('Branch Id is required'),
        swiftCode: Yup.string().required('Swift Code is required'),
        accountName: Yup.string().required('Account Name is required')
    });
    const { id } = useParams();
    const [values, setValues] = React.useState<IBankAccountModel>(initialValues);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [loadingStages, setLoadingStages] = React.useState<any>(LOADINGSTAGES.IDLE);
    const getBankdetails = (id: any) => {
        setLoading(true);
        getBankById(id).then((res: any) => {
            if (res.data.success) {
                setValues(res.data.data);
                setLoadingStages(LOADINGSTAGES.POSITIVE);
            }
            else {
                setLoadingStages(LOADINGSTAGES.NEGATIVE);
            }
        }).catch(() => {
            setLoadingStages(LOADINGSTAGES.ERROR);
        }
        ).finally(() => {
            setLoading(false);
        }
        );
    }
    const saveBankDetails = (values: IBankAccountModel) => {
        const id = toast.loading('Saving Bank Details...');
        setLoading(true);
        saveBank(values).then((res: any) => {
            if (res.data.success) {
                toast.success(res.data.message);
            }
            else {
                toast.error(res.data.message);
                setLoadingStages(LOADINGSTAGES.NEGATIVE);
            }
        }).catch((err) => {
            toast.error(err);
            setLoadingStages(LOADINGSTAGES.ERROR);
        }
        ).finally(() => {
            toast.dismiss(id);
            setLoading(false);
        }
        );
    }
    const formik = useFormik({
        initialValues: values,
        validationSchema: Schema,
        enableReinitialize: true,
        onSubmit: (values) => {
            saveBankDetails(values);
        }
    });
    useEffect(() => {
        if (id) {
            getBankdetails(id);
        }
        else {
            setLoadingStages(LOADINGSTAGES.POSITIVE);
        }
    }, [])
    return (
        <>
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_kitty_group_details'
                    aria-expanded='true'
                    aria-controls='kt_kitty_group_details'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'> {id ? "Edit" : "Add"}{" "} Bank</h3>
                    </div>
                    {loadingStages === LOADINGSTAGES.ERROR && <div className="alert alert-secondary text-gray-700">Something went wrong</div>}
                    {loadingStages === LOADINGSTAGES.LOADING && <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span> </div>}
                    {loadingStages === LOADINGSTAGES.IDLE && <div className="alert alert-info">Please wait...</div>}
                </div>
                {loadingStages === LOADINGSTAGES.POSITIVE &&
                    <div id='kt_kitty_group_details' className='collapse show'>
                        <div className='card-body pt-9'>
                            <form onSubmit={formik.handleSubmit}>
                                <div className='row mb-7'>
                                    <div className= 'form-group row'>
                                    <MyLgSixField label="Bank Name" name="bankName" formik={formik} />
                                    <MyLgSixField label="Account Type" name="accountType" formik={formik} />
                                    </div>
                                    <div className= 'form-group row'>
                                    <MyLgSixField label="Account Number" name="accountNumber" formik={formik} />
                                    <MyLgSixField label="Account Name" name="accountName" formik={formik} />
                                    </div>
                                    <div className= 'form-group row'>
                                    <MyLgSixField label="Bank Id" name="bankId" formik={formik} />
                                    <MyLgSixField label="IFSC" name="ifsc" formik={formik} />
                                    </div>
                                    <div className= 'form-group row'>
                                    <MyLgSixField label="AD Code" name="adCode" formik={formik} />
                                    <MyLgSixField label="Branch Id" name="branchId" formik={formik} />
                                    </div>
                                    <div className= 'form-group row'>
                                    <MyLgSixField label="Swift Code" name="swiftCode" formik={formik} />
                                    </div>
                                </div>
                                <div className='d-flex justify-content-end'>
                                    <button type='submit' className='btn btn-primary' disabled={loading}>
                                        <span className='indicator-label'>Save</span>
                                        {loading &&
                                            <span className='indicator-progress'>Please wait...
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'>
                                                </span>
                                            </span>
                                        }
                                    </button>
                                    <Link to="/account/list-banks" className="btn btn-light ms-3">Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}


