import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { addCategory } from "../../../services/CategoryService";
import { toastNotify } from "../../../services/NotifyService";
import { ValidationField, ValidationSelect, ValidationTextArea } from "../components/components";

export const AddCategoryModel = (props: any) => {
    const { open, onClose, handleAddCategory } = props;
    const addCat = (data: any) => {
        const lId = toast.loading("Adding Category...");
        addCategory(data).then((res) => {
            if (res.data.success) {
                toastNotify(lId, res);
                handleAddCategory();
                onClose();
            }
            else {
                toastNotify(lId, res);
            }
        }).catch((err) => {
            const res = { success: false, message: "Something went wrong" };
            toastNotify(lId, res);
        }).finally(() => {
            toast.dismiss(lId);
        });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            description: '',
            productGroupId: 0,
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required('Category Name is required').max(150, 'Max 150 characters'),
            description: Yup.string()
                .max(500, 'Max 500 characters'),
        }),
        onSubmit: (values: any) => {
            addCat(values);
        },
    });

    const modalStyle = {
        // position: "fixed",
        top: "40%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "700px",
        height: "600px",
        backgroundColor: "white",
        border: "1px solid #ccc",
        zIndex: 100,
        borderRadius: "10px",
    };

    //const [productGroups, setProductGroups] = useState([]);
    // const bindProductGroup = () => {
    //     const lId = toast.loading("Loading Product Groups...");
    //     getProductGroups().then((res) => {
    //         if (res.data.success) {
    //             res.data.data.unshift({ id: 0, productGroupName: "Select Product Group" });
    //             const array = res.data.data.map((item: any) => {
    //                 return { id: item.id, name: item.productGroupName };
    //             });
    //             setProductGroups(array)
    //         } else {
    //             toastNotify(lId, res);
    //         }
    //     }).catch((err) => {
    //         const res = { success: false, message: "Something went wrong" };
    //         toastNotify(lId, res);
    //     }).finally(() => {
    //         toast.dismiss(lId);
    //     });
    // };

    useEffect(() => {
        //bindProductGroup();
        if (open) {
            formik.resetForm();
        }
    }, [open]);
    
    return (
        <div
            className='modal fade show d-block'
            id='kt_modal_add_user'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
            style={modalStyle}
        >
            <div className='modal-dialog modal-xl' >
                <form
                    className='form'
                    id='kt_modal_add_user_form'
                    onSubmit={formik.handleSubmit}
                >
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title' id='exampleModalLabel'>
                                Add Category
                            </h5>
                            <button
                                type='button'
                                className='btn btn-sm btn-icon btn-primary'

                                data-dismiss='modal'
                                // aria-label='Close'
                                onClick={onClose}
                            >
                                <i aria-hidden='true' className='fa fa-close'></i>
                            </button>
                        </div>
                        <div className='modal-body'>
                            {/* <div className='card mb-5 mb-xl-10'> */}
                            <div className='card-body p-9'>
                                <div className='row mb-7'>
                                    {/* <ValidationSelect
                                        className='col-md-6'
                                        label='Product Group'
                                        isRequired
                                        name='productGroupId'
                                        options={productGroups}
                                        formik={formik}
                                    /> */}
                                    <ValidationField
                                        className='col-md-6'
                                        label='Category Name'
                                        isRequired
                                        name='name'
                                        type='text'
                                        placeholder='Category Name'
                                        formik={formik}
                                    />

                                </div>
                                <div className='row mb-7'>
                                    <ValidationTextArea
                                        className='col-md-12'
                                        label='Category Description'
                                        name='description'
                                        type='text'
                                        placeholder='Category Description'
                                        formik={formik}
                                    />
                                </div>
                            </div>
                            {/* </div> */}
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn btn-light font-weight-bold'
                                data-dismiss='modal'

                                onClick={onClose}
                            >
                                Close
                            </button>
                            <button
                                type='submit'
                                className='btn btn-primary font-weight-bold'
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}