import axiosInstance from '../services/AxiosInstance';
import { AUTH_LOCAL_STORAGE_KEY } from "../../src/app/modules/auth/core/AuthHelpers";

export function getPaymentModes() {
    return axiosInstance.get(`common/paymentMode`);
}


export function getTotalDashBoardCounter() {
    return axiosInstance.get(`counters/GetTotalDashBoardCounts`);
}

export function getProducts() {
    return axiosInstance.get(`products`);
}

export function getCompanies() {
    return axiosInstance.get(`companies/all`);
}

export function getOrderStages() {
    return axiosInstance.get(`shared/orderStages`);
}

export function getStates(countryId) {
    return axiosInstance.get(`shared/states/country/${countryId}`);
}

export function getTaxes(isAdditional = false) {
    return axiosInstance.get(`shared/taxes?isAdditional=${isAdditional}`);
}
export function getCurrencies(isActive) {
    return axiosInstance.get(`shared/currencies?isActive=${isActive}`);
}

export function getUnits() {
    return axiosInstance.get(`shared/units`);
}

export function deleteEntity(url) {
    if (url.includes('invoices/cancel')) {
        return axiosInstance.put(url);
    }
    return axiosInstance.delete(url);
}

export function getTerms() {
    return axiosInstance.get(`shared/terms`);
}

export function saveTerms(data) {
    return axiosInstance.post(`shared/terms`, data);
}

export function updateTerms(id, data) {
    return axiosInstance.put(`shared/terms/${id}`, data);
}

export function getTermById(id) {
    return axiosInstance.get(`shared/terms/${id}`);
}

export function getNotes() {
    return axiosInstance.get(`shared/notes`);
}

export function saveNotes(data) {
    return axiosInstance.post(`shared/notes`, data);
}

export function updateNotes(id, data) {
    return axiosInstance.put(`shared/notes/${id}`, data);
}

export function getNoteById(id) {
    return axiosInstance.get(`shared/notes/${id}`);
}

export function changePassword(data) {
    return axiosInstance.post(`account/change-password`, data);
}

export function reportBug(data) {
    return axiosInstance.post(`shared/reportBug`, data);
}

export function getDocumentLabels() {
    return axiosInstance.get(`shared/documentLabels`);
}

export function getCompanyCode() {
    let code = '';
    const aware_user = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY));
    if (aware_user) {
        code = aware_user?.companyCode;
    }
    return code;
}
export function getTaxNames() {
    return axiosInstance.get(`taxationtype/getall`);
}
export function uploadFile(data) {
    return axiosInstance.post(`shared/upload`, data);
}
export function getProductGroups() {
    return axiosInstance.get(`shared/productGroup`);
}

export function getPrefixes() {
    return axiosInstance.get(`shared/prefixes`);
}
export function getQuotationStatus() {
    return axiosInstance.get(`shared/quotation-Status`);
}

export function updateInvoicePrefix(data) {
    return axiosInstance.put(`shared/updateInvoicePrefix`, data);
}

export function updateQuotePrefix(data) {
    return axiosInstance.put(`shared/updateQuotePrefix`, data);
}

export function updatePurchaseOrderPrefix(data) {
    return axiosInstance.put(`shared/updatePurchaseOrderPrefix`, data);
}