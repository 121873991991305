
import React from 'react'
import { MyLgFourField, MyLgSixField, MyLgTenField, MyTextArea } from '../../components/components';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sendPoMail } from '../../../../services/BackendService/PurchaseOrdersService';

export default function POEMail(props: any) {
    const { setShowEmail, invoiceId, mailDetails } = props;

    // const [link, setLink] = useState<string>(`${process.env.REACT_APP_PORTAL_URL}/shared-invoice/${invoiceId}/${companyCode}/${template}`);
    const navigate = useNavigate();
    const closeModal = () => {
        if (setShowEmail) {

            setShowEmail(false);
        } else {
            { navigate('/purchase-orders'); }
        }
    }

    // function used to send email to customer with invoice attached
    const sendEmail = (e: any) => {
        const toastId = toast.loading('Sending Email...');
        // e.preventDefault();
        const id = formik.values.invoiceId;
        const data = {
            to: formik.values.to,
            cCList: formik.values.cCList ? formik.values.cCList : '',
            subject: formik.values.subject,
            message: formik.values.message,
        }
        const label = formik.values.label;
        sendPoMail(id, data, label).then((res: any) => {
            if (res.data.success) {
                toast.update(toastId, {
                    render: res.data.message,
                    type: 'success',
                    isLoading: false,
                    autoClose: 2000,
                });
                setTimeout(() => {
                    closeModal();
                }, 3000);
            }
            else {
                toast.update(toastId, {
                    render: res.data.message,
                    type: 'error',
                    isLoading: false,
                    autoClose: 2000,
                });

            }
        }).catch((err: any) => {
            toast.update(toastId, {
                render: err.message,
                type: 'error',
                isLoading: false,
                autoClose: 2000,
            });
        }
        )
    }
    
    const formik = useFormik({
        initialValues: {
            invoiceId: mailDetails.invoiceId,
            to: mailDetails.to,
            cCList: mailDetails.cCList,
            subject: mailDetails.subject,
            message: mailDetails.message,
            label: 'Duplicate',
        },
        validationSchema: Yup.object({
            to: Yup.string().required('Required'),
            cCList: Yup.string(),
            subject: Yup.string().required('Required'),
            message: Yup.string().required('Required'),
            label: Yup.string().required('Required'),
        }),
        onSubmit: (values: any) => {
            sendEmail(values);
        }
    });

    return (<>
        <div className='modal fade show d-block' id='kt_modal_add_user' role='dialog' tabIndex={-1} aria-modal='true'>
            <div className='modal-dialog modal-dialog-centered modal-xl' role='document'>
                <div className='modal-content'>
                    <div className='container-fluid '>
                        <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                            {/* begin::Scroll */}
                            <div
                                className='card-body p-9 d-flex flex-column scroll-y me-n7 pe-7'
                                id='kt_modal_add_user_scroll'
                                data-kt-scroll='true'
                                data-kt-scroll-activate='{default: false, lg: true}'
                                data-kt-scroll-max-height='auto'
                                data-kt-scroll-dependencies='#kt_modal_add_user_header'
                                data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                                data-kt-scroll-offset='300px'
                            >
                                <div className='card card-custom'>
                                    <div className='card-header'>
                                        <div className='card-title'>
                                            <h3 className='card-label'>Email To: {mailDetails.email ?? ""}</h3>
                                        </div>
                                        <div className='card-toolbar'>
                                            <i className='fas fa-times text-danger' onClick={closeModal} style={{ cursor: 'pointer' }}></i>
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        <div className='form-group row'>
                                            <MyLgSixField label="To" name="to" formik={formik} />
                                            <MyLgSixField label="CC" name="cCList" formik={formik} />
                                            {/* <MyLgSixField label="Label" name="label" formik={formik} /> */}
                                        </div>
                                        <div className='form-group row'>
                                            <MyLgSixField label="Subject" name="subject" formik={formik} className="mb-10" />
                                            <MyTextArea rows={10} label="Message" name="message" formik={formik} className="mb-10"/>
                                        </div>
                                        <div className='card-footer'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <button type='submit' className='btn btn-primary '>
                                                        Send
                                                    </button>
                                                    <button type='reset' className='btn btn-secondary mx-2' onClick={closeModal}>
                                                        Cancel
                                                    </button>
                                                    <span className='svg-icon svg-icon-2x svg-icon-primary ms-5'>
                                                        Note : Invoice will be attached with the email in PDF format.
                                                    </span>
                                                </div>
                                                <div className='separator separator-dashed my-7'></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        {/* begin::Modal Backdrop */}
        <div className='modal-backdrop fade show'></div>
        {/* end::Modal Backdrop */}
    </>


    )
}
