import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { KTSVG } from '../../../../_metronic/helpers';

const POShare = (props: any) => {
    const { setShowShareModal, invoiceId, companyCode, template } = props;
    // const [link, setLink] = useState<string>(`${process.env.REACT_APP_API_URL}/Invoice/pdf/${invoiceId}/${companyCode}/invoice`);
    const [link, setLink] = useState<string>(`${process.env.REACT_APP_PORTAL_URL}/shared-invoice/${invoiceId}/${companyCode}/${template}`);
    const [btnText, setBtnText] = useState<string>('Copy link')

    const navigate = useNavigate();

    const closeModal = () => {
        if (setShowShareModal) {
            setShowShareModal(false);
        } else {
            { navigate('/invoices'); }
        }
    }

    const invoices = {
        invoice1: 'invoice',
        invoice2: 'invoice-simple'
    }
    useEffect(() => {

    }, [])

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                    {/* begin::Modal content */}
                    <div className='modal-content'>
                        <div className='modal-header'>
                            {/* begin::Modal title */}
                            <h2 className='fw-bolder'>Share Invoice</h2>
                            {/* end::Modal title */}

                            {/* begin::Close */}
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                onClick={() => closeModal()}
                                style={{ cursor: 'pointer' }}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                            </div>
                            {/* end::Close */}
                        </div>
                        <div className='modal-body' style={{ height: '400px', paddingTop: '50px', paddingBottom: '50px' }}>
                            <div className='row'>
                                <div className='col-md-10 offset-1 text-center'>
                                    <div className='notice d-flex bg-light-success rounded border-success border border-dashed mb-9 p-6'>
                                        <KTSVG
                                            path='/media/icons/duotune/general/gen044.svg'
                                            className='svg-icon-2tx svg-icon-success me-4'
                                        />

                                        <div className='d-flex flex-stack flex-grow-1'>
                                            <div className='fw-normal'>
                                                <h4 className='text-gray-800'>You are just going to share the invoice</h4>
                                                <div className='fs-6 text-gray-600'>
                                                    Anyone with this link will be able to view the invoice, in PDF form, without needing to login into the <strong>Amber Bills</strong> portal.

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12 text-center'>
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control  border-dashed" readOnly placeholder="Sharing link" aria-label="Sharing link" aria-describedby="basic-addon2"
                                            value={link} />
                                        <div className="input-group-append" >
                                            <span className="input-group-text cursor-pointer" id="basic-addon2" style={{marginLeft:'-2px', borderLeft:'none'}}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(link);
                                                    setBtnText('Link copied');
                                                }

                                                }>{btnText}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* end::Modal body */}
                    </div>
                    {/* end::Modal content */}
                </div>
                {/* end::Modal dialog */}
            </div>
            {/* begin::Modal Backdrop */}
            <div className='modal-backdrop fade show'></div>
            {/* end::Modal Backdrop */}
        </>
    )
}

export { POShare }
