import axios from 'axios'
import { AUTH_LOCAL_STORAGE_KEY } from './AuthHelpers'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/account/verify_token`
export const LOGIN_URL = `${API_URL}/account/authenticate`
export const REGISTER_URL = `${API_URL}/account/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/account/forgot_password`

// Server should return AuthModel
export function login(username: string, password: string) {
  return axios.post<any>(LOGIN_URL, {
    username,
    password,
  })
}

// Server should return AuthModel
export function register(
  username: string,
  firstname: string,
  lastname: string,
  password: string,
  appId: number
) {
  return axios.post(REGISTER_URL, {
    username,
    firstName: firstname,
    lastName: lastname,
    password,
    appId
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  const aware_user = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || '');
  if (aware_user) {
    // const username = axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    //   accessToken: token,
    // }).then((response) => {
    //   console.log(response);
    // })
    // const user: any = {
    //   username: username,
    // };
    return aware_user;
  }
  return null;

  //const aware_user = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || '');
  // if (aware_user) {
  //   var _token = aware_user?.token;
  //   if (_token === token) {
  //     var user: UserModel = {
  //       id: 0,
  //       username: 'string',
  //       password: 'string',
  //       email: 'string',
  //       first_name: 'string',
  //       last_name: 'string',
  //       fullname: 'string',
  //       occupation: 'string',
  //       companyName: 'string',
  //       phone: 'string',
  //       roles: [],
  //       pic: 'string',
  //       timeZone: 'string',
  //       website: 'https://keenthemes.com'
  //     };

  //     return user;
  //   }
  //   return null;
  // }
}
