
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { CreditNoteAddModel } from './c-nPartials/CreditNoteAddModel';


export  function CreditNoteAdd() {
    const navigate = useNavigate();
    const setItemIdForUpdate = (val: any) => {
        navigate("/credit-notes");
    }

    return <CreditNoteAddModel setItemIdForUpdate={setItemIdForUpdate} />
}
