import React from "react";
import { Link } from "react-router-dom";
import { Banner } from "./Banner";
import { Faqs } from "./Faqs";
import { FeaturesMain } from "./FeaturesMain";
import { FeaturesMore } from "./FeaturesMore";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Pricing } from "./Pricing";
import { Projects } from "./ProjectsPartial";
import { Testimonials } from "./Testimonials";
import { Trial } from "./Trial";
import Navbar from "./Navbar";

export function HomePage() {
    const text: string = 'Save thousands to millions of bucks by using single tool for different amazing and outstanding cool and great useful admin';

    return (
        <>
            <div className="mt-lg-10">

                {/*begin::Header Section*/}
                {/* <Header /> */}
                <Navbar />
                {/*end::Header Section*/}

                {/* Banner */}
                <Banner />
                {/* End Banner */}

                <FeaturesMain />

                {/* <!-- Start block: Features Ext --> */}
                <FeaturesMore />

                {/* <!-- End block: Features Ext --> */}

                {/* <!-- Start block: Testimonials --> */}
                <Testimonials />
                {/* <!-- End block --> */}


                {/* <!-- Start block: Pricing --> */}
                {/* <Pricing text={text}/> */}
                {/* <!-- End blockL Pricing --> */}


                {/* <!-- Start block: faqs --> */}
               <Faqs/>

                {/* <!-- End block: faqs --> */}


                {/* <!-- Start block: trial --> */}
                <Trial />
                {/* <!-- End block: trial --> */}


                <Footer />
            </div>
            <div className="d-none">


                <div className="d-flex flex-column flex-root" id="kt_app_root">
                    {/*begin::Header Section*/}

                    {/*end::Header Section*/}
                </div>

                {/*begin::Features*/}
                <div className="mb-n10 mb-lg-n20 z-index-2">
                    {/*begin::Container*/}
                    <div className="container-fluid">

                    </div>
                    {/*end::Container*/}
                </div>
                {/*end::How It Works Section*/}


                <div style={{ marginTop: '100px' }}>
                    <Pricing />
                </div>

                <Testimonials />
                <Footer />
            </div>
        </>
    )
}