import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { getNotes } from '../../services/CommonService';
import { toastNotify } from '../../services/NotifyService';
import { KTSVG } from '../../_metronic/helpers';

export function EditNotes(props: any) {
    const { setShowNotes, afterUpdateNotes, data } = props;
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState<any>('');
    const [notesArr, setNotesArr] = useState<any>([]);

    const onCancel = () => {
        setShowNotes(false);
    }

    const onOk = () => {
        setTimeout(() => {
            afterUpdateNotes(value);
        }, 500);
    }

    const bindNotes = () => {
        setLoading(true);

        getNotes().then((res: any) => {
            setNotesArr(res.data.data)
        }).catch((err: any) => {
            const id = toast.loading("");
            const res = { data: { message: 'Something went wrong', success: false } };
            toastNotify(id, res);
        }).finally(() => {
            setLoading(false);
        })
    }

    const selectTerm = (term: any) => {
        setValue(value + '\n' + term.noteText);
    }

    useEffect(() => {
        bindNotes();
        setTimeout(() => {
            setValue(data);
        }, 500);
    }, [])

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered modal-xl'>
                    {/* begin::Modal content */}
                    <div className='modal-content'>
                        <div className='modal-header'>
                            {/* begin::Modal title */}
                            <h2 className='fw-bolder'>Notes</h2>
                            {/* end::Modal title */}

                            {/* begin::Close */}
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                onClick={() => setShowNotes(false)}
                                style={{ cursor: 'pointer' }}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                            </div>
                            {/* end::Close */}
                        </div>
                        <div className='modal-body'>
                            <div className='card mb-5 mb-xl-10'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-md-9'>
                                            <ReactQuill theme="snow" value={value} onChange={setValue} />
                                        </div>
                                        <div className='col-md-3'>
                                            <table className="table table-row-dashed table-row-gray-300">
                                                <thead>
                                                    <tr>
                                                        <th>Template</th>
                                                    </tr>
                                                    
                                                </thead>
                                                <tbody>
                                                    {
                                                        notesArr && notesArr.map((element: any) => (
                                                            <tr className='cursor-pointer'
                                                                key={element.id}
                                                                onClick={() => selectTerm(element)}>
                                                                <td
                                                                >{element.noteText}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        {/* end::Modal body */}
                        <div className="modal-footer">
                            <button onClick={() => onCancel()}
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                            >
                                Cancel
                            </button>
                            <button onClick={() => onOk()} type="button" className="btn btn-danger">
                                Save
                            </button>
                        </div>
                    </div>
                    {/* end::Modal content */}
                </div>
                {/* end::Modal dialog */}
            </div>
            {/* begin::Modal Backdrop */}
            <div className='modal-backdrop fade show'></div>
            {/* end::Modal Backdrop */}

        </>
    )
}