import axiosInstance from './AxiosInstance';

export function getPaymentModes() {
    return axiosInstance.get(`payments/paymentModes`);
}

export function savePayment(data) {
    return axiosInstance.post(`payments`, data);
}

export function getPaymentDetails(id) {
    return axiosInstance.get(`payments/${id}`);
}

export function searchPayments(params) {
    return axiosInstance.post(`payments/search`, params);
}

export function paymentSummary(params) {
    return axiosInstance.post(`payments/summary`, params);
}
export function updatePayment(data,id) {
    return axiosInstance.put(`payments/${id}`, data);
}
