export function CounterBox(props: any) {

    return (
        <div className="row text-center">
            {
                props.data && props.data.map((element: any, index: any) =>
                (
                    <div className="col px-10" key={index} style={{whiteSpace: 'nowrap'}}>
                        <div className="counter">
                            <h2>{element.value}</h2>
                            <p className="count-text text-gray-600">{element.text}</p>
                        </div>
                    </div>
                )
                )
            }
        </div>
    )
}