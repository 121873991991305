import axiosInstance from '../services/AxiosInstance';

export function getCustomers() {
    return axiosInstance.get(`customers`);
}

export function searchCustomers(searchParams) {
    return axiosInstance.post(`customers/search`, searchParams);
}

export function getSingleCustomer(id) {
    return axiosInstance.get(`customers/${id}`);
}

export function saveCustomer(id, postData) {
    if (id > 0) {
        return axiosInstance.put(`customers/${id}`, postData);
    }
    return axiosInstance.post(`customers`, postData);
}
export function checkPhoneNumber(number) {
    return axiosInstance.get(`customers/checkPhone/${number}`);
}

export function updatePost(post, postId) {
    return axiosInstance.put(`posts/${postId}.json`, post);
}

export function deletePost(postId) {
    return axiosInstance.delete(`posts/${postId}.json`);
}

export function formatPosts(postsData) {
    let posts = [];
    for (let key in postsData) {
        posts.push({ ...postsData[key], id: key });
    }

    return posts;
}

export function getCustomerBranches(customerId, id) {
    return axiosInstance.get(`customers/branches/${customerId}/${id}`);
}
export function recentCustomers() {
    return axiosInstance.get(`customers/recent`);
}