import { IBillBy, IBillTo, IShipTo } from '../interface/IInvoiceTypes/IQuotation'
export function CompanyAddress(props: IBillBy) {
  const { companyName, companyAddress, companyCity, companyState, companyGstin, companyPostalCode } =
    props

  return (
    <address>
      <span className='fw-bolder fs-6 text-dark'>{companyName}</span>
      <p className='mb-0'>Address: {companyAddress}</p>
      <p className='mb-0'>City: {companyCity}</p>
      <p className='mb-0'>State: {companyState}</p>
      <p className='mb-1'>GSTIN: {companyGstin}</p>
      <p className='mb-1'>PostalCode: {companyPostalCode}</p>
    </address>
  )
}

export const BillAddress = (props: any) => {
  const { billName, billAddress, billCity, billState, billPostalCode, billBranchGstin, customerPan, billEmail, billContactNo } = props;
  return (
    <address>
      <span className='fw-bolder fs-6 text-dark'>{billName}</span>
      <p className='mb-0'>Address: {billAddress}</p>
      <p className='mb-0'>City: {billCity}</p>
      <p className='mb-0'>State: {billState}</p>
      <p className='mb-1'>Postal Code: {billPostalCode}</p>
      {/* <p className='mb-1'>PAN: {customerPan}</p> */}
      <p className='mb-1'>Email:{billEmail}</p>
      <p className='mb-1'>Contact No: {billContactNo}</p>
      <p className='mb-1'>GSTIN: {billBranchGstin}</p>
    </address>
  )
}


export const ShipAddress = (props: any) => {
  const { shipName, shipAddress, shipCity, shipState, shipPostalCode, shipBranchGstin, shipEmail, shipContactNo } = props;
  return (
    <address>
      <span className='fw-bolder fs-6 text-dark'>{shipName}</span>
      <p className='mb-0'>Address: {shipAddress}</p>
      <p className='mb-0'>City: {shipCity}</p>
      <p className='mb-0'>State: {shipState}</p>
      <p className='mb-1'>PostalCode: {shipPostalCode}</p>
      <p className='mb-1'>Email:{shipEmail}</p>
      <p className='mb-1'>Contact No: {shipContactNo}</p>
      <p className='mb-1'>GSTIN: {shipBranchGstin}</p>
    </address>
  )
}


export const CustomerAddress = (props: IBillTo) => {
  const { billName, billAddress, billCity, billState, billPostalCode, customerGstin, customerPan, customerEmail, customerContactNo } = props;
  return (
    <address>
      <span className='fw-bolder fs-6 text-dark'>{billName}</span>
      <p className='mb-0'>Address: {billAddress}</p>
      <p className='mb-0'>City: {billCity}</p>
      <p className='mb-0'>State: {billState}</p>
      <p className='mb-1'>GSTIN: {customerGstin}</p>
      <p className='mb-1'>PostalCode: {billPostalCode}</p>
      {/* <p className='mb-1'>PAN: {customerPan}</p> */}
      <p className='mb-1'>Email:{customerEmail}</p>
      <p className='mb-1'>Contact No: {customerContactNo}</p>

    </address>
  )
}

export const SupplierAddress = (props: any) => {
  const { supplierName, supplierAddress, supplierCity, supplierState, supplierPostalCode, supplierGstin, supplierEmail, supplierContactNo } = props;
  return (
    <address>
      <span className='fw-bolder fs-6 text-dark'>{supplierName}</span>
      <p className='mb-0'>Address: {supplierAddress}</p>
      <p className='mb-0'>City: {supplierCity}</p>
      <p className='mb-0'>State: {supplierState}</p>
      <p className='mb-1'>GSTIN: {supplierGstin}</p>
      <p className='mb-1'>PostalCode: {supplierPostalCode}</p>
      <p className='mb-1'>Email:{supplierEmail}</p>
      <p className='mb-1'>Contact No: {supplierContactNo}</p>

    </address>
  )
}
