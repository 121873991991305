import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useFormik } from "formik";
import { Table } from "react-bootstrap";
import moment from 'moment'
import { toast } from "react-toastify";
import { Combobox } from "react-widgets/cjs";
import Accordion from 'react-bootstrap/Accordion';
import * as Yup from 'yup'
import { postInvoiceSettings } from "../../../services/InvoiceSettings";
import { toastNotify } from "../../../services/NotifyService";
import { IInvoiceSettings } from "../../../_models/invoiceSettings-model";
// shared components

import { Link, useNavigate, useParams } from 'react-router-dom';
import { Loader, LOADINGSTAGES, ValidationField, ValidationSelect, ValidationTextArea, } from '../components/components';

//models

import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers"

import Switch, { ToggleSwitchnew, ValidationSelectNew, ValidationFieldNew } from "../components/Togglenew";

import { breadcrumbs } from "../components/PageTitle";
import { PageTitle } from "../../../_metronic/layout/core";
import { boolean } from "yup/lib/locale";
import { bugInitValues } from "../../../_models/bug-model";

export function InvoiceSettings(props: any) {
    const { afterInvoiceUpdate } = props;
    const navigate = useNavigate();


    const [loading, setLoading] = useState(false);

    const [Names, setNames] = useState<any>([]);
    // const invoiceSettings = Yup.object().shape({
    //     showShippingAddress: Yup.boolean(),


    //     showPrimarySignature: Yup.boolean()
    //         .required('Please choose Show Shipping Address'),

    //     showSecondrySignature: Yup.boolean()
    //         .required('Please choose show Secondary Signature'),

    //     decimalplaces: Yup.number()
    //         .required('Please choose decimal Places'),

    //     decimalplacesForQuantity: Yup.number()
    //         .required('Please choose decimal Places for Quanity'),

    //     totalWithoutRoundOffDecimal: Yup.boolean()
    //         .required('Please choose the total width round off Decimal'),

    //     showTotalTaxAmount: Yup.boolean()
    //         .required('Please choose Show Total tax Amount'),

    //     showBankDetails: Yup.boolean()
    //         .required('Please choose the Show Bank Detail'),

    //     documentPrefixSaperator: Yup.boolean()
    //         .required('Please choose the Show Prefix selector'),
    // })

    const today = new Date();
    const initialValues = {
        showShippingAddress: true,
        showPrimarySignature: true,
        showSecondrySignature: true,
        decimalplaces: 1,
        decimalplacesForQuantity: 1,//today.toString(),
        totalWithoutRoundOffDecimal: true,//(today.setDate(today.getDate() + 14)).toString(),
        showTotalTaxAmount: true,
        showBankDetails: true,
        documentPrefixSaperator: '',

    }


    const showShippingAddressToggle = (e: any) => {
        if (e.target.checked) {
            formik.values.showShippingAddress = true;
        }
        else {
            formik.values.showShippingAddress = false;
        }
    }

    const showPrimarySignatureToggle = (e: any) => {
        if (e.target.checked) {
            formik.values.showPrimarySignature = true;
        }
        else {
            formik.values.showPrimarySignature = false;
        }
    }
    const showSecondrySignatureToggle = (e: any) => {
        if (e.target.checked) {
            formik.values.showSecondrySignature = true;
        }
        else {
            formik.values.showSecondrySignature = false;
        }
    }
    const totalWithoutRoundOffDecimalToggle = (e: any) => {
        if (e.target.checked) {
            formik.values.totalWithoutRoundOffDecimal = true;
        }
        else {
            formik.values.totalWithoutRoundOffDecimal = false;
        }
    }
    const showTotalTaxAmountToggle = (e: any) => {
        if (e.target.checked) {
            formik.values.showTotalTaxAmount = true;
        }
        else {
            formik.values.showTotalTaxAmount = false;
        }
    }
    const showBankDetailsToggle = (e: any) => {
        if (e.target.checked) {
            formik.values.showBankDetails = true;
        }
        else {
            formik.values.showBankDetails = false;
        }
    }



    const bindStates = () => {

        const documentPrefixSaperator = [{ "id": "1", "Name": "-" }, { "id": "2", "Name": "/" }]
        const prefix = documentPrefixSaperator.map((item: any) => {
            return { "id": item.id, "name": item.Name }
        })
        //  prefix.unshift({ id: 0, name: 'Select Document Prefix Saperator' })
        setNames(prefix);
    }



    const formik = useFormik({
        enableReinitialize: true,
        initialValues,

        onSubmit: (values, { setStatus, setSubmitting }) => {
            
            const _invoicesettings: IInvoiceSettings = {
                showShippingAddress: values.showShippingAddress,
                showPrimarySignature: values.showPrimarySignature,
                showSecondrySignature: values.showSecondrySignature,
                decimalplaces: values.decimalplaces,
                decimalplacesForQuantity: values.decimalplacesForQuantity,
                totalWithoutRoundOffDecimal: values.totalWithoutRoundOffDecimal,//(today.setDate(today.getDate() + 14)).toString(),
                showTotalTaxAmount: values.showTotalTaxAmount,
                showBankDetails: values.showTotalTaxAmount,
                documentPrefixSaperator: values.documentPrefixSaperator,


            }


            var postData = {
                invoice: _invoicesettings,

            };
            setLoading(true);
            const toastId = toast.loading("Please wait...");

            Save(postData);
        },
    })
    const toastId = toast.loading("Please wait...");

    const [loadingStages, setLoadingStages] = useState(LOADINGSTAGES.IDLE);
    const Save = (postData: any) => {
        setLoadingStages(LOADINGSTAGES.LOADING);
        setLoading(true);
        setTimeout(() => {
            postInvoiceSettings(postData)
                .then((res: any) => {
                    if (res.data.success) {
                        const datas = res.data.data;
                        
                        toastNotify(toastId, res)
                        setLoadingStages(LOADINGSTAGES.POSITIVE);
                    }
                    else { setLoadingStages(LOADINGSTAGES.NEGATIVE); }
                })
                .catch(() => {
                    setLoadingStages(LOADINGSTAGES.ERROR);
                }).finally(() => {
                    setLoading(false);
                });
        }, 500);
    }


    useEffect(() => {
        bindStates();

    }, []);
    useEffect(() => {

    });


    const [value, setValue] = useState<any>(null);

    return (
        <>
            <PageTitle breadcrumbs={breadcrumbs({
                title: "InvoiceSettings",
                path: "/invoiceSettings",
            })} >
                Add InvoiceSettings
            </PageTitle>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="separator mb-5"></div>
                        <div className="card">
                            <div className="card-body">

                                <form
                                    className='col-md-11 mb-lg-10'


                                    noValidate
                                    id='ab_invoice_clone_form'
                                >
                                    <div className="row">
                                        <div className="col-md-10 mb-lg-10">


                                            <div className="row mb-3">

                                            </div>
                                            {/* 2 */}
                                            <div className="row rounded mb-12">
                                                <div className="row mb-12 form-group">
                                                    <div className="row">
                                                        <div className="col-md-12 d-flex flex-row mb-12">
                                                            <div className="col-md-6">Show Shipping Address</div>
                                                            <div className="col-md-6">

                                                                <ToggleSwitchnew
                                                                    name='showShippingAddress'
                                                                    label='showShippingAddress'
                                                                    checked={formik.values.showShippingAddress}
                                                                    onChange={(e: any) => showShippingAddressToggle(e)}




                                                                />
                                                            </div>             </div>

                                                    </div>


                                                    <div className="row">
                                                        <div className="col-md-12 d-flex flex-row mb-12">
                                                            <div className="col-md-6">Show Primary Signature</div>
                                                            <div className="col-md-6">

                                                                <ToggleSwitchnew
                                                                    name='showPrimarySignature'
                                                                    label='showPrimarySignature'
                                                                    checked={formik.values.showPrimarySignature}
                                                                    onChange={(e: any) => showPrimarySignatureToggle(e)}

                                                                />
                                                            </div>             </div>

                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12 d-flex flex-row mb-12">
                                                            <div className="col-md-6">Show Secondry Signature</div>
                                                            <div className="col-md-6">

                                                                <ToggleSwitchnew
                                                                    name='showSecondrySignature'
                                                                    label='showSecondrySignature'
                                                                    checked={formik.values.showSecondrySignature}
                                                                    onChange={(e: any) => showSecondrySignatureToggle(e)}


                                                                />
                                                            </div>             </div>

                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-15 d-flex flex-row mb-24">
                                                            <div className="col-md-8">Show Decimal Places</div>

                                                            <div className="col-md-6">
                                                                <ValidationFieldNew
                                                                    className='col-md-6'
                                                                    name='decimalplaces'
                                                                    type='text'
                                                                    placeholder='Show Deimal Place'

                                                                    formik={formik}
                                                                />


                                                            </div>

                                                        </div>

                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-15 d-flex flex-row mb-24">
                                                            <div className="col-md-8">Show Decimal Places For Quantity</div>

                                                            <div className="col-md-6">
                                                                <ValidationFieldNew
                                                                    className='col-md-6'
                                                                    name='decimalplacesForQuantity'
                                                                    isRequired
                                                                    type='text'
                                                                    placeholder='Show Deimal Place for Quantity'

                                                                    formik={formik}
                                                                />



                                                            </div>

                                                        </div>

                                                    </div>



                                                    <div className="row">
                                                        <div className="col-md-12 d-flex flex-row mb-12">
                                                            <div className="col-md-6">Total with Round Up Off Decimal</div>
                                                            <div className="col-md-6">

                                                                <ToggleSwitchnew
                                                                    name='totalWithoutRoundOffDecimal'
                                                                    label='totalWithoutRoundOffDecimal'
                                                                    checked={formik.values.totalWithoutRoundOffDecimal}
                                                                    onChange={(e: any) => totalWithoutRoundOffDecimalToggle(e)}

                                                                />
                                                            </div>             </div>

                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12 d-flex flex-row mb-12">
                                                            <div className="col-md-6">Show Total Tax Amount</div>
                                                            <div className="col-md-6">

                                                                <ToggleSwitchnew
                                                                    label='showTotalTaxAmount'
                                                                    name='showTotalTaxAmount '
                                                                    checked={formik.values.showTotalTaxAmount}
                                                                    onChange={(e: any) => showTotalTaxAmountToggle(e)}


                                                                />
                                                            </div>             </div>

                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12 d-flex flex-row mb-12">
                                                            <div className="col-md-6">Show Bank Details</div>
                                                            <div className="col-md-6">

                                                                <ToggleSwitchnew
                                                                    name='showBankDetails'
                                                                    label='showBankDetails'
                                                                    checked={formik.values.showBankDetails}
                                                                    onChange={(e: any) => showBankDetailsToggle(e)}



                                                                />
                                                            </div>             </div>

                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12 d-flex flex-row mb-12">
                                                            <div className="col-md-6">Document Prefix Saperator</div>
                                                            <div className="col-md-6">




                                                                <ValidationSelectNew
                                                                    className='col-md-6'
                                                                    label='documentPrefixSaperator'
                                                                    isRequired
                                                                    name='name'
                                                                    options={Names}
                                                                    placeholder='Select Prefix'







                                                                    formik={formik}
                                                                />
                                                            </div>             </div>

                                                    </div>












                                                </div>

                                            </div>



                                        </div>



                                    </div>

                                    <div className='card-footer d-flex justify-content-center py-6 px-9'>
                                        <button type='submit' className='btn btn-primary'
                                            disabled={loading || formik.isSubmitting || !formik.isValid || !formik.touched}>
                                            <span className='indicator-label'>Submit</span>
                                            {(formik.isSubmitting) && (
                                                <span className='indicator-progress'>
                                                    Please wait...{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                        </button>

                                        <Link to={'/invoices'} className='btn btn-light mx-3'>
                                            Cancel
                                        </Link>
                                    </div>
                                </form>


                            </div>


                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}